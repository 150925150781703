import {
  TC_CHAIN_ID,
  TC_EXPLORER,
  TC_LAYER2_EXPLORER,
  TC_LAYER2_NETWORK_RPC,
  TC_NETWORK_RPC,
} from '@/configs';
import { isProduction } from '@/utils/commons';

export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  POLYGON_MUMBAI = 80001,
  TRUSTLESS_COMPUTER = isProduction() ? 22213 : 22215,

  TRUSTLESS_COMPUTER_LAYER_2 = isProduction() ? 42213 : 42070,
  ETHEREUM = isProduction() ? 1 : 5,
  BITCOIN = 6666,
}

export const ETH_CHAIN_ID = isProduction()
  ? SupportedChainId.MAINNET
  : SupportedChainId.GOERLI;

export const BASE_CHAIN_ID = isProduction() ? 8453 : 84531;
export const AVAX_CHAIN_ID = isProduction() ? 43114 : 43113;

export const NETWORK_TO_CHAIN_ID: any = {
  eth: ETH_CHAIN_ID,
  tc: TC_CHAIN_ID,
};

export const NETWORK_TO_EXPLORER: any = {
  eth: isProduction() ? 'https://etherscan.io' : 'https://goerli.etherscan.io',
  tc: TC_EXPLORER,
  nos: TC_LAYER2_EXPLORER,
};

export const CHAIN_ID_TO_NETWORK: any = {
  [ETH_CHAIN_ID]: 'eth',
  [TC_CHAIN_ID]: 'tc',
};

const TRUSTLESS_COMPUTER_CHAIN_INFO = {
  name: isProduction() ? 'Trustless Computer' : 'Trustless Computer (Testnet)',
  title: '',
  chain: 'TC',
  icon: '',
  rpc: [TC_NETWORK_RPC],
  faucets: [],
  nativeCurrency: {
    name: 'TC',
    symbol: 'TC',
    decimals: 18,
  },
  infoURL: 'https://trustless.computer',
  shortName: 'TC',
  chainId: SupportedChainId.TRUSTLESS_COMPUTER,
  networkId: SupportedChainId.TRUSTLESS_COMPUTER,
  slip44: 0,
  explorers: [
    {
      name: isProduction()
        ? 'Trustless computer explorer'
        : 'Trustless computer explorer (Testnet)',
      url: isProduction()
        ? 'https://explorer.trustless.computer'
        : 'https://explorer.regtest.trustless.computer/',
      standard: 'EIP3091',
    },
  ],
  ens: {
    registry: '',
  },
};

export const TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO = {
  name: isProduction() ? 'NOS' : 'NOS (Test)',
  title: '',
  chain: 'NOS',
  icon: '',
  rpc: [TC_LAYER2_NETWORK_RPC],
  faucets: [],
  nativeCurrency: {
    name: 'TC',
    symbol: 'TC',
    decimals: 18,
  },
  infoURL: 'https://trustless.computer',
  shortName: 'TC',
  chainId: SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2,
  networkId: SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2,
  slip44: 0,
  explorers: [
    {
      name: isProduction()
        ? 'Trustless computer explorer'
        : 'Trustless computer explorer (Test)',
      url: isProduction()
        ? 'https://explorer.l2.trustless.computer/'
        : 'https://nos-explorer.regtest.trustless.computer/',
      standard: 'EIP3091',
    },
  ],
  ens: {
    registry: '',
  },
};

export const CHAINS_INFO = [
  TRUSTLESS_COMPUTER_CHAIN_INFO,
  TRUSTLESS_COMPUTER_LAYER2_CHAIN_INFO,
];
