import { API_DGAMES } from '@/configs';
import { apiClient } from '@/services';
import { camelCaseKeys } from 'trustless-swap-sdk';
import {
  RequestMemoryPayload,
  RequestMemoryResponse,
  SubmitMemoryFeePayload,
  SubmitMemoryFeeResponse,
} from '@/interfaces/api/memory';

const API_PATH = 'v1/memory';

export const getMemoryRequest = async (
  payload: RequestMemoryPayload
): Promise<RequestMemoryResponse> => {
  const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/request-mint`, {
    ...payload,
  });
  return camelCaseKeys(res);
};

export const submitMemoryFeeTx = async (
  payload: SubmitMemoryFeePayload
): Promise<SubmitMemoryFeeResponse> => {
  const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/submit-fee-tx`, {
    ...payload,
  });
  return camelCaseKeys(res);
};

export const getMemoryTokenIdTxHash = async (
  mintedTxHash: string
): Promise<string> => {
  const res = await apiClient.get(
    `${API_DGAMES}/${API_PATH}/txhash-to-tokenid/${mintedTxHash}`
  );
  return camelCaseKeys(res).result;
};
