import { useEffect } from 'react';
import { useAppDispatch } from '@/state/hooks';
import {
  fetchAllTokens,
  fetchTokenMultiChain,
  fetchTokenPrice,
} from '@/state/tokens/actions';
import { fetchNetworkList } from '@/state/networks/actions';

export const Fetcher = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log('Fetcher  --- useEffect');
    try {
      Promise.all([
        dispatch(fetchTokenMultiChain()),
        dispatch(fetchTokenPrice()),
        dispatch(fetchAllTokens()),
        dispatch(fetchNetworkList()),
      ]);
    } catch (error) {}
  }, []);

  return null;
};

export default Fetcher;
