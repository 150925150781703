import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { IReferentEarning } from '@/interfaces/api/referral';
import s from './styles.module.scss';
import cs from 'classnames';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useContext, useEffect, useState } from 'react';
import Avatar from '@/components/Avatar';
import {
  convertBtcToUsdt,
  displayBTCValue,
  formatTCAddress,
} from '@/utils/format';
import { WalletContext } from '@/contexts/wallet-context';

type Props = {
  player?: IGetPlayerPoolProfile;
  earning: IReferentEarning;
};

const InvitedItem: React.FC<Props> = ({
  player,
  earning,
}: Props): React.ReactElement => {
  const { btc2Usd } = useContext(WalletContext);
  const [usdt, setUsdt] = useState(0);

  useEffect(() => {
    if (earning?.totalBtcEarning) {
      convertBtcToUsdt(earning?.totalBtcEarning, btc2Usd).then(_usdt => {
        setUsdt(_usdt);
      });
    }
  }, [earning, btc2Usd]);

  return (
    <div className={s.invitedItem}>
      <div className={cs(s.invitedItem__item, s.invitedPlayer)}>
        <div className={s.avatar}>
          {player && (player.twitterAvatar || player.avatar) ? (
            <Avatar
              width={48}
              height={48}
              imgSrcs={player.twitterAvatar || player.avatar}
            />
          ) : (
            <Jazzicon
              diameter={54}
              seed={jsNumberForAddress(player?.address || '')}
            />
          )}
        </div>
        <div className={cs(s.invitedPlayer__info)}>
          <div className={cs(s.invitedItem__item__label)}>
            {player && (
              <>
                {player.twitterName ||
                  (player.twitterUsername && `@${player.twitterUsername}`) ||
                  player.bns ||
                  formatTCAddress(player.address || '')}
              </>
            )}
          </div>
          <div className={cs(s.invitedItem__item__value)}>
            Vol {displayBTCValue(earning.totalBtcTrade)}
            {/* {Number(formatEthPriceInput(ethers.BigNumber.from(earning?.totalEarning || '0').mul(100).toString())).toFixed(2)} TC */}
          </div>
        </div>
      </div>
      <div className={cs(s.invitedItem__item, s.invitedEarning)}>
        <div className={cs(s.invitedItem__item__label)}>
          {displayBTCValue(earning.totalBtcEarning)}
        </div>
        <div className={cs(s.invitedItem__item__value)}>${usdt.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default InvitedItem;
