import {
  getChatMessagesByTime,
  addChatMessage,
  updateChatLastSeen,
  getChatRoomById,
  getChatRoomIds,
  getMessageDetailRoom,
  setChatRoomPin,
  setChatRoomUnPin,
} from '@/services/chat/p2p';
import {
  getGroupMessagesByTime,
  addGroupMessage,
  updateGroupLastSeen,
  getChatGroupById,
  getChatGroupRoomIds,
  getMessageDetail,
  setChatGroupPin,
  setChatGroupUnPin,
  getChatGroupByIdWithoutPermission,
} from '@/services/chat/group';
import { ChatType } from '@/modules/AlphaPWA/DirectMessage/types';
import {
  AddChatMessagePayload,
  ChatRoomResponse,
  GetChatMessageByTimePayload,
} from '@/interfaces/api/chat';

export const getMessageByTime = async (
  type: ChatType,
  payload: GetChatMessageByTimePayload
) => {
  if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
    return await getGroupMessagesByTime(payload);
  }
  return await getChatMessagesByTime(payload);
};

export const addMessage = async (
  type: ChatType,
  payload: AddChatMessagePayload
) => {
  if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
    return await addGroupMessage(payload);
  }
  return await addChatMessage(payload);
};

export const updateLastSeen = async (
  type: ChatType,
  payload: {
    roomId: string;
  }
) => {
  try {
    if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
      return await updateGroupLastSeen(payload);
    }
    return await updateChatLastSeen(payload);
  } catch (e) {
    //
    return;
  }
};

type RoomType = {
  status: 'fulfilled' | 'rejected';
  value: ChatRoomResponse;
};
export const getRoomById = async (id: string, type?: ChatType) => {
  if (!!type) {
    if (type === 'CHAT_ROOM') {
      const [dm] = (await Promise.allSettled([
        getChatRoomById(id),
      ])) as RoomType[];
      if (dm.status === 'fulfilled') {
        return dm.value;
      }
    } else if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
      const [group] = (await Promise.allSettled([
        getChatGroupById(id),
      ])) as RoomType[];
      if (group.status === 'fulfilled') {
        return group.value;
      }
    }
  } else {
    const [dm, group] = (await Promise.allSettled([
      getChatRoomById(id),
      getChatGroupById(id),
    ])) as RoomType[];
    if (dm.status === 'fulfilled') {
      return dm.value;
    }
    if (group.status === 'fulfilled') {
      return group.value;
    }
  }

  return null;
};

export const getRoomByIdWithoutPermission = async (id: string) => {
  const [group] = (await Promise.allSettled([
    getChatGroupByIdWithoutPermission(id),
  ])) as RoomType[];
  if (group.status === 'fulfilled') {
    return group.value;
  }
  return null;
};

type RoomIdsType = {
  status: 'fulfilled' | 'rejected';
  value: string[];
};

export const getAllRoomIds = async (): Promise<string[]> => {
  try {
    const [dmIds, groupIds] = (await Promise.allSettled([
      getChatRoomIds(),
      getChatGroupRoomIds(),
    ])) as RoomIdsType[];

    const roomIds = [];
    if (dmIds.status === 'fulfilled') {
      roomIds.push(...dmIds.value);
    }
    if (groupIds.status === 'fulfilled') {
      roomIds.push(...groupIds.value);
    }
    return roomIds;
  } catch (e) {
    return [];
  }
};

export const getChatMessageDetail = async (
  type: ChatType,
  messagedId: string
) => {
  if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
    return await getMessageDetail(messagedId);
  }
  if (type === 'CHAT_ROOM') {
    return await getMessageDetailRoom(messagedId);
  }
  return undefined;
};

export const setPinChatRoom = async (
  type: ChatType,
  roomId: string,
  pinned: boolean
) => {
  if (pinned) {
    if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
      return await setChatGroupPin({ roomId });
    }
    if (type === 'CHAT_ROOM') {
      return await setChatRoomPin({ roomId });
    }
  } else {
    if (type === 'CHAT_GROUP' || type === 'CHAT_TRIBE') {
      return await setChatGroupUnPin({ roomId });
    }
    if (type === 'CHAT_ROOM') {
      return await setChatRoomUnPin({ roomId });
    }
  }
};
