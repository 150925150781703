import { ITokenMultiChain, Token } from '../tokens/types';
import StorageLocal from '@/utils/storage.local';
import { ListTokenFollowMap, TokenID } from './types';
import { isProduction } from '@/utils/commons';

const VERSION = 'v1.0.1';

const KEYS = {
  LIST_TOKEN_FOLLOWING: 'LIST_TOKEN_FOLLOWING',
};

const getKeyTokenFollowing = () => {
  return `${KEYS.LIST_TOKEN_FOLLOWING}-[${
    isProduction() ? 'MAINNET' : 'TESTNET'
  }]-VERSION-${VERSION}`;
};

const getTokenFollowingMap = () => {
  const keyStorage = getKeyTokenFollowing();
  const dataMap: ListTokenFollowMap | undefined = StorageLocal.get(keyStorage);
  return dataMap;
};

const setTokenFollowingMap = (dataMap: ListTokenFollowMap) => {
  const keyStorage = getKeyTokenFollowing();

  StorageLocal.set(keyStorage, JSON.stringify(dataMap));
};

const clearTokenFollowingMap = (dataMap: ListTokenFollowMap) => {
  const keyStorage = getKeyTokenFollowing();
  StorageLocal.remove(keyStorage);
};

const getTokenFollowingByAddress = (tcAddressL2?: string) => {
  if (!tcAddressL2) return [];
  const dataMap = getTokenFollowingMap();
  return dataMap ? dataMap[tcAddressL2] : [];
};

const removeTokenFollowingByAddress = (tcAddressL2?: string) => {
  if (!tcAddressL2) return;
  const dataMap = getTokenFollowingMap();
  if (dataMap) {
    delete dataMap[tcAddressL2];
    setTokenFollowingMap(dataMap);
  }
};

const getTokenFollowingStorage = (
  tcAddressL2: string,
  listTokenFollow: ITokenMultiChain[]
) => {
  const keyStorage = getKeyTokenFollowing();
  const data: ListTokenFollowMap = StorageLocal.get(keyStorage);
  if (tcAddressL2) {
    data[tcAddressL2] = listTokenFollow;
    StorageLocal.set(keyStorage, data);
  }
};

const setTokenFollowingToLocalStorage = (
  tcAddressL2?: string,
  listTokenFollow?: ITokenMultiChain[]
) => {
  let dataMap: ListTokenFollowMap | undefined = getTokenFollowingMap();
  if (!dataMap) {
    dataMap = {};
  }
  if (tcAddressL2 && listTokenFollow) {
    dataMap[tcAddressL2] = listTokenFollow;
    setTokenFollowingMap(dataMap);
  }
};

export {
  getKeyTokenFollowing,

  // Map
  getTokenFollowingMap,
  setTokenFollowingMap,
  clearTokenFollowingMap,

  // Token List By Address
  getTokenFollowingByAddress,
  removeTokenFollowingByAddress,
  setTokenFollowingToLocalStorage,
  getTokenFollowingStorage,
};
