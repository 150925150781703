import * as CryptoJS from 'crypto-js';
import SDKError, { ERROR_CODE } from '@/utils/error';
import NodeRSA from 'node-rsa';
import { INodeRSAKeyPair } from '@/utils/nodeRSA';

const doubleHash = (key: string) => {
  const hash = CryptoJS.SHA256(key);
  return CryptoJS.SHA256(hash).toString();
};

const decodeBase64 = (base64: string) => {
  return Buffer.from(base64, 'base64');
};

const encryptAES = (text: string, key: string) => {
  const password = doubleHash(key);
  return CryptoJS.AES.encrypt(text, password).toString();
};

const decryptAES = (cipherText: string, key: string, isDoubleHash = true) => {
  const password = isDoubleHash ? doubleHash(key) : key;
  const decrypted = CryptoJS.AES.decrypt(cipherText, password);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        throw new SDKError(ERROR_CODE.DECRYPT);
      }
    } catch (e) {
      throw new SDKError(ERROR_CODE.DECRYPT);
    }
  }
  throw new SDKError(ERROR_CODE.DECRYPT);
};

interface IEncryptRSA {
  message: string;
  publicKeyBase64: string;
}

const encryptRSA = (params: IEncryptRSA) => {
  const keyPub = new NodeRSA();
  keyPub.importKey(
    Buffer.from(params.publicKeyBase64, 'base64'),
    'pkcs1-public-der'
  );

  const encryptedMessage = keyPub.encrypt(params.message, 'base64');

  return {
    encryptedMessage,
  };
};

interface IDecryptRSA {
  encryptedMessage: string;
  rsaKey: INodeRSAKeyPair;
}

const decryptRSA = (params: IDecryptRSA) => {
  const { keyPair } = params.rsaKey;
  const decryptedMessageBase64 = keyPair.decrypt(
    params.encryptedMessage,
    'base64'
  );
  return {
    decryptedMessage: decodeBase64(decryptedMessageBase64).toString('utf8'),
  };
};

const toMD5 = (message: string) => {
  return CryptoJS.MD5(message).toString();
};

export { doubleHash, encryptAES, decryptAES, encryptRSA, decryptRSA, toMD5 };
