/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';

import styles from './styles.module.scss';
import React from 'react';
import { formatMaxDecimals } from '@/modules/Stake/utils';
import { MAX_DECIMAL } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';

interface FieldTextProps {
  input?: any;
  meta?: any;
  label?: string;
  rightLabel?: string;
  placeholder?: string;
  errorMessage?: any;
  prependComp?: any;
  appendComp?: any;
  errorPlacement?: string;
  inputType?: 'text' | 'textarea' | 'number';
  fieldChanged?: (_: any) => void;
  borderColor?: string;
  inputNote?: string;
}

const FieldText = (props: FieldTextProps) => {
  const {
    input,
    label,
    rightLabel,
    meta,
    placeholder,
    inputType = 'text',
    prependComp,
    appendComp,
    fieldChanged,
    // disabledInput, errorPlacement, zIndex, anchorAppend,
    borderColor = '#FFFFFF',
    inputNote,
    ...restProps
  } = props;

  const { onChange, onBlur, onFocus, value } = input;
  const { error, touched } = meta;
  const shouldShowError = !!(touched && error) || (error && value);

  const isError = meta.error && meta.touched;

  const hasAppend = appendComp;

  const handleChange = (e: any) => {
    // change input verify is number
    const value = e.target.value;
    const formatValue = formatMaxDecimals({
      value: value,
      maxDecimals: MAX_DECIMAL,
    });
    if (formatValue !== undefined) {
      onChange(e.target.value);
      fieldChanged?.(e.target.value);
    }
  };

  return (
    <FormControl isInvalid={isError}>
      {(label || rightLabel) && (
        <Flex justifyContent={'space-between'}>
          <Box>
            <FormLabel fontSize="xs" fontWeight="medium">
              {label}
            </FormLabel>
          </Box>
          <Box>
            <FormLabel fontSize="xs" fontWeight="medium">
              {rightLabel}
            </FormLabel>
          </Box>
        </Flex>
      )}
      <InputGroup
        borderStyle={'solid'}
        borderWidth={1}
        borderColor={shouldShowError ? '#dd3b4b' : borderColor}
        borderRadius={8}
        bgColor="#FFFFFF"
        overflow="hidden"
      >
        {prependComp && (
          <InputLeftElement
            children={prependComp}
            // ml={2}
            // mr={2}
            // height="100%"
            position={'relative'}
          />
        )}
        <Box className={styles.formControl}>
          <Input
            as={
              inputType === 'text' || inputType === 'number'
                ? 'input'
                : 'textarea'
            }
            type={inputType}
            placeholder={placeholder}
            _placeholder={{ color: '#b3b3b3' }}
            value={value}
            onFocus={onFocus}
            onBlur={e => {
              onBlur();
              e?.target?.blur();
            }}
            {...restProps}
            onChange={handleChange}
          />
          {inputNote && <Text className={'input-note'}>{inputNote}</Text>}
        </Box>
        {hasAppend && (
          <InputRightElement w="fit-content" pr={2} children={appendComp} />
        )}
      </InputGroup>
      <FormErrorMessage fontSize="sm" color="#dd3b4b">
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FieldText;
