/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useRef } from 'react';
import { MdHelpOutline } from 'react-icons/md';
import s from './style.module.scss';

interface InfoTooltipProps {
  label: ReactNode;
  children?: ReactNode;
  placement?: PlacementWithLogical;
  iconSize?: string;
  fontSize?: string;
  iconColor?: string;
  showIcon?: boolean;
  iconName?: any;
  setIsOpen?: (b: boolean) => void;
  isStyleConfig?: boolean;
}

const InfoTooltip = (props: InfoTooltipProps) => {
  const {
    label,
    fontSize,
    iconSize = 'md',
    placement = 'top',
    children,
    showIcon = false,
    iconColor = '#FFFFFF',
    iconName = MdHelpOutline,
    setIsOpen,
    isStyleConfig = true,
  } = props;
  const { isOpen, onToggle, onClose } = useDisclosure();

  useEffect(() => {
    setIsOpen && setIsOpen(isOpen);
  }, [isOpen]);

  const initRef = useRef<any>();

  const renderChild = () => {
    if (children && showIcon) {
      return (
        <Flex gap={1} alignItems={'center'}>
          {children}
          <Icon as={iconName} fontSize={iconSize} color={iconColor} />
        </Flex>
      );
    }
    if (children) {
      return children;
    }
    return <Icon as={iconName} fontSize={iconSize} color={iconColor} />;
  };

  return (
    <Popover
      closeOnBlur={true}
      placement="auto"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
      styleConfig={
        isStyleConfig
          ? {
              zIndex: 999999999,
            }
          : undefined
      }
    >
      <PopoverTrigger>
        <Box
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onToggle();
          }}
        >
          {renderChild()}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow bg="white" />
        <PopoverBody
          style={{
            padding: 5,
          }}
        >
          <Text fontSize={'12px'}>{label}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default InfoTooltip;
