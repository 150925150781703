import React, { useCallback, useMemo } from 'react';
import { IItemSort } from './itemFilterSort';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';

import s from '../styles.module.scss';
import { compareString } from '@/utils';

interface ISortBy {
  sorts: IItemSort[];
  filterKeys: any;
  onFilter: any;
  setFilterKeys: any;
}

const SortBy: React.FC<ISortBy> = ({
  sorts,
  filterKeys,
  onFilter,
  setFilterKeys,
}) => {
  const currentSortBy = useMemo(() => {
    const sort_col = filterKeys?.sort_col?.split(',');
    const sort_type = filterKeys?.sort_type?.split(',');
    const holder = filterKeys?.holder;

    const key = sort_col?.find?.(
      (v: any) => sorts.findIndex(_v => compareString(v, _v.key)) > -1
    );
    const value = sort_type?.find?.(
      (v: any) => sorts.findIndex(_v => compareString(v, _v.value)) > -1
    );

    return {
      key,
      value,
      label: sorts.find(
        v => compareString(v.key, key) && compareString(v.value, value)
      )?.label,
    };
  }, [filterKeys, sorts]);

  const onSetSort = (sort: IItemSort) => {
    setFilterKeys((_filter: any) => {
      const __filters = {
        ..._filter,
        sort_col: sort.key,
        sort_type: sort.value,
        holder: compareString(sort.key, 'holder') ? sort.value : 0,
        portfolio: compareString(sort.key, 'portfolio') ? sort.value : 0,
      };
      onFilter(__filters);
      return __filters;
    });
  };

  const getActive = (sort: IItemSort) => {
    return (
      compareString(currentSortBy.key, sort.key) &&
      compareString(currentSortBy.value, sort.value)
    );
  };

  return (
    <Flex className={s.sortByTextContainer} gap={'16px'} alignItems={'center'}>
      {sorts.map(st => (
        <Text
          onClick={() => onSetSort(st)}
          className={getActive(st) ? s.active : ''}
          key={st.key}
        >
          {st.label}
        </Text>
      ))}
    </Flex>
  );
};

export default SortBy;
