import { LockTerm, StakeType } from '@/interfaces/api/staking';

const CREATE_STAKE_OPTIONS = {
  [LockTerm.FLEXIBLE]: [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
  ],
  [LockTerm.ONE_MONTH]: [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 4,
    },
  ],
  [LockTerm.TWO_MONTH]: [
    {
      value: 2,
    },
    {
      value: 6,
    },
    {
      value: 12,
    },
  ],
  [LockTerm.THREE_MONTH]: [
    {
      value: 3.75,
    },
    {
      value: 7.5,
    },
    {
      value: 15,
    },
  ],
  [LockTerm.SIX_MONTH]: [
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 40,
    },
  ],
};

const CUSTOM_PERCENT_OPTIONS = {
  [StakeType.KEYS]: {
    [LockTerm.FLEXIBLE]: 4,
    [LockTerm.ONE_MONTH]: 6,
    [LockTerm.TWO_MONTH]: 9,
    [LockTerm.THREE_MONTH]: 12,
    [LockTerm.SIX_MONTH]: 24,
  },
  [StakeType.TOKENS]: {
    [LockTerm.FLEXIBLE]: 4,
    [LockTerm.ONE_MONTH]: 6,
    [LockTerm.TWO_MONTH]: 9,
    [LockTerm.THREE_MONTH]: 20,
    [LockTerm.SIX_MONTH]: 50,
  },
};

const CUSTOM_SLOT_OPTIONS = {
  [StakeType.KEYS]: {
    [LockTerm.FLEXIBLE]: 20,
    [LockTerm.ONE_MONTH]: 30,
    [LockTerm.TWO_MONTH]: 40,
    [LockTerm.THREE_MONTH]: 50,
    [LockTerm.SIX_MONTH]: 60,
  },
  [StakeType.TOKENS]: {
    [LockTerm.FLEXIBLE]: 2000000,
    [LockTerm.ONE_MONTH]: 2000000,
    [LockTerm.TWO_MONTH]: 2000000,
    [LockTerm.THREE_MONTH]: 2000000,
    [LockTerm.SIX_MONTH]: 2000000,
  },
};

const SLOT_OPTIONS = {
  [StakeType.KEYS]: {
    [LockTerm.FLEXIBLE]: 10,
    [LockTerm.ONE_MONTH]: 10,
    [LockTerm.TWO_MONTH]: 10,
    [LockTerm.THREE_MONTH]: 10,
    [LockTerm.SIX_MONTH]: 10,
  },
  [StakeType.TOKENS]: {
    [LockTerm.FLEXIBLE]: 1000000,
    [LockTerm.ONE_MONTH]: 1000000,
    [LockTerm.TWO_MONTH]: 1000000,
    [LockTerm.THREE_MONTH]: 1000000,
    [LockTerm.SIX_MONTH]: 1000000,
  },
};

const MIN_CREATOR_STAKE_REQUIRED = 1;
const MIN_KEY_STAKER_REQUIRED = 0.001;
const MIN_TOKEN_STAKER_REQUIRED = 1;

const MIN_CREATE_STAKING = {
  [StakeType.KEYS]: 0,
  [StakeType.TOKENS]: 50000,
};

const LockTime = {
  [LockTerm.FLEXIBLE]: 30,
  [LockTerm.ONE_MONTH]: 30,
  [LockTerm.TWO_MONTH]: 60,
  [LockTerm.THREE_MONTH]: 90,
  [LockTerm.SIX_MONTH]: 180,
};

const YEAR_DAYS = 360;

const MAX_STAKING_PERCENT = 200;

export {
  CREATE_STAKE_OPTIONS,
  MIN_CREATOR_STAKE_REQUIRED,
  MIN_KEY_STAKER_REQUIRED,
  CUSTOM_PERCENT_OPTIONS,
  CUSTOM_SLOT_OPTIONS,
  SLOT_OPTIONS,
  MIN_CREATE_STAKING,
  LockTime,
  MIN_TOKEN_STAKER_REQUIRED,
  YEAR_DAYS,
  MAX_STAKING_PERCENT,
};
