import { NBC_URL } from '@/constants/common';
import web3 from 'web3';
import BigNumber from 'bignumber.js';
import { MAX_DECIMAL } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { isInValidAmount } from './helpers';

export const checkLines = (str: string) => str.split(/\r\n|\r|\n/).length;

export const checkForHttpRegex = (str: string) => {
  const httpsRegex = /^(http|https):\/\//;
  return httpsRegex.test(str);
};

export const isBase64String = (str: string): boolean => {
  try {
    window.atob(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isNumeric = (str: never | string) => {
  return /^\d+$/.test(str);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringToBuffer = (str: string): Buffer => {
  return Buffer.from(str);
};

export const compareString = (a: unknown, b: unknown) => {
  return a?.toString?.()?.toLowerCase?.() === b?.toString?.()?.toLowerCase?.();
};

export const formatCurrency = (
  value: any = 0,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  symbol = 'TC',
  hideAbbr = false
): string => {
  if (isNaN(Number(value))) return '0';

  let config: any = {
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  };

  if (Number(value) < 1) {
    if (compareString(symbol, 'BTC')) {
      config = {
        maximumFractionDigits: 6,
        minimumFractionDigits: 0,
      };
    } else {
      config = {
        maximumFractionDigits: 4,
        minimumFractionDigits: minimumFractionDigits,
      };
    }
  } else if (Number(value) >= 1 && Number(value) < 1000) {
    config = {
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits,
    };
  } else if (Number(value) >= 10000 && !hideAbbr) {
    return abbreviateNumber(value);
  } else if (Number(value) >= 1000) {
    config = {
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits,
    };
  }

  const result = new Intl.NumberFormat('en-US', config);
  return result.format(value);
};

export const formatName = (name: string, length = 12): string => {
  if (!name) return '';

  if (web3.utils.isAddress(name)) {
    return name.substring(2, 8);
  } else {
    return name?.length > length ? name.substring(0, length) + '...' : name;
  }
};

export const abbreviateNumber = (value: any) => {
  const config: any = {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
  };
  const result = new Intl.NumberFormat('en-US', config);
  return result.format(value);
};

export const getUrlsFromText = (text: string) => {
  // Define a regular expression pattern to match URLs
  const urlPattern = /(?:https?:\/\/)?(?:www\.)?[\w.-]+\.\w+(?:\S+)?/gi;

  // Use the match() method to find all matches in the text
  const matches = text.match(urlPattern);

  // Return the array of URLs
  if (matches) {
    return matches.map(match => {
      if (!match.includes(`${NBC_URL}/@`)) return match.toString();
      return '';
    });
  } else {
    return [];
  }
};

export const countWords = (str: string) => {
  // Use a regular expression to split the string into words
  const words = str.split(/\s+/);
  // Filter out empty strings (in case of multiple spaces)
  const nonEmptyWords = words.filter(word => word !== '');
  // Return the count of non-empty words
  return nonEmptyWords.length;
};

interface IFormat {
  amount: string;
  roundCeil?: boolean;
  decimalsDisplay?: number;
}

export const formatAmount = (params: IFormat) => {
  const decimals = params.decimalsDisplay || MAX_DECIMAL;
  return new BigNumber(
    new BigNumber(params.amount).toFixed(
      decimals,
      params.roundCeil ? BigNumber.ROUND_CEIL : BigNumber.ROUND_FLOOR
    )
  ).toString();
};

interface IFormatToHuman extends IFormat {
  decimals?: number;
}

export const formatToHumanAmount = (params: IFormatToHuman) => {
  const decimals = params.decimals || 18;
  const amount = new BigNumber(params.amount).dividedBy(
    new BigNumber(10).pow(decimals)
  );
  return formatAmount({ ...params, amount: amount.toString() } as any);
};

type IFormatCurrencyV2 = {
  amount: string | number;
  decimals?: number;
};

export const formatCurrencyV2 = (params: IFormatCurrencyV2) => {
  const { amount, decimals = 6 } = params;

  if (!amount || isInValidAmount(amount)) return '0.0';
  const fmt = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };
  const amount_BN = new BigNumber(amount);
  if (amount_BN.isZero()) return '0';

  return new BigNumber(amount).toFormat(decimals, BigNumber.ROUND_DOWN, fmt);
};

export const removeTrailingZeroes = (amountString: string) => {
  let formattedString = amountString;
  while (
    formattedString.length > 0 &&
    ((formattedString.includes('.') &&
      formattedString[formattedString.length - 1] === '0') ||
      formattedString[formattedString.length - 1] === '.')
  ) {
    formattedString = formattedString.slice(0, formattedString.length - 1);
  }

  return formattedString;
};

type IGetDecimalsFromHumanAmount = {
  amount: number;
  defaultDecimals?: number;
};

export const getDecimalsFromHumanAmount = (
  params: IGetDecimalsFromHumanAmount
) => {
  const { amount, defaultDecimals = 6 } = params;
  let decimals;
  if (amount > 10) {
    decimals = 2;
  } else if (amount > 1) {
    decimals = 4;
  } else if (amount > 1e-4) {
    decimals = 5;
  } else if (amount > 1e-5) {
    decimals = 6;
  } else {
    decimals = defaultDecimals;
  }
  return decimals;
};
