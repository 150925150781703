import { CDN_URL_ICONS } from '@/configs';
import { AspectRatio } from '@chakra-ui/react';
import React from 'react';
import { Modal } from 'react-bootstrap';
import GetTC from '../GetTC';
import s from './style.module.scss';

interface NewInfoModalProps {
  onCloseModal: () => void;
  isShow: boolean;
  tcAmount?: number;
  tcAddress?: string;
  title?: string;
}

const GetTCModal = (props: NewInfoModalProps) => {
  const { isShow, onCloseModal, tcAmount, tcAddress, title } = props;
  return (
    <div>
      <Modal
        show={isShow}
        className={s.videoModal}
        centered
        onHide={onCloseModal}
      >
        <Modal.Body>
          <div className={s.content}>
            <img
              alt="close"
              src={`${CDN_URL_ICONS}/ic-close-modal.svg`}
              className={s.closeBtn}
              onClick={onCloseModal}
            />
            <GetTC
              isModal
              tcAmount={tcAmount}
              tcAddress={tcAddress}
              onCloseModal={onCloseModal}
              title={title}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GetTCModal;
