import { RootState } from '@/state';
import { WalletState } from '@/state/wallets/types';
import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { Token } from '../tokens/types';
import { LIST_TOKENS_CANT_REMOVE } from './constants';

export const getWalletSelector = (state: RootState): WalletState =>
  state.wallets;

export const getListTokenFollowSelector = createSelector(
  getWalletSelector,
  reducer => reducer.listTokenFollowing
);

export const getListTokenFollowCanRemoveSelector = createSelector(
  getListTokenFollowSelector,
  listToken =>
    listToken.filter(token => !LIST_TOKENS_CANT_REMOVE.includes(token.tokenId))
);

export const getTotalBTCBalanceFactory = createSelector(
  getWalletSelector,
  reducer => reducer.totalBTCBalanceFactory
);

export const getIsFetchignWalletBalance = createSelector(
  getWalletSelector,
  reducer => reducer.isFetchingBalance
);

export const getFetchignWalletBalanceFirstTimeSelector = createSelector(
  getWalletSelector,
  reducer => reducer.isFetchingBalanceFirstTime
);

export const getTokenFollowSetIDsSelector = createSelector(
  getListTokenFollowSelector,
  listToken => {
    const setObj = new Set<string>();
    listToken.map(token => setObj.add(token.tokenId));
    return setObj;
  }
);

export const getTokenFollowListIDsSelector = createSelector(
  getListTokenFollowSelector,
  listToken => {
    return (listToken && listToken.map(token => token.tokenId)) || [];
  }
);

export const getTokenFollowBalanceMapSelector = createSelector(
  getWalletSelector,
  reducer => {
    return reducer.tokenFollowBalanceMap || [];
  }
);

export const getListTokenBalanceFollowSelector = createSelector(
  [getListTokenFollowSelector, getTokenFollowBalanceMapSelector],
  (listTokenFollows, listTokenDetailBalanceMap) => {
    let result = listTokenFollows.map(token => {
      const tokenId = token.tokenId;
      const balanceDetail = listTokenDetailBalanceMap[tokenId];
      return {
        ...token,
        ...balanceDetail,
      };
    });
    result = orderBy(result, item => Number(item.tokenBalance2USD || '0'), [
      'desc',
    ]);
    return result;
  }
);

export const getListTokensUnFollowingSelector = createSelector(
  [getTokenFollowListIDsSelector],
  listFollowTokenIDs => (allTokens: Token[]) => {
    let result = allTokens.filter(token => {
      return !listFollowTokenIDs.includes(token.tcTokenID);
    });
    return orderBy(result, item => item.symbol?.toUpperCase(), ['asc']);
  }
);

//Feature Receive
export const getReceiveTokenSelector = createSelector(
  getWalletSelector,
  reducer => reducer.recieveTokenSelected
);

//Feature Send
export const getSendTokenSelector = createSelector(
  getWalletSelector,
  reducer => reducer.sendTokenSelected
);
