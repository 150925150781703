import React, { useMemo } from 'react';
import {
  DEFAULT_FOLLOWERS_MAX,
  DEFAULT_FOLLOWERS_MIN,
  DEFAULT_RED_FLAG_MAX,
  DEFAULT_RED_FLAG_MIN,
  DEFAULT_TAGGED_MAX,
  DEFAULT_TAGGED_MIN,
  DEFAULT_TC_MAX,
  DEFAULT_TC_MIN,
} from '../constants';
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import s from '../styles.module.scss';
import { formatCurrency } from '@/utils';
import { ceil, floor } from 'lodash';
import ActionSwitch from '@/modules/AlphaPWA/Profiles/TradeKey/pro/actionSwitch';

export interface IItemFilterType {
  label: string;
  name: string;
  step?: number;
  min?: number;
  max?: number;
  min_between?: number | undefined;
  prefix?: string;
  append?: string;
  type?: 'range' | 'switch';
  value?: string;
  leftText?: string;
  rightText?: string;
}

interface IItemFilterTypes {
  type: IItemFilterType;
  filterKeys: any;
  onSetFilter: (_: any) => void;
}

export const FILTER_TYPES: IItemFilterType[] = [
  {
    label: 'Key Price',
    name: 'price_usd',
    min: DEFAULT_TC_MIN,
    max: DEFAULT_TC_MAX,
    step: 10,
    prefix: '$',
    append: '',
    type: 'range',
    min_between: 1,
  },
  {
    label: 'Twitter Followers',
    name: 'followers',
    min: DEFAULT_FOLLOWERS_MIN,
    max: DEFAULT_FOLLOWERS_MAX,
    step: 1000,
    prefix: '',
    append: '',
    type: 'range',
    min_between: 1,
  },
  // {
  //   label: 'Number of Flags',
  //   name: 'red_flags',
  //   min: DEFAULT_RED_FLAG_MIN,
  //   max: DEFAULT_RED_FLAG_MAX,
  //   step: 0,
  //   prefix: '',
  //   type: 'range',
  //   min_between: 0,
  // },
];

export const PLACE_HOLDER_FILTER_TYPES: IItemFilterType[] = [
  {
    label: 'Key Price',
    name: 'price_usd',
    min: DEFAULT_TC_MIN,
    max: DEFAULT_TC_MAX,
    step: 10,
    prefix: '$',
    append: '',
    type: 'range',
    min_between: 1,
  },
  {
    label: 'Twitter Followers',
    name: 'followers',
    min: DEFAULT_FOLLOWERS_MIN,
    max: DEFAULT_FOLLOWERS_MAX,
    step: 1000,
    prefix: '',
    type: 'range',
    min_between: 0,
  },
  {
    label: 'Number of invitations',
    name: 'tweet_count',
    min: DEFAULT_TAGGED_MIN,
    max: DEFAULT_TAGGED_MAX,
    step: 1,
    prefix: '',
    type: 'range',
    min_between: 0,
  },
  {
    label: '',
    name: 'holding',
    value: 'false',
    prefix: '',
    type: 'switch',
    leftText: 'All',
    rightText: 'Holdings',
  },
];

const HOLDINGS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'holdings',
    label: 'Holdings',
  },
];

const ItemFilterTypes: React.FC<IItemFilterTypes> = ({
  type,
  filterKeys,
  onSetFilter,
}) => {
  const typeWithValue = useMemo(() => {
    const _type = filterKeys?.[type.name]?.split(',');
    return {
      min_value: _type?.[0] || type.min,
      max_value: _type?.[1]
        ? Number(_type?.[1]) > Number(type.max)
          ? type.max
          : _type?.[1]
        : type.max,
      value: _type?.[0] || type.value,
    };
  }, [filterKeys, type]);

  const renderTitle = useMemo(() => {
    const min = typeWithValue.min_value;
    const max = typeWithValue.max_value;

    if (type.type === 'switch') {
      return null;
    }

    return (
      <>
        <strong>{type.label}</strong> from{' '}
        <b>
          {type.prefix}
          {formatCurrency(min, 0, 7)} {type.append}
        </b>{' '}
        to{' '}
        <b>
          {type.prefix}
          {formatCurrency(max, 0, 7)}
          {Number(max) >= Number(type.max) ? '+' : ''} {type.append}
        </b>
      </>
    );
  }, [type, typeWithValue]);

  const onHandleChange = (values: any[]) => {
    onSetFilter({
      [type.name]: [floor(values[0], 3), ceil(values[1], 3)].join(','),
    });
  };

  const handleChange = (value: string) => {
    onSetFilter({
      [type.name]: String(value === 'holdings'),
    });
  };

  return (
    <Flex mb={10} gap={1} flexDirection={'column'}>
      <Text className={s.filterTitle}>{renderTitle}</Text>
      {type.type === 'range' && (
        <Box mx={4}>
          <RangeSlider
            aria-label={['min', 'max']}
            value={[typeWithValue.min_value, typeWithValue.max_value]}
            min={type.min}
            max={type.max}
            onChange={onHandleChange}
            step={type.step}
            minStepsBetweenThumbs={type.min_between}
          >
            <RangeSliderTrack height={'4px'} borderRadius={'100px'}>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
        </Box>
      )}
      {type.type === 'switch' && (
        <Flex gap={4} className={s.filterTitle}>
          <ActionSwitch
            values={HOLDINGS}
            value={typeWithValue.value === 'true' ? 'holdings' : 'all'}
            onChange={handleChange}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ItemFilterTypes;
