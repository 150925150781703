/* eslint-disable react/display-name */
/* eslint-disable react/no-children-prop */

import React, {forwardRef, useContext, useEffect, useMemo, useState,} from 'react';
import cx from 'clsx';
import styles from './styles.module.scss';
import s from './styles.module.scss';
import {Field, useForm, useFormState} from 'react-final-form';
import {Button, Flex, SimpleGrid, Text} from '@chakra-ui/react';
import InputWrapper from '@/modules/AlphaPWA/Profiles/TradeKey/components/form/inputWrapper';
import {useSelector} from 'react-redux';
import useDebounce from '@/hooks/useDebounce';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import {BigNumber} from 'ethers';
import {AssetsContext} from '@/contexts/assets-context';
import {selectCommonReducer} from "@/state/common";
import {parseEther} from "ethers/lib/utils";
import {IBodyBuySweetFloorKeys} from "@/contracts/interfaces/tradeKey";
import FieldText from "@/modules/AlphaPWA/Profiles/TradeKey/components/form/fieldText";
import px2rem from "@/utils/px2rem";
import {formatCurrency} from "@/utils";
import {WalletContext} from "@/contexts/wallet-context";
import BN from 'bignumber.js';

const AMOUNT_OPTIONS = [
  0.0001, 0.001, 0.01, 0.1, 1, 0
];

const IssueForm: React.FC<any> = forwardRef(
  (props: any, ref: any) => {
    const {
      selectKeys,
      setSelectKeys33,
      setEstimateKeysTC33,
      setEstimateTC33,
      userProfile,
      handleSubmit, submitting
    } = props;
    const [keyAmount, setKeyAmount] = useState(0.1);
    const [estimating, setEstimating] = useState(false);
    const [allowBuy, setAllowBuy] = useState(true);
    const { balanceL2 } = useContext(AssetsContext);
    const needReload = useSelector(selectCommonReducer).needReload;
    const { values } = useFormState();
    const [errorText, setErrorText] = useState('');
    const [btcPrice, setBTCPrice] = useState<BN>(new BN(0));
    const { handleGetBTC2USD } = useContext(WalletContext);
    const { change } = useForm();

    const amountBTC = values?.amountBTC;
    const isSelectMode = values?.isSelectMode;

    const { estimateTCMultiKeys } = useTradeKey();

    const newSelectedKeys = useMemo(() => {
      const result = selectKeys.map((k: IBodyBuySweetFloorKeys) => {
        return {
          token_address: k.token_address,
          token_amount: keyAmount,
          btc_amount: amountBTC?.toString() || "0",
        };
      });

      setSelectKeys33(result);

      return result;
    }, [selectKeys.length, keyAmount, amountBTC]);

    const selectKeysDebounced = useDebounce(newSelectedKeys, 2000);

    useEffect(() => {
      onEstimateTC(amountBTC);
    }, [selectKeysDebounced, needReload, userProfile]);

    useEffect(() => {
      getBTCPrice();
    }, []);

    const getBTCPrice = async () => {
      const price = await handleGetBTC2USD();
      setBTCPrice(price);
    }

    const onEstimateTC = async (amountBTC: string) => {
      if(!amountBTC?.trim()) {
        setAllowBuy(false);
        setErrorText('Required');
        return;
      }
      if(isNaN(Number(amountBTC))) {
        setAllowBuy(false);
        setErrorText('Not a number');
        return;
      }
      try {
        setEstimating(true);
        if (newSelectedKeys.length > 0) {
          const response = await estimateTCMultiKeys(newSelectedKeys);

          const myKeyEstimate = await estimateTCMultiKeys(
            [
              {
                token_address: userProfile?.token_address as string,
                token_amount: 0.1
              }
            ]
          );

          const isBalanceEnough= BigNumber.from(balanceL2.amountBTC).gt(parseEther(amountBTC).mul(response.alphaTokens.length));
          const isBalanceEnoughMinimum = BigNumber.from(balanceL2.amountBTC).gt(response.total_tc);
          const isAmountEnoughMinimum = parseEther(amountBTC).gt(myKeyEstimate.total_tc);
          const isAmountEnoughOtherMinimum = response.alphaTokens.findIndex(t => parseEther(amountBTC.toString()).lt(t.amount_tc)) < 0;

          setAllowBuy(
            isBalanceEnough &&
            isBalanceEnoughMinimum &&
            isAmountEnoughOtherMinimum &&
            isAmountEnoughMinimum
          );

          if(!isBalanceEnough) {
            setErrorText('Not enough balance to do all the (3,3) requests.');
          } else if (!isBalanceEnoughMinimum) {
            setErrorText('Not enough balance to buy at least 0.1 keys.');
          } else if (!isAmountEnoughOtherMinimum) {
            setErrorText('Amount not enough to buy at least 0.1 keys.');
          } else if (!isAmountEnoughMinimum) {
            setErrorText(`Amount not enough to buy at least 0.1 ${userProfile?.twitter_name} keys.`);
          } else {
            setErrorText('');
          }

          setEstimateTC33((Number(amountBTC) *  newSelectedKeys.length).toString());
          setEstimateKeysTC33(response.alphaTokens);
        } else {
          setAllowBuy(true);
          setErrorText('');
          setEstimateTC33('0');
          setEstimateKeysTC33([]);
        }
      } catch (error) {
        setAllowBuy(false);
        setErrorText('Oops! Something went wrong. Please try again later.');
        setEstimateTC33('0');
        setEstimateKeysTC33([]);
      } finally {
        setEstimating(false);
      }
    };

    const btnDisable =
      (newSelectedKeys?.length || 0) === 0 || estimating || !allowBuy;

    const getUserNameInTitle = () => {
      if(selectKeys.length >= 3) {
        return `${selectKeys[0].data?.user_twitter_name} and ${selectKeys.length - 1} others`;
      } else {
        return selectKeys.map((d: IBodyBuySweetFloorKeys) => d.data?.user_twitter_name).join(" and ");
      }
    }

    const onSelectAmount = (amount: string) => {
      change('amountBTC', amount);
      change('isSelectMode', true);
    }

    const onChangeAmount = (amount: any) => {
      change('isSelectMode', false);
      change('amountBTC', amount);
    };

    return (
      <form onSubmit={handleSubmit} ref={ref}>
        <Text fontSize={"16px"} fontWeight={500} textTransform={"uppercase"} w={"95%"}>New (3,3) requests With {getUserNameInTitle()}</Text>
        <Flex direction={"column"} mt={4} mb={5} color={'#000000'} opacity={'70%'}>
          <Text fontSize={px2rem(12)}>{selectKeys.length > 1 ? 'These are' : 'This is'} trustless and unstoppable (3,3) requests powered by smart contracts.</Text>
          <Text fontSize={px2rem(12)}>You and {selectKeys.length} participants will each spend {formatCurrency(amountBTC, 0, 6)} BTC to buy and hold the other person’s key for 30 days.</Text>
        </Flex>
        <InputWrapper
          className={cx(styles.inputAmountWrap)} theme="light"
          label={"Amount"}
          rightLabel={`Balance: ${formatCurrency(balanceL2.amountBTCFormatted, 0, 6)} BTC`}
        >
          <SimpleGrid gap={2} columns={3} className={styles.amountOptions}>
            {
              AMOUNT_OPTIONS.map(m => {
                return m > 0 ? (
                  <Flex direction={"column"} alignItems={"center"} className={cx(styles.amountOption, isSelectMode && Number(amountBTC) === m ? styles.isSelect :  null)} onClick={() => onSelectAmount(m.toString())}>
                    <Text fontSize={"14px"} fontWeight={500}>{m} BTC</Text>
                    <Text fontSize={"12px"} fontWeight={400} opacity={"60%"}>${formatCurrency(btcPrice.multipliedBy(m), 0, 2)}</Text>
                  </Flex>
                ) : (
                  <Field
                    id={"inputAmount"}
                    name="inputAmount"
                    component={FieldText}
                    // validate={composeValidators(required)}
                    fieldChanged={onChangeAmount}
                    disabled={submitting || estimating}
                    placeholder={`...`}
                    className={cx(styles.inputAmount)}
                    borderColor={'transparent'}
                    // maxlength={50}
                    // decimals={6}
                    // appendComp={<Text>BTC</Text>}
                  />
                )
              })
            }
          </SimpleGrid>
        </InputWrapper>
        {!allowBuy && <Text className={s.errorText}>{errorText}</Text>}

        <Flex direction={'column'} mt={8}>
          <Button
            type="submit"
            isLoading={submitting || estimating}
            isDisabled={btnDisable}
            // loadingText="Processing"
            className={styles.btnSubmit}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    );
  }
);

export default IssueForm;
