import {create} from 'zustand'

interface IAlphaThemeStore {
    mode: 'dark' | 'light',
    isAlphaPage: boolean,
    setMode: (m: 'dark' | 'light') => void,
    setIsAlphaPage: (b: boolean) => void
}

export const useAlphaThemeStore = create<IAlphaThemeStore>((set) => ({
    mode: 'light',
    isAlphaPage: false,
    setMode: (m: 'dark' | 'light'): void => set({mode: m}),
    setIsAlphaPage: (b: boolean): void => set({isAlphaPage: b}),
}))
