import { ethers } from 'ethers';

let providerPolygon: ethers.providers.JsonRpcProvider;

function useProviderPolygon() {
  if (!providerPolygon) {
    providerPolygon = new ethers.providers.JsonRpcProvider(
      'https://polygon.llamarpc.com'
    );
  }
  return providerPolygon;
}

export const createPolygonProvider = () => {
  return useProviderPolygon();
};

export default useProviderPolygon;
