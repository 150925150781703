import { isProduction } from '@/utils/commons';
import { ethers } from 'ethers';

export const ETHEREUM_RPC_URL = isProduction()
  ? 'https://ethereum.publicnode.com'
  : 'https://endpoints.omniatech.io/v1/eth/sepolia/public';

let providerEthererum: ethers.providers.JsonRpcProvider;

function useProviderEthereum() {
  if (!providerEthererum) {
    providerEthererum = new ethers.providers.JsonRpcProvider(ETHEREUM_RPC_URL);
  }
  return providerEthererum;
}

export default useProviderEthereum;
