const baseUrl = 'https://nbc-analytics-drwcpccxdq-as.a.run.app/api/v1';
const eventTrackingUrl = `${baseUrl}/event_tracking`;

const platform = {
  web: 'web',
};

const eventName = {
  pageView: 'page_view',
  componentLoadTracking: 'component_load_tracking',
};

export default {
  baseUrl,
  eventTrackingUrl,
  platform,
  eventName,
};
