import {
  OverlayContext,
  OverlayPageSetting,
  OverlayScreen,
} from '@/providers/OverlayProvider/types';
import OverlayPages from '@/modules/AlphaPWA/OverlayPages';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { WalletContext } from '@/contexts/wallet-context';

export const context = React.createContext<OverlayContext>(
  {} as OverlayContext
);

const SETTINGS: {
  [key: string]: OverlayPageSetting | undefined;
  SHARE_REFERRAL: OverlayPageSetting;
} = {
  SHARE_REFERRAL: {
    layout: false,
    // header: true,
    // navBar: true,
  },
  REFERRAL_EARNING: {
    layout: false,
  },
};

export default function OverlayProvider({ children }: { children: any }) {
  const [screen, setScreen] = useState<OverlayScreen>('');
  const [screenProps, setScreenProps] = useState<unknown>();
  const { pathname } = useRouter();
  const gaEventTracker = useAnalyticsEventTracker();
  const { addressL2 } = useContext(WalletContext);

  useEffect(() => {
    setScreen('');
    setScreenProps(undefined);
  }, [pathname]);

  const openOverlayScreen = useCallback((s: OverlayScreen, props?: any) => {
    setScreen(s);
    setScreenProps(props);
  }, []);

  const closeOverlayScreen = useCallback(() => {
    setScreen('');
    setScreenProps(undefined);
  }, []);

  const setting = useMemo((): OverlayPageSetting | undefined => {
    return SETTINGS[screen];
  }, [screen]);

  const values = useMemo(
    () => ({
      screen,
      setting,
      screenProps,
      openOverlayScreen,
      closeOverlayScreen,
    }),
    [screen]
  );

  useEffect(() => {
    const onTriggerToInvite = () => {
      const TRIGGER_INVITE = 'trigger_invite';
      const triggerInvite = JSON.parse(
        localStorage.getItem(TRIGGER_INVITE) || '{}'
      );

      const buyCount = triggerInvite?.buyCount || 0;
      const lastTimeShowInvite = triggerInvite?.lastTimeShowInvite || 0;
      const haveToShow = triggerInvite?.haveToShow || null;

      if (haveToShow) {
        setTimeout(() => {
          openOverlayScreen('SHARE_REFERRAL', JSON.parse(haveToShow || {}));
          gaEventTracker(GameFiActions.PopupReferralOpenModal, addressL2);
        }, 1200);
        localStorage.setItem(
          TRIGGER_INVITE,
          JSON.stringify({
            lastTimeShowInvite,
            buyCount,
            haveToShow: null,
          })
        );
      }
    };
    onTriggerToInvite();
  }, [pathname]);

  return (
    <context.Provider value={values}>
      {children}
      <OverlayPages />
    </context.Provider>
  );
}

export const useOverlay = () => {
  return useContext(context);
};
