import React, { useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

type State = 'Active' | 'Idle';

const context = React.createContext<{
  state: State;
}>({} as any);

export default function UserIdle({ children }: { children: any }) {
  const [state, setState] = useState<State>('Active');

  useIdleTimer({
    onActive: () => {
      setState('Active');
      window.userState = 'Active';
    },
    onAction: () => {
      //
    },
    onIdle: () => {
      setState('Idle');
      window.userState = 'Idle';
    },
    timeout: 10_000,
    throttle: 500,
  });

  const values = useMemo(
    () => ({
      state,
    }),
    [state]
  );
  return <context.Provider value={values}>{children}</context.Provider>;
}

export const useUserIdle = () => {
  return React.useContext(context);
};
