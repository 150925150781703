import { RootState } from '@/state';
import { TokenState } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { orderBy, uniqBy } from 'lodash';
import { LIST_TOKEN_DEAULT_DEPOSIT, TcLayerNetwork } from './constants';
import {
  getTokenFollowListIDsSelector,
  getTokenFollowSetIDsSelector,
} from '../wallets/selector';
import { getCurrentFilterNetworkSelector } from '../networks/selector';

export const getTokenReducerSelector = (state: RootState): TokenState =>
  state.tokens;

export const getTokenPriceMapSelector = createSelector(
  getTokenReducerSelector,
  reducer => reducer.tokenPriceMap
);

// ------------------------------------------------------------------------------
// Token Deposit Selector
// ------------------------------------------------------------------------------
export const getTokenListSelector = createSelector(
  getTokenReducerSelector,
  reducer => reducer?.tokenList || []
);

export const getTokenListUniqBySymbolSelector = createSelector(
  getTokenListSelector,
  tokenList => {
    let listData = uniqBy(tokenList, item => item.symbol?.toUpperCase());

    listData = listData.filter(
      token =>
        !LIST_TOKEN_DEAULT_DEPOSIT.includes(token.tcTokenID) &&
        token.status === 1
    );

    return listData;
  }
);

export const getTokenBySymbol = createSelector(
  getTokenListSelector,
  tokenList => (symbol: string) => {
    const tokenFinded = tokenList.find(
      token => token.symbol?.toUpperCase() === symbol?.toUpperCase()
    );
    return tokenFinded;
  }
);

export const getTokenByTcTokenIDSelector = createSelector(
  getTokenListSelector,
  tokenList => (tcTokenID?: string) => {
    const tokenFinded = tokenList.find(
      token => token.tcTokenID?.toUpperCase() === tcTokenID?.toUpperCase()
    );
    return tokenFinded;
  }
);

// ------------------------------------------------------------------------------
// All Tokens Selector
// ------------------------------------------------------------------------------
export const getAllTokensListSelector = createSelector(
  getTokenReducerSelector,
  reducer =>
    reducer.allTokenList.filter(
      token =>
        token.tcLayer == TcLayerNetwork.NOS &&
        (token.type?.toUpperCase() === 'ERC20' ||
          token.type?.toLowerCase() === 'native') &&
        token.status === 1
    ) || []
);

export const getAllTokensCombineFollowingSelector = createSelector(
  [getAllTokensListSelector, getTokenFollowSetIDsSelector],
  (allTokens, tokensFollowingSetIDs) => {
    const result = allTokens.map(token => {
      return {
        ...token,
        isAdded: tokensFollowingSetIDs.has(token.tcTokenID),
      };
    });

    return orderBy(result, item => item.isAdded, ['desc']);
  }
);

// Combine Data
export const getTokenStateInfor = createSelector(
  [getTokenReducerSelector, getTokenListUniqBySymbolSelector],
  (reducer, tokenListUniqSymbol) => {
    const result = {
      ...reducer,
      tokenListUniqSymbol,
    };
    return result;
  }
);

// ------------------------------------------------------------------------------
// Token Multi Chains
// ------------------------------------------------------------------------------

export const getAllTokenMultiChainListSelector = createSelector(
  getTokenReducerSelector,
  reducer => reducer?.tokenMultiChainList || []
);

export const getTokenMultiChainListSelector = createSelector(
  getTokenReducerSelector,
  reducer => {
    try {
      let result = reducer?.tokenMultiChainList || [];
      result = result.filter(token => token.status == 1);
      return result;
    } catch (error) {
      console.log('[getTokenMultiChainListSelector] -- Error -- ', error);
      return [];
    }
  }
);

export const getTokenMultiChainListByNetworkFilter = createSelector(
  [getTokenMultiChainListSelector, getCurrentFilterNetworkSelector],
  (tokenMultiChainList, currentNetworkFilter) => {
    try {
      let result = [];
      if (currentNetworkFilter.id == 'All') result = tokenMultiChainList;
      else
        result = tokenMultiChainList.filter(
          token => token.networkId === currentNetworkFilter.id
        );
      return result;
    } catch (error) {
      console.log(
        '[getTokenMultiChainListByNetworkFilter] -- Error -- ',
        error
      );
      return [];
    }
  }
);

export const getTokenMultiChainDefaultList = createSelector(
  [getTokenMultiChainListSelector],
  tokenMultiChainList => {
    try {
      let result;
      result = tokenMultiChainList.filter(token => !!token.isDefault);
      return result;
    } catch (error) {
      return [];
    }
  }
);

export const getTokenMultiChainDefaultIDsList = createSelector(
  [getTokenMultiChainDefaultList],
  list => {
    try {
      return list.map(token => token.tokenId);
    } catch (error) {
      return [];
    }
  }
);

export const getListTokenMananges = createSelector(
  [getTokenMultiChainListByNetworkFilter, getTokenFollowListIDsSelector],
  (listTokenFilterByNetwork, listTokenFollowing) => {
    try {
      let result;
      result = listTokenFilterByNetwork.map(token => ({
        ...token,
        isFollowing: listTokenFollowing.includes(token.tokenId),
      }));

      return orderBy(result, token => token.isFollowing, 'desc');
    } catch (error) {
      return [];
    }
  }
);

export const getTokenByTokenAddressSelector = createSelector(
  getAllTokenMultiChainListSelector,
  list => (tokenAddress: string) => {
    try {
      return list.find(token => token.tokenId === tokenAddress);
    } catch (error) {
      console.log('[getTokenBTCMultiChainSelector] -- Error -- ', error);
      return undefined;
    }
  }
);
