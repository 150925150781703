import React from 'react';

const IcTwitter = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="20" height="20" rx="10" fill="black" />
      <path
        d="M14.2 4.5625H16.0405L12.0205 9.169L16.75 15.4375H13.0472L10.147 11.635L6.82825 15.4375H4.987L9.28675 10.51L4.75 4.5625H8.54725L11.1685 8.03725L14.2 4.5625ZM13.555 14.3335H14.575L7.99225 5.60875H6.89875L13.555 14.3335Z"
        fill="white"
      />
    </svg>
  );
};

export default IcTwitter;
