import { CONTRACT_ADDRESS } from '@/constants/contract';
import { OPENSEA_API_KEY } from '@/constants/opensea';
import { TOKEN_ADDRESS } from '@/constants/token';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CPlayerShare from '@/contracts';
import MemoryABIs from '@/contracts/abis/Memory.json';
import CContract from '@/contracts/contract';
import useOpenDepositModal from '@/hooks/useOpenDepositModal';
import { createPolygonProvider } from '@/hooks/useProviderPolygon';
import { RequestMemoryResponse } from '@/interfaces/api/memory';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import ImageContent from '@/modules/AlphaPWA/DirectMessage/Chat/MintMessageModal/ImageContent';
import TextContent from '@/modules/AlphaPWA/DirectMessage/Chat/MintMessageModal/TextContent';
import { CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS } from '@/modules/AlphaPWA/DirectMessage/constants';
import { getMessage } from '@/modules/AlphaPWA/DirectMessage/helpers';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { DMMessage, MediaFile } from '@/modules/AlphaPWA/DirectMessage/types';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import FieldText from '@/modules/AlphaPWA/Profiles/TradeKey/components/form/fieldText';
import InputWrapper from '@/modules/AlphaPWA/Profiles/TradeKey/components/form/inputWrapper';
import errorLogger from '@/services/errorLogger';
import {
  getMemoryRequest,
  getMemoryTokenIdTxHash,
  submitMemoryFeeTx,
} from '@/services/memory';
import { formatCurrency } from '@/utils';
import accountStorage from '@/utils/account.storage';
import { isProduction } from '@/utils/commons';
import { composeValidators, required } from '@/utils/form-validate';
import { isImageOrVideo } from '@/utils/media';
import { Skeleton, Spinner } from '@chakra-ui/react';
import cs from 'classnames';
import { BigNumber, Wallet, ethers } from 'ethers';
import * as opensea from 'opensea-js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import s from './styles.module.scss';

export const MINT_MESSAGE_MODAL_NAME: string = 'MINT_MESSAGE_MODAL_NAME';

interface IProps {
  onClose: () => void;
  messageId: string;
}

let seaport: opensea.OpenSeaSDK;
const createOpenseaSDK = async (
  provider: ethers.providers.JsonRpcProvider,
  wallet: Wallet
) => {
  const seaport = new opensea.OpenSeaPort(
    provider,
    {
      chain: isProduction() ? opensea.Chain.Mainnet : opensea.Chain.Polygon,
      apiKey: OPENSEA_API_KEY,
    },
    console.log,
    wallet
  );
  return seaport;
};

const getOpenseaSDK = async (
  provider: ethers.providers.JsonRpcProvider,
  wallet: Wallet
) => {
  if (!seaport) {
    seaport = await createOpenseaSDK(provider, wallet);
  }
  return seaport;
};

const getContractAddress = (isImage: boolean) => {
  if (isProduction()) {
    return isImage
      ? CONTRACT_ADDRESS.MESSAGE_CONTRACT_ADDRESS.IMAGE
      : CONTRACT_ADDRESS.MESSAGE_CONTRACT_ADDRESS.TEXT;
  }
  return isImage
    ? CONTRACT_ADDRESS.MESSAGE_CONTRACT_ADDRESS.IMAGE
    : CONTRACT_ADDRESS.MESSAGE_CONTRACT_ADDRESS.TEXT;
};

const getOpenseaContractAddress = () => {
  return CONTRACT_ADDRESS.MESSAGE_CONTRACT_ADDRESS.OPENSEA;
};

const MintMessageModal: React.FC<IProps> = ({
  onClose,
  messageId,
}): React.ReactElement => {
  const PlayerShareContract = new CPlayerShare();
  const contract = new CContract();
  const { addressL2 } = useContext(WalletContext);
  const walletProvider: IWalletContext = useContext(WalletContext);

  const [processing, setProcessing] = useState(false);
  const { activeRoom, getUserProfile, findLocalMessageById, AIProfiles } =
    useDM();
  const [selectedMessage, setSelectedMessage] = useState<DMMessage>();
  const [isDepositNeeded, setIsDepositNeeded] = useState(false);
  const [isMintingLoading, setIsMintingLoading] = useState(true);
  const [memoryRequestFee, setMemoryRequestFee] =
    useState<RequestMemoryResponse>();
  const [isMinted, setIsMinted] = useState(false);

  const [userProfile, setUserProfile] =
    useState<IGetPlayerPoolProfile | null>();
  const [tokenId, setTokenId] = useState('');
  const [isListForSale, setIsListForSale] = useState(false);
  const [listPrice, setListPrice] = useState('0.0');

  const [tokenBalanceLoading, setTokenBalanceLoading] = useState(true);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [ownerTokenProfile, setOwnerTokenProfile] =
    useState<IGetPlayerPoolProfile>();

  const {
    handleOpenTopup,
    fetchingOtp,
    loading: depositLoading,
  } = useOpenDepositModal();

  useEffect(() => {
    try {
      // silent call approve for all
      const contractAddress = getContractAddress(!!images.length);
      const provider = isProduction()
        ? contract.providerEther
        : createPolygonProvider();

      // const wallet = walletProvider.gameWallet;
      const wallet = createWallet(provider);

      if (provider && wallet) {
        setApproveForToken(provider, wallet, contractAddress);
      }
    } catch (e) {
      //
    }
  }, []);

  useEffect(() => {
    const checkBalance = async () => {
      try {
        if (
          memoryRequestFee &&
          memoryRequestFee.feeToken &&
          addressL2 &&
          walletProvider &&
          walletProvider.gameWallet
        ) {
          const balanceToken = await PlayerShareContract.getTokenBalance(
            memoryRequestFee.feeToken
          );

          setTokenBalance(Number(balanceToken));
        }
      } catch (e) {
        console.log('___________checkBalance error', e);
      } finally {
        setTokenBalanceLoading(false);
      }
    };
    checkBalance();
  }, [memoryRequestFee, walletProvider]);

  const fetchMemoryRequest = (id: string) => {
    getMemoryRequest({
      msg_id: id,
    })
      .then(res => {
        setMemoryRequestFee(res);
        if (res.mintTx) {
          setIsMinted(true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsMintingLoading(false);
      });
  };

  useEffect(() => {
    // fetch info
    fetchMemoryRequest(messageId);
  }, [messageId]);

  useEffect(() => {
    if (!selectedMessage) {
      const message = findLocalMessageById(messageId);
      setSelectedMessage(message);
    }
  }, [messageId, selectedMessage, findLocalMessageById]);

  useEffect(() => {
    if (selectedMessage && selectedMessage.from) {
      const profile = getUserProfile(selectedMessage.from);
      if (profile) {
        setUserProfile(profile);
      }
    }
  }, [selectedMessage]);

  const viewMessage = React.useMemo(() => {
    if (selectedMessage) {
      try {
        if (activeRoom) {
          const translatedMessage = getMessage(
            selectedMessage[
              CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS[activeRoom.language]
            ]
          );
          if (translatedMessage) {
            return `${translatedMessage || ''}`;
          }
        }
        return `${getMessage(selectedMessage?.content) || ''}`;
      } catch (e) {
        return `${getMessage(selectedMessage?.content) || ''}`;
      }
    } else {
      return '';
    }
  }, [selectedMessage, activeRoom]);

  const mediaUrls = React.useMemo((): MediaFile[] => {
    if (selectedMessage) {
      try {
        return selectedMessage?.mediaUrls.map(media => {
          try {
            return JSON.parse(media);
          } catch {
            return [];
          }
        });
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }, [selectedMessage?.mediaUrls]);

  const images = useMemo(
    () => mediaUrls.filter(item => isImageOrVideo(item.name) === 'image'),
    [mediaUrls]
  );

  const feeETH = useMemo(() => {
    if (memoryRequestFee) {
      return ethers.utils.formatEther(memoryRequestFee.fee);
    }
    return '0.0';
  }, [memoryRequestFee]);

  useEffect(() => {
    if (Object.values(AIProfiles).length) {
      let profile;
      if (images.length) {
        // bruce
        profile = AIProfiles[TOKEN_ADDRESS.BRUCE_TOKEN_ADDRESS.toLowerCase()];
      } else {
        // samantha
        profile =
          AIProfiles[TOKEN_ADDRESS.SAMANTHA_TOKEN_ADDRESS.toLowerCase()];
      }
      if (profile && profile.profile) {
        setOwnerTokenProfile(profile.profile);
      }
    }
  }, [AIProfiles, images]);

  const checkBalance = async () => {
    try {
      const balanceETH = await PlayerShareContract.getTokenBalanceV2(
        TOKEN_ADDRESS.ETH_ADDRESS_L2
      );
      if (BigNumber.from(balanceETH).gt(BigNumber.from(feeETH))) {
        return true;
      }
      return false;
    } catch (e) {
      //
    }
    return true;
  };

  const createWallet = (provider: any) => {
    const _password = accountStorage.getPassWord();
    const prvKey = accountStorage.getAccount(`${_password}`);
    const wallet = new Wallet(prvKey, provider);
    return wallet;
  };

  const setApproveForToken = async (
    provider: ethers.providers.Provider | ethers.providers.JsonRpcProvider,
    wallet: Wallet,
    contractAddress: string
  ) => {
    try {
      const network = await provider.getNetwork();
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN_START',
        address: addressL2,
        error: '',
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
          operator: getOpenseaContractAddress(),
          network: network,
        },
      });
      const memoryContract: ethers.Contract = contract
        .getContract(contractAddress, MemoryABIs, provider)
        .connect(wallet);

      const isApproved = await memoryContract.isApprovedForAll(
        wallet.address,
        getOpenseaContractAddress()
      );

      console.log('_____________setApproveForToken', {
        isApproved,
        address: wallet.address,
        contractAddress,
        operator: getOpenseaContractAddress(),
      });

      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN_APPROVAL',
        address: addressL2,
        error: '',
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
          isApproved: isApproved,
          operator: getOpenseaContractAddress(),
        },
      });

      if (!isApproved) {
        console.log('_____________setApproveForToken start');
        console.log('_____________setApproveForToken gasEstimated start');

        const [gasPrice, gasEstimated] = await Promise.all([
          wallet.getGasPrice(),
          memoryContract.estimateGas.setApprovalForAll(
            getOpenseaContractAddress(),
            true
          ),
        ]);
        console.log(
          '_____________setApproveForToken gasEstimated end',
          gasPrice,
          gasEstimated
        );

        const newGasLimit = gasEstimated.mul(110).div(100);
        const newGasPrice = gasPrice.mul(110).div(100);

        console.log('_____________setApproveForToken gas', {
          gasEstimated,
          newGasLimit,
          newGasPrice,
        });
        const approveTx = await memoryContract!.setApprovalForAll(
          getOpenseaContractAddress(),
          true,
          {
            gasPrice: newGasPrice,
            gasLimit: newGasLimit,
          }
        );
        console.log('_____________setApproveForToken waiting', approveTx);
        errorLogger.report({
          action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN_TX',
          address: addressL2,
          error: '',
          extra: {
            walletAddress: wallet?.address,
            contractAddress: contractAddress,
            txHash: approveTx?.hash,
            approveTx,
            operator: getOpenseaContractAddress(),
            newGasLimit,
          },
        });
        await approveTx.wait();
      }
    } catch (e) {
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN_ERROR',
        address: addressL2,
        error: JSON.stringify(e),
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
          operator: getOpenseaContractAddress(),
        },
      });
      throw e;
    } finally {
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN_END',
        address: addressL2,
        error: '',
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
          operator: getOpenseaContractAddress(),
        },
      });
    }
  };

  const listingForSale = async (
    provider: ethers.providers.JsonRpcProvider,
    wallet: Wallet,
    contractAddress: string
  ) => {
    try {
      console.log('_____________listingForSale');
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE_START',
        address: addressL2,
        error: '',
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
        },
      });
      if (wallet && provider) {
        errorLogger.report({
          action: 'MINT_MESSAGE__LISTING_FOR_SALE__SET_APPROVE_TOKEN',
          address: addressL2,
          error: '',
          extra: {
            walletAddress: wallet?.address,
            contractAddress: contractAddress,
          },
        });
        console.log('_____________setApproveForToken');
        await setApproveForToken(provider, wallet, contractAddress);
        console.log('_____________setApproveForToken done');

        console.log('_____________getOpenseaSDK');
        errorLogger.report({
          action: 'MINT_MESSAGE__LISTING_FOR_SALE__CONNECT_OPEN_SEA',
          address: addressL2,
          error: '',
          extra: {
            walletAddress: wallet?.address,
            contractAddress: contractAddress,
          },
        });
        const openseaSKD = await getOpenseaSDK(provider, wallet);
        console.log('_____________getOpenseaSDK done');

        const payload = {
          asset: {
            tokenId: tokenId,
            tokenAddress: contractAddress,
          },
          accountAddress: wallet.address,
          startAmount: Number(listPrice),
        };
        errorLogger.report({
          action: 'MINT_MESSAGE__LISTING_FOR_SALE__CONNECT_OPEN_SEA_START',
          address: addressL2,
          error: '',
          extra: {
            walletAddress: wallet?.address,
            contractAddress: contractAddress,
            payload,
          },
        });

        console.log('_____________createListing', payload);
        await openseaSKD.createListing(payload);
        console.log('_____________createListing done');
        errorLogger.report({
          action: 'MINT_MESSAGE__LISTING_FOR_SALE__CONNECT_OPEN_SEA_SUCCEED',
          address: addressL2,
          error: '',
          extra: {
            walletAddress: wallet?.address,
            contractAddress: contractAddress,
            payload,
          },
        });
      }
      console.log('_____________listingForSale done');
    } catch (e) {
      console.log('_____________listingForSale error', e);
      toast.error('Cannot listing the collection on opensea.');
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE_FAILED',
        address: addressL2,
        error: JSON.stringify(e),
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
        },
      });
      throw e;
    } finally {
      errorLogger.report({
        action: 'MINT_MESSAGE__LISTING_FOR_SALE_END',
        address: addressL2,
        error: '',
        extra: {
          walletAddress: wallet?.address,
          contractAddress: contractAddress,
        },
      });
    }
  };

  const submitTransactionHash = async (txHash: string) => {
    const submitResult = await submitMemoryFeeTx({
      txhash: txHash,
      msg_id: messageId,
    });

    if (submitResult.txhash) {
      const tokenid = await getMemoryTokenIdTxHash(submitResult.txhash);
      setTokenId(tokenid);
    } else {
      toast.success(
        `Your nft is minting. It's expected to take a few seconds.`
      );
    }

    setIsListForSale(true);
  };

  const handleMint = async () => {
    try {
      if (processing) {
        return;
      }
      setProcessing(true);

      if (!addressL2 || !walletProvider.gameWallet) {
        return;
      }
      if (!memoryRequestFee) {
        return;
      }

      if (memoryRequestFee.paymentTx) {
        await submitTransactionHash(memoryRequestFee.paymentTx);
      } else {
        const isBalanceValid = checkBalance();
        if (!isBalanceValid) {
          toast.error('Inefficient ETH balance');
          setIsDepositNeeded(true);
          return;
        }

        // recall request mint if have someone minting/minted
        const requestMint = await getMemoryRequest({
          msg_id: messageId,
        });

        if (requestMint.mintTx) {
          fetchMemoryRequest(messageId);
          toast.error('This object was minted by someone.');
          return;
        }

        const txFee = await contract
          .getERC20Contract(memoryRequestFee.feeToken)
          .connect(walletProvider.gameWallet)
          .transfer(memoryRequestFee?.feeAddress, memoryRequestFee.fee);

        await txFee.wait();
        if (!txFee?.hash) {
          toast.error('Transaction not found.');
          return;
        }
        await submitTransactionHash(txFee?.hash);
      }
    } catch (e) {
      fetchMemoryRequest(messageId);
      console.log('handleMint error', e);
      toast.error('Something went wrong. Please try again later.');
      errorLogger.report({
        action: 'MINT_MESSAGE__MINTING_FAILED',
        address: addressL2,
        error: JSON.stringify(e),
        extra: {
          walletAddress: walletProvider.gameWallet?.address,
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleListForSale = async () => {
    try {
      if (!Number(listPrice)) {
        return;
      }
      if (processing) {
        return;
      }
      setProcessing(true);

      const contractAddress = getContractAddress(!!images.length);
      const provider = isProduction()
        ? contract.providerEther
        : createPolygonProvider();

      const wallet = createWallet(provider);

      if (provider && wallet) {
        await listingForSale(
          provider as ethers.providers.JsonRpcProvider,
          wallet,
          contractAddress
        );
      }

      onClose();
    } catch (e) {
      console.log('handleListForSale error', e);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setProcessing(false);
    }
  };

  const renderDescription = () => {
    if (images.length) {
      return `This image, generated entirely from your description, is one-of-a-kind and made only for you. It's an original keepsake worth preserving.`;
    }
    return `Treasure the precious moments shared with your fellow circle members.
    Capture and cherish these chats, they're priceless memories worth
    preserving.`;
  };

  const renderMintType = () => {
    let type = '';
    if (images.length) {
      type = 'image';
    } else {
      type = 'message';
    }

    return `${
      userProfile?.twitterName || userProfile?.twitterUsername
    }'s ${type}`;
  };

  const onBaseFieldChange = (value: string) => {
    setListPrice(value);
  };

  if (isDepositNeeded) {
    return (
      <div className={s.deposit}>
        <h2 className={s.title}>Error</h2>
        <span className={s.description}>You don’t have enough ETH</span>
        <div className={s.mintActions}>
          <button
            className={s.mintActionsMint}
            onClick={() => {
              if (fetchingOtp || depositLoading) {
                return;
              } else {
                handleOpenTopup();
                onClose();
              }
            }}
            disabled={fetchingOtp || depositLoading}
          >
            {fetchingOtp || depositLoading ? (
              <Spinner
                size={'sm'}
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
              />
            ) : (
              'Deposit'
            )}
          </button>
        </div>
      </div>
    );
  }

  if (isListForSale && tokenId) {
    // https://www.figma.com/file/cQ0bATiDwKXyb7x73pdCUr/Alpha?type=design&node-id=22967-18466&mode=design&t=rnDNs6KNiMUpHd0l-4
    return (
      <Form
        onSubmit={() => {
          //
        }}
        initialValues={{
          inputAmount: '',
        }}
      >
        {() => (
          <div className={s.mintMessageModal}>
            <h2 className={s.title}>Mint successfully</h2>

            {processing && (
              <div className={s.loading}>
                <Spinner />
              </div>
            )}

            <span className={s.description}>{renderDescription()}</span>
            {images.length ? (
              <ImageContent url={images[0].url} />
            ) : (
              <TextContent text={viewMessage} />
            )}
            <div className={s.seperator}></div>

            <div className={s.listingOpenseaMessage}>
              Congratulations! You can now list the NFT on your OpenSea account
              and keep the profits.
            </div>
            <form className={s.mintEstimation}>
              <div className={s.mintEstimationField}>
                <div className={s.mintEstimationField__name}>
                  <span className={s.mintEstimationValue}>Set a price</span>
                  <span className={s.mintEstimationValue}>{`Floor: 0.01 ${
                    isProduction() ? 'ETH' : 'MATIC'
                  }`}</span>
                </div>
                <div>
                  <InputWrapper>
                    <Field
                      name="inputAmount"
                      component={FieldText}
                      placeholder={`0.0`}
                      borderColor={'transparent'}
                      inputType="number"
                      decimals={6}
                      validate={composeValidators(required)}
                      fieldChanged={onBaseFieldChange}
                      appendComp={
                        <span>{isProduction() ? 'ETH' : 'MATIC'}</span>
                      }
                    />
                  </InputWrapper>
                </div>
              </div>
            </form>

            <div className={s.mintActions}>
              <button
                className={s.mintActionsCancel}
                onClick={() => {
                  onClose();
                }}
                disabled={processing}
              >
                Cancel
              </button>

              <button
                className={s.mintActionsMint}
                onClick={handleListForSale}
                disabled={processing}
              >
                List for sale
              </button>
            </div>
          </div>
        )}
      </Form>
    );
  }

  return (
    <div className={s.mintMessageModal}>
      <h2 className={s.title}>
        Mint <span>{renderMintType()}</span>
      </h2>

      {processing && (
        <div className={s.loading}>
          <Spinner />
        </div>
      )}

      <span className={s.description}>{renderDescription()}</span>
      {images.length ? (
        <ImageContent url={images[0].url} />
      ) : (
        <TextContent text={viewMessage} />
      )}

      {!!memoryRequestFee && (
        <>
          <div className={s.seperator}></div>
          <div className={s.mintEstimation}>
            <span className={s.mintEstimationLabel}>Estimate fee</span>
            <span className={s.mintEstimationValue}>
              {formatCurrency(feeETH, 0, 0)}{' '}
              {ownerTokenProfile ? (
                <Avatar
                  className={s.avatar}
                  url={
                    ownerTokenProfile?.twitterAvatar ||
                    ownerTokenProfile?.avatar ||
                    ''
                  }
                  address={ownerTokenProfile?.address || ''}
                  name={
                    ownerTokenProfile?.twitterName ||
                    ownerTokenProfile?.twitterUsername ||
                    ''
                  }
                  width={14}
                />
              ) : (
                <>{memoryRequestFee?.symbol || ''}</>
              )}
            </span>
          </div>
          <div className={s.seperator}></div>
          <div className={s.tokenBalance}>
            Available balance:{' '}
            {tokenBalanceLoading ? (
              <>
                <Skeleton h="12px" w="100px" isLoaded={!tokenBalanceLoading}>
                  <></>
                </Skeleton>
              </>
            ) : (
              <>{formatCurrency(tokenBalance, 0, 0)}</>
            )}{' '}
            {/* <span className={s.ownerName}>{ownerTokenProfile?.twitterName}</span>{' '} */}
            <span className={s.ownerName}>
              {ownerTokenProfile ? (
                <Avatar
                  className={s.avatar}
                  url={
                    ownerTokenProfile?.twitterAvatar ||
                    ownerTokenProfile?.avatar ||
                    ''
                  }
                  address={ownerTokenProfile?.address || ''}
                  name={
                    ownerTokenProfile?.twitterName ||
                    ownerTokenProfile?.twitterUsername ||
                    ''
                  }
                  width={14}
                />
              ) : (
                <>{memoryRequestFee?.symbol || ''}</>
              )}
            </span>{' '}
            tokens
          </div>
        </>
      )}

      {!memoryRequestFee && ownerTokenProfile && (
        <>
          <div className={s.seperator}></div>
          <div className={s.reverseError__title}>
            Notice: Image Temporarily Reserved
          </div>
          <div className={s.reverseError__description}>
            This image is currently in its 15-minute exclusive minting period.
            Check back soon—it might become available for minting shortly!
          </div>
        </>
      )}

      <div className={s.mintActions}>
        <button
          className={s.mintActionsCancel}
          onClick={() => {
            onClose();
          }}
          disabled={processing}
        >
          Cancel
        </button>
        {isMintingLoading ? (
          <>
            <button className={cs(s.mintActionsMint, s.mintActionsMinted)}>
              Minting
            </button>
          </>
        ) : (
          <>
            {isMinted ? (
              <button className={s.mintActionsMinted} disabled={processing}>
                Minted
              </button>
            ) : (
              <>
                {!!memoryRequestFee ? (
                  <button
                    className={cs(
                      s.mintActionsMint,
                      !memoryRequestFee || processing ? s.mintActionsMinted : ''
                    )}
                    onClick={handleMint}
                    disabled={!memoryRequestFee || processing}
                  >
                    Mint
                  </button>
                ) : (
                  <button
                    className={cs(
                      s.mintActionsMint,
                      !memoryRequestFee || processing ? s.mintActionsMinted : ''
                    )}
                    disabled={!memoryRequestFee || processing}
                  >
                    Waiting
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(MintMessageModal);
