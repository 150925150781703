import React, { useContext, useMemo } from 'react';
import s from './PrizeInfo.module.scss';
import { TournamentContext } from '@/contexts/tournament-context';
import Web3 from 'web3';

export const PRIZE_INFO_MODAL_ID = 'PRIZE_INFO_MODAL_ID';

const PrizeInfoModel: React.FunctionComponent<{}> = ({}) => {
  const { circleTournamentInfo } = useContext(TournamentContext);

  const price = useMemo(() => {
    return Web3.utils.fromWei(
      circleTournamentInfo?.ticketPrice ?? '100000000000000',
      'ether'
    );
  }, [circleTournamentInfo]);

  const fee = useMemo(() => {
    return circleTournamentInfo?.feePercentage ?? 10;
  }, [circleTournamentInfo]);

  const additionalDuration = useMemo(() => {
    return (
      parseInt(circleTournamentInfo?.additionalDuration ?? '0') / 60
    ).toFixed(0);
  }, [circleTournamentInfo]);

  return (
    <div className={s.wrapper}>
      <div className={s.titleHeader}>Tournament Regulations</div>

      <div className={s.contentContainer}>
        {/* <p className={s.title}>Tournament Regulations:</p> */}
        <p className={s.content}>
          <span style={{ fontSize: '4px' }}>⚪️</span> The circle's owner can
          seed an initial reward amount.
        </p>

        <p className={s.content}>
          <span style={{ fontSize: '4px' }}>⚪️</span> Each participant needs to
          pay an entry fee to participate. This sum will be added to the
          tournament's prize pool.
        </p>

        <p className={s.content}>
          <span style={{ fontSize: '4px' }}>⚪️</span> The player with the
          highest score receives the entire pot, minus a 20% fee.
        </p>

        <p className={s.content}>
          <span style={{ fontSize: '4px' }}>⚪️</span> The prize pool is shared
          among the top half of the players with the highest scores, minus a 20%
          fee.
        </p>

        <p className={s.content}>
          <span style={{ fontSize: '4px' }}>⚪️</span> Duration: 1 hour.
        </p>
      </div>
    </div>
  );
};

export default React.memo(PrizeInfoModel);
