import AppLoading from '@/components/AppLoading';
import s from './styles.module.scss';

export default function PageLoading() {
  return (
    <>
      <div className={s.container}>
        <AppLoading />
      </div>
    </>
  );
}
