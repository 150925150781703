import { ROUTE_PATH } from "./constants/route-path";

export function isServiceWorkerSupported(): boolean {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return false;
  }

  return 'serviceWorker' in window.navigator;
}

export function register(): void {
  if (isServiceWorkerSupported()) {
    navigator.serviceWorker
      .register('/sw.js', {
        scope: ROUTE_PATH.ALPHA_MOBILE
      })
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.log('Service Worker registration failed:', error);
      });
  }
}

export function unregister(): void {
  if (isServiceWorkerSupported()) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error('Service Worker unregistration failed:', error);
      });
  }
}
