/* eslint-disable import/no-anonymous-default-export */
import connection from './connection/reducer';
import user from './user/reducer';
import wallets from './wallets/reducer';
import modal from '@/state/modal';
import common from '@/state/common';
import notification from '@/state/notification/reducer';
import liquidity from '@/state/liquidity';
import passToken from '@/state/passToken';
import tokens from './tokens/reducer';
import affiliateToken from '@/state/token/affiliate';
import networks from './networks/reducer';
import uniswapToken from '@/state/token/uniswap';
import sendForm from '@/modules/AlphaPWA/Profile/ProfileV2/SendFormModal/state/reducer';
import alias from '@/state/alias/reducer';

export default {
  user,
  wallets,
  connection,
  modal,
  common,
  notification,
  liquidity,
  passToken,
  tokens,
  affiliateToken,
  networks,
  uniswapToken,
  sendForm,
  alias,
};
