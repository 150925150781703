import { useEffect, useState } from 'react';
import s from './style.module.scss';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import useUserToken from '@/modules/AlphaPWA/Games/hooks/useUserToken';
import { UserByTwitter } from '@/interfaces/api/player-share';
import IcCoin from '@/modules/AlphaPWA/PassToken/Components/icCoin';
import { formatCurrency } from '@/utils';
import cs from 'classnames';
import TokenIcon from '@/modules/AlphaPWA/Tokens/img/TokenIcon';
import { TOKEN_ADDRESS } from '@/constants/token';
import { IcBtc } from '@/modules/AlphaPWA/Profile/svgs/ic-btc';
import { SkeletonCircle } from '@chakra-ui/react';
interface TokenDisplayProps {
  amount: string | number;
  tribeToken?: string;
  prefix?: string;
  customClass?: string;
  iconSize?: number;
  customAvatarClass?: string;
}

const TokenDisplay = (props: TokenDisplayProps) => {
  const {
    amount,
    tribeToken = TOKEN_ADDRESS.BTC_ADDRESS_L2,
    prefix,
    customClass,
    iconSize = 12,
    customAvatarClass,
  } = props;
  const { getUserInfoByToken } = useUserToken();
  const [isAvatarLoaded, setIsAvatarLoaded] = useState<boolean>(false);

  const [userByTwitter, setUserByTwitter] = useState<
    UserByTwitter | undefined
  >();

  useEffect(() => {
    fetchUserInfo();
  }, [tribeToken]);

  const fetchUserInfo = async () => {
    if (!tribeToken) return;
    const res = await getUserInfoByToken(tribeToken);
    setUserByTwitter(res);
  };

  const renderTokenAmount = () => {
    if (tribeToken === TOKEN_ADDRESS.BTC_ADDRESS_L2) {
      return (
        <>
          {formatCurrency(amount, 0, 0)}
          {' BTC'}
        </>
      );
    }

    return (
      <>
        {formatCurrency(amount, 0, 0)}
        <SkeletonCircle
          isLoaded={isAvatarLoaded}
          className={s.avatarWrapper}
          width={`${iconSize}px`}
          height={`${iconSize}px`}
        >
          <Avatar
            className={
              customAvatarClass ? cs(s.avatar, customAvatarClass) : s.avatar
            }
            width={iconSize}
            // url={getUrlAvatarTwitter(userByTwitter?.twitterAvatar as string)}
            url={userByTwitter?.twitterAvatar as string}
            onLoaded={() => setIsAvatarLoaded(true)}
          />
        </SkeletonCircle>
        {/* <div className={s.coin}>
          <TokenIcon />
        </div> */}
      </>
    );
  };

  return (
    <div className={customClass ? cs(s.container, customClass) : s.container}>
      {prefix}
      {renderTokenAmount()}
    </div>
  );
};

export default TokenDisplay;
