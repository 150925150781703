export const getOrigin = (str: string) =>
  str
    .replace(/.*\/\//, '')
    .replace(/:.+/, '')
    .replace(/\/.+/, '');

export const getOriginRoot = (str: string) => {
  const originFull = getOrigin(str);
  if (!originFull.includes('.')) {
    return str;
  }
  const originFullChunks = originFull.split('.');
  const originRoot = originFullChunks
    .slice(originFullChunks.length - 2, originFullChunks.length)
    .join('.');
  return originRoot;
};

export function getDomainFromUrl(inputUrl: string) {
  try {
    const url = new URL(inputUrl);
    return url.hostname;
  } catch (e) {
    return inputUrl;
  }
}
