import px2rem from '@/utils/px2rem';
import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleDialog = defineStyle(props => {
  const { isFullHeight } = props;

  return {
    ...(isFullHeight && {
      height:
        'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
    }),
    zIndex: 'modal',
    maxH: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
    color: 'inherit',
    bg: '#212121',
    boxShadow: '#212121',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingBottom: 'env(safe-area-inset-bottom)',
    overflow: 'auto',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)'
  };
});

const baseStyleHeader = defineStyle({
  fontSize: px2rem(26),
  textAlign: 'center',
});

const baseStyleCloseButton = defineStyle({
  top: '12px',
  right: '12px',
  width: '30px',
  height: '30px',
  svg: {
    fontSize: '14px',
    opacity: 0.7,
  },
});

const baseStyle = definePartsStyle(props => ({
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  //   body: baseStyleBody,
  //   footer: baseStyleFooter,
}));

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
});
