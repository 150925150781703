import useUserInfoContractSigner from '@/hooks/useUserInfoContractSigner';
import { TransactionResponse } from '@ethersproject/abstract-provider';

interface ISetNameParams {
  name: string;
}

interface ISetAvatarParams {
  b: any;
}

const useUserInfoOperation = () => {
  const userContractSigner = useUserInfoContractSigner();

  const onSetName = async (
    params: ISetNameParams
  ): Promise<TransactionResponse> => {
    if (!userContractSigner) {
      throw new Error('account is not ready.');
    }
    const tx = (await userContractSigner.setName(
      params.name
    )) as TransactionResponse;
    await tx.wait();
    return tx;
  };

  const onSetAvatar = async (
    params: ISetAvatarParams
  ): Promise<TransactionResponse> => {
    if (!userContractSigner) {
      throw new Error('account is not ready.');
    }
    const tx = (await userContractSigner.setPfp(
      params.b
    )) as TransactionResponse;
    await tx.wait();
    return tx;
  };

  return {
    onSetName,
    onSetAvatar,
  };
};

export default useUserInfoOperation;
