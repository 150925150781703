import React from 'react';

const IcSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.4995 16.5C11.2875 16.5 12.0677 16.3448 12.7956 16.0433C13.5236 15.7418 14.185 15.2998 14.7422 14.7427C15.2993 14.1855 15.7413 13.5241 16.0428 12.7961C16.3443 12.0682 16.4995 11.2879 16.4995 10.5C16.4995 9.71208 16.3443 8.93187 16.0428 8.20391C15.7413 7.47596 15.2993 6.81453 14.7422 6.25737C14.185 5.70022 13.5236 5.25827 12.7956 4.95674C12.0677 4.65521 11.2875 4.50002 10.4995 4.50002C8.90823 4.50002 7.3821 5.13216 6.25689 6.25737C5.13167 7.38259 4.49953 8.90872 4.49953 10.5C4.49953 12.0913 5.13167 13.6174 6.25689 14.7427C7.3821 15.8679 8.90823 16.5 10.4995 16.5ZM16.8195 15.406L20.3995 18.986C20.495 19.0783 20.5711 19.1887 20.6234 19.3108C20.6757 19.4328 20.7032 19.564 20.7043 19.6968C20.7053 19.8296 20.6799 19.9613 20.6296 20.0841C20.5792 20.207 20.5049 20.3186 20.4109 20.4124C20.3169 20.5062 20.2052 20.5804 20.0823 20.6306C19.9594 20.6808 19.8277 20.706 19.6949 20.7048C19.5621 20.7035 19.4309 20.6758 19.309 20.6233C19.187 20.5708 19.0767 20.4946 18.9845 20.399L15.4045 16.819C13.797 18.0669 11.7743 18.6552 9.74828 18.4642C7.72224 18.2732 5.84513 17.3173 4.49908 15.7911C3.15302 14.2648 2.43921 12.283 2.50295 10.2489C2.56669 8.21492 3.4032 6.28164 4.84217 4.84266C6.28115 3.40368 8.21443 2.56718 10.2485 2.50344C12.2825 2.4397 14.2643 3.15351 15.7906 4.49957C17.3168 5.84562 18.2727 7.72273 18.4637 9.74877C18.6547 11.7748 18.0664 13.7975 16.8185 15.405L16.8195 15.406Z"
        fill="white"
      />
    </svg>
  );
};

export default IcSearch;
