import { Button } from '@chakra-ui/react';
import React from 'react';
import { OptionType } from '../Topup.types';
import s from './styles.module.scss';

type Props = {
  item: OptionType;
  onClickItem: (item: OptionType) => void;
};

const DepositItem = (props: Props) => {
  const { item, onClickItem } = props;
  return (
    <Button
      key={item.value}
      className={s.button}
      onClick={() => onClickItem(item)}
      width="100%"
    >
      <img src={item.icon} />
      {item.name}
    </Button>
  );
};

export default React.memo(DepositItem);
