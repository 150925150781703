import errorLogger from '@/services/errorLogger';
import accountStorage from '@/utils/account.storage';
import Logger from '@/utils/persistor/Logger';

const parseError = (info: any) => {
  try {
    return JSON.stringify(info, ['message', 'arguments', 'type', 'name']);
  } catch (e) {
    try {
      return JSON.stringify(info);
    } catch (e) {
      return info;
    }
  }
};

class Persistor {
  protected dbName: string;
  protected database?: IDBDatabase;
  protected collectionName: string;

  private retryCreateConnectionInstance?: ReturnType<typeof setTimeout>;

  constructor(
    db: string,
    collection: string,
    callback?: (database: any) => void
  ) {
    this.dbName = db;
    this.collectionName = collection;
    Logger.info(`Create ${db} database instance`);
    this.connect(callback);
  }

  private sentLogs: Record<string, boolean> = {};
  protected sendErrorLog = (error: string, info: any) => {
    try {
      const infoStr = parseError(info);
      if (!this.sentLogs[infoStr]) {
        this.sentLogs[infoStr] = true;

        Logger.error(error, info);
        const address = accountStorage.getAddress();
        if (address) {
          errorLogger.report({
            action: errorLogger.ERROR_PERSISTOR_LOGGER_TYPE.CHAT_ROOM,
            address: address,
            error: error,
            info: infoStr,
            extra: {
              database: this.dbName,
              collection: this.collectionName,
            },
          });
        }
      }
    } catch (e) {
      //
    }
  };

  private retryCreateConnection(
    from: string,
    callback?: (database: any) => void
  ) {
    if (!this.retryCreateConnectionInstance) {
      this.retryCreateConnectionInstance = setTimeout(() => {
        if (this.retryCreateConnectionInstance) {
          clearTimeout(this.retryCreateConnectionInstance);
        }
        this.sendErrorLog(`Retry create connect:${from}`, {
          from,
        });
        this.connect(callback);
      }, 60 * 1000);
    }
  }

  protected connect = async (
    callback?: (database: any) => void
  ): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      if (typeof window !== 'undefined') {
        const indexedDB =
          Object(window).indexedDB ||
          Object(window).mozIndexedDB ||
          Object(window).webkitIndexedDB ||
          Object(window).msIndexedDB;

        const request = indexedDB.open(this.dbName, 1);
        request.onerror = (event: any): void => {
          reject(event);

          // Logger.error(
          //   'onerror',
          //   this.dbName,
          //   this.collectionName,
          //   request.error
          // );

          this.sendErrorLog('Cannot create persistor:onerror', request.error);
          // this.retryCreateConnection('onerror', callback);
        };

        request.onsuccess = (event: any): void => {
          this.database = event.target.result;
          resolve(event.target.result);

          const onCloseHandler = (e: any) => {
            this.database?.removeEventListener('close', onCloseHandler);
            this.database = undefined;
            this.sendErrorLog(`Connection auto close`, e);
            this.retryCreateConnection('close', callback);
          };
          this.database?.addEventListener('close', onCloseHandler);
        };
        request.onupgradeneeded = (event: any): void => {
          try {
            Logger.info(
              'onupgradeneeded',
              this.dbName,
              this.collectionName,
              event
            );
            callback && callback(event.target.result);
          } catch (e) {
            this.sendErrorLog('Cannot create persistor:onupgradeneeded', e);
          }
        };
      }
    });
  };

  private openConnect = async (): Promise<void> => {
    if (!this.database) {
      await this.connect();
    }
  };

  protected add = async (): Promise<void> => {
    await this.openConnect();
  };

  protected update = async (): Promise<void> => {
    await this.openConnect();
  };

  protected get = async (): Promise<void> => {
    await this.openConnect();
  };

  protected remove = async (): Promise<void> => {
    await this.openConnect();
  };

  protected clear = async (): Promise<void> => {
    await this.openConnect();
  };

  protected deleteDb = (): void => {
    try {
      const DBDeleteRequest = window.indexedDB.deleteDatabase(this.dbName);

      DBDeleteRequest.onerror = (): void => {
        //
      };
      DBDeleteRequest.onsuccess = (): void => {
        //
      };
    } catch (e) {
      //
    }
  };

  private sendGetCollectionLog = false;
  protected getCollection(mode: IDBTransactionMode = 'readonly') {
    try {
      const txn = this.database?.transaction(this.collectionName, mode);
      return txn?.objectStore(this.collectionName);
    } catch (e) {
      if (!this.sendGetCollectionLog) {
        this.sendGetCollectionLog = true;
        this.sendErrorLog('Cannot get collection', e);
      }
      throw e;
    }
  }
}

export default Persistor;
