import {
  BLAST_CONTRACT,
  CDN_URL_ICONS,
  MERGE_CONTRACT,
  PEPE_CONTRACT,
  SUM_21_CONTRACT,
} from '@/configs';
import { IGameType } from '@/hooks/useContentWindowMessage';
import {
  ITournamentCircleConfigItem,
  ITournamentCircleConfigItemV2,
} from '@/interfaces/api/gamefi';

export enum UnityEventType {
  U2JS_SET_DATA = 'U2JS_SET_DATA',
  U2JS_GAME_LOADED = 'U2JS_GAME_LOADED',
  U2JS_UNITY_LOADED = 'U2JS_UNITY_LOADED',
  U2JS_UNITY_ERROR = 'U2JS_UNITY_ERROR',
  U2JS_UNITY_BOOTSTRAP = 'U2JS_UNITY_BOOTSTRAP',
  U2JS_START_LOAD = 'U2JS_START_LOAD',
  U2JS_SET_BACK_BUTTON = 'U2JS_SET_BACK_BUTTON',
}

export enum UnityIFrameStatus {
  LOADING = 'LOADING',
  PLAYING = 'PLAYING',
  PENDING = 'PENDING',
  RELEASED = 'RELEASED',
}

export const WombatCombat = 'Wombat Combat';

export enum GameName {
  BITCOIN_MERGE = 'Key Merge',
  BITCOIN_M21 = '21',
  PEPE = 'Pepe Fight',
  WOMBAT = 'Wombat Combat',
  CONNECT_MEME = 'Connect Meme',
  BLAST = 'Blast',
  WORD_CHAINS = 'Word Chains',
}

export const DefaultTournamentConfig: ITournamentCircleConfigItem = {
  circleAddress: '',
  games: [
    {
      name: GameName.BITCOIN_MERGE,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: ['10000', '30000', '300000'],
      ticketPrice: '300',
    },
    {
      name: GameName.BITCOIN_M21,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: ['10000', '30000', '300000'],
      ticketPrice: '300',
    },
    {
      name: GameName.WOMBAT,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: ['10000', '30000', '300000'],
      ticketPrice: '300',
    },
    {
      name: GameName.PEPE,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: ['10000', '30000', '300000'],
      ticketPrice: '300',
    },
    {
      name: GameName.BLAST,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: ['10000', '30000', '300000'],
      ticketPrice: '300',
    },
  ],
};

export const gameNameToId = (gameName: string): IGameType => {
  switch (gameName) {
    case GameName.BITCOIN_M21:
      return IGameType.Sum21;
    case 'Connect Meme':
      return IGameType.ConnectMeme;
    case 'Pepe Fight':
      return IGameType.PepeFight;
    case GameName.BLAST:
      return IGameType.BitcoinBlast;
    case GameName.WOMBAT:
      return IGameType.PepeFight;
    default:
      return IGameType.BitcoinMerge;
  }
};

export const gameNameToContractAddress = (gameName: string): string => {
  switch (gameName) {
    case GameName.PEPE:
      return PEPE_CONTRACT;
    case GameName.WOMBAT:
      return PEPE_CONTRACT;
    case GameName.BITCOIN_M21:
      return SUM_21_CONTRACT;
    case GameName.BLAST:
      return BLAST_CONTRACT;
    default:
      return MERGE_CONTRACT;
  }
};

export const gameNameToLogo = (gameName: string): string => {
  switch (gameName) {
    case GameName.PEPE:
      return 'https://storage.googleapis.com/tc-cdn-prod/nbc/icons/ic-app-wombat.svg';
    case GameName.WOMBAT:
      return 'https://storage.googleapis.com/tc-cdn-prod/nbc/icons/ic-app-wombat.svg';
    case GameName.BITCOIN_M21:
      return 'https://storage.googleapis.com/tc-cdn-prod/nbc/icons/ic-app-btc21.svg';
    case GameName.BLAST:
      return 'https://storage.googleapis.com/tc-cdn-prod/nbc/icons/ic-app-blast.svg';
    default:
      return 'https://storage.googleapis.com/tc-cdn-prod/nbc/icons/ic-app-merge.svg';
  }
};

export const gameNameToIcon = (gameName: string): string => {
  switch (gameName) {
    case GameName.PEPE:
      return `${CDN_URL_ICONS}/ic-wombat-combat.png`;
    case GameName.WOMBAT:
      return `${CDN_URL_ICONS}/ic-wombat-combat.png`;
    case GameName.BITCOIN_M21:
      return `${CDN_URL_ICONS}/ic-bitcoin-21.png`;
    case GameName.BLAST:
      return `${CDN_URL_ICONS}/ic-blast.png`;
    default:
      return `${CDN_URL_ICONS}/ic-key-merge.png`;
  }
};

export const gameNameToSquareThumbnail = (gameName: string): string => {
  switch (gameName) {
    case GameName.PEPE:
      return 'https://cdn-dev.gamefi.garden/unity-build/pepe/metadata/square-thumbnail.jpg';
    case GameName.WOMBAT:
      return 'https://cdn-dev.gamefi.garden/unity-build/wombat/metadata/square-thumbnail.jpg';
    case GameName.BITCOIN_M21:
      return 'https://cdn-dev.gamefi.garden/unity-build/sum21/metadata/square-thumbnail.jpg';
    case GameName.BLAST:
      return 'https://cdn-dev.gamefi.garden/unity-build/match-three/metadata/square-thumbnail.jpg';
    case GameName.WORD_CHAINS:
      return 'https://storage.googleapis.com/tc-cdn-prod/games/connect-meme/icon/wordschain-square-thumbnail.jpg';
    default:
      return 'https://cdn-dev.gamefi.garden/unity-build/merge-cube/metadata/square-thumbnail.jpg';
  }
};

export enum TournamentType {
  CIRCLE = 'CIRCLE',
  TRIBE = 'TRIBE',
}

export const DefaultCircleTournamentConfigV2: ITournamentCircleConfigItemV2 = {
  circleAddress: '',
  games: [
    {
      name: GameName.BITCOIN_MERGE,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.BITCOIN_M21,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.WOMBAT,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.PEPE,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.BLAST,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
  ],
};

export const DefaultTribeTournamentConfigV2: ITournamentCircleConfigItemV2 = {
  circleAddress: '',
  games: [
    {
      name: GameName.BITCOIN_MERGE,
      initialDuration: 1800,
      additionalDuration: 900,
      initPrizeAmount: '0.001',
      ticketPrice: '0.0001',
    },
    {
      name: GameName.BITCOIN_M21,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.WOMBAT,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.PEPE,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
    {
      name: GameName.BLAST,
      initialDuration: 3600,
      additionalDuration: 900,
      initPrizeAmount: '0.01',
      ticketPrice: '0.001',
    },
  ],
};

export const tournamentUnit = 'tokens';

export const whiteCircleProd = '0x9d550307a9e12819b04b9c323950d500742afe1c';
// export const whiteCircleProd = '0x5870d7743f19225b3bb13e75a07410a462e3c84e';
