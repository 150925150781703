import { WalletError } from '@/enums/wallet-error';

export const ERROR_CODE = {
  DECRYPT: '0',
  CREATE_WALLET: '1',
  INSUFFICIENT_FUNDS_FOR_GAS: 'INSUFFICIENT_FUNDS_FOR_GAS',
  UNPREDICTABLE_GAS_LIMIT: 'UNPREDICTABLE_GAS_LIMIT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  AKF_BBP: 'AKF_BBP',
  INVALID_HASH: 'invalid hash',
  AKF_IBB: 'AKF_IBB',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  ENOUGH_BTC_BUY_TC: 'TransferHelper::transferFrom: transferFrom failed',
  AKT_IK: 'AKT_IK',
  NM_IP: 'NM_IP',
  PT_NEF: 'PT_NEF',
};

export const ERROR_MESSAGE = {
  [ERROR_CODE.DECRYPT]: {
    message: 'Incorrect password.',
    desc: 'Incorrect password.',
  },
  [ERROR_CODE.CREATE_WALLET]: {
    message: 'Create wallet error.',
    desc: 'Create wallet error.',
  },
  [ERROR_CODE.INSUFFICIENT_FUNDS_FOR_GAS]: {
    message: 'Insufficient network fee. Join our discord for further support.',
    desc: 'Insufficient network fee. Join our discord for further support.',
    // url: 'https://discord.com/invite/yNbatuGMDG',
    // linkText: 'Join our discord >>',
  },
  [ERROR_CODE.UNKNOWN_ERROR]: {
    message: 'Some thing went wrong. Try again',
    desc: 'Some thing went wrong. Try again',
    // url: 'https://discord.com/invite/yNbatuGMDG',
    // linkText: 'Join our discord >>',
  },
  [ERROR_CODE.AKF_BBP]: {
    message: "The key's price has changed. Please try again.",
    desc: "The key's price has changed. Please try again.",
  },
  [ERROR_CODE.NM_IP]: {
    message: 'Supply error.',
    desc: 'Supply error.',
    code: ERROR_CODE.NM_IP,
  },
  [ERROR_CODE['AKF_IBB']]: {
    message: `Insufficient balance to complete the transaction`,
    desc: `Insufficient balance to complete the transaction.`,
    url: 'deposit',
    linkText: 'Deposit more.',
  },
  [ERROR_CODE['ENOUGH_BTC_BUY_TC']]: {
    message: `Insufficient balance to complete the transaction`,
    desc: `Insufficient balance to complete the transaction.`,
    url: 'deposit',
    linkText: 'Deposit more.',
  },
  [ERROR_CODE['AKT_IK']]: {
    message: `Enough key balance for sell`,
    desc: `Enough key balance for sell`,
  },
  [ERROR_CODE['PT_NEF']]: {
    message: `Token has closed`,
    desc: `Token has closed`,
  },
};

class SDKError extends Error {
  message: string;
  code: string;
  desc: string;
  constructor(code: string, desc?: string) {
    super();
    const _error = ERROR_MESSAGE[code];
    this.message = `${_error.message} (${code})` || '';
    this.code = code;
    this.desc = desc || _error?.desc;
  }
  getMessage() {
    return this.message;
  }
}

const parseEtherError = (error: any) => {
  let message = '';
  const reason = Object(error)?.reason;
  if (reason && typeof reason === 'string') {
    message = reason;
  }
  const body = error?.body;
  if (body && typeof body === 'string') {
    const bodyObject = JSON.parse(body);
    if (bodyObject?.error?.message) {
      message = bodyObject?.error?.message;
    }
  }
  return message;
};

export const getErrorMessage = (error: unknown) => {
  let message = 'Something went wrong. Please try again later.';
  let desc = '';
  if (error instanceof SDKError) {
    message = error.getMessage();
    desc = error.desc + `(${error.code})`;
  } else if (error instanceof Error && error.message) {
    message = error.message;
    desc = error.message;
    const etherError = parseEtherError(error);
    if (etherError) {
      message = etherError;
      desc = etherError;
    }
  }

  return {
    message: `${message}`,
    desc: `${desc}`,
  };
};

export default SDKError;
