import React from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import sanitizeHtml from 'sanitize-html';
import {
  ALLOWED_ATTRIBUTES,
  TEXT_DIRTY_CONFIG,
} from '@/modules/AlphaPWA/DirectMessage/constants';

type IProps = {
  text: string;
};

const TextContent: React.FC<IProps> = ({ text }): React.ReactElement => {
  return (
    <div
      className={cs(s.boxMessage, s.textMessage)}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(text, TEXT_DIRTY_CONFIG),
      }}
    ></div>
  );
};

export default React.memo(TextContent);
