import { WalletContext } from '@/contexts/wallet-context';
import { getReferralCode, useReferralCode } from '@/services/referral';
import { compareString } from '@/utils';
import { CookieCSR } from '@/utils/cookie';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import localStorage from '@/utils/localstorage';
import { TWITTER_TOKEN } from '@/constants/storage-key';

const STORAGE_ITEM_KEY = 'referral_code';
const STORAGE_USED_REFERRAL_CODE = 'referral_code_used';

export default function Referral() {
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const { referral } = router.query;
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    // storage
    let interval: any;
    if (referral) {
      const isUsed = CookieCSR.getItem(STORAGE_USED_REFERRAL_CODE);
      if (!isUsed) {
        CookieCSR.setItem(STORAGE_ITEM_KEY, `${referral}`);

        interval = setInterval(() => {
          // fix the case force cleanup data
          CookieCSR.setItem(STORAGE_ITEM_KEY, `${referral}`);
        }, 2000);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [referral]);

  useEffect(() => {
    const appendQueryString = () => {
      setTimeout(() => {
        const code = CookieCSR.getItem(STORAGE_ITEM_KEY);
        const isUsed = CookieCSR.getItem(STORAGE_USED_REFERRAL_CODE);

        if (code && !isUsed) {
          if (!router.query.referral) {
            router.query.referral = code;
            router.push(router);
          }
        }
      }, 500);
    };

    appendQueryString();
  }, []);

  useEffect(() => {
    const executeReferralCode = () => {
      if (addressL2) {
        const code = CookieCSR.getItem(STORAGE_ITEM_KEY);
        const isUsed = CookieCSR.getItem(STORAGE_USED_REFERRAL_CODE);
        if (code && !isUsed) {
          getReferralCode().then(yourCode => {
            if (!compareString(yourCode, code)) {
              gaEventTracker(GameFiActions.SetReferralCodeStart, addressL2);

              useReferralCode({
                code: `${code as string}`.toLowerCase(),
              })
                .then(() => {
                  gaEventTracker(
                    GameFiActions.SetReferralCodeAutomatic,
                    addressL2
                  );
                  gaEventTracker(GameFiActions.SetReferralCode, addressL2);
                  CookieCSR.deleteItem(STORAGE_ITEM_KEY);
                  CookieCSR.setItem(STORAGE_USED_REFERRAL_CODE, 'true');
                })
                .catch(e => {
                  if (e.code === 'already_referred') {
                    CookieCSR.deleteItem(STORAGE_ITEM_KEY);
                    CookieCSR.setItem(STORAGE_USED_REFERRAL_CODE, 'true');
                  }
                });
            } else {
              CookieCSR.deleteItem(STORAGE_ITEM_KEY);
              CookieCSR.deleteItem(STORAGE_USED_REFERRAL_CODE);
            }
          });
        }
      }
    };

    const handleSetReferralCode = () => {
      const code = CookieCSR.getItem(STORAGE_ITEM_KEY);
      const token = localStorage.get(TWITTER_TOKEN);
      if (code && token) {
        executeReferralCode();
      } else {
        setTimeout(() => {
          handleSetReferralCode();
        }, 3000);
      }
    };

    handleSetReferralCode();
  }, [addressL2]);

  return <></>;
}
