import styled from 'styled-components';
import px2rem from '@/utils/px2rem';
import { Button } from '@chakra-ui/react';

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
    background-color: transparent !important;
  }
`;

const BoxKey = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${px2rem(16)} ${px2rem(20)};
  gap: ${px2rem(6)};
  border-radius: ${px2rem(8)};
  background-color: #cbc7c7;
  width: 100%;

  .key-text {
    line-break: anywhere;
  }
  .balance-text {
    font-weight: 500;
    font-size: 20px;
  }
  .row-center {
    display: flex;
    align-items: center;
    gap: 12px;
    .ic-copy {
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    }
  }
`;

const ButtonLogin = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

export { Content, BoxKey, ButtonLogin };
