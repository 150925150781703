import JamFloating from '@/modules/AlphaPWA/DirectMessage/VoiceRoom/JamFloating';
import { useAgora } from '@/providers/AgoraProvider/hooks';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import VoiceRoom from '..';
import { useDM } from '../../provider';
import s from './VoiceRoomDrawer.module.scss';

const VoiceRoomDrawerComponent = () => {
  const { setJoinedVoiceRoom, getUserProfile, setVoiceRoom } = useDM();

  const {
    leaveChannel,
    setIsDrawerOpen,
    isDrawerOpen,
    switchScreen,
    callingRoomInfo,
  } = useAgora();

  const playerPoolProfile = getUserProfile(callingRoomInfo?.owner);

  const handleLeaveVoiceRoom = () => {
    leaveChannel();
    setIsDrawerOpen(false);
    setJoinedVoiceRoom(false);
    setVoiceRoom('');
  };

  const handleBack = () => {
    switchScreen('FLOATING');
    // setIsDrawerOpen(false);
  };

  return (
    <Drawer
      isOpen={isDrawerOpen}
      placement="right"
      onClose={() => {
        //
      }}
      size={'full'}
    >
      <DrawerOverlay w="100vw" />
      <DrawerContent bg={'#212121'} w="100vw" className={s.drawer_content}>
        <DrawerHeader py="12px">
          <Flex alignItems={'center'} position="relative">
            <Box className={s.btnBack}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleBack}
              >
                <path
                  d="M15.5002 19.5L8.5 12.4998L15.5002 5.4996"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <p className={s.group_name}>
              <span>{playerPoolProfile?.twitterName}</span>
              <span>&nbsp;& Frens</span>
            </p>
          </Flex>
        </DrawerHeader>

        <DrawerBody p={0}>
          <VoiceRoom leaveVoiceRoom={handleLeaveVoiceRoom} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const VoiceRoomDrawer = () => {
  const { isDrawerOpen, callingRoomInfo } = useAgora();

  return (
    <>
      {callingRoomInfo && <JamFloating />}
      {isDrawerOpen && <VoiceRoomDrawerComponent />}
    </>
  );
};
export default VoiceRoomDrawer;
