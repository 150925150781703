import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { getPersistConfig } from 'redux-deep-persist';
import persistLocalStorage from 'redux-persist/lib/storage';
import { persistCombineReducers, persistStore } from 'redux-persist';
// import logger from 'redux-logger';

import reducer from './reducer';
import { isProduction } from '@/utils/commons';

const reducers = combineReducers(reducer);

const persistConfig = getPersistConfig({
  key: 'root',
  storage: persistLocalStorage,
  whitelist: [
    'liquidity',
    'common.swapTabIndex',
    'common.configs',
    'passToken',
    'affiliateToken',
    'uniswapTokenNew',
    'wallets.totalBTCBalanceFactory',
  ],
  rootReducer: reducers,
});

const persistedReducer = persistCombineReducers(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(isProduction() ? [] : []),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export default store;
