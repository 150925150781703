import React, { useContext } from 'react';
import * as S from './styled';
import { Formik } from 'formik';
import isNumber from 'lodash/isNumber';
import { isAddress } from 'web3-utils';
import { Input } from '@/components/Input';
import useSendTCL2 from '@/hooks/useSendTCL2';
import { WalletContext } from '@/contexts/wallet-context';
import LoginAccount from '@/components/LoginAccountL2/login.modal';
import debounce from 'lodash/debounce';
import { getErrorMessage } from '@/utils/error';
import { AssetsContext } from '@/contexts/assets-context';
import useAsyncEffect from 'use-async-effect';
import BigNumber from 'bignumber.js';
import * as format from 'tc-formatter';
import toast from 'react-hot-toast';
import { ethers } from 'ethers';
import { closeModal } from '@/state/modal';
import { useAppDispatch } from '@/state/hooks';
import { trackingWithdrawal } from '@/services/gamefi';

export const SEND_TC_L2_MODAL_KEY = 'SEND_TC_L2_MODAL_KEY';

interface IFormValue {
  toAddress: string;
  amount: string;
}

const SendTCModal = React.memo(() => {
  const { keySetL2, addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const dispatch = useAppDispatch();

  const { onSendTC, onEstimateGas: estimateGas, getGasPrice } = useSendTCL2();
  const [gasLimit, setGasLimit] = React.useState<number | undefined>(undefined);
  const [estimating, setEstimating] = React.useState(false);
  const [error, setError] = React.useState<string>('');
  const [gasPrice, setGasPrice] = React.useState<number | undefined>(0);

  const onEstimateGas = async (payload: IFormValue) => {
    try {
      if (!payload.amount || !payload.toAddress) {
        setGasLimit(undefined);
        return;
      }
      setEstimating(true);
      const gasLimit = await estimateGas({
        receiver: payload.toAddress,
        amount: payload.amount,
      });

      setGasLimit(gasLimit);
      setError('');
    } catch (error) {
      const { message } = getErrorMessage(error);
      setError(message);
    }
    setEstimating(false);
  };

  const debounceEstimateGas = React.useCallback(debounce(onEstimateGas, 300), [
    keySetL2.prvKey,
  ]);

  const onGetGasPrice = async () => {
    try {
      const gasPrice = await getGasPrice();
      setGasPrice(Number(gasPrice));
    } catch (error) {
      const { desc } = getErrorMessage(error);
      setError(desc);
    }
  };

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!values.toAddress) {
      errors.toAddress = 'Receiver wallet address is required.';
    } else if (!isAddress(values.toAddress)) {
      errors.toAddress = 'Invalid receiver wallet address.';
    }

    if (!values.amount) {
      errors.amount = 'Amount is required.';
    } else if (!isNumber(values.amount)) {
      errors.amount = 'Invalid amount. Amount must be a number.';
    } else if (parseFloat(values.amount) <= 0) {
      errors.amount = 'Invalid amount. Amount must be greater than 0.';
    }
    debounceEstimateGas(values);
    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    try {
      await onSendTC({
        amount: payload.amount,
        receiver: payload.toAddress,
        gasLimit: gasLimit,
      });
      trackingWithdrawal({
        from: addressL2 || '',
        to: payload.toAddress || '',
        amount: new BigNumber(payload.amount).multipliedBy(1e18).toFixed(),
      });
      toast.success(
        'Transaction has been created. Please wait for few seconds.'
      );
      dispatch(closeModal({ id: SEND_TC_L2_MODAL_KEY }));
    } catch (error) {
      const { desc } = getErrorMessage(error);
      toast.error(desc);
    }
  };

  const maxFee = React.useMemo(() => {
    const feeOriginal = new BigNumber(gasPrice || 0)
      .multipliedBy(gasLimit || 0)
      .multipliedBy(1.6)
      .integerValue();
    const feeText = format.formatAmount({
      originalAmount: feeOriginal.toNumber(),
      decimals: 18,
      isCeil: true,
      maxDigits: 6,
    });
    return {
      feeOriginal,
      feeText,

      gasLimit: new BigNumber(gasLimit || 0).toNumber(),
      gasLimitText: gasLimit ? `${format.number(gasLimit)}` : undefined,

      gasPrice: new BigNumber(gasPrice || 0).toNumber(),
      gasPriceText: gasPrice
        ? `${format.shorterAmount({ originalAmount: gasPrice, decimals: 9 })}`
        : undefined,
    };
  }, [gasLimit, gasPrice]);

  const getError = (inputAmount: string) => {
    if (error) return error;
    if (
      new BigNumber(balanceL2.amount).lte(
        new BigNumber(maxFee.feeOriginal).plus(inputAmount)
      )
    ) {
      return 'insufficient funds for intrinsic transaction cost';
    }
  };

  useAsyncEffect(onGetGasPrice, []);

  if (!keySetL2.prvKey) {
    return <LoginAccount isSavePass={false} />;
  }

  return (
    <S.Content>
      <Formik
        key="create"
        initialValues={{
          toAddress: '',
          amount: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const _error = getError(
            ethers.utils.parseEther(String(values.amount || '0')).toString()
          );
          return (
            <form className="form" onSubmit={handleSubmit}>
              <Input
                title="AMOUNT"
                id="amount"
                type="number"
                name="amount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
                className="input"
                placeholder="Enter TC amount."
                errorMsg={
                  _error
                    ? _error
                    : errors.amount && touched.amount
                    ? errors.amount
                    : undefined
                }
              />
              <S.Space />
              <Input
                title="RECEIVING ADDRESS"
                id="toAddress"
                type="text"
                name="toAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.toAddress}
                className="input"
                placeholder="Paste TC wallet address here."
                errorMsg={
                  errors.toAddress && touched.toAddress
                    ? errors.toAddress
                    : undefined
                }
              />
              <S.Row justify="space-between" className="row-content">
                <p>TC Balance</p>
                <p>{balanceL2.amountFormated} TC</p>
              </S.Row>
              {!!gasLimit && (
                <S.Row justify="space-between" className="row-content">
                  <p>
                    Gas <span className="sub-text">(estimated)</span>
                  </p>
                  <p>{maxFee.feeText} TC</p>
                </S.Row>
              )}
              <S.ButtonCreate
                type="submit"
                onClick={() => handleSubmit}
                isDisabled={estimating || !!_error || isSubmitting}
              >
                {isSubmitting ? 'SENDING...' : 'SEND'}
              </S.ButtonCreate>
            </form>
          );
        }}
      </Formik>
    </S.Content>
  );
});

SendTCModal.displayName = 'SendTCModal';

export default SendTCModal;
