import Invitations from '@/modules/AlphaPWA/ThreeThreeInvitations/Invitations';
import Suggestions from '@/modules/AlphaPWA/ThreeThreeInvitations/Suggestions';
import BtnOpenFAQs from '@/modules/AlphaPWA/ThreeThreeInvitations/faqs/btnOpenFaqs';
import styles from '@/modules/AlphaPWA/portfolio/styles.module.scss';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Banner from './banner';
import s from './styles.module.scss';

export const SIDE = {
  ALL: -1,
  REQUESTING: 0,
  REQUESTED: 1,
};

const KeysInvitations = () => {
  const [indexTab, setIndexTab] = useState(1);
  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (params) {
      chekRenderLayout(params);
    }
  }, [params]);

  const chekRenderLayout = async (params: any) => {
    if (params.tab) {
      setIndexTab(Number(params.tab));
    }
  };

  return (
    <Box className={s.container}>
      <Banner />
      <Tabs
        className={styles.tabContainer}
        // isFitted
        // variant='soft-rounded'
        index={indexTab}
        onChange={k => {
          setIndexTab(k);
          router.replace({
            query: { ...router.query, tab: k },
          });
        }}
      >
        <TabList>
          <Tab>Suggestions</Tab>
          <Tab>(3,3) Requests</Tab>
          <Tab>Sent</Tab>
        </TabList>
        <Box position={'absolute'} right={'20px'} top={'7px'}>
          <BtnOpenFAQs />
        </Box>
        <TabPanels>
          <TabPanel p={0}>
            <Suggestions side={SIDE.REQUESTED} />
          </TabPanel>
          <TabPanel p={0}>
            <Invitations side={SIDE.REQUESTED} />
          </TabPanel>
          <TabPanel p={0}>
            <Invitations side={SIDE.REQUESTING} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default KeysInvitations;
