import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AUTO_REMOVE_REQUEST_TIMEOUT,
  AgoraEventEnum,
  AgoraMediaEnum,
  DEFAULT_UNMUTED,
  MAXIMUM_SPEAKER_IN_ROOM,
  config,
  context,
} from './constants';
import {
  DeviceInfo,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IMicrophoneAudioTrack,
  UID,
} from 'agora-rtc-react';
import Logger from '@/providers/AgoraProvider/Logger';
import {
  ActiveChannelUser,
  AgoraChannelAuthentication,
  AgoraChannelName,
  AgoraUserInformation,
  ApproveFromSpeakerToAudience,
  EmptySpeakerEvent,
  JamScreen,
  RequestFromAudienceToSpeaker,
  VolumeType,
} from '@/providers/AgoraProvider/types';
import { getUserByAgoraId, joinCall, leaveCall } from '@/services/chat/p2p';
import { DMRoom, RoomId } from '@/modules/AlphaPWA/DirectMessage/types';
import { getUserDetailByTwitterId } from '@/services/player-share';
import { WalletContext } from '@/contexts/wallet-context';
import { useSocketEvent } from '@/providers/SocketProvider/hooks';
import { AssetsContext } from '@/contexts/assets-context';
import { CallRoleEnum, ChatRoomResponse } from '@/interfaces/api/chat';
import {
  requestToSpeakerFromAudience,
  approveFromSpeakerToAudience,
} from '@/services/chat/group';
import { uniqBy } from 'lodash';
import { compareString } from '@/utils';
import { getRoomById } from '@/modules/AlphaPWA/DirectMessage/api';

type IProps = {
  children: any;
  logger?: typeof console;
};

export default function AgoraProvider({ children, logger = console }: IProps) {
  const { addressL2 } = useContext(WalletContext);
  const { playerPoolProfile } = useContext(AssetsContext);
  const [client, setClient] = useState<IAgoraRTCClient | null>(null);

  const [localAudioTrack, setLocalAudioTrack] =
    useState<IMicrophoneAudioTrack | null>(null);
  const [uid, setUid] = useState<UID>('');
  const [callRoomId, setCallRoomId] = useState<string>();
  const [callingRoomInfo, setCallingRoomInfo] = useState<ChatRoomResponse>();
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [inCall, setInCall] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMinimize, setIsMinimize] = useState(false);

  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const [isUnmuted, setIsUnMuted] = useState<boolean>(false);
  const [channelName, setChannelName] = useState<RoomId>('');
  const [userInformation, setUserInformation] = useState<
    Record<UID, AgoraUserInformation>
  >({});

  const [activeChannelMapUsers, setActiveChannelMapUsers] = useState<
    Record<UID, ActiveChannelUser>
  >({});

  const requestSpeakerSocket = useSocketEvent<RequestFromAudienceToSpeaker>(
    'REQUEST_SPEAK',
    JSON.parse,
    true
  );

  const approveAudienceSocket = useSocketEvent<ApproveFromSpeakerToAudience>(
    'APPROVE_SPEAK',
    JSON.parse
  );

  const emptySpeakerSocket = useSocketEvent<EmptySpeakerEvent>(
    'EMPTY_SPEAKER',
    JSON.parse
  );

  const [emptySpeaker, setEmptySpeaker] = useState<
    EmptySpeakerEvent | undefined
  >(emptySpeakerSocket);

  const [requestSpeaker, setRequestSpeaker] = useState<
    RequestFromAudienceToSpeaker | undefined
  >(requestSpeakerSocket);

  const [approveAudience, setApproveAudience] = useState<
    ApproveFromSpeakerToAudience | undefined
  >(approveAudienceSocket);

  const [requestSpeakerQueue, setRequestSpeakerQueue] = useState<
    RequestFromAudienceToSpeaker[]
  >([]);

  useEffect(() => {
    if (emptySpeakerSocket) {
      setEmptySpeaker(emptySpeakerSocket);
    }
  }, [emptySpeakerSocket]);

  useEffect(() => {
    setRequestSpeaker(requestSpeakerSocket);
    if (requestSpeakerSocket) {
      setRequestSpeakerQueue(prev =>
        uniqBy([...prev, requestSpeakerSocket], 'uid')
      );
    }
  }, [requestSpeakerSocket]);

  useEffect(() => {
    setApproveAudience(approveAudienceSocket);
    if (approveAudienceSocket) {
      setRequestSpeakerQueue(prev =>
        prev.filter(i => i.uid !== approveAudienceSocket.uid)
      );
    }
  }, [approveAudienceSocket]);

  const firstRequest = useMemo(() => {
    return requestSpeakerQueue[0];
  }, [requestSpeakerQueue]);

  useEffect(() => {
    // auto remove the oldest request after AUTO_REMOVE_REQUEST_TIMEOUT milliseconds
    let timeoutInstance: ReturnType<typeof setTimeout>;
    if (firstRequest) {
      timeoutInstance = setTimeout(() => {
        setRequestSpeakerQueue(prev =>
          prev.filter(i => i.uid !== firstRequest.uid)
        );
      }, AUTO_REMOVE_REQUEST_TIMEOUT);
    }

    return () => {
      if (timeoutInstance) {
        clearTimeout(timeoutInstance);
      }
    };
  }, [firstRequest?.uid]);

  // refs
  const localAudioTrackRef = useRef(localAudioTrack);
  const userInformationRef = useRef(userInformation);
  const addressL2Ref = useRef(addressL2);
  const activeChannelMapUsersRef = useRef<Record<UID, ActiveChannelUser>>(
    activeChannelMapUsers
  );

  const eventListenersRef = useRef<Record<string | AgoraEventEnum, Function>>(
    {}
  );

  // const allSpeakerUsers = useMemo(() => {
  //   return Object.values(activeChannelMapUsers).filter(item => !!item.hasAudio);
  // }, []);

  useEffect(() => {
    if (uid && approveAudience) {
      // check audience is logger user => turn on the mic
      if (approveAudience.uid === uid) {
        createLocalTracks();
      }
    }
  }, [approveAudience, uid]);

  useEffect(() => {
    if (callRoomId) {
      // should use the global api return room in any case
      getRoomById(callRoomId).then(roomInfo => {
        if (roomInfo) {
          setCallingRoomInfo(roomInfo);
        }
      });
    } else {
      setCallingRoomInfo(undefined);
    }
  }, [callRoomId]);

  useEffect(() => {
    if (uid) {
      setActiveChannelMapUsers(prev => {
        prev[uid] = {
          ...prev[uid],
          userInfo: playerPoolProfile as any as AgoraUserInformation,
          uid,
          hasAudio: !!localAudioTrack,
        };
        return { ...prev };
      });
    }
  }, [uid, localAudioTrack, playerPoolProfile]);

  useEffect(() => {
    setActiveChannelMapUsers(prev => {
      const newState: Record<UID, ActiveChannelUser> = {};
      if (uid) {
        newState[uid] = prev[uid];
      }
      remoteUsers.forEach(remote => {
        newState[remote.uid] = {
          ...prev[remote.uid],
          uid: remote.uid,
          userInfo: userInformation[remote.uid],
          remoteUser: remote,
          hasAudio: remote.hasAudio,
        };
      });
      return { ...newState };
    });
  }, [remoteUsers, userInformation, uid]);

  useEffect(() => {
    addressL2Ref.current = addressL2;
  }, [addressL2]);

  useEffect(() => {
    userInformationRef.current = userInformation;
  }, [userInformation]);

  useEffect(() => {
    activeChannelMapUsersRef.current = activeChannelMapUsers;
  }, [activeChannelMapUsers]);

  useEffect(() => {
    if (uid) {
      getUserProfileByUid(uid);
    }
  }, [uid]);

  const getUserProfileByUid = async (uid: UID) => {
    const isExit =
      userInformationRef.current && userInformationRef.current[uid];

    if (uid && !isExit) {
      getUserByAgoraId(uid).then(user => {
        const mappingAgoraUser: Record<string, UID> = {
          [user.twitterId]: uid,
        };
        getUserDetailByTwitterId({
          address: addressL2Ref.current as string,
          twitterIds: [user.twitterId],
        }).then(profiles => {
          try {
            setUserInformation(prev => ({
              ...prev,
              ...Object.keys(profiles).reduce(
                (acc, key) => ({
                  ...acc,
                  [mappingAgoraUser[key]]: profiles[key],
                }),
                {}
              ),
            }));
          } catch (e) {
            Logger.log('getUserProfileByUid - error', e);
          }
        });
      });
    }
  };

  const addRemoteUser = (remoteUser: IAgoraRTCRemoteUser) => {
    // fetch user info
    if (remoteUser && remoteUser) {
      getUserProfileByUid(remoteUser.uid);

      setRemoteUsers(prevUsers => {
        const foundUserIndex = prevUsers.findIndex(
          u => u.uid === remoteUser.uid
        );
        if (foundUserIndex > -1) {
          prevUsers[foundUserIndex] = remoteUser;
          return [...prevUsers];
        }
        return [...prevUsers, remoteUser];
      });
    }
  };

  const registerUserPublished = () => {
    if (client) {
      const userPublishHandler = async (
        remoteUser: IAgoraRTCRemoteUser,
        mediaType: AgoraMediaEnum
      ) => {
        await client.subscribe(remoteUser, mediaType);
        Logger.info(
          AgoraEventEnum.USER_PUBLISHED,
          remoteUser,
          mediaType,
          `subscribe success`
        );
        if (mediaType === AgoraMediaEnum.AUDIO) {
          addRemoteUser(remoteUser);
          remoteUser.audioTrack?.play();
        }
      };
      eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED] =
        userPublishHandler;
      client.on(AgoraEventEnum.USER_PUBLISHED, userPublishHandler);
    }
  };

  const removeRegisterUserPublished = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_PUBLISHED,
        eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED];
    }
  };

  const registerUserUnpublished = () => {
    if (client) {
      const userUnPublishHandler = async (
        remoteUser: IAgoraRTCRemoteUser,
        mediaType: AgoraMediaEnum
      ) => {
        await client.unsubscribe(remoteUser, mediaType);
        Logger.info(AgoraEventEnum.USER_UNPUBLISHED, remoteUser, mediaType);
        if (mediaType === AgoraMediaEnum.AUDIO) {
          remoteUser.audioTrack?.stop();
        }
      };

      eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED] =
        userUnPublishHandler;
      client.on(AgoraEventEnum.USER_UNPUBLISHED, userUnPublishHandler);
    }
  };

  const removeRegisterUserUnpublished = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_UNPUBLISHED,
        eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED];
    }
  };

  const registerUserJoined = () => {
    if (client) {
      const userJoinHandler = async (remoteUser: IAgoraRTCRemoteUser) => {
        Logger.info(AgoraEventEnum.USER_JOINED, remoteUser);
        addRemoteUser(remoteUser);
      };

      eventListenersRef.current[AgoraEventEnum.USER_JOINED] = userJoinHandler;
      client.on(AgoraEventEnum.USER_JOINED, userJoinHandler);
    }
  };

  const removeRegisterUserJoined = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_JOINED,
        eventListenersRef.current[AgoraEventEnum.USER_JOINED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_JOINED];
    }
  };

  const registerUserLeft = () => {
    if (client) {
      const userJoinHandler = async (remoteUser: IAgoraRTCRemoteUser) => {
        Logger.info(AgoraEventEnum.USER_LEFT, remoteUser);
        setRemoteUsers(prevUsers => {
          return prevUsers.filter(user => user.uid !== remoteUser.uid);
        });
        setRequestSpeakerQueue(prev =>
          prev.filter(item => item.uid !== remoteUser.uid)
        );
      };

      eventListenersRef.current[AgoraEventEnum.USER_LEFT] = userJoinHandler;
      client.on(AgoraEventEnum.USER_LEFT, userJoinHandler);
    }
  };

  const removeRegisterUserLeft = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_LEFT,
        eventListenersRef.current[AgoraEventEnum.USER_LEFT]
      );
      delete eventListenersRef.current[AgoraEventEnum.USER_LEFT];
    }
  };

  const unpublishAudioTrack = useCallback(async () => {
    Logger.log('unpublishAudioTrack - start');
    try {
      if (client && localAudioTrackRef.current) {
        await client.unpublish([localAudioTrackRef.current]);
        setIsMicrophoneOn(false);
        setIsUnMuted(false);
        Logger.log('unpublishAudioTrack - succeed');
      }
    } catch (e) {
      Logger.log('unpublishAudioTrack - failed');
      // throw e;
    } finally {
      Logger.log('unpublishAudioTrack - end');
    }
  }, [client]);

  const leaveChannel = useCallback(async () => {
    try {
      Logger.log('leaveChannel - start');

      if (channelName) {
        leaveCall(channelName);
      }
      // we close the tracks to perform cleanup
      Logger.log('leaveChannel - cleanup data');
      setEmptySpeaker(undefined);
      setCallRoomId('');
      setUid('');
      unpublishAudioTrack();
      setLocalAudioTrack(null);
      setRemoteUsers([]);
      setActiveChannelMapUsers({});
      setIsMicrophoneOn(false);
      setIsUnMuted(false);
      setStart(false);
      setInCall(false);
      setChannelName('');
      setRequestSpeaker(undefined);
      setRequestSpeakerQueue([]);
      setApproveAudience(undefined);

      removeRegisterUserJoined();
      removeRegisterUserLeft();
      removeRegisterUserPublished();
      removeRegisterUserUnpublished();

      if (localAudioTrack) {
        Logger.log('leaveChannel - close track');
        localAudioTrack.close();
      }

      if (client) {
        client.removeAllListeners();
        // client.setClientRole('audience');
        await client.leave();

        Logger.log('leaveChannel - succeed');
      }
    } catch (e) {
      Logger.log('leaveChannel - failed', e);
    } finally {
      Logger.log('leaveChannel - end');
    }
  }, [channelName, client, localAudioTrack, uid]);

  useEffect(() => {
    if (emptySpeaker && callRoomId && emptySpeaker.roomId === callRoomId) {
      // force user leave call when the meeting room has no speaker
      setIsDrawerOpen(false);
      setIsMinimize(false);
      leaveChannel();
    }
  }, [leaveChannel, emptySpeaker, callRoomId]);

  useEffect(() => {
    localAudioTrackRef.current = localAudioTrack;
  }, [localAudioTrack]);

  useEffect(() => {
    if (uid && localAudioTrack) {
      publishAudioTrack();
    }
  }, [localAudioTrack, uid]);

  useEffect(() => {
    const handler = () => {
      leaveChannel();
      if (localAudioTrackRef.current) {
        localAudioTrackRef.current.close();
      }
    };
    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [leaveChannel]);

  const registerMicrophoneChanged = async () => {
    const AgoraRTC = (await import('agora-rtc-react')).default;
    AgoraRTC.on(AgoraEventEnum.MICROPHONE_CHANGED, (deviceInfo: DeviceInfo) => {
      Logger.log(AgoraEventEnum.MICROPHONE_CHANGED, deviceInfo);

      // check current microphone state
      if (localAudioTrackRef.current) {
        AgoraRTC.checkAudioTrackIsActive(localAudioTrackRef.current);
      }
    });
  };

  const createAgoraClient = async () => {
    const AgoraRTC = (await import('agora-rtc-react')).default;

    Logger.log('createAgoraClient - start');
    setClient(AgoraRTC.createClient(config));
    Logger.log('createAgoraClient - end');
  };

  const createLocalTracks = async () => {
    try {
      const AgoraRTC = (await import('agora-rtc-react')).default;

      Logger.log('createLocalTracks - start');
      // const tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      setLocalAudioTrack(audioTrack);

      setReady(true);
      Logger.log('createLocalTracks - succeed');
      return [audioTrack];
    } catch (e) {
      Logger.log('createLocalTracks - failed', e);
      throw e;
    } finally {
      Logger.log('createLocalTracks - end');
    }
  };

  useEffect(() => {
    createAgoraClient();
    registerMicrophoneChanged();
    registerUserJoined();
    registerUserLeft();
  }, []);

  useEffect(() => {
    Logger.setInstance(logger);
  }, [logger]);

  const publishAudioTrack = useCallback(async () => {
    Logger.log('publishAudioTrack - start');
    try {
      if (client && localAudioTrackRef.current) {
        await client.publish([localAudioTrackRef.current]);
        setIsMicrophoneOn(true);

        setIsUnMuted(DEFAULT_UNMUTED);

        if (!DEFAULT_UNMUTED) {
          localAudioTrackRef.current.setVolume(0);
        }

        setVolumeLevel(localAudioTrackRef.current.getVolumeLevel());
        Logger.log('publishAudioTrack - succeed');
      }
    } catch (e) {
      Logger.log('publishAudioTrack - failed', e);
      throw e;
    } finally {
      Logger.log('publishAudioTrack - end');
    }
  }, [client]);

  const init = useCallback(
    async (authentication: AgoraChannelAuthentication) => {
      Logger.log('init - start', authentication);
      try {
        if (client) {
          if (authentication.role === CallRoleEnum.SPEAKER) {
            createLocalTracks();
          }

          setRemoteUsers([]);
          setActiveChannelMapUsers({});

          // check your user
          const _uid = await client.join(
            authentication.appId,
            authentication.channelName,
            authentication.token,
            authentication.uid
          );
          setUid(_uid);
          registerUserPublished();
          registerUserUnpublished();
          registerUserJoined();
          registerUserLeft();

          setStart(true);
          Logger.log('init - join with uid ', uid);

          Logger.log('init - succeed');
        } else {
          Logger.log('init - client don`t init');
        }
      } catch (e) {
        Logger.log('init - error', e);
        throw e;
      } finally {
        Logger.log('init - end');
      }
    },
    [client]
  );

  const getChannelAuthentication = useCallback(
    async (channel: AgoraChannelName): Promise<AgoraChannelAuthentication> => {
      Logger.log('getChannelAuthentication', channel);
      const joinInfo = await joinCall(channel);
      Logger.log('getChannelAuthentication - info', joinInfo);
      return joinInfo;
    },
    []
  );

  const joinChannel = useCallback(
    async (roomId: AgoraChannelName) => {
      // if (roomId !== callRoomId) {
      //   setIsDrawerOpen(true);
      //   setIsMinimize(false);
      //   try {
      //     await leaveChannel();
      //   } catch (e) {
      //     //
      //   }
      //   try {
      //     // should check have any playing voice call =>
      //     // to stop last voice call and join new voice call
      //     setCallRoomId(roomId);
      //     setInCall(true);
      //     const authRequest = await getChannelAuthentication(roomId);
      //     setChannelName(authRequest.channelName);
      //     init(authRequest);
      //   } catch (e) {
      //     setCallRoomId('');
      //     setChannelName('');
      //     setInCall(false);
      //   }
      // } else {
      //   setIsDrawerOpen(true);
      //   setIsMinimize(false);
      // }
      if (callRoomId) {
        setIsDrawerOpen(true);
        setIsMinimize(false);
      } else {
        if (roomId !== callRoomId) {
          setIsDrawerOpen(true);
          setIsMinimize(false);
          try {
            await leaveChannel();
          } catch (e) {
            //
          }
          try {
            // should check have any playing voice call =>
            // to stop last voice call and join new voice call
            setCallRoomId(roomId);
            setInCall(true);
            const authRequest = await getChannelAuthentication(roomId);
            setChannelName(authRequest.channelName);
            init(authRequest);
          } catch (e) {
            setCallRoomId('');
            setChannelName('');
            setInCall(false);
          }
        } else {
          setIsDrawerOpen(true);
          setIsMinimize(false);
        }
      }
    },
    [init, inCall, start, localAudioTrack, leaveChannel, client, callRoomId]
  );

  const toggleMicrophoneEnable = useCallback(async () => {
    if (localAudioTrack) {
      await localAudioTrack.setEnabled(!isMicrophoneOn);
      setIsMicrophoneOn(prev => !prev);
    }
  }, [localAudioTrack, isMicrophoneOn]);

  const toggleMicrophoneMuted = useCallback(async () => {
    if (localAudioTrack) {
      if (isUnmuted) {
        localAudioTrack.setVolume(0);
      } else {
        localAudioTrack.setVolume(Math.floor(volumeLevel * 100));
      }

      setIsUnMuted(prev => !prev);
    }
  }, [localAudioTrack, isUnmuted, volumeLevel]);

  const sendRequestFromAudienceToSpeaker = useCallback(() => {
    // prevent send request if the has more speakers in room
    const allSpeakers = Object.values(activeChannelMapUsersRef.current).filter(
      item => !!item.hasAudio
    ).length;
    if (allSpeakers <= MAXIMUM_SPEAKER_IN_ROOM) {
      requestToSpeakerFromAudience(channelName);
    }
  }, [channelName]);

  const sendApproveFromSpeakerToAudience = useCallback(
    async (token: string) => {
      setRequestSpeakerQueue(prev =>
        prev.filter(i => !compareString(i.tokenAddress, token))
      );
      await approveFromSpeakerToAudience(channelName, token);
    },
    [channelName]
  );

  const sendDeclineFromSpeakerToAudience = useCallback((token: string) => {
    setRequestSpeakerQueue(prev =>
      prev.filter(i => !compareString(i.tokenAddress, token))
    );
  }, []);

  const sendMuteSpeaker = useCallback(() => {
    //
  }, []);

  useEffect(() => {
    const handler = (volumes: VolumeType[]) => {
      volumes.forEach(volume => {
        setActiveChannelMapUsers(prev => {
          prev[volume.uid] = {
            ...prev[volume.uid],
            volume: volume,
          };
          return { ...prev };
        });
      });
    };
    if (client && uid) {
      client.enableAudioVolumeIndicator();
      client.on('volume-indicator', handler);
    }

    return () => {
      if (client) {
        client.off('volume-indicator', handler);
      }
    };
  }, [client, uid]);

  const switchScreen = useCallback((type: JamScreen) => {
    if (type === 'MAIN') {
      setIsDrawerOpen(true);
      setIsMinimize(false);
    } else {
      setIsMinimize(true);
      setIsDrawerOpen(false);
    }
  }, []);

  const values = useMemo(() => {
    return {
      uid,
      inCall,
      start,
      ready,
      localAudioTrack,
      client,

      isMicrophoneOn,
      remoteUsers,

      userInformation,

      channelName,
      toggleMicrophoneEnable,
      leaveChannel,
      joinChannel,
      publishAudioTrack,
      unpublishAudioTrack,
      toggleMicrophoneMuted,
      volumeLevel,
      isUnmuted,

      requestSpeaker,
      setRequestSpeaker,
      approveAudience,
      setApproveAudience,

      requestSpeakerQueue,

      sendRequestFromAudienceToSpeaker,
      sendApproveFromSpeakerToAudience,
      sendDeclineFromSpeakerToAudience,
      sendMuteSpeaker,

      activeChannelMapUsers,

      isDrawerOpen,
      setIsDrawerOpen,
      emptySpeaker,

      callRoomId,

      isMinimize,
      setIsMinimize,

      switchScreen,
      callingRoomInfo,
    };
  }, [
    uid,
    inCall,
    start,
    ready,
    localAudioTrack,
    client,

    isMicrophoneOn,
    remoteUsers,

    userInformation,

    channelName,
    toggleMicrophoneEnable,
    leaveChannel,
    joinChannel,
    publishAudioTrack,
    unpublishAudioTrack,
    toggleMicrophoneMuted,
    volumeLevel,
    isUnmuted,

    requestSpeaker,
    setRequestSpeaker,
    approveAudience,
    setApproveAudience,

    requestSpeakerQueue,

    sendRequestFromAudienceToSpeaker,
    sendApproveFromSpeakerToAudience,
    sendDeclineFromSpeakerToAudience,
    sendMuteSpeaker,

    activeChannelMapUsers,

    isDrawerOpen,
    setIsDrawerOpen,
    emptySpeaker,

    callRoomId,

    isMinimize,
    setIsMinimize,

    switchScreen,
    callingRoomInfo,
  ]);

  return <context.Provider value={values}>{children}</context.Provider>;
}
