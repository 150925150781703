import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const context = createContext<{
  connected: boolean;
}>({} as any);

const InternetProvider = ({ children }: { children: any }) => {
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    window.addEventListener('online', function () {
      setConnected(true);
    });

    window.addEventListener('offline', function () {
      setConnected(false);
    });
  }, []);

  const values = useMemo(() => {
    return {
      connected,
    };
  }, [connected]);

  return <context.Provider value={values}>{children}</context.Provider>;
};

export default InternetProvider;

export const useInternet = () => {
  return useContext(context);
};
