import ErrorBoundary from '@/components/ErrorBoundary';
import ModalManager from '@/components/ModalManage';
import ToastOverlay from '@/components/ToastOverlay';
import ClientOnly from '@/components/Utils/ClientOnly';
import Web3Provider from '@/components/Web3Provider';
import {
  DOMAIN_URL,
  GA_TRACKING_ID,
  MICROSOFT_CLARITY_ID,
  SOCKET_DGAMES,
} from '@/configs';
import manifest from '@/constants/manifest';
import { SEO_DESCRIPTION, SEO_IMAGE, SEO_TITLE } from '@/constants/seo';
import { AssetsProvider } from '@/contexts/assets-context';
import { BitcoinAracdeProvider } from '@/contexts/bitcoin-arcade-context';
import { CurrencyProvider } from '@/contexts/currency-context';
import { GameFiProvider } from '@/contexts/game-fi-context';
import TradeKeyProvider from '@/contexts/trade-key-context';
import { WalletProvider } from '@/contexts/wallet-context';
import DMProvider from '@/modules/AlphaPWA/DirectMessage/provider';
import Referral from '@/modules/AlphaPWA/Referral';
import Hydrated from '@/modules/Hydrated';
import UserIdle from '@/modules/UserIdle';
import KeepAliveTracking from '@/modules/UserIdle/KeepAliveTracking';
import InternetProvider from '@/providers/InternetProvider';
import LanguageProvider from '@/providers/LanguageProvider';
import OverlayProvider from '@/providers/OverlayProvider';
import SocketProvider from '@/providers/SocketProvider';
import { UIProvider } from '@/providers/UiProvider';
import * as serviceWorkerRegistration from '@/serviceWorkerRegistration';
import store, { persistor } from '@/state';
import '@/styles/index.scss';
import customTheme from '@/theme';
import { getPWAFavicon } from '@/utils/pwa';
import { ChakraProvider } from '@chakra-ui/react';
import 'leaflet/dist/leaflet.css';
import type { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import 'swiper/css';
import { SwapConfigProvider } from '@/contexts/swap-configs-context';
import { PersistGate } from 'redux-persist/integration/react';
import PageLoading from '@/components/PageLoading';
import WalletUpdater from '@/state/wallets/updater';
import { clarity } from 'react-microsoft-clarity';
import { LiveGameFiProvider } from '@/contexts/live-game-fi-context';
import Fetcher from './fetcher';

ReactGA.initialize(GA_TRACKING_ID);

const montserrat = Montserrat({ subsets: ['latin'] });

export default function App({ Component, pageProps }: AppProps) {
  const { seoInfo = {} } = pageProps;
  const { title, description, image, favicon } = seoInfo;

  const router = useRouter();

  const { referral } = router.query;

  useEffect(() => {
    if (MICROSOFT_CLARITY_ID) {
      clarity.init(MICROSOFT_CLARITY_ID);
    }
  }, []);

  useEffect(() => {
    const stringManifest = JSON.stringify({
      ...manifest,
      start_url: referral
        ? `${DOMAIN_URL}/alpha?referral=${referral}`
        : `${DOMAIN_URL}/alpha`,
    });

    const blob = new Blob([stringManifest], { type: 'application/json' });

    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector('#my-manifest-placeholder')
      ?.setAttribute('href', manifestURL);

    const link = document.createElement('Link') as any;
    if (link) {
      link.rel = 'manifest';
      link.setAttribute(
        'href',
        'data:application/json;charset=8' + stringManifest
      );
    }
  }, [referral]);

  return (
    <>
      <Head>
        <title>{title ?? SEO_TITLE}</title>
        <meta property="og:title" content={title ?? SEO_TITLE} />
        <meta
          property="og:description"
          content={description ?? SEO_DESCRIPTION}
        />
        <meta property="og:image" content={image ?? SEO_IMAGE} />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content={title ?? SEO_TITLE} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content={description ?? SEO_DESCRIPTION}
        />
        <meta name="twitter:image" content={image ?? SEO_IMAGE} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/svg"
          sizes="16x16 32x32"
          href={favicon ?? getPWAFavicon()}
        />
        <link
          rel="apple-touch-icon"
          sizes="16x16 32x32"
          href={favicon ?? getPWAFavicon()}
        />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        ></meta>
        <link rel="manifest" id="my-manifest-placeholder" />

        <script type="text/javascript">
          {/* Dont move these codes - it to resolve some unknown bug for indexddb */}
          {/* https://developer.apple.com/forums/thread/681201 */}
          window.indexedDB; window.mozIndexedDB; window.webkitIndexedDB;
          window.msIndexedDB;
        </script>
      </Head>

      <style jsx global>{`
        html {
          font-family: ${montserrat.style.fontFamily};
        }
        body {
          background: #000000;
        }
      `}</style>
      <ErrorBoundary>
        <ClientOnly>
          <LanguageProvider>
            <InternetProvider>
              <UserIdle>
                <ChakraProvider theme={customTheme}>
                  <Provider store={store}>
                    <PersistGate
                      loading={<PageLoading />}
                      persistor={persistor}
                    >
                      <Web3Provider>
                        <Fetcher />
                        <WalletProvider>
                          <AssetsProvider>
                            <Referral></Referral>
                            <CurrencyProvider>
                              <SocketProvider
                                host={`${SOCKET_DGAMES}/gamefi`}
                                path=""
                              >
                                <DMProvider>
                                  <BitcoinAracdeProvider>
                                    <Hydrated>
                                      <OverlayProvider>
                                        <TradeKeyProvider>
                                          <GameFiProvider>
                                            <LiveGameFiProvider>
                                              <UIProvider>
                                                <WalletUpdater />
                                                <SwapConfigProvider>
                                                  <Component {...pageProps} />
                                                </SwapConfigProvider>
                                              </UIProvider>
                                              <ModalManager />
                                              <ToastOverlay />
                                              {/* <KeepAliveTracking /> */}
                                            </LiveGameFiProvider>
                                          </GameFiProvider>
                                        </TradeKeyProvider>
                                      </OverlayProvider>
                                    </Hydrated>
                                  </BitcoinAracdeProvider>
                                </DMProvider>
                              </SocketProvider>
                            </CurrencyProvider>
                          </AssetsProvider>
                        </WalletProvider>
                      </Web3Provider>
                    </PersistGate>
                  </Provider>
                </ChakraProvider>
              </UserIdle>
            </InternetProvider>
          </LanguageProvider>
        </ClientOnly>
      </ErrorBoundary>
    </>
  );
}

// Register the service worker
serviceWorkerRegistration.register();
