import { isProduction } from '@/utils/commons';

const OPENSEA_MESSAGE_CONTRACT_ADDRESS = isProduction()
  ? '0x1E0049783F008A0085193E00003D00cd54003c71'
  : '0x1E0049783F008A0085193E00003D00cd54003c71';

const IMAGE_MESSAGE_CONTRACT_ADDRESS = isProduction()
  ? '0x64813e3Ba26c31Fe32100bce478DBf7939f965Cc'
  : '0xd5A194ca7994f9D2B0284B061A5A10D7Dd070715';

const TEXT_MESSAGE_CONTRACT_ADDRESS = isProduction()
  ? '0x9746fC49C88244167935b3f909EA7dc5EB0083AB'
  : '0xbB02E7e018e285b5363206db528c19ce96dE5415';

const MESSAGE_CONTRACT_ADDRESS = {
  OPENSEA: OPENSEA_MESSAGE_CONTRACT_ADDRESS,
  IMAGE: IMAGE_MESSAGE_CONTRACT_ADDRESS,
  TEXT: TEXT_MESSAGE_CONTRACT_ADDRESS,
};

const CONTRACT_ADDRESS = {
  MESSAGE_CONTRACT_ADDRESS,
};

export { CONTRACT_ADDRESS };
