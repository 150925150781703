import {Flex} from "@chakra-ui/react";
import styles from './styles.module.scss';
import Content from './content';

const ModuleProfile = () => {
  return (
    <Flex className={styles.container} direction={"column"} gap={4}>
      <Content />
    </Flex>
  );
};

export default ModuleProfile;
