import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import s from './GCUserAvatar.module.scss';
import { useAgora } from '@/providers/AgoraProvider/hooks';
import { UID } from 'agora-rtc-react';
import { Skeleton } from '@chakra-ui/react';
import { UserByTwitter } from '@/interfaces/api/player-share';
import { VolumeType } from '@/providers/AgoraProvider/types';
import cs from 'classnames';

import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import TipModal, {
  TIP_MODAL_ID,
} from '@/modules/AlphaPWA/DirectMessage/Chat/Message/TipButton/TipModal';
import { WalletContext } from '@/contexts/wallet-context';
import { compareString } from '@/utils';
import IcGif from '@/modules/AlphaPWA/DirectMessage/VoiceRoom/svg/ic-gif';

type Props = {
  user?: UserByTwitter;
  uid?: UID;
  volume?: VolumeType;
  isSpeaker: boolean;
};

export const getAvatarId = (address: string) => {
  return `jam_joined_user_${address || ''}`.toLowerCase();
};

const GCUserAvatar = ({ user, uid, volume, isSpeaker }: Props) => {
  const dispatch = useAppDispatch();
  const { addressL2 } = useContext(WalletContext);
  const { callRoomId } = useAgora();
  const buttonRef = useRef<HTMLDivElement>(null);

  const isCurrentSpeaking = (volume?.level || 0) > 20;
  const isMe = addressL2 === user?.address;

  const handleCloseModal = async () => {
    dispatch(closeModal({ id: TIP_MODAL_ID }));
  };

  const handleOnClick = () => {
    if (
      user?.address &&
      callRoomId &&
      !compareString(addressL2, user?.address)
    ) {
      dispatch(
        openModal({
          id: TIP_MODAL_ID,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          hideCloseButton: false,
          render: () => {
            if (
              user?.address &&
              callRoomId &&
              !compareString(addressL2, user?.address)
            ) {
              return (
                <TipModal
                  tipToAddress={user?.address}
                  roomId={callRoomId as string}
                  onClose={handleCloseModal}
                />
              );
            }
            return <></>;
          },
        })
      );
    }
  };

  return (
    <div className={`${s.wrapper} `}>
      <div className={s.avatar_wrapper}>
        <div
          id={getAvatarId(user?.address || '')}
          className={cs(
            s.avatar,
            isSpeaker ? s.speaker : s.audience,
            isCurrentSpeaking ? s.speaking : ''
          )}
          onClick={handleOnClick}
          ref={buttonRef}
        >
          {!user && (
            <Skeleton
              height="100%"
              width="100%"
              borderRadius="50%"
              className={s.skeleton}
            ></Skeleton>
          )}
          {user && (
            <div className={s.avatar}>
              <Avatar
                url={
                  getUrlAvatarTwitter(
                    user?.twitterAvatar as string,
                    'medium',
                    Boolean(user?.twitterAvatar)
                  ) || ''
                }
                address={user?.address || ''}
                width={48}
              />
              {!isMe && (
                <div className={s.avatar_gif}>
                  <IcGif />
                </div>
              )}
            </div>
          )}
          {!!isCurrentSpeaking && (
            <div className={s.mic_on}>
              <SvgInset
                size={12}
                svgUrl={`${CDN_URL_ICONS}/ic-microphone.svg`}
              />
            </div>
          )}
          {volume?.level === 0 && isSpeaker && (
            <div className={s.mic_off}>
              <SvgInset size={18} svgUrl={`${CDN_URL_ICONS}/ic-mic-off.svg`} />
            </div>
          )}
        </div>
      </div>
      <div className={s.name_wrapper}>
        <p className={s.name}>{user?.twitterName || user?.address}</p>
      </div>
      <div className={s.user_type}>
        <p className={s.name}>{isSpeaker ? 'Speaker' : 'Audience'}</p>
      </div>
    </div>
  );
};

export default GCUserAvatar;
