import { IUniSwapToken } from './uniswap.interface';

export const TokensInGoerli: IUniSwapToken[] = [
  {
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    decimals: 18,
    symbols: 'ETH',
    name: 'ETH',
    isNative: true,
  },
  {
    address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    decimals: 18,
    symbols: 'UNI',
    name: 'UNIswap',
  },
  {
    address: '0xf54090Ff13773eF2C46cBb435BbA4c84C1a66910',
    decimals: 18,
    symbols: 'WF',
    name: 'WF',
  },
];

export const TokensInMainnet: IUniSwapToken[] = [
  {
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
    symbols: 'ETH',
    name: 'ETH',
    isNative: true,
  },
  {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    symbols: 'USDC',
    name: 'UNICoin',
  },
  {
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 6,
    symbols: 'USDT',
    name: 'Tether USD',
  },
  {
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    decimals: 8,
    symbols: 'BTC',
    name: 'BTC',
  },
  // {
  //   address: '0x6b175474e89094c44da98b954eedeac495271d0f',
  //   decimals: 18,
  //   symbols: 'DAI',
  //   name: 'Dai Stablecoin',
  // },
];
