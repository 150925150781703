import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import { ethers, Wallet } from 'ethers';
import { useContext } from 'react';
import { TOKEN_ADDRESS } from '@/constants/token';
import BigNumber from 'bignumber.js';
import { SupportedChainId } from '@/constants/chains';
import { IBurnTokenParams } from '@/contracts/bridge/bridge.interface';
import useProviderL2 from '@/hooks/useProviderL2';

BigNumber.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 100 });

class CBridge {
  gameWalletProvider: IWalletContext = useContext(WalletContext);
  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;
  providerL2 = useProviderL2();

  private contract = new CContract();

  private convertHumanAmountToWei = (humanAmount: string) => {
    return new BigNumber(humanAmount).multipliedBy(1e18).toString();
  };

  public approve = async (tokenAddress: string, humanAmount: string) => {
    const contract = await this.contract
      .getERC20Contract(tokenAddress)
      .connect(this.wallet);

    const allowance = await contract.allowance(
      this.wallet.address,
      TOKEN_ADDRESS.BRIDGE_ADDRESS_L2
    );
    const amountApprove = allowance.toString();

    const isNeedApprove = new BigNumber(amountApprove).lt(
      this.convertHumanAmountToWei(humanAmount)
    );

    console.log('CBridge approve', {
      amountApprove,
      isNeedApprove,
      tokenAddress,
      route: TOKEN_ADDRESS.BRIDGE_ADDRESS_L2,
    });

    if (isNeedApprove) {
      await contract.approve(
        TOKEN_ADDRESS.BRIDGE_ADDRESS_L2,
        ethers.constants.MaxUint256
      );
    }
  };

  public burnTokenL2 = async (params: IBurnTokenParams) => {
    await this.approve(params.tokenAddress, params.humanAmount);

    const contract = await this.contract
      .getBridgeContract()
      .connect(this.wallet);

    const receiverAddress = params.receiver || this.wallet.address;

    const burnAmountWei = this.convertHumanAmountToWei(params.humanAmount);

    console.log('CBridge burnTokenL2 payload', {
      burnAmountWei,
      receiverAddress,
      destinationChainId: params.destinationChainId,
    });

    const tx = await contract['bridgeToken(address,uint256,string,uint256)'](
      params.tokenAddress,
      burnAmountWei,
      receiverAddress,
      params.destinationChainId,
      {
        gasLimit: '500000',
      }
    );

    console.log('CBridge burnTokenL2 tx', tx);
    // await tx.wait(1);
  };

  balanceOf = async (tokenAddress: string) => {
    const balance = await this.contract
      .getERC20Contract(tokenAddress)
      .balanceOf(this.wallet?.address);
    return balance.toString();
  };

  getTCBalance = async () => {
    const balance = await this.providerL2?.getBalance(this.wallet.address);
    return balance ? '0' : (balance as any).toString();
  };
}

export default CBridge;
