import { API_DGAMES } from '@/configs';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import {
  ICategory,
  ICategoryItem,
  ICategoryNameAPI,
  IGameConfigResponse,
  IJackpotGameListResponse,
  IJackpotLeaderboardPlayerResponse,
  IJackpotLeaderboardResponse as IJackpotLeaderBoardResponse,
  IJackpotResponse,
  ILauncher,
  ILeaderboardItem,
  IPLayerHistoryResponse,
  IUserGameInfo,
  IUserGameProgress,
} from '@/interfaces/api/gamefi';
import createAxiosInstance from '@/services/http-client';
import localStorage from '@/utils/localstorage';

const gameFiClient = createAxiosInstance({
  baseURL: API_DGAMES,
});

// set authorization token
export const setBearerToken = (token: string | string[]) => {
  if (token && gameFiClient) {
    gameFiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};
setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

const getLauncher = async () => {
  try {
    return (
      (((await gameFiClient.get(`/v1/game-config/launcher`)) as any)
        ?.config as ILauncher) || []
    );
  } catch (error) {
    throw error;
  }
};

const getCategoryItems = async (categoryId: string) => {
  return (
    ((
      (await gameFiClient.get(
        `/v1/web-game-config/configs/${categoryId}`
      )) as any
    )?.configs as ICategoryItem[]) || []
  );
};

const getTrendingCategoryItems = async () => {
  return (
    (((await gameFiClient.get(`/v1/web-game-config/config/trending`)) as any)
      ?.configs as ICategoryItem[]) || []
  );
};

const getCategoryName = async () => {
  return (
    (((await gameFiClient.get('/v1/web-game-config/categories')) as any)
      ?.categories as ICategoryNameAPI[]) || []
  );
};

const getCategories = async () => {
  try {
    const categoriesName = await getCategoryName();

    const categories: ICategory[] = [];
    for (const item of categoriesName) {
      const { id: categoryId } = item;
      const categoryItems = await getCategoryItems(categoryId);
      categories.push({ ...item, items: categoryItems });
    }
    return categories;
  } catch (error) {
    throw error;
  }
};

const getCategoryItem = async (gameId: string) => {
  try {
    return (
      (await gameFiClient.get(
        `/v1/web-game-config/config/gameId/${gameId}`
      )) as any
    ).config as ICategoryItem;
  } catch (error) {
    throw error;
  }
};

const getLeaderBoardItems = async (page: number, limit: number) => {
  try {
    return (
      (await gameFiClient.get(
        `/v1/async-games/earning/leaderboard?page=${page}&limit=${limit}`
      )) as any
    ).leaderboard as ILeaderboardItem[];
  } catch (error) {
    throw error;
  }
};

export const getGameConfig = async (
  configKey: string
): Promise<IGameConfigResponse> => {
  try {
    return (await gameFiClient.get(
      `/v1/config/${configKey}`
    )) as IGameConfigResponse;
  } catch (error) {
    throw error;
  }
};

const submitReferral = async ({
  inviter,
  invitee,
}: {
  inviter: string;
  invitee: string;
}) => {
  try {
    await gameFiClient.put('/admin/v1/dgames/invitation', {
      inviter,
      invitee,
    });
  } catch (error) {
    throw new Error('Submit referral address error.');
  }
};

const trackingWithdrawal = ({
  from,
  to,
  amount,
}: {
  from: string;
  to: string;
  amount: string;
}) => {
  try {
    gameFiClient.post('/admin/v1/tracking/withdraw', {
      from,
      to,
      amount,
    });
  } catch (e) {
    // TODO handle error
  }
};

const getUserGameInfo = async (address: string) => {
  try {
    return {}
    /*return (await gameFiClient.get(
      `/v1/user/info/${address}`
    )) as any as IUserGameInfo;*/
  } catch (error) {
    throw error;
  }
};

const getUserGameProgress = async (address: string) => {
  try {
    return (await gameFiClient.get(
      `/v1/user/progress/${address}`
    )) as any as IUserGameProgress;
  } catch (error) {
    throw error;
  }
};

export const getJackpotInfo = async (
  circleAddress?: string
): Promise<IJackpotResponse> => {
  try {
    const res = (await gameFiClient.get(
      `v1/jackpot/current/${circleAddress}`
    )) as IJackpotResponse;
    return res;
  } catch (err: unknown) {
    return { jackpot: {} as any };
  }

  // MOCK DATA
  // return {
  //   jackpot: {
  //     id: '56',
  //     game: {
  //       name: 'Key Merge',
  //       thumbnailUrl:
  //         'https://cdn-dev.gamefi.garden/unity-build/merge-cube/metadata/poster.jpg',
  //       gameUrl: 'https://alpha.newbitcoincity.com/bitcoin-merge-iframe',
  //       numOfPlays: 0,
  //       description:
  //         'https://cdn-dev.gamefi.garden/unity-build/merge-cube/metadata/instruction/BitcoinMerge_Instruction.html',
  //       descriptionText:
  //         'Merge numbers to get higher values. Go head-to-head with global opponents and win to earn rewards',
  //       contractAddress: '',
  //     },
  //     value: '10100000000000',
  //     ticketPrice: '100000000000',
  //     balanceRequirement: '1',
  //     alpha: '0xE829B6ef14b69C2aaF7366F93a0633fA55Afb1e4',
  //     submissionCount: '0',
  //     endAt: '1698222829',
  //     additionalDuration: '600',
  //     rewardConfigId: 1,
  //     topPlayerCount: 0,
  //     feePercentage: 0,
  //     isOpening: false,
  //   },
  // } as IJackpotResponse;
};

export const getJackpotById = async (id: number): Promise<IJackpotResponse> => {
  const res = (await gameFiClient.get(
    `v1/jackpot/id/${id}`
  )) as IJackpotResponse;
  return res;
};

export const getJackpotLeaderBoard = async (
  circleAddress?: string
): Promise<IJackpotLeaderBoardResponse> => {
  try {
    const res = (await gameFiClient.get(
      `v1/jackpot/leaderboard/${circleAddress}`
    )) as IJackpotLeaderBoardResponse;
    return res;
  } catch (err: unknown) {
    return { leaderboard: [] };
  }
};

export const getJackpotLeaderBoardByPotId = async (
  potId?: string
): Promise<IJackpotLeaderBoardResponse> => {
  try {
    const res = (await gameFiClient.get(
      `v1/jackpot/leaderboard-pot/${potId}`
    )) as IJackpotLeaderBoardResponse;
    return res;
  } catch (err: unknown) {
    return { leaderboard: [] };
  }
};

export const getJackpotLeaderboardPlayer = async (
  address: string
): Promise<IJackpotLeaderboardPlayerResponse> => {
  try {
    const res = (await gameFiClient.get(
      `v1/jackpot/player/info/${address}`
    )) as IJackpotLeaderboardPlayerResponse;
    return res;
  } catch (err: unknown) {
    throw err;
  }
};

const getIsBlockCountryIP = async () => {
  try {
    return ((await gameFiClient.get('/v1/network/is-block')) as any)
      .block as boolean;
  } catch (error) {
    throw error;
  }
};

export const getGameList = async (): Promise<IJackpotGameListResponse> => {
  try {
    const res = (await gameFiClient.get(
      `v1/jackpot/games`
    )) as IJackpotGameListResponse;
    return res;
  } catch (err: unknown) {
    return { games: [] };
  }
};

export const getPlayerHistory = async (
  circleAddress: string,
  playerAddress: string
): Promise<IPLayerHistoryResponse> => {
  try {
    const res = (await gameFiClient.get(
      `/v1/jackpot/history/${circleAddress}/${playerAddress}`
    )) as IPLayerHistoryResponse;
    return res;
  } catch (err: unknown) {
    return { history: [], totalScore: 0 };
  }
};

export {
  getCategories,
  getCategoryItem,
  getIsBlockCountryIP,
  getLauncher,
  getLeaderBoardItems,
  getTrendingCategoryItems,
  getUserGameInfo,
  getUserGameProgress,
  submitReferral,
  trackingWithdrawal,
};
