export interface IBoosterConfig {
  id: string;
  name: string;
  value: string;
  total: number;
  used: number;
  remaining: number;
  isActivated: boolean;
  endTimeTimeStamp?: number;
  description: string;
}

export interface IOGLevelConfig {
  level: number;
  minFriends: number;
  maxFriends: number;
  value: string;
}

export interface IBooster {
  id: string;
  walletAddress: string;
  booster?: IBoosterConfig;
  registerBoost: boolean;
  message: string;
  totalFriends: number;
  level: number;
  referenceConfig?: {
    'level-1': IOGLevelConfig;
    'level-2': IOGLevelConfig;
    'level-3': IOGLevelConfig;
    'level-4': IOGLevelConfig;
    'level-5': IOGLevelConfig;
  };
}

export interface IBoosterPayload {
  boot_id: string;
  reference: string;
  signature: string;
  wallet_address: string;
}

export interface IGiftTask {
  id: string;
  action: string;
  walletAddress: string;
}

export enum GiftEarn {
  GiftEarnPfp = 'set_pfp',
  GiftEarnName = 'set_name',
  GiftEarnBuyTc = 'buy_chip',
}
