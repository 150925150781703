import {Box, Flex} from '@chakra-ui/react';
import styles from '@/modules/AlphaPWA/Profiles/TradeKey/styles.module.scss';
import React, {useRef, useState} from 'react';
import s from './styles.module.scss';
import {IBodyBuySweetFloorKeys} from '@/contracts/interfaces/tradeKey';
import {IUserProfile} from "@/services/interfaces/userProfile";
import {Form} from "react-final-form";
import BuyForm from "./form";

interface IModalAmount {
  selectKeys: IBodyBuySweetFloorKeys[];
  onConfirm: () => void;
  setSelectKeys33: (_: any) => void;
  setEstimateKeysTC33: (_: any) => void;
  setEstimateTC33: (_: any) => void;
  userProfile?: IUserProfile
}

const ModalAmount: React.FC<IModalAmount> = props => {
  const {
    selectKeys,
    onConfirm,
    setSelectKeys33,
    setEstimateKeysTC33,
    setEstimateTC33,
    userProfile,
  } = props;
  const refForm = useRef<any>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: any) => {
    onConfirm && onConfirm();
  };

  return (
    <Flex className={s.container} direction={'column'} gap={10}>
      <Box className={styles.formWrapper} mt={-10}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            amountBTC: "0.001",
            inputAmount: "",
            isSelectMode: true
          }}
        >
          {({ handleSubmit }: any) => (
            <BuyForm
              handleSubmit={handleSubmit}
              ref={refForm}
              submitting={submitting}
              selectKeys={selectKeys}
              setSelectKeys33={setSelectKeys33}
              setEstimateKeysTC33={setEstimateKeysTC33}
              setEstimateTC33={setEstimateTC33}
              userProfile={userProfile}
            />
          )}
        </Form>
      </Box>
    </Flex>
  );
};

export default ModalAmount;
