import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  addon: {
    background: 'var(--alpha-confirmModalBg)',
  },
  field: {
    minHeight: '40px',
    paddingLeft: '40px',
    fontSize: '14px',
    color: '#ffffff',
    borderColor: '#212020',
    background: 'var(--alpha-confirmModalBg)',
    _placeholder: {
      opacity: 0.7,
    },
    _hover: {
      borderColor: '#212020',
    },
    _focusVisible: {
      borderColor: '#212020',
    },
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
