import { compareString } from '@/utils';
import React, { useMemo } from 'react';
import clsx from 'classnames';
import s from '../styles.module.scss';
import { Flex, Text } from '@chakra-ui/react';

export interface IItemSort {
  key: string;
  label: string;
  value: string;
}

export const SORTS: IItemSort[] = [
  {
    key: 'created_at',
    label: 'New',
    value: '0',
  },
  {
    key: 'buy_price',
    label: 'Top',
    value: '0',
  },
  {
    key: 'holder',
    label: 'Holders',
    value: '1',
  },
  // {
  //   key: 'portfolio',
  //   label: 'Portfolio',
  //   value: '0',
  // },
  // {
  //   key: 'total_volume',
  //   label: 'Vol',
  //   value: '0',
  // },
  // {
  //   key: 'latest_online',
  //   label: 'Active',
  //   value: '0',
  // },
  // {
  //   key: 'buy_price',
  //   label: 'Lowest Price',
  //   value: '1',
  // },
];

export const PLACE_HOLDER_SORTS: IItemSort[] = [
  {
    key: 'buy_price',
    label: 'Price',
    value: '0',
  },
  {
    key: 'created_at',
    label: 'New',
    value: '0',
  },
  {
    key: 'top_ft',
    label: 'FT',
    value: '1',
  },
  {
    key: 'top_sa',
    label: 'SA',
    value: '1',
  },
  // {
  //   key: 'buy_price',
  //   label: 'Lowest Price',
  //   value: '1',
  // },
  // {
  //   key: 'num_mention',
  //   label: 'Invitations',
  //   value: '0',
  // },
];

const ItemSort = ({
  sort,
  filterKeys,
  onSetFilter,
}: {
  sort: IItemSort;
  filterKeys: any;
  onSetFilter: (_: any) => void;
}) => {
  const isActive = useMemo(() => {
    const sort_col = filterKeys?.sort_col?.split(',');
    const sort_type = filterKeys?.sort_type?.split(',');

    const index = sort_col?.findIndex?.((v: any) => compareString(v, sort.key));
    if (index > -1 && compareString(sort_type[index], sort.value)) {
      return true;
    }
    return false;
  }, [filterKeys, sort]);

  const onSetSort = () => {
    onSetFilter({
      sort_col: sort.key,
      sort_type: sort.value,
    });
  };

  return (
    <Flex
      onClick={onSetSort}
      className={clsx(s.itemSort, isActive && s.itemSortActive)}
    >
      <Text>{sort.label}</Text>
    </Flex>
  );
};

export default ItemSort;
