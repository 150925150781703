import React from 'react';

import log from '@/services/logger';
import { LogLevel } from '@/enums/log-level';

type IProps = {} & any;

type Error = {};

type ErrorState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<IProps, ErrorState> {
  constructor(props: IProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false } as ErrorState;
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack;
    renderingError.cause = error;

    log(JSON.stringify({ error, errorInfo }), LogLevel.ERROR, 'ErrorBoundary');
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
