import { createSlice } from '@reduxjs/toolkit';
import {
  PREFIX,
  actionCreators,
  fetchAllTokens,
  fetchTokenDeposit,
  fetchTokenMultiChain,
  fetchTokenPrice,
} from './actions';
import { TokenState } from './types';

export const initialState: TokenState = {
  tokenList: [],
  isFetching: true,
  isFetched: false,

  allTokenList: [],
  isFetchingAllToken: true,
  isFetchedAllToken: false,
  error: undefined,

  tokenPriceMap: {},

  tokenMultiChainList: [],
};

const tokensSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTokenDeposit.pending, state => {
        state.tokenList = [];
        state.isFetching = true;
        state.isFetched = false;
      })
      .addCase(fetchTokenDeposit.fulfilled, (state, action) => {
        state.tokenList = action.payload;
        state.isFetching = false;
        state.isFetched = true;
      })
      .addCase(fetchTokenDeposit.rejected, (state, action) => {
        state.tokenList = [];
        state.isFetching = false;
        state.isFetched = true;
        state.error = action.error;
      })
      //
      .addCase(fetchAllTokens.pending, state => {
        state.allTokenList = [];
        state.isFetchingAllToken = true;
        state.isFetchedAllToken = false;
      })
      .addCase(fetchAllTokens.fulfilled, (state, action) => {
        state.allTokenList = action.payload;
        state.isFetchingAllToken = false;
        state.isFetchedAllToken = true;
      })
      .addCase(fetchAllTokens.rejected, (state, action) => {
        state.allTokenList = [];
        state.isFetchingAllToken = false;
        state.isFetchedAllToken = true;
        state.error = action.error;
      })
      //
      .addCase(fetchTokenMultiChain.pending, state => {
        state.tokenMultiChainList = [];
        state.isFetchingAllToken = true;
        state.isFetchedAllToken = false;
      })
      .addCase(fetchTokenMultiChain.fulfilled, (state, action) => {
        state.tokenMultiChainList = action.payload;
        state.isFetchingAllToken = false;
        state.isFetchedAllToken = true;
      })
      .addCase(fetchTokenMultiChain.rejected, (state, action) => {
        state.tokenMultiChainList = [];
        state.isFetchingAllToken = false;
        state.isFetchedAllToken = true;
        state.error = action.error;
      })

      //
      .addCase(fetchTokenPrice.pending, state => {
        state.tokenPriceMap = {};
      })
      .addCase(fetchTokenPrice.fulfilled, (state, action) => {
        state.tokenPriceMap = action.payload;
      })
      .addCase(fetchTokenPrice.rejected, (state, action) => {
        state.tokenPriceMap = {};
      });
  },
});

export const tokensActions = tokensSlice.actions;

export default tokensSlice.reducer;
