import s from './styles.module.scss';
import { CDN_URL_STICKERS } from '@/configs';

const AppLoading = (props: any) => {
  return (
    <div className={`${s.container} apploading`}>
      <img
        src={`${CDN_URL_STICKERS}/alpha-loading.gif`}
        alt="alpha-loading.gif"
      />
    </div>
  );
};

export default AppLoading;
