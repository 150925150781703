import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import {
  GroupRoomType,
  IGameItem,
  IJackPotInfo,
} from '@/interfaces/api/gamefi';
import {
  UnityEventType,
  UnityIFrameStatus,
} from '@/modules/AlphaPWA/Games/constants';
import errorLogger from '@/services/errorLogger';
import useAnalyticsEventTracker, { GameActions } from '@/utils/ga';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { TournamentProvider } from './tournament-context';
import { WalletContext } from './wallet-context';
import { BTC_L2_ADDRESS } from '@/configs';

export interface IGameFiContext {
  unityIframeStatus: UnityIFrameStatus;
  isShowBackButton: boolean;
  updateIsPlayingGame: (
    status: UnityIFrameStatus,
    data?: {
      mode?: string;
      game?: IGameItem;
      circleTournamentInfo?: IJackPotInfo;
      circleAddress?: string;
      extra?: string;
    }
  ) => void;
  releaseIframe: () => void;
  openGame: () => void;
  // updateExtraData: (extra?: string) => void;
}

const initialValue: IGameFiContext = {
  unityIframeStatus: UnityIFrameStatus.RELEASED,
  isShowBackButton: false,
  updateIsPlayingGame: () => {},
  releaseIframe: () => {},
  openGame: () => {},
  // updateExtraData: () => {},
};

export const GameFiContext = React.createContext<IGameFiContext>(initialValue);

export const GameFiProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [isPlayingGame, setIsPlayingGame] = useState(false);
  const [unityIframeStatus, setUnityIframeStatus] = useState<UnityIFrameStatus>(
    UnityIFrameStatus.RELEASED
  );
  const {
    onPostMessageOpenGame,
    onPostMessageCloseGame,
    onPostMessageUpdateGameUrl,
  } = useContentWindowMessage();

  // const [mode, setMode] = useState('');
  // const [game, setGame] = useState<IGameItem>();
  // const [extra, setExtra] = useState<string>('');

  const circleTournamentInfoRef = useRef<IJackPotInfo>();
  const game = useRef<IGameItem>();
  const mode = useRef<string>('');
  const extra = useRef<string>('');

  const [hydration, setHydration] = useState(false);
  const [isShowBackButton, setShowBackButton] = useState<boolean>(false);
  const gaEventTracker = useAnalyticsEventTracker();
  // const { circleTournamentInfo, circleAddress } = useContext(TournamentContext);
  const { addressL2 } = useContext(WalletContext);
  const [circleTournamentInfo, setCircleTournamentInfo] = useState<
    IJackPotInfo | undefined
  >();
  const [circleAddress, setCircleAddress] = useState<string | undefined>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHydration(true);
    }
  }, []);

  useEffect(() => {
    if (hydration && window) {
      window.onmessage = async event => {
        try {
          const eventData = JSON.parse(event.data);
          switch (eventData.type) {
            // case 'close_game':
            //   setTimeout(() => {
            //     router.push(ROUTE_PATH.ALPHA_MOBILE_GAMES);
            //   }, 2000);
            //   break;
            case UnityEventType.U2JS_SET_BACK_BUTTON:
              // console.log('U2JS_SET_BACK_BUTTON', game?.current?.name);
              setShowBackButton(eventData.isShow);
              break;
            case UnityEventType.U2JS_START_LOAD:
              // console.log('U2JS_START_LOAD', game?.current?.name);
              gaEventTracker(
                GameActions.UnityStartLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_UNITY_BOOTSTRAP:
              // console.log('U2JS_UNITY_BOOTSTRAP', game?.current?.name);
              gaEventTracker(
                GameActions.UnityBootstrapLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_GAME_LOADED:
              // console.log('U2JS_GAME_LOADED', game?.current?.name);
              // console.log('U2JS_GAME_LOADED_______', game?.current?.name);

              updateIsPlayingGame(UnityIFrameStatus.PLAYING);
              gaEventTracker(
                GameActions.UnityGameLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_UNITY_ERROR:
              const errorMessage = eventData.metadata;
              // console.log('U2JS_UNITY_ERROR', errorMessage);
              updateIsPlayingGame(UnityIFrameStatus.PENDING, {});
              toast.error(errorMessage);
              gaEventTracker(
                GameActions.UnityLoadError,
                JSON.stringify(circleTournamentInfo)
              );

              errorLogger.report({
                action: errorLogger.ERROR_LOGGER_TYPE.UNITY_LOAD,
                address: addressL2,
                error: eventData.meta,
                info: JSON.stringify(circleTournamentInfo),
              });
              onPostMessageCloseGame();
              break;
            case UnityEventType.U2JS_UNITY_LOADED:
              // console.log('U2JS_UNITY_LOADED');
              openGame();
              gaEventTracker(
                GameActions.UnityLoaded,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            default:
              break;
          }
        } catch (error) {}
      };
    }
  }, [hydration, unityIframeStatus]);

  // const updateExtraData = (extra?: string) => {
  //   setExtra(extra ?? '');
  // };

  const updateIsPlayingGame = (
    status: UnityIFrameStatus,
    data?:
      | {
          mode?: string;
          game?: IGameItem;
          extra?: string;
          circleTournamentInfo?: IJackPotInfo;
          circleAddress?: string;
        }
      | undefined
  ) => {
    // console.log(
    //   'updateIsPlayingGame',
    //   status,
    //   data?.game,
    //   data?.mode,
    //   data?.extra
    // );

    setUnityIframeStatus(status);
    if (data?.game) {
      game.current = data.game;
    }
    if (data?.mode) {
      mode.current = data?.mode;
    }
    if (data?.circleTournamentInfo) {
      setCircleTournamentInfo(data?.circleTournamentInfo);
    }
    if (data?.circleAddress) {
      setCircleAddress(data?.circleAddress);
    }
    if (data?.extra != undefined) {
      extra.current = data?.extra;
    }

    if (mode.current == 'practice') {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }

    if (data?.circleTournamentInfo) {
      circleTournamentInfoRef.current = data?.circleTournamentInfo;
    }
  };

  const openGame = () => {
    if (!game.current) {
      return;
    }

    // console.log('MODE::::', mode, extra, game);
    onPostMessageOpenGame(game.current.id);
    onPostMessageUpdateGameUrl(url(game.current, mode.current, extra.current));
  };

  const url = (game: IGameItem, mode: string, extra?: string): string => {
    console.log('JS2U_____', circleTournamentInfoRef);
    const tokenAddress = circleTournamentInfoRef.current?.potToken;

    const decodedUrl = decodeURIComponent(game?.gameUrl ?? '');
    let result = '';
    if (mode === 'practice') {
      if (decodedUrl.includes('?')) result = `${decodedUrl}&practice=true`;
      else result = `${decodedUrl}?practice=true`;
    } else if (mode === 'play') {
      result = `${decodedUrl}?jackpot=true&action=PLAY&alpha=${circleAddress}&player=${addressL2}&erc20=${tokenAddress}`;
    }
    result = `${result}&extra=wombat`;

    return result;
  };

  const releaseIframe = () => {
    // setGame(undefined);
    game.current = undefined;
    setUnityIframeStatus(UnityIFrameStatus.RELEASED);
  };

  const contextValues = useMemo((): IGameFiContext => {
    return {
      unityIframeStatus,
      updateIsPlayingGame,
      releaseIframe,
      openGame,
      isShowBackButton,
    };
  }, [isPlayingGame, unityIframeStatus, isShowBackButton]);

  return (
    <GameFiContext.Provider value={contextValues}>
      <TournamentProvider>{children}</TournamentProvider>
    </GameFiContext.Provider>
  );
};
