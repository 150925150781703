import { AuthenticationType } from '@/enums/chat';
import {
  ChatGroupMember,
  ChatMessageResponse,
  ChatRoomResponse,
  ChatTypeResponse,
  LanguageSupportedEnum,
  PromptFeeResponse,
  TipBase,
} from '@/interfaces/api/chat';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { Scrollbars } from 'react-custom-scrollbars';

export type DMScreen = '' | 'HOME' | 'CHAT';

export type MessageContent = {
  type?: 'TEXT' | 'STICKER' | 'HTML';
  plain: string;
  rawText: string;
  joinContent?: string; // for join or leave
};

export interface EmojiType {
  id: string;
  name: string;
  keywords: string[];
  native: string;
  shortcodes: string;
  unified: string;
}

export type DMRealTimeMessage = Omit<
  ChatMessageResponse,
  'id' | 'createdAt' | 'updatedAt'
>;

export type UploadFile = File & {
  isSucceed?: boolean;
  url?: string;
};

export type MediaFile = {
  url: string;
  size: number;
  name: string;
  type: string;
};

export type RoomPersonInfo = IGetPlayerPoolProfile;

export type DMRoom = ChatRoomResponse & {
  messageLoaded?: boolean;
  pinned?: boolean;
  ownerToken?: string;
  tribeType?: AuthenticationType;
  metadata?: string;
  collectionAddress?: string;
  roles?: Array<string>;
};

export type ChatType = ChatTypeResponse;

export type DMMessage = ChatMessageResponse & {
  uiid?: string;
  isLoading?: boolean;
  isError?: boolean;
  timestamp?: string;
};

export type TipMessage = {
  id: string;
  roomId: string;
};

export type ExtendScrollbars = Scrollbars & {
  scrolledIDs: Record<string, boolean>;
};

export type ChatTab = 'DAOS' | 'DMS' | 'TRIBES';

export type RoomId = string;

export type RemoveMemberFromGroupRealTime = {
  roomId: RoomId;
  info: ChatGroupMember;
};

export type AddMemberFromGroupRealTime = {
  roomId: RoomId;
  info: ChatGroupMember;
};

type EnvelopInvolvedPerson = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type EnvelopContent = {
  id: string;
  quantity: number;
  receiverInfo: EnvelopInvolvedPerson;
  rewardInfo: EnvelopInvolvedPerson;
  tokenGroupOwner: string;
  tokenReceiver: string;
  tokenReward: string;
};

export type MessageWheelContent = {
  prizeWheelFeedId: number;
  roomId: string;
  timestamp: number;
};

export type MessageRaffleContent = {
  raffleFeedId: number;
  roomId: string;
  timestamp: number;
};

export type MessageBullBearContent = {
  bullBearFeedId: number;
  game_feed_id: number;
  bullBearJoinId: number;
  extra_data: {
    join_id: number;
  };
  bullBearEventId: number;
  game_id: number;
  roomId: string;
  timestamp: number;
  action: string;
};

type BuyFromPerson = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type BuyMessageContent = {
  buyAmount: number;
  eventContent: string;
  fromInfo: BuyFromPerson;
  network: string;
  toInfo: BuyFromPerson;
};

export type UpdateRealTimeRoomCalling = {
  appId: string;
  channelName: string;
  result: string;
  roomId: string;
  sessionId: string;
  token: string;
  uid: string;
};

export type GroupCallingEnded = {
  remainMember: number;
  result: boolean;
  roomId: string;
  sessionId: string;
};

export type BlockChatUser = {
  room_id: string;
  address: string[];
};

export type UnblockChatUser = {};

export type GameTournamentUser = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};
export type RoomLanguageSettings = Record<RoomId, LanguageSupportedEnum>;

export enum ContentByLanguageEnum {
  OFF = 'content',
  ENGLISH = 'contentEn',
  CHINESE_SIMPLIFIED = 'contentCn',
  CHINESE_TRADITIONAL = 'contentTw',
  JAPANESE = 'contentJp',
}

export type ReplyMessageData = DMMessage & {
  isSender?: boolean;
  isGroup?: boolean;
};

export type TournamentFromInfoType = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type TournamentRewardInfoType = {
  reward: number;
  user?: {
    address: string;
    twitterAvatar: string;
    twitterFollowersCount: number;
    twitterId: string;
    twitterName: string;
    twitterUsername: string;
  };
  rewardKeyAmount?: number;
  score?: number;
};

export type TournamentInfoType = {
  deadline: number;
  game: string;
  id: number;
  prize: number;
  balanceRequirement?: number;
};

export type LiveTournamentInfoType = {
  deadline: number;
  game: string;
  id: string;
  prize: number;
  submissionCount: number;
};

export type TournamentTokenRewardInfo = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type TournamentPlayerInfo = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type TournamentGameInfoType = {
  contractAddress: string;
  description: string;
  descriptionText: string;
  name: string;
  numOfPlayers: number;
  thumbnailUrl: string;
};

export type TournamentMessageType = {
  amount: number;
  type: number;
  isOpening?: boolean;
  eventContent: string;
  fromInfo?: TournamentFromInfoType;
  rewardInfo?: TournamentRewardInfoType[];
  tournamentInfo?: TournamentInfoType;
  players?: TournamentPlayerInfo[];
  tokenRewardUserInfo?: TournamentTokenRewardInfo;
  bestScore: number;
  score: number;
  rank: number;
  game?: TournamentGameInfoType;
  potToken?: string;
};

export type LiveTournamentMessageType = {
  amount: number;
  type: number;
  isOpening?: boolean;
  eventContent: string;
  fromInfo?: TournamentFromInfoType;
  rewardInfo?: TournamentRewardInfoType[];
  tournamentInfo?: LiveTournamentInfoType;
  players?: TournamentPlayerInfo[];
  tokenRewardUserInfo?: TournamentTokenRewardInfo;
  bestScore: number;
  score: number;
  rank: number;
  game?: TournamentGameInfoType;
  potToken?: string;
};

export type TipMessageContent = {
  isTipOpened: boolean;
  messageId: string;
  sender: TipBase;
  receiver: TipBase;
  token: TipBase;
  amount: string;
  animation: string;
};

export type MemberInCall = {
  members?: string[];
  total?: number;
};

export type CallMemberChangedType = {
  roomId: string;
  address: string;
};

export type PinUpdateSocket = {
  roomId: string;
  address: string;
  pinned: boolean;
};

export type JamStartedContent = {
  content: string;
};

export type MintMessageContent = {
  openseaLink: string;
  ipfs?: string;
};

export type JamJoinedContent = JamStartedContent;

export enum ChatCommandEnum {
  chart = 'chart',
  uni = 'uni',
}

export enum ChatCommandAdditionEnum {
  chart = 'chart',
}

interface LabelDescriptionMap {
  [name: string]: any;
}

export const ChatCommandDesc: LabelDescriptionMap = {
  chart: 'To access the charts for BTC, ETH, SOL...',
  uni: 'To engage in trading, utilize Spipe, and execute frontrun transactions on Uniswap',
}

export type QuillMessageSendData = {
  plainText: string;
  sendPlain: string;
  sendRaw: string;
};

export type PromptExtend = PromptFeeResponse & {
  // command: string;
  // originalPrompt: string;
  // params: string[];
};

export type AIProfileChecking = {
  profile?: IGetPlayerPoolProfile;
  // holder?: boolean;
  holdingToken?: boolean;
};

export enum PromptEncapsulationEnum {
  public = '/',
  private = '!',
  // private = 'thisfeatureisnotunsupportforthismomentpleasecommentwhenisreadyandreplacebyonlineabove',
}

export type ForwardMessageMetadata = {
  originForwardMessage: ChatMessageResponse;
};
