export enum WalletError {
  UNSUPPORTED_CHAIN = 'UNSUPPORTED_CHAIN',
  FAILED_SWITCH_CHAIN = 'FAILED_SWITCH_CHAIN',
  FAILED_ADD_CHAIN = 'FAILED_ADD_CHAIN',
  FAILED_CONNECT = 'FAILED_CONNECT',
  NO_INSTANCE = 'NO_INSTANCE',
  FAILED_LINK_WALLET = 'FAILED_LINK_WALLET',
  FAILED_UNLINK_WALLET = 'FAILED_UNLINK_WALLET',
  FAILED_SIGN_MESSAGE = 'FAILED_SIGN_MESSAGE',
  NO_METAMASK = 'NO_METAMASK',
  FAILED_TRANSFER = 'FAILED_TRANSFER',
  FAILED_GET_TX = 'FAILED_GET_TX',
}

export enum WalletErrorCode {
  USER_REJECTED = 4001,
  NO_CHAIN = 4902,
}
