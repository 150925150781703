import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { CDN_URL_IMAGES } from '@/configs';
import s from './styles.module.scss';
import { DonateResult } from '@/constants/donate';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { formatCurrency } from '@/utils';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';

export const DONATE_RESULT_MODAL: string = 'DONATE_RESULT_MODAL';

interface IProps {
  result: DonateResult;
  onClose: () => void;
  amount?: string | number;
  senderName?: string;
  fromUserName?: string;
  fromUserAddress?: string;
  fromUserAvatar?: string;
  errorCode?: number;
}

const DonateResultModal: React.FC<IProps> = ({
  // isOpen,
  onClose,
  result,
  amount = 1,
  senderName,
  fromUserName,
  fromUserAddress,
  fromUserAvatar,
  errorCode,
}): React.ReactElement => {
  const { activeRoom } = useDM();

  const renderBodyContent = () => {
    switch (result) {
      case DonateResult.Success:
        return (
          <Box className={s.success_wrapper} position={'relative'}>
            <div className={s.background}>
              <img
                src={`${CDN_URL_IMAGES}/evelope-bg.png`}
                alt="Red envelope background"
              />
            </div>
            <div className={s.content}>
              <h2 className={s.title}>Congratulations ✨</h2>
              <div className={s.imgWrapper}>
                <Avatar
                  className={s.avatar}
                  url={fromUserAvatar || ''}
                  address={fromUserAddress || ''}
                  width={140}
                />
              </div>
              <p className={s.description}>
                <span>{senderName}</span>
                &nbsp;gifted you {formatCurrency(amount, 0, 2)}&nbsp;
                <span>{fromUserName}</span>
                &nbsp;{activeRoom?.type === 'CHAT_TRIBE' ? 'token' : 'key'}{labelAmountOrNumberAdds(Number(amount))}
              </p>
              <Button w="100%" onClick={onClose}>
                Done
              </Button>
            </div>
          </Box>
        );

      default:
        let message = 'Oops! Something went wrong. Please try again later.';
        let icon = 'donate-fail.png';

        if (errorCode === -3015) {
          message =
            'Got one gift already? Cool your jets for 3 minutes, then grab another!';
          icon = 'clock-icon-donate.png';
        }

        return (
          <div className={s.error_wrapper}>
            <h2 className={s.title}>{message}</h2>
            <div className={s.errorImgWrapper}>
              <img
                className={s.errorImg}
                src={`${CDN_URL_IMAGES}/${icon}`}
                alt="heart broken"
              />
            </div>

            <Button w="100%" onClick={onClose}>
              Done
            </Button>
          </div>
        );
    }
  };

  return <>{renderBodyContent()}</>;
};

export default DonateResultModal;
