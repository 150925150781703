import { APP_ENV } from '@/configs';
import { ApplicationEnvironment } from '@/enums/configs';
import { isBrowser } from '@trustless-computer/dapp-core';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';

export const isProduction = (): boolean => {
  return APP_ENV === ApplicationEnvironment.PRODUCTION;
};

export const isLocalHost = (): boolean => {
  return window.location.hostname === 'localhost';
};

export const isDevelop = (): boolean => {
  return APP_ENV === ApplicationEnvironment.DEVELOP;
};

export const copyToClipboard = (text: string): void => {
  if (!isBrowser()) return;
  navigator.clipboard.writeText(text);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const camelCaseKeys = <T>(obj: Record<string, any>): T => {
  if (Array.isArray(obj)) {
    return obj.map((v: Record<string, any>) => camelCaseKeys<T>(v)) as T;
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_camelCase(key)]: camelCaseKeys<T>(obj[key] as Record<string, any>),
      }),
      {}
    ) as T;
  }
  return obj as T;
};

export const snakeCaseKeys = <T>(obj: Record<string, any>): T => {
  if (Array.isArray(obj)) {
    return obj.map((v: Record<string, any>) => snakeCaseKeys<T>(v)) as T;
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_snakeCase(key)]: snakeCaseKeys<T>(obj[key] as Record<string, any>),
      }),
      {}
    ) as T;
  }
  return obj as T;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const traits = (arrAttrs: any[]): any => {
  let trs = [];
  let indexMin = 0;

  for (let i = 0; i < arrAttrs.length; i++) {
    indexMin += arrAttrs[i].rate;
    trs[i] = indexMin;
  }

  const ftrs = Math.floor(Math.random() * indexMin);
  for (let i = 0; i < trs.length; i++) {
    if (ftrs < trs[i]) {
      return arrAttrs[i];
    }
  }
  return arrAttrs[0];
};
