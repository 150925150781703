import cs from 'classnames';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import s from './styles.module.scss';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import { prettyPrintBytes } from '@trustless-computer/dapp-core';

export interface IProps {
  className: string;
  acceptedFileType?: Array<string>;
  fileOrFiles?: File[] | undefined;
  labelText: string;
  maxSize: number;
  onChange: (files: File | undefined) => void;
  showImage?: boolean;
}

const DropFile: React.FC<IProps> = ({
  acceptedFileType,
  fileOrFiles,
  className,
  labelText,
  maxSize,
  onChange,
  showImage = true,
}: IProps) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  const onChangeFile = (file: File): void => {
    setFile(file);
    setError('');
    onChange(file);
  };

  const onSizeError = (): void => {
    setError(`File size error, maximum file size is 35Kb.`);
  };

  const onTypeError = (): void => {
    setError('Invalid file extension.');
  };

  return (
    <div
      className={cs(s.dropFile, className, {
        [s.dropFile__drag]: false,
        [s.dropFile__error]: !!error,
      })}
    >
      <FileUploader
        handleChange={onChangeFile}
        name={'zipFileUploader'}
        maxSize={maxSize}
        minSize={0}
        types={acceptedFileType}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
        fileOrFiles={fileOrFiles}
        classes={s.dropZone}
      >
        <div>
          {showImage && (
            <img
              className={s.dropZoneThumbnail}
              src={`${CDN_URL_ICONS}/docs.png`}
            />
          )}
          {file ? (
            <p className={s.dropZoneDescription}>
              {`${file.name} (${prettyPrintBytes(file.size)})`}
            </p>
          ) : (
            <p className={s.dropZoneDescription}>{labelText}</p>
          )}
          {error && (
            <p className={cs(s.dropZoneDescription, s.errorText)}>{error}</p>
          )}
        </div>
      </FileUploader>
    </div>
  );
};

export default DropFile;
