import { AssetsContext, IAssetsContext } from '@/contexts/assets-context';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import { BigNumber, Wallet, ethers } from 'ethers';
import { useContext } from 'react';
import CContract from './contract';
import { ceil } from 'lodash';
import { ALPHA_KEY_FACTORY_ADDRESS, BTC_L2_ADDRESS } from '@/configs';
import { parseEther } from 'ethers/lib/utils';
import NBigNumber from 'bignumber.js';
import CTradeAPI from '@/services/classes/trade';
import errorLogger from '@/services/errorLogger';
import { TOKEN_ADDRESS } from '@/constants/token';
import sleep from '@/utils/sleep';
import {
  Environment,
  WalletType,
  changeWallet,
  choiceConFig,
  refreshProvider,
} from 'trustless-swap-sdk';
import { isProduction } from '@/utils/commons';

export enum ETypes {
  'issue',
  'buy',
  'sell',
  'approve',
  'update_creator_fee',
  'withdraw',
  'createTournament',
  'add_watch_list',
  'remove_watch_list',
  'swap_tokens',
  'transfer',
  'swap_eth_key',
  'deposit_fund',
  'claim_token',
  'multi_send',
  'add_pool',
  'increase_pool',
  'remove_pool',
  'collect_fee_pool',
}

export const typeToFee = {
  [ETypes.issue]: 1000000,
  [ETypes.buy]: 1000000,
  [ETypes.sell]: 1000000,
  [ETypes.approve]: 60000,
  [ETypes.update_creator_fee]: 100000,
  [ETypes.withdraw]: 2100000,
  [ETypes.createTournament]: 350000,
  [ETypes.add_watch_list]: 200000,
  [ETypes.remove_watch_list]: 70000,
  [ETypes.swap_tokens]: 2000000,
  [ETypes.transfer]: 10000,
  [ETypes.swap_eth_key]: 2000000,
  [ETypes.deposit_fund]: 1000000,
  [ETypes.claim_token]: 1000000,
  [ETypes.multi_send]: 50000,
  [ETypes.add_pool]: 620000,
  [ETypes.increase_pool]: 300000,
  [ETypes.remove_pool]: 400000,
  [ETypes.collect_fee_pool]: 180000,
};
type TokenType = 'BTC' | 'ETH';

class CContractBase {
  gameWalletProvider: IWalletContext = useContext(WalletContext);

  assetContext: IAssetsContext = useContext(AssetsContext);

  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;

  contract = new CContract();
  tradeAPI = new CTradeAPI();

  private MIN_BUY_TC = '0.05'; // need buy 0.05 TC for fee
  private CURRENT_RETRY = 0;

  configSDK = () => {
    changeWallet(
      WalletType.PRIVATEKEY,
      this.wallet.address,
      this.wallet.privateKey
    );
    choiceConFig(isProduction() ? Environment.MAINNET : Environment.TESTNET);
    refreshProvider(null);
  };

  private getFaucet = async () => {
    try {
      const tx = await this.tradeAPI.faucetTC({
        address: this.wallet.address,
        network: 'nos',
      });
      console.log('getFaucettx', tx);

      if (tx) {
        await this.gameWalletProvider.gameWallet?.provider.waitForTransaction(
          tx as any
        );
      }
      return true;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.FAUCET_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
      });
      return true;
    } finally {
      // store.dispatch(requestReload());
    }
  };

  getBTCPrice = async () => {
    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      return await akf.getBTCPrice();
    } catch (error) {
      return 0;
    }
  };

  buyTCFee = async (need_amount_tc = '0.1', tokenType?: TokenType) => {
    const tokenAddress =
      tokenType === 'ETH' ? TOKEN_ADDRESS.ETH_ADDRESS_L2 : BTC_L2_ADDRESS;
    let [tokenBuyPrice, chainNetwork, userAmountBTC] = await Promise.all([
      this.getBTCPrice(),
      this.gameWalletProvider.gameWallet?.provider.getNetwork(),
      this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .balanceOf(this.wallet.address),
    ]);

    if (tokenType === 'ETH') {
      tokenBuyPrice = BigNumber.from('145000000000000000000');
    }
    const amountApprove = await this.contract
      .getERC20Contract(tokenAddress)
      .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);
    if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
      const txApprove = await this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
      await txApprove.wait();
    }

    const estimateBTCAmount = parseEther(need_amount_tc)
      .mul(parseEther(tokenType === 'ETH' ? '0.1' : '1'))
      .div(tokenBuyPrice);

    let amountBTC = estimateBTCAmount;

    if (BigNumber.from(estimateBTCAmount).gt(userAmountBTC)) {
      amountBTC = BigNumber.from(userAmountBTC).gt(estimateBTCAmount)
        ? amountBTC
        : userAmountBTC;
    }

    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));

      const chainId = chainNetwork?.chainId as number;

      const messagePack = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'uint256',
          'uint256',
          'address',
          'uint256',
          'uint256',
          'uint256',
        ],
        [
          akf.address,
          chainId,
          nonce,
          this.wallet.address,
          amountBTC,
          tokenBuyPrice,
          ethers.constants.MaxUint256,
        ]
      );

      const messageHash = ethers.utils.keccak256(
        ethers.utils.arrayify(messagePack)
      );
      const signature = await this.wallet.signMessage(
        ethers.utils.arrayify(messageHash)
      );

      const data_hex = akf.interface.encodeFunctionData(
        (tokenType === 'ETH' ? 'requestETH2TC' : 'requestTC') as any,
        [
          nonce,
          this.wallet.address,
          amountBTC,
          tokenBuyPrice,
          ethers.constants.MaxUint256,
          signature,
        ]
      );
      const result: any = await this.tradeAPI.sendTx({
        contract_address: akf.address,
        data_hex,
      });
      const r: any =
        await this.gameWalletProvider.gameWallet?.provider.getTransaction(
          result
        );
      await r.wait();
      await this.assetContext.fetchAssets();
      return;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.BUY_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
        info: JSON.stringify({
          userAmountBTC: userAmountBTC.toString(),
          amountBTC: amountBTC.toString(),
        }),
      });
      await this.getFaucet();
      throw error;
    }
  };

  estimateTCGasFee = async ({
    type,
    needFeeTC,
  }: {
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;

    try {
      const [amount, amountBTC] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountBTC).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(BTC_L2_ADDRESS)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amount?.toString()).lt(gasFee.toString()),
        amount?.toString()
      );

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amount?.toString()).lt(gasFee.toString())
      ) {
        await this.buyTCFee(this.MIN_BUY_TC);
        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }

    return isBuy;
  };

  getTokenApprove = async ({
    token_address,
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    token_address: string;
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(token_address)
        .allowance(this.wallet.address, spender_address);

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateTCGasFee({
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(token_address)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      console.log('error', error);

      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getTokenApprove({
          spender_address,
          token_amount,
          need_approve,
          token_address,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };
}

export default CContractBase;
