import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../';

export interface ILiquidityFilter {
  search?: string;
}

export interface ILiquidityState {
  slippage: number;
  filter: ILiquidityFilter;
}

const initialState: ILiquidityState = {
  slippage: 0.5,
  filter: {
    search: '',
  },
};

const liquiditySlide = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    updateSlippage: (state, action) => {
      state.slippage = action.payload;
    },
    updateFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const { updateSlippage, updateFilter } = liquiditySlide.actions;

export const liquidityReducer = (state: RootState) => state.liquidity;

export default liquiditySlide.reducer;
