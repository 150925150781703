import { BACKUP_PRV_KEY, COPIED_PRV_KEY, FOLLOWER_INVITED_LIST } from "@/constants/storage-key";
import { isBrowser } from "@trustless-computer/dapp-core"

export const getIsBackedUp = () => {
  if (!isBrowser()) {
    return true;
  }

  const isBackedUp = window.localStorage.getItem(BACKUP_PRV_KEY);
  if (isBackedUp === 'true') return true;
  return false;
}

export const getCopiedPrvKey = () => {
  if (!isBrowser()) {
    return true;
  }

  const isCopied = window.localStorage.getItem(COPIED_PRV_KEY);
  if (isCopied === 'true') return true;
  return false;
}

export const setIsBackedUp = () => {
  if (!isBrowser()) {
    return;
  }

  window.localStorage.setItem(BACKUP_PRV_KEY, 'true');
}

export const setCopiedPrvKey = () => {
  if (!isBrowser()) {
    return;
  }

  window.localStorage.setItem(COPIED_PRV_KEY, 'true');
}

export const getIsFirstTimeUser = () => {
  if (!isBrowser()) {
    return true;
  }

  const isFirstTimeUser = window.localStorage.getItem('first_time_user');
  if (isFirstTimeUser === 'true') return true;
  return false;
}

export const setIsFirstTimeUser = () => {
  if (!isBrowser()) {
    return;
  }

  window.localStorage.setItem('first_time_user', 'true');
}

export const setInvitedList = (list: Map<string, number>) => {
  if (!isBrowser()) {
    return;
  }
  const value = JSON.stringify(Array.from(list.entries()));
  window.localStorage.setItem(FOLLOWER_INVITED_LIST, value)
}

export const getInvitedList = () => {
  if (!isBrowser()) {
    return;
  }

  const invitedList = (window.localStorage.getItem(FOLLOWER_INVITED_LIST))
  if (!invitedList) { return; }

  const storedMapArray = JSON.parse(invitedList);
  const storedMap: Map<string, number> = new Map(storedMapArray);

  return storedMap
}