export enum StakingStatus {
  StakingOrderStatusNone = 'none',
  StakingOrderStatusCancelled = 'cancelled',
  StakingOrderStatusCreated = 'created',
  StakingOrderStatusDone = 'done',
}

export interface StakingHistory {
  id: number;
  user_address: string;
  user_id: number;
  user_twitter_id: string;
  user: Trader;
  status_num: number;
  status: string;
  ratio: string;
  duration: number;
  order_id: string;
  trader_address: string;
  trader_id: number;
  trader_twitter_id: string;
  trader: Trader;
  amount: string;
  interest: string;
  order_created_at: string;
  order_expired_at: string;
  order_closed_at: Date;
  staking_user: StakingUser;
  tx_hash: string;
  closed_tx_hash: string;
  locked: boolean;
}

export interface StakingUser {
  id: number;
  transaction_date: Date;
  user_address: string;
  user_id: number;
  user_twitter_id: string;
  user: string;
  token: Token;
  status_num: number;
  status: string;
  ratio: string;
  duration: number;
  free: string;
  total: string;
  total_keys: string;
  total_staking_keys: string;
}

export interface Token {
  twitter_id: string;
  twitter_user: string;
  token_address: string;
  supply: string;
  buy_price: string;
  buy_price_usd: string;
  is_pre_sale: boolean;
  red_flag: number;
  min_holding_requirement: string;
  converted_at: string;
  player_fee_rate: string;
  usd_price: string;
  price: string;
}

export interface Trader {
  id: number;
  network: string;
  address: string;
  address_checked: string;
  twitter_id: string;
  twitter_username: string;
  twitter_name: string;
  twitter_avatar: string;
  twitter_followers_count: number;
  token_address: string;
}

export interface User {
  id: number;
  network: string;
  address: string;
  address_checked: string;
  bns: string;
  avatar: string;
  twitter_id: string;
  twitter_username: string;
  twitter_name: string;
  twitter_avatar: string;
  twitter_followers_count: number;
  pnl_percent: number;
  pnl_usd: number;
  pnl_unrealized_usd: number;
  pnl_unrealized_percent: number;
  token_price: number;
  token_price_usd: number;
  nbc_verified: boolean;
  top_holdings: string;
}

export interface StakingPool {
  id: string;
  user_address: string;
  user_id: number;
  user_twitter_id: string;
  user: User;
  status_num: number;
  status: string;
  ratio: string;
  duration: string;
  locked: boolean;
  free: string;
  total: string;
  total_staking_keys: number;
  total_keys: number;
  token: Token;
  trader: Trader;
  user_token: Token;
  trader_token: Token;
  user_balance: string;
}

export enum LockTerm {
  FLEXIBLE = 1,
  ONE_MONTH,
  TWO_MONTH,
  THREE_MONTH,
  SIX_MONTH,
}

export enum StakeType {
  KEYS,
  TOKENS,
}
