import { AgoraContext } from '@/providers/AgoraProvider/types';
import { ClientConfig } from 'agora-rtc-react';
import React from 'react';

export const context = React.createContext<AgoraContext>({} as any);

export const config: ClientConfig = { mode: 'rtc', codec: 'vp8' };

export enum AgoraEventEnum {
  USER_PUBLISHED = 'user-published',
  USER_UNPUBLISHED = 'user-unpublished',
  USER_JOINED = 'user-joined',
  USER_LEFT = 'user-left',
  MICROPHONE_CHANGED = 'microphone-changed',
}

export enum AgoraMediaEnum {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export const DEFAULT_UNMUTED = false;

export const MAXIMUM_SPEAKER_IN_ROOM = 8;

export const AUTO_REMOVE_REQUEST_TIMEOUT = 10000; //10s
