import React from 'react';

const IcDefaultAvatar = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17377_8610)">
        <rect width="36" height="36" rx="18" fill="black" />
        <rect x="10.8" y="6" width="14.4" height="14.4" rx="7.2" fill="white" />
        <rect x="-9" y="24" width="54" height="54" rx="27" fill="white" />
      </g>
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="black" />
      <defs>
        <clipPath id="clip0_17377_8610">
          <rect width="36" height="36" rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcDefaultAvatar;
