import toast from 'react-hot-toast';
import ToastMessage from './ToastMessage';

export const showError = ({
  url,
  linkText,
  message,
}: {
  url?: string;
  linkText?: string;
  message: string;
}) => {
  toast.error(
    t => (
      <ToastMessage id={t.id} message={message} url={url} linkText={linkText} />
    ),
    {
      duration: 5000,
      style: {
        maxWidth: '900px',
        borderLeft: '4px solid #FF4747',
      },
    }
  );
};

export const showSuccess = ({
  url,
  linkText,
  message,
}: {
  url?: string;
  linkText?: string;
  message: string;
}) => {
  toast.success(
    t => (
      <ToastMessage id={t.id} message={message} url={url} linkText={linkText} />
    ),
    {
      duration: 5000,
      style: {
        maxWidth: '900px',
        borderLeft: '4px solid #62d344',
        padding: `8px 10px`,
        justifyContent: 'space-between',
      },
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    }
  );
};
