import { ChatGroupMember } from '@/interfaces/api/chat';
import React from 'react';
import s from './LeaveConfirmModal.module.scss';
import { useDispatch } from 'react-redux';
import { closeModal } from '@/state/modal';
import { Text } from '@chakra-ui/react';

type Props = {
  onConfirm: () => void;
};

export const LEAVE_JAM_CONFIRM_MODAL = 'leave-jam-confirm-modal';

const LeaveJamConfirmModal = ({ onConfirm }: Props) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal({ id: LEAVE_JAM_CONFIRM_MODAL }));
  };

  const handleConfirm = () => {
    onConfirm();
    handleCloseModal();
  };

  return (
    <div className={s.wrapper}>
      <Text className={s.desc}>
        You're the last speaker in the room. If you leave, this jam session will
        be ended.
      </Text>
      <div className={s.cta_btn}>
        <button className={s.cancel} onClick={handleCloseModal}>
          Cancel
        </button>
        <button className={s.block} onClick={handleConfirm}>
          Leave
        </button>
      </div>
    </div>
  );
};

export default LeaveJamConfirmModal;
