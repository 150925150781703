export default {
  short_name: 'Alpha',
  name: 'Alpha',
  icons: [
    {
      src: 'https://alpha.wtf/icons/alpha/favicon.svg',
      sizes: '512x512',
      type: 'image/svg+xml',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/72x72.jpg',
      type: 'image/jpg',
      sizes: '72x72',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/96x96.jpg',
      type: 'image/jpg',
      sizes: '96x96',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/120x120.jpg',
      type: 'image/jpg',
      sizes: '120x120',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/128x128.jpg',
      type: 'image/jpg',
      sizes: '128x128',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/144x144.jpg',
      type: 'image/jpg',
      sizes: '144x144',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/152x152.jpg',
      type: 'image/jpg',
      sizes: '152x152',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/180x180.jpg',
      type: 'image/jpg',
      sizes: '180x180',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/192x192.jpg',
      type: 'image/jpg',
      sizes: '192x192',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/384x384.jpg',
      type: 'image/jpg',
      sizes: '384x384',
    },
    {
      src: 'https://alpha.wtf/icons/alpha/512x512.jpg',
      type: 'image/jpg',
      sizes: '512x512',
    },
  ],
  start_url: '/alpha',
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#000000',
  orientation: 'portrait-primary',
};
