import ShareReferral from '@/modules/AlphaPWA/OverlayPages/ShareReferral';
import { useOverlay } from '@/providers/OverlayProvider';
import s from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import ReferralEarning from '@/modules/AlphaPWA/OverlayPages/ReferralEarning';

const HEADER_HEIGHT = 73;
const NAVBAR_HEIGHT = 67;

export default function OverlayPages() {
  const { screen, setting } = useOverlay();
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const [isPadding, setIsPadding] = useState(false);

  useEffect(() => {
    if (screen) {
      if (setting) {
        if (setting.layout) {
          let calHeight = window.innerHeight;
          if (setting.header) {
            setTop(HEADER_HEIGHT);
            calHeight -= HEADER_HEIGHT;
          } else {
            setIsPadding(true);
          }

          if (setting.navBar) {
            calHeight -= NAVBAR_HEIGHT;
          }
          setHeight(calHeight);
        } else {
          setIsPadding(true);
          setHeight(window.innerHeight);
        }
      } else {
        setIsPadding(true);
        setHeight(window.innerHeight);
      }
    } else {
      setHeight(0);
    }
  }, [screen, setting]);

  if (screen === '' || !height) {
    return <></>;
  }
  return (
    <div
      className={cs(s.overlayPages, isPadding ? s.paddingTop : '')}
      style={{
        minHeight: height,
        height: height,
        top: top,
      }}
    >
      <div className={s.overlayPages__container}>
        {screen === 'SHARE_REFERRAL' && <ShareReferral />}
        {screen === 'REFERRAL_EARNING' && <ReferralEarning />}
      </div>
    </div>
  );
}
