import { BTC_L2_ADDRESS } from '@/configs';
import { TournamentContext } from '@/contexts/tournament-context';
import { IJackpotLeaderboardItem } from '@/interfaces/jackpot';
import { ethers } from 'ethers';
import { useContext, useState } from 'react';

const useGetTournamentRewards = () => {
  const { getRewards } = useContext(TournamentContext);
  const [isLoading, setIsLoading] = useState(true);
  const [rewards, setRewards] = useState<IJackpotLeaderboardItem[]>([]);

  const fetchRewards = async (
    tournamentId: string,
    isFilterReward: boolean = true
  ) => {
    if (tournamentId) {
      setIsLoading(true);
      var _rewards = await getRewards(tournamentId);
      // console.log('winners_GET', tournamentId, _rewards);
      const isRewardKey =
        !_rewards[0]?.potToken ||
        _rewards[0]?.potToken == BTC_L2_ADDRESS ||
        _rewards[0]?.potToken == ethers.constants.AddressZero;
      if (isFilterReward) {
        if (isRewardKey) {
          _rewards = _rewards.filter(e => (e.rewardKeyAmount ?? 0) != 0);
        } else {
          _rewards = _rewards.filter(e => (e.rewardTokenAmount ?? 0) != 0);
        }
      }
      setRewards(_rewards.map(e => ({ ...e, isKeyReward: isRewardKey })));
      setIsLoading(false);
    }
  };

  return { rewards, fetchRewards, isLoading };
};

export default useGetTournamentRewards;
