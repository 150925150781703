import { BTC_L2_ADDRESS } from '@/configs';
import { passTokenReducer } from '@/state/passToken';
import { formatAmountToClient, getErrorMessageContract } from '@/utils/helpers';
import BN from 'bignumber.js';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import CContractBase, { ETypes } from '../base';
import { CSwap } from '../swap';
import { ISwap } from '../swap/swap.interface';
import {
  IEstimateMultiSwap,
  IEstimateTokenSwap,
  IMakeInvestParams,
} from './passToken.interface';

class CPassToken extends CContractBase {
  swapContract = new CSwap();

  private tokenAmountSweep = useSelector(passTokenReducer).tokenAmountSweep;

  public makeInvest = async (params: IMakeInvestParams) => {
    try {
      await this.getTokenApprove({
        token_address: BTC_L2_ADDRESS,
        spender_address: params.token_address,
        need_approve: true,
        token_amount: 1,
      });

      await this.estimateTCGasFee({ type: ETypes.deposit_fund });
      const tx = await this.contract
        .getPassTokenContract(params.token_address)
        .connect(this.wallet)
        .depositFund(parseEther(params.btc_amount));

      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public getTokenByTwitterId = async (twitterId: string) => {
    try {
      return await this.contract
        .getPassTokenFactoryContract()
        .getTwitterToken(twitterId);
    } catch (error) {
      return '';
    }
  };

  public getTwitterIdByPassToken = async (passToken: string) => {
    try {
      return await this.contract
        .getPassTokenFactoryContract()
        .getTokenTwitter(passToken);
    } catch (error) {
      return '';
    }
  };

  public getCircleTreasury = async (params: any) => {
    try {
      const address = await this.contract
        .getPassTokenFactoryContract()
        .getTwitterToken(params?.twitterId);

      const fee = await this.contract
        .getPassTokenContract(address)
        .callStatic.collectFee();

      return {
        treasuryAddress: address,
        amountBTC: formatEther(fee.amountBTC),
        amountToken: formatEther(fee.amountToken),
      };
    } catch (error) {
      return {
        treasuryAddress: '',
        amountBTC: '0',
        amountToken: '0',
      };
    }
  };

  public getCircleTreasuryAddress = async (params: any) => {
    try {
      const address = await this.contract
        .getPassTokenFactoryContract()
        .getTwitterToken(params?.twitterId);

      return {
        treasuryAddress: address,
      };
    } catch (error) {
      return {
        treasuryAddress: '',
      };
    }
  };

  public estimateMultiSwap = async (params: IEstimateMultiSwap) => {
    try {
      this.configSDK();
    } catch (error) {
      throw error;
    }
  };

  public estimateTokenSwap = async (params: IEstimateTokenSwap) => {
    try {
      this.configSDK();
    } catch (error) {
      throw error;
    }
  };

  public buyMultiTokens = async (params: IEstimateMultiSwap) => {
    try {
      this.configSDK();

      for (let i = 0; i < params.tokens.length; i++) {
        const element = params.tokens[i];

        postMessage(
          JSON.stringify({
            address: element.address,
            status: 'processing',
          })
        );

        try {
          const _token: ISwap = {
            humanAmount: this.tokenAmountSweep.toString(),
            tokenIn: {
              symbol: 'BTC',
              address: BTC_L2_ADDRESS,
            } as any,
            tokenOut: {
              symbol: element.user_twitter_username,
              address: element.pass_token_address,
            } as any,
            receiver: this.wallet.address,
            slipNumber: 50,
            isExactIn: false,
          };

          const estimateAmount = await this.swapContract.estimateSwap({
            tokenIn: _token.tokenIn,
            tokenOut: _token.tokenOut,
            humanAmount: _token.humanAmount,
            isExactIn: false,
          });

          const eachParam: ISwap = {
            humanAmount: formatEther(new BN(estimateAmount?.[0]).toFixed()),
            tokenIn: {
              ..._token.tokenIn,
            },
            tokenOut: {
              ..._token.tokenOut,
            },
            receiver: this.wallet.address,
            slipNumber: 50,
            isExactIn: false,
          };

          const tx = await this.swapContract.swapToken(eachParam);
          postMessage(
            JSON.stringify({
              address: element.address,
              status: 'success',
              tx_hash: tx.txHash,
            })
          );
        } catch (error) {
          postMessage(
            JSON.stringify({
              address: element.address,
              status: 'error',
              message: getErrorMessageContract(error).message,
            })
          );
        }
      }

      return true;
    } catch (error) {
      throw error;
    }
  };

  public getFreeTokenBalance = async (token_address: string) => {
    try {
      const rs = await this.contract
        .getPassTokenContract(token_address)
        .connect(this.wallet)
        .freeBalanceOf(this.wallet.address);

      return formatAmountToClient(rs?.toString());
    } catch (error) {
      return 0;
    }
  };
}

export default CPassToken;
