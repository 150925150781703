export const TAPROOT_ADDRESS = 'taproot_address';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const GM_DESPOSIT_DATA = 'gm_deposit_data';
export const GM_DEPOSIT_ADDRESSES = 'gm_deposit_addresses';
export const WALLET_ADDRESS_CONNECT = 'wallet_connect_address';
export const PREV_CHAIN_ID = 'prev_chain_id';
export const TX_HASH = 'tx_hash';
export const CLAIM_START_DATE = 'csd';
export const FCM_TOKEN = 'fcm_token';
export const BACKUP_PRV_KEY = 'BACKUP_PRV_KEY';
export const TWITTER_TOKEN = 'twitter_token';
export const ACTIVITIES_TAB_INDEX = 'activities_tab_index';
export const APP_TAB_INDEX = 'app_tab_index';
export const UUID = 'uuid';
export const COPIED_PRV_KEY = 'copied_prv_key';
export const SHOW_NOTIFICATION = 'showed_notification';
export const FIRST_TIME_USER = 'first_time_user';
export const TWITTER_PROFILE = 'twitter_profile';

export const POPUP_REFERRAL_THROTTLE_TIME = 'popup_referral_throttle_time';
export const CLOSE_FORGING_BANNER = 'close_forging_banner';
export const CLOSE_33_BANNER = 'close_33_banner';
export const CLOSE_CIRCLE_BANNER = 'close_circle_banner';

export const FOLLOWER_INVITED_LIST = 'follower_invited_list';
export const HOME_TAB_INDEX = 'home_tab_index';
export const PROFILE_TAB_INDEX = 'profile_tab_index';
export const KEY_TAB_INDEX = 'key_tab_index';
export const TOKEN_TAB_INDEX = 'token_tab_index';
export const RECEIVED_REFERRAL_DONATION = 'received_referral_donation';

export const RETA_BTC_2_USDT = 'RETA_BTC_2_USDT';
export const RETA_ETH_2_USDT = 'RETA_ETH_2_USDT';

export const WELCOME_FROM_FT = 'WELCOME_FROM_FT';
export const PORTFOLIO_TAB_INDEX = 'PORTFOLIO_TAB_INDEX';
export const REPORT_SUMMARY = 'REPORT_SUMMARY';
export const CACHED_BTC_TIMER = 'btc_timer';
export const CACHED_BTC_PRICE = 'btc_price';
export const TRIBE_ACCESS_CODE = 'tribe_access_code';
export const OPENED_BOOST_FINISH_GAME = 'opened_boost_finish_game';
