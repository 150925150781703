import React, { useContext, useState } from 'react';
import * as S from './styled';
import { WalletContext } from '@/contexts/wallet-context';
import { Input } from '@/components/Input';
import { useAppDispatch } from '@/state/hooks';
import { closeModal } from '@/state/modal';
import { ethers } from 'ethers';
import accountStorage from '@/utils/account.storage';
import { Formik } from 'formik';
import OtpInput from 'react-otp-input';
import toast from 'react-hot-toast';
import QRCodeScanner from '@/components/QRCodeScanner';
import { CDN_URL } from '@/configs';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';

export const IMPORT_MODAL_KEY = 'IMPORT_MODAL_KEY';

interface IFormValue {
  prvKey: string;
}

const ImportModal = React.memo(() => {
  const { onLoginL2 } = useContext(WalletContext);
  const { onPostMessageWalletCreated } = useContentWindowMessage();

  const [pass, setPass] = useState(
    `${Math.floor(1000 + Math.random() * 9000)}`
  );
  const [confirmPass, setConfirmPass] = useState('');

  const dispatch = useAppDispatch();

  const [showQRCodeScanner, setShowQRCodeScanner] = useState(false);

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.prvKey) {
      errors.prvKey = 'Private key is required.';
    }
    if (values.prvKey) {
      try {
        new ethers.Wallet(values.prvKey).address;
      } catch (e) {
        errors.prvKey = 'Invalid private key.';
      }
    }

    return errors;
  };

  const _handleSubmit = async (payload: IFormValue): Promise<void> => {
    const isValid = pass.length === 4;
    if (isValid) {
      accountStorage.setAccount({
        prvKey: payload.prvKey,
        password: pass,
      });
      onLoginL2(pass);
      onPostMessageWalletCreated();
      dispatch(closeModal({ id: IMPORT_MODAL_KEY }));
    } else {
      toast.error('Incorrect passcode.');
    }
  };

  return (
    <Formik
      key="create-wallet"
      initialValues={{
        prvKey: '',
      }}
      validate={validateForm}
      onSubmit={_handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit: _handleSubmit,
        setFieldValue,
      }) => (
        <S.Content>
          <Input
            id="prvKey"
            title="PRIVATE KEY"
            value={values.prvKey}
            placeholder="Enter your private key"
            onChange={handleChange}
            errorMsg={errors.prvKey}
            rightElement={
              <img
                alt="qr-code-scanner"
                onClick={() => setShowQRCodeScanner(true)}
                className="ic-scanner"
                src={`${CDN_URL}/icons/scanner.png`}
                style={{ width: 24, height: 24 }}
              />
            }
          />
          {/* <S.Space /> */}
          {/* <S.PasscodeContainer>
            <p className="passcodeTitle">PASSCODE</p>
            <OtpInput
              value={pass}
              onChange={setPass}
              numInputs={4}
              renderSeparator={<S.PasscodeSeparator />}
              renderInput={props => (
                <S.PasscodeInput
                  {...props}
                  type="password"
                  inputMode="numeric"
                />
              )}
              containerStyle={{ width: '100%' }}
              inputType="tel"
            />
          </S.PasscodeContainer> */}

          {/* <S.PasscodeContainer style={{ marginTop: 16 }}>
            <p className="passcodeTitle">CONFIRM PASSCODE</p>
            <OtpInput
              value={confirmPass}
              onChange={setConfirmPass}
              numInputs={4}
              renderSeparator={<S.PasscodeSeparator />}
              renderInput={props => (
                <S.PasscodeInput
                  {...props}
                  type="password"
                  inputMode="numeric"
                />
              )}
              containerStyle={{ width: '100%' }}
              inputType="tel"
            />
          </S.PasscodeContainer> */}
          {/*<div style={{ marginTop: 24 }}>
            <CheckBox content="Save Passcode To Browser" />
          </div>*/}
          <S.ButtonCreate
            type="submit"
            onClick={() => _handleSubmit(values as any)}
            // onClick={handleSubmit}
          >
            Import
          </S.ButtonCreate>
          {showQRCodeScanner && (
            <QRCodeScanner
              onClose={() => setShowQRCodeScanner(false)}
              onSubmited={(prvKey: string) => {
                setFieldValue('prvKey', prvKey);
              }}
            />
          )}
        </S.Content>
      )}
    </Formik>
  );
});

ImportModal.displayName = 'ImportModal';

export default ImportModal;
