import {
  Button,
  Flex,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import cs from 'classnames';
import React, {
  forwardRef,
  PropsWithRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { formatCurrency } from '@/utils';
import styles from '../styles.module.scss';
import useDebounce from '@/hooks/useDebounce';

interface IFooter extends PropsWithRef<any> {
  sweepMax: number;
  selectKeys: any[];
  onSubmit: (_: any) => void;
  estimateTC: string;
  estimating: boolean;
  submitting: boolean;
  allowBuy: boolean;
  onChangeSweep: (_: any) => void;
  numProcessDone: number;
  isInvite?: boolean;
  showInvite?: boolean;
}

const Footer: React.FC<IFooter> = forwardRef((props, ref) => {
  const {
    sweepMax,
    selectKeys,
    onSubmit,
    estimateTC,
    estimating,
    submitting,
    allowBuy,
    onChangeSweep,
    numProcessDone,
    showInvite = false,
  } = props;

  const [numSweep, setNumSweep] = useState(selectKeys.length);

  const buyNumKeys = useMemo(() => selectKeys.length, [selectKeys.length]);
  const buyAmountTC = estimateTC;

  const isDisabled = buyNumKeys === 0 || estimating;

  const [showCheckBalance, setShowCheckBalance] = useState(false);

  useEffect(() => {
    setShowCheckBalance(false);
  }, [selectKeys.length]);

  useImperativeHandle(ref, () => {
    return {
      reset: () => setNumSweep(0),
      setNumSweep,
      setShowCheckBalance,
    };
  });

  return (
    <Flex className={styles.footer}>
      <Flex direction={'column'} flex={1} gap={1}>
        <Flex
          flex={1}
          gap={4}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text className={styles.label}>Sweep</Text>
          <Text
            fontWeight={'bold'}
            display={'flex'}
            alignItems={'center'}
            gap={'1px'}
          >
            {formatCurrency(buyAmountTC, 0, 6, 'BTC')} BTC
          </Text>
        </Flex>
        <Flex pl={4}>
          <Text className={styles.label}>({buyNumKeys})</Text>
          <Slider
            aria-label="slider-ex-1"
            defaultValue={buyNumKeys}
            value={Number(buyNumKeys)}
            max={sweepMax}
            onChange={onChangeSweep}
            // onChangeEnd={onChangeSweep}
            ml={10}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} py={2}>
        <Flex gap={2}>
          <Button
            // isLoading={submitting}
            isDisabled={isDisabled}
            onClick={() => {
              setShowCheckBalance(true);
              if (allowBuy) {
                onSubmit(false);
              }
            }}
            className={cs(
              styles.btnAction,
              styles.btnBuy,
              isDisabled && styles.isDisabled,
              !allowBuy && styles.isNotAllowBuy
            )}
            flexDirection={'column'}
          >
            <Text>Sweep</Text>
          </Button>
          {showInvite && (
            <Button
              // isLoading={submitting}
              isDisabled={isDisabled}
              onClick={() => {
                setShowCheckBalance(false);
                onSubmit(true);
              }}
              className={cs(
                styles.btnAction,
                styles.btnInvite,
                isDisabled && styles.isDisabledInvite,
                !allowBuy && styles.isNotAllowInvite
              )}
              flexDirection={'column'}
            >
              <Text>Send (3,3)</Text>
            </Button>
          )}
        </Flex>
        {!allowBuy && showCheckBalance && (
          <Text className={styles.errorText}>Not enough BTC balance</Text>
        )}
      </Flex>
    </Flex>
  );
});

export default Footer;
