import { ERC20 } from '@/contracts/interfaces/IERC20';
import { formatAmountFromChain } from '@/utils/helpers';
import { Wallet, ethers } from 'ethers';
import { isNativeToken } from '../../tokens/helpers';
import ERC20ABI from './abis/erc20.json';
import { TokenBalance } from '../types';

class ContractSimple {
  private erc20: ERC20 | undefined = undefined;
  private provider: ethers.providers.Provider;
  private wallet: Wallet | undefined = undefined;

  constructor(provider: ethers.providers.Provider) {
    this.provider = provider;
  }

  public getERC20Contract = (contractAddress: string) => {
    this.erc20 = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.provider
    ) as ERC20;

    return this.erc20;
  };

  public getBalanceERC20WithParams = async ({
    tokenAddress,
    walletAddress,
    decimals = 18,
    symbol,
    network,
  }: {
    tokenAddress: string;
    walletAddress: string;
    decimals?: number;
    symbol?: string;
    network?: string;
  }) => {
    let balance;
    try {
      if (isNativeToken(tokenAddress)) {
        balance = await this.provider.getBalance(walletAddress);
      } else {
        balance =
          await this.getERC20Contract(tokenAddress).balanceOf(walletAddress);
      }

      balance = formatAmountFromChain(balance?.toString(), decimals);

      // console.log(
      //   `[getBalanceERC20WithParams] - ${symbol} - ${tokenAddress} ==>> `,
      //   balance
      // );
    } catch (error) {
      console.log('[getBalanceERC20WithParams] ERROR  ', error);
      balance = 0;
    } finally {
      return String(balance);
    }
  };
}

export default ContractSimple;
