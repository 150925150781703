import { DEX_API, PERP_API_URL } from '@/configs';
import {
  ILiquidity,
  ILiquidityRecord,
  IListDexTokenRequest,
  IListDexTokenResponse,
} from '../interfaces/liquidity';
import { IRequestList } from '../interfaces/services';
import CApiClient from './apiClient';
import { getListLiquidity } from 'trustless-swap-sdk';
import { camelCaseKeys } from '@/utils/commons';

class CLiquidityAPI {
  private prefix = `${PERP_API_URL}/api`;

  private apiClient = new CApiClient().api;

  private addressL2: string = localStorage.getItem('ADDRESS_STORAGE') || '';

  private getUrl(url: string) {
    return `${this.prefix}/${url}`;
  }

  public getListPair = async (
    params: IRequestList
  ): Promise<ILiquidity[] | undefined> => {
    try {
      const response: ILiquidity[] = await this.apiClient.get(
        `${DEX_API}/swap/pair/apr/list/v2`,
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            ...params,
          },
        }
      );
      return camelCaseKeys(response || []);
    } catch (error) {}
  };

  public getDexTokens = async (
    params: IListDexTokenRequest
  ): Promise<IListDexTokenResponse | undefined> => {
    try {
      const response = (await this.apiClient.get(
        `${DEX_API}/token-explorer/tokens/v1`,
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            ...params,
          },
        }
      )) as IListDexTokenResponse;
      return response;
    } catch (error) {}
  };

  public scanTxHash = async (
    tx_hash: string
  ): Promise<IListDexTokenResponse | undefined> => {
    try {
      const response = (await this.apiClient.get(
        `${DEX_API}/sync/swap-v3/scan-transaction-hash`,
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            tx_hash,
          },
        }
      )) as IListDexTokenResponse;
      return response;
    } catch (error) {}
  };
}

export default CLiquidityAPI;
