import { PERP_API_URL } from '@/configs';
import CApiClient from './apiClient';
import {
  IPassToken,
  IPassTokenHoldingsResponse,
  IPassTokenParams,
  IPassTokensResponse,
  IKeyHolder,
  IMarketTokensRequest,
  IMarketTokensResponse,
} from '../interfaces/token';
import { IPlayerHolding } from '@/interfaces/api/player-share';
import qs from 'query-string';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { apiClient } from '@/services';
import {
  IAffiliateHistory,
  IReferralRewardPool,
  ISettingReferralRewardPoolBody,
} from '../interfaces/affiliate';
import { IRequestList } from '../interfaces/services';

class CPassTokenAPI {
  private prefixNBCKeys = `${PERP_API_URL}/api/nbc-keys`;
  private prefixPassToken = `${PERP_API_URL}/api/pass-token`;

  private apiClient = new CApiClient().api;
  private addressL2: string = localStorage.getItem('ADDRESS_STORAGE') || '';

  private getUrlNBCKeys(url: string) {
    return `${this.prefixNBCKeys}/${url}`;
  }

  private getUrlPassToken(url: string) {
    return `${this.prefixPassToken}/${url}`;
  }

  public getPassTokens = async (
    params: IPassTokenParams
  ): Promise<IPassTokensResponse> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlNBCKeys(`pass-tokens`),
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            ...params,
          },
        }
      )) as IPassTokensResponse;
      return response;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  public getKeyHolders = async (address?: string): Promise<IKeyHolder[]> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlNBCKeys('key-holders'),
        {
          params: {
            network: 'nos',
            address: address || this.addressL2,
            page: 1,
            limit: 99999,
          },
        }
      )) as IKeyHolder[];
      return response;
    } catch (error) {
      return [];
    }
  };

  public getPassTokenHolding = async (): Promise<
    IPassTokenHoldingsResponse | undefined
  > => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlPassToken('holding'),
        {
          params: {
            network: 'nos',
            address: this.addressL2,
          },
        }
      )) as IPassTokenHoldingsResponse;
      return response;
    } catch (error) {
      return undefined;
    }
  };

  // get poll address for transfer
  public getReferralRewardPool = async (
    address?: string
  ): Promise<IReferralRewardPool | undefined> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlPassToken('referral-reward/pool'),
        {
          params: {
            network: 'nos',
            address: address || this.addressL2,
          },
        }
      )) as IReferralRewardPool;
      return response;
    } catch (error) {
      return undefined;
    }
  };

  public getReferralRewardPools = async (
    params?: IRequestList
  ): Promise<IReferralRewardPool[]> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlPassToken('referral-reward/pools'),
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            ...params,
          },
        }
      )) as IReferralRewardPool[];
      return response;
    } catch (error) {
      return [];
    }
  };

  public settingReferralRewardPool = async (
    body: ISettingReferralRewardPoolBody
  ): Promise<IPassTokenHoldingsResponse | undefined> => {
    try {
      const response = (await this.apiClient.post(
        this.getUrlPassToken('referral-reward/setting'),
        body,
        {
          params: {
            network: 'nos',
            address: this.addressL2,
          },
        }
      )) as IPassTokenHoldingsResponse;
      return response;
    } catch (error) {
      return undefined;
    }
  };

  public getReferralRewardSpends = async (
    params: IRequestList
  ): Promise<IAffiliateHistory[]> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlPassToken('referral-reward/spends'),
        {
          params: {
            network: 'nos',
            address: this.addressL2,
          },
        }
      )) as IAffiliateHistory[];
      return response;
    } catch (error) {
      return [];
    }
  };

  public getBalancePassTokenHolding = async (params: {
    network?: string;
    address?: string;
    page?: number;
    limit?: number;
    player_address?: string;
  }) => {
    try {
      const res = await apiClient.get(this.getUrlPassToken('holding'), {
        params: { network: 'nos', address: this.addressL2, ...params },
      });
      return Object(camelCaseKeys(res));
    } catch (error) {
      return [];
    }
  };

  public getMarketTokens = async (
    params: IMarketTokensRequest
  ): Promise<IMarketTokensResponse> => {
    try {
      const response = (await this.apiClient.get(
        this.getUrlNBCKeys('tokens/v2'),
        {
          params: {
            network: 'nos',
            address: this.addressL2,
            ...params,
          },
        }
      )) as IMarketTokensResponse;
      return response;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };
}

export default CPassTokenAPI;
