import React, { useContext } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { getContractSigner } from '@/utils/signer';
import useProviderL2 from '@/hooks/useProviderL2';
import { USERINFO_CONTRACT } from '@/configs';
import userInfoABI from '@/abis/UserInfo.json';

const useUserInfoContractSigner = () => {
  const { keySetL2 } = useContext(WalletContext);
  const providerL2 = useProviderL2();

  const contractSigner = React.useMemo(() => {
    try {
      if (!keySetL2.prvKey || !providerL2) return undefined;
      const contractSigner = getContractSigner(
        USERINFO_CONTRACT,
        userInfoABI,
        providerL2,
        keySetL2.prvKey
      );
      return contractSigner;
    } catch (error) {
      return undefined;
    }
  }, [keySetL2, providerL2]);

  return contractSigner;
};

export default useUserInfoContractSigner;
