import { PERP_API_URL, TC_API_URL } from '@/configs';
import {
  BINANCE_PAIR,
  FeeRateName,
  ICollectedUTXOResp,
  IFeeRate,
  IPendingUTXO,
} from '@/interfaces/api/bitcoin';
import { apiClient } from '@/services';
import { isProduction } from '@/utils/commons';
import BigNumber from 'bignumber.js';
import CacheManager from './cache';
// import * as TC_SDK from 'trustless-computer-sdk';

const BINANCE_API_URL = 'https://api.binance.com/api/v3';
const WALLETS_API_PATH = '/wallets';

// Collected UTXO
export const getCollectedUTXO = async (
  btcAddress: string,
  tcAddress: string
): Promise<ICollectedUTXOResp | undefined> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const collected: any = await apiClient.get<ICollectedUTXOResp>(
      `${TC_API_URL}${WALLETS_API_PATH}/${btcAddress}`
    );
    const tempUTXOs = [...(collected?.txrefs || [])];
    let utxos;
    try {
      // const tcClient = new TC_SDK.TcClient(TC_SDK.Mainnet, TC_NETWORK_RPC);
      // utxos = await TC_SDK.aggregateUTXOs({
      //   tcAddress: tcAddress,
      //   btcAddress: btcAddress,
      //   utxos: [...tempUTXOs],
      //   tcClient,
      // });
    } catch (e) {
      utxos = [...tempUTXOs];
    }
    return {
      ...collected,
      txrefs: utxos || [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  } catch (err) {
    console.log(err);
  }
};

export const getPendingUTXOs = async (
  btcAddress: string
): Promise<IPendingUTXO[]> => {
  let pendingUTXOs = [];
  if (!btcAddress || !isProduction()) return [];
  try {
    const res = await fetch(
      `https://blockstream.info/api/address/${btcAddress}/txs`
    ).then(res => {
      return res.json();
    });
    pendingUTXOs = (res || []).filter(
      (item: IPendingUTXO) => !item.status.confirmed
    );
  } catch (err) {
    return [];
  }
  return pendingUTXOs;
};

export const getFeeRate = async (): Promise<IFeeRate> => {
  try {
    const res = await fetch('https://mempool.space/api/v1/fees/recommended');
    const fee: IFeeRate = await res.json();
    if (fee[FeeRateName.fastestFee] <= 10) {
      return {
        [FeeRateName.fastestFee]: 15,
        [FeeRateName.halfHourFee]: 10,
        [FeeRateName.hourFee]: 5,
      };
    }
    return fee;
  } catch (err: unknown) {
    console.log(err);
    return {
      [FeeRateName.fastestFee]: 25,
      [FeeRateName.halfHourFee]: 20,
      [FeeRateName.hourFee]: 15,
    };
  }
};

export const getTokenRate = async (
  pair: BINANCE_PAIR = 'ETH'
): Promise<number> => {
  try {
    const res: any = await apiClient.get(
      `${PERP_API_URL}/api/nbc-keys/token-price`
    );
    const rate = res[`${pair}`];
    return new BigNumber(rate).toNumber();
  } catch (err: unknown) {
    console.log(err);
    throw err;
  }
};

export type ListTokenRate = {
  [key in BINANCE_PAIR]: string | number;
};

export const getTokensRate = async (): Promise<ListTokenRate> => {
  let result: ListTokenRate = {
    ETH: '0.0',
    BTC: '0.0',
    LINK: '0.0',
    TC: '0.0',
  } as any;
  try {
    result = await apiClient.get(`${PERP_API_URL}/api/nbc-keys/token-price`);
  } catch (err: unknown) {
    console.log('[getTokensRate] err ', err);
  } finally {
    return result;
  }
};

export const getCacheTokensRate = async (): Promise<ListTokenRate> => {
  const result: ListTokenRate = await CacheManager.cachePromise({
    key: CacheManager.KEYS.RATE_TOKENS,
    promiseFunc: () => getTokensRate(),
    expiredTime: CacheManager.EXPIRED_TIME.RATE_TOKENS_EXPIRED_TIME,
  });
  return result;
};
