import FAQs from './index';
import {Flex, useDisclosure,} from '@chakra-ui/react';
import styles from './styles.module.scss';

import React from 'react';
import IcFAQ from "@/modules/AlphaPWA/ThreeThreeInvitations/faqs/IcFAQ";
import {openModal} from "@/state/modal";
import {useDispatch} from "react-redux";

const CREATE_FOC_MODAL_KEY = 'CREATE_FOC_MODAL_KEY';
const BtnOpenFAQs = () => {
  const {
    isOpen: isFAQsModalOpen,
    onOpen: onOpenFAQsModal,
    onClose: onCloseFAQsModal,
  } = useDisclosure({ id: 'faqs_33' });
  const dispatch = useDispatch();

  const onOpenChart = () => {
    // onOpenFAQsModal();
    dispatch(
      openModal({
        id: CREATE_FOC_MODAL_KEY,
        theme: 'dark',
        title: `FAQs`,
        className: styles.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <FAQs />
        ),
      })
    );
  };

  return (
    <>
      <Flex
        onClick={onOpenChart}
        cursor={'pointer'}
        // style={{
        //   backgroundImage: `url(${ASSETS_URL}/img/btn_faqs.png)`,
        // }}
        className={styles.btnMenuBar}
        alignItems={"center"}
        gap={1}
      >
        <IcFAQ />
        FAQs
      </Flex>
    </>
  );
};

export default BtnOpenFAQs;
