import { API_FOC } from '@/configs';
import {
  IBoosterConfig,
  IBooster,
  IBoosterPayload,
  GiftEarn,
  IGiftTask,
} from '@/interfaces/api/booster';
import createAxiosInstance from '@/services/http-client';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

const focClient = createAxiosInstance({
  baseURL: API_FOC,
});

const getBooster = async (address: string) => {
  try {
    return camelCaseKeys(
      await focClient.get(
        `boosters/wallet_address/${address}?save_message=true`
      )
    ) as IBooster;
  } catch (error) {
    throw error;
  }
};

const getBoosterConfigs = async () => {
  try {
    return camelCaseKeys(await focClient.get(`/boosters/configs`)) as any;
  } catch (error) {
    throw error;
  }
};

const submitBooster = async (payload: IBoosterPayload) => {
  try {
    return camelCaseKeys(await focClient.post(`/boosters`, payload)) as any;
  } catch (error) {
    throw error;
  }
};

const submitMetamaskAddressBooster = async (
  walletAddr: string,
  mmAddr: string
) => {
  try {
    return camelCaseKeys(
      await focClient.put(`/boosters/wallet_address/${walletAddr}`, {
        metamask_wallet_address: mmAddr,
      })
    ) as any;
  } catch (error) {
    throw error;
  }
};

const getGiftsTask = async (address: string) => {
  try {
    return (
      ((
        camelCaseKeys(
          await focClient.get(`/boosters/gifts/address/${address}?status=1`)
        ) as any
      )?.items as IGiftTask[]) || []
    );
  } catch (error) {
    throw error;
  }
};

const submitMarkedGiftTask = async (address: string, task: GiftEarn) => {
  try {
    return await focClient.get(
      `/boosters/gifts/address/${address}/actions/${task}`
    );
  } catch (error) {
    throw error;
  }
};

export {
  getBooster,
  getBoosterConfigs,
  submitBooster,
  submitMarkedGiftTask,
  getGiftsTask,
  submitMetamaskAddressBooster,
};
