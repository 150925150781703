import { getOriginRoot } from '@/utils/url';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { CookieValueTypes, OptionsType } from 'cookies-next/lib/types';

class CookieCSR {
  static getItem(name: string, opt: OptionsType = {}): CookieValueTypes {
    return getCookie(name, {
      ...opt,
      domain: getOriginRoot(window.location.origin),
    });
  }

  static setItem(name: string, value: string, opt: OptionsType = {}) {
    return setCookie(name, value, {
      ...opt,
      domain: getOriginRoot(window.location.origin),
    });
  }

  static deleteItem(name: string, opt: OptionsType = {}): void {
    return deleteCookie(name, {
      ...opt,
      domain: getOriginRoot(window.location.origin),
    });
  }
}

export { CookieCSR };
