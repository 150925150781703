import { ethers, Wallet } from 'ethers';
import useProviderL2 from '@/hooks/useProviderL2';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import Web3 from 'web3';
import { JsonRpcProvider } from '@ethersproject/providers';
import { useContext } from 'react';
import { WalletContext } from '@/contexts/wallet-context';

export interface ISendTCL2Params {
  amount: string;
  receiver: string;
  gasLimit?: number;
}

const getWalletSigner = (
  privateKey: string,
  provider: JsonRpcProvider
): Wallet => {
  const wallet = new Wallet(privateKey);
  return wallet.connect(provider);
};

const useSendTCL2 = () => {
  const { keySetL2 } = useContext(WalletContext);
  const providerL2 = useProviderL2();

  const getGasPrice = async (): Promise<string> => {
    if (!providerL2) return '0';
    const gasPrice = await providerL2.getGasPrice();
    return gasPrice.toString();
  };

  const onEstimateGas = async (params: ISendTCL2Params) => {
    if (!keySetL2.prvKey || !providerL2) return;
    const walletSigner = getWalletSigner(keySetL2.prvKey, providerL2);
    const gasLimit = await walletSigner.estimateGas({
      from: keySetL2.address,
      value: ethers.utils.parseEther(String(params.amount)),
      to: params.receiver,
    });
    return gasLimit.toNumber();
  };

  const onSendTC = async (params: ISendTCL2Params) => {
    if (!keySetL2.prvKey || !providerL2) return;
    const walletSigner = getWalletSigner(keySetL2.prvKey, providerL2);
    const tx: TransactionResponse = await walletSigner.sendTransaction({
      from: keySetL2.address,
      value: ethers.utils.parseEther(String(params.amount)),
      to: params.receiver,
      gasLimit: Web3.utils.toHex(params.gasLimit || 0),
    });
    await tx.wait();
  };

  return {
    onEstimateGas,
    onSendTC,
    getGasPrice,
  };
};

export default useSendTCL2;
