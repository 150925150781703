import { API_TOPUP } from '@/configs';
import { apiClient } from '.';
import CacheManager from './cache';
import { INetwork } from '@/state/networks/types';

const API_PATH = API_TOPUP + '/api';

export const getNetworkListAPI = async (): Promise<INetwork[]> => {
  try {
    const res: INetwork[] = await apiClient.get(`${API_PATH}/list-network`);
    return res;
  } catch (error) {
    console.log('[getNetworkListAPI] error: ', error);
    return [];
  }
};

export const getCacheAllTokens = async (): Promise<INetwork[]> => {
  const result: INetwork[] = await CacheManager.cachePromise({
    key: CacheManager.KEYS.NETWORK_LIST,
    promiseFunc: () => getNetworkListAPI(),
    expiredTime: CacheManager.EXPIRED_TIME.ALL_TOKENS,
  });
  return result;
};
