import { useContext, useEffect } from 'react';

import { WalletContext } from '@/contexts/wallet-context';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { isEmpty } from 'lodash';
import { loadBalanceHandler } from './actions';
import { getListTokenFollowSelector } from './selector';

let IntervalTimerRef: any = undefined;
let LOAD_BALANCE_INTERVAL_TIME = 10 * 1000; // 10s

const WalletUpdater = () => {
  const dispatch = useAppDispatch();
  const tokenFollowIdsList = useAppSelector(getListTokenFollowSelector);
  const { addressL2 } = useContext(WalletContext);

  const clearIntervalTimer = () => {
    clearInterval(IntervalTimerRef);
    IntervalTimerRef = undefined;
  };

  useEffect(() => {
    if (!addressL2 || !tokenFollowIdsList || isEmpty(tokenFollowIdsList)) {
      clearIntervalTimer();
      return;
    }
    if (IntervalTimerRef) {
      clearIntervalTimer();
    }

    dispatch(loadBalanceHandler());
    IntervalTimerRef = setInterval(() => {
      dispatch(loadBalanceHandler());
    }, LOAD_BALANCE_INTERVAL_TIME);

    return () => {};
  }, [addressL2, tokenFollowIdsList, dispatch]);

  return <></>;
};

export default WalletUpdater;
