import { PERP_API_URL } from "@/configs";
import createAxiosInstance from "./http-client";
import { TWITTER_TOKEN } from "@/constants/storage-key";
import {
  ICheckIfCanClaimParams,
  IClaimLuckyMoneyParams,
  IClaimLuckyMoneyResponse,
  IGenerateDonationInfoParams,
  IGenerateDonationInfoResponse,
  IGetListHoldingTokenParams,
  IGetListPublicEventParams,
  IPublicEventItem,
  IRequestScanTxHashParams
} from "@/interfaces/api/donate";
import { camelCaseKeys, snakeCaseKeys } from "@/utils/commons";
import queryString from "query-string";
import localStorage from '@/utils/localstorage';

// All APIs here need authentication token

const apiClient = createAxiosInstance({
  baseURL: PERP_API_URL,
})

export const setBearerToken = (token: string | string[]) => {
  if (token && apiClient) {
    apiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};

setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

export const getDonationInfo = async (params: IGenerateDonationInfoParams): Promise<IGenerateDonationInfoResponse> => {
  const transformedParams = snakeCaseKeys<Record<string, string>>(params);
  const qs = queryString.stringify(transformedParams);
  const res = await apiClient.get(`/api/alpha-keys/donate/info?${qs}`);
  return Object(camelCaseKeys(res));
}

export const getListPublicEvents = async (params: IGetListPublicEventParams): Promise<Array<IPublicEventItem>> => {
  const transformedParams = snakeCaseKeys<Record<string, string>>(params);
  const qs = queryString.stringify(transformedParams);
  const res = await apiClient.get(`/api/alpha-keys/donate/public-event?${qs}`);
  return Object(camelCaseKeys(res));
}

export const getListHoldingTokens = async (params: IGetListHoldingTokenParams) => {
  const transformedParams = snakeCaseKeys<Record<string, string>>(params);
  const qs = queryString.stringify(transformedParams);
  const res = await apiClient.get(`/api/alpha-keys/donate/holding?${qs}`);
  return Object(camelCaseKeys(res));
}

export const checkIfCanClaim = async (params: ICheckIfCanClaimParams) => {
  const transformedParams = snakeCaseKeys<Record<string, string>>(params);
  const qs = queryString.stringify(transformedParams);
  const res = await apiClient.get(`/api/alpha-keys/donate/validate-claim?${qs}`);
  return res;
}

export const claimLuckyMoney = async (params: IClaimLuckyMoneyParams): Promise<IClaimLuckyMoneyResponse> => {
  try {
    const transformedParams = snakeCaseKeys<Record<string, string>>(params);
    const qs = queryString.stringify(transformedParams);
    const res = await apiClient.post(`/api/alpha-keys/donate/claim?${qs}`);
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    return {
      errorCode: Object(err).code
    };
  }
}

export const requestScanTxHash = async (params: IRequestScanTxHashParams) => {
  const transformedParams = snakeCaseKeys<Record<string, string>>(params);
  const qs = queryString.stringify(transformedParams);
  const res = await apiClient.get(`/api/alpha-keys/donate/scan-hash?${qs}`);
  return Object(camelCaseKeys(res));

}
