import { createSlice } from '@reduxjs/toolkit';
import { PREFIX, actionCreators, fetchNetworkList } from './actions';
import { NetworkState } from './types';
import { NETWORK_ALL } from './constants';

export const initialState: NetworkState = {
  networkList: [],
  currentNetworkFitler: NETWORK_ALL,

  isFetching: true,
  isFetched: false,
  error: undefined,
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNetworkList.pending, state => {
        state.networkList = [];
        state.isFetching = true;
        state.isFetched = false;
      })
      .addCase(fetchNetworkList.fulfilled, (state, action) => {
        state.networkList = action.payload;
        state.isFetching = false;
        state.isFetched = true;
      })
      .addCase(fetchNetworkList.rejected, (state, action) => {
        state.networkList = [];
        state.isFetching = false;
        state.isFetched = true;
        state.error = action.error;
      });
  },
});

export const networkActions = slice.actions;

export default slice.reducer;
