import AppLoading from '@/components/AppLoading';
import GCUserAvatar from '@/components/GroupCall/UserAvatar';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import { WalletContext } from '@/contexts/wallet-context';
import TipMessages from '@/modules/AlphaPWA/DirectMessage/VoiceRoom/TipMessages';
import { useAgora } from '@/providers/AgoraProvider/hooks';
import errorLogger from '@/services/errorLogger';
import { useAppDispatch } from '@/state/hooks';
import { openModal } from '@/state/modal';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import cs from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import LeaveJamConfirmModal, {
  LEAVE_JAM_CONFIRM_MODAL,
} from './LeaveConfirmModal';
import s from './VoiceRoom.module.scss';

type Props = {
  leaveVoiceRoom: () => void;
};

enum UserType {
  Speaker,
  Audience,
}

const VoiceRoom = ({ leaveVoiceRoom }: Props) => {
  const dispatch = useAppDispatch();
  const { addressL2 } = useContext(WalletContext);
  const [firstJoin, setFirstJoin] = useState(true);
  const [requestSent, setRequestSent] = useState(false);
  const [hasMicPermission, setHasMicPermission] = useState(true);

  const {
    isUnmuted,
    uid,
    toggleMicrophoneMuted,
    start,
    activeChannelMapUsers,
    sendRequestFromAudienceToSpeaker,
    sendApproveFromSpeakerToAudience,
    sendDeclineFromSpeakerToAudience,
    requestSpeakerQueue,
  } = useAgora();

  const speakersOnCallList = Object.values(activeChannelMapUsers).filter(
    user => user.hasAudio
  );

  const audiencesOnCallList = Object.values(activeChannelMapUsers).filter(
    user => !user.hasAudio
  );

  const participants = [...speakersOnCallList, ...audiencesOnCallList];

  const userType = useMemo(
    () =>
      Object.values(activeChannelMapUsers).find(user => user.uid === uid)
        ?.hasAudio
        ? UserType.Speaker
        : UserType.Audience,
    [activeChannelMapUsers, uid]
  );

  const handleClickMute = () => {
    if (!start) return;

    toggleMicrophoneMuted();

    if (!isUnmuted && firstJoin) {
      setFirstJoin(false);
    }
  };

  const handleClickRequestJoin = () => {
    setRequestSent(true);
    setTimeout(() => {
      setRequestSent(false);
    }, 5000);

    sendRequestFromAudienceToSpeaker();
  };

  const handleAcceptRequest = (token: string) => {
    sendApproveFromSpeakerToAudience(token);
  };

  const handleLeaveRoom = () => {
    if (userType === UserType.Speaker && speakersOnCallList.length === 1) {
      // open modal
      dispatch(
        openModal({
          id: LEAVE_JAM_CONFIRM_MODAL,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          render: () => (
            <LeaveJamConfirmModal
              onConfirm={() => {
                leaveVoiceRoom();
                setFirstJoin(false);
              }}
            />
          ),
        })
      );
      return;
    } else {
      leaveVoiceRoom();
      setFirstJoin(false);
    }
  };

  const checkMicroPermission = async () => {
    try {
      const AgoraRTC = (await import('agora-rtc-react')).default;

      await AgoraRTC.createMicrophoneAudioTrack();
      // await AgoraRTC.createMicrophoneAudioTrack();
    } catch (error) {
      // dispatch(
      //   openModal({
      //     id: MICRO_PERMISSION_MODAL,
      //     theme: 'dark',
      //     modalProps: {
      //       centered: true,
      //       zIndex: 9999999,
      //     },
      //     hideCloseButton: true,
      //     render: () => <MicroPermissionModal />,
      //   })
      // );
      setHasMicPermission(false);
      errorLogger.report({
        action: 'MICROPHONE_PERMISSION',
        address: addressL2,
        error: 'Cannot get user microphone permission',
        info: JSON.stringify({
          error,
        }),
      });
    }
  };

  useEffect(() => {
    if (start) {
      checkMicroPermission();
    }
  }, [start]);

  useEffect(() => {
    if (isUnmuted) {
      setFirstJoin(false);
    }
  }, [isUnmuted]);

  return (
    <div className={s.wrapper}>
      {!start && (
        <div className={s.loading}>
          <Flex alignItems={'center'} flexDirection={'column'}>
            <AppLoading />
            <p>Joining Jam...</p>
          </Flex>
        </div>
      )}

      {/* {participants.length > 0 && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          <h4 className={s.heading}>
            <span>{participants.length} </span>
            New Bitcoiners join the meeting
          </h4>
        </Flex>
      )} */}

      {!hasMicPermission && (
        <div className={s.permission_wrapper}>
          <div className={s.permission_icon}>
            <SvgInset
              size={40}
              svgUrl={`${CDN_URL_ICONS}/ic-mic-warning.svg`}
            />
          </div>
          <div className={s.permission_content}>
            Please go to Settings -{'>'} Privacy & Security -{'>'} Microphone -
            {'>'} turn access on for New Bitcoin City so you can use your voice
            to talk and engage with others.
          </div>
        </div>
      )}

      <Box className={s.listItem}>
        <div className={s.listParticipant}>
          {participants &&
            participants.length > 0 &&
            participants.map(user => (
              <GCUserAvatar
                user={user.userInfo}
                key={user.uid}
                uid={user.uid}
                volume={user.volume}
                isSpeaker={!!user.hasAudio}
              />
            ))}
          <div />
          <div />
          <div />
        </div>
      </Box>

      {/* Render tip message */}
      <TipMessages />

      <div className={s.controllers}>
        {userType === UserType.Speaker && (
          <Box position="relative" className={s.requested_wrapper}>
            {requestSpeakerQueue &&
              requestSpeakerQueue.length > 0 &&
              requestSpeakerQueue.map(item => {
                const avatar = Object.values(activeChannelMapUsers).find(
                  user => user.uid === item.uid
                )?.userInfo?.twitterAvatar;
                const name = Object.values(activeChannelMapUsers).find(
                  user => user.uid === item.uid
                )?.userInfo?.twitterName;
                return (
                  <Box className={s.request_box}>
                    <Flex
                      alignItems={'center'}
                      gap="4px"
                      className={s.requested_info}
                      mb="12px"
                    >
                      <Avatar
                        className={s.avatar}
                        url={avatar}
                        address={item?.address || ''}
                        width={24}
                      ></Avatar>
                      <span>{name || item.address}</span>
                      request to speak
                    </Flex>
                    <Flex className={s.request_cta}>
                      <button
                        className={s.decline}
                        onClick={() => {
                          sendDeclineFromSpeakerToAudience(item.tokenAddress);
                        }}
                      >
                        Decline
                      </button>
                      <button
                        className={s.accept}
                        onClick={() => handleAcceptRequest(item.tokenAddress)}
                      >
                        Accept
                      </button>
                    </Flex>
                  </Box>
                );
              })}
          </Box>
        )}

        <div className={s.heading_wrapper}>
          <div className={s.end_call}>
            <button onClick={handleLeaveRoom}>
              <SvgInset
                size={24}
                svgUrl={`${CDN_URL_ICONS}/ic-leave-call.svg`}
              />
              Leave
            </button>
          </div>
          {userType === UserType.Speaker ? (
            <Tooltip
              label={<div className={s.tooltip}>You are on mute</div>}
              placement="top"
              hasArrow
              bg={'#fff'}
              borderRadius={'8px'}
              isOpen={firstJoin}
            >
              <div
                className={cs(s.mute_controller, { [s.muted]: !isUnmuted })}
                onClick={handleClickMute}
              >
                {isUnmuted ? (
                  <button>
                    <SvgInset
                      size={24}
                      svgUrl={`${CDN_URL_ICONS}/ic-mute.svg`}
                    />
                    <span>Mute</span>
                  </button>
                ) : (
                  <button>
                    <SvgInset
                      size={24}
                      svgUrl={`${CDN_URL_ICONS}/ic-unmute.svg`}
                    />
                    <span>Mute</span>
                  </button>
                )}
              </div>
            </Tooltip>
          ) : (
            <div
              className={cs(s.mute_controller, s.muted, s.request_btn)}
              onClick={handleClickRequestJoin}
            >
              <button
                className={cs({ [s.request_sent]: requestSent })}
                disabled={requestSent || !start}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M12.9729 15.0108C12.8959 14.8238 12.9339 14.6088 13.0769 14.4658L15.718 11.8257C15.861 11.6827 16.076 11.6388 16.263 11.7178C16.45 11.7958 16.572 11.9777 16.572 12.1797C16.572 12.2307 16.5641 12.2909 16.5501 12.3389C16.1091 13.7519 15.0021 14.8579 13.5911 15.2969C13.5431 15.3119 13.4931 15.3189 13.4431 15.3189C13.2381 15.3199 13.0509 15.1978 12.9729 15.0108ZM19.25 10.2498C18.836 10.2498 18.5 10.5858 18.5 10.9998C18.5 14.4458 15.696 17.2498 12.25 17.2498C11.711 17.2498 11.1811 17.1807 10.6751 17.0447C10.2721 16.9397 9.86313 17.1757 9.75613 17.5757C9.64913 17.9757 9.88691 18.3867 10.2859 18.4937C10.6809 18.5997 11.088 18.6607 11.5 18.7007V20.9998C11.5 21.4138 11.836 21.7498 12.25 21.7498C12.664 21.7498 13 21.4138 13 20.9998V18.7117C16.923 18.3337 20 15.0198 20 10.9998C20 10.5858 19.664 10.2498 19.25 10.2498ZM21.7801 2.46975C21.4871 2.17675 21.012 2.17675 20.719 2.46975L16.698 6.49075C16.441 4.25175 14.555 2.49978 12.25 2.49978C11.06 2.49978 9.93008 2.96985 9.06008 3.81985C8.22008 4.67985 7.75002 5.80978 7.75002 6.99978V10.9998C7.75002 12.1868 8.22505 13.3087 9.05105 14.1377L7.80105 15.3877C6.64105 14.2107 6.00002 12.6548 6.00002 10.9998C6.00002 10.5858 5.66402 10.2498 5.25002 10.2498C4.83602 10.2498 4.50002 10.5858 4.50002 10.9998C4.50002 13.0558 5.29599 14.9878 6.74099 16.4488L2.71999 20.4697C2.42699 20.7627 2.42699 21.2378 2.71999 21.5308C2.86599 21.6768 3.05802 21.7508 3.25002 21.7508C3.44202 21.7508 3.63405 21.6778 3.78005 21.5308L21.7801 3.53079C22.0731 3.23679 22.0731 2.76275 21.7801 2.46975Z"
                    fill="black"
                  />
                </svg>
                <span>Request to speak</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceRoom;
