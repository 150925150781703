import { CDN_URL_IMAGES } from '@/configs';
import { isProduction } from '@/utils/commons';
import web3 from 'web3';

export const ETH_SCAN_URL = 'https://etherscan.io';
export const TURBO_TOKEN_SYMBOL = 'TURBO';
export const PEPE_TOKEN_SYMBOL = 'PEPE';
export const TOKEN_ICON_DEFAULT =
  'https://cdn.trustless.computer/upload/1683530065704444020-1683530065-default-coin.svg';
export const DEFAULT_GAS_PRICE = 1e9;
export const MaxUint256 = web3.utils.hexToNumberString(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
);

export const CONTRACT_METHOD_IDS = {
  APPROVE: '0x095ea7b3',
  BRIDGE_TC_DEPOSIT: '0x8340f549',
};

export const METAMASK_DOWNLOAD_PAGE = 'https://metamask.io/download/';
export const ISSUE_SHARES_URL = `https://${isProduction() ? '' : 'dev.'
  }newbitcoincity.com/player-shares/issue-shares`;

export const NBC_URL = `https://${isProduction() ? '' : 'dev.'}alpha.wtf`;

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
export const DEFAULT_LIGHTGRAY_AVATAR = `${CDN_URL_IMAGES}/bg_light_gray.jpg`;

