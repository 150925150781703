import { RPC_PROVIDERS } from '@/constants/providers';
import { INetworkIDsMap } from '../networks/types';
import { ITokenMultiChain } from '../tokens/types';
import { ChainId } from '@/constants/chains_v2';
import { JsonRpcProvider } from '@ethersproject/providers';
import ContractSimple from './contract/contract';
import {
  TokenBalance,
  TokenFollowBalanceMap,
  ContractFactoryMap,
  FetchBalanceFromListTokenParasm,
  GetTotalBalanceParams,
  TotalBTCBalanceFactory,
} from './types';
import BigNumber from 'bignumber.js';
import {
  formatCurrencyV2,
  getDecimalsFromHumanAmount,
  removeTrailingZeroes,
} from '@/utils';
import { ethers } from 'ethers';
import { isInValidAmount } from '@/utils/helpers';
import { isEmpty } from 'lodash';

const ContractFactory: ContractFactoryMap = {};
const ProviderFactory: any = {};

export const providerFactory = (rpcURL: string) => {
  if (!ProviderFactory[rpcURL]) {
    ProviderFactory[rpcURL] = new ethers.providers.JsonRpcProvider(rpcURL);
  }
  return ProviderFactory[rpcURL];
};

const getContractInstanceByProvider = (proivder: JsonRpcProvider) => {
  const url = proivder.connection.url;
  const contractIntance = ContractFactory[url];

  if (!contractIntance) {
    ContractFactory[url] = new ContractSimple(proivder);
    return ContractFactory[url];
  } else {
    return contractIntance;
  }
};

export const getTokenBalanceFormat = (
  token: ITokenMultiChain,
  tokenBalance: string
) => {
  const rate2USD = token.priceUsd;
  const tokenBalance_BN = new BigNumber(tokenBalance);
  const tokenBalance2USD_BN = new BigNumber(tokenBalance_BN).multipliedBy(
    rate2USD
  );
  const tokenBalance2USD = tokenBalance2USD_BN.toFixed();

  const token2USDdecimals = getDecimalsFromHumanAmount({
    amount: Number(tokenBalance2USD),
  });

  const tokenBalance2USDFormatted = formatCurrencyV2({
    amount: tokenBalance2USD_BN.toFixed(),
    decimals: 2,
  });

  const decimals = getDecimalsFromHumanAmount({
    amount: Number(tokenBalance),
  });

  const tokenBalanceFormatted = formatCurrencyV2({
    amount: tokenBalance_BN.toFixed(6),
    decimals: decimals,
  });

  return {
    rate2USD: rate2USD,
    tokenBalance: tokenBalance,
    tokenBalanceFormatted: removeTrailingZeroes(tokenBalanceFormatted),
    tokenBalance2USD: tokenBalance2USD,
    tokenBalance2USDFormatted: removeTrailingZeroes(tokenBalance2USDFormatted),
  };
};

const fetchBalanceFromListToken = async (
  params: FetchBalanceFromListTokenParasm
) => {
  try {
    const { walletAddres, listTokenFollowing, networkIDsMap } = params;

    if (!walletAddres || !listTokenFollowing || !networkIDsMap) {
      return;
    }

    let jobList: any[] = [];

    listTokenFollowing.map(token => {
      const network = networkIDsMap[token.networkId];
      if (!network) {
        return;
      }

      const provider = providerFactory(network.rpcEndpoint);
      const contractInstance = getContractInstanceByProvider(provider);

      jobList.push(
        contractInstance.getBalanceERC20WithParams({
          tokenAddress: token.tokenId,
          walletAddress: walletAddres,
          decimals: token.decimals,
          symbol: token.symbol,
          network: token.network,
        })
      );
    });
    const listBalance: string[] = await Promise.all(jobList);
    return listBalance;
  } catch (error) {
    console.log('[fetchBalanceFromListToken] ERROR ', error);
    throw error;
  }
};

export const getTotalBTCBalance = (params: GetTotalBalanceParams) => {
  const {
    listTokenFollowing,
    currentTotalBTCBalance,
    tokenBTC,
    tokenBalanceMap,
    isFetchingFirstTime,
  } = params;
  let totalBalanceDefault: TotalBTCBalanceFactory = {
    rateBTC2USD: '0',
    totalBalanceUSD: '0',
    totalBalanceUSDFormatted: '0',
    totalBalanceBTC: '0',
    totalBalanceBTCFormatted: '0',
  };

  try {
    if (
      !listTokenFollowing ||
      isEmpty(listTokenFollowing) ||
      !tokenBTC ||
      !tokenBalanceMap
    ) {
      if (currentTotalBTCBalance) {
        return currentTotalBTCBalance;
      } else {
        return currentTotalBTCBalance;
      }
    }

    const rateBTC2USD = tokenBTC.priceUsd;

    let listTokenBalance: any[] = [];

    Object.values(tokenBalanceMap).map(token => {
      listTokenBalance.push(token.tokenBalance2USD || '0');
    });

    let totalBalanceUSD_BN = BigNumber.sum(...listTokenBalance) || '0';

    let totalBalanceUSD = totalBalanceUSD_BN.toFixed();

    const totalBalanceUSDFormatted = formatCurrencyV2({
      amount: totalBalanceUSD,
      decimals: 2,
    });

    let totalBalanceBTC = new BigNumber(
      totalBalanceUSD_BN.div(rateBTC2USD)
    ).toFixed();

    let totalBalanceBTCFormatted = isInValidAmount(totalBalanceBTC)
      ? '0'
      : formatCurrencyV2({
          amount: totalBalanceBTC,
          decimals: 6,
        });

    const result: TotalBTCBalanceFactory = {
      totalBalanceUSD: totalBalanceUSD,
      totalBalanceUSDFormatted: totalBalanceUSDFormatted,
      totalBalanceBTC: totalBalanceBTC,
      totalBalanceBTCFormatted: totalBalanceBTCFormatted,
      rateBTC2USD: rateBTC2USD?.toString(),
    };

    return result;
  } catch (error) {
    console.log('[LOG][getTotalBalanceSelector] 555 ERROR ', error);
    if (currentTotalBTCBalance) {
      return currentTotalBTCBalance;
    } else {
      return totalBalanceDefault;
    }
  }
};

export { fetchBalanceFromListToken };
