import { SupportedChainId, CHAINS_INFO } from '@/constants/chains';
import { WalletError, WalletErrorCode } from '@/enums/wallet-error';
import { WalletEvent } from '@/enums/wallet-event';
import { IResourceChain } from '@/interfaces/chain';
import {
  ICustomTransaction,
  ITransferPayload,
  ProviderRpcError,
  WalletOperationReturn,
} from '@/interfaces/wallet';
import { MetaMaskInpageProvider } from '@metamask/providers';
import { getChainList } from '@/services/chainlist';
import Web3 from 'web3';
import { provider, RLPEncodedTransaction, TransactionConfig } from 'web3-core';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import { TC_LAYER2_NETWORK_RPC } from '@/configs';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { ERC20 } from '@/contracts/interfaces/IERC20';
import ERC20ABI from '@/contracts/abis/erc20.json';
import useProviderL2 from '@/hooks/useProviderL2';
import { useWeb3React } from '@web3-react/core';
import { getContract } from '@/utils';
import { formatEther, parseEther } from 'ethers/lib/utils';

export class WalletManager {
  web3Provider: Web3 | null = null;
  metamaskProvider: MetaMaskInpageProvider | null = null;
  contracts: Record<string, Contract | null> = {};

  constructor() {
    this.initiateWeb3Provider();
    this.initiateMetamaskProvider();
  }

  initiateWeb3Provider(): void {
    this.web3Provider = new Web3(window.ethereum as provider);
  }

  getWeb3Provider(): Web3 {
    if (!this.web3Provider) {
      this.initiateWeb3Provider();
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.web3Provider!;
  }

  initiateMetamaskProvider(): void {
    this.metamaskProvider = window.ethereum as MetaMaskInpageProvider;
  }

  getMetamaskProvider(): MetaMaskInpageProvider {
    if (!this.web3Provider) {
      this.initiateMetamaskProvider();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.metamaskProvider!;
  }

  // Wallet simple getters

  isInstalled(): boolean {
    try {
      return this.getMetamaskProvider().isMetaMask;
    } catch (_: unknown) {
      return false;
    }
  }

  isConnected(): boolean {
    try {
      return this.getMetamaskProvider().isConnected();
    } catch (_: unknown) {
      return false;
    }
  }

  async isChainSupported(chainID: number): Promise<boolean> {
    try {
      const currentChainID = await this.getWeb3Provider().eth.getChainId();
      return chainID === currentChainID;
    } catch (err: unknown) {
      return false;
    }
  }

  async connectedAddress(): Promise<string | null> {
    const addresses = await this.getMetamaskProvider().request({
      method: 'eth_accounts',
    });
    if (addresses && Array.isArray(addresses)) {
      return addresses[0];
    }
    return null;
  }

  // Wallet methods
  // Should return WalletOperationReturn for more information

  async connect(): Promise<WalletOperationReturn<string | null>> {
    try {
      const addresses = await this.getMetamaskProvider().request({
        method: 'eth_requestAccounts',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      if (addresses && Array.isArray(addresses)) {
        return {
          isError: false,
          isSuccess: true,
          message: '',
          data: addresses[0],
        };
      }
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_CONNECT,
        data: null,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_CONNECT,
        data: null,
      };
    }
  }

  async getBalance(
    walletAddress: string
  ): Promise<WalletOperationReturn<string | null>> {
    try {
      const balance =
        await this.getWeb3Provider().eth.getBalance(walletAddress);
      return {
        isError: false,
        isSuccess: true,
        message: '',
        data: balance,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: '',
        data: null,
      };
    }
  }

  async signMessage(
    message: string,
    walletAddress: string
  ): Promise<WalletOperationReturn<string | null>> {
    try {
      const signature = await this.getWeb3Provider().eth.personal.sign(
        Web3.utils.fromUtf8(message),
        walletAddress,
        ''
      );

      return {
        isError: false,
        isSuccess: true,
        message: '',
        data: signature,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_CONNECT,
        data: null,
      };
    }
  }

  async signTransaction(
    tx: TransactionConfig
  ): Promise<WalletOperationReturn<RLPEncodedTransaction | null>> {
    try {
      const transation = await this.getWeb3Provider().eth.signTransaction(tx);

      return {
        isError: false,
        isSuccess: true,
        message: '',
        data: transation,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_CONNECT,
        data: null,
      };
    }
  }

  async requestSwitchChain(
    chainID: number
  ): Promise<WalletOperationReturn<unknown>> {
    try {
      const metamaskProvider = this.getMetamaskProvider();
      const web3Provider = this.getWeb3Provider();

      try {
        await metamaskProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: web3Provider.utils.toHex(chainID),
            },
          ],
        });
      } catch (err: unknown) {
        if ((err as ProviderRpcError).code === WalletErrorCode.NO_CHAIN) {
          await this.requestAddChain(chainID);
        } else {
          throw err;
        }
      }

      return {
        isError: false,
        isSuccess: true,
        message: 'OK',
        data: null,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_SWITCH_CHAIN,
        data: null,
      };
    }
  }

  /**
   *
   * @param chainID
   * @returns
   */
  async requestAddChain(
    chainID: number
  ): Promise<WalletOperationReturn<unknown>> {
    try {
      let chain;
      if (chainID === SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2) {
        chain = CHAINS_INFO.find((c: IResourceChain) => c.chainId === chainID);
      } else {
        const chainList = await getChainList();
        chain = chainList.find((c: IResourceChain) => c.chainId === chainID);
      }
      if (!chain) {
        return {
          isError: true,
          isSuccess: false,
          message: WalletError.FAILED_ADD_CHAIN,
          data: null,
        };
      }
      const web3Provider = this.getWeb3Provider();
      const metamaskProvider = this.getMetamaskProvider();

      const params = {
        chainId: web3Provider.utils.toHex(chain.chainId),
        chainName: chain.name,
        nativeCurrency: {
          name: chain.nativeCurrency.name,
          symbol: chain.nativeCurrency.symbol,
          decimals: chain.nativeCurrency.decimals,
        },
        rpcUrls: chain.rpc,
        blockExplorerUrls: [
          chain.explorers &&
          chain.explorers.length > 0 &&
          chain.explorers[0].url
            ? chain.explorers[0].url
            : chain.infoURL,
        ],
      };

      await metamaskProvider.request({
        method: 'wallet_addEthereumChain',
        params: [params],
      });

      return {
        isError: false,
        isSuccess: true,
        message: 'OK',
        data: null,
      };
    } catch (_: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_ADD_CHAIN,
        data: null,
      };
    }
  }

  async getRawTransactionByHash(
    txHash: string
  ): Promise<WalletOperationReturn<ICustomTransaction | null>> {
    try {
      let web3Provider: any = null;
      if (!window.provider) {
        window.provider = new Web3(TC_LAYER2_NETWORK_RPC) as any;
      }

      web3Provider = window.provider;
      const tx = (await web3Provider.eth.getTransaction(
        txHash
      )) as ICustomTransaction;
      return {
        isError: false,
        isSuccess: true,
        message: 'OK',
        data: tx,
      };
    } catch (_: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: WalletError.FAILED_GET_TX,
        data: null,
      };
    }
  }

  getDataFieldValue = (tokenRecipientAddress: string, tokenAmount: any) => {
    const web3 = new Web3();
    const TRANSFER_FUNCTION_ABI = {
      constant: false,
      inputs: [
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    };
    const metamaskProvider = this.getMetamaskProvider();
    this.getContract;
    return web3.eth.abi.encodeFunctionCall(TRANSFER_FUNCTION_ABI as any, [
      tokenRecipientAddress,
      tokenAmount,
    ]);
  };

  async transfer(
    payload: ITransferPayload
  ): Promise<WalletOperationReturn<string | null>> {
    const { fromAddress, toAddress, value, erc20, provider, account } = payload;
    try {
      const metamaskProvider = this.getMetamaskProvider();
      let txHash;
      if (erc20 && erc20.tokenAddress) {
        if (!provider || !account) {
          throw new Error('Provider or account is not defined');
        }
        const contract = getContract(
          erc20.tokenAddress,
          ERC20ABI,
          provider,
          account
        );
        const amount = Web3.utils.toHex(
          new BigNumber(value).multipliedBy(`1e${erc20.decimal}`).toFixed()
        );

        const tx = await contract
          .connect(provider.getSigner())
          .transfer(toAddress, amount, {
            gasLimit: '100000',
          });

        await tx.wait();

        contract.connect(provider);
      } else {
        txHash = await metamaskProvider.request({
          method: 'eth_sendTransaction',
          params: [
            {
              from: fromAddress,
              to: toAddress,
              value: Web3.utils.toHex(Web3.utils.toWei(value, 'ether')),
            },
          ],
        });
      }

      if (txHash) {
        return {
          isError: false,
          isSuccess: true,
          message: 'OK',
          data: txHash as string,
        };
      } else {
        return {
          isError: true,
          isSuccess: false,
          message: WalletError.FAILED_TRANSFER,
          data: null,
        };
      }
    } catch (error: any) {
      return {
        isError: true,
        isSuccess: false,
        message:
          error && error.message ? error.message : WalletError.FAILED_TRANSFER,
        data: null,
      };
    }
  }
  /**
   * Looking for contract in cache
   * creates it if it doesn't exist,
   * and then returns it.
   * @param contractAddress
   * @param abi
   * @returns
   */
  async getContract(
    contractAddress: string,
    abi: AbiItem[],
    needToCache = true
  ): Promise<Contract> {
    if (!this.contracts[contractAddress] || !needToCache) {
      const web3Provider = this.getWeb3Provider();
      this.contracts[contractAddress] = new web3Provider.eth.Contract(
        abi,
        contractAddress
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.contracts[contractAddress]!;
  }

  getBlockNumber = async (): Promise<WalletOperationReturn<number | null>> => {
    try {
      const blockNumber = await this.getWeb3Provider().eth.getBlockNumber();
      return {
        isError: false,
        isSuccess: true,
        message: '',
        data: blockNumber,
      };
    } catch (err: unknown) {
      return {
        isError: true,
        isSuccess: false,
        message: '',
        data: null,
      };
    }
  };

  // Wallet events
  registerEvent(eventName: WalletEvent, handler: (args: unknown) => void) {
    const metamaskProvider = this.getMetamaskProvider();
    if (metamaskProvider) {
      metamaskProvider.on(eventName, handler);
    }
  }

  unregisterEvent(eventName: WalletEvent, handler: (args: unknown) => void) {
    const metamaskProvider = this.getMetamaskProvider();
    if (metamaskProvider) {
      metamaskProvider.removeListener(eventName, handler);
    }
  }
}
