import px2rem from '@/utils/px2rem';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${px2rem(20)};

  .back-again-btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${px2rem(0)};
    margin-top: ${px2rem(16)};

    svg,
    path {
      fill: #3772ff;
    }

    p {
      font-weight: 400;
      font-size: ${px2rem(16)};
      line-height: ${px2rem(26)};
      color: #3772ff;
      font-family: var(--chakra-fonts-sora);
    }
  }

  .payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${px2rem(16)};
    padding: ${px2rem(24)};
    border-radius: ${px2rem(12)};
    border: 1px solid #898989;
    width: 100%;
  }

  .text {
    &_title {
      font-size: ${px2rem(18)};
      line-height: ${px2rem(28)};
      text-align: center;
      color: #ffffff;

      span {
        font-weight: 700;
      }
    }
  }

  .wrap-qr {
    background-color: white;
    padding: ${px2rem(12)};
  }

  .expire-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_expire {
      font-size: ${px2rem(14)};
      line-height: ${px2rem(24)};
      text-align: center;
      color: #cecece;
    }
  }

  .copy-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: ${px2rem(8)} ${px2rem(16)};
    gap: ${px2rem(8)};
    background: #f4f4f4;
    border-radius: ${px2rem(20)};

    &_address {
      font-size: ${px2rem(14)};
      line-height: ${px2rem(24)};
      text-align: center;
      font-weight: 500;
      color: #000000;
    }

    .icon-copy {
      width: ${px2rem(24)};
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    }
  }

  .wrap-receive-address {
    width: 100%;
    padding: ${px2rem(8)} ${px2rem(24)};

    background: #2e2e2e;
    border-radius: ${px2rem(20)};

    &_text {
      font-size: ${px2rem(16)};
      line-height: ${px2rem(26)};
      color: #b6b6b6;
    }
  }
`;

export const CheckHistory = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -16px;

  a {
    font-family: var(--chakra-fonts-sora);
    font-size: ${px2rem(18)};
    font-weight: 400;
    line-height: ${px2rem(28)};
    letter-spacing: 0em;
    text-align: center;
    color: #3772ff;
    text-decoration: underline;
    margin-top: ${px2rem(40)};
    text-underline-offset: ${px2rem(2)};
    cursor: pointer;
  }
`;
