import React, { PropsWithChildren, useMemo } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import useGameFiWallet from '@/hooks/useGameFiWallet';
import BigNumber from 'bignumber.js';
import { IBooster, IBoosterConfig } from '@/interfaces/api/booster';
import {
  getBooster,
  getBoosterConfigs,
  submitBooster,
  submitMetamaskAddressBooster,
} from '@/services/bootster';
import { validateEVMAddress } from '@/utils';
import { isProduction } from '@/utils/commons';
import { getErrorMessage } from '@/utils/error';
import cs from 'classnames';
import { Wallet } from 'ethers';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import onboardingStorage from '@/utils/onboarding-storage';
import { ROUTE_PATH } from '@/constants/route-path';
import { useWeb3React } from '@web3-react/core';
import { numberWithCommas } from '@/utils/format';

const { utils } = require('ethers');

export interface IBitcoinAracdeContext {
  booster: IBooster | undefined;
  isRegisterBooter: boolean | undefined;
  boosterConfig: IBoosterConfig | undefined;
  handleSubmitBooster: (
    data: IBoosterConfig,
    walletAddress?: string,
    isRedirect?: boolean
  ) => Promise<void>;
  totalAllowlist: number;
  isHideSidebar: boolean;
  setIsHideSidebar: (data: boolean) => void;
  isShowOnboarding: boolean;
  setIsShowOnboarding: (data: boolean) => void;

  estimatedJackpot: number | string;
  estimatedUSDJackpot: number | string;
}

const initialValue: IBitcoinAracdeContext = {
  booster: undefined,
  isRegisterBooter: undefined,
  boosterConfig: undefined,
  handleSubmitBooster: () => new Promise<void>(() => null),
  totalAllowlist: 0,
  isHideSidebar: false,
  setIsHideSidebar: () => new Promise<void>(() => null),
  isShowOnboarding: false,
  setIsShowOnboarding: () => new Promise<void>(() => null),

  estimatedJackpot: 0,
  estimatedUSDJackpot: 0,
};

export const BitcoinAracdeContext =
  React.createContext<IBitcoinAracdeContext>(initialValue);

export const BitcoinAracdeProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const { addressL2, keySetL2, walletStateL2, eth2Usd } =
    useContext(WalletContext);
  const { openCreate } = useGameFiWallet();
  const gaEventTracker = useAnalyticsEventTracker();
  const { onPostMessageWasOnAllowlist } = useContentWindowMessage();

  const router = useRouter();
  const params = router.query;

  const [isHideSidebar, setIsHideSidebar] = useState<boolean>(false);
  const [isShowOnboarding, setIsShowOnboarding] = useState<boolean>(false);

  const [booster, setBooster] = useState<IBooster | undefined>();
  const [boosterConfigs, setBoosterConfigs] = useState<IBoosterConfig[]>([]);
  const [totalAllowlist, setTotalAllowlist] = useState<number>(0);

  const boosterRef = useRef<IBoosterConfig | undefined>(undefined);
  const inputWalletAddr = useRef<string | undefined>(undefined);
  const isRedirectRef = useRef<boolean | undefined>(undefined);

  const convertTC2USD = (tc: number) => {
    if (!eth2Usd) return new BigNumber(0);

    const TcInBN = new BigNumber(tc);
    const usd = TcInBN.multipliedBy(eth2Usd.multipliedBy(0.0069));

    return usd;
  };

  const estimatedJackpot = React.useMemo(() => {
    return totalAllowlist
      ? numberWithCommas(Number((totalAllowlist * 0.2).toFixed(0)))
      : 0;
  }, [totalAllowlist]);

  const estimatedUSDJackpot = React.useMemo(() => {
    return totalAllowlist && eth2Usd
      ? numberWithCommas(Number(convertTC2USD(totalAllowlist * 0.2).toFixed(0)))
      : 0;
  }, [totalAllowlist, eth2Usd]);

  useEffect(() => {
    if (booster && boosterRef && boosterRef.current) {
      onHandleSubmitBooster(boosterRef.current, true, inputWalletAddr.current, isRedirectRef.current);
    }
  }, [booster]);

  useEffect(() => {
    if (booster && booster.registerBoost) {
      onPostMessageWasOnAllowlist();
    }
  }, [booster]);

  const handleSubmitBooster = async (
    data: IBoosterConfig,
    walletAddress?: string,
    isRedirect?: boolean
  ) => {
    if (
      walletStateL2.isNeedCreate ||
      !addressL2 ||
      !keySetL2.prvKey ||
      !booster
    ) {
      boosterRef.current = data;
      inputWalletAddr.current = walletAddress;
      isRedirectRef.current = isRedirect
      await openCreate();
      return;
    }
    onHandleSubmitBooster(data, false, walletAddress);
  };

  const isRegisterBooter = React.useMemo(() => {
    return booster ? booster.registerBoost : undefined;
  }, [booster]);

  const boosterConfig = React.useMemo(() => {
    if (boosterConfigs.length > 0) {
      if (params.tier && typeof params.tier === 'string') {
        const filters = boosterConfigs.filter(
          booster => booster.id === params.tier
        );
        if (filters.length > 0) {
          return filters[0];
        } else {
          return boosterConfigs[0];
        }
      } else {
        return boosterConfigs[0];
      }
    }
    return undefined;
  }, [boosterConfigs, params]);

  useEffect(() => {
    addressL2 && handleGetBooster(addressL2);
  }, [addressL2]);

  useEffect(() => {
    handleGetBoosterConfigs();
  }, []);

  const handleGetBooster = async (address: string) => {
    try {
      const data = await getBooster(address);
      setBooster(data);
    } catch (error) {}
  };

  const handleGetBoosterConfigs = async () => {
    try {
      const data = await getBoosterConfigs();
      if (data && data.items) {
        setBoosterConfigs(data.items);
        setTotalAllowlist(data.totalAllowList + 1000);
      }
    } catch (error) {}
  };

  const onHandleSubmitBooster = async (
    data: IBoosterConfig,
    showWallet?: boolean,
    walletAdrr?: string,
    isRedirect?: boolean
  ) => {
    if (
      keySetL2.prvKey &&
      addressL2 &&
      !addressL2.startsWith('0x000') &&
      booster
    ) {
      try {
        const wallet = new Wallet(keySetL2.prvKey);
        const addrMessage = `${addressL2.toLowerCase()} ${booster.message}`;
        const signMesssage =
          params.ref && validateEVMAddress(params.ref as string)
            ? `${addrMessage} ${(params.ref as string).toLowerCase()}`
            : addrMessage;
        const signMesssage256 = utils.keccak256(
          utils.toUtf8Bytes(signMesssage)
        );
        const signature = await wallet.signMessage(signMesssage256);
        await submitBooster({
          wallet_address: addressL2.toLowerCase(),
          boot_id: data.id,
          signature,
          reference: (params.ref as string) || '',
        });
        if (walletAdrr) {
          submitMetamaskAddressBooster(addressL2, walletAdrr);
        }
        await handleGetBooster(addressL2);
        gaEventTracker(GameFiActions.ClickGetOnAllowlist, addressL2);
        // toast.success('Get on the allowlist successfully.');
        // onboardingStorage.setIsNewUser('1');
        if (isRedirect || typeof isRedirect === 'undefined') {
          window.location.replace(`${ROUTE_PATH.MEGA_REFERRAL}`);
        }
      } catch (error) {
        const { message } = getErrorMessage(error);
        toast.error(message);
      }
      boosterRef.current = undefined;
      inputWalletAddr.current = undefined;
    }
  };

  const contextValues = useMemo((): IBitcoinAracdeContext => {
    return {
      booster,
      isRegisterBooter,
      boosterConfig,
      handleSubmitBooster,
      totalAllowlist,
      isHideSidebar,
      setIsHideSidebar,
      isShowOnboarding,
      setIsShowOnboarding,
      estimatedJackpot,
      estimatedUSDJackpot,
    };
  }, [
    isRegisterBooter,
    handleSubmitBooster,
    booster,
    boosterConfig,
    isHideSidebar,
    setIsHideSidebar,
    isShowOnboarding,
    setIsShowOnboarding,
    totalAllowlist,
    estimatedJackpot,
    estimatedUSDJackpot,
  ]);

  return (
    <BitcoinAracdeContext.Provider value={contextValues}>
      {children}
    </BitcoinAracdeContext.Provider>
  );
};
