export const IMG_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'avif',
  'svg',
];
export const VIDEO_EXTENSIONS = ['mp4', 'mov'];

export const ACCEPTED_FILE_TYPE = [...IMG_EXTENSIONS, ...VIDEO_EXTENSIONS];
