import CPlayerShare from '@/contracts';
import {IBodyIssueKeys} from '@/contracts/interfaces/issueKey';
import {
  IBodyBuySweetFloorKeys,
  IBodyCheckBalance,
  IBodyFillOrderLimit,
  IBodyFillOrderLimitV2,
  IBodySwap,
  IBuyKeyByKey,
  IEstimateTCMultiKeys,
  ISellKeyFromOut,
} from '@/contracts/interfaces/tradeKey';
import {IAddWatchListParams, IRemoveWatchListParams, IWatchListContext,} from '@/contracts/interfaces/watchList';
import {IPagingParams} from '@/interfaces/api/query';
import {IThreeThreeInvitation} from '@/interfaces/threethree';
import {TRADE_ACTION} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import {SIDE} from '@/modules/AlphaPWA/ThreeThreeInvitations';
import CTradeAPI from '@/services/classes/trade';
import errorLogger from '@/services/errorLogger';
import {ILimitOrder, IResponseOrders} from '@/services/interfaces/order';
import {IParamsGetKeys, IResponseTradeKeys, ITradeKey,} from '@/services/interfaces/trade';
import {IUserProfile} from '@/services/interfaces/userProfile';
import {getThreeThreeInvitationList} from '@/services/three-three';
import {selectCommonReducer} from '@/state/common';
import {useAppSelector} from '@/state/hooks';
import {compareString} from '@/utils';
import useAnalyticsEventTracker, {AlphaActions} from '@/utils/ga';
import {formatAmountToClient} from '@/utils/helpers';
import {BigNumber, ethers} from 'ethers';
import {formatEther} from 'ethers/lib/utils';
import {uniqBy} from 'lodash';
import {useRouter} from 'next/router';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AssetsContext} from './assets-context';
import {WalletContext} from './wallet-context';
import {ILocation} from '@/services/interfaces/location';
import {TOKEN_ADDRESS} from '@/constants/token';
import {CSwap} from '@/contracts/swap';
import {IToken} from '@/interfaces/token';
import BigNumer from 'bignumber.js';

interface IProps {
  children: React.ReactNode;
}
interface IContext extends IWatchListContext {
  userProfile: IUserProfile | undefined;
  userLocation: ILocation | undefined;
  getEstimatePriceTC: (
    _: string,
    __: number,
    ___: string,
    ____?: EstimatePriceToken
  ) => Promise<any>;
  alphaBuyOrSell: (_: string, __: number, ___: string) => Promise<any>;
  getTokenDetail: (token_address: string) => Promise<ITradeKey>;
  scanTrxAlpha: (tx_hash: string) => Promise<any>;
  getTradeKeys: (params: IParamsGetKeys) => Promise<IResponseTradeKeys>;
  getTradeTokens: (params: any) => Promise<any>;
  getTopPlaceholdersConverted: (
    params: IParamsGetKeys
  ) => Promise<IResponseTradeKeys>;
  getTopPlaceholdersSAConverted: (
    params: IParamsGetKeys
  ) => Promise<IResponseTradeKeys>;
  estimateTCMultiKeys: (
    body: IBodyBuySweetFloorKeys[]
  ) => Promise<IEstimateTCMultiKeys>;
  buySweetFloorKeys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  requestTrade33Keys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  trade33Keys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  cancelTrade33Keys: (body: string) => Promise<any>;
  rejectTrade33Keys: (body: string) => Promise<any>;
  reRequestTrade33Keys: (body: IBodyBuySweetFloorKeys) => Promise<any>;
  checkEnoughBalanceAndAllowance: (body: IBodyCheckBalance) => Promise<any>;

  requestTrade33KeysSameAmount: (
    body: IBodyBuySweetFloorKeys[]
  ) => Promise<any>;
  trade33KeysSameAmount: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  reRequestTrade33KeysSameAmount: (
    body: IBodyBuySweetFloorKeys
  ) => Promise<any>;

  issueKeyByTwitter: (body: IBodyIssueKeys) => Promise<any>;
  estimateTCBeforeMint: ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => any;
  has33Requested: boolean;
  estimateBTCForLimitOrder: ({
    supply,
    amount,
  }: {
    supply: BigNumber;
    amount: BigNumber;
  }) => any;
  getFillOpenOrders: (params: IPagingParams) => Promise<IResponseOrders>;
  limitOrder: (body: IBodyFillOrderLimit) => Promise<any>;
  limitOrderV2: (body: IBodyFillOrderLimitV2) => Promise<any>;
  cancelFillOrder: ({ nonce }: { nonce: string }) => Promise<any>;
  updateCreateFee: ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => Promise<any>;
  amountOutForSwapTokens: (params: IBodySwap) => Promise<any>;
  swapTokens: (params: IBodySwap) => Promise<any>;
  getBuyAmountAfterFeeV2: (_: string, __: string) => Promise<any>;
  getBuyPriceAfterFeeV2: (params: any) => Promise<any>;
  estimateBuyKeyByKeyAfterFee: (params: IBuyKeyByKey) => Promise<BigNumber>;
  getSellAmountAfterFeeV2: (params: ISellKeyFromOut) => Promise<BigNumber>;
}

interface EstimatePriceToken {
  tokenIn: IToken;
  tokenOut: IToken;
}

export const TradeKeyContext = React.createContext({} as IContext);

const TradeKeyProvider = ({ children }: IProps) => {
  const playerShareContract = new CPlayerShare();
  const tradeAPI = new CTradeAPI();

  const { gameWallet, addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const needReload = useAppSelector(selectCommonReducer).needReload;
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const router = useRouter();
  const [userLocation, setUserLocation] = useState<ILocation>();
  const swapContract = new CSwap();

  const address = gameWallet?.address;

  const gaEventTracker = useAnalyticsEventTracker();

  const intervalRef = useRef<any>();

  const [data33Request, setData33Request] = useState<IThreeThreeInvitation[]>(
    []
  );

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    getCurrentUserProfile();
  }, [address, needReload]);

  useEffect(() => {
    if (addressL2) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      const interval = setInterval(fetch33Request, 30000);
      intervalRef.current = interval;
      return () => {
        clearInterval(interval);
      };
    }
  }, [addressL2, needReload]);

  const getLocation = async () => {
    try {
      const response = await tradeAPI.getLocation();
      setUserLocation(response);
    } catch (error) {}
  };

  const updateCreateFee = async ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => {
    try {
      const response = await playerShareContract.updateCreateFee({
        token_address,
        fee,
      });
      scanTrxAlpha(response.hash);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetch33Request = async (
    page = 1,
    isFetchMore = false,
    pageLimit = 1
  ) => {
    try {
      const res = await getThreeThreeInvitationList({
        address: addressL2 || '',
        side: SIDE.REQUESTED,
        page: page,
        limit: pageLimit,
      });

      if (isFetchMore) {
        setData33Request(prev => [...prev, ...res]);
      } else {
        setData33Request(res);
      }
    } catch (e) {
    } finally {
    }
  };

  const has33Requested = useMemo(() => {
    const i = data33Request.findIndex(d => d.status === 'waiting');
    return i >= 0;
  }, [JSON.stringify(data33Request)]);

  const getCurrentUserProfile = async () => {
    try {
      if (address) {
        const res = await tradeAPI.getUserProfile(address, addressL2);

        const tokenAddress = await playerShareContract.getAlphaKeysToken();

        const fee = await playerShareContract.getCreatorFee(tokenAddress);

        setUserProfile({
          ...res,
          creator_fee: fee,
          token_address: tokenAddress,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const scanTrxAlpha = async (tx_hash: string) => {
    try {
      return await tradeAPI.scanTrxAlpha({ tx_hash });
    } catch (error) {
      //
    }
  };

  const getTokenDetail = async (tokenAddress = ''): Promise<ITradeKey> => {
    try {
      const response = await tradeAPI.getTokenDetail(tokenAddress);

      if (
        response.address &&
        !compareString(response.address, ethers.constants.AddressZero)
      ) {
        const [yourBalance, tokenInfo, fee, platformFee] = await Promise.all([
          playerShareContract.getTokenBalanceFree(response.address),
          playerShareContract.getTokenERC20Info(response.address),
          playerShareContract.getCreatorFee(response.address as string),
          playerShareContract.getPlatformFee(response.address as string),
        ]);

        response.create_fee = fee;
        response.trading_fee = platformFee;
        response.your_balance = formatAmountToClient(
          yourBalance?.toString?.() || '0'
        ) as any;
        response.symbol = tokenInfo.symbol;
        response.total_supply_number = parseFloat(
          formatEther(tokenInfo.totalSupply?.toString())
        );
        response.btc_price = (
          parseFloat(response.usd_price as string) /
          parseFloat(response.price as string)
        ).toString();
      }

      return response;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  const estimateTCBeforeMint = ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => {
    try {
      const amount = playerShareContract.estimateTCBeforeMint({
        token_amount,
        supply,
      });
      return amount;
    } catch (error) {
      return 0;
    }
  };

  const getBuyAmountAfterFeeV2 = async (
    token_address: string,
    btc_amount: number
  ) => {
    const resultAmount = 0;
    try {
      const rs = playerShareContract.getBuyAmountAfterFeeV2({
        btc_amount,
        token_address,
      });

      return rs;
    } catch (error) {
      console.log('error', error);

      return resultAmount;
    }
  };

  const getEstimatePriceTC = async (
    token_address: string,
    token_amount: number,
    type: string,
    token?: EstimatePriceToken
  ) => {
    let resultAmount: any = {
      amount: 0,
      amount_after_fee: 0,
      amount_fee: 0,
    };
    try {
      if (type === TRADE_ACTION.BUY) {
        const response = await Promise.all([
          playerShareContract.getBuyPriceAfterFee({
            token_amount,
            token_address,
          }),
          playerShareContract.getBuyPrice({
            token_amount,
            token_address,
          }),
        ]);

        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );

        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .toString();
        resultAmount = {
          amount_after_fee: response[0],
          amount: response[1],
          amount_fee,
        };
      } else {
        const response = await Promise.all([
          playerShareContract.getSellPriceAfterFee({
            token_amount,
            token_address,
          }),
          playerShareContract.getSellPrice({
            token_amount,
            token_address,
          }),
        ]);
        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .abs()
          .toString();
        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );

        if (
          token?.tokenIn &&
          token?.tokenOut &&
          token?.tokenOut.is_token &&
          !compareString(token?.tokenOut.address, TOKEN_ADDRESS.BTC_ADDRESS_L2)
        ) {
          const data = await swapContract.estimateSwap({
            humanAmount: formatEther(response[0]),
            tokenIn: {
              ...token.tokenIn,
              address: TOKEN_ADDRESS.BTC_ADDRESS_L2,
            },
            tokenOut: token.tokenOut,
          });
          if (data && data.length > 0) {
            const amount = ethers.BigNumber.from(
              new BigNumer(data[0]).toFixed()
            );
            const rate = new BigNumer(amount.toString())
              .div(response[0].toString())
              .toString();
            resultAmount = {
              amount_after_fee: amount,
              amount: ethers.BigNumber.from(
                new BigNumer(response[1].toString())
                  .multipliedBy(rate)
                  .toFixed(0, BigNumer.ROUND_FLOOR)
              ),
              amount_fee: ethers.BigNumber.from(
                new BigNumer(amount_fee.toString())
                  .multipliedBy(rate)
                  .toFixed(0, BigNumer.ROUND_FLOOR)
              ),
            };
            // if (!isProduction()) {
            //   alert(rate);
            // }
          } else {
            throw new Error('Error');
          }
        } else {
          resultAmount = {
            amount_after_fee: response[0],
            amount: response[1],
            amount_fee,
          };
        }
      }
      return resultAmount;
    } catch (error) {
      console.log('error', error);

      return resultAmount;
    }
  };

  const alphaBuyOrSell = async (
    token_address: string,
    token_amount: number,
    type: string
  ) => {
    try {
      let tx: any = 0;

      const tcAmount = await getEstimatePriceTC(
        token_address,
        token_amount,
        type
      );

      if (type === TRADE_ACTION.BUY) {
        tx = await playerShareContract.buyKeys({
          token_amount,
          token_address,
          tc_amount: tcAmount.amount_after_fee.toString(),
        });
      } else {
        tx = await playerShareContract.sellKeys({
          token_amount,
          token_address,
        });
      }
      await tx.wait();
      return tx;
    } catch (error: any) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.BuyKey,
        error: JSON.stringify({
          error: error,
          info: {
            token_address,
            trader: gameWallet?.address,
            token_amount,
            type,
            trader_balance: balanceL2,
          },
        }),
      });
      gaEventTracker(
        AlphaActions.TradeKey,
        error?.message as string
        // JSON.stringify({
        //   error: error,
        //   info: {
        //     token_address,
        //     trader: gameWallet?.address,
        //     token_amount,
        //     type,
        //   },
        // })
      );
      throw error;
    }
  };

  const getTradeKeys = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      let response: IResponseTradeKeys;
      if (compareString(params.holder, '1')) {
        params.address = gameWallet?.address;
        response = await tradeAPI.getKeysV1(params);
      } else {
        response = await tradeAPI.getKeys(params);
        let _rows = response.rows;
        if (compareString(params.sort_col, 'portfolio')) {
          const [_profile, _tokenDetail] = await Promise.all([
            tradeAPI.getUserProfile(
              '0xFab817c73F818Fb4DeCd086Adb9da8FfD43dF113'
            ),
            tradeAPI.getTokenDetail(
              '0xFab817c73F818Fb4DeCd086Adb9da8FfD43dF113'
            ),
          ]);
          const detail: ITradeKey = {
            ..._tokenDetail,
            address: _profile?.address,
            portfolio: parseFloat(_profile?.portfolio as string),
          };
          _rows.unshift(detail);

          _rows = uniqBy(_rows, 'address').sort(
            (a, b) => Number(b.portfolio) - Number(a.portfolio)
          );
          _rows = uniqBy(_rows, 'address');
        }
        response.rows = _rows;
      }
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const getTopPlaceholdersConverted = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const response: IResponseTradeKeys =
        await tradeAPI.getTopPlaceholdersConverted(params);
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const getTopPlaceholdersSAConverted = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const response: IResponseTradeKeys =
        await tradeAPI.getTopPlaceholdersSAConverted(params);
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const estimateTCMultiKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<IEstimateTCMultiKeys> => {
    try {
      const response = await playerShareContract.estimateTCMultiKeys(body);
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const buySweetFloorKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response = await playerShareContract.buySweetFloorKeys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: errorLogger.ERROR_LOGGER_TYPE.SWEEP_KEYS,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.SweepTradeKey,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const issueKeyByTwitter = async (body: IBodyIssueKeys): Promise<any> => {
    try {
      const response = await playerShareContract.autoCreateAlphaKeysFor(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.IssuedKey,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.IssuedKey,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const requestTrade33Keys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response = await playerShareContract.requestTrade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Request33,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.Request33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const trade33Keys = async (body: IBodyBuySweetFloorKeys[]): Promise<any> => {
    try {
      const response = await playerShareContract.trade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Trade33,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.Trade33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const cancelTrade33Keys = async (order_id: string): Promise<any> => {
    try {
      const response = await playerShareContract.cancelTrade33Keys(order_id);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelTrade33,
        error: JSON.stringify({
          error: error,
          info: { order_id },
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.CancelTrade33,
        JSON.stringify({
          error: error,
          info: { order_id },
        })
      );
      throw error;
      //
    }
  };

  const rejectTrade33Keys = async (order_id: string): Promise<any> => {
    try {
      const response = await playerShareContract.rejectTrade33Keys(order_id);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelTrade33,
        error: JSON.stringify({
          error: error,
          info: { order_id },
        }),
      });
      gaEventTracker(
        AlphaActions.CancelTrade33,
        JSON.stringify({
          error: error,
          info: { order_id },
        })
      );
      throw error;
      //
    }
  };

  const reRequestTrade33Keys = async (
    body: IBodyBuySweetFloorKeys
  ): Promise<any> => {
    try {
      const response = await playerShareContract.reRequestTrade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.ReRequest33,
        error: JSON.stringify({
          error: error,
          info: body,
        }),
      });
      gaEventTracker(
        AlphaActions.ReRequest33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const checkEnoughBalanceAndAllowance = async (
    body: IBodyCheckBalance
  ): Promise<any> => {
    try {
      const response =
        await playerShareContract.checkEnoughBalanceAndAllowance(body);
      return response;
    } catch (error) {
      // gaEventTracker(
      //   AlphaActions.ReRequest33,
      //   JSON.stringify({
      //     error: error,
      //     info: body,
      //   })
      // );
      throw error;
      //
    }
  };

  const requestTrade33KeysSameAmount = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response =
        await playerShareContract.requestTrade33KeysSameAmount(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Request33,
        error: JSON.stringify({
          error: error,
          info: body,
        }),
      });
      gaEventTracker(
        AlphaActions.Request33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const trade33KeysSameAmount = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response = await playerShareContract.trade33KeysSameAmount(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Trade33,
        error: JSON.stringify({
          error: error,
          info: body,
        }),
      });
      gaEventTracker(
        AlphaActions.Trade33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const reRequestTrade33KeysSameAmount = async (
    body: IBodyBuySweetFloorKeys
  ): Promise<any> => {
    try {
      const response =
        await playerShareContract.reRequestTrade33KeysSameAmount(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.ReRequest33,
        error: JSON.stringify({
          error: error,
          info: body,
        }),
      });
      gaEventTracker(
        AlphaActions.ReRequest33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const estimateBTCForLimitOrder = ({
    supply,
    amount,
  }: {
    supply: BigNumber;
    amount: BigNumber;
  }) => {
    try {
      const response = playerShareContract.getBuyPriceV2(supply, amount);

      return response;
    } catch (error) {
      return 0;
    }
  };

  const getSignatureFillLimitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const response =
        await playerShareContract.getSignatureFillLimitOrder(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const limitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const {
        signature,
        nonce,
      }: {
        signature: string;
        nonce: string;
      } = (await getSignatureFillLimitOrder(body)) as any;
      const _body: ILimitOrder = {
        ...body,
        signature,
        nonce,
      };
      const response = await tradeAPI.limitOrder(
        _body,
        gameWallet?.address as string
      );
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.OpenLimitOrder,
        error: JSON.stringify({
          error: error,
          trader_balance: balanceL2,
        }),
      });
      throw error;
    }
  };

  const limitOrderV2 = async (body: IBodyFillOrderLimitV2) => {
    try {
      const response = await playerShareContract.createFillOrder(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFillOpenOrders = async (
    params: IPagingParams
  ): Promise<IResponseOrders> => {
    try {
      const response: IResponseOrders = await tradeAPI.openLimitOrders({
        ...params,
        address: gameWallet?.address as string,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const cancelFillOrder = async ({
    nonce,
  }: {
    nonce: string;
  }): Promise<any> => {
    try {
      const response = await playerShareContract.cancelFillOrder({ nonce });
      scanTrxAlpha(response.hash);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelLimitOrder,
        error: JSON.stringify({
          error: error,
          info: {
            nonce,
            address: gameWallet?.address,
          },
        }),
      });
      gaEventTracker(
        AlphaActions.CancelLimitOrder,
        JSON.stringify({
          error: error,
          info: {
            nonce,
            address: gameWallet?.address,
          },
        })
      );
      throw error;
    }
  };

  /**
   * ==== Begin Watch List ======
   */

  const addWatchList = async (
    params: IAddWatchListParams
  ): Promise<ethers.ContractTransaction> => {
    try {
      const tx = await playerShareContract.addWatchList(params);
      await scanTrxAlpha(tx.hash);
      return tx;
    } catch (error) {
      throw error;
    }
  };

  const removeWatchList = async (
    params: IRemoveWatchListParams
  ): Promise<ethers.ContractTransaction> => {
    try {
      const tx = await playerShareContract.removeWatchList(params);
      await scanTrxAlpha(tx.hash);
      return tx;
    } catch (error) {
      throw error;
    }
  };

  const amountOutForSwapTokens = async (params: IBodySwap): Promise<any> => {
    try {
      const tx = await playerShareContract.amountOutForSwapTokens(params);
      return tx;
    } catch (error) {
      throw error;
    }
  };

  const swapTokens = async (params: IBodySwap): Promise<any> => {
    try {
      const tx = await playerShareContract.swapTokens(params);
      await scanTrxAlpha(tx.hash);
      return tx;
    } catch (error) {
      throw error;
    }
  };

  const getBuyPriceAfterFeeV2 = async (params: {
    token_address: string;
    token_amount: string;
  }): Promise<BigNumber> => {
    return await playerShareContract.getBuyPriceAfterFee({ ...params } as any);
  };

  const estimateBuyKeyByKeyAfterFee = async (
    params: IBuyKeyByKey
  ): Promise<BigNumber> => {
    return await playerShareContract.estimateBuyKeyByKeyAfterFee({ ...params });
  };

  const getSellAmountAfterFeeV2 = async (
    params: ISellKeyFromOut
  ): Promise<BigNumber> => {
    return await playerShareContract.getSellAmountAfterFeeV2({ ...params });
  };

  const getTradeTokens = async (params: any) => {
    return await tradeAPI.getPassTokens(params);
  }

  /**
   * ==== End Watch List ======
   */

  const values = useMemo(() => {
    return {
      userProfile,
      userLocation,
      getTokenDetail,
      getEstimatePriceTC,
      alphaBuyOrSell,
      scanTrxAlpha,
      getTradeKeys,
      estimateTCMultiKeys,
      buySweetFloorKeys,
      issueKeyByTwitter,
      estimateTCBeforeMint,
      getTopPlaceholdersConverted,
      getTopPlaceholdersSAConverted,
      requestTrade33Keys,
      trade33Keys,
      cancelTrade33Keys,
      rejectTrade33Keys,
      reRequestTrade33Keys,
      checkEnoughBalanceAndAllowance,
      has33Requested,
      requestTrade33KeysSameAmount,
      trade33KeysSameAmount,
      reRequestTrade33KeysSameAmount,
      estimateBTCForLimitOrder,
      getFillOpenOrders,
      limitOrder,
      cancelFillOrder,
      limitOrderV2,
      updateCreateFee,
      addWatchList,
      removeWatchList,
      amountOutForSwapTokens,
      swapTokens,
      getBuyAmountAfterFeeV2,
      getBuyPriceAfterFeeV2,
      estimateBuyKeyByKeyAfterFee,
      getSellAmountAfterFeeV2,
      getTradeTokens,
    };
  }, [
    JSON.stringify(userProfile),
    userLocation,
    getTokenDetail,
    getEstimatePriceTC,
    alphaBuyOrSell,
    scanTrxAlpha,
    getTradeKeys,
    estimateTCMultiKeys,
    buySweetFloorKeys,
    issueKeyByTwitter,
    estimateTCBeforeMint,
    getTopPlaceholdersConverted,
    getTopPlaceholdersSAConverted,
    requestTrade33Keys,
    trade33Keys,
    cancelTrade33Keys,
    rejectTrade33Keys,
    reRequestTrade33Keys,
    checkEnoughBalanceAndAllowance,
    has33Requested,
    requestTrade33KeysSameAmount,
    trade33KeysSameAmount,
    reRequestTrade33KeysSameAmount,
    estimateBTCForLimitOrder,
    getFillOpenOrders,
    limitOrder,
    cancelFillOrder,
    limitOrderV2,
    updateCreateFee,
    addWatchList,
    removeWatchList,
    amountOutForSwapTokens,
    swapTokens,
    getBuyAmountAfterFeeV2,
    getBuyPriceAfterFeeV2,
    estimateBuyKeyByKeyAfterFee,
    getSellAmountAfterFeeV2,
    getTradeTokens,
  ]);

  return (
    <TradeKeyContext.Provider value={values as any}>
      {children}
    </TradeKeyContext.Provider>
  );
};

export default TradeKeyProvider;
