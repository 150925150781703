import React, { useCallback, useContext } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { getContractSigner } from '@/utils/signer';
import useProviderL2 from '@/hooks/useProviderL2';
import abi from '@/abis/PlayerShareToken.json';

const usePlayerShareTokenContractSigner = () => {
  const { keySetL2 } = useContext(WalletContext);
  const providerL2 = useProviderL2();

  const generateSigner = useCallback((contractAddress: string) => {
    try {
      if (!keySetL2.prvKey || !providerL2) return undefined;
      const contractSigner = getContractSigner(
        contractAddress,
        abi,
        providerL2,
        keySetL2.prvKey
      );
      return contractSigner;
    } catch (error) {
      return undefined;
    }
  }, [keySetL2, providerL2])

  return generateSigner;
};

export default usePlayerShareTokenContractSigner;
