import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  label: {},
  control: {
    width: '14px',
    minWidth: '14px',
    height: '14px',
    border: '1px solid #b6b6b6',
    _checked: {
      border: '1px solid var(--chakra-colors-blue-600)',
      background: 'var(--alpha-checkBox)',
    },
  },
  icon: {
    color: '#000',
    fontSize: 12,
  },
});

const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: '20px',
      borderRadius: '4px',
      borderColor: '#B6B6B6',
    }),
    label: defineStyle({
      fontSize: '14px',
      marginLeft: '12px',
    }),
  }),
};

export const checkboxTheme = defineMultiStyleConfig({ baseStyle, sizes });
