import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import React, { useEffect, useState } from 'react';

const ReactionGM = () => {
  return (
    <>
      <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-emoji-gm.svg`} />
    </>
  );
};

export default ReactionGM;
