import { WalletContext } from '@/contexts/wallet-context';
import useProviderL2 from '@/hooks/useProviderL2';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import { BigNumber, ethers, Wallet } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import { useContext } from 'react';
import Web3 from 'web3';
import useProviderEthereum from './useProviderEthereum';
import { ISendTokenOnEthereumParams } from '@/contracts/burn/burn.interface';
import { parseEther } from 'ethers/lib/utils';
import CContract from '@/contracts/contract';

export const GAS_LIMIT_ON_ETHEREUM = 30000;

export interface ISendTCL2Params {
  amount: string;
  receiver: string;
  gasLimit?: number;
}

export interface ISendETHParams {
  amount: string;
  receiver: string;
  gasLimit?: number;
  gasPrice?: string;
  gasPriceRaw?: string;
}

const getWalletSigner = (
  privateKey: string,
  provider: JsonRpcProvider
): Wallet => {
  const wallet = new Wallet(privateKey);
  return wallet.connect(provider);
};

const useSendEthereum = () => {
  const { keySetL2 } = useContext(WalletContext);
  const providerEthereum = useProviderEthereum();

  const getGasPriceOnEthereum = async (): Promise<string> => {
    if (!providerEthereum) return '0';
    const gasPrice = await providerEthereum.getGasPrice();
    return gasPrice.toString();
  };

  const get = async (): Promise<string> => {
    if (!providerEthereum) return '0';
    const gasPrice = await providerEthereum.getGasPrice();
    return gasPrice.toString();
  };

  const onEstimateGas = async (params: ISendTCL2Params) => {
    if (!keySetL2.prvKey || !providerEthereum) return;
    const walletSigner = getWalletSigner(keySetL2.prvKey, providerEthereum);
    const gasLimit = await walletSigner.estimateGas({
      from: keySetL2.address,
      value: ethers.utils.parseEther(String(params.amount)),
      to: params.receiver,
    });
    return gasLimit.toNumber();
  };

  const onSendETH = async (params: ISendETHParams) => {
    try {
      if (!keySetL2.prvKey || !providerEthereum) return;

      const walletSigner = getWalletSigner(keySetL2.prvKey, providerEthereum);

      const gasPriceRaw = params.gasPriceRaw;
      const gasPrice = params.gasPrice; //wei

      // const gasPriceToEther = new BN(params.gasPrice || '0')
      //   .dividedBy(1e18)
      //   .toFixed();

      const gasPriceGWei = ethers.utils.formatUnits(
        gasPrice?.toString() as string,
        'gwei'
      );

      const bufferGwei = 5; //5 gwei
      const newGasPriceBufferBN_Str = new BN(gasPriceGWei)
        .plus(bufferGwei)
        .multipliedBy(1e9)
        .toFixed();

      const gasPriceBufferBN = BigNumber.from(newGasPriceBufferBN_Str); //convert to Bignumber EtherJS

      // const gasPriceBufferToWei = ethers.utils.formatUnits(
      //   gasPriceBufferBN.toString(),
      //   'wei'
      // );
      // const gasPriceBufferToGwei = ethers.utils.formatUnits(
      //   gasPriceBufferBN.toString(),
      //   'gwei'
      // );

      // console.log(' gasPrice ', gasPrice);
      // console.log(' gasPriceRaw ', gasPriceRaw);
      // console.log(' gasPriceGWei ', gasPriceGWei);
      // console.log(' gasPriceToEther ', gasPriceToEther);

      // console.log(' gasPriceBufferBN ', gasPriceBufferBN);
      // console.log(' gasPriceBufferToWei ', gasPriceBufferToWei);
      // console.log(' gasPriceBufferToGwei ', gasPriceBufferToGwei);

      // Waitting Tx Created
      let tx: TransactionResponse = await walletSigner.sendTransaction({
        value: ethers.utils.parseEther(String(params.amount)),
        to: params.receiver,
        gasLimit: String(GAS_LIMIT_ON_ETHEREUM),
        gasPrice: gasPriceBufferBN, //wei (Or using gasPriceRaw)
      });

      console.log('Seng ETH --- Successfully --- tx --- ', tx);

      // // Waitting Tx Confirm (Can ignore if needed)
      // tx = await tx.wait();

      return tx;
    } catch (error) {
      throw error;
    }
  };

  const onSendTokenEthereum = async (params: ISendETHParams) => {
    if (!keySetL2.prvKey || !providerEthereum) return;

    const walletSigner = getWalletSigner(keySetL2.prvKey, providerEthereum);
    const tx: TransactionResponse = await walletSigner.sendTransaction({
      from: keySetL2.address,
      value: String(params.amount),
      to: params.receiver,
    });

    await tx.wait();
  };

  return {
    onSendETH,
    onSendTokenEthereum,
    getGasPriceOnEthereum,
    onEstimateGas,
  };
};

export default useSendEthereum;
