import localStorage from '@/utils/localstorage';
import BigNumber from 'bignumber.js';
import { TRANSACTION_FEE } from '@/constants/amount';

class ConvertTC2BTCStorage {
  CONVERT_NEEDED = 'CONVERT_NEEDED';
  CHECKED_AMOUNT_TC_THRESHOLD = 'CHECKED_AMOUNT_TC_THRESHOLD';

  private getConvertNeededKey = () => {
    return this.CONVERT_NEEDED;
  };
  isConvertNeeded = (): boolean => {
    const key = this.getConvertNeededKey();
    const value = localStorage.get(key);
    return !(!value || value === 'false');
  };
  updateIsConvertNeeded = (value: boolean) => {
    const key = this.getConvertNeededKey();
    return localStorage.set(key, value);
  };

  private getCheckedAmountThresholdKey = () => {
    return this.CHECKED_AMOUNT_TC_THRESHOLD;
  };
  isCheckedAmountThreshold = (): boolean => {
    const key = this.getCheckedAmountThresholdKey();
    const value = localStorage.get(key);
    return !(!value || value === 'false');
  };

  updateCheckedAmountThreshold = () => {
    const key = this.getCheckedAmountThresholdKey();
    return localStorage.set(key, true);
  };
}

const convertTC2BTCStorage = new ConvertTC2BTCStorage();

const checkAmountTCThreshold = (balance: string) => {
  const checked = convertTC2BTCStorage.isCheckedAmountThreshold();
  const isConvertNeed = convertTC2BTCStorage.isConvertNeeded();
  const isOver = new BigNumber(balance).gt(TRANSACTION_FEE.TC_THRESHOLD);

  if (!checked) {
    convertTC2BTCStorage.updateCheckedAmountThreshold();
    if (isOver) convertTC2BTCStorage.updateIsConvertNeeded(true);
  } else {
    if (!isOver && isConvertNeed) {
      convertTC2BTCStorage.updateIsConvertNeeded(false);
    }
  }
};

export { checkAmountTCThreshold };

export default convertTC2BTCStorage;
