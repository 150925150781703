import * as React from 'react';

const PasswordHide = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={24}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.678 6.206c.756.559 1.519 1.058 2.206 1.646.865.74 1.703 1.518 2.49 2.34.873.914.817 2.26-.067 3.25a22.11 22.11 0 0 1-3.497 3.152c-1.53 1.112-3.171 2.018-4.97 2.622-2.856.96-5.681.844-8.47-.27-.253-.1-.4-.088-.596.112-1.377 1.395-2.768 2.777-4.153 4.165-.108.11-.205.232-.301.352-.407.51-1.132.57-1.622.134-.463-.412-.465-1.156-.015-1.6 1.766-1.74 3.535-3.48 5.292-5.23C13.475 11.4 18.97 5.913 24.463.425c.324-.323.676-.522 1.127-.377.403.13.673.417.768.843.095.428-.101.75-.39 1.037l-3.13 3.12-1.16 1.158Zm-2.665 5.546c-.042-.728-.208-1.216-.395-1.8-.115-.359-.323-.392-.587-.128a4215.245 4215.245 0 0 0-5.986 5.998c-.256.257-.218.425.12.564.75.306 1.517.441 2.335.355 2.54-.268 4.517-2.437 4.513-4.989Zm-9.988.056c.019.49.124.756.16 1.162.015.162-.032.384-.139.494-1.043 1.072-2.107 2.123-3.159 3.186-.18.183-.338.202-.547.05a24.261 24.261 0 0 1-3.685-3.242c-.85-.922-.902-2.183-.097-3.148C2.6 9.063 3.845 8.027 5.162 7.08c1.691-1.215 3.506-2.195 5.511-2.79 2.268-.672 4.54-.664 6.808.007.351.104.42.324.167.579-.662.67-1.322 1.341-2.005 1.99-.11.103-.353.156-.503.116-2.545-.687-5.429 1.038-5.988 3.786a5.287 5.287 0 0 0-.127 1.04Z"
      fill="#9C9C9C"
    />
  </svg>
);

const PasswordShow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={16}
    viewBox="0 0 27 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 7.798v.455c-.143.807-.626 1.405-1.201 1.946-.331.312-.655.631-.993.935-1.854 1.674-3.891 3.06-6.235 3.97-2.473.961-4.994 1.176-7.578.513-2.192-.563-4.144-1.61-5.96-2.935a23.155 23.155 0 0 1-3.307-2.919C.729 8.694.696 7.38 1.689 6.3c.57-.62 1.189-1.2 1.824-1.755a20.146 20.146 0 0 1 5.714-3.537C12.622-.37 15.99-.356 19.34 1.174c2.404 1.098 4.484 2.645 6.352 4.496.613.606 1.208 1.213 1.308 2.128Zm-17.997.195c-.003 2.722 2.254 4.978 4.99 4.99 2.702.01 4.97-2.243 4.99-4.959.02-2.73-2.26-5.024-4.991-5.022-2.726.002-4.987 2.264-4.99 4.99Z"
      fill="#9C9C9C"
    />
    <path
      d="M13.985 10.146c-1.217-.004-2.16-.968-2.15-2.198.01-1.167.993-2.115 2.182-2.104 1.188.012 2.147.979 2.134 2.152-.013 1.212-.962 2.154-2.166 2.15Z"
      fill="#9C9C9C"
    />
  </svg>
);

interface IProps extends React.SVGProps<SVGSVGElement> {
  isShow: boolean;
}

const PasswordIcon = ({ isShow, ...rest }: IProps) => {
  if (isShow) return <PasswordShow {...rest} />;
  return <PasswordHide {...rest} />;
};

export default PasswordIcon;
