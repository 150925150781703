import {PERP_API_URL} from '@/configs';
import {TWITTER_TOKEN} from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';
import createAxiosInstance from './http-client';
import {IThreeThreeInvitation, IThreeThreeInvitationParams,} from '@/interfaces/threethree';

const apiClient = createAxiosInstance({
  baseURL: `${PERP_API_URL}/api`,
});

// set authorization token
export const setBearerToken = (token: string | string[]) => {
  if (token && apiClient) {
    apiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};
setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

export const getThreeThreeInvitationList = async (
  params: IThreeThreeInvitationParams & {
    page?: number;
    limit?: number;
  }
): Promise<IThreeThreeInvitation[]> => {
  const res: IThreeThreeInvitation[] = await apiClient.get(`/three-three/list`, {
    params,
  });

  return res;
};

export const getThreeThreeSuggestionList = async (
  params: {
    page?: number;
    limit?: number;
    address: string;
  }
): Promise<IThreeThreeInvitation[]> => {
  const res: IThreeThreeInvitation[] = await apiClient.get(`/three-three/suggest`, {
    params,
  });

  return res;
};
