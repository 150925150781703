import { v4 as uuidv4 } from 'uuid';
import { storageUtil } from './storage';
import isEmpty from 'lodash/isEmpty';

import ApiCaller from './apiCaller';
import { AA_TRACKING_API_KEY } from '@/configs';
import accountStorage from '@/utils/account.storage';

let AutonomousAnalytic: ApiCaller;

if (typeof window !== 'undefined') {
  AutonomousAnalytic = new ApiCaller({
    clientSecret: AA_TRACKING_API_KEY,
  });
}

const USER_PSEUDO_ID = 'userPseudoId';
const USER_ADDRESS = accountStorage.ADDRESS_KEY;

const sendAAPageView = ({ page, userAddress, query = '' }: any): void => {
  try {
    let userPseudoId = storageUtil.get(USER_PSEUDO_ID);
    if (isEmpty(userPseudoId)) {
      userPseudoId = uuidv4();
      storageUtil.set(USER_PSEUDO_ID, userPseudoId);
    }
    AutonomousAnalytic.trackPageView({
      pageName: page,
      userAddress: userAddress || localStorage.getItem(USER_ADDRESS) || '',
      userPseudoId,
      query,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('AutonomousAnalytic', 'sendPageView', error);
  }
};

const sendAAEvent = async ({
  eventName,
  data,
  userAddress,
  platform = 'web',
}: any): Promise<void> => {
  try {
    const eventParams: any = [];
    eventParams.push({
      key: 'userAddress',
      value: localStorage.getItem(USER_ADDRESS) || '',
    });
    // eslint-disable-next-line array-callback-return
    data &&
      Object.keys(data || {}).map(key => {
        if (typeof data[key] === 'object') {
          eventParams.push({ key, value: JSON.stringify(data[key] ?? '') });
        } else {
          eventParams.push({ key, value: data[key] ?? '' });
        }
      });
    let userPseudoId = storageUtil.get(USER_PSEUDO_ID);
    if (isEmpty(userPseudoId)) {
      userPseudoId = uuidv4();
      storageUtil.set(USER_PSEUDO_ID, userPseudoId);
    }
    await AutonomousAnalytic.trackEvent({
      eventName,
      eventParams,
      platform,
      userAddress: userAddress || localStorage.getItem(USER_ADDRESS) || '',
      userPseudoId,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('AutonomousAnalytic', 'sendAAEvent', error);
  }
};

export { sendAAPageView, sendAAEvent };
