// import { getAccessToken } from '@/utils/auth-storage';
import axios from 'axios';
import http from 'http';
import https from 'https';

export const TIMEOUT = 5 * 60000;

export const HEADERS = { 'Content-Type': 'application/json' };

const createAxiosInstance = ({ baseURL = '' }: { baseURL: string }) => {
  const instance = axios.create({
    baseURL,
    timeout: TIMEOUT,
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    headers: {
      ...HEADERS,
    },
  });

  instance.interceptors.request.use(
    config => {
      // const token = getAccessToken();
      // if (token) {
      //   config.headers.Authorization = `Bearer ${token}`;
      // }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    res => {
      let result = res?.data?.data || res?.data?.result;
      const error = res?.data?.error;
      if (error && Object.keys(error).length) {
        return Promise.reject(error);
      }
      // if (res?.data?.count) {
      //   result = {
      //     ...result,
      //     rows: result,
      //     count: res?.data?.count,
      //   };
      // }
      return Promise.resolve(result);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      if (!error.response) {
        return Promise.reject(error);
      } else {
        const response = error?.response?.data || error;
        const errorMessage =
          response?.error || error?.Message || JSON.stringify(error);
        return Promise.reject(errorMessage);
      }
    }
  );

  return instance;
};

export default createAxiosInstance;
