import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import useApi from '@/hooks/useApi';
import { useIsInViewport } from '@/hooks/useIsInViewport';

type Props = {
  src: string;
  size?: number;
  speed?: number;
};

export const fetchApi = async (src: any) => {
  try {
    const response = await fetch(src);
    const data = await response.json();
    return { data };
  } catch (error: any) {
    console.log('fail get sticker', error.message);
    return {};
  }
};

const LottieWrapper = ({ src, speed = 1, size = 40, ...props }: Props) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(sectionRef, { threshold: 0 });
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  // const [data, setData] = useState<any>();
  // const fetchJson = () => {
  //   fetch(src)
  //     .then(response => {
  //       return response.json();
  //     })
  //     .then(data => {
  //       setData(data);
  //     })
  //     .catch((e: Error) => {
  //       console.log(e.message);
  //     });
  // };
  // useEffect(() => {
  //   fetchJson();
  // }, []);

  const { data: stickerJson } = useApi(fetchApi, src, {
    revalidateOnFocus: false, // Disable re-fetching on window focus
    shouldRetryOnError: false, // Prevent automatic retries on error
    refreshWhenHidden: false, // Prevent re-fetching when tab is hidden
    refreshWhenOffline: false, // Prevent re-fetching when offline
    dedupingInterval: 3600000,
  });

  useEffect(() => {
    if (lottieRef.current && isInViewport) {
      lottieRef.current?.setSpeed(speed);
      lottieRef.current?.play();
      setTimeout(
        () => {
          lottieRef.current?.stop();
        },
        (lottieRef.current?.getDuration() || 0) * 5000
      );
    }
  }, [isInViewport, lottieRef]);

  if (!isInViewport)
    return <Box width={`${size}px`} height={`${size}px`} ref={sectionRef} />;

  return (
    <Box width={`${size}px`} height={`${size}px`} ref={sectionRef}>
      <Lottie
        lottieRef={lottieRef}
        animationData={stickerJson.data}
        {...props}
      />
    </Box>
  );
};

export default LottieWrapper;
