import React, { useRef } from 'react';
import context from '../context';
import Logger from '../Logger';

const useSocketProvider = () => {
  const { socket, isConnected } = React.useContext(context);

  const socketRef = React.useRef(socket);
  const isConnectedRef = useRef(isConnected);

  React.useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  React.useEffect(() => {
    isConnectedRef.current = isConnected;
  }, [isConnected]);

  const sendEmit = React.useCallback(
    (event: string | symbol, ...args: any[]): Promise<boolean> => {
      return new Promise(resolve => {
        if (socketRef.current) {
          if (isConnectedRef.current) {
            socketRef.current.sendEmit(event, ...args);
            resolve(true);
          } else {
            setTimeout(() => {
              sendEmit(event, ...args);
            }, 150);
          }
        }
      });
    },
    []
  );

  const addEventListener = React.useCallback(
    (eventName: string, handler: (...rest: any) => void): Promise<boolean> => {
      return new Promise(resolve => {
        if (socketRef.current) {
          if (isConnectedRef.current) {
            socketRef.current.addEventListener(eventName, handler);
            resolve(true);
          } else {
            setTimeout(() => {
              addEventListener(eventName, handler);
            }, 150);
          }
        }
      });
    },
    []
  );

  const removeEventListener = React.useCallback(
    (eventName: string, handler: (...rest: any) => void): Promise<boolean> => {
      return new Promise(resolve => {
        if (socketRef.current) {
          if (isConnectedRef.current) {
            socketRef.current.removeEventListener(eventName, handler);
            resolve(true);
          } else {
            setTimeout(() => {
              removeEventListener(eventName, handler);
            }, 150);
          }
        }
      });
    },
    []
  );

  return {
    socket,
    isConnected,
    sendEmit,
    addEventListener,
    removeEventListener,
  };
};

export default useSocketProvider;
