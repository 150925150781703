import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import React from 'react';

const ReactionRed = () => {
  return (
    <>
      <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-emoji-red.svg`} />
    </>
  );
};

export default ReactionRed;
