import ReferralEarning from '@/modules/AlphaPWA/OverlayPages/ReferralEarning';
import Congratulation from '@/modules/AlphaPWA/OverlayPages/ShareReferral/Congratulation';
import { ReferralModal } from '@/modules/AlphaPWA/OverlayPages/ShareReferral/types';
import { useCallback, useState } from 'react';

export default function ShareReferral() {
  const [modal, setModal] = useState<ReferralModal>('Congratulation');

  if (modal === 'Congratulation') {
    return <Congratulation setModal={setModal} />;
  } else if (modal === 'ReferralList') {
    return <ReferralEarning />;
  }
  return <></>;
}
