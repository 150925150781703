import { RootState } from '@/state';
import { createSelector } from '@reduxjs/toolkit';
import { INetworkIDsMap, NetworkState } from './types';
import { ITokenMultiChain } from '../tokens/types';
import { NETWORK_NAME_MAP } from './constants';

export const getNetworkStateSelector = (state: RootState): NetworkState =>
  state.networks;

export const getAllNetworkListSelector = createSelector(
  getNetworkStateSelector,
  networkState => {
    return networkState.networkList || [];
  }
);

export const getNetworkListSelector = createSelector(
  getNetworkStateSelector,
  networkState => {
    return (
      networkState.networkList?.filter(network => network?.status == 1) || []
    );
  }
);

export const getNetworkByChainID = createSelector(
  getNetworkListSelector,
  networkList => (chainId: number) => {
    return networkList.find(network => network.chainId === chainId);
  }
);

export const getNetworkByNetworkID = createSelector(
  getNetworkListSelector,
  networkList => (networkID: string) => {
    return (
      networkList.find(
        network => network?.id?.toUpperCase() === networkID?.toUpperCase()
      ) || networkList[0]
    );
  }
);

export const getCurrentFilterNetworkSelector = createSelector(
  getNetworkStateSelector,
  networkState => networkState.currentNetworkFitler
);

export const getNetworkByNameSelector = createSelector(
  getNetworkListSelector,
  networkList => (tokenNetworkName: string) => {
    return networkList.find(
      network => network.id?.toUpperCase() === tokenNetworkName.toUpperCase()
    );
  }
);

export const getNetworIDsMapSelector = createSelector(
  getNetworkListSelector,
  networkList => {
    let networkIDsMap: INetworkIDsMap = {};
    networkList.map(network => (networkIDsMap[network.id] = network));
    return networkIDsMap;
  }
);

export const getNetworkListFormSendSelector = createSelector(
  [getAllNetworkListSelector],
  allNetworkList => (tokenSelected: ITokenMultiChain | undefined) => {
    if (!tokenSelected) return [];
    const { networkId: networkIDOfToken, networkOutChainID } = tokenSelected;
    return allNetworkList.filter(
      network =>
        networkOutChainID?.toUpperCase() === network?.id.toUpperCase() ||
        networkIDOfToken?.toUpperCase() === network?.id.toUpperCase()
    );
  }
);

export const isOutChainNetworkByNetworkIDSelector = createSelector(
  getAllNetworkListSelector,
  networksList => (networkID: string) => {
    const networkFinded = networksList.find(
      network => network.id?.toUpperCase() === networkID.toUpperCase()
    );
    if (networkFinded) {
      return networkFinded.networkName?.toUpperCase() !== NETWORK_NAME_MAP.NOS;
    } else {
      return false;
    }
  }
);
