import { IGameType } from '@/hooks/useContentWindowMessage';
import { IJackpotLeaderboardItem } from '../jackpot';

interface ICategoryNameAPI {
  id: string;
  name: string;
  description: string;
}

interface ICategoryItem {
  categories: string[];
  name: string;
  backgroundUrl: string;
  downloadUrl: string;
  gameId: string;
  description?: string;
  title?: string;
  iconUrl?: string;
  thumbnail?: string;
  gameUrl?: string;
  iframeUrl?: string;
  author?: string;
  isAvailable: boolean;
  fileName: string;
  totalEarning?: string;
  totalPlays?: number;
}

interface ILauncher {
  name: string;
  iconUrl: string;
  backgroundUrl: string;
  downloadUrl: string;
  gameId: string;
  author: string;
  isAvailable: boolean;
  fileName: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
  items: ICategoryItem[];
}

export interface IJackpotLeaderboardResponse {
  leaderboard: IJackpotLeaderboardItem[];
}

interface ILeaderboardResponse {
  leaderboard: ILeaderboardItem[];
  total: number;
}

export interface IPLayerHistoryResponse {
  history: string[];
  totalScore: number;
}

interface ILeaderboardItem {
  address: string;
  earning: string;
  name?: string;
  pfp?: string;
  pfpUrl?: string;
}

interface IUserGameInfo {
  name?: string;
  pfpUrl?: string;
}

interface IUserGameProgress {
  gamePlayed: number;
  milestone: number;
  prize: string;
  step?: number;
}

export type {
  ICategoryNameAPI,
  ICategoryItem,
  ICategory,
  ILauncher,
  ILeaderboardItem,
  IUserGameInfo,
  IUserGameProgress,
  ILeaderboardResponse,
};

export interface IJackpotItem {
  matchId: string;
  bestPlayer: string;
  bestScore: string;
  value: string;
  deadline: string;
  game: {
    thumbnailUrl: string;
    gameUrl: string;
  };
}

export interface IJackpotLeaderboardPlayerResponse {
  address: string;
  averageScore: string;
  matches: { score: string }[];
  rank: number;
  hasTicket: boolean;
  usedTickets: number;
  score: string;
  issuedShare: boolean;
  reward: string;
  maxScore: string;
  minScore: string;
  tokenAddress?: string;
}

export interface IJackpotResponse {
  jackpot: IJackPotInfo;
}

export interface IGameConfigResponse {
  config: IJackPotInfo;
}

export interface IGameConfig {
  key: string;
  value: string;
}

export interface IJackPotInfo {
  id: string;
  endAt: string;
  additionalDuration: string;
  rewardConfigIndex: number;
  token: string;
  soldTickets: string;
  topPlayerCount: number;
  isOpening: boolean;
  feePercentage: number;
  operationFundingPercentage: number;
  reversePercentage: number;
  value: string;
  ticketPrice: string;
  game?: {
    thumbnailUrl: string;
    gameUrl: string;
    name: string;
    description: string;
    descriptionText: string;
    numOfPlays: number;
    contractAddress: string;
  };
  balanceRequirement: string;
  alpha: string;
  submissionCount: string;
  rewardConfigId: number;
  tournamentType?: GroupRoomType;
  potToken?: string;
}

export interface IGameItem {
  name: string;
  thumbnailUrl: string;
  numOfPlays: number;
  gameUrl: string;
  description: string;
  descriptionText: string;
  id: IGameType;
  isUnityGame: boolean;
  additionalDuration?: number;
  initialDuration?: number;
}

export interface IJackpotGameListResponse {
  games: Array<IGameItem>;
}

export interface IAlphaInfo {
  result: {
    owner: {
      twitter_id: string;
      twitter_name: string;
      twitter_username: string;
      twitter_avatar: string;
      twitter_followers_count: number;
      bns: string;
      avatar: string;
      address: string;
      is_pre_sale: boolean;
    };
    token: {
      contract_address: string;
      name: string;
      symbol: string;
      supply: {};
    };
  };
  data: null;
  error: null;
}

export interface IGetPotDistributions {
  TotalReward?: number;
  RemainValue?: number;
  AlphaRewards?: number[];
  RewardedAlphas?: string[];
  AlphaPlayers?: string[][];
  AlphaPlayerRewards?: number[][];
}

export interface ITournamentCircleConfigItem {
  circleAddress: string;
  games: ITournamentGameConfig[];
}

export interface ITournamentGameConfig {
  name: string;
  initialDuration: number;
  additionalDuration: number;
  initPrizeAmount: string[];
  ticketPrice: string;
}

export enum GroupRoomType {
  KEY_CIRCLE = 0,
  TRIBE_PASSCODE_CIRCLE = 1,
  TRIBE_NFT_GATED_CIRCLE = 2,
  TRIBE_NO_PASSCODE_CIRCLE = 3,
  PASS_CIRCLE = 4,
}

export interface ITournamentCircleConfigItemV2 {
  circleAddress: string;
  games: ITournamentGameConfigV2[];
}

export interface ITournamentGameConfigV2 {
  name: string;
  initialDuration: number;
  additionalDuration: number;
  initPrizeAmount: string;
  ticketPrice: string;
}
