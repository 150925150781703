const CopyIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V10C4.5 10.5523 4.94772 11 5.5 11H10.5C11.0523 11 11.5 10.5523 11.5 10V5C11.5 4.44772 11.0523 4 10.5 4Z"
        stroke="#4185EC"
        stroke-opacity="0.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 8C1.95 8 1.5 7.55 1.5 7V2C1.5 1.45 1.95 1 2.5 1H7.5C8.05 1 8.5 1.45 8.5 2"
        stroke="#4185EC"
        stroke-opacity="0.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
