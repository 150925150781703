import React, { PropsWithChildren } from 'react';
import {
  changeWallet,
  Environment,
  refreshProvider,
  choiceConFig,
  WalletType,
} from 'trustless-swap-sdk';
import { isProduction } from '@/utils/commons';
import useProviderL2 from '@/hooks/useProviderL2';

export interface ISwapConfigsContext {
  setConfigs: () => void;
}

const initialValue: ISwapConfigsContext = {
  setConfigs: async () => undefined,
};

export const SwapConfigsContext =
  React.createContext<ISwapConfigsContext>(initialValue);

export const SwapConfigProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const provider = useProviderL2();

  const setConfigs = () => {
    changeWallet(WalletType.EXTENSION, '', '');
    choiceConFig(isProduction() ? Environment.MAINNET : Environment.TESTNET);
    refreshProvider(provider);
  };

  const values = React.useMemo(() => {
    return {
      setConfigs,
    };
  }, [setConfigs, provider]);

  React.useEffect(setConfigs, [provider]);

  return (
    <SwapConfigsContext.Provider value={values}>
      {children}
    </SwapConfigsContext.Provider>
  );
};
