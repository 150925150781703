import {
  EUniSupportChains,
  IUniSwapToken,
} from '@/contracts/swap/uniswap.interface';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { uniqBy } from 'lodash';
import { isProduction } from '@/utils/commons';
import {
  TokensInGoerli,
  TokensInMainnet,
} from '@/contracts/swap/uniswap.tokens.ether';

export interface IUniSwapTokenState {
  tokens: {
    [chain: string]: IUniSwapToken[];
  };
  currentChain: EUniSupportChains;
}

const initialState: IUniSwapTokenState = {
  tokens: {
    [EUniSupportChains.EtherChain]: TokensInMainnet,
  },
  currentChain: EUniSupportChains.EtherChain,
};

const uniswapTokenSlide = createSlice({
  name: 'uniswap',
  initialState,
  reducers: {
    setChain: (state, actions) => {
      state.currentChain = actions.payload;
    },
    updateUniswapTokens: (state, actions) => {
      const _tokens = state.tokens[`${state.currentChain}`];
      _tokens.push(actions.payload);

      state.tokens[`${state.currentChain}`] = uniqBy(_tokens, 'address');
    },
  },
});

export const { setChain, updateUniswapTokens } = uniswapTokenSlide.actions;

export const uniswapTokenReducer = (state: RootState) => state.uniswapToken;

export default uniswapTokenSlide.reducer;
