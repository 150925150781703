import { useEffect } from "react";
import { useAlphaThemeStore } from "@/hooks/useAlphaThemeStore";

export const useAlphaTheme = (modeInset: 'dark' | 'light') => {

    const { isAlphaPage, mode } = useAlphaThemeStore(state => state)
    const { setMode, setIsAlphaPage } = useAlphaThemeStore(state => state);

    useEffect(() => {
        setMode(modeInset);
        setIsAlphaPage(true);
    }, []);

    useEffect(() => {
        if (isAlphaPage) {
            document.documentElement.classList.add(`is-alpha-${mode}`);
        } else {
            document.documentElement.classList.remove(`is-alpha-dark`, 'is-alpha-light');
        }
        return () => {
            document.documentElement.classList.remove(`is-alpha-${mode}`);
        }
    }, [isAlphaPage, mode])
}
