import {
  IBodyBuySweetFloorKeys,
  IResponseBuySweetFloorKeys,
} from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { ITradeKey } from '@/services/interfaces/trade';
import {
  compareString,
  formatCurrency,
  formatName,
  getExplorer,
} from '@/utils';
import { colors } from '@/utils/color';
import {
  formatAmountToClient,
  getErrorMessageContract,
  getUrlAvatarTwitter,
} from '@/utils/helpers';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import clsx from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdWarning } from 'react-icons/md';
import HorizontalItem from '../components/horizontalItem';
import styles from '../styles.module.scss';
import Avatar from '../components/avatar';
import { isString } from 'lodash';
import CPlayerShare from '@/contracts';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import InfoTooltip from '../components/infoTooltip';

interface IBuying {
  keys: ITradeKey[];
  selectKeys: IBodyBuySweetFloorKeys[];
  waitKeys: IResponseBuySweetFloorKeys[];
  estimateKeysTC: any[];
  setNumProcessDone: (_: any) => void;
  isInvite?: boolean;
}

interface ItemBuying extends IBuying {
  token_address: string;
  isLast?: boolean;
  isInvite?: boolean;
}

const ItemBuying: React.FC<ItemBuying> = ({
  keys,
  token_address,
  isLast,
  waitKeys,
  estimateKeysTC,
  setNumProcessDone,
  isInvite,
}) => {
  const refBuying = useRef(false);

  const infoTransaction = useMemo(
    () => waitKeys?.find?.(v => compareString(v.token_address, token_address)),
    [token_address, waitKeys]
  );

  const infoKey = useMemo(
    () => ({
      ...keys.find(v => compareString(v.address, token_address)),
      ...infoTransaction,
    }),
    [token_address, keys, infoTransaction]
  );

  const estimateKeyTC = useMemo(
    () =>
      estimateKeysTC.find(v => compareString(v.token_address, token_address)),
    [token_address, estimateKeysTC]
  );

  const { scanTrxAlpha } = useTradeKey();

  const gameWalletProvider: IWalletContext = useContext(WalletContext);

  const [loading, setLoading] = useState(true);
  const [txSuccess, setTxSuccess] = useState<any>(undefined);
  const [txError, setTxError] = useState<any>(undefined);

  // useEffect(() => {
  //   if (infoTransaction && !refBuying.current) {
  //     refBuying.current = true;
  //     onWaitTransaction();
  //   }
  // }, [infoTransaction]);

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData) {
            if (
              !refBuying.current &&
              compareString(parsedData?.token_address, token_address) &&
              parsedData?.tx_hash
            ) {
              console.log('messageBuy', event);
              refBuying.current = true;
              onWaitTransaction(parsedData);
            }
          }
        } catch (error) {}
      },
      false
    );
  }, []);

  const onWaitTransaction = async (_infoTransaction: any) => {
    try {
      if (_infoTransaction?.error) {
        throw _infoTransaction?.error;
      }

      const tx =
        await gameWalletProvider.gameWallet?.provider.waitForTransaction(
          _infoTransaction?.tx_hash
        );

      await scanTrxAlpha(tx?.transactionHash as string);
      setTxError(undefined);
      setTxSuccess(tx);
    } catch (error) {
      setTxError(getErrorMessageContract(error)?.message);
      setTxSuccess(undefined);
    } finally {
      setNumProcessDone((v: any) => v + 1);
      setLoading(false);
    }
  };

  const renderStatus = useMemo(() => {
    if (loading) {
      return (
        <>
          <Spinner color={colors.redSecondary} />
          <Text color={colors.redSecondary}>
            {isInvite ? 'Sending' : 'Buying'}
          </Text>
        </>
      );
    }

    if (txSuccess) {
      return (
        <>
          <BsFillCheckCircleFill color={colors.greenPrimary} />
          <Text
            onClick={() =>
              window.open(
                getExplorer(txSuccess?.transactionHash, 'nos', 'tx'),
                '_blank'
              )
            }
            color={colors.greenPrimary}
            textDecorationLine={'underline'}
          >
            {isInvite ? 'Sent' : `Successfully`}
          </Text>
        </>
      );
    }

    return (
      <InfoTooltip label={txError}>
        <Flex alignItems={'center'} gap={2}>
          <MdWarning color={colors.redPrimary} />
          <Text color={colors.redPrimary}>{`Failed`}</Text>
        </Flex>
      </InfoTooltip>
    );
  }, [loading, txSuccess]);

  return (
    <HorizontalItem
      className={clsx(
        styles.buyingContainerItem,
        isLast && styles.buyingContainerItemLast
      )}
      label={
        <Flex gap={'5px'} alignItems={'center'}>
          <Avatar
            url={getUrlAvatarTwitter(infoKey?.user_twitter_avatar as string)}
            width={32}
            name={infoKey?.user_twitter_name}
            address={infoKey?.owner}
            fontSize={14}
          />
          <Flex flexDirection={'column'}>
            <Text fontWeight={'500'}>
              {formatName(
                (infoKey?.user_twitter_name ||
                  infoKey?.user_twitter_username ||
                  infoKey?.address) as string,
                20
              )}
            </Text>
            <Text as="b">
              {formatCurrency(
                isInvite
                  ? estimateKeyTC.btc_amount
                  : infoTransaction
                  ? formatAmountToClient(infoTransaction?.tc_amount?.toString())
                  : infoKey.price,
                0,
                6,
                'BTC'
              )}{' '}
              {infoKey?.base_token_symbol}
            </Text>
          </Flex>
        </Flex>
      }
      value={
        <Flex alignItems={'center'} gap={2}>
          {renderStatus}
        </Flex>
      }
    />
  );
};

const Buying: React.FC<IBuying> = ({
  keys,
  selectKeys,
  waitKeys,
  estimateKeysTC,
  setNumProcessDone,
  isInvite,
}) => {
  return (
    <>
      <Flex
        className={styles.buyingContainer}
        flexDirection={'column'}
        gap={2}
        maxHeight={'60vh'}
      >
        {selectKeys.map((v, i) => (
          <ItemBuying
            key={'sweep_' + v.token_address}
            keys={keys}
            token_address={v.token_address}
            isLast={i === selectKeys.length - 1}
            waitKeys={waitKeys}
            estimateKeysTC={estimateKeysTC}
            setNumProcessDone={setNumProcessDone}
            selectKeys={selectKeys}
            isInvite={isInvite}
          />
        ))}
      </Flex>
    </>
  );
};

export default Buying;
