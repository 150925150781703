import { getNetworkListAPI } from '@/services/networks';
import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { INetwork, NetworkState } from './types';
import { NETWORK_ALL } from './constants';

export const PREFIX = 'NETWORKS';

const setNetworkList: CaseReducer<NetworkState, PayloadAction<INetwork[]>> = (
  state,
  action
) => {
  state.networkList = action.payload;
  return state;
};

const setNetworkFilter: CaseReducer<NetworkState, PayloadAction<INetwork>> = (
  state,
  action
) => {
  state.currentNetworkFitler = action.payload;
};

const setNetworkAllDefault: CaseReducer<NetworkState> = (state, action) => {
  state.currentNetworkFitler = NETWORK_ALL;
};

const fetchNetworkList: any = createAsyncThunk(
  `${PREFIX}/fetchNetworkList`,
  async () => {
    const networkList: INetwork[] = await getNetworkListAPI();
    return networkList;
  }
);

const actionCreators = {
  setNetworkList,
  setNetworkFilter,
  setNetworkAllDefault,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export { fetchNetworkList };
