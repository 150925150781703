import { existsSync } from 'fs';
import { IRequestList, IResponse } from './services';

export interface IPassTokenParams extends IRequestList {
  search?: string;
  key_type?: '1' | '2'; // 1: ido, 2: tokens
}

export interface IPassToken {
  address?: string;
  address_check?: string;
  total_supply?: string;
  total_supply_number?: number;
  base_token_symbol?: string;
  owner?: string;
  created_at?: Date;
  volume?: string;
  total_volume?: string;
  price?: string;
  usd_price?: string;
  percent?: number;
  percent_7day?: number;
  user_twitter_id?: string;
  user_twitter_name?: string;
  user_twitter_username?: string;
  user_twitter_avatar?: string;
  twitter_followers_count?: number;
  contributors?: number;
  min_holding_requirement?: string;
  twitter_description?: string;
  fund_ended_at?: Date;
  is_fund_ended?: boolean;
  fund_balance_btc?: string;
  fund_balance_token?: string;
  fund_balance_claimed_btc?: string;
  fund_balance_claimed_token?: string;
  key?: IPassTokenKey;
  treasury_amount?: string;
}
export interface IPassTokenSelect extends IPassToken {
  buy_amount: number;
}

export interface IPassTokenKey {
  id?: number;
  twitter_id?: string;
  twitter_user?: null;
  token_address?: string;
  supply?: string;
  buy_price?: string;
  buy_price_usd?: string;
  is_pre_sale?: boolean;
  red_flag?: number;
  min_holding_requirement?: string;
  converted_at?: null;
  player_fee_rate?: string;
}

export interface IPassTokensResponse extends IResponse {
  count: number;
  rows: IPassToken[];
}

export interface IUserTokenHolding {
  id: number;
  network: string;
  address: string;
  address_checked: string;
  bns?: string;
  avatar?: string;
  twitter_id: string;
  twitter_username: string;
  twitter_name: string;
  twitter_avatar: string;
  twitter_followers_count: number;
  token_address: string;
  vol?: number;
  buy_price: string;
  usd_price: string;
  base_token_symbol: string;
  total_point: number;
  total_point_inday: number;
  boost: number;
  ranking: number;
  need_active: boolean;
  volume: number;
  total_volume: number;
  price: number;
  percent: number;
  percent_7day: number;
  activity: string;
  transaction_date: string;
  portfolio_value: number;
  last_claim?: any;
  is_watched: boolean;
  portfolio: number;
  total_cost: number;
  win_rate: number;
  win: number;
  total_holdings: number;
  total_holders: number;
  total_watchers: number;
  pnl_percent: number;
  pnl_usd: number;
  pnl_unrealized_usd: number;
  pnl_unrealized_percent: number;
  token_price: number;
  token_price_usd: number;
  nbc_verified: boolean;
  top_holdings?: any;
  role: string;
  total_amount: string;
}

export interface IPassTokenInfo {
  id: number;
  network: string;
  name: string;
  symbol: string;
  contract_address: string;
  decimals: number;
  image_url: string;
}

export interface IPassTokenHoldingsResponse extends IRequestList {
  data: any;
  error: any;
  count: number;
  rows: IPassTokenHolding[];
}

export interface IPassTokenHolding {
  user: IUserTokenHolding;
  token: IPassTokenInfo;
  balance: string;
  ft_balance: string;
  price: string;
  buy_price: string;
  usd_price: string;
  usd_buy_price: string;
  percent: number;
  total_volume: string;
  base_token_symbol: string;
  user_type: number;
}

export interface IKeyHolder {
  address: string;
  balance: string;
}

export enum IMarketSortCol {
  pass_token_buy_price = 'pass_token_buy_price',
  key_buy_price = 'buy_price',
  pass_token_percent = 'pass_token_percent',
  key_percent = 'percent',
  pass_token_created_at = 'pass_token_created_at',
  key_created_at = 'created_at',
}

export enum IMarketSortType {
  asd = '1',
  dsd = '0',
}

export interface IMarketTokensRequest extends IRequestList {
  sort_col?: IMarketSortCol;
  sort_type?: IMarketSortType;
}

export interface IMarketTokensResponse extends IResponse {
  count: number;
  rows: IMarketToken[];
}

export interface IMarketSelected extends IMarketToken {}
export interface IMarketToken {
  address?: string;
  address_check?: string;
  total_supply?: string;
  total_supply_number?: number;
  base_token_symbol?: string;
  owner?: string;
  created_at?: string;
  activated_at?: null;
  volume?: string;
  total_volume?: string;
  price?: string;
  usd_price?: string;
  percent?: number;
  percent_7day?: number;
  user_twitter_id?: string;
  user_twitter_name?: string;
  user_twitter_username?: string;
  user_twitter_avatar?: string;
  twitter_followers_count?: number;
  num_mention?: number;
  bns?: string;
  avatar?: string;
  is_pre_sale?: boolean;
  friend_tech_index?: number;
  min_holding_requirement?: string;
  latest_online?: null;
  ft_trader_address?: string;
  ft_share_price?: string;
  side?: number;
  holders?: null;
  portfolio?: number;
  twitter_description?: string;
  treasury_amount?: string;
  pass_token_address?: string;
  pass_token_price?: string;
  pass_token_supply?: number;
  pass_token_usd_price?: string;
  pass_token_percent?: number;
}
