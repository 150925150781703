import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface IAffiliateTokenState {
  poolBalance: string;
}

const initialState: IAffiliateTokenState = {
  poolBalance: '0',
};

const affiliateTokenSlide = createSlice({
  name: 'affiliateToken',
  initialState,
  reducers: {
    setPoolBalance: (state, actions) => {
      state.poolBalance = actions.payload;
    },
  },
});

export const { setPoolBalance } = affiliateTokenSlide.actions;

export const affiliateTokenReducer = (state: RootState) => state.affiliateToken;

export default affiliateTokenSlide.reducer;
