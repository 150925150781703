import React from 'react';
import useCountdown from '@/hooks/useCountdown';

interface IProps {
  deadline: string;
  withUnit?: boolean;
  isEnded?: boolean;
}

const CountDownText: React.FC<IProps> = ({
  deadline,
  withUnit = true,
  isEnded = true,
}): React.ReactElement => {
  const { minutes, hours, seconds, days, ended } = useCountdown(deadline);

  if (!deadline) return <>-h : --m : --s</>;

  if (days == 0 && minutes == '00' && hours == '00' && seconds == '00')
    return <>...</>;

  return (
    <>
      {!ended ? (
        <>
          {`${
            days !== null && days > 0 ? `${days}${withUnit ? 'd' : ''} : ` : ''
          } ${hours !== null ? `${hours}${withUnit ? 'h' : ''}` : '--'} : ${
            minutes !== null ? `${minutes}${withUnit ? 'm' : ''}` : '--'
          } : ${seconds !== null ? `${seconds}${withUnit ? 's' : ''}` : '--'}`}
        </>
      ) : isEnded ? (
        <>Ended</>
      ) : (
        <>Calculating...</>
      )}
    </>
  );
};

export default CountDownText;
