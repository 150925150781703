/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { storageUtil, LOCAL_STORAGE_CONFIG } from '../storage';
import constant from '../constant';
import AASession from '../session';

const RAPID_API_KEY = 'f241372ab6msh5c8c929734bfec8p1962d5jsnb0e1e649e8a5';

const detectLocationFromIpRequest = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const dataDetectIp = storageUtil.get(LOCAL_STORAGE_CONFIG.dataDetectIp);
    if (dataDetectIp && dataDetectIp?.ip) {
      resolve({
        status: 200,
        data: {
          ...dataDetectIp,
          region_name: dataDetectIp?.region_name || dataDetectIp?.region || '',
          region_code: dataDetectIp?.region_code || dataDetectIp?.region || '',
          country_name:
            dataDetectIp?.country_name || dataDetectIp?.country || '',
          organization: dataDetectIp?.organization || '',
        },
      });
    } else {
      axios({
        method: 'GET',
        url: 'https://telize-v1.p.rapidapi.com/geoip',
        headers: {
          'x-rapidapi-host': 'telize-v1.p.rapidapi.com',
          'x-rapidapi-key': RAPID_API_KEY,
        },
      })
        .then(response => {
          if (response.status === 200) {
            const { data } = response;
            if (data.ip) {
              storageUtil.set(LOCAL_STORAGE_CONFIG.dataDetectIp, {
                ...data,
                region_name: data.region || '',
                region_code: data.region || '',
                country_name: data.country || '',
              });
              resolve({
                status: 200,
                data: {
                  ...data,
                  region_name: data.region || '',
                  region_code: data.region || '',
                  country_name: data.country || '',
                },
              });
            } else {
              reject(data?.error);
            }
          }
        })
        .catch(e => {
          reject(e);
        });
    }
  });
};

class ApiCaller {
  static trackEvent(arg0: {
    eventName: any;
    eventParams: { key: string; value: any }[];
    platform: string;
    userId: any;
    userPseudoId: any;
  }) {
    throw new Error('Method not implemented.');
  }

  static trackPageView(arg0: {
    pageName: any;
    userId: any;
    userPseudoId: any;
  }) {
    throw new Error('Method not implemented.');
  }

  config: any;

  clientSecret: any;

  debug: any;

  session: AASession;

  constructor(config: { clientSecret: any; debug?: any }) {
    this.config = config;
    this.clientSecret = config.clientSecret;
    this.debug = config.debug || false;
    this.session = new AASession();
  }

  async trackEvent({
    eventName,
    eventParams,
    userId,
    userPseudoId,
    platform,
    ecommerce = false,
    items = false,
  }: any) {
    this.session.update();
    const { eventTrackingUrl } = constant;
    const eventPayload: any = {
      event_name: eventName,
      event_timestamp: Math.floor(Date.now() / 1000),
    };

    // Extra data processing
    const eventData: any = {};
    eventData.session_id = this.session.getSessionId();
    if (userId) {
      eventData.user_id = userId;
      eventParams.push({
        key: 'user_id',
        value: userId,
      });
    }

    if (userPseudoId) {
      eventData.user_pseudo_id = userPseudoId;
    }
    eventData.platform = platform || constant.platform.web;
    if (eventParams) {
      eventData.event_params = eventParams;
    }
    if (ecommerce) {
      eventData.ecommerce = ecommerce;
    }
    if (items) {
      eventData.items = items;
    }

    // Web info
    const isBrowser = typeof document !== 'undefined';
    let url;
    let userAgentString;
    let referrer;
    let screenHeight;
    let screenWidth;
    let browserHeight;
    let browserWidth: any = null;
    try {
      if (isBrowser) {
        url = window.location.href;
        userAgentString = navigator.userAgent;
        referrer = document.referrer;
        screenHeight = window.screen.availHeight;
        screenWidth = window.screen.availWidth;
        browserHeight = window.innerWidth;
        browserWidth = window.innerWidth;

        if (url) {
          eventData.web_info = { url };
          if (referrer) eventData.web_info.referrer = referrer;
          if (userAgentString) eventData.web_info.user_agent = userAgentString;
          if (screenHeight) eventData.web_info.screen_width = screenHeight;
          if (screenWidth) eventData.web_info.screen_height = screenWidth;
          if (browserHeight) eventData.web_info.browser_width = browserHeight;
          if (browserWidth) eventData.web_info.browser_height = browserWidth;
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    // location client
    try {
      const detectIp = await detectLocationFromIpRequest();
      if (detectIp?.status === 200) {
        const dataDetectIp = detectIp?.data;
        if (dataDetectIp && dataDetectIp?.ip) {
          eventParams.push({
            key: 'ip',
            value: dataDetectIp?.ip || '',
          });
          eventParams.push({
            key: 'country_name',
            value: dataDetectIp?.country_name || '',
          });
          eventParams.push({
            key: 'country_code',
            value: dataDetectIp?.country_code || '',
          });
          eventParams.push({
            key: 'state_name',
            value: dataDetectIp?.region_name || '',
          });
          eventParams.push({
            key: 'state_code',
            value: dataDetectIp?.region_code || '',
          });
          eventParams.push({
            key: 'city_name',
            value: dataDetectIp?.city || '',
          });
          eventParams.push({
            key: 'organization',
            value: dataDetectIp?.organization || '',
          });
        } else {
          eventParams.push({
            key: 'error_get_ip',
            value: JSON.stringify(dataDetectIp),
          });
        }
      } else {
        eventParams.push({
          key: 'error_get_api_ip',
          value: JSON.stringify(detectIp),
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    // utm_source
    try {
      eventParams.push({
        key: 'utm_source',
        value: storageUtil.get('utm_source') || 'organic',
      });
      eventParams.push({
        key: 'utm_medium',
        value: storageUtil.get('utm_medium') || '',
      });
      eventParams.push({
        key: 'utm_campaign',
        value: storageUtil.get('utm_campaign') || '',
      });
      eventParams.push({
        key: 'utm_term',
        value: storageUtil.get('utm_term') || '',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // Finalize payload
    eventPayload.data = eventData;

    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('Fetch API', eventTrackingUrl, eventPayload);
    }

    // Submit data to backend
    axios({
      url: eventTrackingUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: this.clientSecret,
      },
      data: eventPayload,
    })
      .then(data => {
        if (this.debug) {
          // eslint-disable-next-line no-console
          console.log('Return data from API:', data);
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(
          'There has been a problem with your fetch operation:',
          err
        );
      });
  }

  trackPageView({ pageName, query = '', ...props }: any) {
    const pageViewEventName = constant.eventName.pageView;
    const eventParams = [
      { key: 'page_name', value: pageName },
      { key: 'page_query_params', value: query },
      { key: 'email', value: props?.email },
    ];
    this.trackEvent({
      ...props,
      eventName: pageViewEventName,
      eventParams,
    } as any);
  }

  trackComponentLoadTime({ componentName, startTime, endTime, ...props }: any) {
    const componentLoadEventName = constant.eventName.componentLoadTracking;
    const eventParams = {
      component_name: componentName,
      start_time: startTime,
      end_time: endTime,
    };
    this.trackEvent({
      ...props,
      eventName: componentLoadEventName,
      eventParams,
    } as any);
  }
}

export default ApiCaller;
