import React from 'react';

const IcSweepToken = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="black" />
      <path
        d="M12 7.91663C9.10144 7.91663 6.16669 8.81846 6.16669 10.5416C6.16669 12.2648 9.10144 13.1666 12 13.1666C14.8992 13.1666 17.8334 12.2648 17.8334 10.5416C17.8334 8.81846 14.8992 7.91663 12 7.91663ZM9.08335 13.7138V15.4638C9.80494 15.6382 10.6029 15.745 11.4167 15.7794V14.0294C10.6306 13.9988 9.8493 13.8931 9.08335 13.7138ZM12.5834 14.0288V15.7788C13.3695 15.7488 14.1509 15.6431 14.9167 15.4632V13.7132C14.1509 13.8931 13.3695 13.9988 12.5834 14.0288ZM16.0834 13.3399V15.0899C17.1339 14.6495 17.8334 14.0066 17.8334 13.1666V11.4166C17.8334 12.2566 17.1339 12.8995 16.0834 13.3399ZM7.91669 15.0899V13.3399C6.86669 12.8995 6.16669 12.256 6.16669 11.4166V13.1666C6.16669 14.006 6.86669 14.6495 7.91669 15.0899Z"
        fill="url(#paint0_linear_24548_20060)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_24548_20060"
          x1="6.16669"
          y1="11.848"
          x2="17.8334"
          y2="11.848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE259" />
          <stop offset="1" stop-color="#FFA751" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcSweepToken;
