import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import { BigNumber as EtherBigNumber, ethers, Wallet } from 'ethers';
import { useContext } from 'react';
import { TOKEN_ADDRESS } from '@/constants/token';
import BigNumber from 'bignumber.js';
import {
  ISwapParams,
  ISwapResponse,
  ISwap,
  ISwapToken2Key,
  ISwapExactOut,
} from '@/contracts/swap/swap.interface';
import {
  Token,
  setTOkenSwap,
  getBestRouteExactIn,
  choiceConFig,
  refreshProvider,
  Environment,
  changeWallet,
  WalletType,
  executeTradeSlippage,
  getBestRouteExactOut,
} from 'trustless-swap-sdk';
import { isProduction } from '@/utils/commons';
import CPlayerShare, { ETypes } from '..';
import { ALPHA_KEY_FACTORY_ADDRESS } from '@/configs';
import { parseEther } from 'ethers/lib/utils';
import { IBestRoute } from '@/modules/AlphaPWA/Swap/types';
import { compareString } from '@/utils';
import CTradeAPI from '@/services/classes/trade';
import CLiquidityAPI from '@/services/classes/liquidity';

class CSwap {
  gameWalletProvider: IWalletContext = useContext(WalletContext);
  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;

  tokenBTC = new Token(1, TOKEN_ADDRESS.BTC_ADDRESS_L2, 18, 'btc', 'btc');
  tokenTC = new Token(1, TOKEN_ADDRESS.TC_ADDRESS_L2, 18, 'tc', 'tc');
  tokenETH = new Token(1, TOKEN_ADDRESS.ETH_ADDRESS_L2, 18, 'eth', 'eth');

  private contract = new CContract();
  private cplayerShare = new CPlayerShare();
  private tradeAPI = new CTradeAPI();
  private liquidityAPI = new CLiquidityAPI();

  private configSDK = () => {
    changeWallet(
      WalletType.PRIVATEKEY,
      this.wallet?.address || '',
      this.wallet?.privateKey || ''
    );
    choiceConFig(isProduction() ? Environment.MAINNET : Environment.TESTNET);
    refreshProvider(null);
  };

  private convertHumanAmountToWei = (humanAmount: string) => {
    return new BigNumber(humanAmount).multipliedBy(1e18).toString();
  };

  public approve = async (
    tokenAddress: string,
    humanAmount: string,
    signerAddress?: string
  ) => {
    const _signerAddress = signerAddress || TOKEN_ADDRESS.DEX_ROUTER_ADDRESS_L2;
    const contract = await this.contract
      .getERC20Contract(tokenAddress)
      .connect(this.wallet);

    const allowance = await contract.allowance(
      this.wallet.address,
      _signerAddress
    );
    const amountApprove = allowance.toString();

    const isNeedApprove = new BigNumber(amountApprove).lt(
      this.convertHumanAmountToWei(humanAmount)
    );

    console.log('CSwap approve', {
      amountApprove,
      isNeedApprove,
      tokenAddress,
      route: _signerAddress,
    });

    if (isNeedApprove) {
      const tx = await contract.approve(
        _signerAddress,
        ethers.constants.MaxUint256
      );

      await tx.wait();
    }
  };

  /**
   * estimate swap BTC to ETH
   */
  public estimateSwapBTC2TC = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap estimateSwapBTC2TC rs1:', rs1);

    return rs1;
  };

  /**
   * swap BTC to TC
   */
  public swapBTC2TC = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined> => {
    this.configSDK();
    await this.approve(TOKEN_ADDRESS.BTC_ADDRESS_L2, params.humanAmount);
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;
    console.log('CSwap swapBTC2TC params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap swapBTC2TC rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapBTC2TC rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash };
    }
  };

  /**
   * estimate swap BTC to ETH
   */
  public estimateSwapBTC2ETH = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenETH,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap estimateSwapBTC2ETH rs1:', rs1);

    return rs1;
  };

  /**
   * swap BTC to ETH
   */
  public swapBTC2ETH = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined | any> => {
    this.configSDK();
    await this.approve(TOKEN_ADDRESS.BTC_ADDRESS_L2, params.humanAmount);
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;

    console.log('CSwap swapBTC2ETH params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenETH,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap swapBTC2ETH rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapBTC2ETH rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash, amount: rs2[4] };
    }
    throw new Error('CSwap Swap BTC to ETH failed.');
  };

  /**
   * estimate swap
   */
  public estimateSwap = async (params: ISwap): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    const isExactIn = params.isExactIn === undefined ? true : params.isExactIn;

    const tokenIn = new Token(
      1,
      params.tokenIn.address,
      18,
      params.tokenIn.symbol,
      params.tokenIn.symbol
    );

    const tokenOut = new Token(
      1,
      params.tokenOut.address,
      18,
      params.tokenOut.symbol,
      params.tokenOut.symbol
    );

    console.log('CSwap estimateSwap rs1:', {
      tokenIn,
      in_amount,
      tokenOut,
    });

    setTOkenSwap(tokenIn, in_amount as unknown as number, tokenOut, 3000);

    let rs1: any = [];
    if (isExactIn) {
      rs1 = await getBestRouteExactIn(in_amount);
    } else {
      // in this case, we need to estimate the amount of tokenIn,
      // in_amount = expect amount receive
      rs1 = await getBestRouteExactOut(in_amount);
    }

    console.log('CSwap estimateSwap rs1:', rs1);

    return rs1;
  };

  /**
   * swap
   */
  public swapToken = async (
    params: ISwap
  ): Promise<ISwapResponse | undefined | any> => {
    const in_amount = await this.getNewAmount({
      tokenAddress: params.tokenIn.address,
      humanAmount: params.humanAmount,
    });

    this.configSDK();
    await this.approve(params.tokenIn.address, params.humanAmount);
    const scanTX = true; // scan tx wait for confirm

    console.log('[LOG] swapToken CSwap params:', params);

    const receiver = params.receiver || this.wallet.address;

    const tokenIn = new Token(
      1,
      params.tokenIn.address,
      18,
      params.tokenIn.symbol,
      params.tokenIn.symbol
    );

    const tokenOut = new Token(
      1,
      params.tokenOut.address,
      18,
      params.tokenOut.symbol,
      params.tokenOut.symbol
    );

    setTOkenSwap(tokenIn, in_amount as unknown as number, tokenOut, 3000);

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('[LOG] CSwap swap rs1:', rs1);

    const rs2 = await executeTradeSlippage(
      rs1[2],
      params.slipNumber || 50,
      receiver,
      scanTX
    );

    console.log('[LOG] CSwap swap rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      await this.tradeAPI.scanTrxAlpha({ tx_hash: rs2[1].transactionHash });
      await this.liquidityAPI.scanTxHash(rs2[1].transactionHash);
      return { txHash: rs2[1].transactionHash, amount: rs2[4] };
    }
    throw new Error('CSwap Swap failed.');
  };

  public getDeadline = () => new Date().getTime() + 15 * 60 * 1000;

  public swapExactOut = async (
    params: ISwapExactOut
  ): Promise<ISwapResponse | undefined | any> => {
    this.configSDK();

    const in_amount = await this.getNewAmount({
      tokenAddress: params.tokenIn.address,
      humanAmount: params.amountIn,
    });

    await this.approve(params.tokenIn.address, params.amountIn);

    console.log('[LOG] CSwap swapExactOut params:', params);

    const receiver = this.wallet.address;

    const data: any[] = await this.estimateSwap({
      tokenIn: {
        ...params.tokenIn,
      },
      tokenOut: {
        ...params.tokenOut,
      },
      humanAmount: params.amountOut,
      isExactIn: false,
    });

    const bestRoute: IBestRoute = data[1];

    const path = this.getRoutePath(bestRoute, params.tokenIn.address, true);

    const swapV3Contract = this.contract.getSwapV3Contract();

    const tx = await swapV3Contract.connect(this.wallet).exactOutput({
      path: path,
      recipient: receiver,
      deadline: this.getDeadline(),
      amountOut: parseEther(params.amountOut),
      amountInMaximum: parseEther(in_amount),
    });

    await tx.wait();
    if (tx.hash) {
      await this.tradeAPI.scanTrxAlpha({ tx_hash: tx.hash });
      await this.liquidityAPI.scanTxHash(tx.hash);
      return { txHash: tx.hash, amount: params.amountOut };
    } else {
      throw new Error('CSwap Swap swapExactOut failed.');
    }
  };

  public buyTC = async () => {
    // faucet via BTC

    try {
      await this.cplayerShare.getBTCApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });

      await this.cplayerShare.estimateTCGasFee({
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      console.log('[LOG] buyTC: 111 error ', error);
      // TODO: handle error
    }

    // buy TC via ETH
    try {
      await this.cplayerShare.getETHApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });

      await this.cplayerShare.estimateETHTCGasFee({
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      console.log(' CSwap buyTC error:', error);
      // TODO: handle error
    }

    // buy TC via USDT
    try {
      await this.cplayerShare.getUSDTApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });

      await this.cplayerShare.estimateUSDTTCGasFee({
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      console.log(' CSwap buy USDT error:', error);
    }
  };

  public getBalance = async (tokenAddress: string, walletAddress?: string) => {
    try {
      const tokenContract = this.contract.getERC20Contract(tokenAddress);
      const balance = await tokenContract.balanceOf(
        walletAddress || this.wallet?.address
      );
      return balance.toString();
    } catch (e) {
      return '0';
    }
  };

  public getNewAmount = async (params: {
    humanAmount: string;
    tokenAddress: string;
  }) => {
    const amountBefore = await this.getBalance(params.tokenAddress);

    await this.buyTC();

    const amountAfter = await this.getBalance(params.tokenAddress);

    let humanAmount = params.humanAmount;

    const ethDiff = new BigNumber(amountBefore).minus(amountAfter);

    if (
      ethDiff.gt(0) &&
      new BigNumber(params.humanAmount)
        .times(1e18)
        .plus(ethDiff)
        .gt(amountAfter)
    ) {
      humanAmount = new BigNumber(params.humanAmount)
        .minus(ethDiff.div(1e18))
        .toString();

      if (new BigNumber(params.humanAmount).lte(0)) {
        throw new Error('CSwap Swap ETH to Key failed.');
      }
    }

    console.log('[LOG] getNewAmount ', humanAmount);

    return humanAmount;
  };

  /**
   * swap Token (ETH, USDT, USDC ... ) -> KEY
   */
  public swapTokenToKey = async (
    params: ISwapToken2Key
  ): Promise<ISwapResponse | undefined | any> => {
    const tokenInAddress = params.tokenIn.address;

    console.log('params', params);

    const amountBefore = await this.getBalance(tokenInAddress);

    await this.buyTC();

    await this.approve(
      params.tokenIn.address,
      params.humanAmount,
      TOKEN_ADDRESS.ROUTER_ADDRESS
    );

    const amountAfter = await this.getBalance(tokenInAddress);

    const amountDiff = new BigNumber(amountAfter).minus(amountBefore);

    if (
      amountDiff.gt(0) &&
      new BigNumber(params.humanAmount)
        .times(1e18)
        .plus(amountDiff)
        .lt(amountAfter)
    ) {
      params.humanAmount = new BigNumber(params.humanAmount)
        .minus(amountDiff.div(1e18))
        .toString();

      console.log('params.humanAmount', params.humanAmount);

      if (new BigNumber(params.humanAmount).lte(0)) {
        throw new Error('CSwap Swap Token to Key failed.');
      }
    }

    const receiver = params.receiver || this.wallet.address;

    const routerContract = this.contract.getRouterContract(
      TOKEN_ADDRESS.ROUTER_ADDRESS
    );

    const alphaTokenContract = this.contract.getAlphaKeysTokenContract(
      params.tokenOut.address
    );

    const btcAmount = await alphaTokenContract.getBuyPriceAfterFeeV2(
      parseEther(params.keyAmount)
    );

    const BTCAddress = TOKEN_ADDRESS.BTC_ADDRESS_L2;
    const data: any[] = await this.estimateSwap({
      tokenIn: {
        ...params.tokenIn,
      },
      tokenOut: {
        ...params.tokenOut,
        address: BTCAddress,
      },
      humanAmount: params.humanAmount,
    });
    const bestRoute: IBestRoute = data[1];

    // ETH -> Key
    const path = this.getRoutePath(bestRoute, params.tokenIn.address, true);
    console.log('SWAP: path ', path);
    console.log(
      'SWAP: payload ',
      {
        path: path,
        recipient: receiver,
        deadline: new Date().getTime() + 15 * 60 * 1000,
        amountOut: btcAmount,
        amountInMaximum: parseEther(params.humanAmount),
      },
      params.tokenOut.address, //Key address
      parseEther(params.keyAmount)
    );
    // ETH -> Key
    const tx = await routerContract
      .connect(this.wallet)
      .tokenToExactKeyCrossPair(
        {
          path: path,
          recipient: receiver,
          deadline: new Date().getTime() + 15 * 60 * 1000,
          amountOut: btcAmount,
          amountInMaximum: parseEther(params.humanAmount),
        },
        params.tokenOut.address, //Key address
        parseEther(params.keyAmount)
      );

    await tx.wait();

    return tx;
  };

  public getRoutePath = (
    bestRoute: IBestRoute,
    addressIn: string,
    isReverse: boolean
  ) => {
    // ETH -> Key
    const paths: string[] = [];

    const replace0x = (address: string) => {
      return address.startsWith('0x') ? address.replace('0x', '') : address;
    };
    bestRoute.pathPairs.forEach((pathPair, index) => {
      let fee = ethers.utils
        .hexlify(EtherBigNumber.from(pathPair.fee))
        .replace('0x', '');
      if (fee.length === 1) {
        fee = '00000' + fee;
      } else if (fee.length === 2) {
        fee = '0000' + fee;
      } else if (fee.length === 3) {
        fee = '000' + fee;
      } else if (fee.length === 4) {
        fee = '00' + fee;
      } else if (fee.length === 5) {
        fee = '0' + fee;
      }

      if (index === 0) {
        paths.push(replace0x(addressIn));
        paths.push(fee);
        const token = compareString(
          replace0x(pathPair.token1),
          replace0x(addressIn)
        )
          ? pathPair.token0
          : pathPair.token1;
        paths.push(replace0x(token));
      } else {
        const token = paths.some(path =>
          compareString(replace0x(pathPair.token0), replace0x(path))
        )
          ? pathPair.token1
          : pathPair.token0;
        paths.push(fee);
        paths.push(replace0x(token));
      }
    });

    const newPaths = isReverse ? paths.reverse() : paths;
    const path = '0x' + newPaths.join('');

    console.log('SWAP getRoutePath: 222', {
      paths: newPaths,
      path,
      bestRoute,
    });
    return path;
  };

  /**
   * KEY -> Token (ETH, USDT, USDC ... )
   */
  public swapKeyToToken = async (
    params: ISwap
  ): Promise<ISwapResponse | undefined | any> => {
    await this.buyTC();
    await this.approve(
      params.tokenIn.address,
      params.humanAmount,
      TOKEN_ADDRESS.ROUTER_ADDRESS
    );
    await this.approve(
      TOKEN_ADDRESS.BTC_ADDRESS_L2,
      '1',
      TOKEN_ADDRESS.ROUTER_ADDRESS
    );

    const receiver = params.receiver || this.wallet.address;

    const routerContract = this.contract.getRouterContract(
      TOKEN_ADDRESS.ROUTER_ADDRESS
    );

    const mintPrice = await this.cplayerShare.getSellPriceAfterFee({
      token_amount: Number(params.humanAmount),
      token_address: params.tokenIn.address,
    });

    // get unix time
    const unixTime = Math.floor((new Date().getTime() + 15 * 60 * 1000) / 1000);

    const BTCAddress = TOKEN_ADDRESS.BTC_ADDRESS_L2;
    const data: any[] = await this.estimateSwap({
      tokenIn: {
        ...params.tokenIn,
        address: BTCAddress,
      },
      tokenOut: {
        ...params.tokenOut,
      },
      humanAmount: params.humanAmount,
    });
    const bestRoute: IBestRoute = data[1];

    // ETH -> Key
    const path = this.getRoutePath(bestRoute, BTCAddress, false);

    const tx = await routerContract.connect(this.wallet).keyToTokenCrossPair(
      {
        path: path,
        recipient: receiver,
        deadline: unixTime,
        amountIn: new BigNumber(params.humanAmount)
          .multipliedBy(1e18)
          .toString(),
        amountOutMinimum: '0',
      },
      params.tokenIn.address, //Key address,
      new BigNumber(params.humanAmount).multipliedBy(1e18).toString(),
      mintPrice
    );
    await tx.wait();

    return tx;
  };

  /**
   * estimate swap TC to BTC
   */
  public estimateSwapTC2BTC = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenTC,
      in_amount as unknown as number,
      this.tokenBTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap estimateSwapTC2BTC rs1:', rs1);
    return rs1;
  };

  /**
   * swap TC to BTC
   */
  public swapTC2BTC = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined> => {
    this.configSDK();
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;

    console.log('CSwap swapTC2BTC params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenTC,
      in_amount as unknown as number,
      this.tokenBTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap swapTC2BTC rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapTC2BTC rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash };
    }
  };
}

export default CSwap;
