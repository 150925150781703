import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import s from './ShareInfo.module.scss';
import cs from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { APP_ENV } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import {
  claimReferralEarning,
  getAddressReferents,
  getAddressReferralEarning,
  getReferralCode,
} from '@/services/referral';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { IReferralEarning } from '@/interfaces/api/referral';
import { isWebApp } from '@/utils/client';
import CopyIcon from '../InviteAndRewards/svg/CopyIcon';
import ShareIcon from '../InviteAndRewards/svg/ShareIcon';
import TweetIcon from '../InviteAndRewards/svg/TweetIcon';
import { TWITTER_SHARE_DM, TWITTER_SHARE_TWEET } from '@/constants/twitter';
import { formatTCAddress } from '@/utils/format';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { AssetsContext } from '@/contexts/assets-context';
import { getAddressReferrer } from '@/services/referral';
import EnterInviteCodeModal from '../EnterInviteCodeModal';
import ReferentsModal from '../ReferentsModal';
import { getPlayerPoolProfile } from '@/services/player-share';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { useRouter } from 'next/router';
import { getLink } from '@/utils/helpers';

type Props = React.ComponentPropsWithRef<'div'> & {
  referents: { address: string }[];
  referralEarnings?: IReferralEarning;
  setReferralEarnings?: (referralEarnings: IReferralEarning) => void;
  isModal?: boolean;
};

const ShareInfo = ({
  referents,
  referralEarnings,
  setReferralEarnings,
  children,
  className,
  isModal = false,
  ...rest
}: PropsWithChildren<Props>) => {
  const router = useRouter();

  const { addressL2 } = useContext(WalletContext);

  const { referralCode, fetchingReferralCode } = useContext(AssetsContext);
  const [referrerAddress, setReferrerAddress] = useState<string>();
  const [referrerProfile, setReferrerProfile] =
    useState<IGetPlayerPoolProfile>();

  const {
    isOpen: isOpenReferents,
    onOpen: onOpenReferents,
    onClose: onCloseReferents,
  } = useDisclosure({ id: 'referents' });

  const {
    isOpen: isOpenEnterInviteCode,
    onOpen: onOpenEnterInviteCode,
    onClose: onCloseEnterInviteCode,
  } = useDisclosure({
    id: 'enter_invite_code ',
  });

  const gaEventTracker = useAnalyticsEventTracker();
  const isPWA = isWebApp();

  const callApiCheck = useCallback(() => {
    getAddressReferrer().then(reference => {
      setReferrerAddress(reference);
    });
  }, []);

  useEffect(() => {
    callApiCheck();
  }, []);

  useEffect(() => {
    if (referrerAddress) {
      getPlayerPoolProfile(referrerAddress).then(profile => {
        setReferrerProfile(profile);
      });
    }
  }, [referrerAddress]);

  useEffect(() => {
    if (!setReferralEarnings) return;
    const getEarings = () => {
      getAddressReferralEarning().then(res => {
        setReferralEarnings(res);
      });
    };

    getEarings();

    let interval: any;
    if (!isOpenReferents) {
      interval = setInterval(() => {
        getEarings();
      }, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpenReferents]);

  const getReferrerName = () => {
    if (referrerProfile) {
      return (
        referrerProfile.twitterName ||
        referrerProfile.twitterUsername ||
        referrerProfile.bns ||
        formatTCAddress(referrerProfile.address)
      );
    }
    return formatTCAddress(referrerAddress || '');
  };

  return (
    <>
      <div {...rest} className={cs(s.shareInfo, className, isModal && s.modal)}>
        {fetchingReferralCode && <div className={s.loading}></div>}
        {/* Heading */}
        <div className={s.heading}>
          <h2 className={s.heading__title}>
            Get Bitcoin for referring a friend.
          </h2>

          <h4 className={s.heading__sub_title}>
            Love New Bitcoin City? Invite a friend and{' '}
            <span className={s.heading__sub_title__highlight}>
              earn 100% of their trading fees
            </span>{' '}
            in the first 90 days. Easy money, right?
          </h4>
        </div>

        {/* Codes */}
        <div className={s.codes}>
          <div
            className={cs(s.codes__block, s.codes__link)}
            onClick={() => {
              if (referralCode) {
                copy(getLink(referralCode));
                toast.remove();
                toast.success('Copied');
                gaEventTracker(GameFiActions.CopyReferralCode, addressL2);
              }
            }}
          >
            {/* <span className={s.codes__block__label}>Link</span> */}
            <span className={s.codes__block__value}>
              <span>{getLink(referralCode || '')}</span>
              <CopyIcon />
            </span>
          </div>

          <div
            className={cs(s.codes__block, s.codes__share)}
            onClick={() => {
              if (referralCode) {
                const url = getLink(referralCode);
                if (isPWA) {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: TWITTER_SHARE_DM,
                        text: TWITTER_SHARE_DM,
                        url,
                      })
                      .catch(error => {
                        window.open(url, '_blank');
                      });
                  } else {
                    window.open(url, '_blank');
                  }
                } else {
                  window.open(url, '_blank');
                }
                gaEventTracker(GameFiActions.ShareReferralCode, addressL2);
              }
            }}
          >
            {/* <SvgInset size={20} svgUrl={getShareIcon()} /> */}
            <span className={s.codes__share__icon}>
              <ShareIcon />
            </span>
            <span className={s.codes__block__label}>Share</span>
          </div>
          <div
            className={cs(s.codes__block, s.codes__share, s.tweet)}
            onClick={() => {
              const shareUrl = getLink(referralCode || '');
              const content = `${TWITTER_SHARE_TWEET}${shareUrl}`;
              const hashtags = '';

              gaEventTracker(GameFiActions.TweetReferralCode, addressL2);
              window.open(
                `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  content
                )}`,
                '_blank'
              );
            }}
          >
            {/* <SvgInset size={20} svgUrl={getShareIcon()} /> */}
            <span className={s.codes__share__icon}>
              <TweetIcon />
            </span>
            <span className={s.codes__block__label}>Tweet</span>
          </div>
        </div>

        {/* earningRewards */}
        {/* <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={'20px'}
          p={'0 16px'}
          mb={'16px'}
        >
          <div
            className={s.earningRewards}
            onClick={e => {
              // if has invited friend should show
              if (referents.length) {
                onOpenReferents();
              }
            }}
          >
            <div
              className={cs(s.earningRewards__block, s.earningRewards__invited)}
            >
              <span className={cs(s.earningRewards__block__amount)}>
                {referents.length || 0}
              </span>
              <h4 className={cs(s.earningRewards__block__title)}>invited</h4>
            </div>

            <div
              className={cs(s.earningRewards__block, s.earningRewards__total)}
            >
              <h4 className={cs(s.earningRewards__block__title)}>
                Total claimed
              </h4>
              <span className={cs(s.earningRewards__block__amount)}>
                {displayBTCValue(referralEarnings?.totalBtcClaim)}
              </span>
            </div>
          </div>
          <div
            className={cs(s.earningRewards__block, s.earningRewards__referCode)}
            onClick={() => {
              if (referralCode) {
                copy(referralCode);
                toast.remove();
                toast.success('Copied');
                gaEventTracker(GameFiActions.CopyReferralCode, addressL2);
              }
            }}
          >
            <h4 className={cs(s.earningRewards__block__title)}>Code</h4>
            <span className={cs(s.earningRewards__block__amount)}>
              {referralCode}
            </span>
            <CopyIcon />
          </div>
        </Flex> */}
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          mb={'16px'}
        >
          {referrerAddress ? (
            <div
              className={s.invite_code}
              onClick={() => {
                router.push(
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${referrerAddress}`
                );
              }}
            >
              <span className={cs(s.invite_code_text)}>
                You have been invited by
              </span>
              <span className={cs(s.invite_code_text, s.invite_code_hl)}>
                {getReferrerName()}
              </span>
            </div>
          ) : (
            <div className={s.invite_code} onClick={onOpenEnterInviteCode}>
              <span
                className={cs(
                  s.invite_code_text,
                  s.invite_code_hl,
                  s.invite_code_underline
                )}
              >
                Who invited you to New Bitcoin City?
              </span>
            </div>
          )}
          <div
            className={cs(s.yourReferralCode)}
            onClick={() => {
              if (referralCode) {
                copy(referralCode);
                toast.remove();
                toast.success('Copied');
                gaEventTracker(GameFiActions.CopyReferralCode, addressL2);
              }
            }}
          >
            <h4 className={cs(s.yourReferralCode__title)}>Code</h4>
            <span className={cs(s.yourReferralCode__amount)}>
              {referralCode}
            </span>
            <CopyIcon />
          </div>
        </Flex>

        {/* Claim */}
        {/* temp hide this section until implement claim feature */}
        {/*
            {Number(referralEarnings?.totalBtcRemain) >
            MINIMUM_BTC_AMOUNT_CAN_CLAIM ? (
              <>
                {isClaiming ? (
                  <div className={s.rewardClaiming}>
                    <Spinner color="blue" />
                  </div>
                ) : (
                  <div className={s.rewardClaiming} onClick={handleOnClaim}>
                    <span>Claim</span>
                  </div>
                )}
              </>
            ) : (
              <div className={cs(s.rewardClaiming, s.rewardClaiming_inactive)}>
                <span>Claim</span>
              </div>
            )}
          </div> */}
        {/* {children} */}

        <EnterInviteCodeModal
          isOpen={isOpenEnterInviteCode}
          onClose={() => {
            // getAddressReferrer().then(reference => {
            //   setIsReferralFromAnother(!!reference);
            // });
            callApiCheck();
            onCloseEnterInviteCode();
          }}
        />
      </div>
      <ReferentsModal
        isOpen={isOpenReferents}
        onClose={onCloseReferents}
        referents={referents || []}
        referentsInfo={referralEarnings?.referents || []}
      />
    </>
  );
};

export default ShareInfo;
