import { ITokenMultiChain } from '@/state/tokens/types';
import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { intersectionBy, isEmpty } from 'lodash';
import { RootState } from '..';
import { PREFIX } from './constants';
import { WalletActions } from './reducer';
import {
  getFetchignWalletBalanceFirstTimeSelector,
  getListTokenFollowSelector,
  getTokenFollowBalanceMapSelector,
} from './selector';
import {
  getTokenFollowingByAddress,
  setTokenFollowingToLocalStorage,
} from './storage';
import {
  TokenBalance,
  TokenFollowBalanceMap,
  TotalBTCBalanceFactory,
  WalletState,
} from './types';
import { getNetworIDsMapSelector } from '../networks/selector';
import {
  fetchBalanceFromListToken,
  getTokenBalanceFormat,
  getTotalBTCBalance,
} from './helper';
import { batch } from 'react-redux';
import { getTokenByTokenAddressSelector } from '../tokens/selector';
import { TOKEN_ADDRESS } from '@/constants/token';
import { isInValidAmount } from '@/utils/helpers';
import BigNumber from 'bignumber.js';
import { saveTotalBTCBalanceToStorage } from '@/utils/balance-storage';

const fetchingWalletBalance: CaseReducer<
  WalletState,
  PayloadAction<boolean>
> = (state, action) => {
  state.isFetchingBalance = action.payload;
};

const fetchingWalletBalanceFirstTime: CaseReducer<
  WalletState,
  PayloadAction<boolean>
> = (state, action) => {
  state.isFetchingBalanceFirstTime = action.payload;
};

const setTokenFollowBalanceMap: CaseReducer<
  WalletState,
  PayloadAction<TokenFollowBalanceMap>
> = (state, action) => {
  state.tokenFollowBalanceMap = action.payload;
};

const setTotalBTCBalanceFactory: CaseReducer<
  WalletState,
  PayloadAction<TotalBTCBalanceFactory | undefined>
> = (state, action) => {
  const walletAddress = state.addressL2;
  const totalBTCBalanceFactory_NEW = action.payload;
  const totalBalanceBTC = totalBTCBalanceFactory_NEW?.totalBalanceBTC || '0';
  state.totalBTCBalanceFactory = action.payload;

  if (
    walletAddress &&
    totalBTCBalanceFactory_NEW &&
    totalBalanceBTC &&
    !isInValidAmount(totalBalanceBTC) &&
    new BigNumber(totalBalanceBTC).gt(0)
  ) {
    saveTotalBTCBalanceToStorage(walletAddress, totalBalanceBTC);
  }
};

const saveListTokenIDsWithBalance: CaseReducer<
  WalletState,
  PayloadAction<boolean>
> = (state, action) => {
  state.isFetchingBalance = action.payload;
};

const setTcAddressL2: CaseReducer<WalletState, PayloadAction<string>> = (
  state,
  action
) => {
  state.addressL2 = action.payload;
};

const setListTokenFollow: CaseReducer<
  WalletState,
  PayloadAction<ITokenMultiChain[]>
> = (state, action) => {
  state.listTokenFollowing = action.payload;
};

const setupListTokenFollowing: CaseReducer<
  WalletState,
  PayloadAction<{
    listTokenDefault: ITokenMultiChain[];
    listAllTokens: ITokenMultiChain[];
  }>
> = (state, action) => {
  const addressL2 = state.addressL2;
  const listTokenDefault = action.payload.listTokenDefault;
  const listAllTokens = action.payload.listAllTokens;

  let listTokenFollowLocal = getTokenFollowingByAddress(addressL2);

  // Case Empty => Init List Token Default
  // listTokenFollowLocal = Undefined | Null
  if (!listTokenFollowLocal || isEmpty(listTokenFollowLocal)) {
    listTokenFollowLocal = listTokenDefault;
  }

  // Replace Data API to Local with (update price from API)
  listTokenFollowLocal = intersectionBy(
    listAllTokens,
    listTokenFollowLocal,
    'tokenId'
  );

  // Save to LocalStorage with new data
  setTokenFollowingToLocalStorage(addressL2, listTokenFollowLocal);

  state.listTokenFollowing = listTokenFollowLocal;
};

//Recieve Feature
const recieveTokenSelected: CaseReducer<
  WalletState,
  PayloadAction<ITokenMultiChain>
> = (state, action) => {
  state.recieveTokenSelected = action.payload;
};

//Send Feature
const sendTokenSelected: CaseReducer<
  WalletState,
  PayloadAction<ITokenMultiChain>
> = (state, action) => {
  state.sendTokenSelected = action.payload;
};

const fetchBalanceError: CaseReducer<WalletState, PayloadAction<any>> = (
  state,
  action
) => {
  state.fetchBalanceError = action.payload;
};

const loadBalanceHandler: any = createAsyncThunk(
  `${PREFIX}/loadBalanceHandler`,
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const addressL2 = state.wallets.addressL2;
    const currentTotalBTCBalanceFactory = state.wallets.totalBTCBalanceFactory;

    try {
      const listTokenFollowing = getListTokenFollowSelector(state);
      const isFirstTimeLoadBalance =
        getFetchignWalletBalanceFirstTimeSelector(state);
      const tokenFollowBalanceMap = getTokenFollowBalanceMapSelector(state);
      const networIDsMap = getNetworIDsMapSelector(state);
      const tokenBTC = getTokenByTokenAddressSelector(state)(
        TOKEN_ADDRESS.BTC_ADDRESS_L2
      );

      if (!addressL2 || !listTokenFollowing || listTokenFollowing.length < 1)
        return;

      if (isFirstTimeLoadBalance) {
        dispatch(WalletActions.fetchingWalletBalance(true));
      }

      let listBalance =
        (await fetchBalanceFromListToken({
          walletAddres: addressL2,
          listTokenFollowing: listTokenFollowing,
          networkIDsMap: networIDsMap,
        })) || [];

      let tokenFollowBalanceMap_NEW: TokenFollowBalanceMap = {};

      listTokenFollowing.map((token, index) => {
        const tokenID = token.tokenId;
        const tokenBalance = listBalance[index] || '0';
        const balanceFormat = getTokenBalanceFormat(token, tokenBalance);
        return (tokenFollowBalanceMap_NEW[tokenID] = {
          ...balanceFormat,
        });
      });

      let tokenFollowBalanceMap_Combined = {
        ...tokenFollowBalanceMap,
        ...tokenFollowBalanceMap_NEW,
      };

      const totalBTCBalanceFactory_NEW = getTotalBTCBalance({
        listTokenFollowing: listTokenFollowing,
        isFetchingFirstTime: isFirstTimeLoadBalance,
        tokenBalanceMap: tokenFollowBalanceMap_NEW,
        tokenBTC: tokenBTC,
        currentTotalBTCBalance: currentTotalBTCBalanceFactory,
      });

      batch(() => {
        dispatch(
          WalletActions.setTokenFollowBalanceMap(tokenFollowBalanceMap_Combined)
        );
        dispatch(
          WalletActions.setTotalBTCBalanceFactory(totalBTCBalanceFactory_NEW)
        );
        dispatch(WalletActions.fetchBalanceError(undefined));
      });
    } catch (error) {
      console.log('[WALLET] [loadBalanceHandler] -- ERROR -- ', error);
      dispatch(
        WalletActions.setTotalBTCBalanceFactory(currentTotalBTCBalanceFactory)
      );
    } finally {
      batch(() => {
        dispatch(WalletActions.fetchingWalletBalance(false));
        dispatch(WalletActions.fetchingWalletBalanceFirstTime(false));
      });
    }
  }
);

const actionCreators = {
  setTcAddressL2,
  setListTokenFollow,
  setupListTokenFollowing,
  setTokenFollowBalanceMap,
  fetchingWalletBalance,
  fetchingWalletBalanceFirstTime,
  fetchBalanceError,
  setTotalBTCBalanceFactory,

  //Recieve
  recieveTokenSelected,

  //Send
  sendTokenSelected,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export { loadBalanceHandler };
