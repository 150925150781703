import {
  Box,
  Button, Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { defaultKeyParams, selectCommonReducer } from '@/state/common';
import queryString from 'query-string';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import IcFilter from '../assets/icons/icFilter';
import {
  DEFAULT_FOLLOWERS_MAX,
  DEFAULT_FOLLOWERS_MIN,
  DEFAULT_RED_FLAG_MAX,
  DEFAULT_RED_FLAG_MIN,
  DEFAULT_TAGGED_MAX,
  DEFAULT_TAGGED_MIN,
  DEFAULT_TC_MAX,
  DEFAULT_TC_MIN,
} from '../constants';
import s from '../styles.module.scss';
import ItemFilterTypes, { IItemFilterType } from './itemFilterTypes';

const BtnFilter = ({
  onFilter,
  filters,
  keyName,
}: {
  onFilter: (_: any) => void;
  filters: IItemFilterType[];
  keyName: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filterKeyParams = (useSelector(selectCommonReducer) as any)[
    keyName
    ] as any;
  const [filterKeys, setFilterKeys] = useState(
    queryString.parse(filterKeyParams)
  );

  useEffect(() => {
    setFilterKeys(queryString.parse(filterKeyParams));
  }, [filterKeyParams]);

  const onHandleFilter = () => {
    onFilter(filterKeys);
    onClose();
  };

  const onReset = () => {
    setFilterKeys(_keys => ({
      ..._keys,
      ...defaultKeyParams,
    }));
  };

  const onSetFilter = (objFilter: any) => {
    setFilterKeys(_keys => ({ ..._keys, ...objFilter }));
  };

  const numFilters = useMemo(() => {
    let count = 0;
    if (keyName === 'filterKeyParams') {
      if (filterKeys?.followers) {
        const [min, max] = (filterKeys?.followers as any)?.split?.(',');
        if (
          Number(min) !== DEFAULT_FOLLOWERS_MIN ||
          Number(max) !== DEFAULT_FOLLOWERS_MAX
        ) {
          count++;
        }
      }
      if (filterKeys?.price_usd) {
        const [min, max] = (filterKeys?.price_usd as any)?.split?.(',');
        if (Number(min) !== DEFAULT_TC_MIN || Number(max) !== DEFAULT_TC_MAX) {
          count++;
        }
      }
      // if (filterKeys?.red_flags) {
      //   const [min, max] = (filterKeys?.red_flags as any)?.split?.(',');
      //   if (
      //     Number(min) !== DEFAULT_RED_FLAG_MIN ||
      //     Number(max) !== DEFAULT_RED_FLAG_MAX
      //   ) {
      //     count++;
      //   }
      // }
    } else {
      if (filterKeys?.followers) {
        const [min, max] = (filterKeys?.followers as any)?.split?.(',');
        if (
          Number(min) !== DEFAULT_FOLLOWERS_MIN ||
          Number(max) !== DEFAULT_FOLLOWERS_MAX
        ) {
          count++;
        }
      }
      if (filterKeys?.price_usd) {
        const [min, max] = (filterKeys?.price_usd as any)?.split?.(',');
        if (Number(min) !== DEFAULT_TC_MIN || Number(max) !== DEFAULT_TC_MAX) {
          count++;
        }
      }
      if (filterKeys?.tweet_count) {
        const [min, max] = (filterKeys?.tweet_count as any)?.split?.(',');
        if (
          Number(min) !== DEFAULT_TAGGED_MIN ||
          Number(max) !== DEFAULT_TAGGED_MAX
        ) {
          count++;
        }
      }
      if (filterKeys?.holding === 'true') {
        count++;
      }
    }

    return count;
  }, [filterKeys]);

  return (
    <>
      <Flex
        onClick={onOpen}
        className={s.wrapModel}
        position={'relative'}
        alignItems={'center'}
      >
        <IconButton
          className={clsx(s.iconButton, Number(numFilters) > 0 ? s.active : '')}
          icon={<IcFilter />}
          aria-label={''}
        ></IconButton>
        {/* <Text className={s.filterText}>Filter</Text> */}
        {Number(numFilters) > 0 && (
          <Flex className={s.showNumFilters}>
            {/* <Text>{numFilters}</Text> */}
          </Flex>
        )}
      </Flex>
      <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'md'}>
        <DrawerOverlay />
        <DrawerContent className={s.filterModal}>
          <DrawerHeader borderBottomWidth="1px">
            {/* <IcFilter /> */}
            <Box />
            <Center w={"40px"} h={"40px"} onClick={onClose}>
              <IoMdClose />
            </Center>
          </DrawerHeader>
          <DrawerBody>
            {/* <Flex flexDirection={'column'} gap={2} className={s.rowFilter}>
              <Text className={s.title}>Sort by</Text>
              <SimpleGrid gap={2} columns={2}>
                {SORTS.map(s => (
                  <ItemSort
                    sort={s}
                    key={`${s.key}-${s.value}`}
                    filterKeys={filterKeys}
                    onSetFilter={onSetFilter}
                  />
                ))}
              </SimpleGrid>
            </Flex>
            <Box py={6} /> */}
            <Flex flexDirection={'column'} gap={2} className={s.rowFilter}>
              <Text className={s.title}>Filters</Text>
              <Flex mt={8} gap={2} flexDirection={'column'}>
                {filters.map(t => (
                  <ItemFilterTypes
                    key={`${t.name}`}
                    type={t}
                    filterKeys={filterKeys}
                    onSetFilter={onSetFilter}
                  />
                ))}
              </Flex>
            </Flex>
          </DrawerBody>
          <DrawerFooter alignItems={'center'} gap={2}>
            <Button className={s.btnReset} onClick={() => onReset()}>
              Reset
            </Button>
            <Button className={s.btnApply} onClick={() => onHandleFilter()}>
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BtnFilter;
