import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Skeleton, Spinner } from '@chakra-ui/react';

import { CDN_URL_ICONS } from '@/configs';

import { AssetsContext } from '@/contexts/assets-context';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { getTipItems, getTipWallet, uploadTip } from '@/services/tip';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import { poolProfilePersistor } from '@/utils/persistor';
import BigNumber from 'bignumber.js';
import Image from 'next/image';
import toast from 'react-hot-toast';
import useOpenDepositModal from '@/hooks/useOpenDepositModal';
import CPlayerShare, { ETypes } from '@/contracts';

import s from './styles.module.scss';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { TipItemType } from '@/interfaces/api/tip';
import { kFormatter } from '@/utils/format';
import { TOKEN_ADDRESS } from '@/constants/token';
import {
  getPassTokenDetail,
  getPlayerPoolProfile,
} from '@/services/player-share';
import { IPassToken } from '@/services/interfaces/token';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { formatCurrency } from '@/utils/string';

export const TIP_MODAL_ID: string = 'TIP_MODAL_ID';

interface IProps {
  onClose: () => void;
  // messageId: string;
  tipToAddress: string;
  roomId: string;
}

const TipModal: React.FC<IProps> = ({
  onClose,
  // messageId,
  tipToAddress,
  roomId,
}): React.ReactElement => {
  const { addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const cplayerShare = new CPlayerShare();
  const contract = new CContract();
  const gameWalletProvider: IWalletContext = useContext(WalletContext);
  const gaEventTracker = useAnalyticsEventTracker();
  const [playerPoolProfile, setPlayerPoolProfile] =
    useState<IGetPlayerPoolProfile | null>(null);

  const {
    handleOpenTopup,
    fetchingOtp,
    loading: depositLoading,
  } = useOpenDepositModal();

  const { activeRoom } = useDM();
  const [loading, setLoading] = useState(false);
  const [receiverWallet, setReceiverWallet] = useState<string>('');
  const [listItemsGift, setListItemsGift] = useState<TipItemType[]>([]);
  const [itemGiftSelecting, setItemGiftSelecting] = useState<TipItemType>();
  const [isEnoughBalance, setIsEnoughBalance] = useState(false);
  const [passToken, setPassToken] = useState<IPassToken>();
  const [roomOwnerProfile, setRoomOwnerProfile] =
    useState<IGetPlayerPoolProfile | null>(null);

  const [tokenBalanceLoading, setTokenBalanceLoading] = useState(true);
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    // get user name, avatar
    if (tipToAddress) {
      (async () => {
        const storedItem = await poolProfilePersistor.getItem(
          tipToAddress as string
        );
        setPlayerPoolProfile(storedItem);
      })();
    }
  }, [tipToAddress]);

  useEffect(() => {
    // get user name, avatar
    if (activeRoom && activeRoom.owner) {
      (async () => {
        const storedItem = await getPlayerPoolProfile(
          activeRoom.owner as string,
          activeRoom.owner as string
        );
        setRoomOwnerProfile(storedItem);
      })();
    }
  }, [activeRoom]);

  useEffect(() => {
    // get wallet to send money
    (async () => {
      const result = await getTipWallet({ roomId, token: tipToAddress });
      if (result.wallet) {
        setReceiverWallet(result.wallet);
      }
      const listItems = await getTipItems();
      if (listItems?.items) {
        setListItemsGift(listItems?.items);
      }
    })();
  }, [roomId]);

  // const isEnoughBTC = useMemo(() => {
  //   if (!itemGiftSelecting?.price) return true;
  //   const amountGift = new BigNumber(itemGiftSelecting?.price);
  //   const currentBalance = new BigNumber(balanceL2.amountBTCFormatted);
  //   return currentBalance.isGreaterThanOrEqualTo(amountGift);
  // }, [balanceL2.amountBTCFormatted, itemGiftSelecting]);

  useEffect(() => {
    const getPassToken = async () => {
      try {
        if (activeRoom && activeRoom.owner) {
          const passToken = await getPassTokenDetail({
            address: activeRoom.owner,
          });
          setPassToken(passToken);
        }
      } catch (e) {
        console.log('___________getPassToken error', e);
      }
    };

    getPassToken();
  }, [activeRoom]);

  useEffect(() => {
    const checkBalance = async () => {
      try {
        if (
          passToken &&
          passToken.address &&
          addressL2 &&
          gameWalletProvider &&
          gameWalletProvider.gameWallet
        ) {
          const balanceToken = await cplayerShare.getTokenBalance(
            passToken?.address
          );

          setTokenBalance(Number(balanceToken));

          if (Number(balanceToken) > Number(itemGiftSelecting?.tokenPrice)) {
            setIsEnoughBalance(true);
          }
        }
      } catch (e) {
        console.log('___________checkBalance error', e);
      } finally {
        setTokenBalanceLoading(false);
      }
    };
    checkBalance();
  }, [passToken, itemGiftSelecting, gameWalletProvider]);

  const handleSubmit = async () => {
    toast.remove();

    if (!isEnoughBalance) {
      toast.error('Inefficient token balance', {
        duration: 5000,
      });
      return;
    }

    if (!addressL2 || !gameWalletProvider.gameWallet) {
      toast.error('Unauthorized');
      return;
    }

    if (
      loading ||
      !receiverWallet ||
      !tipToAddress ||
      !itemGiftSelecting?.tokenPrice ||
      !passToken ||
      !passToken.address
    ) {
      toast.error('Something wrong. Please try again.');
      return;
    }

    try {
      setLoading(true);
      await cplayerShare.estimateTCGasFee({
        type: ETypes.buy,
      });
      const amount = new BigNumber(itemGiftSelecting?.tokenPrice)
        .multipliedBy(1e18)
        .toFixed(0);

      const remain = Number(tokenBalance) - itemGiftSelecting?.tokenPrice;
      const passTokenMinimum =
        roomOwnerProfile?.passTokenMinHoldingRequirement || 100;
      if (remain < passTokenMinimum) {
        toast.error('Something wrong. Please try again.');
        return;
      }

      const tx = await contract
        .getERC20Contract(passToken?.address)
        .connect(gameWalletProvider.gameWallet)
        .transfer(receiverWallet, amount);
      await tx.wait();

      if (!tx?.hash) {
        toast.error('Transaction not found.');
        gaEventTracker(AlphaActions.SendTipFail, addressL2);
        return;
      }

      const resultTip = await uploadTip({
        roomId,
        tipItemId: itemGiftSelecting?.id,
        txHash: tx?.hash,
      });
      if (resultTip?.result) {
        toast.success(
          `Your gift is on its way to ${playerPoolProfile?.twitterName}. It's expected to take a few seconds.`,
          {
            duration: 8000,
          }
        );
        gaEventTracker(AlphaActions.SendTipSuccess, addressL2);
        onClose();
      } else {
        toast.error('Something wrong. Please try again.');
        gaEventTracker(AlphaActions.SendTipFail, addressL2);
      }
    } catch (err) {
      console.log('___________err', err);
      console.log(err);
      toast.error('Transaction not found.');
      gaEventTracker(AlphaActions.SendTipFail, addressL2);
    } finally {
      setLoading(false);
      gaEventTracker(AlphaActions.ClickSendTip, addressL2);
    }
  };

  return (
    <>
      <h6 className={s.title}>
        Send a gift to {` `}
        <span className={s.receiverName}>{playerPoolProfile?.twitterName}</span>
      </h6>
      <p className={s.description}>
        The gift amount will be used to buy random keys and sent to{' '}
        <span>{playerPoolProfile?.twitterName}</span>.
      </p>
      {listItemsGift?.length < 1 ? (
        <div className={s.loading}>
          <Spinner />
        </div>
      ) : (
        <div className={s.listItem}>
          {listItemsGift?.map((item: TipItemType) => (
            <div
              className={cn(
                s.item,
                itemGiftSelecting?.id === item?.id && s.active
              )}
              key={item?.id}
              onClick={() => {
                loading === false && setItemGiftSelecting(item);
              }}
            >
              <Image
                className={s.item_yield}
                src={item?.thumbnailUrl}
                alt={item?.id}
                width={60}
                height={60}
              />
              <div className={s.price}>
                <Avatar
                  className={s.avatar}
                  url={
                    roomOwnerProfile?.twitterAvatar ||
                    roomOwnerProfile?.avatar ||
                    ''
                  }
                  address={roomOwnerProfile?.address || ''}
                  name={
                    roomOwnerProfile?.twitterName ||
                    roomOwnerProfile?.twitterUsername ||
                    ''
                  }
                  width={16}
                />
                <span>{kFormatter(item?.tokenPrice)}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* {isEnoughBTC === false ? (
        <div className={s.notEnoughBTC}>
          Don’t have enough BTC?&nbsp;
          {fetchingOtp || depositLoading ? (
            <Spinner
              size={'sm'}
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
            />
          ) : (
            <span onClick={handleOpenTopup}>&nbsp;Deposit now.</span>
          )}
        </div>
      ) : (
        <button
          onClick={handleSubmit}
          className={s.sendBtn}
          type="button"
          disabled={loading || !itemGiftSelecting?.id}
        >
          {loading ? (
            <Spinner
              size={'sm'}
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
            />
          ) : (
            'Send'
          )}
        </button>
      )} */}

      <div className={s.tokenBalance}>
        Available balance:{' '}
        {tokenBalanceLoading ? (
          <>
            <Skeleton h="12px" w="100px" isLoaded={!tokenBalanceLoading}>
              <></>
            </Skeleton>
          </>
        ) : (
          <>{formatCurrency(tokenBalance, 0, 0)}</>
        )}{' '}
        <span className={s.ownerName}>{roomOwnerProfile?.twitterName}</span>{' '}
        tokens
      </div>
      <button
        onClick={handleSubmit}
        className={s.sendBtn}
        type="button"
        disabled={loading || !itemGiftSelecting?.id}
      >
        {loading ? (
          <Spinner
            size={'sm'}
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
        ) : (
          'Send'
        )}
      </button>
    </>
  );
};

export default React.memo(TipModal);
