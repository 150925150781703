import emojiUnicode from 'emoji-unicode';

export const parseEmojiToUnicode = (
  emoji: string,
  prefix = true,
  toUpperCase = true
): string => {
  if (prefix) {
    if (toUpperCase) {
      return `U+${emojiUnicode(emoji)}`.toUpperCase();
    }
    return `u+${emojiUnicode(emoji)}`;
  }
  return emojiUnicode(emoji);
};
