import { API_DGAMES } from '@/configs';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import {
  ILiveTournamentLeaderBoardResponse,
  ILiveTournamentResponse,
  IMatchWordsConfigResponse,
  IMatchWordsPlayerInfosResponse,
} from '@/interfaces/api/gamefi-match-word';
import createAxiosInstance from '@/services/http-client';
import localStorage from '@/utils/localstorage';

const gameFiClient = createAxiosInstance({
  baseURL: API_DGAMES,
});

// set authorization token
export const setBearerToken = (token: string | string[]) => {
  if (token && gameFiClient) {
    gameFiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};
setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

const getLeaderBoardById = async (
  id?: string
): Promise<ILiveTournamentLeaderBoardResponse> => {
  try {
    const res = (await gameFiClient.get(
      `/v1/games/match-words/leaderboard/${id}`
    )) as ILiveTournamentLeaderBoardResponse;
    return res;
  } catch (err: unknown) {
    return { players: [] };
  }
};


const getCurrentMatchWordGameByCircleAddress = async (
  alphaAddress: string
): Promise<ILiveTournamentResponse | undefined> => {
  try {
    const res = (await gameFiClient.get(
      `/v1/games/match-words/get-by-alpha/${alphaAddress}`
    )) as ILiveTournamentResponse;
    return res;
  } catch (err: unknown) {
    return undefined;
  }
};

const createMatchWordGame = async (
  alpha: string,
  configId: string,
  txHash: string
): Promise<void> => {
  await gameFiClient.post(`/v1/games/match-words/create`, {
    alpha,
    configId,
    txHash,
  });
};

const buyTicket = async (potId: string, txHash: string): Promise<void> => {
  return await gameFiClient.post(`/v1/games/match-words/buy-ticket/${potId}`, {
    txHash,
  });
};

const getMatchWordsConfig = async (
  alpha: string
): Promise<IMatchWordsConfigResponse | undefined> => {
  try {
    const res = (await gameFiClient.get(
      `/v1/games/match-words/config/${alpha}`
    )) as IMatchWordsConfigResponse;
    return res;
  } catch (err: unknown) {
    return undefined;
  }
};

const getPotPlayerInfos = async (
  potId: string
): Promise<IMatchWordsPlayerInfosResponse | undefined> => {
  try {
    const res = (await gameFiClient.get(
      `/v1/games/match-words/player-info/${potId}`
    )) as IMatchWordsPlayerInfosResponse;
    return res;
  } catch (err: unknown) {
    return undefined;
  }
};

export {
  buyTicket,
  createMatchWordGame,
  getCurrentMatchWordGameByCircleAddress,
  getMatchWordsConfig,
  getPotPlayerInfos,
  getLeaderBoardById,
};
