import px2rem from '@/utils/px2rem';
import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${px2rem(24)};
  position: relative;

  .back-btn {
    display: flex;
    position: absolute;
    top: -${px2rem(62)};
    left: 0;
    cursor: pointer;

    &_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${px2rem(6)};
      p {
        font-weight: 400;
        font-size: ${px2rem(16)};
        line-height: ${px2rem(26)};
        color: #e7e7e7;
        font-family: var(--chakra-fonts-sora);
      }
    }
  }

  .confirm-btn {
    width: 100%;
    height: ${px2rem(56)};
    align-self: center;

    font-weight: 500;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(28)};
    text-align: center;
    color: #fcfcfd;
    font-family: var(--chakra-fonts-sora);

    background: #3772ff;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transform: scale(1.01);
    }

    span {
      margin-left: ${px2rem(4)};
      font-size: ${px2rem(14)};
    }
  }

  .textOr {
    text-align: center;
    font-family: var(--chakra-fonts-sora);
    font-size: ${px2rem(16)};
    color: gray;
  }

  .viewQr {
    font-family: var(--chakra-fonts-sora);
    font-size: ${px2rem(16)};
    font-weight: 400;
    line-height: ${px2rem(26)};
    letter-spacing: 0em;
    text-align: center;
    color: #3772ff;
    text-decoration: underline;
    text-underline-offset: ${px2rem(2)};
    cursor: pointer;
    margin-bottom: ${px2rem(8)};
  }

  .claimer {
    line-height: ${px2rem(28)};
  }
`;

export const AmountContainer = styled.div`
  margin-top: ${px2rem(16)};

  .confirm-btn {
    width: 100%;
    height: ${px2rem(56)};
    align-self: center;

    font-weight: 500;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(28)};
    text-align: center;
    color: #fcfcfd;
    font-family: var(--chakra-fonts-sora);

    background: #3772ff;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    :disabled {
      opacity: 0.5;
      :hover {
        opacity: 0.5;
      }
    }

    :hover {
      opacity: 0.8;
    }

    :active {
      transform: scale(1.01);
    }

    span {
      margin-left: ${px2rem(4)};
      font-size: ${px2rem(14)};
    }
  }

  .estPrice {
    margin-top: ${px2rem(24)};
    font-weight: 500;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};
    text-align: center;
    color: #fcfcfd;
    font-family: var(--chakra-fonts-sora);
  }
`;

export const AmountGrid = styled.div`
  display: grid;
  gap: ${px2rem(32)};
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr 1fr;
  }

  .amount-item {
    padding: ${px2rem(32)};
    border-radius: ${px2rem(20)};
    border: 1px solid #ffffff33;
    cursor: pointer;
    p {
      font-family: var(--chakra-fonts-sora);
      font-size: ${px2rem(20)};
      font-weight: 400;
      line-height: ${px2rem(20)};
      text-align: center;
      color: #ffffff;
    }

    :hover {
      opacity: 0.8;
    }

    &_selected {
      border: 1px solid #3772ff;
      box-shadow: 0px 0px 10px 0px #3772ff;
    }
  }
`;

export const CheckHistory = styled.div`
  display: flex;
  justify-content: center;
  a {
    font-family: var(--chakra-fonts-sora);
    font-size: ${px2rem(18)};
    font-weight: 400;
    line-height: ${px2rem(28)};
    letter-spacing: 0em;
    text-align: center;
    color: #3772ff;
    text-decoration: underline;
    margin-top: ${px2rem(40)};
    text-underline-offset: ${px2rem(2)};
    cursor: pointer;
  }
`;
