/* eslint-disable */
import { API_TOPUP } from '@/configs';
import { IDailyFaucet, IDailyFaucetParams } from '@/interfaces/daily-faucet';
import {
  IFaucetTcParams,
  IGetTcParams,
  IGetTcResponse,
  ITopupHistoryResponse,
  IGetWBTCParams,
  NewDepositTokenParams,
  NewDepositTokenRespones,
} from '@/interfaces/topup';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { apiClient } from '.';
import CacheManager from './cache';
import { Token } from '@/state/tokens/types';

// const API_TOPUP_URL_LOCAL = "http://192.168.2.242:8005"
// const API_PATH = API_TOPUP_URL_LOCAL + '/api';

const API_PATH = API_TOPUP + '/api';

export const requestFaucetTc = (payload: IFaucetTcParams): Promise<string> => {
  const res = apiClient.post<IFaucetTcParams, string>(
    `${API_PATH}/tc-faucet`,
    payload
  );
  return res;
};

export const requestGetTc = (
  payload: IGetTcParams
): Promise<IGetTcResponse> => {
  const res = apiClient.post<IGetTcParams, IGetTcResponse>(
    `${API_PATH}/tc-buy`,
    payload
  );
  return res;
};

export const requestGetWBtc = (
  payload: IGetWBTCParams
): Promise<IGetTcResponse> => {
  const res = apiClient.post<IGetWBTCParams, IGetTcResponse>(
    `${API_PATH}/new-deposit`,
    payload
  );
  return res;
};

export const requestNewDepositAPI = (
  payload: NewDepositTokenParams
): Promise<NewDepositTokenRespones> => {
  const res = apiClient.post<NewDepositTokenParams, NewDepositTokenRespones>(
    `${API_PATH}/new-deposit-token`,
    payload
  );
  return res;
};

export const getHistoryTopup = async (
  address: string
): Promise<ITopupHistoryResponse[]> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/tc-buy-faucet-history?address=${address}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    throw Error('History not found');
  }
};

export const getHistoryDepositBTC = async (
  address: string
): Promise<ITopupHistoryResponse[]> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/wbtc-buy-history?address=${address}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    throw Error('History not found');
  }
};

export const getDailyFaucet = async (
  address: string
): Promise<IDailyFaucet> => {
  try {
    const res = await apiClient.post<{ tcAddress: string }, IDailyFaucet>(
      `${API_PATH}/faucet-daily-task-request`,
      {
        tcAddress: address,
      }
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    throw Error('Daily faucet not found');
  }
};

export const submitTaskDailyFaucet = async (
  params: IDailyFaucetParams
): Promise<any> => {
  try {
    const res = await apiClient.post<IDailyFaucetParams, any>(
      `${API_PATH}/faucet-daily-task-submit`,
      params
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    throw err;
  }
};

export const getListTokenDeposit = async (): Promise<Token[]> => {
  try {
    const res: Token[] = await apiClient.get(`${API_PATH}/list-token-deposit`);
    return res;
  } catch (error) {
    console.log('[GetListTokenDeposit] error: ', error);
    return [];
  }
};

export const getCacheListTokenDeposit = async (): Promise<Token[]> => {
  const result: Token[] = await CacheManager.cachePromise({
    key: CacheManager.KEYS.LIST_TOKENS_DEPOSIT,
    promiseFunc: () => getListTokenDeposit(),
    expiredTime: CacheManager.EXPIRED_TIME.LIST_TOKENS_DEPOSIT,
  });
  return result;
};
