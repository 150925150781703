import { Input } from '@/components/Input';
import { useAppDispatch } from '@/state/hooks';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as S from './styled';
import useUserInfoOperation from '@/hooks/userInfo/useUserInfoOperation';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@/utils/error';
import { WalletContext } from '@/contexts/wallet-context';
import { closeModal } from '@/state/modal';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import sleep from '@/utils/sleep';
import { IUserGameInfo } from '@/interfaces/api/gamefi';
import { submitMarkedGiftTask } from '@/services/bootster';
import { GiftEarn } from '@/interfaces/api/booster';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { isEmpty } from 'lodash';

export const SET_NAME_GAMEFI = 'SET_NAME_GAMEFI';

interface IFormValue {
  name: string;
}

const SetNameGameFiModal = React.memo(() => {
  const dispatch = useAppDispatch();
  const { onPostMessageSetNameSuccess } = useContentWindowMessage();
  const gaEventTracker = useAnalyticsEventTracker();

  const [loading, setIsLoading] = React.useState(false);

  const { onSetName } = useUserInfoOperation();
  const { addressL2, userGameInfo, handleGetUserGameInfo } =
    useContext(WalletContext);

  const handleIntervalGetUserInfo = async () => {
    let isBreak = false;
    while (true) {
      if (isBreak) {
        break;
      }
      await sleep(3);
      addressL2 &&
        handleGetUserGameInfo(addressL2, (data: IUserGameInfo) => {
          if (data.name) {
            isBreak = true;
          }
        });
    }
    onPostMessageSetNameSuccess();
  };

  const handleSubmit = async (payload: IFormValue) => {
    try {
      setIsLoading(true);
      await onSetName({
        name: payload.name,
      });
      toast.success('Create transaction successfully!');
      addressL2 && submitMarkedGiftTask(addressL2, GiftEarn.GiftEarnName);
      addressL2 && gaEventTracker(GameFiActions.SetNameSuccess, addressL2);
      if (userGameInfo && userGameInfo.name) {
        setTimeout(() => {
          addressL2 && handleGetUserGameInfo(addressL2);
          onPostMessageSetNameSuccess();
        }, 10000);
      } else {
        handleIntervalGetUserInfo();
      }
    } catch (error) {
      const { message } = getErrorMessage(error);
      toast.error(message);
    } finally {
      setIsLoading(false);
      dispatch(closeModal({ id: SET_NAME_GAMEFI }));
    }
  };

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!values.name) {
      errors.name = 'Name is required.';
    }
    if (!isEmpty(values.name)) {
      errors.name = 'Name is required.';
    }
    return errors;
  };

  return (
    <S.Content>
      <Formik
        key="create"
        initialValues={{
          name: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form className="form" onSubmit={handleSubmit}>
              <Input
                title=""
                id="name"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="input"
                placeholder="Enter name"
                errorMsg={errors.name && touched.name ? errors.name : undefined}
              />

              <S.ButtonCreate type="submit" isDisabled={loading}>
                {loading ? 'LOADING...' : 'CONFIRM'}
              </S.ButtonCreate>
            </form>
          );
        }}
      </Formik>
    </S.Content>
  );
});

SetNameGameFiModal.displayName = 'SetNameGameFiModal';

export default SetNameGameFiModal;
