import React, { useContext } from 'react';
import * as S from './styled';
import { WalletContext } from '@/contexts/wallet-context';
import { AssetsContext } from '@/contexts/assets-context';
import { Text } from '@chakra-ui/react';
import { Input } from '@/components/Input';
import { CDN_URL } from '@/configs';
import SvgInset from '@/components/SvgInset';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { setCopiedPrvKey } from '@/utils/alpha.storage';
import s from './styles.module.scss';
import cn from 'classnames';
import { formatCurrency } from '@/utils';

export const ACCOUNT_INFO_KEY_L2 = 'ACCOUNT_INFO_KEY_L2';

const AccountInfoL2Modal = React.memo(() => {
  const { keySetL2, addressL2, onLoginL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const [pass, setPass] = React.useState('');

  return (
    <S.Content style={{ gap: 12 }}>
      {keySetL2.prvKey ? (
        <>
          {/* <Text style={{ fontSize: 18, fontWeight: 600 }}>Import Account</Text> */}
          {/* <QRCode value={keySetL2.prvKey} className="mb-24" /> */}
          <S.BoxKey className={s.modalBoxPrivateKey}>
            <Text className={s.modalLabel}>This is your private key</Text>
            <div className="row-center">
              <Text
                size="16"
                color="txt-error"
                fontWeight="medium"
                className="key-text"
              >
                {keySetL2.prvKey}
              </Text>
              <SvgInset
                svgUrl={`${CDN_URL}/icons/ic-copy.svg`}
                size={24}
                className="ic-copy"
                onClick={() => {
                  if (!keySetL2?.prvKey) return;
                  copy(keySetL2.prvKey);
                  toast.success('Copied');
                  setCopiedPrvKey();
                }}
              />
            </div>
          </S.BoxKey>
          <S.BoxKey className={s.modalBoxPrivateKey}>
            <Text className={cn('balance-text', s.modalLabel)}>
              {formatCurrency(balanceL2.amountBTCFormatted, 0, 4)} BTC
            </Text>
            <Text className={cn('key-text', s.modalLabel)}>{addressL2}</Text>
          </S.BoxKey>
        </>
      ) : (
        <>
          <Input
            value={pass}
            type="password"
            placeholder="Password"
            onChange={event => setPass(event.target.value)}
          />
          <S.ButtonLogin
            onClick={() => {
              if (!pass) return;
              onLoginL2(pass);
            }}
          >
            Export
          </S.ButtonLogin>
        </>
      )}
    </S.Content>
  );
});

AccountInfoL2Modal.displayName = 'AccountInfoL2Modal';

export default AccountInfoL2Modal;
