/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Box } from '@chakra-ui/react';
import styles from './styles.module.scss';
import FAQs from '@/components/FAQ';

export const faqsData = [
  {
    q: 'What is (3,3)?',
    a: `
        <p>(3,3) is a key buying agreement where two users agree to purchase each other's keys simultaneously, powered by smart contracts for trustless and unstoppable transactions.</p>
        <p>When you accept a (3,3) key purchase, it gets locked on-chain for 30 days.</p>
        `,
  },
  {
    q: 'How does (3,3) work?',
    a: `
        <p>
          <ol>
              <li>You send an invitation to the person you want to buy a key from.</li>
              <li>They receive your invitation and, upon acceptance, buy your key.</li>
              <li>Simultaneously, you must have a sufficient balance to purchase their key as well.</li>
          </ol>
        </p>
        `,
  },
];

const FAQsModal = () => {
  return (
    <Box className={styles.contentWrapper}>
      <FAQs data={faqsData} />
    </Box>
  );
};

export default FAQsModal;
