import { NATIVE_ETH_ADDRESS } from '@/constants/token';
import { ETHEREUM_RPC_URL } from '@/hooks/useProviderEthereum';
import { GAS_LIMIT_ON_ETHEREUM } from '@/hooks/useSendEthereum';
import { estimateWithdrawToken } from '@/services/token-bridge';
import { RootState } from '@/state';
import { INetwork } from '@/state/networks/types';
import { ITokenMultiChain } from '@/state/tokens/types';
import {
  getContractByProvider,
  getProviderByRPCUrl,
} from '@/state/wallets/contract/hook';
import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { PREFIX } from './constants';
import { initialState } from './reducer';
import { SendFormState } from './types';

const setFormInstance: CaseReducer<SendFormState, PayloadAction<any>> = (
  state,
  action
) => {
  state.formInstance = action.payload;
};

const setSendFormSubmitting: CaseReducer<
  SendFormState,
  PayloadAction<boolean>
> = (state, action) => {
  state.isSubmitting = action.payload;
};

const setTokenSelected: CaseReducer<
  SendFormState,
  PayloadAction<ITokenMultiChain>
> = (state, action) => {
  state.tokenSelected = action.payload;
};

const setNetworkSelected: CaseReducer<
  SendFormState,
  PayloadAction<INetwork>
> = (state, action) => {
  state.networkSelected = action.payload;
};

const setTokenBalance: CaseReducer<SendFormState, PayloadAction<string>> = (
  state,
  action
) => {
  state.tokenBalance = action.payload;
};

const setMaxActionFlag: CaseReducer<SendFormState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isMaxAction = action.payload;
};

const resetState: CaseReducer<SendFormState, PayloadAction> = (
  state,
  action
) => {
  return initialState;
};

const fetchTokenBalance = createAsyncThunk(
  `${PREFIX}/fetchTokenBalance`,
  async (_, { getState, dispatch }) => {
    let balance = '0';
    try {
      const state = getState() as RootState;
      const sendFormState = state.sendForm;
      const walletAddress = state.wallets.addressL2;

      const { networkSelected, tokenSelected } = sendFormState;

      if (!networkSelected || !networkSelected.rpcEndpoint || !walletAddress)
        return;
      const provider = getProviderByRPCUrl(networkSelected.rpcEndpoint);
      const contractInstance = getContractByProvider(provider);

      balance = await contractInstance.getBalanceERC20WithParams({
        tokenAddress: tokenSelected.tokenId,
        decimals: tokenSelected.decimals,
        walletAddress: walletAddress,
      });
    } catch (error) {
      console.log('[loadBalanceHandler] -- ERROR -- ', error);
      balance = '0';
    } finally {
      return balance;
    }
  }
);

const loadETHBalanceOnEthereum = createAsyncThunk(
  `${PREFIX}/loadETHBalanceOnEthereum`,
  async (_, { getState, dispatch }) => {
    let ethBalance = '0';
    try {
      const state = getState() as RootState;
      const sendFormState = state.sendForm;
      const walletAddress = state.wallets.addressL2;

      const { networkSelected, tokenSelected } = sendFormState;

      if (!networkSelected || !networkSelected.rpcEndpoint || !walletAddress)
        return;
      const provider = getProviderByRPCUrl(ETHEREUM_RPC_URL);
      const contractInstance = getContractByProvider(provider);

      ethBalance = await contractInstance.getBalanceERC20WithParams({
        tokenAddress: NATIVE_ETH_ADDRESS,
        decimals: 18,
        walletAddress: walletAddress,
      });
    } catch (error) {
      console.log('[loadETHBalanceOnEthereum] -- ERROR -- ', error);
      ethBalance = '0';
    } finally {
      return ethBalance;
    }
  }
);

const fetchEstimateWidthrawFee = createAsyncThunk(
  `${PREFIX}/fetchEstimateWidthrawFee`,
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const sendFormState = state.sendForm;
      const { tokenSelected } = sendFormState;

      if (tokenSelected.tokenId) {
        //hard code tcAddress (estAddress just use for estimate)
        const tcAddress = '0x70b5AB86461f8A38db04C0Bc307162CC98ec122c';
        const estData = await estimateWithdrawToken({
          tcTokenID: tokenSelected.tokenId || '',
          tcAddress: tcAddress,
        });
        return estData;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log('[fetchEstimateWidthrawFee] ERROR: ', error);
      return undefined;
    }
  }
);

const fetchEstimateFeeEthereum = createAsyncThunk(
  `${PREFIX}/fetchEstimateFeeEthereum`,
  async (_, { getState, dispatch }) => {
    let estimateObj = {
      gasPriceRaw: '0' as any,
      gasPrice: '0',
      fee: '0',
    };

    try {
      const state = getState() as RootState;
      const sendFormState = state.sendForm;
      const walletAddress = state.wallets.addressL2;

      const { networkSelected } = sendFormState;

      if (!networkSelected || !networkSelected.rpcEndpoint || !walletAddress)
        return;
      const provider = getProviderByRPCUrl(networkSelected.rpcEndpoint);

      const gasPriceRaw = await provider.getGasPrice();
      const gasPrice = gasPriceRaw.toString();

      estimateObj.gasPriceRaw = gasPriceRaw;
      estimateObj.gasPrice = gasPrice;
      estimateObj.fee = new BigNumber(gasPrice?.toString() || '0')
        .dividedBy(1e18) // ETH decimals = 18
        .multipliedBy(GAS_LIMIT_ON_ETHEREUM)
        .toFixed(6);
    } catch (error) {
      console.log('[fetchEstimateFeeEthereum] -- ERROR -- ', error);
      estimateObj.fee = '0';
      estimateObj.gasPrice = '0';
      estimateObj.gasPriceRaw = '0';
    } finally {
      return estimateObj;
    }
  }
);

const actionCreators = {
  setFormInstance,
  resetState,
  setTokenSelected,
  setNetworkSelected,
  setTokenBalance,
  setSendFormSubmitting,
  setMaxActionFlag,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export {
  fetchEstimateFeeEthereum,
  fetchEstimateWidthrawFee,
  fetchTokenBalance,
  loadETHBalanceOnEthereum,
};
