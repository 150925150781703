import qs from 'query-string';
import { API_DGAMES, API_FOC } from '@/configs';
import {
  AddChatMessagePayload,
  ChatGroupResponse,
  ChatMessageResponse,
  ChatRoomResponse,
  GetChatMessageByTimePayload,
  GetRoomPayload,
  ChatGroupMember,
  LanguageSupportedEnum,
  LanguageBackEndEnum,
  ReportGroupMemberResponse,
  ICreateTribeRoomPayload,
  ICreateTribeRoomResponse,
  ICheckNftGatedParams,
  IJoinRoomPayload,
  ICheckNftGatedResponse,
  IRecentActiveMember,
  SetModePayload,
  ForwardChatMessagePayload,
} from '@/interfaces/api/chat';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { apiClient } from '@/services';
import {
  MAPPING_UI_2_DB,
  formatMessagesResponse,
  unifyChatGroup,
  unifyChatTribe,
} from '@/services/chat/helpers';
import queryString from 'query-string';

const API_PATH = 'v1/chat-group';

export const getChatGroups = async (
  payload: GetRoomPayload
): Promise<{
  rooms: ChatRoomResponse[];
  total: number;
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/rooms?${query}`);
    const dataFormatted = Object(camelCaseKeys(res));
    return {
      total: dataFormatted.total,
      rooms: (dataFormatted.rooms as ChatGroupResponse[]).map(item =>
        unifyChatGroup(item)
      ),
    };
  } catch (err: unknown) {
    return {
      total: 0,
      rooms: [],
    };
  }
};

export const addGroupMessage = async (
  payload: AddChatMessagePayload
): Promise<ChatMessageResponse> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/add-message`, {
      ...payload,
    });
    return Object(camelCaseKeys(res)).message;
  } catch (err: unknown) {
    console.error('addGroupMessage error', err);
    throw Error('Cannot send chat message');
  }
};

export const getGroupMessagesByTime = async (
  payload: GetChatMessageByTimePayload
): Promise<{
  total: number;
  messages: ChatMessageResponse[];
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/messages-from-time?${query}`
    );
    const result = Object(camelCaseKeys(res));
    return {
      total: result.total,
      messages: formatMessagesResponse(result.messages),
    };
  } catch (err: unknown) {
    return {
      total: 0,
      messages: [],
    };
  }
};

export const updateGroupLastSeen = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/update-seen/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('updateGroupLastSeen error', err);
    throw Error('Cannot update room last seen');
  }
};

export const setGroupSoundSetting = async (payload: {
  roomId: string;
  address: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/mute-room`, {
      ...payload,
    });
    return Object(camelCaseKeys(res));
  } catch (e) {
    return false;
  }
};

export const getGroupMessageRoomId = async (payload: {
  token: string;
}): Promise<
  | {
      roomId: string;
    }
  | undefined
> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/room-by-token/${payload.token}`
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    return;
  }
};

export const getRoomMembersByRoomId = async (
  roomId: string
): Promise<{ members: ChatGroupMember[] }> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/room-members?roomId=${roomId}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    return { members: [] };
  }
};

export const getChatGroupById = async (
  id: string
): Promise<ChatRoomResponse> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/room-id/${id}`);
  const dataFormatted = Object(camelCaseKeys(res));

  if (dataFormatted.room.isTribe) {
    return unifyChatTribe(dataFormatted.room);
  }
  return unifyChatGroup(dataFormatted.room);
};

export const updateCircleLanguage = async (
  roomId: string,
  payload: {
    language: LanguageSupportedEnum;
  }
): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/update-language/${roomId}`,
      {
        language: MAPPING_UI_2_DB[payload.language] || LanguageBackEndEnum.OFF,
      }
    );
    return Object(camelCaseKeys(res));
  } catch (e) {
    throw e;
  }
};

export const getChatGroupRoomIds = async (): Promise<string[]> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/room-ids`);
  const dataFormatted = Object(camelCaseKeys(res));
  return dataFormatted.roomIds;
};

export const blockGroupMember = async (
  roomId: string,
  payload: {
    tokens: string[];
  }
): Promise<any> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/block-chat-member`,
      {
        roomId,
        ...payload,
      }
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(' blockGroupMember :', error);
  }
};

export const unBlockGroupMember = async (
  roomId: string,
  payload: {
    tokens: string[];
  }
): Promise<any> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/unblock-chat-member`,
      {
        roomId,
        ...payload,
      }
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(' blockGroupMember :', error);
  }
};

export const toggleBlockFT = async (currentStatus: boolean): Promise<any> => {
  const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/update-circle`, {
    setting: {
      allowFtJoin: !currentStatus,
    },
  });
  return Object(camelCaseKeys(res));
};
export const getMessageDetail = async (
  messageId: string
): Promise<ChatMessageResponse | undefined> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/message/${messageId}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.log(err);
  }
};

export const reportGroupMember = async (payload: {
  roomId: string;
  userWasReportedToken: string;
  reason: string;
}): Promise<ReportGroupMemberResponse> => {
  const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/report`, {
    ...payload,
  });
  return Object(camelCaseKeys(res));
};

export const getGroupListReportByYou = async (
  roomId: string
): Promise<string[]> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/list-report/${roomId}`
    );
    return ((Object(camelCaseKeys(res)).tokenAddresses || []) as string[]).map(
      item => item.toLowerCase()
    );
  } catch (err: unknown) {
    return [];
  }
};

export const setChatGroupPin = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/pin-room/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('setChatGroupPin error', err);
    throw Error('setChatGroupPin');
  }
};

export const setChatGroupUnPin = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/unpin-room/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('setChatGroupUnPin error', err);
    throw Error('setChatGroupUnPin');
  }
};

export const requestToSpeakerFromAudience = async (
  channelName: string
): Promise<ReportGroupMemberResponse> => {
  const res = await apiClient.post(
    `${API_DGAMES}/${API_PATH}/request-speaker`,
    {
      channelName,
    }
  );
  return Object(camelCaseKeys(res));
};

export const approveFromSpeakerToAudience = async (
  channelName: string,
  toToken: string
): Promise<ReportGroupMemberResponse> => {
  const res = await apiClient.post(
    `${API_DGAMES}/${API_PATH}/approve-speaker`,
    {
      channelName,
      toToken,
    }
  );
  return Object(camelCaseKeys(res));
};

export const createTribeRoom = async (
  payload: ICreateTribeRoomPayload
): Promise<ChatRoomResponse> => {
  const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/tribe/create`, {
    ...payload,
  });
  return unifyChatTribe(Object(camelCaseKeys(res)).room);
};

export const checkNftGatedTribeRoom = async (
  params: ICheckNftGatedParams
): Promise<ICheckNftGatedResponse> => {
  const qs = queryString.stringify(params);
  const res = await apiClient.get(`${API_FOC}/tribe/nft_gate?${qs}`);
  return Object(camelCaseKeys(res));
};

export const joinTribePublicRoom = async (
  payload: IJoinRoomPayload
): Promise<ICreateTribeRoomResponse> => {
  const res = await apiClient.post(
    `${API_DGAMES}/${API_PATH}/tribe/join-public`,
    { ...payload }
  );
  return Object(camelCaseKeys(res));
};

export const joinTribeNftGatedRoom = async (
  payload: IJoinRoomPayload
): Promise<ICreateTribeRoomResponse> => {
  const res = await apiClient.post(
    `${API_DGAMES}/${API_PATH}/tribe/join-nft-gated`,
    { ...payload }
  );
  return Object(camelCaseKeys(res));
};

export const getRecentActiveMembersToTip = async (payload: {
  roomId: string;
}): Promise<{
  timestamp: string;
  members: IRecentActiveMember[];
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/recent-active-members?${query}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.log(err);
    return {
      timestamp: '',
      members: [],
    };
  }
};

export const pinCommunity = async (payload: {
  ownerToken: string;
  pinned: boolean;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/pin-community`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('pinCommunity error', err);
    throw Error('pinCommunity');
  }
};

export const getAvailableRoomsByTwitterId = async (payload: {
  twitterId: string;
}): Promise<{
  total: number;
  rooms: ChatRoomResponse[];
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/get-by-twitter-id?${query}`
    );
    const dataFormatted = Object(camelCaseKeys(res));
    return {
      total: dataFormatted.total,
      rooms: (dataFormatted.rooms as ChatGroupResponse[]).map(item => {
        if (item.isTribe) {
          return unifyChatTribe(item);
        } else {
          return unifyChatGroup(item);
        }
      }),
    };
  } catch (err: unknown) {
    console.log(err);
    return {
      total: 0,
      rooms: [],
    };
  }
};

export const setModeChat = async (payload: SetModePayload): Promise<any> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/set-channel-moderator`,
      {
        ...payload,
      }
    );

    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.error('setModeChat error', err);
    throw Error('Cannot set mode chat');
  }
};
// WARNING: this api are use for get room data without persmision and that only return the chat room without last message
export const getChatGroupByIdWithoutPermission = async (
  id: string
): Promise<ChatRoomResponse> => {
  const res = await apiClient.get(
    `${API_DGAMES}/${API_PATH}/room-id-public/${id}`
  );
  const dataFormatted = Object(camelCaseKeys(res));

  if (dataFormatted.room.isTribe) {
    return unifyChatTribe(dataFormatted.room);
  }
  return unifyChatGroup(dataFormatted.room);
};

export const deleteMessage = async (messageId: string): Promise<any> => {
  try {
    const res = await apiClient.delete(
      `${API_DGAMES}/${API_PATH}/delete-message?messageId=${messageId}`
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log('blockGroupMember :', error);
  }
};

export const sendForwardMessage = async (
  payload: ForwardChatMessagePayload
): Promise<ChatMessageResponse> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/forward-message`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).message;
  } catch (err: unknown) {
    console.error('sendForwardMessage error', err);
    throw Error('Cannot forward chat message');
  }
};
