import { WalletContext } from '@/contexts/wallet-context';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import useUserInfoOperation from '@/hooks/userInfo/useUserInfoOperation';
import { useAppDispatch } from '@/state/hooks';
import { closeModal } from '@/state/modal';
import { getErrorMessage } from '@/utils/error';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import DropFile from '../DropFile';
import * as S from './styled';
import s from './styles.module.scss';
import sleep from '@/utils/sleep';
import { IUserGameInfo } from '@/interfaces/api/gamefi';
import { submitMarkedGiftTask } from '@/services/bootster';
import { GiftEarn } from '@/interfaces/api/booster';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { isEmpty } from 'lodash';
import { Input } from '@/components/Input';
import { Formik } from 'formik';

export const SET_AVATAR_GAMEFI = 'SET_AVATAR_GAMEFI';

interface IFormValue {
  name: string;
}

const SetAvatarGameFiModal = React.memo(() => {
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();

  const { onPostMessageSetAvatarSuccess, onPostMessageSetNameSuccess } =
    useContentWindowMessage();
  const { onSetAvatar, onSetName } = useUserInfoOperation();
  const { addressL2, userGameInfo, handleGetUserGameInfo } =
    useContext(WalletContext);

  const [rawFile, setRawFile] = useState<File | undefined>(undefined);
  const [loading, setIsLoading] = React.useState(false);

  const handleChangeFile = (file: File | undefined): void => {
    setRawFile(file);
  };

  const handleIntervalGetUserInfo = async () => {
    // let isBreak = false;
    // while (true) {
    //   if (isBreak) {
    //     break;
    //   }
    //   await sleep(3);
    //   addressL2 &&
    //     handleGetUserGameInfo(addressL2, (data: IUserGameInfo) => {
    //       if (data.pfpUrl) {
    //         isBreak = true;
    //       }
    //     });
    // }
    addressL2 &&
      handleGetUserGameInfo(addressL2, (data: IUserGameInfo) => {
        // if (data.pfpUrl) {
        //   isBreak = true;
        // }
      });
    onPostMessageSetAvatarSuccess();
  };

  const handleSubmitAvatar = async () => {
    if (rawFile) {
      try {
        const image = await rawFile.arrayBuffer();
        if (image) {
          await onSetAvatar({ b: Buffer.from(image) });
          addressL2 && submitMarkedGiftTask(addressL2, GiftEarn.GiftEarnName);
          addressL2 &&
            gaEventTracker(GameFiActions.SetAvatarSuccess, addressL2);
          if (userGameInfo && userGameInfo.pfpUrl) {
            setTimeout(() => {
              addressL2 && handleGetUserGameInfo(addressL2);
              onPostMessageSetAvatarSuccess();
            }, 10000);
          } else {
            handleIntervalGetUserInfo();
          }
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const handleIntervalGetUserInfoName = async () => {
    let isBreak = false;
    while (true) {
      if (isBreak) {
        break;
      }
      await sleep(3);
      addressL2 &&
        handleGetUserGameInfo(addressL2, (data: IUserGameInfo) => {
          if (data.name) {
            isBreak = true;
          }
        });
    }
    onPostMessageSetNameSuccess();
  };

  const handleSubmitUserName = async (name: string) => {
    try {
      await onSetName({
        name,
      });
      addressL2 && submitMarkedGiftTask(addressL2, GiftEarn.GiftEarnName);
      addressL2 && gaEventTracker(GameFiActions.SetNameSuccess, addressL2);
      if (userGameInfo && userGameInfo.name) {
        setTimeout(() => {
          addressL2 && handleGetUserGameInfo(addressL2);
          onPostMessageSetNameSuccess();
        }, 8000);
      } else {
        handleIntervalGetUserInfoName();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (payload: IFormValue) => {
    try {
      setIsLoading(true);
      const name = payload.name.replace(/^\s+|\s+$/gm, '');
      if ((name && !isEmpty(name)) || rawFile) {
        await handleSubmitUserName(payload.name);
        await handleSubmitAvatar();
        toast.success('Create transaction successfully!');
        dispatch(closeModal({ id: SET_AVATAR_GAMEFI }));
      }
    } catch (error) {
      const { message } = getErrorMessage(error);
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = (): Record<string, string> => {
    const errors: Record<string, string> = {};
    return errors;
  };

  return (
    <S.Content>
      <Formik
        key="create"
        initialValues={{
          name: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form className="form" onSubmit={handleSubmit}>
              <Input
                title=""
                id="name"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="input"
                placeholder="Enter name"
                errorMsg={errors.name && touched.name ? errors.name : undefined}
              />

              <DropFile
                labelText="Upload your image file here. (Maximum file size is 35Kb)"
                className={s.dropZoneContainer}
                acceptedFileType={['jpg', 'jpeg', 'png']}
                maxSize={0.035}
                onChange={handleChangeFile}
                fileOrFiles={rawFile ? [rawFile] : undefined}
              />

              <S.ButtonCreate type="submit" isDisabled={loading}>
                {loading ? 'Loading...' : 'Confirm'}
              </S.ButtonCreate>
            </form>
          );
        }}
      </Formik>
    </S.Content>
  );
});

SetAvatarGameFiModal.displayName = 'SetAvatarGameFiModal';

export default SetAvatarGameFiModal;
