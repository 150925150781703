import Web3 from 'web3';

export const openMetamaskDeeplink = (url?: string): void => {
  const appURL =
    url ||
    window.location.hostname.replace(/^https?:\/\//, '').replace('/', '');
  const deeplink = `https://metamask.app.link/dapp/${appURL}`;
  window.location.href = deeplink;
};

export const sendMetamaskDeeplink = (
  address: string,
  amount: string,
  chainId: number
): void => {
  const value = Web3.utils.toHex(Web3.utils.toWei(amount, 'ether'));
  const deeplink = `https://metamask.app.link/send/${address}@${chainId}?value=${value}`;
  window.location.href = deeplink;
};
