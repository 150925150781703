import { ChatMessageResponse, MessageTypeEnum } from '@/interfaces/api/chat';
import Tip from '@/modules/AlphaPWA/DirectMessage/VoiceRoom/TipMessages/Tip';
import { DMMessage } from '@/modules/AlphaPWA/DirectMessage/types';
import { useSocketEvent } from '@/providers/SocketProvider/hooks';
import { formatMessageResponse } from '@/services/chat/helpers';
import { useState, useEffect, useCallback } from 'react';

export default function TipMessages() {
  const newGroupMessage = useSocketEvent<ChatMessageResponse>(
    'GROUP_MESSAGE_SENT',
    JSON.parse
  );
  const [tipMessages, setTipMessages] = useState<DMMessage[]>([]);

  useEffect(() => {
    if (newGroupMessage && newGroupMessage.type === MessageTypeEnum.TIP_EVENT) {
      setTipMessages(prev => [...prev, formatMessageResponse(newGroupMessage)]);
    }
  }, [newGroupMessage]);

  const removeTipOutOfQueue = useCallback((messageId: string) => {
    setTipMessages(prev => prev.filter(item => item.id !== messageId));
  }, []);

  return (
    <>
      {tipMessages.map(tip => (
        <Tip key={tip.id} tip={tip} removeTipOutOfQueue={removeTipOutOfQueue} />
      ))}
    </>
  );
}
