import CPlayerShare from '@/contracts';
import CPassTokenAPI from '@/services/classes/passToken';
import { useRef, useState } from 'react';
import Web3 from 'web3';

const useTokenBalance = () => {
  const [tokenBalance, setTokenBalance] = useState('0');
  const [isFetchingToken, setIsFetchingToken] = useState(false);

  const cplayerShare = new CPlayerShare();

  const getTokenBalance = async (tokenAddress: string) => {
    setIsFetchingToken(true);
    // const holdingTokens = await passTokenAPI.getPassTokenHolding();
    // const thisTokenInfo = holdingTokens?.rows.find(
    //   token =>
    //     token.token.contract_address.toLowerCase() ===
    //     tokenAddress.toLowerCase()
    // );

    const balanceW = await cplayerShare.freeStakingBalanceOf(tokenAddress);

    const balance = Web3.utils.fromWei(balanceW);

    setTokenBalance(balance);

    setIsFetchingToken(false);

    return balance;
  };

  return { tokenBalance, getTokenBalance, isFetchingToken };
};

export default useTokenBalance;
