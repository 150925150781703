import { NATIVE_ETH_ADDRESS, NATIVE_TOKEN_LIST } from '@/constants/token';
import { ITokenMultiChain } from './types';
import { TC_ADDRESS } from '@/configs';

export const getListTokenDefault = (listToken: ITokenMultiChain[]) => {
  return listToken.filter(token => !!token.isDefault) || [];
};

export const getChainIDByNetwork = (networkName: string) => {};

export const isNativeToken = (tokenAddress: string) => {
  return NATIVE_TOKEN_LIST.includes(tokenAddress);
};
