import { IMG_EXTENSIONS, VIDEO_EXTENSIONS } from "@/constants/media";

export function isImageOrVideo(filename: string) {
  // const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'avif'];
  // const videoExtensions = ['mp4', 'mkv', 'mov', 'webm'];

  // Get the file extension by splitting the filename at the last dot

  // check undefined
  const fileName = filename?.split('.');
  const _fileName = fileName?.pop();

  const fileExtension = _fileName?.toLowerCase();

  if (!fileExtension) return 'unknown';

  // Check if the file extension is in the list of image or video extensions
  if (IMG_EXTENSIONS.includes(fileExtension)) {
    return 'image';
  } else if (VIDEO_EXTENSIONS.includes(fileExtension)) {
    return 'video';
  } else {
    return 'unknown';
  }
}