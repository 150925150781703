import styles from './styles.module.scss';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import {
  claimReferralEarning,
  getAddressReferents,
  getAddressReferralEarning,
  getReferralCode,
} from '@/services/referral';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import ShareReferralTwitter from '@/modules/AlphaPWA/Profile/ShareReferralTwitter';
import toast from 'react-hot-toast';
import cs from 'classnames';
import BackIcon from '@/modules/AlphaPWA/OverlayPages/ReferralEarning/icons/BackIcon';
import ShareInfo from '@/modules/AlphaPWA/Profile/ShareInfo';
import { IReferralEarning } from '@/interfaces/api/referral';
import { displayBTCValue } from '@/utils/format';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { MINIMUM_BTC_AMOUNT_CAN_CLAIM } from '@/constants/amount';
import { useRouter } from 'next/router';
import InviteIcon from '@/modules/AlphaPWA/OverlayPages/ReferralEarning/icons/InviteIcon';
import TimeIcon from '@/modules/AlphaPWA/OverlayPages/ReferralEarning/icons/TimeIcon';
import ReferentsModal from '@/modules/AlphaPWA/Profile/ReferentsModal';
import ReferralHistoryModal from '@/modules/AlphaPWA/Profile/ReferralHistoryModal';

interface IReferralEarningProps {
  isShowBackBtn?: boolean;
}

export default function ReferralEarning({
  isShowBackBtn = true,
}: IReferralEarningProps) {
  const router = useRouter();

  const { addressL2 } = useContext(WalletContext);
  const [referralEarnings, setReferralEarnings] = useState<IReferralEarning>();
  const [referents, setReferents] = useState<{ address: string }[]>([]);
  const [isClaiming, setIsClaiming] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker();

  const {
    isOpen: isOpenReferents,
    onOpen: onOpenReferents,
    onClose: onCloseReferents,
  } = useDisclosure({ id: 'referents_earning' });

  const {
    isOpen: isOpenReferalHistory,
    onOpen: onOpenReferralHistory,
    onClose: onCloseReferralHistory,
  } = useDisclosure({ id: 'referral_history' });

  const handleOnClaim = () => {
    if (addressL2) {
      gaEventTracker(GameFiActions.ReferralClaimClick, addressL2);
      setIsClaiming(true);
      claimReferralEarning()
        .then(() => {
          toast.success('Claim your reward successfully.');
        })
        .catch(() => {
          toast.error('Claim your reward failed.');
        })
        .finally(() => {
          getAddressReferralEarning()
            .then(res => {
              setReferralEarnings(res);
            })
            .finally(() => {
              setIsClaiming(false);
            });
        });
    }
  };

  useEffect(() => {
    getAddressReferents().then(_referents => {
      setReferents(_referents);
    });

    let interval = setInterval(() => {
      getAddressReferents().then(_referents => {
        setReferents(_referents);
      });
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const TopContent = useMemo((): React.ReactElement => {
    return (
      <div>
        <div className={styles.heading}>
          {/* <span className={styles.heading__text}>
            <SvgInset svgUrl={`${CDN_URL_ICONS}/fire-ic.svg`} />
            Total Referral Earnings
          </span> */}

          {isShowBackBtn && (
            <>
              <span
                className={styles.heading__close}
                onClick={() => {
                  router.back();
                }}
              >
                <BackIcon />
              </span>
            </>
          )}
          {/* 
          <span className={styles.heading__balance}>
            {' '}
            {displayBTCValue(referralEarnings?.totalBtcEarning)}
          </span> */}
        </div>

        {/* <div className={styles.header}>
          <div className={styles.header__bar}></div>
        </div> */}

        <ShareInfo
          className={styles.shareInfo}
          referralEarnings={referralEarnings}
          referents={referents}
          setReferralEarnings={setReferralEarnings}
        ></ShareInfo>
      </div>
    );
  }, [referents, referralEarnings]);

  return (
    <>
      <div className={styles.referralEarning}>
        <div className={styles.container}>
          <div className={styles.body}>
            <div className={styles.body__inner}>
              <div className={styles.twitterList}>
                <ShareReferralTwitter>{TopContent}</ShareReferralTwitter>
              </div>

              <div className={cs(styles.footer)}>
                <div
                  className={cs(styles.footer__col)}
                  onClick={e => {
                    // if has invited friend should show
                    if (referents.length) {
                      onOpenReferents();
                    }
                  }}
                >
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__label
                    )}
                  >
                    Total earning
                  </div>
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__value
                    )}
                  >
                    {displayBTCValue(referralEarnings?.totalBtcEarning)}
                  </div>
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__value
                    )}
                  >
                    <span className={styles.footerInvited}>
                      <InviteIcon /> Invited ({referents.length || 0})
                    </span>
                  </div>
                </div>

                <div
                  className={cs(styles.footer__col)}
                  onClick={e => {
                    onOpenReferralHistory();
                  }}
                >
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__label
                    )}
                  >
                    Claimed
                  </div>
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__value
                    )}
                  >
                    {displayBTCValue(referralEarnings?.totalBtcClaim)}
                  </div>
                  <div
                    className={cs(
                      styles.footer__col__item,
                      styles.footer__col__value
                    )}
                  >
                    <span className={styles.footerClaimed}>
                      <TimeIcon /> Claim history
                    </span>
                  </div>
                </div>

                <div
                  className={cs(styles.footer__col)}
                  onClick={e => {
                    // if has invited friend should show
                    if (
                      !isClaiming &&
                      Number(referralEarnings?.totalBtcRemain) >
                        MINIMUM_BTC_AMOUNT_CAN_CLAIM
                    ) {
                      handleOnClaim();
                    }
                  }}
                >
                  {/* Claim button */}
                  <div className={styles.footerClaimAction}>
                    <button
                      className={cs(
                        styles.claimSection__btn,
                        !(
                          Number(referralEarnings?.totalBtcRemain) >
                          MINIMUM_BTC_AMOUNT_CAN_CLAIM
                        )
                          ? styles.claimSection__btn__inactive
                          : ''
                      )}
                    >
                      {isClaiming ? (
                        <span className={styles.claimSection__btn__label}>
                          <Spinner />
                        </span>
                      ) : (
                        <span className={styles.claimSection__btn__label}>
                          Claim
                        </span>
                      )}

                      <span className={styles.claimSection__btn__value}>
                        {displayBTCValue(referralEarnings?.totalBtcRemain)}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReferentsModal
        isOpen={isOpenReferents}
        onClose={onCloseReferents}
        referents={referents || []}
        referentsInfo={referralEarnings?.referents || []}
      />
      {isOpenReferalHistory && (
        <ReferralHistoryModal
          isOpen={isOpenReferalHistory}
          onClose={onCloseReferralHistory}
        />
      )}
    </>
  );
}
