import React, { useContext, useEffect, useMemo, useState } from 'react';
import s from './RewardsModal.module.scss';
import { TournamentContext } from '@/contexts/tournament-context';
import Web3 from 'web3';
import { IJackpotLeaderboardItem } from '@/interfaces/jackpot';
import { TournamentInfoType } from '../../DirectMessage/types';
import {
  List,
  Text,
  Heading,
  Flex,
  Box,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import useGetTournamentRewards from '../hooks/useGetTournamentRewards';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import { goToRoute } from '@/utils/go-route';
import { ROUTE_PATH } from '@/constants/route-path';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { closeModal } from '@/state/modal';
import TokenDisplay from '../components/TokenDisplay';
import _ from 'lodash';
import { formatCurrency } from '@/utils';

export const REWARDS_MODAL_ID = 'REWARDS_MODAL_ID';

const RewardsModal: React.FunctionComponent<{
  tournamentId: string;
  tournamentName: string;
  data: IJackpotLeaderboardItem[];
  keyName: string;
  keyAvatar: string;
}> = ({ data, tournamentName, tournamentId, keyName, keyAvatar }) => {
  const { rewards, fetchRewards } = useGetTournamentRewards();
  let isPlaying = keyName == '' && keyAvatar == '';
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRewards(tournamentId, !isPlaying);
  }, []);

  // useEffect(() => {
  //   if (rewards) {
  //     setRewardData(rewards);
  //   }
  // }, [rewards]);

  const _buildRewards = () => {
    return rewards.map(e => {
      return (
        <>
          <Flex
            className={s.itemContainer}
            onClick={() => _handlePressUser(e.player?.address)}
          >
            <Avatar name={e.player.twitterName} url={e.player.twitterAvatar} />
            <VStack ml="8px" align="start" justify="space-between">
              <Text className={s.titleItem}>{e.player.twitterName ?? ''}</Text>
              <Text className={s.contentItem}>{e.score}</Text>
            </VStack>
            <Spacer />
            {e.isKeyReward ? _buildKeyReward(e) : _buildTokenReward(e)}
          </Flex>
        </>
      );
    });
  };

  const _buildKeyReward = (e: IJackpotLeaderboardItem) => {
    return (
      <VStack align="end" justify="space-between">
        <Text className={s.titleItem}>
          {Math.round((e.rewardKeyAmount ?? 0) * 1000) / 1000}{' '}
          {e.rewardKeyAmount == 1 ? 'key' : 'keys'}
        </Text>
        <Text className={s.contentItem}>{keyName}</Text>
      </VStack>
    );
  };

  const getBoostPercent = (e: IJackpotLeaderboardItem) => {
    return ((e.booster ?? 1) * 100) % 100;
  };

  const _buildTokenReward = (e: IJackpotLeaderboardItem) => {
    return (
      <VStack align="end" justify="space-between">
        <TokenDisplay
          amount={e.rewardTokenAmount ?? 0}
          tribeToken={e.potToken}
          prefix=""
          customClass={s.rewardToken}
          iconSize={20}
        />
        <Text className={s.boostInfo}>
          Boost: {formatCurrency(getBoostPercent(e), 0, 0)}%
        </Text>
      </VStack>
    );
  };

  const _handlePressUser = (userAddress: String) => {
    goToRoute(router, `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userAddress}`);
    dispatch(
      closeModal({
        id: REWARDS_MODAL_ID,
      })
    );
  };

  const _buildLeaderboard = () => {
    return rewards.map((e, idx) => {
      return (
        <>
          <Flex
            className={s.itemContainer}
            onClick={() => _handlePressUser(e.player?.address)}
          >
            <Avatar name={e.player.twitterName} url={e.player.twitterAvatar} />
            <VStack ml="8px" align="start" justify="space-between">
              <Text className={s.titleItem}>{e.player.twitterName ?? ''}</Text>
              <Text className={s.contentItem}>@{e.player.twitterUsername}</Text>
            </VStack>
            <Spacer />
            <VStack align="end" justify="space-between">
              <Text className={s.titleItem}>{e.score}</Text>
              <Text className={s.contentItem}>#{idx + 1}</Text>
            </VStack>
          </Flex>
        </>
      );
    });
  };

  return (
    <div className={s.wrapper}>
      <p className={s.titleHeader}>{`${tournamentName} Tournament`}</p>
      <Flex marginBottom="20px">
        <Text className={s.label}>
          {!isPlaying ? 'Winners / Score' : 'Players'}
        </Text>
        <Spacer />
        <Text className={s.label}>
          {!isPlaying ? 'Earnings' : 'Score / Rank'}
        </Text>
      </Flex>
      <List>{isPlaying ? _buildLeaderboard() : _buildRewards()}</List>
    </div>
  );
};

export default React.memo(RewardsModal);
