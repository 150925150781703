import { sliderAnatomy as sliderParts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(sliderParts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  thumb: defineStyle({
    bg: 'var(--alpha-slider-bg)',
    borderWidth: '0px',
    borderColor: 'var(--alpha-slider-thumb-border)',
    width: '16px',
    height: '16px',
  }),
  track: {
    height: '4px',
    bg: 'var(--alpha-slider-track-bg)',
    borderRadius: '100px',
  },
  filledTrack: {
    bg: 'var(--alpha-slider-bg)',
  },
});

const Slider = defineMultiStyleConfig({
  baseStyle: baseStyle,
});

export default Slider;
