import { createSlice } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';

import { Wallet, WalletState } from './types';
import { PREFIX } from './constants';
import { actionCreators } from './actions';

/* Used to track wallets that have been connected by the user in current session, and remove them when deliberately disconnected. 
  Used to compute is_reconnect event property for analytics */

const initialState: WalletState = {
  connectedWallets: [],
  listTokenFollowing: [],
  addressL2: undefined,
  isFetchingBalance: false,
  tokenFollowBalanceMap: {},
  isFetchingBalanceFirstTime: true,

  //Recieve
  fetchBalanceError: undefined,
  totalBTCBalanceFactory: undefined,
};

const walletsSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    addConnectedWallet(state, { payload }) {
      const existsAlready = state.connectedWallets.find(wallet =>
        shallowEqual(payload, wallet)
      );
      if (!existsAlready) {
        state.connectedWallets = state.connectedWallets.concat(payload);
      }
    },
    removeConnectedWallet(state, { payload }) {
      state.connectedWallets = state.connectedWallets.filter(
        wallet => !shallowEqual(wallet, payload)
      );
    },
    setTcAddressL2: actionCreators.setTcAddressL2,
    setupListTokenFollowing: actionCreators.setupListTokenFollowing,
    fetchingWalletBalance: actionCreators.fetchingWalletBalance,
    setTokenFollowBalanceMap: actionCreators.setTokenFollowBalanceMap,
    fetchingWalletBalanceFirstTime:
      actionCreators.fetchingWalletBalanceFirstTime,
    setListTokenFollow: actionCreators.setListTokenFollow,

    recieveTokenSelected: actionCreators.recieveTokenSelected,
    sendTokenSelected: actionCreators.sendTokenSelected,
    fetchBalanceError: actionCreators.fetchBalanceError,
    setTotalBTCBalanceFactory: actionCreators.setTotalBTCBalanceFactory,
  },
});

export const {
  addConnectedWallet,
  setTcAddressL2,
  setupListTokenFollowing,
  fetchingWalletBalance,
  setTokenFollowBalanceMap,
  fetchingWalletBalanceFirstTime,
  setListTokenFollow,
  recieveTokenSelected,
  sendTokenSelected,
  setTotalBTCBalanceFactory,
} = walletsSlice.actions;

export const WalletActions = walletsSlice.actions;

export default walletsSlice.reducer;
