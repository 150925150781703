import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import { IGameItem, IJackPotInfo } from '@/interfaces/api/gamefi';
import { ILiveTournamentInfo } from '@/interfaces/api/gamefi-match-word';
import {
  UnityEventType,
  UnityIFrameStatus,
} from '@/modules/AlphaPWA/Games/constants';
import errorLogger from '@/services/errorLogger';
import useAnalyticsEventTracker, { GameActions } from '@/utils/ga';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { LiveTournamentProvider } from './live-tournament-context';
import { WalletContext } from './wallet-context';

export interface ILiveGameFiContext {
  unityIframeStatus: UnityIFrameStatus;
  isShowBackButton: boolean;
  updateIsPlayingGame: (
    status: UnityIFrameStatus,
    data?: {
      mode?: string;
      game?: IGameItem;
      circleTournamentInfo?: ILiveTournamentInfo;
      circleAddress?: string;
      extra?: string;
    }
  ) => void;
  releaseIframe: () => void;
  openGame: () => void;
  // updateExtraData: (extra?: string) => void;
}

const initialValue: ILiveGameFiContext = {
  unityIframeStatus: UnityIFrameStatus.RELEASED,
  isShowBackButton: false,
  updateIsPlayingGame: () => {},
  releaseIframe: () => {},
  openGame: () => {},
  // updateExtraData: () => {},
};

export const LiveGameFiContext =
  React.createContext<ILiveGameFiContext>(initialValue);

export const LiveGameFiProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [isPlayingGame, setIsPlayingGame] = useState(false);
  const [unityIframeStatus, setUnityIframeStatus] = useState<UnityIFrameStatus>(
    UnityIFrameStatus.RELEASED
  );
  const {
    onPostMessageOpenGame,
    onPostMessageCloseGame,
    onPostMessageUpdateGameUrl,
  } = useContentWindowMessage();

  // const [mode, setMode] = useState('');
  // const [game, setGame] = useState<IGameItem>();
  // const [extra, setExtra] = useState<string>('');

  const circleTournamentInfoRef = useRef<ILiveTournamentInfo>();
  const alphaAddressRef = useRef<string>('');
  const game = useRef<IGameItem>();
  const extra = useRef<string>('');

  const [hydration, setHydration] = useState(false);
  const [isShowBackButton, setShowBackButton] = useState<boolean>(false);
  const gaEventTracker = useAnalyticsEventTracker();
  const { addressL2 } = useContext(WalletContext);
  const [circleTournamentInfo, setCircleTournamentInfo] = useState<
    ILiveTournamentInfo | undefined
  >();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHydration(true);
    }
  }, []);

  useEffect(() => {
    if (hydration && window) {
      window.onmessage = async event => {
        try {
          const eventData = JSON.parse(event.data);
          switch (eventData.type) {
            // case 'close_game':
            //   setTimeout(() => {
            //     router.push(ROUTE_PATH.ALPHA_MOBILE_GAMES);
            //   }, 2000);
            //   break;
            case UnityEventType.U2JS_SET_BACK_BUTTON:
              // console.log('U2JS_SET_BACK_BUTTON', game?.current?.name);
              setShowBackButton(eventData.isShow);
              break;
            case UnityEventType.U2JS_START_LOAD:
              // console.log('U2JS_START_LOAD', game?.current?.name);
              gaEventTracker(
                GameActions.UnityStartLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_UNITY_BOOTSTRAP:
              // console.log('U2JS_UNITY_BOOTSTRAP', game?.current?.name);
              gaEventTracker(
                GameActions.UnityBootstrapLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_GAME_LOADED:
              // console.log('U2JS_GAME_LOADED', game?.current?.name);
              // console.log('U2JS_GAME_LOADED_______', game?.current?.name);

              updateIsPlayingGame(UnityIFrameStatus.PLAYING);
              gaEventTracker(
                GameActions.UnityGameLoad,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            case UnityEventType.U2JS_UNITY_ERROR:
              const errorMessage = eventData.metadata;
              // console.log('U2JS_UNITY_ERROR', errorMessage);
              updateIsPlayingGame(UnityIFrameStatus.PENDING, {});
              toast.error(errorMessage);
              gaEventTracker(
                GameActions.UnityLoadError,
                JSON.stringify(circleTournamentInfo)
              );

              errorLogger.report({
                action: errorLogger.ERROR_LOGGER_TYPE.UNITY_LOAD,
                address: addressL2,
                error: eventData.meta,
                info: JSON.stringify(circleTournamentInfo),
              });
              onPostMessageCloseGame();
              break;
            case UnityEventType.U2JS_UNITY_LOADED:
              // console.log('U2JS_UNITY_LOADED');
              openGame();
              gaEventTracker(
                GameActions.UnityLoaded,
                JSON.stringify(circleTournamentInfo)
              );
              break;
            default:
              break;
          }
        } catch (error) {}
      };
    }
  }, [hydration, unityIframeStatus]);

  // const updateExtraData = (extra?: string) => {
  //   setExtra(extra ?? '');
  // };

  const updateIsPlayingGame = (
    status: UnityIFrameStatus,
    data?:
      | {
          mode?: string;
          game?: IGameItem;
          extra?: string;
          circleTournamentInfo?: ILiveTournamentInfo;
          circleAddress?: string;
        }
      | undefined
  ) => {
    console.log('updateIsPlayingGame', status, data?.game, data?.extra);

    setUnityIframeStatus(status);
    if (data?.game) {
      game.current = data.game;
    }
    if (data?.circleTournamentInfo) {
      setCircleTournamentInfo(data?.circleTournamentInfo);
    }
    if (data?.circleAddress) {
      alphaAddressRef.current = data?.circleAddress;
    }
    if (data?.extra != undefined) {
      extra.current = data?.extra;
    }

    setShowBackButton(false);

    if (data?.circleTournamentInfo) {
      circleTournamentInfoRef.current = data?.circleTournamentInfo;
    }
  };

  const openGame = () => {
    if (!game.current) {
      return;
    }

    // console.log('MODE::::', mode, extra, game);
    onPostMessageOpenGame(game.current.id);
    onPostMessageUpdateGameUrl(url(game.current, extra.current));
  };

  const url = (game: IGameItem, extra?: string): string => {
    console.log('JS2U_____', circleTournamentInfoRef);
    const tokenAddress = circleTournamentInfoRef.current?.potToken;
    const alphaAddress = alphaAddressRef.current;

    const decodedUrl = decodeURIComponent(game?.gameUrl ?? '');
    let result = '';
    result = `${decodedUrl}?jackpot=true&action=PLAY&alpha=${alphaAddress}&player=${addressL2}&erc20=${tokenAddress}`;
    result = `${result}&extra=wombat`;

    return result;
  };

  const releaseIframe = () => {
    // setGame(undefined);
    game.current = undefined;
    setUnityIframeStatus(UnityIFrameStatus.RELEASED);
    console.log('RELEASE LIVE GAME IFRAME');
  };

  const contextValues = useMemo((): ILiveGameFiContext => {
    return {
      unityIframeStatus,
      updateIsPlayingGame,
      releaseIframe,
      openGame,
      isShowBackButton,
    };
  }, [isPlayingGame, unityIframeStatus, isShowBackButton]);

  return (
    <LiveGameFiContext.Provider value={contextValues}>
      <LiveTournamentProvider>{children}</LiveTournamentProvider>
    </LiveGameFiContext.Provider>
  );
};
