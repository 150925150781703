// import React, { useContext, useRef, useState } from 'react';
// import {
//   Flex,
//   Modal,
//   ModalBody,
//   ModalCloseButton,
//   ModalContent,
//   ModalHeader,
//   ModalOverlay,
//   Text,
// } from '@chakra-ui/react';
// import { Form } from 'react-final-form';
// import s from './styles.module.scss';
// import { getErrorMessage } from '@/utils/error';
// import toast from 'react-hot-toast';
// import OtpInput from 'react-otp-input';
// import { getAddressReferrer, useReferralCode } from '@/services/referral';
// import { WalletContext } from '@/contexts/wallet-context';
// import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
// import RedEnvelopModal, {
//   RED_ENVELOP_MODAL,
// } from '@/components/RedEnvelopModal';
// import { RECEIVED_REFERRAL_DONATION } from '@/constants/storage-key';
// import { getListPublicEvents, checkIfCanClaim } from '@/services/donate';
// import { closeModal, openModal } from '@/state/modal';
// import { useAppDispatch } from '@/state/hooks';
// import sleep from '@/utils/sleep';
// import InputSearch from '@/modules/AlphaPWA/Profile/EnterInviteCodeModal/InputSearch';

// type Props = {
//   isOpen: boolean;
//   onClose: () => void;
// };

// interface IFormValue {
//   code: string;
// }

// const EnterInviteCodeModal: React.FC<Props> = ({
//   isOpen,
//   onClose,
// }: Props): React.ReactElement => {
//   const refForm = useRef<any>();
//   const [loading, setLoading] = useState(false);
//   const { addressL2 } = useContext(WalletContext);
//   const [submitError, setSubmitError] = useState('');
//   const dispatch = useAppDispatch();
//   const gaEventTracker = useAnalyticsEventTracker();

//   const handleCloseModal = () => {
//     dispatch(closeModal({ id: RED_ENVELOP_MODAL }));
//   };

//   const handleShowLuckyMoney = async () => {
//     const isReceived = localStorage.getItem(RECEIVED_REFERRAL_DONATION);
//     if (isReceived) return;
//     const res = await getAddressReferrer();
//     if (!res || !addressL2) return;
//     const availableEvents = await getListPublicEvents({
//       network: 'nos',
//       address: addressL2 || '',
//       playerAddress: res,
//     });
//     if (availableEvents && availableEvents.length > 0) {
//       const canClaim = await checkIfCanClaim({
//         address: addressL2 || '',
//         playerAddress: res,
//         network: 'nos',
//         eventId: availableEvents[0].eventId,
//       });
//       if (!canClaim) {
//         return;
//       }
//       dispatch(
//         openModal({
//           id: RED_ENVELOP_MODAL,
//           theme: 'dark',
//           modalProps: {
//             centered: true,
//             zIndex: 9999999,
//           },
//           className: 'donate_modal',
//           hideCloseButton: true,
//           render: () => (
//             <RedEnvelopModal
//               event={availableEvents[0]}
//               onClose={handleCloseModal}
//             />
//           ),
//         })
//       );
//     }
//   };

//   const validateForm = (values: IFormValue): Record<string, string> => {
//     const errors: Record<string, string> = {};

//     if (!values.code) {
//       errors.code = 'Code is required.';
//     }
//     return errors;
//   };

//   const handleSubmit = async (payload: IFormValue): Promise<void> => {
//     if (loading) return;
//     setLoading(true);

//     try {
//       gaEventTracker(GameFiActions.SetReferralCodeStart, addressL2);
//       await useReferralCode({
//         code: `${payload.code}`.toLowerCase(),
//       });
//       gaEventTracker(GameFiActions.SetReferralCodeManual, addressL2);
//       gaEventTracker(GameFiActions.SetReferralCode, addressL2);
//       toast.success('Successfully');
//       onClose();
//       await sleep(3);
//       handleShowLuckyMoney();
//     } catch (error: any) {
//       if ((error as any).message) {
//         setSubmitError((error as any).message);
//       }

//       toast.error((error as any).message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className={s.wrapper}>
//       <Modal isOpen={isOpen} onClose={onClose} isCentered>
//         <ModalOverlay />

//         <ModalContent p="24px 22px" minW={330} className={s.modalContent}>
//           <Flex justifyContent={'flex-end'}>
//             <ModalCloseButton />
//           </Flex>
//           <ModalHeader
//             textAlign="center"
//             fontSize="24px"
//             fontWeight={'600'}
//             mb="16px"
//           >
//             <div className={s.heading}>
//               <div className={s.heading__title}>Enter Referrer's name</div>
//               <div className={s.heading__sub_title}>
//                 Who led you to New Bitcoin City?
//               </div>
//             </div>
//           </ModalHeader>
//           <ModalBody>
//             <Form
//               key="cash-out-form"
//               initialValues={{
//                 code: '@',
//               }}
//               validate={validateForm}
//               onSubmit={handleSubmit}
//             >
//               {({ handleSubmit }: any) => (
//                 <InputSearch
//                   handleSubmit={handleSubmit}
//                   ref={refForm}
//                   submitting={loading}
//                 />
//               )}
//             </Form>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </div>
//   );
// };

// export default EnterInviteCodeModal;

import React, { useContext, useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import s from './styles.module.scss';
import { getErrorMessage } from '@/utils/error';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { getAddressReferrer, useReferralCode } from '@/services/referral';
import { WalletContext } from '@/contexts/wallet-context';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import RedEnvelopModal, {
  RED_ENVELOP_MODAL,
} from '@/components/RedEnvelopModal';
import { RECEIVED_REFERRAL_DONATION } from '@/constants/storage-key';
import { getListPublicEvents, checkIfCanClaim } from '@/services/donate';
import { closeModal, openModal } from '@/state/modal';
import { useAppDispatch } from '@/state/hooks';
import sleep from '@/utils/sleep';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface IFormValue {
  code: string;
}

const EnterInviteCodeModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { addressL2 } = useContext(WalletContext);
  const [submitError, setSubmitError] = useState('');
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();

  const handleCloseModal = () => {
    dispatch(closeModal({ id: RED_ENVELOP_MODAL }));
  };

  const handleShowLuckyMoney = async () => {
    const isReceived = localStorage.getItem(RECEIVED_REFERRAL_DONATION);
    if (isReceived) return;
    const res = await getAddressReferrer();
    if (!res || !addressL2) return;
    const availableEvents = await getListPublicEvents({
      network: 'nos',
      address: addressL2 || '',
      playerAddress: res,
    });
    if (availableEvents && availableEvents.length > 0) {
      const canClaim = await checkIfCanClaim({
        address: addressL2 || '',
        playerAddress: res,
        network: 'nos',
        eventId: availableEvents[0].eventId,
      });
      if (!canClaim) {
        return;
      }
      dispatch(
        openModal({
          id: RED_ENVELOP_MODAL,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          className: 'donate_modal',
          hideCloseButton: true,
          render: () => (
            <RedEnvelopModal
              event={availableEvents[0]}
              onClose={handleCloseModal}
            />
          ),
        })
      );
    }
  };

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.code) {
      errors.code = 'Code is required.';
    }
    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    if (loading) return;
    setLoading(true);

    try {
      gaEventTracker(GameFiActions.SetReferralCodeStart, addressL2);
      await useReferralCode({
        code: `${payload.code}`.toLowerCase(),
      });
      gaEventTracker(GameFiActions.SetReferralCodeManual, addressL2);
      gaEventTracker(GameFiActions.SetReferralCode, addressL2);
      toast.success('Successfully');
      onClose();
      await sleep(3);
      handleShowLuckyMoney();
    } catch (error: any) {
      if ((error as any).message) {
        setSubmitError((error as any).message);
      }

      toast.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.wrapper}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent p="24px 22px" minW={330} textAlign={'center'}>
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="center"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Enter invite code
          </ModalHeader>
          <ModalBody>
            <Formik
              key="cash-out-form"
              initialValues={{
                code: '',
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({
                values,
                touched,
                errors,
                // handleChange,
                setFieldValue,
                handleSubmit,
              }) => {
                // console.log(values)
                return (
                  <form className={s.form} onSubmit={handleSubmit}>
                    <div className={s.codeInput}>
                      <OtpInput
                        value={values.code}
                        onChange={v => {
                          setSubmitError('');
                          setFieldValue('code', v);
                        }}
                        numInputs={4}
                        renderSeparator={<></>}
                        renderInput={props => <input {...props} />}
                        placeholder="----"
                        inputType="text"
                      />
                      {!!(errors.code || submitError) && (
                        <div className={s.error}>
                          <Text className="error">
                            {errors.code || submitError}
                          </Text>
                        </div>
                      )}
                    </div>
                    <button
                      disabled={!!errors.code || !!submitError || loading}
                      className={s.submitBtn}
                      type="submit"
                    >
                      {loading ? 'Processing...' : 'Confirm'}
                    </button>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EnterInviteCodeModal;
