import { TokenMultiChainInit } from '@/state/tokens/constants';
import { createSlice } from '@reduxjs/toolkit';
import {
  actionCreators,
  fetchEstimateFeeEthereum,
  fetchEstimateWidthrawFee,
  fetchTokenBalance,
  loadETHBalanceOnEthereum,
} from './actions';
import { PREFIX } from './constants';
import { SendFormState } from './types';

export const initialState: SendFormState = {
  tokenSelected: TokenMultiChainInit,

  // Estimate WidthrawData
  estimateWidthrawData: undefined,
  isFetchingEstWidthraw: false,

  MINI_WIDTHRAW_BTC_AMOUNT: '0.0003',

  // Estimate Fee OutChain
  estimateFeeOutchainERC20: undefined,
  gasPriceEthereum: '0',

  isEstFetchingFeeOutchainERC20: false,

  // Token Balance
  tokenBalance: '0',
  isFetchingBalance: false,

  // ETH Balance
  ethBalance: '0',
  isFetchingETHBalance: false,

  // Network Select
  networkSelected: undefined,

  //Form
  isSubmitting: false,
  formInstance: undefined,

  //Max Action
  isMaxAction: false,
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTokenBalance.pending, state => {
        state.tokenBalance = '0';
        state.isFetchingBalance = true;
      })
      .addCase(fetchTokenBalance.fulfilled, (state, action) => {
        state.tokenBalance = action.payload;
        state.isFetchingBalance = false;
      })
      .addCase(fetchTokenBalance.rejected, (state, action) => {
        state.tokenBalance = '0';
        state.isFetchingBalance = false;
      });

    builder
      .addCase(loadETHBalanceOnEthereum.pending, state => {
        state.ethBalance = '0';
        state.isFetchingETHBalance = true;
      })
      .addCase(loadETHBalanceOnEthereum.fulfilled, (state, action) => {
        state.ethBalance = action.payload;
        state.isFetchingETHBalance = false;
      })
      .addCase(loadETHBalanceOnEthereum.rejected, (state, action) => {
        state.ethBalance = '0';
        state.isFetchingETHBalance = false;
      });

    builder
      .addCase(fetchEstimateWidthrawFee.pending, state => {
        state.estimateWidthrawData = undefined;
        state.isFetchingEstWidthraw = true;
      })
      .addCase(fetchEstimateWidthrawFee.fulfilled, (state, action) => {
        state.estimateWidthrawData = action.payload;
        state.isFetchingEstWidthraw = false;
      })
      .addCase(fetchEstimateWidthrawFee.rejected, (state, action) => {
        state.estimateWidthrawData = undefined;
        state.isFetchingEstWidthraw = false;
      });

    builder
      .addCase(fetchEstimateFeeEthereum.pending, state => {
        state.estimateFeeOutchainERC20 = undefined;
        state.isEstFetchingFeeOutchainERC20 = true;
        state.gasPriceEthereumRaw = undefined;
        state.gasPriceEthereum = undefined;
      })
      .addCase(fetchEstimateFeeEthereum.fulfilled, (state, action) => {
        state.estimateFeeOutchainERC20 = action.payload?.fee;
        state.gasPriceEthereum = action.payload?.gasPrice;
        state.gasPriceEthereumRaw = action.payload?.gasPriceRaw;

        state.isEstFetchingFeeOutchainERC20 = false;
      })
      .addCase(fetchEstimateFeeEthereum.rejected, (state, action) => {
        state.estimateFeeOutchainERC20 = undefined;
        state.gasPriceEthereum = undefined;
        state.gasPriceEthereumRaw = undefined;
        state.isEstFetchingFeeOutchainERC20 = false;
      });
  },
});

export const sendFormActions = slice.actions;

export default slice.reducer;
