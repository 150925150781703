import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  IMarketSortCol,
  IMarketSortType,
  IMarketTokensRequest,
} from '@/services/interfaces/token';
import {
  KEY_AMOUNTS,
  TOKEN_AMOUNTS,
} from '@/modules/AlphaPWA/Market/BottomBar/BottomBar.Setting';

export interface IFilter {
  sort_col?: 'buy_price' | 'created_at';
  sort_type?: '0' | '1';
  search?: string;
}

export interface IPassTokenState {
  tokenTabIndex: number;
  filter1: IFilter;
  searching: boolean;
  marketFilter: IMarketTokensRequest;
  marketTabIndex: number;
  tokenAmountSweep: number;
  keyAmountSweep: number;
}

const initialState: IPassTokenState = {
  tokenTabIndex: 0,
  filter1: {
    sort_col: 'buy_price',
    sort_type: '0',
    search: '',
  },
  searching: false,
  marketFilter: {
    sort_col: IMarketSortCol.pass_token_percent,
    sort_type: IMarketSortType.dsd,
    page: 1,
    limit: 30,
  },
  marketTabIndex: 2,
  tokenAmountSweep: TOKEN_AMOUNTS[1],
  keyAmountSweep: KEY_AMOUNTS[0],
};

const passTokenSlide = createSlice({
  name: 'passToken',
  initialState,
  reducers: {
    setTokenTabIndex: (state, actions) => {
      state.tokenTabIndex = actions.payload;
    },
    setPassTokenFilters: (state, actions) => {
      state.filter1 = { ...state.filter1, ...actions.payload };
    },
    setSearching: (state, actions) => {
      state.searching = actions.payload;
    },
    updateMarketFilter: (state, actions) => {
      state.marketFilter = actions.payload;
    },
    setMarketTabIndex: (state, actions) => {
      state.marketTabIndex = actions.payload;
    },
    setTokenAmountSweep: (state, actions) => {
      state.tokenAmountSweep = actions.payload;
    },
    setKeyAmountSweep: (state, actions) => {
      state.keyAmountSweep = actions.payload;
    },
  },
});

export const {
  setTokenTabIndex,
  setPassTokenFilters,
  setSearching,
  updateMarketFilter,
  setMarketTabIndex,
  setTokenAmountSweep,
  setKeyAmountSweep,
} = passTokenSlide.actions;

export const passTokenReducer = (state: RootState) => state.passToken;

export default passTokenSlide.reducer;
