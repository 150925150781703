import { WalletContext } from '@/contexts/wallet-context';
import CPassToken from '@/contracts/passToken';
import { getUserDetailByTwitterId } from '@/services/player-share';
import { useContext, useRef } from 'react';

const useUserToken = () => {
  const passToken = useRef(new CPassToken()).current;
  const { addressL2 } = useContext(WalletContext);

  const getOwnerToken = async (twitterId: string) => {
    return await passToken.getTokenByTwitterId(twitterId);
  };

  const getUserInfoByToken = async (tokenAddress: string) => {
    if (!addressL2 || !tokenAddress) return;
    const twitterId = await passToken.getTwitterIdByPassToken(tokenAddress);
    if (!twitterId) return;
    const res = await getUserDetailByTwitterId({
      address: addressL2!,
      twitterIds: [`${twitterId}`],
    });
    return res[`${twitterId}`];
  };

  return { getOwnerToken, getUserInfoByToken };
};

export default useUserToken;
