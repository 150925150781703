import {
  ACCESS_TOKEN,
  PREV_CHAIN_ID,
  REFRESH_TOKEN,
  WALLET_ADDRESS_CONNECT,
} from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';

export const getAccessToken = (): string | null => {
  const accessToken = localStorage.get(ACCESS_TOKEN) as string;
  return accessToken;
};

export const clearAccessTokenStorage = (): void => {
  localStorage.remove(ACCESS_TOKEN);
  localStorage.remove(REFRESH_TOKEN);
};

export const clearAuthStorage = (): void => {
  localStorage.remove(ACCESS_TOKEN);
  localStorage.remove(REFRESH_TOKEN);
  // walletBTCStorage.removeWallet();
};

export const setAccessToken = (
  accessToken: string,
  refreshToken: string
): void => {
  localStorage.set(ACCESS_TOKEN, accessToken);
  localStorage.set(REFRESH_TOKEN, refreshToken);
};

export const getWalletAddress = (): string | null => {
  const walletAddress = localStorage.get(WALLET_ADDRESS_CONNECT) as string;
  return walletAddress;
};

export const setWalletAddress = (walletAddress: string): void => {
  localStorage.set(WALLET_ADDRESS_CONNECT, walletAddress);
};

export const setWalletChainId = (chainId: any): void => {
  localStorage.set(PREV_CHAIN_ID, chainId);
};
