import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  extendTheme,
} from '@chakra-ui/react';

import { css } from 'styled-components';
import { checkboxTheme } from './themes/checkboxTheme';
import { modalTheme } from './themes/modal';
import { numberInputTheme } from './themes/numberInput';
import Slider from './themes/slider';
import Tabs from './themes/tabs';
import px2rem from './utils/px2rem';
import { drawerTheme } from './themes/drawer';
import { inputTheme } from './themes/input';

const Button: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'text.white',
  },
  baseStyle: {
    borderRadius: 40,
    _hover: {
      opacity: 0.8,
    },
  },
  sizes: {
    md: {
      fontSize: `${px2rem(20)}`,
      lineHeight: '28px',
      padding: `${px2rem(24)} ${px2rem(60)}`,
    },
  },
  variants: {
    solid: {
      bgColor: 'text.blue',
      fontSize: '14px',
      fontWeight: '600',
    },
    link: {
      textDecoration: 'underline',
    },
    outline: {
      bgColor: 'transparent',
      borderWidth: 1,
      borderColor: 'text.blue',
      color: 'text.blue',
    },
  },
};

const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    color: 'black',
  },
};

const Switch: ComponentStyleConfig = {
  baseStyle: {
    thumb: {
      bgColor: '#000',
    },
    track: {
      _checked: {
        bgColor: '#F8F0AC',
      },
    },
  },
};

const breakpoints = {
  sm: '500px',
  md: '768px',
  'min-pc': '1024px',
  'mid-pc': '1280px',
  lg: '1440px',
  xl: '1920px',
  '2xl': '2560px',
};

type MediaWidthsType = typeof breakpoints;
type MediaWidthsKeysType = keyof MediaWidthsType;

export const MediaQueryBuilder = (
  key: MediaWidthsKeysType,
  innerCSS?: any
) => css`
  @media (max-width: ${breakpoints[key]}) {
    ${innerCSS};
  }
`;

const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'var(--chakra-colors-gray-100)',
  },
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const customTheme = extendTheme({
  breakpoints,
  fonts: {
    primary: `var(--pro-font-family)`,
  },
  colors: {
    text: {
      primary: '#1c1c1c',
      secondary: '#999999',
      accent: '#0DB774',
      blue: '#1588FF',
    },
    brand: {
      primary: {
        50: '#efe4ff',
        100: '#cdb3ff',
        200: '#a37ef2',
        300: '#8a4dfe',
        400: '#6a1cfd',
        500: '#5103e4',
        600: '#3e01b2',
        700: '#2c0080',
        800: '#1b004f',
        900: '#0a001f',
      },
      info: {
        50: '#dff1ff',
        100: '#afd2ff',
        200: '#7eb3ff',
        300: '#4c95ff',
        400: '#1b77fd',
        500: '#025de4',
        600: '#0048b2',
        700: '#003480',
        800: '#001f50',
        900: '#000a20',
      },
      success: {
        50: '#dbfff3',
        100: '#b0fbdf',
        200: '#84f8ca',
        300: '#55f3b7',
        400: '#28f0a2',
        500: '#0fd788',
        600: '#02a769',
        700: '#00774b',
        800: '#00492b',
        900: '#001a0b',
      },
      warning: {
        50: '#ffefdb',
        100: '#ffd5ae',
        200: '#ffba7e',
        300: '#ff9e4c',
        400: '#ff831a',
        500: '#e66900',
        600: '#b45200',
        700: '#813900',
        800: '#4f2200',
        900: '#200900',
      },
      danger: {
        50: '#ffe5e9',
        100: '#f9bcc2',
        200: '#ee919a',
        300: '#e66673',
        400: '#dd3b4b',
        500: '#c42231',
        600: '#991826',
        700: '#6e101a',
        800: '#44070e',
        900: '#1e0001',
      },
    },
    background: {
      primary: '#fff',
      white: '#fff',
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bgColor: 'background.default',
        color: 'text.primary',
      },
      // styles for the `a`
      a: {
        _hover: {
          color: 'inherit',
          // textDecoration: 'underline',
        },
      },
    },
  },
  components: {
    Tabs,
    Button,
    Divider,
    Modal: modalTheme,
    Text,
    Slider,
    NumberInput: numberInputTheme,
    Checkbox: checkboxTheme,
    Switch,
    Drawer: drawerTheme,
    Input: inputTheme,
  },
});

export default customTheme;
