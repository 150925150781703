import { TOURNAMENT_ENABLE } from '@/configs';
import {
  IGameItem,
  IJackPotInfo,
  ITournamentCircleConfigItem,
} from '@/interfaces/api/gamefi';
import {
  IJackpotLeaderboardItem as IJackpotLeaderBoardItem,
  IJackpotLeaderboardItem,
} from '@/interfaces/jackpot';
import { _isUnityGame } from '@/modules/AlphaPWA/Games';
import {
  DefaultTournamentConfig,
  gameNameToId,
} from '@/modules/AlphaPWA/Games/constants';
import useUserToken from '@/modules/AlphaPWA/Games/hooks/useUserToken';
import {
  getGameConfig,
  getJackpotInfo,
  getJackpotLeaderBoard,
  getJackpotLeaderBoardByPotId,
  getPlayerHistory,
} from '@/services/gamefi';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { max } from 'lodash';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WalletContext } from './wallet-context';
import { useRouter } from 'next/router';

const TOURNAMENT_CONFIG_KEY = 'tournament-config';
const TOURNAMENT_WHITE_LIST_CONFIG_KEY = 'whitelist-address';
const TOURNAMENT_BLACK_LIST_CONFIG_KEY = 'blacklist-address';
const WOMBAT_WHITE_LIST_CONFIG_KEY = 'wombat-whitelist-address';
export const ALL_WHITELIST = 'all';

export interface ITournamentContext {
  // circleInfo: any | undefined;
  whiteList: Array<string>;
  blackList: Array<string>;
  wombatWhiteList: Array<string>;
  circleAddress: string;
  // tokenAddress: string;
  circleTournamentInfo: IJackPotInfo | undefined;
  jackpot: number;
  deadline: string;
  gameInfo: IGameItem | undefined;
  usdVal: number;
  topAlpha: IJackpotLeaderBoardItem | undefined;
  rank?: number;
  lastScore?: number;
  totalScore?: number;
  bestScore?: number;
  nPlayTarget: number;
  isFetchingTournament: boolean;
  ownerCircleAddress: string;
  isEnable: boolean;
  tournamentConfig: ITournamentCircleConfigItem;
  // setCurrentCircleOwnerAddress: (circle?: string) => void;
  setCircleAddress: (circleAddress?: string) => void;
  clearTournament: () => void;
  init: () => void;
  setOwnerCircleAddress: (ownerCircleAddress?: string) => void;
  getRewards: (potId: string) => Promise<IJackpotLeaderboardItem[]>;
}

const initialValue: ITournamentContext = {
  // circleInfo: undefined,
  whiteList: [],
  blackList: [],
  wombatWhiteList: [],
  circleAddress: '',
  // tokenAddress: '',
  circleTournamentInfo: undefined,
  jackpot: 0,
  deadline: '',
  gameInfo: undefined,
  topAlpha: undefined,
  usdVal: 0,
  rank: 0,
  totalScore: 0,
  lastScore: 0,
  bestScore: 0,
  nPlayTarget: 0,
  isFetchingTournament: true,
  ownerCircleAddress: '',
  isEnable: false,
  tournamentConfig: DefaultTournamentConfig,
  setCircleAddress: () => {},
  clearTournament: () => {},
  init: () => {},
  setOwnerCircleAddress: () => {},
  getRewards: async () => [],
};

export const TournamentContext =
  React.createContext<ITournamentContext>(initialValue);

export const TournamentProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const router = useRouter();
  const { id } = router.query as { id: string };

  const { addressL2 } = useContext(WalletContext);

  const [circleAddress, updateCircleAddress] = useState<string>('');
  // const [tokenAddress, setTokenAddress] = useState<string>('');
  const [ownerCircleAddress, updateOwnerCircleAddress] = useState<string>('');

  const [circleTournamentInfo, setCircleTournamentInfo] = useState<
    IJackPotInfo | undefined
  >();

  // Tournament Remote Config
  const [whiteList, setWhiteList] = useState<Array<string>>([]);
  const [blackList, setBlackList] = useState<Array<string>>([]);
  const [wombatWhiteList, setWombatWhiteList] = useState<Array<string>>([]);
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [tournamentConfig, setTournamentConfig] =
    useState<ITournamentCircleConfigItem>(DefaultTournamentConfig);
  const [tournamentConfigs, setTournamentConfigs] = useState<
    ITournamentCircleConfigItem[]
  >([]);

  // UI data
  const [jackpot, setJackpot] = useState(0);
  const [deadline, setDeadline] = useState('');
  const [eth2Usd] = useState(0);
  const [gameInfo, setGameInfo] = useState<IGameItem | undefined>();
  const [yourReward, setYourReward] = useState<number>(0);
  const [topAlpha, setTopAlpha] = useState<
    IJackpotLeaderBoardItem | undefined
  >();

  const [rank, setRank] = useState<number | undefined>(0);
  const [lastScore, setLastScore] = useState<number | undefined>(0);
  const [bestScore, setBestScore] = useState<number | undefined>(0);
  const [totalScore, setTotalScore] = useState<number | undefined>(0);
  const [isFetchingTournament, setIsFetchingTournament] = useState(true);
  const [nPlayTarget, setNPlayTarget] = useState(0);
  const { getUserInfoByToken } = useUserToken();
  const [ratio, setRatio] = useState<number>(0);

  useEffect(() => {
    setRatio(0);
    fetchTokenPrice();
  }, [circleTournamentInfo?.potToken]);

  const fetchTokenPrice = async () => {
    const tokenPriceUsd = (
      await getUserInfoByToken(circleTournamentInfo?.potToken ?? '')
    )?.tokenPriceUsd;
    if (!tokenPriceUsd) return;
    setRatio(tokenPriceUsd);
  };

  const setCircleAddress = (_circleAddress?: string) => {
    // if (_tokenAddress) {
    //   setTokenAddress(_tokenAddress);
    // }
    if (_circleAddress) {
      if (_circleAddress == circleAddress) {
        fetchCurrentTournament();
      } else {
        updateCircleAddress(_circleAddress);
      }
    } else {
      fetchCurrentTournament();
    }
  };

  const setOwnerCircleAddress = (_ownerCircleAddress?: string) => {
    if (_ownerCircleAddress) {
      updateOwnerCircleAddress(_ownerCircleAddress);
    }
  };

  const clearTournament = () => {
    updateCircleAddress('');
    setCircleTournamentInfo(undefined);
    setIsEnable(false);
    setTournamentConfig(DefaultTournamentConfig);
  };

  useEffect(() => {
    if (!TOURNAMENT_ENABLE) {
      return;
    }
    fetchCurrentTournament();
  }, [circleAddress, whiteList, tournamentConfig]);

  useEffect(() => {
    _updateUIData();
    updateDataCircleTournament(false);
  }, [circleTournamentInfo, circleTournamentInfo?.value]);

  useEffect(() => {
    var topScore = parseInt(topAlpha?.score ?? '0');
    if (rank != 1) {
      setNPlayTarget(topScore - (bestScore ?? 0));
    }
  }, [rank, topAlpha, totalScore, bestScore]);

  const usdVal = useMemo(() => {
    return jackpot * ratio;
  }, [jackpot, ratio]);

  const _updateUIData = () => {
    if (!circleTournamentInfo) {
      return;
    }

    // Jackpot Reward
    const jackpotVal = new BigNumber(circleTournamentInfo.value)
      .dividedBy(1e18)
      .toNumber();
    setJackpot(jackpotVal);
    if (circleTournamentInfo.game) {
      setGameInfo({
        ...circleTournamentInfo.game,
        descriptionText: '',
        id: gameNameToId(circleTournamentInfo.game.name),
        isUnityGame: _isUnityGame(circleTournamentInfo.game.name),
        numOfPlays: 0,
      });
    }

    // End Time
    const endAt = dayjs(parseInt(circleTournamentInfo.endAt ?? 0) * 1000)
      .utc()
      .toISOString();
    setDeadline(endAt);
  };

  const _fetchLeaderBoard = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    if (isRefresh) {
      setRank(undefined);
      setTopAlpha(undefined);
    }

    if (!circleAddress) {
      return;
    }

    const res = await getJackpotLeaderBoard(circleAddress);
    const leaderBoard = res?.leaderboard ?? [];
    if (leaderBoard.length > 0) {
      setTopAlpha(leaderBoard[0]);

      const _myInfoIndex = leaderBoard.findIndex(
        e => e.player.address.toLowerCase() == addressL2?.toLowerCase()
      );

      if (_myInfoIndex > -1) {
        setRank(_myInfoIndex + 1);
      }
    } else {
      setRank(undefined);
      setTopAlpha(undefined);
    }
  };

  const _fetchPlayerHistory = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    if (isRefresh) {
      setLastScore(undefined);
      setTotalScore(undefined);
      setBestScore(undefined);
    }

    if (!circleAddress || !addressL2) {
      return;
    }
    const res = await getPlayerHistory(circleAddress, addressL2);
    if (res.history.length > 0) {
      const _lastScore = res.history[res.history.length - 1];
      const _totalScore = res.totalScore;
      const _bestScore = max(res.history.map(e => parseInt(e)));

      setLastScore(parseInt(_lastScore));
      setTotalScore(_totalScore);
      setBestScore(_bestScore);
    } else {
      setLastScore(undefined);
      setTotalScore(undefined);
      setBestScore(undefined);
    }
  };

  const updateDataCircleTournament = (isRefresh: boolean = false) => {
    _fetchLeaderBoard({
      isRefresh: isRefresh,
    });
    _fetchPlayerHistory({
      isRefresh: isRefresh,
    });
  };

  // const _fetchCircleInfo = async () => {
  //   setIsFetchingTournament(true);

  //   if (!currentCircleOwnerAddress) {
  //     return;
  //   }

  //   var _token = await getPlayerTokenDetail({
  //     address: currentCircleOwnerAddress,
  //   });
  //   console.log('@@@@@@@_fetchCircleInfo:::', _token);

  //   setCircleInfo(_token);
  //   setIsFetchingTournament(false);
  // };

  const fetchCurrentTournament = async () => {
    setIsFetchingTournament(true);
    setCircleTournamentInfo(undefined);
    if (!circleAddress) {
      setIsFetchingTournament(false);
      return;
    }

    var _circleTournamentInfo = await getJackpotInfo(circleAddress);

    // console.log('currentTour_tournament:::', _circleTournamentInfo);

    setCircleTournamentInfo(_circleTournamentInfo.jackpot);

    setIsEnable(
      whiteList.includes(circleAddress) || whiteList.includes(ALL_WHITELIST)
    );

    const foundConfig = tournamentConfigs.find(
      e => e.circleAddress.toLowerCase() == circleAddress.toLocaleLowerCase()
    );

    // console.log('config_foundConfig', foundConfig);

    if (foundConfig) {
      setTournamentConfig(foundConfig);
    }
    setIsFetchingTournament(false);
  };

  const init = async () => {
    initWhiteList();
    getTournamentConfig();
    // initBlackList();
    // initWombatWhiteList();
  };

  const initWhiteList = async () => {
    const whiteListRes = await getGameConfig(TOURNAMENT_WHITE_LIST_CONFIG_KEY);
    const whiteList = JSON.parse(whiteListRes.config.value) as Array<string>;
    // console.log('config_whiteList:::', whiteList);
    setWhiteList(whiteList);
  };

  const getTournamentConfig = async () => {
    const tournamentConfigRes = await getGameConfig(TOURNAMENT_CONFIG_KEY);
    const tournamentConfig = JSON.parse(
      tournamentConfigRes.config.value
    ) as Array<ITournamentCircleConfigItem>;
    // console.log('config_tournamentConfig:::', tournamentConfig);

    setTournamentConfigs(tournamentConfig);
  };

  const initBlackList = async () => {
    const blackListRes = await getGameConfig(TOURNAMENT_BLACK_LIST_CONFIG_KEY);
    const blackList = JSON.parse(blackListRes.config.value) as Array<string>;
    // console.log('blackList:::', blackList);
    setBlackList(blackList);
  };

  const initWombatWhiteList = async () => {
    const wombatWhiteListRes = await getGameConfig(
      WOMBAT_WHITE_LIST_CONFIG_KEY
    );
    const wombatWhiteList = JSON.parse(
      wombatWhiteListRes.config.value
    ) as Array<string>;
    // console.log('wombatWhiteList:::', wombatWhiteList);
    setWombatWhiteList(wombatWhiteList);
  };

  const getRewards = async (
    potId: string
  ): Promise<IJackpotLeaderboardItem[]> => {
    const res = await getJackpotLeaderBoardByPotId(potId);
    // console.log('reward::::', res.leaderboard);

    return res.leaderboard;
  };

  const contextValues = useMemo((): ITournamentContext => {
    return {
      deadline,
      gameInfo,
      jackpot,
      usdVal,
      topAlpha,
      circleAddress,
      circleTournamentInfo,
      rank,
      lastScore,
      totalScore,
      isFetchingTournament,
      nPlayTarget,
      bestScore,
      setCircleAddress,
      clearTournament,
      init,
      setOwnerCircleAddress,
      whiteList,
      ownerCircleAddress,
      blackList,
      wombatWhiteList,
      isEnable,
      tournamentConfig,
      getRewards,
      // tokenAddress,
    };
  }, [
    deadline,
    gameInfo,
    jackpot,
    usdVal,
    yourReward,
    topAlpha,
    circleAddress,
    circleTournamentInfo,
    rank,
    lastScore,
    totalScore,
    isFetchingTournament,
    nPlayTarget,
    bestScore,
    whiteList,
    ownerCircleAddress,
    wombatWhiteList,
    blackList,
    isEnable,
    tournamentConfig,
    // tokenAddress,
  ]);

  return (
    <TournamentContext.Provider value={contextValues}>
      {children}
    </TournamentContext.Provider>
  );
};
