import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { memo } from 'react';

type Props = {
  imgSrcs: string | string[];
  width?: number;
  height?: number;
  fill?: boolean;
};

const DAvatar = memo(
  ({ imgSrcs, width = 48, height = 48, fill = false }: Props) => {
    return <Avatar width={width} url={imgSrcs} />;
  }
);

export default DAvatar;
