import LottieWrapper from '@/components/Lottie';
import { tryParseStringObj } from '@/modules/AlphaPWA/DirectMessage/helpers';
import {
  DMMessage,
  TipMessageContent,
} from '@/modules/AlphaPWA/DirectMessage/types';
import { useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import cs from 'classnames';
import { getAvatarId } from '@/components/GroupCall/UserAvatar';
import gsap from 'gsap';

export default function Tip({
  tip,
  removeTipOutOfQueue,
}: {
  tip: DMMessage;
  removeTipOutOfQueue: (messageId: string) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [messageContent, setMessageContent] = useState<TipMessageContent>();

  useEffect(() => {
    try {
      const content = tryParseStringObj<TipMessageContent>(tip.content);
      if (typeof content !== 'string') {
        setMessageContent(content);
      }
    } catch (err) {
      console.log(err);
    }
  }, [tip]);

  const setAppearPosition = (x: number, y: number) => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();

      ref.current.style.top = `${y - height / 2}px`;
      ref.current.style.left = `${x - width / 2}px`;
      ref.current.style.opacity = '1';
    }
  };

  const setTarget = (x: number, y: number) => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      gsap.to(ref.current, {
        top: y - height / 2,
        left: x - width / 2,
        duration: 2.5,
        onComplete: () => {
          setTimeout(() => {
            removeTipOutOfQueue(tip.id);
          }, 2000);
        },
      });
    }
  };

  const handleOnLoad = () => {
    if (messageContent) {
      const senderUiId = getAvatarId(messageContent.sender.address);
      const receiverUiId = getAvatarId(messageContent.receiver.address);

      const receiverEle = document.getElementById(receiverUiId);
      if (receiverUiId && receiverEle) {
        if (senderUiId) {
          const senderEle = document.getElementById(senderUiId);
          if (senderEle) {
            const { top, left, width, height } =
              senderEle.getBoundingClientRect();
            setAppearPosition(left + width / 2, top + height / 2);
          } else {
            setAppearPosition(window.innerWidth, window.innerHeight);
          }
        }

        const { top, left, width, height } =
          receiverEle.getBoundingClientRect();
        setTarget(left + width / 2, top + height / 2);
      }
    }
  };

  useEffect(() => {
    if (messageContent) {
      setTimeout(() => {
        handleOnLoad();
      }, 1000);
    }
  }, [messageContent]);

  if (messageContent) {
    return (
      <div ref={ref} className={cs(s.tipLottie)}>
        <LottieWrapper size={40} src={messageContent?.animation as string} />
      </div>
    );
  }
  return <></>;
}
