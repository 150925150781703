import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import React from 'react';

const ReactionGreen = () => {
  return (
    <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-emoji-green-1.svg`} />
  );
};

export default ReactionGreen;
