import {Button, Flex, Spinner, Text} from "@chakra-ui/react";
import styles from "@/modules/AlphaPWA/ThreeThreeInvitations/Invitations/styles.module.scss";
import React, {useState} from "react";
import {IThreeThreeInvitation} from "@/interfaces/threethree";
import BtnSend33 from "@/modules/AlphaPWA/Profiles/TradeKey/components/profileHeaderUserInfo/btnSend33";
import useOpenDepositModal from "@/hooks/useOpenDepositModal";
import {formatEther} from "ethers/lib/utils";
import {ethers} from "ethers";
import {formatCurrency} from "@/utils";

const ModalAccept = (
  {
    // isUserAAvailable,
    isUserBAvailable,
    isUserAChange,
    isUserBChange,
    data,
    handleReject,
    handleResend33,
    onClose,
    amountRequire
  }:
    {
      // isUserAAvailable: boolean,
      isUserBAvailable: boolean,
      isUserAChange: boolean,
      isUserBChange: boolean,
      data: IThreeThreeInvitation,
      handleReject: (_: any) => void,
      handleResend33: (_: any) => void,
      onClose: () => void,
      amountRequire: ethers.BigNumber
    }
) => {
  const [isReject, setIsReject] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const { handleOpenTopup, fetchingOtp } = useOpenDepositModal();

  const onHandleReject = async () => {
    setIsReject(true);
    await handleReject(data);
    setIsReject(false);
  }

  const onHandleResend = async (txs: any) => {
    handleResend33(txs);
  }

  const profile = {
    address: data?.user_a?.address
  }

  const tokenDetail = {
    address: data?.user_a?.token_address,
    userTwitterName: data?.user_a.twitter_name,
  }

  return (
    <Flex direction={"column"} gap={8}>
      <Text>
        {
          !isUserBAvailable ? (
            `You need ${formatCurrency(formatEther(amountRequire), 0, 6)} BTC more to accept this (3,3) request. Please make a deposit to proceed.`
          // ) : !isUserAAvailable ? (
          //   `${data.user_a.twitter_name} does not have enough balance to execute (3,3) transaction.`
          ) : isUserAChange ? (
            `The price of ${data.user_a.twitter_name}'s keys has fluctuated since the (3,3) request was initiated. The offered amount is not sufficient to buy the minimum of 0.1 keys at the current price.`
          ) : isUserBChange ? (
            `The price of ${data.user_b.twitter_name}'s keys has fluctuated since the (3,3) request was initiated. The offered amount is not sufficient to buy the minimum of 0.1 keys at the current price.`
          ) : (
            ``
          )
        }
      </Text>
      <Flex gap={4} className={styles.btnActionWrapper}>
        {
          !isUserBAvailable ? (
            <Button
              onClick={() => {
                handleOpenTopup();
                onClose();
              }}
              className={styles.btnResend}
            >
              {fetchingOtp ? <Spinner size="lg"></Spinner> : 'Deposit now'}
            </Button>
          ) : (
            <>
              <Button
                isLoading={isReject}
                isDisabled={isReject || isResend}
                className={styles.btnCancel}
                onClick={onHandleReject}>Cancel</Button>
              <BtnSend33
                className={styles.btnResend}
                profile={profile}
                tokenDetail={tokenDetail}
                title={"Resend (3,3)"}
                onCallback={onHandleResend}
                isResend={true}
                orderId={`0x${data.order_id}`}
              />
            </>
          )
        }
      </Flex>
    </Flex>
  )
};

export default ModalAccept;
