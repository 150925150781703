import { AssetsContext } from '@/contexts/assets-context';
import { getErrorMessage } from '@/utils/error';
import { useCallback, useContext } from 'react';
import toast from 'react-hot-toast';
import usePlayerShareTokenContractSigner from '../usePlayerShareTokenContractSigner';


const useGetBalanceTC = () => {

  const getContractSigner = usePlayerShareTokenContractSigner();

  const getBalanceTC = useCallback(async (contractAddress: string) => {
    try {
      const contractSigner = getContractSigner(contractAddress);
      if (!contractSigner) {
        throw new Error('account is not ready.');
      }
      const tx = (await contractSigner.balanceTC());
      return tx;
    } catch (error) {
      const { message } = getErrorMessage(error);
      console.log(message)
    }
  }, [getContractSigner]);

  return { getBalanceTC };
};

export default useGetBalanceTC;
