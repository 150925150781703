import { PERP_API_URL } from '@/configs';
import { IPagingParams } from '@/interfaces/api/query';
import { isProduction } from '@/utils/commons';
import { delay } from '@/utils/helpers';
import { ILimitOrder, IResponseOrders } from '../interfaces/order';
import { IRequestBase } from '../interfaces/services';
import {
  IParamsGetKeys,
  IResponseTradeKeys,
  ITradeKey,
} from '../interfaces/trade';
import { IUserProfile } from '../interfaces/userProfile';
import {
  IGetWatchListParams,
  IResponseCopyTradeList,
} from '../interfaces/copyTrade';
import CApiClient from './apiClient';
import { IResponseWatchList, IWatchList } from '../interfaces/watchList';
import axios from 'axios';
import { ILocation } from '../interfaces/location';

const DEFAULT_LIMIT_RETRY_SCAN = 5;

class CTradeAPI {
  private prefix = `${PERP_API_URL}/api`;

  private apiClient = new CApiClient().api;

  private limitScanTX = DEFAULT_LIMIT_RETRY_SCAN;

  private getUrl(url: string) {
    return `${this.prefix}/${url}`;
  }

  public getTokenDetail = async (address: string): Promise<ITradeKey> => {
    try {
      const res: ITradeKey = await this.apiClient.get(
        this.getUrl('player-share/token/detail'),
        {
          params: {
            address,
            network: 'nos',
          },
        }
      );

      return res || {};
    } catch (e) {
      return {};
    }
  };

  public getUserProfile = async (
    player_address: string,
    address?: string
  ): Promise<IUserProfile> => {
    try {
      const res: IUserProfile = await this.apiClient.get(
        this.getUrl('player-share/profile/v3'),
        {
          params: {
            address,
            player_address,
            network: 'nos',
          },
        }
      );

      return res || {};
    } catch (e) {
      return {};
    }
  };

  public scanTrxAlpha = async ({
    tx_hash,
  }: {
    tx_hash: string;
  }): Promise<any> => {
    try {
      const rs = await this.apiClient.get(
        this.getUrl(`sync/alpha-keys/scan-transaction-hash`),
        {
          params: {
            tx_hash,
            network: 'nos',
          },
        }
      );
      this.limitScanTX = DEFAULT_LIMIT_RETRY_SCAN;
      return rs;
    } catch (e) {
      this.limitScanTX--;
      if (this.limitScanTX > 0) {
        delay(1000);
        await this.scanTrxAlpha({ tx_hash });
      }
      // throw e;
    }
  };

  /**
   * getKeys
   */
  public getKeys = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const res = (await this.apiClient.get(
        this.getUrl(`player-share/tokens`),
        {
          params: {
            network: 'nos',
            ...params,
          },
        }
      )) as IResponseTradeKeys;

      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  /**
   * getKeys
   */
  public getKeysV1 = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const res = (await this.apiClient.get(
        this.getUrl(`player-share/tokensv1`),
        {
          params: {
            network: 'nos',
            ...params,
            side: '1',
          },
        }
      )) as IResponseTradeKeys;

      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  /**
   * getTopPlaceholdersConverted
   */
  public getTopPlaceholdersConverted = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const res = (await this.apiClient.get(
        this.getUrl(`player-share/tokens/converted`),
        {
          params: {
            network: 'nos',
            ...params,
          },
        }
      )) as IResponseTradeKeys;

      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  /**
   * getTopPlaceholdersSAConverted
   */
  public getTopPlaceholdersSAConverted = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const res = (await this.apiClient.get(
        this.getUrl(`player-share/tokens/sa/converted`),
        {
          params: {
            network: 'nos',
            ...params,
          },
        }
      )) as IResponseTradeKeys;

      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  /**
   * sendTx
   */
  public sendTx = async ({
    contract_address,
    data_hex,
  }: {
    contract_address: string;
    data_hex: any;
  }) => {
    try {
      const res = await this.apiClient.post(
        `${PERP_API_URL}/nos/${isProduction() ? 'mainnet' : 'testnet'}/send-tx`,
        {
          contract_address,
          data_hex,
        }
      );
      return res;
    } catch (error) {
      return error;
    }
  };

  /**
   * openLimitOrders
   */
  public openLimitOrders = async (
    params: IPagingParams & { address: string }
  ): Promise<IResponseOrders> => {
    try {
      const res: IResponseOrders = await this.apiClient.get(
        this.getUrl(`alpha-keys/limit-order/list`),
        {
          params: {
            ...params,
            network: 'nos',
          },
        }
      );
      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };

  /**
   * limitOrder
   */
  public limitOrder = async (body: ILimitOrder, address: string) => {
    try {
      const res = await this.apiClient.post(
        this.getUrl(`alpha-keys/limit-order`),
        {
          ...body,
        },
        {
          params: {
            address,
            network: 'nos',
          },
        }
      );
      return res;
    } catch (error) {
      console.log('error', error);

      return true;
    }
  };

  /**
   * faucetTC
   */
  public faucetTC = async (params: IRequestBase) => {
    try {
      const res = await this.apiClient.post(
        this.getUrl('alpha-keys/user/faucet-tc'),
        {},
        {
          params,
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getWatchList
   */
  public getCopyTradeList = async (
    params: IGetWatchListParams
  ): Promise<IResponseCopyTradeList> => {
    try {
      const response: IResponseCopyTradeList = await this.apiClient.get(
        this.getUrl(`alpha-keys/watchlist/list`),
        {
          params: {
            ...params,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getWatchList
   */
  public getWatchList = async (
    params: IGetWatchListParams
  ): Promise<IResponseWatchList> => {
    try {
      const response: IResponseWatchList = await this.apiClient.get(
        this.getUrl(`follow/following/v2`),
        {
          params: {
            ...params,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getWatchList
   */
  public getUserPortfolio = async (
    params: IRequestBase & {
      player_address: string;
    }
  ): Promise<IWatchList> => {
    try {
      const response: IWatchList = await this.apiClient.get(
        this.getUrl(`alpha-keys/user/portfolio`),
        {
          params: {
            ...params,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  public getSummaryReport = async (): Promise<IWatchList> => {
    try {
      const response: IWatchList = await this.apiClient.get(
        this.getUrl(`alpha-keys/summary-report`),
        {
          params: {
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  public getLocation = async (): Promise<ILocation> => {
    try {
      const response: any = await axios.get('https://geolocation-db.com/json/');

      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getKeys
   */
  public getPassTokens = async (
    params: any
  ): Promise<any> => {
    try {
      const res = (await this.apiClient.get(
        this.getUrl(`nbc-keys/pass-tokens`),
        {
          params: {
            network: 'nos',
            ...params,
          },
        }
      )) as IResponseTradeKeys;

      return res;
    } catch (error) {
      return {
        count: 0,
        rows: [],
      };
    }
  };
}

export default CTradeAPI;
