import { API_TOPUP } from '@/configs';
import { ITokenMultiChain, Token, TokenPriceMap } from '@/state/tokens/types';
import { apiClient } from '.';
import CacheManager from './cache';
import { isProduction } from '@/utils/commons';
import { LIST_TOKEN_DEFAULT_TESTNET } from '@/state/wallets/constants';
import { TOKEN_ADDRESS } from '@/constants/token';

const API_PATH = API_TOPUP + '/api';

export const getListTokenMultichain = async (): Promise<ITokenMultiChain[]> => {
  try {
    const res: ITokenMultiChain[] = await apiClient.get(
      `${API_PATH}/list-token-multichain`
    );
    return res;
  } catch (error) {
    console.log('[getListTokenMultichain] error: ', error);
    return [];
  }
};

export const getAllTokensAPI = async (): Promise<Token[]> => {
  try {
    const res: Token[] = await apiClient.get(`${API_PATH}/tokens`);
    return res;
  } catch (error) {
    console.log('[getAllTokensAPI] error: ', error);
    return [];
  }
};

export const getTokensPriceAPI = async (): Promise<TokenPriceMap> => {
  try {
    if (isProduction()) {
      const res: TokenPriceMap = await apiClient.get(
        `${API_PATH}/list-token-price`
      );

      return {
        ...res,
        '0x0000000000000000000000000000000000000000':
          res['0x43bda480de297a14cec95bfb1c6a313615f809ef'],
        '0xdac17f958d2ee523a2206206994597c13d831ec7':
          res['0xf1612388d43a6b00316ca05ca358bc1a2e7b8e97'],
      };
    } else {
      return {
        [TOKEN_ADDRESS.BTC_ADDRESS_L2]: 37372.25,
        [TOKEN_ADDRESS.ETH_ADDRESS_L2]: 2006.5400390625,
        [TOKEN_ADDRESS.SOL_ADDRESS_L2]: 56.83,
        [TOKEN_ADDRESS.PEPE_ADDRESS_L2]: 0.00000117,
        [TOKEN_ADDRESS.USDT_ADDRESS_L2]: 0.998806,
      };
    }
  } catch (error) {
    console.log('[getTokensPriceAPI] error: ', error);
    return {};
  }
};

export const getCacheAllTokens = async (): Promise<Token[]> => {
  const result: Token[] = await CacheManager.cachePromise({
    key: CacheManager.KEYS.ALL_TOKENS,
    promiseFunc: () => getAllTokensAPI(),
    expiredTime: CacheManager.EXPIRED_TIME.ALL_TOKENS,
  });
  return result;
};

export const getCacheTokenPrice = async (): Promise<TokenPriceMap> => {
  const result: TokenPriceMap = await CacheManager.cachePromise({
    key: CacheManager.KEYS.TOKENS_PRICE,
    promiseFunc: () => getTokensPriceAPI(),
    expiredTime: CacheManager.EXPIRED_TIME.TOKENS_PRICE,
  });
  return result;
};

export const getCacheListTokenMultichain = async (): Promise<
  ITokenMultiChain[]
> => {
  const result: ITokenMultiChain[] = await CacheManager.cachePromise({
    key: CacheManager.KEYS.TOKEN_MULTI_CHAIN,
    promiseFunc: () => getListTokenMultichain(),
    expiredTime: CacheManager.EXPIRED_TIME.TOKEN_MULTI_CHAIN,
  });
  return result;
};
