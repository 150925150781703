import { API_DGAMES } from '@/configs';
import {
  ChatRoomResponse,
  ChatTribeResponse,
  GetRoomPayload,
} from '@/interfaces/api/chat';
import { apiClient } from '@/services';
import { unifyChatTribe } from '@/services/chat/helpers';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import qs from 'query-string';

const API_PATH = 'v1/chat-group';

export const getChatGroup = async (
  payload: GetRoomPayload
): Promise<ChatRoomResponse[]> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/community/list?${query}`
    );
    const dataFormatted = Object(camelCaseKeys(res));
    // return {
    //   total: dataFormatted.total,
    //   rooms: (dataFormatted.rooms as ChatTribeResponse[]).map(item =>
    //     unifyChatTribe(item)
    //   ),
    // };
    return (dataFormatted.rooms as ChatTribeResponse[]).map(item =>
      unifyChatTribe(item)
    );
  } catch (err: unknown) {
    return [];
  }
};

export const getChatTribes = async (
  payload: GetRoomPayload
): Promise<{
  rooms: ChatRoomResponse[];
  total: number;
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/tribe/list?${query}`
    );
    const dataFormatted = Object(camelCaseKeys(res));
    return {
      total: dataFormatted.total,
      rooms: (dataFormatted.rooms as ChatTribeResponse[]).map(item =>
        unifyChatTribe(item)
      ),
    };
  } catch (err: unknown) {
    return {
      total: 0,
      rooms: [],
    };
  }
};

export const leaveTribe = async (
  roomId: string
): Promise<Record<string, boolean> | undefined> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/tribe/leave`, {
      roomId,
    });
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    return;
  }
};

export const searchTribe = async (payload: {
  search: string;
  page: number;
  limit: number;
}): Promise<any> => {
  try {
    const query = qs.stringify(payload);
    const res = (await apiClient.get(
      `${API_DGAMES}/${API_PATH}/tribe/search?${query}`
    )) as { rooms: Array<any> };
    return res?.rooms || [];
  } catch (err: unknown) {
    return [];
  }
};

export const getSuggestionTribes = async (payload: {
  page: number;
  limit: number;
}): Promise<{ items: Array<any>; total: number }> => {
  try {
    const query = qs.stringify(payload);
    const res = (await apiClient.get(
      `${API_DGAMES}/${API_PATH}/tribe/suggestion?${query}`
    )) as { items: Array<any>; total: number };
    return {
      items: res?.items || [],
      total: res?.total || 0,
    };
  } catch (err: unknown) {
    return {
      items: [],
      total: 0,
    };
  }
};

export const getSuggestionTribesPublic = async (payload: {
  page: number;
  limit: number;
}): Promise<{ items: Array<any>; total: number }> => {
  try {
    const query = qs.stringify(payload);
    const res = (await apiClient.get(
      `${API_DGAMES}/${API_PATH}/tribe/suggestion-public-api?${query}`
    )) as { items: Array<any>; total: number };
    return {
      items: res?.items || [],
      total: res?.total || 0,
    };
  } catch (err: unknown) {
    return {
      items: [],
      total: 0,
    };
  }
};
