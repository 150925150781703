/*
  Update NameSticker && EMOJI_STICKERS
*/

import LottieWrapper from '@/components/Lottie';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS, CDN_URL_STICKERS } from '@/configs';
import LottieBlue from '@/modules/AlphaPWA/Home/PostItem/Emoji/Blue';
import ReactionBue from '@/modules/AlphaPWA/Home/PostItem/Emoji/Blue/reaction-bue';
import LottieGM from '@/modules/AlphaPWA/Home/PostItem/Emoji/Gm';
import ReactionGm from '@/modules/AlphaPWA/Home/PostItem/Emoji/Gm/reaction-gm';
import LottieGreen from '@/modules/AlphaPWA/Home/PostItem/Emoji/Green';
import ReactionGreen from '@/modules/AlphaPWA/Home/PostItem/Emoji/Green/reaction-green';
import LottieOrange from '@/modules/AlphaPWA/Home/PostItem/Emoji/Orange';
import ReactionOrange from '@/modules/AlphaPWA/Home/PostItem/Emoji/Orange/reaction-orange';
import ReactionRed from '@/modules/AlphaPWA/Home/PostItem/Emoji/Red/reaction-red';
import LottieYellow from '@/modules/AlphaPWA/Home/PostItem/Emoji/Yellow';
import ReactionYellow from '@/modules/AlphaPWA/Home/PostItem/Emoji/Yellow/reaction-yellow';
import ReactionHallowin from '@/modules/AlphaPWA/Home/PostItem/Emoji/Hallowin/reaction-hallowin';
import LottieHallowin from '@/modules/AlphaPWA/Home/PostItem/Emoji/Hallowin';
import LottieRed from '@/modules/AlphaPWA/Home/PostItem/Emoji/Red';
import { ReactElement } from 'react';
import StickerWebp from '@/components/StickerWebp';

export enum COLLECTION {
  DEFAULT = 'Default',
  WHALE = 'Whale',
  HALLOWEEN = 'Halloween',
  // New Collection = 'New',
}

export enum NameSticker {
  'love' = 0,
  'fire' = 1,
  'gm' = 2,
  'bitcoin' = 3,
  'pepe' = 4,
  'fart' = 5,
  'redpacket' = 6,
  'pranksy' = 7,
  'dingalingts' = 8,
  'HerroCrypto' = 9,
  'redpacketparty' = 10,
  'vombat' = 11,
  'LFG' = 12,
  'lutherin' = 13,
  'anonymoux' = 14,
  'kaleo' = 15,
  'pawel' = 16,
  'degod' = 17,
  'wombat' = 18,
  'pump' = 19,
  'scientist' = 20,
  'starcraft2' = 21,
  'ecap' = 22,
  'incognito' = 23,
  'farmers' = 24,
  'udi' = 25,
  'cyrus' = 26,
  'shark' = 27,
  'CryptoGrayX' = 28,
  'pokeeeh' = 29,
  'dracula' = 30,
  'web' = 31,
  'skull' = 32,
  'ghost' = 33,
  'spider' = 34,
  'ma' = 35,
  'bat' = 36,
  'pumpkin' = 37,
  'reetika' = 38,
  'madvincentv2' = 39,
  'anya' = 40,
  'goodnight' = 41,
  'cryptoClay' = 42,
  'chef' = 43,
  'lol' = 44,
  'spaceship' = 45,
}

export const getStickerName = (type: number) => {
  const indexOfS = Object.values(NameSticker).indexOf(
    type as unknown as NameSticker
  );

  const key = Object.keys(NameSticker)[indexOfS];
  return key;
};

const EMOJI_SIZE = 34;

export const EMOJI_REACTIONS = [
  {
    type: 0,
    icon: (): React.ReactElement => {
      return <ReactionRed />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/RED.json`}
        />
      );
    },
  },
  {
    type: 1,
    icon: (): React.ReactElement => {
      return <ReactionYellow />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/YELLOW.json`}
        />
      );
    },
  },
  {
    type: 2,
    icon: (): React.ReactElement => {
      return <ReactionGm />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/GM.json`}
        />
      );
    },
  },
  {
    type: 3,
    icon: (): React.ReactElement => {
      return <ReactionOrange />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/ORANGE.json`}
        />
      );
    },
  },
  {
    type: 4,
    icon: (): React.ReactElement => {
      return <ReactionGreen />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/GREEN.json`}
        />
      );
    },
  },
  {
    type: 5,
    icon: (): React.ReactElement => {
      return <ReactionBue />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || EMOJI_SIZE}
          src={`${CDN_URL_STICKERS}/emojis/BLUE.json`}
        />
      );
    },
  },
  // {
  //   type: 6,
  //   icon: (): React.ReactElement => {
  //     return <ReactionHallowin />;
  //   },
  //   lottie: (size?: number): React.ReactElement => {
  //     return <LottieHallowin size={size || 34} />;
  //   },
  //   hide: true,
  // },
];

const STICKER_SIZE = 160;

type TEMOJI_STICKERS = {
  type: number;
  icon: () => ReactElement;
  lottie?: (size?: number) => ReactElement;
  webp?: (size?: number) => ReactElement;
  collection: string;
  disabled?: boolean;
};

export const EMOJI_STICKERS: TEMOJI_STICKERS[] = [
  {
    type: 11,
    icon: (): React.ReactElement => {
      return <ReactionBue />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-wombat-2.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-wombat-2.webp`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
    disabled: true,
  },
  {
    type: 18,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-wombat-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-wombat-animation.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-wombat.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 7,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/pransky.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pranksy.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pranksy.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 16,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-pawel-trader-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pawel-trader-animation.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pawel.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },

  {
    type: 24,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-farmers.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-farmers.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-farmers.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 23,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-incognito.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-Incognito.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-incognito.webp`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 21,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-starcraft.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-starcraft2.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-starcraft2.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 20,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-scientist.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-scientist.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-scientist.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 22,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-ecap.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-ecap.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-ecap.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },

  {
    type: 0,
    icon: (): React.ReactElement => {
      return <ReactionRed />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-love-2.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-love.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 1,
    icon: (): React.ReactElement => {
      return <ReactionYellow />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-fire.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-fire.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 2,
    icon: (): React.ReactElement => {
      return <ReactionGm />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-gm.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-gm.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 3,
    icon: (): React.ReactElement => {
      return <ReactionOrange />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-bitcoin.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-bitcoin.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 4,
    icon: (): React.ReactElement => {
      return <ReactionGreen />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pepe.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pepe.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },

  {
    type: 5,
    icon: (): React.ReactElement => {
      return <ReactionBue />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-fart.json`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
    disabled: true,
  },
  {
    type: 6,
    icon: (): React.ReactElement => {
      return (
        <img
          src={`${CDN_URL_ICONS}/ic-read-envelop.png`}
          alt=""
          width={18}
          height={18}
        ></img>
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-redpacket.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-redpacket.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },

  {
    type: 8,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/dingaling.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-dingaling-1.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-dingalings.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 9,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/herro.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-herro.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-HerroCrypto.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },

  {
    type: 10,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_ICONS}/sticker-party_optimized.gif`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-redPacketParty.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-redpacketparty.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 12,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/LFG-svg.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-LFG.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-LFG.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 13,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/lutherin-svg.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-lutherin-1.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-lutherin.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 14,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-anonymoux-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-anonymoux-animation-v1-update.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-anonymoux.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 15,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-kaleo-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-kaleo-animation-v1-update.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-kaleo.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },

  {
    type: 19,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-pump-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pump-animation.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pump.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 17,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/sticker-degod-media.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-degod-animation-v1-update.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-degod.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 25,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-udi.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-udi.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-udi.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 26,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-cyrus.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-cyrus.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-cyrus.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 27,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-shark.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-shark.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-shark.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 28,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-CryptoGrayX.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-CryptoGrayX.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-cryptoGrayX.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 29,
    icon: (): React.ReactElement => {
      return (
        <img
          src={`${CDN_URL_STICKERS}/ic-pokeeeh.png`}
          alt=""
          width={18}
          height={18}
        ></img>
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pokeeeh.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pokeeeh.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 30,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-dracula.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-dracula.json`}
        />
      );
    },

    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 31,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-web.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-web-1.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 32,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-skull.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-skull.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 33,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-ghost.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-ghost.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-ghost.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 34,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-spider.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-spider-1.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 35,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-ma.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-ma.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 36,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-bat.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-bat.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 37,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-pumpkin.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-pumpkin.json`}
        />
      );
    },
    collection: COLLECTION.HALLOWEEN,
  },
  {
    type: 38,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-reetika.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-reetika.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-reetika.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 39,
    icon: (): React.ReactElement => {
      return (
        <SvgInset
          size={16}
          svgUrl={`${CDN_URL_STICKERS}/ic-madvincentv2.svg`}
        />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-madvincentv2.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-madvincentv2.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },

  {
    type: 40,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-anya.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-anya.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-anya.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 41,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-goodnight.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-goodnight.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-goodnight.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 42,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-cryptoClay.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-cryptoClay.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-cryptoClay.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
  {
    type: 43,
    icon: (): React.ReactElement => {
      return <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-chef.svg`} />;
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-chef.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-chef.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 44,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-lol_v2.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-lol_v2.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-lol.gif`}
        />
      );
    },
    collection: COLLECTION.DEFAULT,
  },
  {
    type: 45,
    icon: (): React.ReactElement => {
      return (
        <SvgInset size={16} svgUrl={`${CDN_URL_STICKERS}/ic-spaceship-1.svg`} />
      );
    },
    lottie: (size?: number): React.ReactElement => {
      return (
        <LottieWrapper
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-spaceship.json`}
        />
      );
    },
    webp: (size?: number): ReactElement => {
      return (
        <StickerWebp
          size={size || STICKER_SIZE}
          src={`${CDN_URL_STICKERS}/sticker-spaceship.gif`}
        />
      );
    },
    collection: COLLECTION.WHALE,
  },
];
