import { CDN_URL_ICONS } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import useCountdown from '@/hooks/useCountdown';
import { isLocalHost, isProduction } from '@/utils/commons';
import { Box, Flex, ModalBody, ModalHeader, Text } from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import s from './TopupModal.module.scss';
import { BiChevronLeft } from 'react-icons/bi';
import SvgInset from '@/components/SvgInset';
import { useRouter } from 'next/router';
import useOpenDepositModal from '@/hooks/useOpenDepositModal';
import { OptionType } from './Topup.types';
import DepositItem from './components/DepositItem';

export const TOPUP_MODAL_L2 = 'TOPUP_MODAL_L2';

export enum Option {
  ft = 100,
  SA = 101,
  mobile = 102,
  desktop = 103,
}

const Options = [
  {
    name: 'Deposit on mobile',
    value: Option.mobile,
    icon: `${CDN_URL_ICONS}/ic_mobile.svg`,
  },
  {
    name: 'Deposit on desktop',
    value: Option.desktop,
    icon: `${CDN_URL_ICONS}/ic_desktop.svg`,
  },
  {
    name: 'Deposit from Stars Arena',
    value: Option.SA,
    icon: `${CDN_URL_ICONS}/ic_sa.svg`,
  },
  {
    name: 'Deposit from Friend Tech',
    value: Option.ft,
    icon: `${CDN_URL_ICONS}/ic_ft.svg`,
  },
];

const TopUpModal = ({
  otp,
  expiredTime,
}: {
  otp: string;
  expiredTime: string;
}) => {
  const router = useRouter();
  const [option, setOption] = useState<Option | undefined>(undefined);
  const { hideModal } = useOpenDepositModal();
  const { hours, minutes, seconds } = useCountdown(expiredTime);
  const origin = useMemo(() => {
    if (isLocalHost()) {
      return window.location.origin;
    }
    return isProduction() ? 'https://alpha.wtf' : 'https://dev.alpha.wtf';
  }, []);

  const urlDeposit = useMemo(() => `${origin}${ROUTE_PATH.DEPOSIT}`, []);

  const onClickCopy = (text: string) => {
    copy(text);
    toast.remove();
    toast.success('Copied');
  };

  const renderOptionDesktop = () => {
    return (
      <Flex direction="column">
        <div className={s.block_wrapper}>
          <Text
            fontSize={'14px'}
            lineHeight={'160%'}
            fontWeight={'600'}
            mb="8px"
          >
            1. Open the link on desktop browser
          </Text>
          <Flex
            direction="row"
            mb="8px"
            mt="8px"
            justify="space-between"
            backgroundColor={'rgba(248, 240, 172, 0.2)'}
            padding="8px 16px"
            alignItems="center"
            border="1px solid #F8F0AC"
            borderRadius="50px"
          >
            <Text
              fontSize={'12px'}
              lineHeight={'160%'}
              fontWeight={'500'}
              textDecor={'underline'}
              color={'#4185EC'}
              width="fit-content"
              onClick={() => onClickCopy(urlDeposit)}
            >
              {urlDeposit}
            </Text>
            <SvgInset
              svgUrl={`${CDN_URL_ICONS}/ion_copy.svg`}
              size={20}
              className={s.copy_icon}
              onClick={() => onClickCopy(urlDeposit)}
            />
          </Flex>
        </div>
        <div className={s.block_wrapper}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text fontSize={'14px'} lineHeight={'160%'} fontWeight={'600'}>
              2. Enter this OTP code
            </Text>

            {seconds && (
              <Flex alignItems={'center'} gap="4px">
                <Text
                  fontSize={'12px'}
                  fontWeight={'400'}
                  minW="50px"
                >{`Expires in ${hours} : ${minutes} : ${seconds}`}</Text>
              </Flex>
            )}
          </Flex>
          <Box
            textAlign={'center'}
            fontSize={'40px'}
            fontWeight={700}
            mt="16px"
            borderRadius={'8px'}
            letterSpacing={'8px'}
            bgColor={'#F4F4F4'}
            color="#4185EC"
          >
            {otp}
          </Box>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="12px"
        >
          <BiChevronLeft color="white" fontSize={20} fontWeight={700} />
          <Text
            align="center"
            cursor="pointer"
            onClick={() => {
              setOption(undefined);
            }}
          >
            Change deposit method
          </Text>
        </Box>
      </Flex>
    );
  };

  const itemOnClick = (item: OptionType) => {
    switch (item.value) {
      case Option.ft:
      case Option.SA:
      case Option.mobile: {
        setOption(undefined);
        router.push(
          `${ROUTE_PATH.DEPOSIT}?code=${otp}&mode=mobile&type=${item.value}`
        );
        hideModal();
        break;
      }
      case Option.desktop:
        setOption(Option.desktop);
        break;
      default:
        break;
    }
  };

  const renderOptions = () => {
    return (
      <Flex direction="column" gap="12px">
        {Options.map(item => (
          <DepositItem item={item} onClickItem={item => itemOnClick(item)} />
        ))}
      </Flex>
    );
  };

  return (
    <div className={s.wrapper}>
      <ModalHeader fontSize="24px" fontWeight={'600'} mb="16px">
        {option === Option.desktop ? (
          <Box textAlign={'center'}>Deposit on desktop</Box>
        ) : (
          <Box textAlign={'center'}>Deposit to Bitcoin L2</Box>
        )}
        <Text
          align="center"
          fontSize={'14px'}
          mt={'8px'}
          lineHeight={'160%'}
          fontWeight={'600'}
        >
          Easily deposit from Stars Arena and Friend Tech.
        </Text>
        <Text
          align="center"
          fontSize={'14px'}
          lineHeight={'160%'}
          fontWeight={'600'}
        >
          BTC, ETH, SOL and AVAX are accepted.
        </Text>
      </ModalHeader>
      <ModalBody>
        {option === Option.desktop ? renderOptionDesktop() : renderOptions()}
      </ModalBody>
    </div>
  );
};

export default React.memo(TopUpModal);
