import { ModalBody, ModalFooter } from '@chakra-ui/react';
import React from 'react';
import s from './InstructionalPracticeModal.module.scss';
import { Flex, FormLabel } from '@chakra-ui/react';

export const INSTRUCTIONAL_PRACTICE_MODAL_ID =
  'INSTRUCTIONAL_PRACTICE_MODAL_ID';

const InstructionalPracticeModal: React.FunctionComponent<{
  descriptionUrl: string;
  clickPlayGame: (...args: any[]) => void;
}> = ({ descriptionUrl, clickPlayGame }) => {
  return (
    <div className={s.wrapper}>
      <iframe
        src={`${descriptionUrl}${window.location.search}`}
        width="100%"
        className={s.iframe}
      />
      <button onClick={clickPlayGame} className={s.practiceBtn}>
        Practice Now
      </button>
    </div>
  );
};

export default React.memo(InstructionalPracticeModal);
