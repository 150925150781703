import { Token } from '@/state/tokens/types';
import { WalletState } from './types';
import { isProduction } from '@/utils/commons';
import { TOKEN_ADDRESS } from '@/constants/token';

export const PREFIX = 'wallets';

export const LIST_TOKEN_DEFAULT_MAINNET: Token[] = [
  {
    symbol: 'BTC',
    tcTokenID: '0x111808abe352c8003e0effcc04998eab26cebe3c',
    network: 'bitcoin',
    name: 'Bitcoin',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/BTC.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 8,
  },
  {
    symbol: 'ETH',
    tcTokenID: '0x43bda480de297a14cec95bfb1c6a313615f809ef',
    network: 'ethereum',
    name: 'Ethereum',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/ETH.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 18,
  },
  {
    symbol: 'USDT',
    tcTokenID: '0xf1612388d43a6b00316ca05ca358bc1a2e7b8e97',
    network: 'ethereum',
    name: 'Tether USD',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/USDT.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 6,
  },
  {
    symbol: 'SOL',
    tcTokenID: '0xca0ea534e2a7e116be739f4ccc1121515ce6f56a',
    network: 'solana',
    name: 'Solana',
    decimals: 9,
    logo: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/solana-sol-icon.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 53.79999923706055,
    tcLayer: 2,
    type: 'ERC20',
  },
  // {
  //   symbol: 'PEPE',
  //   tcTokenID: '0xdc0155d60db4f9deee0bde67b3cf5f72ef716c20',
  //   network: 'ethereum',
  //   name: 'Pepe token',
  //   logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/PEPE.png',
  //   isDefaultAlphaDeposit: false,
  //   orderAlphaDeposit: 0,
  //   priceUsd: 0,
  //   tcLayer: 2,
  //   type: 'ERC20',
  //   decimals: 18,
  // },
];

export const LIST_TOKEN_DEFAULT_TESTNET: Token[] = [
  {
    symbol: 'BTC',
    tcTokenID: '0x1d45c32C97707C916b350042CEAC2164fb6D00A1',
    network: 'bitcoin',
    name: 'Bitcoin',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/BTC.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 8,
  },
  {
    symbol: 'ETH',
    tcTokenID: '0x0FBa66555B74F13809862BD6f15FffA0A0237059',
    network: 'ethereum',
    name: 'Ethereum',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/ETH.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 18,
  },
  {
    symbol: 'USDT',
    tcTokenID: '0x5B68dEd386C681232a1308eD1eCC68c997792055',
    network: 'ethereum',
    name: 'Tether USD',
    logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/USDT.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 0,
    tcLayer: 2,
    type: 'ERC20',
    decimals: 6,
  },
  {
    symbol: 'SOL',
    tcTokenID: '0x8066FBcC2E49fBc531De3d6739306fa04e09862D',
    network: 'solana',
    name: 'Solana',
    decimals: 9,
    logo: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/solana-sol-icon.png',
    isDefaultAlphaDeposit: false,
    orderAlphaDeposit: 0,
    priceUsd: 53.79999923706055,
    tcLayer: 2,
    type: 'ERC20',
  },
  // {
  //   symbol: 'PEPE',
  //   tcTokenID: '0x53fB3204757d007961bC995A97D2b9AD7565FCDc',
  //   network: 'ethereum',
  //   name: 'Pepe token',
  //   logo: 'https://cdn.trustless.computer/cmc/symbols/128x128/PEPE.png',
  //   isDefaultAlphaDeposit: false,
  //   orderAlphaDeposit: 0,
  //   priceUsd: 0,
  //   tcLayer: 2,
  //   type: 'ERC20',
  //   decimals: 18,
  // },
];

export const LIST_TOKENS_CANT_REMOVE = [
  TOKEN_ADDRESS.BTC_ADDRESS_L2,
  TOKEN_ADDRESS.ETH_ADDRESS_L2,
];

export const LIST_TOKEN_DEFAULT = isProduction()
  ? LIST_TOKEN_DEFAULT_MAINNET
  : LIST_TOKEN_DEFAULT_TESTNET;
