/* eslint-disable react-hooks/exhaustive-deps */
import AppLoading from '@/components/AppLoading';
import { ROUTE_PATH } from '@/constants/route-path';
import accountStorage from '@/utils/account.storage';
import { isWebApp } from '@/utils/client';
import { Center, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import s from './styles.module.scss';

export interface IUIContext {
  disableGotoDetail: boolean;
  setDisableGotoDetail: (b: boolean) => void;
}

const initialValue: IUIContext = {
  disableGotoDetail: false,
  setDisableGotoDetail: _b => null,
};

export const UIContext = React.createContext<IUIContext>(initialValue);

export const UIProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [disableGotoDetail, setDisableGotoDetail] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const router = useRouter();
  const address = accountStorage.getAddress();
  const isPWA = isWebApp();

  const isAuthenticated = !!address && !!accountStorage.isUserLinkingTwitter;
  const isAlphaScreen = router.pathname === ROUTE_PATH.ALPHA_MOBILE;
  const isWelcomeScreen = router.pathname === ROUTE_PATH.ALPHA_MOBILE_LINK_TW;
  const isAppScreen = router.pathname === '/app';

  useEffect(() => {
    if (isPWA) {
      if (isWelcomeScreen || isAppScreen || isAlphaScreen) {
        if (!isAuthenticated) {
          router.replace(ROUTE_PATH.ALPHA_MOBILE_HOME).finally(() => {
            setInitialLoading(false);
          });
        } else {
          router.replace(ROUTE_PATH.ALPHA_MOBILE_KEYS).finally(() => {
            setInitialLoading(false);
          });
        }
      } else {
        setInitialLoading(false);
      }
    } else {
      setInitialLoading(false);
    }
  }, []);

  const contextValues = useMemo((): IUIContext => {
    return {
      disableGotoDetail: disableGotoDetail,
      setDisableGotoDetail: setDisableGotoDetail,
    };
  }, [disableGotoDetail, setDisableGotoDetail]);

  return (
    <UIContext.Provider value={contextValues}>
      {initialLoading ? (
        <Center className={s.initialLoading} height={window.innerHeight}>
          <AppLoading />
        </Center>
      ) : (
        children
      )}
    </UIContext.Provider>
  );
};
