import React, { useContext } from 'react';
import * as S from './styled';
import { WalletContext } from '@/contexts/wallet-context';
import { useAppDispatch } from '@/state/hooks';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { closeModal } from '@/state/modal';

export const LOGIN_ACCOUNT_MODAL_L2 = 'LOGIN_ACCOUNT_MODAL_L2';

interface IProps {
  isSavePass: boolean;
}
const LoginAccount = React.memo(({ isSavePass }: IProps) => {
  const [pass, setPass] = useState('');

  const { onLoginL2 } = useContext(WalletContext);
  const dispatch = useAppDispatch();

  const handleSubmit = async (): Promise<void> => {
    onLoginL2(pass, isSavePass);
    dispatch(closeModal({ id: LOGIN_ACCOUNT_MODAL_L2 }));
  };

  return (
    <S.Content>
      <S.PasscodeContainer>
        <p className="passcodeTitle">PASSCODE</p>
        <OtpInput
          value={pass}
          onChange={setPass}
          numInputs={4}
          renderSeparator={<S.PasscodeSeparator />}
          renderInput={props => <S.PasscodeInput {...props} />}
          containerStyle={{ width: '100%' }}
          inputType="number"
        />
      </S.PasscodeContainer>
      <S.ButtonCreate onClick={handleSubmit}>Login</S.ButtonCreate>
    </S.Content>
  );
});

LoginAccount.displayName = 'LoginAccount';

export default LoginAccount;
