import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import Logger from '../Logger';

class WebSocket {
  private host: string;
  private path: string;
  private ws?: Socket;
  private token: string;

  private sendEvents: any[] = [];
  private subscribeEvents: any[] = [];

  constructor(_host: string, _path: string, _token: string) {
    this.host = _host;
    this.path = _path;
    this.token = _token;

    Logger.info('WebSocket: constructor ', this.host, this.path);

    this.init();
  }

  public get isConnected(): boolean {
    return !!this.ws?.connected;
  }

  init(): void {
    Logger.info('WebSocket: init ', this.host, this.path);
    this.createConnection();
  }

  disconnect(): void {
    this.ws?.disconnect();
  }

  createConnection(): void {
    Logger.info('WebSocket: createConnection ', this.host, this.path);
    const opts: Partial<ManagerOptions & SocketOptions> = {
      auth: {
        token: this.token,
      },
      reconnectionDelay: 30000,
      transports: ['websocket'],
    };
    if (this.path !== '') {
      opts.path = this.path;
    }
    Logger.info(
      'WebSocket: createConnection with anonymous user',
      `${this.host}/${this.path}`,
      opts
    );
    this.ws = io(`${this.host}`, opts);
  }

  addEventListener(eventName: string, handler: (...rest: any) => void) {
    Logger.info('WebSocket: addEventListener', eventName);
    this.subscribeEvents.push([eventName, handler]);
    this.ws?.on(eventName, handler);
    Logger.info('WebSocket: addEventListener', eventName, 'succeed');
  }

  removeEventListener(eventName: string, handler: (...rest: any) => void) {
    Logger.info('WebSocket: removeEventListener', eventName);
    this.ws?.off(eventName, handler);
    Logger.info('WebSocket: removeEventListener', eventName, 'succeed');
  }

  sendEmit(event: string | symbol, ...args: any[]) {
    Logger.info('WebSocket: sendEmit', event, ...args);
    this.sendEvents.push([event, args]);
    this.ws?.emit(event as any, ...args);
    Logger.info('WebSocket: sendEmit', event, ...args, 'succeed');
  }

  onAny(handler: (eventName: string, ...args: any) => void) {
    this.ws?.onAny(handler);
  }

  handleReconnect() {
    setTimeout(() => {
      this.sendEvents.forEach(item => {
        Logger.info('WebSocket: reconnect sendEmit', item[0], ...item[1]);
        this.ws?.emit(item[0], ...item[1]);
        Logger.info(
          'WebSocket: reconnect sendEmit',
          item[0],
          ...item[1],
          'succeed'
        );
      });

      // this.subscribeEvents.forEach(item => {
      //   Logger.info('WebSocket: reconnect addEventListener', item[0]);
      //   this.ws?.on(item[0], item[1]);
      //   Logger.info(
      //     'WebSocket:reconnect  addEventListener',
      //     item[0],
      //     'succeed'
      //   );
      // });
    }, 100);
  }
}

export default WebSocket;
