import { API_FOC } from '@/configs';
import {
  IGenerateMessagePayload,
  IGenerateSignaturePayload,
  IGenerateSignatureResponse,
  IGetAddressFromCodeResponse,
} from '@/interfaces/api/signature';

import createAxiosInstance from '@/services/http-client';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

const focClient = createAxiosInstance({
  baseURL: API_FOC,
});

export const generateMessage = async (
  payload: IGenerateMessagePayload
): Promise<string> => {
  const res = await focClient.post(`/signature/otp/nonce`, payload) as string;
  return res;
};

export const verifySignature = async (
  payload: IGenerateSignaturePayload
): Promise<IGenerateSignatureResponse> => {
  const res = await focClient.post(`/signature/otp`, payload);
  return Object(camelCaseKeys(res));
};

export const getOtpByWalletAddress = async (walletAddress: string): Promise<IGenerateSignatureResponse | null> => {
  try {
    const res = await focClient.get(`/signature/otp/wallet_address/${walletAddress}`) as string;
    return Object(camelCaseKeys(res));
  } catch (err) {
    return null;
  }
}

export const getAddressFromCode = async ({
  code,
}: {
  code: string;
}): Promise<IGetAddressFromCodeResponse> => {
  const res = await focClient.get(`/signature/otp/${code}`);
  return Object(camelCaseKeys(res));
};
