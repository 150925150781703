import { Text } from '@chakra-ui/react';
import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import PasswordIcon from '@/components/PassWord';
import px2rem from '@/utils/px2rem';
import { Row } from 'react-bootstrap';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .row-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-t {
    font-size: ${px2rem(12)};
    font-weight: 600;
    line-height: ${px2rem(20)};
    letter-spacing: 0em;
    text-align: left;
    font-family: var(--chakra-fonts-sora);
    color: #cecece;
    margin-bottom: ${px2rem(6)};
  }

  .error {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff4747;
    margin-top: 4px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: ${px2rem(56)};
  padding-left: ${px2rem(16)};
  padding-right: ${px2rem(16)};

  background: #000000;
  border: 1px solid #353945;
  border-radius: ${px2rem(8)};

  :focus {
    border-color: #353945;
  }
  :hover {
    border-color: #353945;
  }

  .input-container {
    flex: 1;
    font-weight: 400;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};
    color: #ffffff;
    height: 100%;
    font-family: var(--chakra-fonts-sora);

    :-webkit-autofill {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: #191d23 !important;
    }
  }

  .icon-container {
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  refInput?: any;
  classContainer?: string | undefined;
  classInputWrapper?: string | undefined;
  classInput?: string | undefined;
  title?: string | undefined;
  errorMsg?: string;
  rightElement?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    type = 'text',
    value = '',
    placeholder = '',
    onChange = () => {},
    onKeyDown = () => {},
    refInput,
    classContainer,
    classInputWrapper,
    classInput,
    title,
    errorMsg,
    rightElement,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [currentType, setCurrentType] = useState<
    HTMLInputTypeAttribute | undefined
  >(type);
  const inputTypeInit = useRef<string>(type);

  useEffect(() => {
    inputTypeInit.current = type;
  }, []);

  const onClick = () => {
    setShowPassword(!showPassword);
    setCurrentType(currentType === 'text' ? 'password' : 'text');
  };

  return (
    <Container className={classContainer}>
      {!!title && <Text className="text-t">{title}</Text>}
      <div className="row-right">
        <InputWrapper className={classInputWrapper}>
          <input
            {...rest}
            ref={refInput}
            className={`input-container ${classInput}`}
            type={currentType}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            spellCheck="false"
            autoComplete="off"
          />

          {inputTypeInit.current === 'password' && (
            <div className="icon-container" onClick={onClick}>
              <PasswordIcon isShow={showPassword} />
            </div>
          )}
        </InputWrapper>
        {rightElement && rightElement}
      </div>
      {!!errorMsg && <Text className="error">{errorMsg}</Text>}
    </Container>
  );
};

export default Input;
