/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Spinner, Text } from '@chakra-ui/react';
import styles from './styles.module.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'next/router';
import { useAppSelector } from '@/state/hooks';
import { selectCommonReducer } from '@/state/common';
import { WalletContext } from '@/contexts/wallet-context';
import ListTable, { ColumnProp } from '@/components/ListTable';
import { getThreeThreeSuggestionList } from '@/services/three-three';
import clsx from 'clsx';
import { IThreeThreeInvitation, IUserInfo } from '@/interfaces/threethree';
import { abbreviateNumber, formatCurrency, formatName } from '@/utils';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter, settingMomentFromNow } from '@/utils/helpers';
import { ROUTE_PATH } from '@/constants/route-path';
import px2rem from '@/utils/px2rem';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import BtnSend33 from '@/modules/AlphaPWA/Profiles/TradeKey/components/profileHeaderUserInfo/btnSend33';
import moment from 'moment';

const LIMIT_PAGE = 50;

settingMomentFromNow();

const ModuleHistory: React.FC<any> = () => {
  const { addressL2 } = useContext(WalletContext);
  const [data, setData] = useState<IThreeThreeInvitation[]>([]);
  const needReload = useAppSelector(selectCommonReducer).needReload;
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (addressL2) {
      let pageLimit = LIMIT_PAGE;
      if (params.pageHistory) {
        pageLimit = LIMIT_PAGE * Number(params.pageHistory);
      }
      fetchData(1, false, pageLimit);
    }
  }, [addressL2, needReload]);

  const fetchData = async (
    page = 1,
    isFetchMore = false,
    pageLimit = LIMIT_PAGE
  ) => {
    try {
      setIsFetching(true);
      const res = await getThreeThreeSuggestionList({
        address: addressL2 || '',
        page: page,
        limit: pageLimit,
      });
      router.replace({
        query: { ...router.query, pageHistory: page },
      });

      if (isFetchMore) {
        setData(prev => [...prev, ...res]);
      } else {
        setData(res);
      }
    } catch (e) {
    } finally {
      setIsFetching(false);
    }
  };

  const onLoadMoreTokens = () => {
    if (isFetching || data.length % LIMIT_PAGE !== 0) return;
    const page = Math.floor(data.length / LIMIT_PAGE) + 1;
    fetchData(page, true);
  };

  const debounceLoadMore = debounce(onLoadMoreTokens, 300);

  const onItemClick = (data: IUserInfo) => {
    const address = data?.address;

    if (!address) return;
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${address}`);
  };

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        id: 'player',
        label: 'Key',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IUserInfo, extraData: any, index: number) {
          return (
            <Flex
              gap={3}
              alignItems={'center'}
              width={'100%'}
              onClick={() => onItemClick(data)}
            >
              <Flex flex={1} gap={2} alignItems={'center'}>
                <Avatar
                  url={getUrlAvatarTwitter(
                    (data?.twitter_avatar || data?.avatar) as string,
                    'normal',
                    Boolean(data?.twitter_avatar)
                  )}
                  address={data.address}
                  width={32}
                />
                <Flex width={'100%'} flex={1} gap={2} direction={'column'}>
                  <Flex
                    alignItems={'center'}
                    maxWidth={'100%'}
                    width={'86%'}
                    flex={1}
                    onClick={() =>
                      router.push(
                        `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.address}`
                      )
                    }
                    gap={2}
                  >
                    <Text className={styles.buyer}>
                      {formatName(data?.twitter_name as string, 50)}
                    </Text>
                  </Flex>
                  {data?.activity === '3_3' ? (
                    <Flex alignItems={'center'} gap={2}>
                      <Text
                        color={'#000000'}
                        fontSize={px2rem(12)}
                        opacity={'70%'}
                      >
                        used (3,3)
                      </Text>
                      ·
                      <Text
                        color={'#000000'}
                        fontSize={px2rem(12)}
                        opacity={'70%'}
                      >
                        {`${moment(data?.transaction_date).fromNow()}`}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex alignItems={'center'} gap={2}>
                      {/* <Text
                          color={'#000000'}
                          fontSize={px2rem(12)}
                          opacity={'70%'}
                        >
                          Vol{' '}
                          {formatCurrency(
                            data?.total_volume,
                            0,
                            6,
                            data.base_token_symbol
                          )}{' '}
                          {data.base_token_symbol}
                        </Text>
                        · */}
                      <Flex gap={1}>
                        <Text
                          color={'#000000'}
                          fontSize={px2rem(12)}
                          opacity={'70%'}
                        >
                          {abbreviateNumber(data.twitter_followers_count)}{' '}
                          follower
                          {labelAmountOrNumberAdds(
                            data.twitter_followers_count
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        id: 'tradingVolume',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IUserInfo) {
          return (
            <Flex
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
              onClick={() => onItemClick(data)}
            >
              <Text className={styles.buyer}>
                {formatCurrency(data?.buy_price, 0, 6, data.base_token_symbol)}{' '}
                {data.base_token_symbol}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                ${formatCurrency(data?.usd_price, 0, 2)}
              </Text>
            </Flex>
          );
        },
      },
      {
        id: 'twitter_followers_count',
        label: ' ',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IUserInfo) {
          const profile = data;
          const tokenDetail = {
            address: data.token_address,
            userTwitterName: data.twitter_name,
          };
          return (
            <Flex alignItems={'center'} justifyContent={'flex-end'} gap={2}>
              <BtnSend33
                profile={profile}
                tokenDetail={tokenDetail}
                className={styles.earnButton}
              />
            </Flex>
          );
        },
      },
    ];
  }, [isSubmitting]);

  return (
    <Flex
      overflow={'hidden'}
      height={'100%'}
      className={clsx(styles.container)}
      direction={'column'}
    >
      <InfiniteScroll
        className={styles.tokensList}
        dataLength={data?.length || 0}
        hasMore={true}
        // height={"calc(100vh - env(safe-area-inset-top) - 73px - 87px - 39px)"}
        loader={
          isFetching && (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Spinner speed="0.65s" emptyColor="gray.200" color="blue.500" />
            </Flex>
          )
        }
        next={debounceLoadMore}
      >
        <ListTable
          data={data}
          columns={columns}
          // noHeader={true}
          // ItemListComponent={(row, extraData, columns, i) => {
          //   return (
          //     <div key={i}>
          //       <FeedItem item={row} />
          //     </div>
          //   );
          // }}
          showEmpty={!isFetching}
          emptyLabel={'There is no requests.'}
        />
      </InfiniteScroll>
    </Flex>
  );
};

export default ModuleHistory;
