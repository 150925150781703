export default function TimeIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99984 0.729004C3.54184 0.729004 0.729004 3.54184 0.729004 6.99984C0.729004 10.4578 3.54184 13.2707 6.99984 13.2707C10.4578 13.2707 13.2707 10.4578 13.2707 6.99984C13.2707 3.54184 10.4578 0.729004 6.99984 0.729004ZM6.99984 12.3957C4.02425 12.3957 1.604 9.97542 1.604 6.99984C1.604 4.02425 4.02425 1.604 6.99984 1.604C9.97542 1.604 12.3957 4.02425 12.3957 6.99984C12.3957 9.97542 9.97542 12.3957 6.99984 12.3957ZM9.05902 8.44065C9.22994 8.61157 9.22994 8.88867 9.05902 9.05959C8.97385 9.14476 8.86184 9.18791 8.74984 9.18791C8.63784 9.18791 8.52582 9.14534 8.44065 9.05959L6.69065 7.30959C6.6084 7.22734 6.56234 7.11591 6.56234 7.00041V4.08374C6.56234 3.84224 6.75834 3.64624 6.99984 3.64624C7.24134 3.64624 7.43734 3.84224 7.43734 4.08374V6.81897L9.05902 8.44065Z"
        fill="#F8F0AC"
      />
    </svg>
  );
}
