import { compareString, formatCurrency } from '@/utils';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import IcSweepKey from './icSweepKey';
import IcSweepToken from './icSweepToken';
import s from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  passTokenReducer,
  setKeyAmountSweep,
  setTokenAmountSweep,
} from '@/state/passToken';

export const TOKEN_AMOUNTS = [10000, 20000, 100000, 1000000];
export const KEY_AMOUNTS = [1, 2, 5, 10];

const BottomBarSetting = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'open-settings' });
  const {
    isOpen: isOpenToken,
    onOpen: onOpenToken,
    onClose: onCloseToken,
  } = useDisclosure({ id: 'open-settings-token' });
  const {
    isOpen: isOpenKey,
    onOpen: onOpenKey,
    onClose: onCloseKey,
  } = useDisclosure({ id: 'open-settings-key' });
  const dispatch = useDispatch();
  const tokenAmountSweep = useSelector(passTokenReducer).tokenAmountSweep;
  const keyAmountSweep = useSelector(passTokenReducer).keyAmountSweep;

  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onOpen}
      >
        <rect width="20" height="20" rx="10" fill="white" fill-opacity="0.2" />
        <g opacity="0.5">
          <path
            d="M10 11.25C10.8284 11.25 11.5 10.5784 11.5 9.75C11.5 8.92157 10.8284 8.25 10 8.25C9.17157 8.25 8.5 8.92157 8.5 9.75C8.5 10.5784 9.17157 11.25 10 11.25Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.2534 12.125C13.8609 12.79 13.4194 12.98 12.5853 12.695C12.0456 12.505 11.4078 12.8375 11.3097 13.4075C11.1625 14.2625 10.77 14.5 9.98497 14.5C9.19996 14.5 8.80746 14.215 8.66027 13.4075C8.56215 12.8375 7.92433 12.505 7.38464 12.695C6.55058 12.98 6.10901 12.79 5.71651 12.125C5.32401 11.5075 5.4712 10.985 6.10901 10.4625C6.55058 10.0825 6.50151 9.4175 6.05995 9.085C5.42214 8.4675 5.37307 8.04 5.76557 7.375C6.15808 6.7575 6.6487 6.615 7.43371 6.8525C7.9734 7.0425 8.56215 6.6625 8.66027 6.14C8.85652 5.285 9.19996 5 10.034 5C10.77 5 11.1625 5.38 11.3097 6.1875C11.4078 6.71 12.0456 7.0425 12.5853 6.8525C13.4194 6.52 13.8609 6.71 14.2534 7.375C14.6459 8.04 14.5969 8.4675 13.91 9.0375C13.4684 9.4175 13.4684 10.0825 13.91 10.4625C14.5969 11.0325 14.6459 11.46 14.2534 12.125Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>

      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Sweep settings</DrawerHeader>
          <DrawerBody>
            <Flex className={s.itemSetting}>
              <Flex alignItems={'center'} gap={'8px'}>
                <IcSweepToken />
                <Text className={s.title}>Sweep Token Amount</Text>
              </Flex>
              <Popover
                isOpen={isOpenToken}
                onClose={onCloseToken}
                placement="top-end"
              >
                <PopoverTrigger>
                  <Button onClick={onOpenToken} className={s.btnChangeAmount}>
                    {formatCurrency(tokenAmountSweep, 0, 2)}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Flex className={s.itemAmounts}>
                      {TOKEN_AMOUNTS.map(a => (
                        <Flex
                          onClick={() => {
                            dispatch(setTokenAmountSweep(a));
                            onCloseToken();
                          }}
                          className={clsx(
                            s.itemAmount,
                            compareString(tokenAmountSweep, a) &&
                              s.itemAmountActive
                          )}
                        >
                          <Text>{formatCurrency(a, 0, 2)}</Text>
                        </Flex>
                      ))}
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            <Flex className={s.itemSetting}>
              <Flex alignItems={'center'} gap={'8px'}>
                <IcSweepKey />
                <Text className={s.title}>Sweep Key Amount</Text>
              </Flex>
              <Popover
                isOpen={isOpenKey}
                onClose={onCloseKey}
                placement="top-end"
              >
                <PopoverTrigger>
                  <Button onClick={onOpenKey} className={s.btnChangeAmount}>
                    {formatCurrency(keyAmountSweep, 0, 2)}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Flex className={s.itemAmounts}>
                      {KEY_AMOUNTS.map(a => (
                        <Flex
                          onClick={() => {
                            dispatch(setKeyAmountSweep(a));
                            onCloseKey();
                          }}
                          className={clsx(
                            s.itemAmount,
                            compareString(keyAmountSweep, a) &&
                              s.itemAmountActive
                          )}
                        >
                          <Text>{formatCurrency(a, 0, 2)}</Text>
                        </Flex>
                      ))}
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BottomBarSetting;
