export enum EUniSupportChains {
  EtherChain = 'ethchain',
}

export enum UniSwapEnvironment {
  LOCAL,
  MAINNET,
}

export interface UniSwapWalletConfig {
  address: string;
  privateKey: string;
}

export interface IUniSwapCurrentConfig {
  env: UniSwapEnvironment;
  rpc: {
    local: string;
    mainnet: string;
  };
  chainID: number;
  wallet?: UniSwapWalletConfig;
}

export interface IUniSwapTokens {
  tokenIn: string;
  tokenOut: string;
}

export interface IUniSwapQuoteAmountOut extends IUniSwapTokens {
  amount: string;
  owner_circle_address: string;
}

export interface IUniSwapTokenSwapParams extends IUniSwapQuoteAmountOut {}

export interface IUniSwapToken {
  address: string;
  decimals: number;
  symbols: string;
  name: string;
  isNative?: boolean;
  balance?: string;
  balanceFormated?: string;
}

export interface IUniSwapEstimateResponse {
  amount: string;
  gasPrice: string;
  poolInfo: any;
}
