import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import { GameFiContext } from '@/contexts/game-fi-context';
import { TournamentContext } from '@/contexts/tournament-context';
import { WalletContext } from '@/contexts/wallet-context';
import withAlphaMiddleware from '@/hocs/withAlphaMiddleware';
import { IGameType } from '@/hooks/useContentWindowMessage';
import { IGameItem } from '@/interfaces/api/gamefi';
import { getGameList, getIsBlockCountryIP } from '@/services/gamefi';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import useAnalyticsEventTracker, { GameActions } from '@/utils/ga';
import { Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDM } from '../DirectMessage/provider';
import CircleTournament from './CircleTournament';
import InstructionalPracticeModal, {
  INSTRUCTIONAL_PRACTICE_MODAL_ID,
} from './InstructionalPracticeModal';
import {
  GameName,
  UnityIFrameStatus,
  WombatCombat,
  gameNameToId,
} from './constants';
import s from './styles.module.scss';
import { delay } from '@/utils/helpers';

export const _isUnityGame = (gameName: string): boolean => {
  return gameName != 'Connect Meme';
};

const GameScreen: React.FC = (): React.ReactElement => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState<Array<IGameItem>>([]);
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();
  const firstRender = useRef(true);
  const dm = useDM();

  const [isBlock, setIsBlock] = useState<boolean | undefined>(undefined);

  const { updateIsPlayingGame, releaseIframe, unityIframeStatus, openGame } =
    useContext(GameFiContext);

  const { balanceL2 } = useContext(AssetsContext);
  const { addressL2 } = useContext(WalletContext);

  const { isTournament, circleAddress, extra } = router.query as {
    isTournament: string;
    circleAddress: string;
    extra: string;
  };

  const { gameInfo, circleTournamentInfo, setCircleAddress, tournamentConfig } =
    useContext(TournamentContext);

  useEffect(() => {
    // console.log('isTournament:', isTournament);
    if (isTournament && circleAddress) {
      setCircleAddress(circleAddress);
    }
  }, [isTournament, circleAddress, balanceL2]);

  useEffect(() => {
    return () => {
      // if (!firstRender.current) {
      //   console.log('game_unmount');
      //   releaseIframe();
      // } else {
      //   firstRender.current = false;
      // }
      // console.log('game_unmount');
      releaseIframe();
    };
  }, []);

  useEffect(() => {
    handleGetIsBlockCountryIP();
  }, []);

  const handleGetIsBlockCountryIP = async () => {
    try {
      const data = await getIsBlockCountryIP();
      setIsBlock(data);
    } catch (error) {
      setIsBlock(false);
    }
  };

  const fetchData = async () => {
    const res = await getGameList();

    var games = res.games.map(e => {
      return {
        ...e,
        id: gameNameToId(e.name),
        isUnityGame: _isUnityGame(e.name),
      };
    });

    // if (isTournament) {
    //   games = games.filter(e => e.gameUrl == gameInfo?.gameUrl);

    //   if (games[0] && games[0]?.name == GameName.PEPE) {
    //     games = [
    //       {
    //         name: WombatCombat,
    //         thumbnailUrl:
    //           'https://cdn-dev.gamefi.garden/unity-build/wombat/metadata/poster.jpg',
    //         gameUrl: games[0]?.gameUrl,
    //         numOfPlays: 0,
    //         description:
    //           'https://cdn-dev.gamefi.garden/unity-build/pepe/metadata/instruction/Pepe_Instruction.html',
    //         descriptionText:
    //           'Overpower your opponents with clever placement of Wombats. Compete and earn rewards on a global scale.',
    //         isUnityGame: true,
    //         id: IGameType.PepeFight,
    //       },
    //     ];
    //   }
    // } else {
    //   games = games.filter(e => e.name != GameName.CONNECT_MEME);
    // }

    setGames(games);
    setLoading(false);
  };

  const handlePractice = (game: IGameItem) => {
    if (isTournament) {
      gaEventTracker(
        GameActions.TournamentOpenPractice,
        JSON.stringify(circleTournamentInfo)
      );
    } else {
      gaEventTracker(GameActions.OpenPracticeGame, game.name);
    }

    dispatch(
      openModal({
        id: INSTRUCTIONAL_PRACTICE_MODAL_ID,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <InstructionalPracticeModal
            descriptionUrl={game.description}
            clickPlayGame={async () => {
              dispatch(closeModal({ id: INSTRUCTIONAL_PRACTICE_MODAL_ID }));

              // console.log('updateIsPlayingGame::::::::::', game);

              updateIsPlayingGame(UnityIFrameStatus.LOADING, {
                game: game,
                mode: 'practice',
                circleAddress: circleAddress,
                circleTournamentInfo: circleTournamentInfo,
                extra: extra,
              });

              if (unityIframeStatus == UnityIFrameStatus.PENDING) {
                openGame();
              }

              if (isTournament) {
                gaEventTracker(
                  GameActions.TournamentPlayPractice,
                  JSON.stringify(circleTournamentInfo)
                );
              } else {
                gaEventTracker(GameActions.PlayPracticeGame, game.name);
              }
            }}
          />
        ),
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [isTournament]);

  const onBack = () => {
    if (window.history.length > 1) {
      router.back();
    } else {
      router.push(ROUTE_PATH.ALPHA_MOBILE_DM);
    }
  };

  return (
    <>
      {loading ? (
        <div className={s.loadingWrapper}>
          <Spinner size={'xl'} color="blue.500" />
        </div>
      ) : (
        <div className={s.gameScreen}>
          <div className={s.backAction} onClick={onBack}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M14.4001 16.8L9.6001 12L14.4001 7.20005"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {isTournament == 'true' && (
            <CircleTournament games={games} isCountryBlocked={isBlock} />
          )}
          {
            <div className={s.gameWrapper}>
              {!isBlock && isTournament == 'true' && (
                <h1 className={s.heading}>
                  <p>New to the game?</p>
                  <p>Hone your skills here before joining the tournament!</p>
                </h1>
              )}
              <ul
                className={s.gameList}
                style={{ paddingLeft: 12, paddingRight: 12 }}
              >
                {games
                  .filter(e => e.name != GameName.CONNECT_MEME)
                  .filter(
                    e =>
                      (isTournament === 'true' &&
                        e.gameUrl == gameInfo?.gameUrl) ||
                      isTournament === 'false'
                  )
                  .map((game, index) => {
                    return (
                      <li
                        key={game.gameUrl}
                        className={s.gameItem}
                        onClick={() => handlePractice(game)}
                      >
                        <div className={s.gameInfoWrapper}>
                          <div className={s.gameInfoContent}>
                            <img
                              className={s.thumbnailImg}
                              src={game.thumbnailUrl}
                              alt={'thumbnail'}
                            />
                            <div className={s.gameInfo}>
                              <p className={s.name}>{game.name}</p>
                              <p className={s.desc}>{game.descriptionText}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default withAlphaMiddleware(GameScreen);
