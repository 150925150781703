import createAxiosInstance from '@/services/http-client';

const errorHttp = createAxiosInstance({
  baseURL: 'https://alpha-api.newbitcoincity.com',
});

const ERROR_LOGGER_TYPE = {
  WITHDRAW: 'WITHDRAW',
  SWEEP_KEYS: 'SWEEP_KEYS',
  UNITY_LOAD: 'UNITY_LOAD',
  BUY_TC: 'BUY_TC',
  FAUCET_TC: 'FAUCET_TC',
  STAKING: 'STAKING',
  RED_PACKAGE: 'RED_PACKAGE',
  STAKING_TOKENS: 'STAKING_TOKENS',
  TRANSFER_TOKEN_ON_ETHEREUM: 'TRANSFER_TOKEN_ON_ETHEREUM',
  TRANSFER_TOKEN_ON_NOS: 'TRANSFER_TOKEN_ON_NOS',
};

export const ERROR_LOGGER_LEVEL = {
  Error: 'Error',
  Info: 'Info',
  Warning: 'Warning',
};

const ERROR_PERSISTOR_LOGGER_TYPE = {
  CHAT_ROOM: 'PERSISTOR_CHAT_ROOM',
};

interface IErrorLoggerPayload {
  address?: string;
  action: string | keyof typeof ERROR_LOGGER_TYPE;
  error: any;
  info?: any;
  extra?: any;
  level?: string | keyof typeof ERROR_LOGGER_LEVEL;
}

const report = async (payload: IErrorLoggerPayload) => {
  try {
    await errorHttp.post(
      `/api/client-log?network=nos&address=${payload.address}`,
      {
        action: payload.action,
        error: payload.error,
        info: payload.info,
        extra: payload.extra,
        level: payload.level,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const errorLogger = {
  report,
  ERROR_LOGGER_TYPE,
  ERROR_PERSISTOR_LOGGER_TYPE,
};

export default errorLogger;
