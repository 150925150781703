import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ITokenMultiChain, Token, TokenPriceMap, TokenState } from './types';
import { getCacheListTokenDeposit } from '@/services/topup';
import {
  getAllTokensAPI,
  getCacheAllTokens,
  getCacheListTokenMultichain,
  getCacheTokenPrice,
  getListTokenMultichain,
} from '@/services/tokens';

export const PREFIX = 'TOKENS';

const setTokens: CaseReducer<TokenState, PayloadAction<Token[]>> = (
  state,
  action
) => {
  state.tokenList = action.payload;
};

const setTokenPriceMap: CaseReducer<
  TokenState,
  PayloadAction<TokenPriceMap>
> = (state, action) => {
  state.tokenPriceMap = action.payload;
};

const fetchTokenDeposit: any = createAsyncThunk(
  `${PREFIX}/fetchTokenDeposit`,
  async () => {
    const tokenList: Token[] = await getCacheListTokenDeposit();
    return tokenList;
  }
);

const fetchAllTokens: any = createAsyncThunk(
  `${PREFIX}/fetchAllTokens`,
  async () => {
    const tokenList: Token[] = await getAllTokensAPI();
    return tokenList;
  }
);

const fetchTokenMultiChain: any = createAsyncThunk(
  `${PREFIX}/fetchTokenMultiChain`,
  async () => {
    const tokenMultiChainList: ITokenMultiChain[] =
      await getCacheListTokenMultichain();
    return tokenMultiChainList;
  }
);

const fetchTokenPrice: any = createAsyncThunk(
  `${PREFIX}/fetchTokenPrice`,
  async () => {
    const tokenPrice: TokenPriceMap = await getCacheTokenPrice();
    return tokenPrice;
  }
);

const actionCreators = {
  setTokens,
  setTokenPriceMap,
};

// Export Pure Actions
export { actionCreators };
// Export Async Actions
export {
  fetchTokenDeposit,
  fetchAllTokens,
  fetchTokenMultiChain,
  fetchTokenPrice,
};
