import { Flex } from '@chakra-ui/react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import AppLoading from '../AppLoading';
import s from './ScrollWrapper.module.scss';

interface props {
  children: React.ReactNode;
  onFetch: () => void;
  isFetching: boolean;
  hasIncrementedPageRef: any;
  onFetchNewData: () => void;
  onScroll?: (e: any) => void;
  className?: string
}

const ScrollWrapper = forwardRef((props: props, ref) => {
  const {
    children,
    isFetching,
    hasIncrementedPageRef,
    onFetch,
    onFetchNewData,
    onScroll,
    className = '',
  } = props;

  // fetching data on scroll
  const feedContainerRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    if (feedContainerRef.current) {
      const container = feedContainerRef.current;
      const isScrolledToBottom =
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - container.scrollHeight / 4;

      if (isScrolledToBottom && !isFetching && !hasIncrementedPageRef.current) {
        onFetch();
      }
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onScrollTo: (scrollTo: any) => {
          if (feedContainerRef.current) {
            return feedContainerRef.current?.scrollTo({
              left: 0,
              top: scrollTo,
              behavior: 'smooth',
            });
          }
        },
        currentScrollOffset: feedContainerRef.current?.scrollTop,
      };
    },
    [feedContainerRef.current]
  );

  const handleTouchStart = (e: any) => {
    const touchStartY = e.touches[0].clientY;
    let hasScrolled = false;

    const handleTouchMove = (e: any) => {
      const touchCurrentY = e.touches[0].clientY;
      const scrollOffset = touchCurrentY - touchStartY;

      // Check if the user has scrolled down
      if (scrollOffset > 0 && !hasScrolled) {
        hasScrolled = true;
      }

      // If the user has scrolled down, prevent default to allow scrolling
      if (hasScrolled) {
        e.preventDefault();
      }
    };

    const handleTouchEnd = () => {
      if (hasScrolled) {
        onFetchNewData();
      }

      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  useEffect(() => {
    if (feedContainerRef.current) {
      feedContainerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (feedContainerRef.current) {
        feedContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFetching]);

  const handleRefresh = async () => {
    onFetchNewData();
  };

  return (
    <PullToRefresh
      onRefresh={handleRefresh}
      pullingContent={''}
      refreshingContent={
        <Flex alignItems={'center'} justifyContent={'center'}>
          <AppLoading
            style={{
              with: '40px',
              height: '40px',
            }}
          />
        </Flex>
      }
      className={`${s.refreshWrapper} ${className}`}
    >
      <div
        className={s.wrapperScroll}
        ref={feedContainerRef}
        onScroll={e => onScroll?.(e)}
        // onTouchStart={handleTouchStart}
      >
        {children}
      </div>
    </PullToRefresh>
  );
});

export default ScrollWrapper;
