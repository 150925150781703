export const ROUTE_PATH = {
  // Website
  NOT_FOUND: '/404',
  HOME: '/',
  GAMEFI: '/mega-whales',
  MEGA_REFERRAL: '/mega-whales-referral',
  SOCIAL: '/join-alpha',
  DEFI: '/defi',
  NFTS: '/nfts',
  GM_SOULS: '/souls',
  GM: '/souls/gm',
  ORPHANAGE: '/souls/orphanage',
  TECH: '/souls/tech',
  ART: '/souls/art',
  CONNECT_WALLET: '/connect-wallet',
  CLAIM_GM: '/claim-gm',
  BRIDGE_GM: '/bridge-gm',
  SOULS: '/souls',
  PIONEERS: '/pioneers',
  STORY: '/story',
  BUILDER: '/builder',
  AI: '/ai',
  BLOG: '/blog',
  DEPOSIT: '/deposit',
  TOPUP: '/topup',
  DEVELOPER: '/developers',
  TRUSTLESS_COMPUTER: '/tc',
  VERIFY_FRIEND_TECH: '/friend-tech',
  DELEGATE: '/verify',

  // Mega Whales PWA
  MEGA_WHALES_MOBILE: '/megawhales',
  MEGA_WHALES_MOBILE_WELCOME: '/megawhales/welcome',
  MEGA_WHALES_MOBILE_DEPOSIT: '/megawhales/deposit',
  MEGA_WHALES_MOBILE_IMPORT_KEY: '/megawhales/import-private-key',
  MEGA_WHALES_MOBILE_PROFILE: '/megawhales/profile',
  MEGA_WHALES_MOBILE_LEADERBOARD: '/megawhales/leaderboard',
  MEGA_WHALES_MOBILE_GAMES: '/megawhales/games',
  MEGA_WHALES_MOBILE_SHARE: '/megawhales/player-shares',
  MEGA_WHALES_MOBILE_PLAY: '/megawhales/play',
  MEGA_WHALES_MOBILE_FEED: '/megawhales/feed',
  MEGA_WHALES_MOBILE_PRE_LAUNCH: '/megawhales/pre-launch',
  MEGA_WHALES_MOBILE_PRE_LAUNCH_GAME: '/megawhales/pre-launch/game',
  MEGA_WHALES_MOBILE_PRE_LAUNCH_INVITE: '/megawhales/pre-launch/invite',
  MEGA_WHALES_MOBILE_MINE: '/megawhales/mine',

  // Mega Whales PWA
  ALPHA_MOBILE: '/alpha',
  ALPHA_MOBILE_WELCOME: '/alpha',
  ALPHA_MOBILE_DEPOSIT: '/alpha/deposit',
  ALPHA_MOBILE_IMPORT_KEY: '/alpha/import-private-key',
  ALPHA_MOBILE_PROFILE: '/alpha/profile',
  ALPHA_MOBILE_SHARE: '/alpha/player-shares',
  ALPHA_MOBILE_HOME: '/alpha/home',
  ALPHA_MOBILE_MINE: '/alpha/mine',
  ALPHA_MOBILE_NOTI: '/alpha/notification',
  ALPHA_TRADE: '/alpha/trade',
  ALPHA_TRADE_PRO: '/alpha/pro-trade',
  ALPHA_MOBILE_INITIAL: '/alpha/initial',
  ALPHA_MOBILE_TWEET_DETAIL: '/alpha/tweet',
  ALPHA_MOBILE_GAMES: '/alpha/games',
  ALPHA_MOBILE_PLAY: '/alpha/games/play',
  ALPHA_MOBILE_PRE_LAUNCH_GAME: '/alpha/pre-launch/game',
  ALPHA_MOBILE_KEYS: '/alpha/market',
  ALPHA_MOBILE_TOKENS: '/alpha/tokens',
  ALPHA_MOBILE_PLACE_HOLDER_KEYS: '/alpha/placeholder-keys',
  ALPHA_MOBILE_DM: '/alpha/dm',
  ALPHA_MOBILE_CHAT: '/alpha/chat',
  ALPHA_MOBILE_SHARES_DETAIL: '/alpha/shares-detail', //Keyholders
  ALPHA_MOBILE_TOKEN_HOLDERS_DETAIL: '/alpha/shares-token-detail', //tokenholders
  ALPHA_MOBILE_PORTFOLIO: '/alpha/portfolio',
  ALPHA_MOBILE_KEYS_INVITATIONS: '/alpha/keys-invitations',
  ALPHA_MOBILE_TRADE_LIMIT_ORDER: '/alpha/trade/limit-order',
  ALPHA_MOBILE_TRADE_OPEN_ORDER: '/alpha/trade/open-order',
  ALPHA_MOBILE_REFERRAL: '/alpha/referral',
  ALPHA_MOBILE_TWEET: '/alpha/tweet',
  ALPHA_MOBILE_WATCH_LIST: '/alpha/watch-list',
  ALPHA_MOBILE_CREATOR_CREATE: '/alpha/stake/create',
  ALPHA_MOBILE_STAKE_LIST: '/alpha/stake/list',
  ALPHA_MOBILE_STAKE_PORTFOLIO: '/alpha/stake/portfolio',
  ALPHA_MOBILE_STAKE_LISTING: '/alpha/stake/listing',
  ALPHA_MOBILE_SWAP: '/alpha/swap',
  ALPHA_MOBILE_BULL_BEAR: '/alpha/bull-bear',
  ALPHA_MOBILE_SPIN_WHEEL: '/alpha/spin-wheel',
  ALPHA_MOBILE_PLACE_HOLDER: '/alpha/placeholder',
  ALPHA_MOBILE_PLACE_HOLDER_HISTORY: '/alpha/placeholder/history',
  ALPHA_MOBILE_APPS: '/alpha/apps',
  ALPHA_LIST_POOLS: '/alpha/liquidity',
  ALPHA_ADD_POOLS: '/alpha/liquidity/add',
  ALPHA_REMOVE_POOLS: '/alpha/liquidity/remove',
  ALPHA_INCREASE_POOLS: '/alpha/liquidity/increase',
  ALPHA_IDO: '/alpha/ido',
  ALPHA_MOBILE_LINK_TW: '/alpha/welcome',
  ALPHA_MOBILE_PROFILE_KEYS: '/alpha/stake/profile-keys',
  ALPHA_MOBILE_PROFILE_TOKENS: '/alpha/stake/profile-tokens',
  ALPHA_MOBILE_GROW_EARN_LEADERBOARD: '/alpha/grow-n-earn',
  ALPHA_MOBILE_BLAST_LIST: '/alpha/blast-list',
};
