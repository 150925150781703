import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import Persistor from './Persistor';
import moment from 'moment';
import {
  getListPlayerPoolProfile,
  getPlayerPoolProfile,
} from '@/services/player-share';
import { chunk } from 'lodash';

const COLLECTION = 'PoolProfile';
const DATABASE_NAME = 'PoolProfile';

// 1 minutes
const REVALIDATE_TIME = [1, 'minutes'];
const BATCH_TIMEOUT = 10000; //10s

const KEY_PATH = 'stored_id';

type StoreProfileType = IGetPlayerPoolProfile & {
  writtenAt: number;
};

class PoolProfilePersistor extends Persistor {
  private timeOutManager: Record<string, ReturnType<typeof setTimeout>> = {};
  private batchAddressToRevalidate: string[] = [];
  private memoryCaches: Record<string, IGetPlayerPoolProfile | null> = {};
  private fetchingAddresses: Record<string, boolean | undefined> = {};

  constructor() {
    super(DATABASE_NAME, COLLECTION, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  public getMemoryCaches = (): Record<string, IGetPlayerPoolProfile | null> => {
    return this.memoryCaches;
  };

  private getRecord = async (
    storedId: string
  ): Promise<StoreProfileType | null> => {
    const cache = this.memoryCaches[
      `${storedId}`.toLowerCase()
    ] as StoreProfileType;
    if (cache) {
      return cache;
    }
    await this.get();

    return new Promise((resolve, reject) => {
      const collection = this.getCollection();
      const query = collection?.get(`${storedId}`.toLowerCase());

      if (query) {
        query.onsuccess = (event: any): void => {
          const storedItem = event.target.result;
          if (event.target.result) {
            this.memoryCaches[`${storedId}`.toLowerCase()] = storedItem;
          }
          resolve(storedItem);
        };

        query.onerror = (event): void => {
          reject(event);
        };
      }
    });
  };

  private retryUpdateNewData(address: string) {
    this.batchAddressToRevalidate = [
      ...this.batchAddressToRevalidate,
      `${address}`.toLowerCase(),
    ];
    this.timeOutManager[`${address}`.toLowerCase()] = setTimeout(() => {
      // one of any timeout fire will be call by list api and cleanup all timeout instances

      const uniqueAddress = Array.from(new Set(this.batchAddressToRevalidate));
      if (uniqueAddress.length) {
        chunk(uniqueAddress, 20).forEach(dataChunk => {
          getListPlayerPoolProfile(dataChunk).then(players => {
            players
              .filter(p => !!p)
              .forEach(player => {
                player &&
                  this.upsertItem(`${player?.address}`.toLowerCase(), player);
              });
          });
        });
      }

      this.batchAddressToRevalidate = [];
      Object.values(this.timeOutManager[`${address}`.toLowerCase()]).forEach(
        timeout => {
          if (timeout) {
            clearTimeout(timeout);
          }
        }
      );
    }, BATCH_TIMEOUT);
  }

  public getItem = async (
    address: string
  ): Promise<IGetPlayerPoolProfile | null> => {
    if (this.fetchingAddresses[`${address}`.toLowerCase()]) {
      return new Promise(resolve => {
        if (!this.fetchingAddresses[`${address}`.toLowerCase()]) {
          resolve(this.getItem(address));
        } else {
          setTimeout(() => {
            resolve(this.getItem(address));
          }, 50);
        }
      });
    }

    if ((this.fetchingAddresses[`${address}`.toLowerCase()] = undefined)) {
      this.fetchingAddresses[`${address}`.toLowerCase()] = true;
    }
    try {
      const record = await this.getRecord(address);
      if (record) {
        if (
          record.writtenAt &&
          moment(record.writtenAt)
            .add(...REVALIDATE_TIME)
            .isAfter(moment())
        ) {
          return record;
        }

        this.retryUpdateNewData(address);
        return record;
      }
      this.fetchingAddresses[`${address}`.toLowerCase()] = true;
      const player = await getPlayerPoolProfile(address as string);
      this.upsertItem(address, player);
      return player;
    } catch (e) {
      const cache = this.memoryCaches[
        `${address}`.toLowerCase()
      ] as StoreProfileType;
      if (cache) {
        if (this.fetchingAddresses[`${address}`.toLowerCase()]) {
          this.fetchingAddresses[`${address}`.toLowerCase()] = false;
        }
        return cache;
      }
      this.fetchingAddresses[`${address}`.toLowerCase()] = true;
      const player = await getPlayerPoolProfile(address as string);
      this.upsertItem(address, player);
      return player;
    }
  };

  private addItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    try {
      this.fetchingAddresses[`${address}`.toLowerCase()] = false;

      if (item) {
        this.memoryCaches[`${address}`.toLowerCase()] = item;
      }
      const record = await this.getRecord(address);

      if (record) {
        this.updateItem(`${address}`.toLowerCase(), item);
      } else {
        this.addItem(`${address}`.toLowerCase(), item);
      }
    } catch (e) {
      //
    } finally {
      //
    }
  };
}

const persistor = new PoolProfilePersistor();

export default persistor;
