import BigNumber from 'bignumber.js';

const NUMBER_TRANSACTIONS = 55; // number of transactions cover network fee
const GAS_PRICE = 3000000000; // 3 Gwei

const BUY_KEY_FEE = new BigNumber(0.0019).multipliedBy(1e18).toNumber();
const NORMAL_FEE = new BigNumber(21000).multipliedBy(GAS_PRICE).toNumber();

const SWAP_FEE = new BigNumber(500000).multipliedBy(GAS_PRICE).toNumber();

const APPROVE_FEE = new BigNumber(50000).multipliedBy(GAS_PRICE).toNumber();

const WITHDRAW_FEE = new BigNumber(APPROVE_FEE)
  .multipliedBy(2)
  .plus(SWAP_FEE)
  .toNumber();

// amount tc receive when swap btc to tc
const TC_NEEDS_SWAP_BTC2TC = new BigNumber(BUY_KEY_FEE)
  .multipliedBy(NUMBER_TRANSACTIONS)
  .toNumber();

const TC_THRESHOLD = new BigNumber(0.21).multipliedBy(1e18).toNumber();

const MIN_QUANTITY_OF_TC = new BigNumber(BUY_KEY_FEE)
  .multipliedBy(2) // account cover fee for 5 transactions
  .plus(WITHDRAW_FEE)
  .minus(APPROVE_FEE) // WITHDRAW_FEE cover 2 APPROVE_FEE
  .toNumber();

const TRANSACTION_FEE = {
  APPROVE_FEE,
  SWAP_FEE,
  WITHDRAW_FEE,
  BUY_KEY_FEE,
  TC_NEEDS_SWAP_BTC2TC,
  NORMAL_FEE,
  MIN_QUANTITY_OF_TC,
  TC_THRESHOLD,
};

export { TRANSACTION_FEE };

export const MINIMUM_BTC_AMOUNT_CAN_CLAIM = 0;
