import { TAPROOT_ADDRESS } from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';

class BitCoinStorage {
  CURRENT_TC_L1_CONNECTED = 'CURRENT_TC_L1_CONNECTED';

  private getUserTaprootKey = (evmAddress: string) => {
    return `${TAPROOT_ADDRESS}-${evmAddress?.toLowerCase()}`;
  };

  getUserTaprootAddress = (evmAddress: string): string | null => {
    const key = this.getUserTaprootKey(evmAddress);
    return localStorage.get<string | null>(key);
  };

  setUserTaprootAddress = (evmAddress: string, taprootAddress: string) => {
    const key = this.getUserTaprootKey(evmAddress);
    return localStorage.set(key, taprootAddress);
  };

  removeUserTaprootAddress = (evmAddress: string) => {
    const key = this.getUserTaprootKey(evmAddress);
    return localStorage.remove(key);
  };

  private getCurrentTCL1ConnectedKey = () => {
    return this.CURRENT_TC_L1_CONNECTED;
  };
  getCurrentTCL1Connected = () => {
    const key = this.getCurrentTCL1ConnectedKey();
    return localStorage.get(key);
  };
  setCurrentTCL1Connected = (tcAddress: string) => {
    const key = this.getCurrentTCL1ConnectedKey();
    return localStorage.set(key, tcAddress);
  };
  removeCurrentTCL1Connected = () => {
    const key = this.getCurrentTCL1ConnectedKey();
    return localStorage.remove(key);
  };
}

const instance = new BitCoinStorage();

export default instance;
