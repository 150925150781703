import React, { useContext, useMemo } from 'react';
import s from './WarningInfo.module.scss';
import { TournamentContext } from '@/contexts/tournament-context';
import Web3 from 'web3';

export const WARNING_INFO_MODAL_ID = 'WARNING_INFO_MODAL_ID';

interface IProps {
  onPlay: () => void;
}

const WarningInfoModel: React.FC<IProps> = ({ onPlay }): React.ReactElement => {
  const { circleTournamentInfo } = useContext(TournamentContext);

  const price = useMemo(() => {
    return Web3.utils.fromWei(
      circleTournamentInfo?.ticketPrice ?? '100000000000000',
      'ether'
    );
  }, [circleTournamentInfo]);

  const fee = useMemo(() => {
    return circleTournamentInfo?.feePercentage ?? 10;
  }, [circleTournamentInfo]);

  const additionalDuration = useMemo(() => {
    return (
      parseInt(circleTournamentInfo?.additionalDuration ?? '0') / 60
    ).toFixed(0);
  }, [circleTournamentInfo]);

  const remainDuration = useMemo(() => {
    var remainSeconds = ~~(
      parseInt(circleTournamentInfo?.endAt ?? '0') -
      Date.now() / 1000
    );

    if (remainSeconds >= 60) {
      return ~~(remainSeconds / 60) + 'm ' + (remainSeconds % 60) + 's';
    }

    return remainSeconds + 's';
  }, [circleTournamentInfo]);

  return (
    <div className={s.wrapper}>
      {/* <p className={s.titleHeader}>Warning</p> */}

      <div className={s.contentContainer}>
        <p className={s.titleHeader}>Only {remainDuration} left</p>
        <p className={s.title}>
          You might not have sufficient time to complete your game. Please take
          a moment to decide whether you'd like to proceed.
        </p>
      </div>

      <div className={s.contentContainer}>
        {/* <div className={s.descContainer}>
          <p className={s.content}>
            <span>&#8226;</span> If the new deadline falls after the initial
            hour, it extends by {additionalDuration} minutes.
          </p>
          <p className={s.example}>
            Example: If the initial deadline is 2:00 PM and a player is
            overtaken at 1:55 PM, the deadline becomes 2:10 PM.
          </p>
        </div> */}

        <button className={s.playButton} onClick={() => onPlay()}>
          <p>Play</p>
        </button>
      </div>
    </div>
  );
};

export default React.memo(WarningInfoModel);
