import { BTC_L2_ADDRESS, TVL_ADDRESS } from '@/configs';
import { TOKEN_ADDRESS } from '@/constants/token';
import { WalletContext } from '@/contexts/wallet-context';
import CPlayerShare from '@/contracts';
import CContract from '@/contracts/contract';
import useGetBalanceTC from '@/hooks/playerShareToken/useGetBalanceTC';
import useDebounce from '@/hooks/useDebounce';
import useIsMounted from '@/hooks/useIsMounted';
import useProviderL2 from '@/hooks/useProviderL2';
import {
  IAssetsDetailType,
  ICollectedUTXOResp,
  IFeeRate,
  ITxHistory,
} from '@/interfaces/api/bitcoin';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import {
  permissionUploadMedia,
  setBearerToken as setBearerTokenTweet,
} from '@/services/alpha_tweet';
import {
  getCacheTokensRate,
  getCollectedUTXO,
  getFeeRate,
  getPendingUTXOs,
} from '@/services/bitcoin';
import {
  getConfigs,
  getPlayerPoolProfile2,
  getTopLeaderBoards,
  postUserPoint,
  refreshToken,
  setBearerToken,
} from '@/services/player-share';
import { getReferralCode } from '@/services/referral';
import { selectCommonReducer } from '@/state/common';
import { useAppSelector } from '@/state/hooks';
import {
  fetchAllTokens,
  fetchTokenMultiChain,
  fetchTokenPrice,
} from '@/state/tokens/actions';
import { getUserSelector } from '@/state/user/selector';
import accountStorage from '@/utils/account.storage';
import { checkAmountTCThreshold } from '@/utils/convert-storage';
import { convertTcToChips } from '@/utils/format';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import {
  checkRateValid,
  formatAmountToClient,
  formatAmountToContract,
} from '@/utils/helpers';
import { comingAmountBuilder, currentAssetsBuilder } from '@/utils/utxo';
import { formatEthPriceInput } from '@trustless-computer/dapp-core';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { Wallet, ethers } from 'ethers';
import debounce from 'lodash/debounce';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from '@/state/hooks';
import { fetchNetworkList } from '@/state/networks/actions';
import useAsyncEffect from 'use-async-effect';

const INTERVAL_LOAD_BALANCE = 8 * 1000; // 8s
let INTERVAL_TIMER: any = undefined;

// import * as TC_SDK from 'trustless-computer-sdk';

const INITIAL_BALANCE_L2 = {
  isLoaded: false,
  amount: '0',
  amountFormated: '0',
  amountBlue: '0',
  amountRed: '0',
  amountWhite: '0',
  amountBTC: '0',
  amountBTCFormatted: '0',
};

export interface IAssetsContext {
  btcBalance: string;
  juiceBalance: string;
  currentAssets: ICollectedUTXOResp | undefined;
  assets: ICollectedUTXOResp | undefined;
  isLoadingAssets: boolean;
  isLoadedAssets: boolean;
  history: ITxHistory[];
  feeRate: IFeeRate;
  comingAmount: number;
  eth2btcRate: number;
  fetchAssets: () => void;
  debounceFetchData: () => void;
  fetchFeeRate: () => Promise<IFeeRate | undefined>;
  getAvailableAssetsCreateTx: () => Promise<ICollectedUTXOResp | undefined>;
  balanceL2: {
    isLoaded: boolean;
    amount: string;
    amountFormated: string;
    amountBlue: string;
    amountRed: string;
    amountWhite: string;
    amountBTC: string;
    amountBTCFormatted: string;
  };
  sharePoolAddr: string | null;
  sharePoolBalance: string | null;
  playerPoolProfile: IGetPlayerPoolProfile | null;
  isTop: boolean;
  getPlayerPoolProfileByAddress: () => Promise<void>;
  refreshTokenTwitter: () => Promise<void>;
  treasuryBalance: string;
  tvlBalance: string;
  referralCode?: string;
  fetchingReferralCode: boolean;
  getReferralCodeFromContext: () => void;
  totalBalanceObj: IAssetsDetailType;
  totalBalancebFetching?: boolean;
  rankingInfo: any;
}

const initialValue: IAssetsContext = {
  btcBalance: '0',
  juiceBalance: '0',
  currentAssets: undefined,
  assets: undefined,
  isLoadingAssets: false,
  isLoadedAssets: false,
  history: [],
  feeRate: {
    fastestFee: 25,
    halfHourFee: 20,
    hourFee: 15,
  },
  comingAmount: 0,
  eth2btcRate: 0,
  balanceL2: {
    ...INITIAL_BALANCE_L2,
  },
  treasuryBalance: '0',
  tvlBalance: '0',
  fetchAssets: () => new Promise<void>(r => r()),
  debounceFetchData: () => new Promise<void>(r => r()),
  fetchFeeRate: () => new Promise<IFeeRate | undefined>(() => null),
  getAvailableAssetsCreateTx: () =>
    new Promise<ICollectedUTXOResp | undefined>(() => null),
  sharePoolAddr: null,
  sharePoolBalance: null,
  playerPoolProfile: null,
  isTop: false,
  getPlayerPoolProfileByAddress: () => new Promise<void>(r => r()),
  refreshTokenTwitter: () => new Promise<void>(r => r()),
  referralCode: '',
  fetchingReferralCode: true,
  getReferralCodeFromContext: () => {},
  totalBalanceObj: {},
  totalBalancebFetching: false,
  rankingInfo: undefined,
};

export const AssetsContext = React.createContext<IAssetsContext>(initialValue);

export const AssetsProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const user = useAppSelector(getUserSelector);
  const currentAddress = React.useMemo(() => {
    return user?.walletAddressBtcTaproot || '';
  }, [user?.walletAddressBtcTaproot]);
  const { provider, account: tcAddress, connector } = useWeb3React();
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();

  const { addressL2, keySetL2, handleGetBTC2USD, handleGetEth2USD } =
    useContext(WalletContext);

  const providerL2 = useProviderL2();
  const [balanceL2, setBalanceL2] = React.useState({
    ...INITIAL_BALANCE_L2,
  });
  const [treasuryBalance, setTreasuryBalance] = useState('0');
  // UTXOs
  const [assets, setAssets] = useState<ICollectedUTXOResp | undefined>();
  const [currentAssets, setCurrentAssets] = useState<
    ICollectedUTXOResp | undefined
  >();
  const [isLoadingAssets, setIsLoadingAssets] = useState<boolean>(false);
  const [isLoadedAssets, setIsLoadedAssets] = useState<boolean>(false);
  // const [btcBalance, setBtcBalance] = useState('0');
  const [juiceBalance, setJuiceBalance] = useState('0');
  const [tvlBalance, setTvlBalance] = useState('0');

  const [isBalanceFetching, setBalanceFetching] = useState(false);
  const [totalBalanceObj, setTotalBalanceObj] = useState<IAssetsDetailType>({});

  // History
  const [history, setHistory] = useState<ITxHistory[]>([]);

  // Address by pool
  const [playerPoolProfile, setPlayerPoolProfile] =
    useState<IGetPlayerPoolProfile | null>(null);

  // Permission upload media on public post type
  const [isTop, setIsTop] = useState(false);

  const [poolAddr, setPoolAddr] = useState<string | null>(null);
  const [poolBalance, setPoolBalance] = useState<string | null>(null);
  const { getBalanceTC } = useGetBalanceTC();
  const timer = useRef<any>();

  // Fee rate
  const [feeRate, setFeeRate] = useState<IFeeRate>({
    fastestFee: 25,
    halfHourFee: 20,
    hourFee: 15,
  });
  const [comingAmount, setcomingAmount] = useState<number>(0);
  const [eth2btcRate, setEth2BtcRate] = useState<number>(0);

  const [referralCode, setReferralCode] = useState<string>('');
  const [fetchingReferralCode, setFetchingReferralCode] = useState(true);
  // const [prevBalance, setPrevBalance] = useState(new BigNumber(0));
  const gaEventTracker = useAnalyticsEventTracker();

  const PlayerShareContract = new CPlayerShare();
  const contract = new CContract();

  const needReload = useSelector(selectCommonReducer).needReload;

  const needReloadDebounced = useDebounce(needReload, 1000);
  const [rankingInfo, setRankingInfo] = useState<any>();

  const fetchRankingInfo = async () => {
    if (!addressL2) return;
    try {
      const res = await getTopLeaderBoards({
        page: 1,
        limit: 0,
        address: addressL2 as string,
      });
      setRankingInfo(res[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRankingInfo();
  }, [addressL2]);

  const fetchAssets = async (): Promise<ICollectedUTXOResp | undefined> => {
    if (!currentAddress || !tcAddress) return undefined;
    let _assets = undefined;
    try {
      setIsLoadingAssets(true);
      _assets = await getCollectedUTXO(currentAddress, tcAddress);
      setAssets(_assets);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingAssets(false);
      setIsLoadedAssets(true);
    }
    return _assets;
  };

  const fetchData = async () => {
    const [assets, pendingUTXOs] = await Promise.all([
      await fetchAssets(),
      await getPendingUTXOs(currentAddress),
    ]);

    // Current assets
    let _currentAssets = undefined;
    if (assets) {
      _currentAssets = currentAssetsBuilder({
        current: assets,
        pending: pendingUTXOs,
      });
    }
    setCurrentAssets(_currentAssets);

    // Coming amount...
    const _comingAmount = comingAmountBuilder(currentAddress, pendingUTXOs);
    setcomingAmount(_comingAmount);
  };

  const debounceFetchData = React.useCallback(debounce(fetchData, 300), [
    currentAddress,
    tcAddress,
  ]);

  const fetchFeeRate = async () => {
    let _feeRate = {
      fastestFee: 25,
      halfHourFee: 20,
      hourFee: 15,
    };
    try {
      _feeRate = await getFeeRate();
      setFeeRate(_feeRate);
    } catch (error) {
      setFeeRate(_feeRate);
    }
    return _feeRate;
  };

  const btcBalance = React.useMemo(() => {
    if (currentAddress) {
      // const utxos = await getBtcBalance(currentAddress);
      // const balance = TC_SDK.getBTCBalance({
      //   utxos: currentAssets?.txrefs || [],
      //   inscriptions: currentAssets?.inscriptions_by_outputs || {},
      // });

      // setBtcBalance(balance.toString());
      // return balance.toString();
      return '0';
    }
    return '0';
  }, [currentAddress, currentAssets]);

  const fetchJuiceBalance = async () => {
    if (tcAddress && provider) {
      const balance = await provider.getBalance(tcAddress);
      setJuiceBalance(balance.toString());
    }
  };

  const getAvailableAssetsCreateTx = async () => {
    const [assets, pendingUTXOs] = await Promise.all([
      await fetchAssets(),
      await getPendingUTXOs(currentAddress),
    ]);
    // Current assets
    let _currentAssets = undefined;
    if (assets) {
      _currentAssets = currentAssetsBuilder({
        current: assets,
        pending: pendingUTXOs,
      });
    }
    setCurrentAssets(_currentAssets);

    return _currentAssets;
  };

  const onLoadBalanceL2 = async () => {
    if (!providerL2 || !addressL2) return;
    try {
      const [balance, balanceBTC] = await Promise.all([
        providerL2.getBalance(addressL2),
        PlayerShareContract.getTokenBalance(BTC_L2_ADDRESS),
        fetchJuiceBalance(),
      ]);

      checkAmountTCThreshold(balance.toString());

      const amountFormated = formatEthPriceInput(balance.toString());

      const { blue, red, white } = convertTcToChips(amountFormated);

      setBalanceL2(prev => {
        // if (new BigNumber(prev.amount).lt(new BigNumber(balance.toString()))) {
        //   gaEventTracker(AlphaActions.TopUpSuccess, addressL2);
        // }
        // if (
        //   new BigNumber(prev.amountBTC).lt(new BigNumber(balanceBTC.toString()))
        // ) {
        //   gaEventTracker(AlphaActions.TopUpBTCSuccess, addressL2);
        // }

        return {
          amount: balance.toString(),
          isLoaded: true,
          amountFormated: amountFormated,
          amountBlue: blue,
          amountRed: red,
          amountWhite: white,
          amountBTCFormatted: balanceBTC,
          amountBTC: formatAmountToContract(balanceBTC),
        } as any;
      });
    } catch (e) {
      setBalanceL2({
        amount: '0',
        isLoaded: true,
        amountFormated: '0',
        amountBlue: '0',
        amountRed: '0',
        amountWhite: '0',
        amountBTC: '0',
        amountBTCFormatted: '0',
      });
    }
  };

  useEffect(() => {
    onLoadBalanceL2();
    getTreasuryBalance();
    getTVLBalance();
    // const interval = setInterval(() => {
    //   onLoadBalanceL2();
    //   getTreasuryBalance();
    //   getTVLBalance();
    // }, 10000); // 10s
    // return () => {
    //   clearInterval(interval);
    // };
  }, [addressL2, needReloadDebounced]);

  const getPlayerPoolProfileByAddress = async (): Promise<void> => {
    if (addressL2) {
      try {
        const res = await getPlayerPoolProfile2(addressL2);
        if (res) {
          setPlayerPoolProfile(res);
          accountStorage.setTwitterProfile(res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const refreshTokenTwitter = useCallback(async (): Promise<void> => {
    const token = accountStorage.getTwitterToken();
    if (!token && addressL2 && keySetL2?.prvKey) {
      const timeStamp = new Date().getTime();
      const wallet = new Wallet(keySetL2.prvKey);
      const signMessage = ethers.utils.toUtf8Bytes(`${timeStamp}`);
      const signature = await wallet.signMessage(signMessage);
      const result = await refreshToken({
        address: addressL2,
        timestamp: timeStamp,
        signature,
      });
      if (result?.token) {
        accountStorage.setTwitterToken(result?.token);
        setBearerToken(result?.token);
        setBearerTokenTweet(result?.token);
      }
    }
  }, []);

  useEffect(() => {
    getPlayerPoolProfileByAddress();
    refreshTokenTwitter();
  }, [addressL2, poolAddr]);

  const getTVLBalance = useCallback(async () => {
    if (!providerL2 || !contract) return;

    try {
      // setLoading(true);
      const [balanceTC, balanceBTC] = await Promise.all([
        providerL2.getBalance(TVL_ADDRESS),
        contract.getERC20Contract(BTC_L2_ADDRESS).balanceOf(TVL_ADDRESS),
      ]);

      setTvlBalance(formatAmountToClient(balanceBTC.toString()));
    } catch (error) {
      console.log('🚀 ~ getTvlBalance ~ error:', error);
    } finally {
      // setLoading(false);
    }
  }, [tvlBalance]);

  const getTreasuryBalance = useCallback(async () => {
    if (!providerL2 || !contract) return;

    try {
      // setLoading(true);

      const configs = await getConfigs({ network: 'nos' });
      const treasuryAddr = configs?.['nos']?.treasuryAddr;

      const [balanceTC, balanceBTC] = await Promise.all([
        providerL2.getBalance(treasuryAddr),
        await contract.getERC20Contract(BTC_L2_ADDRESS).balanceOf(treasuryAddr),
      ]);

      const treasuryExpensesBtc = (configs?.['nos']?.treasuryExpensesBtc ||
        0) as any;

      const treasuryExpensesBtcMaintain = (configs?.['nos']
        ?.treasuryExpensesBtcMaintain || 0) as any;

      if (Number(treasuryExpensesBtcMaintain) > 0) {
        setTreasuryBalance(treasuryExpensesBtcMaintain);
      } else {
        setTreasuryBalance(
          (
            parseFloat(formatAmountToClient(balanceBTC.toString())) +
            parseFloat(treasuryExpensesBtc?.toString())
          ).toString()
        );
      }
    } catch (error) {
      console.log('🚀 ~ getTreasuryBalance ~ error:', error);
    } finally {
      // setLoading(false);
    }
  }, [treasuryBalance]);

  const getReferralCodeFromContext = () => {
    getReferralCode()
      .then(code => {
        setReferralCode(code);
      })
      .finally(() => {
        setFetchingReferralCode(false);
      });
  };

  // useEffect(() => {
  //   getTreasuryBalance();
  //   getTVLBalance();

  //   const intervalId = setInterval(getTVLBalance, 60000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    if (isMounted && typeof window !== 'undefined') {
      // ReactGA.send({
      //   hitType: 'pageview',
      //   address: addressL2,
      //   twitterId: playerPoolProfile?.twitterId,
      //   page: window.location.pathname + window.location.search,
      // });
      gaEventTracker(
        AlphaActions.PageView,
        JSON.stringify({
          address: addressL2,
          twitterId: playerPoolProfile?.twitterId,
        })
      );
    }
  }, [isMounted, window.location.pathname]);

  useEffect(() => {
    if (addressL2) {
      getReferralCodeFromContext();
    }
  }, [addressL2]);

  const setUserOnline = async () => {
    if (addressL2) {
      try {
        const result = await postUserPoint(addressL2);
      } catch (error) {
        console.log('postUserPoint Error :', error);
      }
    }
  };

  const getPermissionUploadMedia = async () => {
    if (!playerPoolProfile) return;
    try {
      const res: any = await permissionUploadMedia(
        playerPoolProfile?.twitterId
      );

      if (res) {
        setIsTop(res.length > 0);
      }
    } catch (error) {
      console.log('__error permission upload', error);
    }
  };

  useEffect(() => {
    if (playerPoolProfile) {
      getPermissionUploadMedia();
    }
  }, [playerPoolProfile]);

  useEffect(() => {
    if (addressL2) {
      setUserOnline();
      timer.current = setInterval(setUserOnline, 1800000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [addressL2]);

  const contextValues = useMemo((): IAssetsContext => {
    return {
      btcBalance,
      currentAssets,
      assets,
      isLoadingAssets,
      isLoadedAssets,
      history,
      feeRate,
      comingAmount,
      debounceFetchData,
      eth2btcRate,
      juiceBalance,
      fetchAssets,
      fetchFeeRate,
      getAvailableAssetsCreateTx,
      balanceL2,
      sharePoolAddr: poolAddr,
      sharePoolBalance: poolBalance,
      playerPoolProfile,
      isTop,
      getPlayerPoolProfileByAddress,
      refreshTokenTwitter,
      treasuryBalance,
      tvlBalance,
      referralCode,
      fetchingReferralCode,
      getReferralCodeFromContext,
      totalBalanceObj,
      totalBalancebFetching: isBalanceFetching,
      rankingInfo,
    };
  }, [
    btcBalance,
    currentAssets,
    assets,
    isLoadingAssets,
    isLoadedAssets,
    history,
    feeRate,
    comingAmount,
    debounceFetchData,
    eth2btcRate,
    juiceBalance,
    fetchAssets,
    fetchFeeRate,
    getAvailableAssetsCreateTx,
    balanceL2,
    poolAddr,
    poolBalance,
    playerPoolProfile,
    isTop,
    treasuryBalance,
    tvlBalance,
    referralCode,
    fetchingReferralCode,
    totalBalanceObj,
    isBalanceFetching,
    rankingInfo,
  ]);

  return (
    <AssetsContext.Provider value={contextValues}>
      {children}
    </AssetsContext.Provider>
  );
};
