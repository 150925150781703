import moment from 'moment';
import Persistor from './Persistor';

const COLLECTION = 'Media';
const DATABASE_NAME = 'Media';

type StoreMetadataType = {
  data: Blob;
  writtenAt: number;
  stored_id: string;
};

const KEY_PATH = 'stored_id';

class MediaPersistor extends Persistor {
  private memoryCaches: string[] = [];

  constructor() {
    super(DATABASE_NAME, COLLECTION, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreMetadataType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection();
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  public getItem = async (url: string): Promise<Blob | null> => {
    try {
      const record = await this.getRecord(url);
      if (record) {
        // if (
        //   record.writtenAt &&
        //   moment(record.writtenAt).add(5, 'days').isAfter(moment())
        // ) {
        //   setTimeout(() => {
        //     this.removeItem(url);
        //   }, 10);
        // }
        this.memoryCaches.push(url);
        return record.data;
      }
    } catch (e) {
      //
    }
    return null;
  };

  public addItem = async (url: string, data: Blob): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.add({
          data,
          [KEY_PATH]: `${url}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            this.memoryCaches.push(url);
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  public removeItem = async (url: string) => {
    try {
      await this.remove();
      const collection = this.getCollection('readwrite');
      collection?.delete(url);
      this.memoryCaches = this.memoryCaches.filter(u => u != url);
    } catch (e) {
      // this.sendErrorLog(ERRORS.DELETE, e);
    }
  };

  public getAllItem = async (): Promise<Blob[]> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection();
        const query = collection?.getAll();

        if (query) {
          query.onsuccess = (event: any): void => {
            const listData = (event.target.result as StoreMetadataType[])
              .map(item => {
                if (item.data) {
                  this.memoryCaches.push(item.stored_id);
                }
                return item.data;
              })
              .filter(item => !!item);
            resolve(listData);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  public getAllUrlCached = async () => {
    await this.getAllItem();
    return this.memoryCaches;
  };
}

const persistor = new MediaPersistor();

export default persistor;
