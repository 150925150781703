import px2rem from '@/utils/px2rem';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${px2rem(720)};
  background: linear-gradient(0deg, #1b1e26, #1b1e26),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid #ffffff33;
  padding: ${px2rem(32)};
  border-radius: ${px2rem(20)};
  max-width: ${px2rem(600)};
  display: flex;
  flex-direction: column;
  /* margin-top: ${px2rem(32)}; */

  @media screen and (max-width: 420px) {
    padding: ${px2rem(16)};
    padding-bottom: ${px2rem(24)};
  }

  .title {
    font-size: ${px2rem(32)};
    font-weight: 600;
    line-height: ${px2rem(44)};
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: ${px2rem(32)};
    color: #ffffff;
    font-family: var(--chakra-fonts-sora);
  }
`;

export const MDContainer = styled.div<{
  width?: string;
  align?: string;
  justify?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  gap?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap }) => gap};

  .checkHistory {
    font-family: var(--chakra-fonts-sora);
    font-size: ${px2rem(18)};
    font-weight: 400;
    line-height: ${px2rem(28)};
    letter-spacing: 0em;
    text-align: center;
    color: #3772ff;
    text-decoration: underline;
    margin-top: ${px2rem(40)};
    text-underline-offset: ${px2rem(2)};
    cursor: pointer;
  }
`;
