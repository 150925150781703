import React from 'react';
import * as S from './styled';
import { TC_URL } from '@/configs';
import { Text } from '@chakra-ui/react';

export const ACCOUNT_ERROR_KEY = 'ACCOUNT_ERROR_KEY';

const AccountErrorModal = React.memo(() => {
  return (
    <S.Content style={{ gap: 12 }}>
      <Text>
        The wallet currently connected to New Bitcoin City differs from the{' '}
        <a
          href={TC_URL}
          target="_blank"
          className="wallet-link"
          style={{ textDecoration: 'underline' }}
        >
          Trustless Wallet
        </a>{' '}
        . Kindly verify and reconnect with the same wallet address to ensure a
        smooth experience.
      </Text>
    </S.Content>
  );
});

AccountErrorModal.displayName = 'AccountErrorModal';

export default AccountErrorModal;
