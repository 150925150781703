import { ethers } from 'ethers';
import { ContractFactoryMap } from '../types';
import { JsonRpcProvider } from '@ethersproject/providers';
import ContractSimple from './contract';

const ContractFactory: ContractFactoryMap = {};
const ProviderFactory: any = {};

export const getProviderByRPCUrl = (rpcURL: string): JsonRpcProvider => {
  if (!ProviderFactory[rpcURL]) {
    ProviderFactory[rpcURL] = new ethers.providers.JsonRpcProvider(rpcURL);
  }
  return ProviderFactory[rpcURL];
};

export const getContractByProvider = (proivder: JsonRpcProvider) => {
  const url = proivder.connection.url;
  const contractIntance = ContractFactory[url];

  if (!contractIntance) {
    ContractFactory[url] = new ContractSimple(proivder);
    return ContractFactory[url];
  } else {
    return contractIntance;
  }
};

const useProviderFactory = () => {
  return {
    getProviderByRPCUrl,
    getContractByProvider,
  };
};

export { useProviderFactory };
