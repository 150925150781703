import px2rem from '@/utils/px2rem';
import styled from '@emotion/styled';

export const ToastPending = styled.div`
  font-size: ${px2rem(14)};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${px2rem(22)};
  width: 100%;
  flex: 1;

  .wallet-link {
    display: flex;
    align-items: center;
    color: #898989;
    font-weight: 600;
    gap: ${px2rem(4)};
    margin-top: 5px;
  }

  button {
    background-color: #f5f5f5;
  }
`;
