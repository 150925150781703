import Persistor from './Persistor';
import moment from 'moment';

import { ChatRoomResponse } from '@/interfaces/api/chat';
import { getChatGroupByIdWithoutPermission } from '@/services/chat/group';

const COLLECTION = 'PublicChatRoom';
const DATABASE_NAME = 'PublicChatRoom';

// 1 minutes
const REVALIDATE_TIME = [1, 'minutes'];

const KEY_PATH = 'stored_id';

type StoreProfileType = ChatRoomResponse & {
  writtenAt: number;
};

class PublicChatRoomPersistor extends Persistor {
  private memoryCaches: Record<string, ChatRoomResponse | null> = {};
  private fetchingIds: Record<string, boolean | undefined> = {};

  constructor() {
    super(DATABASE_NAME, COLLECTION, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  public getMemoryCaches = (): Record<string, ChatRoomResponse | null> => {
    return this.memoryCaches;
  };

  private getRecord = async (
    storedId: string
  ): Promise<StoreProfileType | null> => {
    const cache = this.memoryCaches[
      `${storedId}`.toLowerCase()
    ] as StoreProfileType;
    if (cache) {
      return cache;
    }
    await this.get();

    return new Promise((resolve, reject) => {
      const collection = this.getCollection();
      const query = collection?.get(`${storedId}`.toLowerCase());

      if (query) {
        query.onsuccess = (event: any): void => {
          const storedItem = event.target.result;
          if (event.target.result) {
            this.memoryCaches[`${storedId}`.toLowerCase()] = storedItem;
          }
          resolve(storedItem);
        };

        query.onerror = (event): void => {
          reject(event);
        };
      }
    });
  };

  public getItem = async (id: string): Promise<ChatRoomResponse | null> => {
    if (this.fetchingIds[`${id}`.toLowerCase()]) {
      return new Promise(resolve => {
        if (!this.fetchingIds[`${id}`.toLowerCase()]) {
          resolve(this.getItem(id));
        } else {
          setTimeout(() => {
            resolve(this.getItem(id));
          }, 50);
        }
      });
    }

    if ((this.fetchingIds[`${id}`.toLowerCase()] = undefined)) {
      this.fetchingIds[`${id}`.toLowerCase()] = true;
    }
    try {
      const record = await this.getRecord(id);
      if (record) {
        if (
          record.writtenAt &&
          moment(record.writtenAt)
            .add(...REVALIDATE_TIME)
            .isAfter(moment())
        ) {
          return record;
        }

        getChatGroupByIdWithoutPermission(id as string).then(room => {
          this.upsertItem(id, room);
        });
        return record;
      }
      this.fetchingIds[`${id}`.toLowerCase()] = true;
      const room = await getChatGroupByIdWithoutPermission(id as string);
      this.upsertItem(id, room);
      return room;
    } catch (e) {
      const cache = this.memoryCaches[
        `${id}`.toLowerCase()
      ] as StoreProfileType;
      if (cache) {
        if (this.fetchingIds[`${id}`.toLowerCase()]) {
          this.fetchingIds[`${id}`.toLowerCase()] = false;
        }
        return cache;
      }
      this.fetchingIds[`${id}`.toLowerCase()] = true;
      const room = await getChatGroupByIdWithoutPermission(id as string);
      this.upsertItem(id, room);
      return room;
    }
  };

  private addItem = async (
    id: string,
    item: ChatRoomResponse
  ): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${id}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (
    id: string,
    item: ChatRoomResponse
  ): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${id}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (
    id: string,
    item: ChatRoomResponse
  ): Promise<void> => {
    try {
      this.fetchingIds[`${id}`.toLowerCase()] = false;

      if (item) {
        this.memoryCaches[`${id}`.toLowerCase()] = item;
      }
      const record = await this.getRecord(id);

      if (record) {
        this.updateItem(`${id}`.toLowerCase(), item);
      } else {
        this.addItem(`${id}`.toLowerCase(), item);
      }
    } catch (e) {
      //
    } finally {
      //
    }
  };
}

const persistor = new PublicChatRoomPersistor();

export default persistor;
