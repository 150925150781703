import { TOKEN_ADDRESS } from '@/constants/token';
import { ITokenMultiChain } from './types';

export enum TcLayerNetwork {
  TrusstlessComputer = 1,
  NOS = 2,
}

export const TokenType = {
  ERC20: 'ERC20',
};

export const TokenMultiChainInit: ITokenMultiChain = {
  id: '',
  uuid: '',
  created_at: '',
  network: '',
  networkId: '',
  type: '',
  decimals: 0,
  name: '',
  symbol: '',
  tokenId: '',
  status: 0,
  priceUsd: 0,
  isStableCoin: false,
  logo: '',
  order: 0,
  networkOutChainID: '',
  outChainNetworkInfo: undefined,
  networkInfo: {
    id: '',
    uuid: undefined,
    deleted_at: undefined,
    created_at: undefined,
    updated_at: undefined,
    networkName: '',
    networkTitle: '',
    networkLogo: '',
    rpcEndpoint: '',
    explorerUrl: '',
    chainId: 0,
    status: 0,
    bridgeContractAddress: ''
  }
};

export const LIST_TOKEN_DEAULT_DEPOSIT = [
  TOKEN_ADDRESS.BTC_ADDRESS_L2, // BTC
  TOKEN_ADDRESS.ETH_ADDRESS_L2, // ETH
  TOKEN_ADDRESS.SOL_ADDRESS_L2, // SOL
  TOKEN_ADDRESS.USDT_ADDRESS_L2, // USDT
];
