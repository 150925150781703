import { TC_LAYER2_NETWORK_RPC } from '@/configs';
import { ethers } from 'ethers';
import { useMemo } from 'react';

function useProviderL2() {
  return useMemo(() => {
    try {
      if (!window.provider) {
        window.provider = new ethers.providers.JsonRpcProvider(
          TC_LAYER2_NETWORK_RPC
        );
      }
      return window.provider;
    } catch (error) {
      window.provider = undefined;
      return undefined;
    }
  }, [window.provider]);
}

export default useProviderL2;
