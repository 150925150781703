import qs from 'query-string';
import { API_DGAMES } from '@/configs';
import {
  AddChatMessagePayload,
  AgoraMappingUserResponse,
  ChatMessageResponse,
  ChatPeerToPeerResponse,
  ChatRoomResponse,
  GetChatMessageByTimePayload,
  GetRoomPayload,
  JoinCallResponse,
  PromptFeeResponse,
} from '@/interfaces/api/chat';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { apiClient } from '@/services';
import { formatMessagesResponse, unifyChatRoom } from '@/services/chat/helpers';
import { UID } from 'agora-rtc-react';

const API_PATH = 'v1/chat';

export const getChatRooms = async (
  payload: GetRoomPayload
): Promise<{
  rooms: ChatRoomResponse[];
  total: number;
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/rooms?${query}`);
    const dataFormatted = Object(camelCaseKeys(res));
    return {
      total: dataFormatted.total,
      rooms: (dataFormatted.rooms as ChatPeerToPeerResponse[]).map(item =>
        unifyChatRoom(item)
      ),
    };
  } catch (err: unknown) {
    return {
      total: 0,
      rooms: [],
    };
  }
};

export const addChatMessage = async (
  payload: AddChatMessagePayload
): Promise<ChatMessageResponse> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/add-message`, {
      ...payload,
    });
    return Object(camelCaseKeys(res)).message;
  } catch (err: unknown) {
    console.error('addChatMessage error', err);
    throw Error('Cannot send chat message');
  }
};

export const getChatMessagesByTime = async (
  payload: GetChatMessageByTimePayload
): Promise<{
  total: number;
  messages: ChatMessageResponse[];
}> => {
  try {
    const query = qs.stringify(payload);
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/messages-from-time?${query}`
    );

    const result = Object(camelCaseKeys(res));
    return {
      total: result.total,
      messages: formatMessagesResponse(result.messages),
    };
  } catch (err: unknown) {
    return {
      total: 0,
      messages: [],
    };
  }
};

export const updateChatLastSeen = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/update-seen/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('updateChatLastSeen error', err);
    throw Error('Cannot update room last seen');
  }
};

export const getChatRoomById = async (
  id: string
): Promise<ChatRoomResponse> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/room-id/${id}`);
  const dataFormatted = Object(camelCaseKeys(res));
  return unifyChatRoom(dataFormatted.room);
};

export const joinCall = async (roomId: string): Promise<JoinCallResponse> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/join-call`, {
      roomId,
    });
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.error('joinCall error', err);
    throw Error('Cannot join call');
  }
};

export const leaveCall = async (roomId: string): Promise<void> => {
  try {
    await apiClient.post(`${API_DGAMES}/${API_PATH}/leave-call`, {
      roomId,
    });
  } catch (err: unknown) {
    console.error('leaveCall error', err);
    throw Error('Cannot leave call');
  }
};

export const getUserByAgoraId = async (
  uid: UID
): Promise<AgoraMappingUserResponse> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/user-by-agora-uid/${uid}`
    );

    return Object(camelCaseKeys(res)).user;
  } catch (err: unknown) {
    console.error('getChatUserInformation error', err);
    throw Error('Cannot leave call');
  }
};

export const getChatRoomIds = async (): Promise<string[]> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/room-ids`);
  const dataFormatted = Object(camelCaseKeys(res));
  return dataFormatted.roomIds;
};

export const getMessageDetailRoom = async (
  messageId: string
): Promise<ChatMessageResponse | undefined> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/message/${messageId}`
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.log(err);
  }
};

export const setChatRoomPin = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/pin-room/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('setChatRoomPin error', err);
    throw Error('setChatRoomPin');
  }
};

export const setChatRoomUnPin = async (payload: {
  roomId: string;
}): Promise<boolean> => {
  try {
    const res = await apiClient.post(
      `${API_DGAMES}/${API_PATH}/unpin-room/${payload.roomId}`,
      {
        ...payload,
      }
    );
    return Object(camelCaseKeys(res)).result;
  } catch (err: unknown) {
    console.error('setChatRoomUnPin error', err);
    throw Error('setChatRoomUnPin');
  }
};

export const getJamUserByRoomId = async (
  roomId: string
): Promise<{
  members: string[];
  total: number;
}> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/${API_PATH}/jam-member/${roomId}?limit=100&page=1`
    );

    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.error('getChatUserInformation error', err);
    throw Error('Cannot leave call');
  }
};

export const getChatPromptFeeList = async (): Promise<PromptFeeResponse[]> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/prompt-fee`);
  return Object(camelCaseKeys(res)).promptInfos;
};

// export const getChatPromptFee = async (
//   prompt: string
// ): Promise<PromptFeeResponse> => {
//   const res = await apiClient.post(`${API_DGAMES}/${API_PATH}/prompt-fee`, {
//     prompt,
//   });

//   return Object(camelCaseKeys(res));
// };
