import { StyledEmpty } from './Empty.styled';
import { CDN_URL } from '@/configs';

export type TEmpty = {
  infoText?: string;
  src?: string;
  isTable?: boolean;
  size?: number;
};

const Empty = ({
  infoText = '',
  isTable = false,
  src = `${CDN_URL}/icons/ic-empty.svg`,
  size = 95,
}: TEmpty) => {
  return (
    <StyledEmpty className={'notFound'} isTable={isTable}>
      <img
        width={size}
        height={size}
        src={src}
        alt="Not found item"
        className={'notFound_image'}
      />
      <h5 className="content">{infoText}</h5>
    </StyledEmpty>
  );
};

export default Empty;
