/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import { storageUtil } from '../storage';

const KEY_LAST_ACTIVE = 'aa_session_last_active';
const KEY_SESSION_ID = 'aa_session_id';

const SESSION_INACTIVITY_MILLISECONDS = 900000; // 15 minutes

class AASession {
  storageLocal: {
    set(name: string, value: any): void;
    get(name: string): any;
    remove(name: string): void;
    clear(): void;
  };

  constructor() {
    this.storageLocal = storageUtil;
    this.update();
  }
  persistData(key: string, value: any) {
    this.storageLocal.set(key, value);
  }
  getData(key: string) {
    return this.storageLocal.get(key);
  }
  generateSessionId() {
    const sessionId = uuidv4();
    this.persistData(KEY_SESSION_ID, sessionId);
  }
  getSessionId() {
    return this.getData(KEY_SESSION_ID);
  }
  setLastActive(timestamp: any) {
    this.persistData(KEY_LAST_ACTIVE, timestamp);
  }
  getLastActive() {
    return this.getData(KEY_LAST_ACTIVE);
  }
  update() {
    const currentTimestamp: any = Date.now();
    const lastActive = this.getLastActive();
    const sessionId = this.getSessionId();
    if (lastActive && sessionId) {
      const lastActiveNumber = parseInt(lastActive, 10);
      if (
        currentTimestamp >
        lastActiveNumber + SESSION_INACTIVITY_MILLISECONDS
      ) {
        this.generateSessionId();
      }
      this.setLastActive(currentTimestamp);
    } else {
      // Initial page load for new user
      this.generateSessionId();
      this.setLastActive(currentTimestamp);
    }
  }
}

export default AASession;
