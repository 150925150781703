import { API_DGAMES } from '@/configs';
import { TipItemType } from '@/interfaces/api/tip';
import { apiClient } from '@/services';
import { camelCaseKeys } from 'trustless-swap-sdk';

export const getTipWallet = async ({
  roomId,
  token,
}: {
  roomId: string;
  token: string;
}): Promise<any> => {
  try {
    const res = await apiClient.get(
      `${API_DGAMES}/v1/tip/tip-wallet?roomId=${roomId}&address=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getTipItems = async (): Promise<{
  items: TipItemType[];
}> => {
  try {
    const res = await apiClient.get(`${API_DGAMES}/v1/tip/tip-items`);
    return camelCaseKeys(res);
  } catch (error) {
    console.log(error);
    return {
      items: [],
    };
  }
};

export const uploadTip = async ({
  roomId,
  tipItemId,
  txHash,
}: {
  roomId: string;
  tipItemId: string;
  txHash: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/v1/tip/upload-tip`, {
      roomId,
      tipItemId,
      txHash,
    });
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const openTip = async ({
  messageId,
}: {
  messageId: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/v1/tip/open-tip`, {
      messageId,
    });
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export type GetTipTweetResponse = {
  wallet: string;
};
export const getTipTweetAddress = async ({
  address,
}: {
  address: string;
}): Promise<GetTipTweetResponse> => {
  const res = await apiClient.get(
    `${API_DGAMES}/v1/tip/tweet-tip-wallet?address=${address}`
  );
  return camelCaseKeys(res);
};

export type UploadTipTweetResponse = any;

export const uploadTipTweet = async ({
  tweetId,
  tipItemId,
  txHash,
}: {
  tweetId: string;
  tipItemId: string;
  txHash: string;
}): Promise<UploadTipTweetResponse> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/v1/tip/upload-tweet-tip`, {
      tweetId,
      tipItemId,
      txHash,
    });
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export type GetTweetTipResponse = {
  amount: string;
  createdAt: string;
  id: string;
  tipItem: {
    id: string;
    thumbnailUrl: string;
    price: string;
    animation: string;
    createdAt: string;
    updatedAt: string;
  };
  sender: {
    address: string;
    twitterId: string;
    twitterName: string;
    twitterAvatar: string;
    twitterFollowersCount: number;
  };
  token: {
    address: string;
    twitterId: string;
    twitterName: string;
    twitterAvatar: string;
    twitterFollowersCount: number;
  };
};
export const getTweetTips = async ({
  tweetId,
}: {
  tweetId: string;
}): Promise<GetTweetTipResponse[]> => {
  const res = await apiClient.get(`${API_DGAMES}/v1/tip/tweet-tips/${tweetId}`);
  return camelCaseKeys(res).items;
};

export const uploadTipListMemberInCircle = async (payload: {
  roomId: string;
  tipItemId: string;
  txHash: string;
  timestamp: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post(`${API_DGAMES}/v1/tip/upload-active-tip`, {
      ...payload,
    });
    return res;
  } catch (error) {
    return {};
  }
};
