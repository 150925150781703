import { TransactionResponse } from '@ethersproject/abstract-provider';
import useJackpotContractSigner from '../useJackpotContractSigner';
import { useContext, useEffect, useState } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { AssetsContext } from '@/contexts/assets-context';

interface IPlayerInfo {
    score: number;
    hasTicket: boolean;
    usedTickets: number;
}


const useGetPlayerData = () => {
    const contractSigner = useJackpotContractSigner();
    const [potId, setPotId] = useState('')
    const [playerInfo, setPlayerInfo] = useState<IPlayerInfo>()
    const [loading, setLoading] = useState(true)

    const { addressL2 } = useContext(WalletContext);
    const { sharePoolAddr } = useContext(AssetsContext)

    const getPotNumber = async () => {
        if (!contractSigner) {
            setLoading(false);
            return;
        }

        const potNumber = await contractSigner.potNumber();
        setPotId(potNumber.toString())
    }


    const getPotPlayer = async (

    ): Promise<TransactionResponse | null> => {
        if (!contractSigner) {
            setLoading(false)
            throw new Error('account is not ready.');
        }

        if (!potId) {
            setLoading(false)
            throw new Error('potId is not found.');
        }

        try {
            setLoading(true)
            const tx = (await contractSigner.getPlayerData(potId, addressL2));
            setPlayerInfo({
                score: tx.score.toString(),
                hasTicket: tx.hasTicket,
                usedTickets: tx.usedTickets
            })
            return tx;
        } catch (error) {
            // toast.error("Cannot get pot player info");
            console.log(error)
        } finally {
            setLoading(false)
        }
        return null;
    };

    useEffect(() => {
        getPotNumber()
    }, [])

    useEffect(() => {
        if (potId && addressL2) {
            getPotPlayer()
        }
    }, [potId, addressL2])


    return { playerInfo, loading };
};

export default useGetPlayerData;
