import { createAsyncThunk } from '@reduxjs/toolkit';
import aliasService from '@/services/alias';
import { Alias } from '@/interfaces/api/alias';

export const getConfigs = createAsyncThunk<Alias[]>(
  'alias/getConfigs',
  async () => {
    const configs = await aliasService.getConfigs();
    console.log('ALIAS - configs: ', configs);
    return configs;
  }
);
