const KEY = 'BALANCE';

export const TOTAL_BALANCE = 'TOTAL-BALANCE';
export const TOTAL_BTC_BALANCE = 'TOTAL-BTC-BALANCE';

export const getKeyBTCBalanceStorage = (tokenAddress: string) => {
  return `${TOTAL_BTC_BALANCE}-${tokenAddress}`;
};

export const getKeyBalanceStorage = (tokenAddress: string) => {
  return `${TOTAL_BALANCE}-${tokenAddress}`;
};

export const getBalanceFromKey = (keyStorage: string) => {
  let balanceLocal;
  try {
    balanceLocal = localStorage.getItem(keyStorage);
  } catch (error) {
    balanceLocal = '-2';
  } finally {
    return balanceLocal || '-2';
  }
};

export const getBalanceFromStorage = (tokenAddress: string) => {
  let balanceLocal;
  try {
    const key = getKeyBalanceStorage(tokenAddress);
    balanceLocal = localStorage.getItem(key);
    return balanceLocal;
  } catch (error) {}
};

export const saveBalanceToStorage = (tokenAddress: string, balance: string) => {
  try {
    const key = getKeyBalanceStorage(tokenAddress);
    localStorage.setItem(key, balance);
  } catch (error) {}
};

export const saveTotalBTCBalanceToStorage = (
  walletAddres: string,
  balance: string
) => {
  try {
    const key = getKeyBTCBalanceStorage(walletAddres);
    localStorage.setItem(key, balance);
  } catch (error) {}
};

export const getTotalBTCBalanceFromStorage = (walletAddres: string) => {
  let balanceLocal;
  try {
    const key = getKeyBTCBalanceStorage(walletAddres);
    balanceLocal = localStorage.getItem(key);
    return balanceLocal;
  } catch (error) {}
};
