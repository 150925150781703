export const TOPUP_TABS = {
  Faucet: {
    title: 'Faucet',
    path: 'faucet',
  },
  // DailyFaucet: {
  //   title: 'Daily Faucet',
  //   path: 'faucet',
  // },
  Buy: {
    title: 'Buy TC',
    path: 'buy-tc',
  },
};

export const HOME_TAB_ARRAY = Object.values(TOPUP_TABS);

export const GG_RECAPTCHA_SITE_V2 = '6Le6VwInAAAAACVFg16ZDCTkqQ2_Kzi5paBSLRbL';

export const GG_RECAPTCHA_SITE_V3 = '6LevQD8mAAAAAEMxH0hhtwfP7dT1yuCXa1Ox3M1i';
