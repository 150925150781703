import { createSlice } from '@reduxjs/toolkit';
import { INotification, INotificationData } from '@/interfaces/notification';

export interface NotificationState {
  unreadNotifications: Array<INotificationData>;
}

export const initialState: NotificationState = {
  unreadNotifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateUnreadNotification(state, { payload: { notifications } }) {
      state.unreadNotifications = [...state.unreadNotifications, ...notifications];
    },
    resetUnreadTradeNotification(state) {
      state.unreadNotifications = state.unreadNotifications.filter((notiObj) => (
        notiObj.type !== 'buy' &&
        notiObj.type !== 'sell' &&
        notiObj.type !== 'issue')
      )
    },
    resetUnreadPostNotification(state) {
      state.unreadNotifications = state.unreadNotifications.filter((notiObj) => (
        notiObj.type !== 'post' &&
        notiObj.type !== 'reply')
      )
    },
    resetUnreadNotification(state) {
      state.unreadNotifications = [];
    },
  },
});

export const {
  updateUnreadNotification,
  resetUnreadNotification,
  resetUnreadPostNotification,
  resetUnreadTradeNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
