import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';
import { Alias, AddPayload, DeletePayload } from '@/interfaces/api/alias';
import accountStorage from '@/utils/account.storage';

const getConfigs = async (): Promise<Alias[]> => {
  const address = accountStorage.getAddress();
  let configs: Alias[] = [];
  try {
    configs = (await apiClient.get(
      `${PERP_API_URL}/api/prompt/list?network=nos&address=${address}`
    )) as Alias[];
  } catch (error) {
    configs = [];
  }
  return configs;
};

const removeConfig = async (payload: DeletePayload) => {
  const address = accountStorage.getAddress();
  await apiClient.delete(
    `${PERP_API_URL}/api/prompt/${payload.id}?network=nos&address=${address}`
  );
};

const addConfig = async (payload: AddPayload) => {
  const address = accountStorage.getAddress();
  await apiClient.post(
    `${PERP_API_URL}/api/prompt/?network=nos&address=${address}`,
    {
      ...payload,
    }
  );
};

const aliasService = {
  getConfigs,
  removeConfig,
  addConfig,
};

export default aliasService;
