import localStorage from '@/utils/localstorage';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import ListTable, { ColumnProp } from '@/components/ListTable';
import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import {
  IBodyBuySweetFloorKeys,
  IResponseBuySweetFloorKeys,
  IUIPageParams,
} from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import useDebounce from '@/hooks/useDebounce';
import KeyPresaleIcon from '@/modules/AlphaPWA/Notification/svg/KeyPresaleIcon';
import { IParamsGetKeys, ITradeKey } from '@/services/interfaces/trade';
import {
  requestReload,
  selectCommonReducer,
  setFilterKeyParams,
  setKeyPageParams,
} from '@/state/common';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import px2rem from '@/utils/px2rem';
import { BigNumber } from 'ethers';
import { clone, floor, isNaN } from 'lodash';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import KeyIcon from '../../BuyAKey/KeyIcon';
import Avatar from '../components/avatar';
import HorizontalItem from '../components/horizontalItem';
import { showError } from '../components/toast';
import styles from '../styles.module.scss';
import Buying from './buying';
import Footer from './footer';
import ProTradeKeyHeader from './header';
import BigNumberBN from 'bignumber.js';

import ScrollWrapper from '@/components/ScrollWrapper/ScrollWrapper';
import {
  POPUP_REFERRAL_THROTTLE_TIME,
  REPORT_SUMMARY,
} from '@/constants/storage-key';
import { WalletContext } from '@/contexts/wallet-context';
import { useOverlay } from '@/providers/OverlayProvider';
import { closeModal, openModal } from '@/state/modal';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import {
  delay,
  getErrorMessageContract,
  getUrlAvatarTwitter,
  settingMomentFromNow,
} from '@/utils/helpers';
import storageLocal from '@/utils/storage.local';
import { parseEther } from 'ethers/lib/utils';
import moment from 'moment';
import { BsClock } from 'react-icons/bs';
import IcTwitter from '../assets/icons/icTwitter';
import {
  KEY_FILTER,
  KEY_SELECT,
  labelAmountOrNumberAdds,
  MAX_DECIMAL,
  MIN_DECIMAL,
} from '../constants';
import ModalAmountBTC from './ModalAmountBTC';
import { SORTS } from './itemFilterSort';
import { FILTER_TYPES } from './itemFilterTypes';
import CTradeAPI from '@/services/classes/trade';
import { isMoreOneDay } from '@/utils/time';

settingMomentFromNow();

const DEFAULT_LIMIT = 10;
export const CHOOSE_AMOUNT_3_3_MODAL_KEY = 'CHOOSE_AMOUNT_3_3_MODAL_KEY';

const ProTradeKeyModule = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { balanceL2, tvlBalance } = useContext(AssetsContext);
  const hasIncrementedPageRef = useRef(false);
  const apiTradeKey = useRef(new CTradeAPI()).current;
  const { addressL2 } = useContext(WalletContext);
  const {
    getTradeKeys,
    estimateTCMultiKeys,
    buySweetFloorKeys,
    requestTrade33KeysSameAmount,
    userProfile,
  } = useTradeKey();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openOverlayScreen } = useOverlay();

  const { needReload, filterKeyParams, keyPageParams } =
    useSelector(selectCommonReducer);

  const { btc2Usd } = useContext(WalletContext);
  // const setSelectKeys = (e: any) => dispatch(setDPSelectKeys(e));

  const [selectKeys, setSelectKeys] = useState<IBodyBuySweetFloorKeys[]>([]);

  const [isFetching, setIsFetching] = useState(true);
  const [keys, setKeys] = useState<ITradeKey[]>([]);
  const [numKeys, setNumKeys] = useState<number>(0);
  const refParams = useRef<IParamsGetKeys>({
    page: 1,
    limit: DEFAULT_LIMIT,
    key_type: '1',
    ...queryString.parse(filterKeyParams),
  });
  // const [estimateTC, setEstimateTC] = useState<string>('0');
  const [estimateKeysTC, setEstimateKeysTC] = useState<any[]>([]);
  const [estimating, setEstimating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  // const [allowBuy, setAllowBuy] = useState(true);
  const [waitKeys, setWaitKeys] = useState<IResponseBuySweetFloorKeys[]>([]);
  const [numProcessDone, setNumProcessDone] = useState(0);
  const [searching, setSearching] = useState(false);
  const [filtering, setFiltering] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker();
  const selectKeysDebounced = useDebounce(selectKeys);
  const numProcessDoneDebounced = useDebounce(numProcessDone);

  const refFooter = useRef<any>();
  const refDone = useRef<any>(false);
  const refInitial = useRef(false);
  const [isInvite, setIsInvite] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [estimateTC33, setEstimateTC33] = useState<string>('0');
  const [selectKeys33, setSelectKeys33] = useState<IBodyBuySweetFloorKeys[]>(
    []
  );
  const [estimateKeysTC33, setEstimateKeysTC33] = useState<any[]>([]);

  const _summaryReport = localStorage.get(REPORT_SUMMARY) as any;

  const [summaryReport, setSummaryReport] = useState<any>(
    _summaryReport
      ? JSON.parse(_summaryReport)
      : {
          total_volume: 0,
          total_transaction: 0,
        }
  );

  const estimateTC = useMemo(
    () =>
      selectKeys.reduce(
        (p: any, c) => parseFloat(p) + parseFloat(c.data?.price as string),
        0
      ),
    [selectKeys]
  );

  const allowBuy = useMemo(() => {
    return BigNumber.from(
      estimateTC && !isNaN(estimateTC)
        ? parseEther(formatCurrency(estimateTC.toString(), 0, 6).toString())
        : '0'
    ).lt(balanceL2.amountBTC);
  }, [estimateTC, balanceL2]);

  const openReferralModal = () => {
    localStorage.set(
      POPUP_REFERRAL_THROTTLE_TIME,
      `${new Date().toISOString()}`
    );
    openOverlayScreen('SHARE_REFERRAL');
    gaEventTracker(GameFiActions.PopupReferralOpenModal, addressL2);
  };

  const showSuccessInform = () => {
    try {
      const lastShowPopupReferral = localStorage.get(
        POPUP_REFERRAL_THROTTLE_TIME
      );
      if (lastShowPopupReferral) {
        const lastTime = moment(lastShowPopupReferral as any);
        const hoursAgo = moment().diff(lastTime, 'hours');
        if (Math.abs(hoursAgo) >= 3) {
          openReferralModal();
        }
      } else {
        openReferralModal();
      }
    } catch (e) {
      //
    }
  };

  const chekRenderLayout = async (params: IUIPageParams) => {
    await delay(1000);
    if (params.userId > 0) {
      const element = document.getElementById(
        `user_${params.userId.toString()}`
      );
      if (element) {
        element.scrollIntoView({ block: 'center' });

        dispatch(
          setKeyPageParams({
            ...keyPageParams,
            userId: 0,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!refInitial.current) {
      const localParams = storageLocal.get(KEY_FILTER);
      const localSelects = storageLocal.get(KEY_SELECT);
      if (localParams) {
        const _params = queryString.parse(localParams);
        refParams.current = {
          ...refParams.current,
          ..._params,
        };

        dispatch(setFilterKeyParams(localParams));
      }
      if (localSelects) {
        setSelectKeys(localSelects);
      }

      let isNew = true;
      let pageLimit = DEFAULT_LIMIT;
      if (keyPageParams.pageIndex) {
        pageLimit = DEFAULT_LIMIT * (keyPageParams.pageIndex + 1);
        refParams.current = {
          ...refParams.current,
          limit: pageLimit,
        };
        isNew = false;
      }
      fetchData(isNew);
      apiTradeKey.getSummaryReport().then(v => {
        localStorage.set(REPORT_SUMMARY, JSON.stringify(v));
        setSummaryReport(v);
      });
    }
  }, [needReload, keyPageParams]);

  const onFilter = (_params: any) => {
    if (isFetching || submitting || refreshing || searching) {
      return true;
    }
    refParams.current = {
      ...refParams.current,
      page: 1,
      limit: DEFAULT_LIMIT,
      ..._params,
    };
    setSelectKeys([]);
    dispatch(setFilterKeyParams(queryString.stringify(_params)));
    setFiltering(true);
    fetchData(true);
  };

  const fetchData = async (isNew?: boolean) => {
    try {
      setIsFetching(true);
      const [response] = await Promise.all([
        getTradeKeys({ ...refParams.current, search: '' }),
        ,
      ]);

      if (isNew) {
        setKeys(response.rows);
        refParams.current = {
          ...refParams.current,
          page: 1,
          search: '',
        };

        if (response.rows.length > DEFAULT_LIMIT && !filtering && !searching) {
          refParams.current = {
            ...refParams.current,
            page: floor(response.rows.length / DEFAULT_LIMIT),
            limit: DEFAULT_LIMIT,
            search: '',
          };
        }
      } else {
        setKeys(_keys => [..._keys, ...response.rows]);
      }

      dispatch(
        setKeyPageParams({
          ...keyPageParams,
          pageIndex: refParams.current.page,
        })
      );

      setNumKeys(response.count);
    } catch (error) {
      console.log('error', error);
    } finally {
      if (!refInitial.current) {
        chekRenderLayout(keyPageParams);
      }
      setIsFetching(false);
      setSearching(false);
      setFiltering(false);
      hasIncrementedPageRef.current = false;
      refInitial.current = true;
    }
  };

  const onCheckBox = (data: ITradeKey) => {
    const _selectKeys: IBodyBuySweetFloorKeys[] = clone(selectKeys);
    const _findIndex = _selectKeys.findIndex(v =>
      compareString(v.token_address, data.address)
    );
    if (_findIndex >= 0) {
      _selectKeys.splice(_findIndex, 1);
    } else {
      _selectKeys.push({
        token_address: data.address as string,
        token_amount: 1,
        data: data,
      });
    }

    setSelectKeys(_selectKeys);
    refFooter.current?.setNumSweep(_selectKeys.length);
  };

  const handleGoToProfile = async (data: ITradeKey) => {
    dispatch(
      setKeyPageParams({
        ...keyPageParams,
        userId: data?.user_twitter_id,
      })
    );
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  const onGoToSwap = (data: ITradeKey) => {
    // router.replace(
    //   `${ROUTE_PATH.ALPHA_MOBILE_SWAP}?from_token=&to_token=${data?.owner}`
    // );
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        id: 'player',
        label: 'Key',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey, extraData: any, index: number) {
          const isChecked =
            selectKeys.findIndex(v =>
              compareString(v.token_address, data.address)
            ) >= 0;

          const renderDesc = () => {
            if (refParams.current?.sort_col === 'created_at') {
              return (
                <>
                  <BsClock fontSize={'10px'} opacity={'70%'} />
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    {`${moment(data?.created_at).fromNow()}`}
                  </Text>
                </>
              );
            }
            if (refParams.current?.sort_col === 'latest_online') {
              return (
                <>
                  <BsClock fontSize={'10px'} opacity={'70%'} />
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    {`${moment(data?.latest_online).fromNow()}`}
                  </Text>
                </>
              );
            }
            if (refParams.current?.sort_col === 'buy_price') {
              const usdTreasury = new BigNumberBN(
                data?.treasury_amount || '0'
              ).multipliedBy(btc2Usd || '0');
              return (
                <Flex gap={1}>
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    Treasury{' '}
                  </Text>
                  <Text color={'#000000'} fontSize={px2rem(12)}>
                    ${formatCurrency(usdTreasury.toString(), 0, 2)}
                  </Text>
                </Flex>
              );
            }
            if (refParams.current?.sort_col === 'holder') {
              return (
                <Flex gap={1}>
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    Portfolio
                  </Text>
                  <Text color={'#000000'} fontSize={px2rem(12)}>
                    {formatCurrency(
                      data?.portfolio,
                      0,
                      2,
                      data.base_token_symbol
                    )}{' '}
                    {data.base_token_symbol}
                  </Text>
                </Flex>
              );
            }
            if (refParams.current?.sort_col === 'portfolio') {
              return (
                <>
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    Portfolio{' '}
                    {formatCurrency(
                      data?.portfolio,
                      0,
                      2,
                      data.base_token_symbol
                    )}{' '}
                    {data.base_token_symbol}
                  </Text>
                </>
              );
            }
            return (
              <>
                <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                  Vol{' '}
                  {formatCurrency(
                    data?.total_volume,
                    0,
                    2,
                    data.base_token_symbol
                  )}{' '}
                  {data.base_token_symbol}
                </Text>
              </>
            );
          };

          return (
            <Flex
              gap={3}
              alignItems={'center'}
              className={submitting && isChecked ? styles.isProcessing : ''}
              width={'100%'}
              id={`user_${data.user_twitter_id}`}
            >
              <Box className={styles.checkboxContainer}>
                <Checkbox
                  onChange={() => onCheckBox(data)}
                  isChecked={isChecked}
                />
              </Box>
              <Flex width={'100%'} flex={1} gap={2} alignItems={'center'}>
                <Avatar
                  url={getUrlAvatarTwitter(
                    (data?.user_twitter_avatar || data?.avatar) as string,
                    'normal'
                  )}
                  address={data?.owner}
                  name={data?.user_twitter_name}
                  width={32}
                  onClick={() => onCheckBox(data)}
                  fontSize={14}
                />
                <Flex width={'100%'} gap={2} direction={'column'}>
                  <Flex
                    onClick={() => handleGoToProfile(data)}
                    gap={2}
                    alignItems={'center'}
                    maxWidth={'100%'}
                    width={'90%'}
                    flex={1}
                  >
                    <Text className={styles.buyer}>
                      {formatName(data?.user_twitter_name as string, 50)}
                    </Text>
                    <Flex
                      className={clsx(
                        styles.keyContainer,
                        data.is_pre_sale && styles.keyContainerPresale
                      )}
                    >
                      {data.is_pre_sale ? <KeyPresaleIcon /> : <KeyIcon />}
                    </Flex>
                  </Flex>
                  <Flex alignItems={'center'} gap={1}>
                    {renderDesc()}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        id: 'tradingVolume',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex
              onClick={e => onGoToSwap(data)}
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
            >
              <Text className={styles.buyer}>
                {formatCurrency(data?.price, 0, 2, data.base_token_symbol)}{' '}
                {data.base_token_symbol}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                ${formatCurrency(data?.usd_price, 2, 2)}
              </Text>
            </Flex>
          );
        },
      },
      {
        id: 'twitter_followers_count',
        label: (
          <Flex gap={1}>
            <IcTwitter />
          </Flex>
        ),
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex
              onClick={e => onCheckBox(data)}
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
            >
              <Text className={styles.buyer}>
                {abbreviateNumber(data.twitter_followers_count)}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'0%'}>
                a
              </Text>
            </Flex>
          );
        },
      },
    ];
  }, [selectKeys, submitting, refParams.current]);

  // const onEstimateTC = async () => {
  //   try {
  //     // setEstimating(true);
  //     if (selectKeys.length > 0) {
  //       // const response = await estimateTCMultiKeys(selectKeys);
  //       // console.log('selectKeys', selectKeys);

  //       const total_tc_formatted = selectKeys.reduce(
  //         (p: any, c) => parseFloat(p) + parseFloat(c.data?.price as string),
  //         0
  //       );

  //       setEstimateTC(total_tc_formatted);
  //       // setEstimateKeysTC(response.alphaTokens);
  //       setAllowBuy(
  // BigNumber.from(parseEther(total_tc_formatted.toString())).lt(
  //   balanceL2.amountBTC
  // )
  //       );
  //     } else {
  //       setEstimateTC('0');
  //       setAllowBuy(true);
  //       setEstimateKeysTC([]);
  //     }
  //   } catch (error) {
  //     setAllowBuy(false);
  //     setEstimateTC('0');
  //     setEstimateKeysTC([]);
  //   } finally {
  //     setEstimating(false);
  //   }
  // };

  useEffect(() => {
    // onEstimateTC();
  }, [selectKeysDebounced, needReload]);

  useEffect(() => {
    if (
      submitting &&
      numProcessDone === selectKeys.length &&
      !refDone.current
    ) {
      refDone.current = true;
      setSubmitting(false);
      setIsDone(true);
      // showSuccessInform();
      // onClose();
    }
  }, [
    numProcessDoneDebounced,
    submitting,
    selectKeys,
    refFooter.current,
    refDone.current,
  ]);

  const onDoneAndClose = () => {
    refDone.current = false;
    refFooter.current?.reset?.();
    onRefresh();
    setSelectKeys([]);
    setIsDone(false);
    setWaitKeys([]);
    setNumProcessDone(0);
    dispatch(requestReload());
    onClose();
  };

  const onBeforeSubmit = (_isInvite: boolean) => {
    setIsInvite(_isInvite);
    if (_isInvite) {
      openChooseAmountFor33();
    } else {
      onOpen();
    }
  };

  const onHideModalChooseAmount33 = () => {
    dispatch(closeModal({ id: CHOOSE_AMOUNT_3_3_MODAL_KEY }));
  };

  const handleSubmit33 = () => {
    onHideModalChooseAmount33();
    onOpen();
  };

  const openChooseAmountFor33 = () => {
    dispatch(
      openModal({
        id: CHOOSE_AMOUNT_3_3_MODAL_KEY,
        theme: 'dark',
        // title: `Amount of keys`,
        className: styles.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <ModalAmountBTC
            selectKeys={selectKeys}
            onConfirm={handleSubmit33}
            setSelectKeys33={setSelectKeys33}
            setEstimateKeysTC33={setEstimateKeysTC33}
            setEstimateTC33={setEstimateTC33}
            userProfile={userProfile}
          />
        ),
      })
    );
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      let txs = [];

      if (isInvite) {
        txs = (await requestTrade33KeysSameAmount(
          selectKeys33
        )) as IResponseBuySweetFloorKeys[];
      } else {
        txs = (await buySweetFloorKeys(
          selectKeys
        )) as IResponseBuySweetFloorKeys[];
      }

      setWaitKeys(txs);
    } catch (error: any) {
      showError(getErrorMessageContract(error));
    } finally {
      dispatch(requestReload());
      // setSubmitting(false);
    }
  };

  const onChangeSweep = (_numSweep: any) => {
    if (Number(_numSweep) <= keys.length) {
      const _selectKeys = keys.slice(0, Number(_numSweep));
      setSelectKeys(
        _selectKeys.map(v => ({
          token_address: v.address as string,
          token_amount: 1,
          data: v,
        }))
      );
    }
  };

  const onRefresh = async () => {
    if (!refInitial.current) {
      return;
    }
    try {
      setRefreshing(true);
      refParams.current = {
        ...refParams.current,
        page: 1,
      };
      await fetchData(true);
    } catch (error) {
      console.log('refreshing err', error);
    } finally {
      setRefreshing(false);
    }
  };

  const amountKeys = selectKeys?.length * selectKeys[0]?.token_amount;

  return (
    <>
      <Flex
        className={clsx(styles.formContainer, styles.proTradeContainer)}
        direction={'column'}
        alignItems={'center'}
        gap={0}
        flex={1}
      >
        {/* <Flex
          bg={'rgba(255, 255, 255, 0.1)'}
          justifyContent={'space-between'}
          alignItems={'center'}
          height={'52px'}
          width={'100%'}
          px={'20px'}
          py={'4px'}
        >
          <Text
            style={{
              fontSize: '12px',
              fontWeight: 400,
              opacity: 1,
            }}
            flex={1}
          >
            <span
              style={{
                opacity: 0.7,
              }}
            >
              TVL
            </span>{' '}
            <b>{formatCurrency(tvlBalance, 2, 2)} BTC</b>
          </Text>
          <Text
            style={{
              fontSize: '12px',
              fontWeight: 400,
              opacity: 1,
            }}
            textAlign={'center'}
            flex={1}
          >
            <span
              style={{
                opacity: 0.7,
              }}
            >
              Volume
            </span>{' '}
            <b>{formatCurrency(summaryReport?.total_volume, 2, 2)} BTC</b>
          </Text>
          <Text
            style={{
              fontSize: '12px',
              fontWeight: 400,
              opacity: 1,
            }}
            textAlign={'right'}
            flex={1}
          >
            <span
              style={{
                opacity: 0.7,
              }}
            >
              Trades
            </span>{' '}
            <b>{abbreviateNumber(summaryReport?.total_transaction)}</b>
          </Text>
        </Flex> */}
        <ProTradeKeyHeader
          onFilter={onFilter}
          setSearching={setSearching}
          searching={searching}
          isNotAllowFilter={isFetching || refreshing || searching || submitting}
          sorts={SORTS}
          filters={FILTER_TYPES}
        />
        <ScrollWrapper
          onFetch={() => {
            refParams.current = {
              ...refParams.current,
              page: refParams.current.page + 1,
            };
            hasIncrementedPageRef.current = true;
            fetchData();
          }}
          isFetching={isFetching || refreshing}
          hasIncrementedPageRef={hasIncrementedPageRef}
          onFetchNewData={onRefresh}
        >
          <ListTable
            data={keys}
            columns={columns}
            showEmpty={!isFetching}
            emptyLabel={
              <Text textAlign={'center'}>
                Your filters produced no results.
                <br />
                Try adjusting or clearing your filters to display better
                results.
              </Text>
            }
            initialLoading={filtering}
            // onItemClick={e => onCheckBox(e)}
          />
        </ScrollWrapper>

        <Footer
          ref={refFooter}
          sweepMax={keys.length}
          selectKeys={selectKeys}
          onSubmit={onBeforeSubmit}
          estimateTC={estimateTC}
          estimating={estimating}
          submitting={submitting}
          onChangeSweep={onChangeSweep}
          allowBuy={allowBuy}
          numProcessDone={numProcessDone}
          isInvite={isInvite}
          showInvite={false}
        />
      </Flex>

      <Drawer
        closeOnEsc={!submitting}
        closeOnOverlayClick={!submitting}
        placement={'bottom'}
        onClose={onClose}
        isOpen={isOpen}
        onCloseComplete={isDone ? onDoneAndClose : undefined}
      >
        <DrawerOverlay />
        <DrawerContent className={styles.confirmModal}>
          <DrawerHeader borderBottomWidth="1px">
            {isInvite ? 'Sending (3,3) requests' : 'Buy keys'}
            {/* {isDone && (
              <DrawerCloseButton
                fontSize={'14px'}
                mt={4}
                mr={4}
                onClick={() => {
                  refDone.current = true;
                  refFooter.current?.reset?.();
                  dispatch(requestReload());
                  onRefresh();
                  setSelectKeys([]);
                  setSubmitting(false);
                  setIsDone(false);
                  onClose();
                }}
              />
            )} */}
          </DrawerHeader>
          <DrawerBody>
            {submitting || isDone ? (
              <Buying
                keys={keys}
                selectKeys={isInvite ? selectKeys33 : selectKeys}
                waitKeys={waitKeys}
                estimateKeysTC={isInvite ? estimateKeysTC33 : estimateKeysTC}
                setNumProcessDone={setNumProcessDone}
                isInvite={isInvite}
              />
            ) : (
              <>
                {!isInvite && (
                  <>
                    <HorizontalItem
                      label={
                        <Text fontSize={px2rem(16)} fontWeight={500}>
                          Amount:
                        </Text>
                      }
                      value={`${formatCurrency(
                        amountKeys,
                        MIN_DECIMAL,
                        MAX_DECIMAL
                      )} key${labelAmountOrNumberAdds(amountKeys)}`}
                    />
                    <Box py={4} />
                  </>
                )}
                <HorizontalItem
                  label={
                    <Text fontSize={px2rem(16)} fontWeight={500}>
                      Total:
                    </Text>
                  }
                  value={`${formatCurrency(
                    isInvite ? estimateTC33 : estimateTC,
                    0,
                    6
                  )} BTC`}
                />
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            {submitting || isDone ? (
              <Button
                isDisabled={submitting}
                onClick={onDoneAndClose}
                className={styles.btnConfirm}
              >
                {submitting
                  ? `Processing ${numProcessDone} / ${selectKeys.length}`
                  : 'Close'}
              </Button>
            ) : (
              <Button onClick={onSubmit} className={styles.btnConfirm}>
                {isInvite ? 'Send' : 'Confirm'}
              </Button>
            )}

            {isInvite && (
              <Text>
                (3,3) runs on a smart contract that arranges the key swap
                transaction trustlessly and holds the keys for 30 days.
              </Text>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ProTradeKeyModule;
