import { API_FOC, PERP_API_URL } from '@/configs';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';
import createAxiosInstance from './http-client';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { IPostData } from '@/interfaces/api/post';
import { IActivityItem } from '@/interfaces/api/activity';

const apiClient = createAxiosInstance({
  baseURL: PERP_API_URL,
});

const focClient = createAxiosInstance({
  baseURL: API_FOC,
});

export const setBearerToken = (token: string | string[]) => {
  if (token && apiClient) {
    apiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};

setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

export const getPosts = async ({
  page,
  limit,
  parentId,
  address,
  player_address,
  only_following,
  activity,
}: {
  page: number;
  limit: number;
  parentId?: number;
  address?: string;
  player_address?: string;
  only_following?: boolean;
  activity?: string;
}): Promise<Array<IPostData>> => {
  const res = await apiClient.get(
    `/api/tweet/list?network=nos&version=2&page=${page}&limit=${limit}${
      parentId ? `&parent_id=${parentId}` : ''
    }${address ? `&address=${address}` : ''}${
      player_address ? `&player_address=${player_address}` : ''
    }${only_following ? `&only_following=${only_following}` : ''}${
      activity ? `&activity=${activity}` : ''
    }`
  );
  return res as unknown as Array<IPostData>;
};

export const getPostDetail = async (id: number, address?: string) => {
  const res = await apiClient.get(
    `api/tweet/detail/${id}?network=nos&address=${address}`
  );
  return res;
};

export const createTweet = async (
  params: {
    content: string;
    parent_id?: number;
    animation_url?: string;
    user_address?: string;
    media_urls?: string[];
  },
  address: string
) => {
  const res = await apiClient.post(
    `/api/tweet?network=nos&address=${address}`,
    params
  );
  return res;
};

export const createTweetGame = async (params: {
  content: string;
  wallet_address: string;
  activity: string;
  airdrop_receivers: any;
  airdrop_token_address: string;
  airdrop_tx_hash: string;
}) => {
  const res = await apiClient.post(
    `/api/tweet/activity?network=nos&address=${params.wallet_address}`,
    params
  );
  return res;
};

export const createTweetGameToFriend = async (params: {
  content: string;
  wallet_address: string;
  activity: string;
  airdrop_receivers: any;
  airdrop_token_address: string;
  airdrop_tx_hash: string;
}) => {
  const res = await apiClient.post(
    `/api/tweet/airdrop-to-friend?network=nos&address=${params.wallet_address}`,
    params
  );
  return res;
};

export const createTweetNotiToTribeCircle = async (
  params: {
    game_type: string;
    twitter_id: string;
    content: string;
  },
  wallet_address: string
) => {
  const res = await apiClient.post(
    `/api/alpha-keys/user/noti-circle?network=nos&address=${wallet_address}`,
    params
  );
  return res;
};

export const deleteTweet = async (postId: string, address: string) => {
  const res = await apiClient.delete(
    `/api/tweet/${postId}?network=nos&address=${address}`
  );
  return res;
};

export const permissionUploadMedia = async (twitter_id: string) => {
  const res = await apiClient.get(
    `/api/tw/valid/post-image?network=nos&twitter-id=${twitter_id}`
  );
  return res;
};

export const editTweet = async (payload: {
  id: number;
  address: string;
  content: string;
}) => {
  const res = await apiClient.put(
    `/api/tweet/${payload.id}?network=nos&address=${payload?.address}`,
    {
      content: payload.content,
    }
  );
  return res;
};

export const submitLike = async (payload: {
  id: number;
  address: string;
  reaction_type: number;
}) => {
  const res = await apiClient.post(
    `/api/tweet/like/${payload.id}?network=nos&address=${payload.address}`,
    { reaction_type: payload.reaction_type }
  );
  return res;
};

export const reTweet = async (payload: {
  postId: number;
  address: string;
  public: boolean;
}) => {
  const res = await apiClient.post(
    `/api/tweet/repost?network=nos&address=${payload.address}`,
    {
      post_id: payload.postId,
      public: payload.public,
    }
  );
  return res;
};

export const fetchMetaData = async (url: string) => {
  const res = await focClient.post(`/seo/hyperlinks`, {
    url,
  });

  return camelCaseKeys(Object(res));
};

export const getLastReaction = async ({
  postId,
  page,
  limit,
  address,
  network = 'nos',
}: {
  postId: number;
  page: number;
  limit: number;
  parentId?: number;
  address?: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.get(
      `/api/tweet/last-reaction/${postId}?network=${network}&page=${page}&limit=${limit}&address=${address}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const submitTweetTip = async ({
  postId,
  address,
  amount,
  txHash,
  network = 'nos',
}: {
  postId: number;
  address: string;
  amount: string;
  txHash: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.post(
      `/api/tweet/tips/${postId}?network=${network}&address=${address}`,
      {
        amount: `${amount}`,
        tx_hash: txHash,
      }
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getTweetTip = async ({
  postId,
  address,
  network = 'nos',
}: {
  postId: number;
  address: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.get(
      `/api/tweet/tips/${postId}?network=${network}&address=${address}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const submitChatMessageTip = async ({
  messageId,
  address,
  amount,
  txHash,
  roomId,
  network = 'nos',
}: {
  messageId: string;
  address: string;
  amount: string;
  txHash: string;
  roomId: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.post(
      `/api/tips/chat-messages/${messageId}?network=${network}&address=${address}`,
      {
        amount: `${amount}`,
        tx_hash: txHash,
        room_id: roomId,
      }
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getChatMessageTip = async ({
  messageId,
  address,
  network = 'nos',
}: {
  messageId: string;
  address: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.get(
      `/api/tips/chat-messages/${messageId}?network=${network}&address=${address}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getTipTotal = async ({
  twitterId,
  address,
  network = 'nos',
}: {
  twitterId: string;
  address: string;
  network?: string;
}) => {
  try {
    const res = await apiClient.get(
      `/api/tips/users/${twitterId}/total_tips?network=${network}&address=${address}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const pinPost = async ({
  tweetId,
  twitterId,
  network,
  address,
}: {
  tweetId: string;
  twitterId: string;
  network: string;
  address: string;
}) => {
  try {
    const res = await apiClient.post(
      `/api/tweet/pinned?network=${network}&twitter_id=${twitterId}&address=${address}`,
      {
        tweet_id: tweetId,
      }
    );
    return camelCaseKeys(Object(res));
  } catch (err: unknown) {
    console.log(err);
  }
};

export const unpinPost = async ({
  tweetId,
  twitterId,
  network,
  address,
}: {
  tweetId: string;
  twitterId: string;
  network: string;
  address: string;
}) => {
  try {
    const res = await apiClient.post(
      `/api/tweet/unpinned?network=${network}&twitter_id=${twitterId}&address=${address}`,
      {
        tweet_id: tweetId,
      }
    );
    return camelCaseKeys(Object(res));
  } catch (err: unknown) {
    console.log(err);
  }
};

export const getPinnedPosts = async ({
  twitterId,
  network,
  address,
  activity,
}: {
  twitterId: string;
  network: string;
  address: string;
  activity: string;
}) => {
  try {
    const res = await apiClient.get(
      `/api/tweet/pinned?network=${network}&twitter_id=${twitterId}&address=${address}&activity=${activity}`
    );
    return res;
  } catch (err: unknown) {
    console.log(err);
  }
};

export const getActivityListTopBar = async ({
  page,
  limit,
  address,
  activity,
  network = 'nos',
}: {
  page: number;
  limit: number;
  address?: string;
  network?: string;
  activity?: string;
}): Promise<Array<IActivityItem>> => {
  try {
    const res = await apiClient.get(
      `/api/tweet/list-by-activity?page=${page}&limit=${limit}&network=${network}&address=${address}&activity=${activity}`
    );
    return camelCaseKeys(Object(res));
  } catch (err: unknown) {
    console.log(err);
    return [];
  }
};

export const checkJoinedWheel = async (
  twitter_id: string,
  event_id: number
) => {
  try {
    const res = await apiClient.get(
      `/api/check-wheel-join?event_id=${event_id}&twitter_id=${twitter_id}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
  }
};

export const checkJoinedRaffle = async (
  twitter_id: string,
  event_id: number
) => {
  try {
    const res = await apiClient.get(
      `/api/check-raffle-join?event_id=${event_id}&twitter_id=${twitter_id}`
    );
    return camelCaseKeys(Object(res));
  } catch (error) {
    console.log(error);
  }
};

export const reportPost = async ({
  tweetId,
  twitterId,
  address,
  network = 'nos',
}: {
  tweetId: string;
  twitterId: string;
  network?: string;
  address: string;
}) => {
  try {
    const res = await apiClient.post(
      `/api/tweet/report?network=${network}&twitter_id=${twitterId}&address=${address}`,
      {
        id: tweetId,
      }
    );
    return camelCaseKeys(Object(res));
  } catch (err: unknown) {
    console.log(err);
  }
};

export const getReelVideos = async ({
  page,
  limit,
  address = '',
  network = 'nos',
}: {
  page: number;
  limit: number;
  address?: string;
  network?: string;
}): Promise<Array<IPostData>> => {
  try {
    const res = await apiClient.get(
      `/api/tweet/list-by-reels?version=2&page=${page}&limit=${limit}&network=${network}&address=${address}`
    );
    return res as any;
  } catch (err: unknown) {
    console.log(err);
    return [];
  }
};

export const shareTweetCount = async ({
  tweet_id,
  address = '',
}: {
  tweet_id: number;
  address?: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post(
      `/api/tweet/share/${tweet_id}?network=nos&address=${address}`,
      {}
    );
    return res as any;
  } catch (err: unknown) {
    console.log(err);
    return [];
  }
};
