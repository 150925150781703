import React, { useContext, useMemo, useRef, useState } from 'react';
import s from './styles.module.scss';
import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import { WalletContext } from '@/contexts/wallet-context';
import { claimLuckyMoney } from '@/services/donate';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import DonateResultModal, { DONATE_RESULT_MODAL } from '../DonateResultModal';
import { DonateResult } from '@/constants/donate';
import toast from 'react-hot-toast';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import cs from 'classnames';
import { IPublicEventItem } from '@/interfaces/api/donate';
import { RECEIVED_REFERRAL_DONATION } from '@/constants/storage-key';
import errorLogger from '@/services/errorLogger';

export const RED_ENVELOP_MODAL: string = 'RED_ENVELOP_MODAL';

interface IProps {
  onClose: () => void;
  event: IPublicEventItem;
}

const RedEnvelopModal: React.FC<IProps> = ({
  event,
  onClose,
}: IProps): React.ReactElement => {
  const {
    total,
    remain,
    donateUser,
    eventId,
  } = event;
  const taken = total - remain;
  const { addressL2 } = useContext(WalletContext);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();

  const formattedTakenCount = useMemo(() => {
    return taken >= 0 ? taken : 0;
  }, [taken]);

  const handleCloseModal = () => {
    dispatch(closeModal({ id: DONATE_RESULT_MODAL }));
  };

  const handleClaim = async () => {
    if (loading || !addressL2 || !donateUser.address || !eventId) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.RED_PACKAGE,
        address: addressL2,
        error: 'Invalid information',
        info: JSON.stringify({
          loading,
          addressL2,
          senderAddress: donateUser.address,
          eventId
        }),
      });
      return;
    }

    setLoading(true);
    try {
      const res = await claimLuckyMoney({
        network: 'nos',
        address: addressL2,
        playerAddress: donateUser.address,
        eventId: eventId,
      });

      if (res && !res.errorCode && res.token !== null && res.amount && parseFloat(res.amount) > 0) {
        dispatch(
          openModal({
            id: DONATE_RESULT_MODAL,
            theme: 'dark',
            modalProps: {
              centered: true,
              zIndex: 9999999,
            },
            className: 'donate_modal',
            hideCloseButton: true,
            render: () => (
              <DonateResultModal
                senderName={res.fromUser?.twitterName || ''}
                fromUserAddress={res.token?.address}
                fromUserAvatar={res.token?.userTwitterAvatar || res.token?.avatar}
                fromUserName={res.token?.userTwitterName}
                amount={res.amount}
                result={DonateResult.Success}
                onClose={handleCloseModal}
              />
            ),
          })
        );
        gaEventTracker(AlphaActions.ClaimSuccess, addressL2);
        onClose();
        localStorage.setItem(RECEIVED_REFERRAL_DONATION, 'true');
      }
      // Pass token 
      else if (res && !res.errorCode && res.passToken !== null && res.amount && parseFloat(res.amount) > 0) {
        dispatch(
          openModal({
            id: DONATE_RESULT_MODAL,
            theme: 'dark',
            modalProps: {
              centered: true,
              zIndex: 9999999,
            },
            className: 'donate_modal',
            hideCloseButton: true,
            render: () => (
              <DonateResultModal
                senderName={res.fromUser?.twitterName || ''}
                fromUserAddress={res.passToken?.address}
                fromUserAvatar={res.passToken?.userTwitterAvatar || res.passToken?.avatar}
                fromUserName={res.passToken?.userTwitterName}
                amount={res.amount}
                result={DonateResult.Success}
                onClose={handleCloseModal}
              />
            ),
          })
        );
        gaEventTracker(AlphaActions.ClaimSuccess, addressL2);
      }
      else {
        dispatch(
          openModal({
            id: DONATE_RESULT_MODAL,
            theme: 'dark',
            modalProps: {
              centered: true,
              zIndex: 9999999,
            },
            hideCloseButton: true,
            render: () => (
              <DonateResultModal
                result={DonateResult.Fail}
                onClose={handleCloseModal}
                errorCode={res.errorCode}
              />
            ),
          })
        );
        gaEventTracker(AlphaActions.ClaimFail, addressL2);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      gaEventTracker(AlphaActions.ClickClaim, addressL2);
    }
  };

  return (
    <div className={s.modalWrapper}>
      <div
        className={cs(
          s.success_wrapper,
        )}
      >
        <div className={s.background}>
          <img
            src={`${CDN_URL_IMAGES}/evelope-bg.png`}
            alt="Red envelope background"
          />
        </div>
        <div className={s.content}>
          <div className={s.imgWrapper}>
            <img
              className={s.icYolo}
              src={`${CDN_URL_ICONS}/ic-yolo.png`}
              alt="ic-yolo"
            />
          </div>
          <h2 className={s.title}>Red packet to {donateUser.twitterName} referees</h2>
          <p className={s.description}>
            {formattedTakenCount}/{total}
          </p>
          <button
            onClick={handleClaim}
            disabled={loading}
            className={s.openBtn}
          >
            {loading ? '...' : 'Tap to snatch'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RedEnvelopModal);
