import { LanguageSupportedEnum } from '@/interfaces/api/chat';
import { context } from '@/providers/LanguageProvider/constants';
import {
  getStorageLanguage,
  writeStorageLanguage,
} from '@/providers/LanguageProvider/helpers';
import { DEFAULT_LANGUAGE } from '@/services/chat/helpers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const LanguageProvider = ({ children }: { children: any }) => {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    setLanguage(getStorageLanguage());
  }, []);

  // useEffect(() => {
  //   const handler = () => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     // console.log(JSON.parse(window.localStorage.getItem('sampleList')));
  //   };
  //   window.addEventListener('storage', handler);

  //   return () => {
  //     window.removeEventListener('storage', handler);
  //   };
  // }, []);

  const updateCurrentLanguage = useCallback((lang: LanguageSupportedEnum) => {
    writeStorageLanguage(lang);
    setLanguage(lang);
  }, []);

  const values = useMemo(() => {
    return {
      language,
      updateCurrentLanguage,
    };
  }, [language, updateCurrentLanguage]);

  return <context.Provider value={values}>{children}</context.Provider>;
};

export default LanguageProvider;

export const useLanguage = () => {
  return useContext(context);
};
