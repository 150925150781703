type ChatBasic = {
  id: string;
  createdAt: string;
  updatedAt: string;
  lastMessage?: ChatMessageResponse;
  lastSeen?: string;
  isActive: boolean;
  unreadCount: number;
  remain: number;
  tokenReceiver: string;
  tokenReward: string;
  quantity: string;
  mute: boolean;
  pinned: boolean;
  metadata?: string;
};

export type ChatPeerToPeerResponse = ChatBasic & {
  to: string;
};

export enum LanguageSupportedEnum {
  OFF = '',
  ENGLISH = 'en',
  CHINESE_SIMPLIFIED = 'zh-cn',
  CHINESE_TRADITIONAL = 'zh-tw',
  JAPANESE = 'ja',
}

export enum LanguageBackEndEnum {
  OFF = 0,
  ENGLISH = 1,
  CHINESE_SIMPLIFIED = 2,
  CHINESE_TRADITIONAL = 3,
  JAPANESE = 4,
}

export type ChatGroupResponse = ChatBasic & {
  owner: string;
  members: ChatGroupMember[];
  memberCount: number;
  isTribe: boolean;
  mute: boolean;
  isCalling: boolean;
  jamSessionId?: string;
  jamChannelName?: string;
  language: LanguageSupportedEnum;
  setting: { allowFtJoin: boolean };
  callToken?: string;
  callUid?: string;
  type: number;
};

export type ChatTribeResponse = ChatGroupResponse & {
  isTribe: boolean;
  type: number;
};

export type ChatTypeResponse = 'CHAT_ROOM' | 'CHAT_GROUP' | 'CHAT_TRIBE';

export type OwnerInfo = {
  address: string;
  id: string;
  isChatBlocked: boolean;
  tokenAddress: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export enum ChatRoomKindEnum {
  // please put the chat room kind in here
  EVERYONE = 4,
}

export type ChatRoomResponse = ChatBasic & {
  owner: string;
  members: ChatGroupMember[];
  type: ChatTypeResponse;
  mute: boolean;
  memberCount: number;
  isCalling: boolean;
  isTribe: boolean;
  jamSessionId?: string;
  jamChannelName?: string;
  language: LanguageSupportedEnum;
  ownerInfo?: OwnerInfo;
  callToken?: string;
  callUid?: string;
  setting: { allowFtJoin: boolean };
  kind?: ChatRoomKindEnum;
  roles?: Array<string>;
};

export type GetRoomPayload = {
  page: number;
  limit: number;
  ownerToken?: string;
};

export enum MessageTypeEnum {
  NORMAL = 0,
  ADD_MEMBER = 1,
  REMOVE_MEMBER = 2,
  CREATE_DONATION = 3,
  CLAIM_DONATION = 4,
  BUY_EVENT = 5,
  WHEEL_EVENT = 6,
  TOURNAMENT_SCORE = 500,
  TOURNAMENT_OPEN = 510,
  TOURNAMENT_CLOSE = 520,
  RAFFLE_EVENT = 7,
  BULL_BEAR_EVENT = 8,
  BULL_BEAR_JOIN_EVENT = 9,
  GAME_EVENT = 10,
  TIP_EVENT = 11,
  TIP_EVENT_OPENED = 12,
  JAM_STARTED = 13,
  JAM_JOINED = 14,
  STAKING = 1000,
  MINT_MESSAGE = 200,
  LIVE_TOURNAMENT_OPEN = 600,
  LIVE_TOURNAMENT_CLOSE = 610,
}

export enum MessageGameType {
  BULL_BEAR = 'bull-bear',
  STAKING = 'staking',
  SPIN_WHEEL = 'spin-wheel',
  SPIN_WHEELV2 = 'spin-wheel-v2',
  CHART = 'chart',
  AIRDROP = 'airdrop',
  TOKEN_BURNED = 'token_burned',
  TOKEN_DONATE = 'token_donate',
  STAKING_TOKEN = 'token_staking',
  STAKING_TOKEN_VER2 = 'token_staking_v2',
  SWAP_UNI = 'swap_uni',
  PROPOSAL = 'proposal',
}

export enum MessageGameBullBearAction {
  END = 'end',
  CREATE = 'create',
  JOIN = 'join',
}

export enum MessageStakingAction {
  STAKE = 'stake',
  UN_STAKE = 'unstake',
  CREATE_POOL = 'create-pool',
}

export type ChatMessageResponse = {
  id: string;
  roomId: string;
  from: string;
  content: string;
  contentEn: string;
  contentCn: string;
  contentTw: string;
  contentJp: string;
  createdAt: string;
  updatedAt: string;
  parentId?: string;
  mediaUrls: string[];
  type: MessageTypeEnum;
  tokenGroupOwner: string;
  tokenCreator: string;
  num: number;
  mentions: string[];
  forwardFromId?: string;
  metadata?: string;
};

export type AddChatMessageMetadata = {
  txHash?: string;
  signedData?: {
    timestamp: number;
    signature: string;
  };
};

export type AddChatMessagePayload = {
  roomId: string;
  content: string;
  parentId?: string;
  mediaUrls: string[];
  mentions?: string[];
  metadata?: string;
  private?: boolean;
};

export type GetChatMessageByTimePayload = {
  limit: number;
  roomId: string;
  timeFrom?: string;
  reverse?: boolean;
};

export type ChatGroupMember = {
  address: string;
  twitterId: string;
  twitterName: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  twitterUsername: string;
  network?: string;
  isChatBlocked?: boolean;
  tokenAddress?: string;
  sender?: TipBase;
  receiver?: TipBase;
  roles: Array<string>;
};

export enum CallRoleEnum {
  SPEAKER = 0,
  AUDIENCE = 1,
}

export type JoinCallResponse = {
  appId: string;
  channelName: string;
  result: boolean;
  sessionId: string;
  token: string;
  uid: string;
  role: CallRoleEnum;
};

export type AgoraMappingUserResponse = {
  id: string;
  token: string;
  twitterId: string;
  agoraUid: string;
  agoraNickname: string;
  agoraUsername: string;
  createdAt: string;
  updatedAt: string;
};

export type ReportGroupMemberResponse = {
  result: boolean;
  reportCount: number;
};

export type TipBase = {
  id: number;
  address: string;
  twitter_avatar: string;
  twitter_followers_count: number;
  twitter_id: string;
  twitter_name: string;
  twitter_username: string;
};

export interface ICreateTribeRoomPayload {
  tribeType: number;
  passCode: string;
  collectionAddress: string;
  metadata: string;
}

export type ICreateTribeRoomResponse = ChatTribeResponse;

export interface ICheckNftGatedParams {
  nbc_address: string;
  token_address: string;
}

export interface ICheckNftGatedResponse {
  status: boolean;
}

export interface IJoinRoomPayload {
  passCode: string;
  roomId: string;
}

export interface IRecentActiveMember {
  address: string;
  twitterId: string;
  twitterUsername: string;
  twitterName: string;
  twitterAvatar: string;
  twitterFollowersCount: number;
  id: string;
  tokenAddress: string;
  isChatBlocked: boolean;
}

export type PromptFeeResponse = {
  prompt: string;
  fee: string;
  feeToken: string;
  botAddress: string;
  feeAddress: string;
  private: boolean;
  description?: string;
};

export type SetModePayload = {
  roomId: string;
  isModerator: boolean;
  userToken: string;
};

export type ForwardChatMessagePayload = {
  messageId: string;
  roomIds: string[];
};
