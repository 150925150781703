import CountDownText from '@/components/CountdownText';
import { AssetsContext } from '@/contexts/assets-context';
import { GameFiContext } from '@/contexts/game-fi-context';
import { TournamentContext } from '@/contexts/tournament-context';
import { WalletContext } from '@/contexts/wallet-context';
import CPlayerShare from '@/contracts';
import useGetPlayerData from '@/hooks/jackpot/useGetPlayerData';
import useJackpotContractSigner from '@/hooks/useJackpotContractSigner';
import { getPlayerPoolProfile, getTokenHolding } from '@/services/player-share';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import useAnalyticsEventTracker, { GameActions } from '@/utils/ga';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import Web3 from 'web3';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import PrizeInfoModal, { PRIZE_INFO_MODAL_ID } from '../PrizeInfoModal';
import { GameName, UnityIFrameStatus } from '../constants';
import { ICircleTournament } from './circleTournament';
import s from './styles.module.scss';

import { CDN_URL_ICONS } from '@/configs';
import { GroupRoomType, IJackPotInfo } from '@/interfaces/api/gamefi';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import IcCoin from '@/modules/AlphaPWA/PassToken/Components/icCoin';
import { CountryBlock } from '@/pages/country-block';
import CPassTokenAPI from '@/services/classes/passToken';
import { formatCurrency } from '@/utils';
import { getUrlToSwap } from '@/utils/helpers';
import { Button, Flex, Text } from '@chakra-ui/react';
import RewardsModal, { REWARDS_MODAL_ID } from '../RewardsModal';
import WarningInfoModel, { WARNING_INFO_MODAL_ID } from '../WarningInfoModal';
import TokenDisplay from '../components/TokenDisplay';
import useTokenBalance from '../hooks/useTokenBalance';
import SvgInset from '@/components/SvgInset';
import errorLogger, { ERROR_LOGGER_LEVEL } from '@/services/errorLogger';

const CountUp = dynamic(() => import('react-countup'), { ssr: false });

const JACKPOT_TC_FEE = 0.03;

const CircleTournament: React.FC<ICircleTournament> = ({
  isCountryBlocked,
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();
  const {
    deadline,
    jackpot,
    gameInfo,
    usdVal,
    topAlpha,
    rank,
    lastScore,
    circleTournamentInfo,
    nPlayTarget,
    bestScore,
    circleAddress,
    ownerCircleAddress,
  } = useContext(TournamentContext);
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const NOT_HOLD_KEY_MODAL = 'NOT_HOLD_KEY_MODAL';
  const { playerPoolProfile, balanceL2 } = useContext(AssetsContext);

  const { updateIsPlayingGame, unityIframeStatus, openGame } =
    useContext(GameFiContext);

  const playerShareContract = new CPlayerShare();
  const jackpotContractSigner = useJackpotContractSigner();

  const { playerInfo, loading } = useGetPlayerData();
  const isProcessing = useRef<boolean>(false);
  const passTokenAPI = useRef(new CPassTokenAPI()).current;
  const { tokenBalance, getTokenBalance } = useTokenBalance();

  const { extra } = router.query as {
    isTournament: string;
    circleAddress: string;
    extra: string;
  };

  useEffect(() => {
    _fetchUserToken();
    const intervalFetchBalance = setInterval(() => {
      _fetchUserToken();
    }, 5000);
    return () => clearInterval(intervalFetchBalance);
  }, [circleTournamentInfo]);

  useEffect(() => {
    console.log('tokenBalance:::', tokenBalance);
  }, [tokenBalance]);

  const _fetchUserToken = () => {
    if (circleTournamentInfo?.potToken) {
      getTokenBalance(circleTournamentInfo?.potToken);
    }
  };

  const handleBuyKey = (profile: IGetPlayerPoolProfile, isBuyKey: boolean) => {
    dispatch(closeModal({ id: NOT_HOLD_KEY_MODAL }));

    if (isBuyKey) {
      router.push(getUrlToSwap(ownerCircleAddress));
    } else {
      router.push(getUrlToSwap(profile.address, '', 0, true));
    }
  };

  // useEffect(() => {
  //   if (minimumRequiredToken) {
  //     showNotAllowNotHolder();
  //   }
  // }, [minimumRequiredToken]);

  const showNotAllowNotHolder = async (minimum: string, isBuyKey: boolean) => {
    const playerPoolProfile = await getPlayerPoolProfile(ownerCircleAddress);

    const getKeyText = () => {
      try {
        if (Number(minimum) === 1) {
          return isBuyKey ? 'key' : 'token';
        }
      } catch {
        //
      }
      return isBuyKey ? 'keys' : 'tokens';
    };

    const getIcon = () => {
      if (isBuyKey) {
        return (
          <img
            src={`${CDN_URL_ICONS}/IcKeyWheel2.svg`}
            alt="menu-ic"
            style={{ height: '80px' }}
          />
        );
      }
      return (
        <div className={s.customSvg}>
          <IcCoin />
        </div>
      );
    };

    dispatch(
      openModal({
        id: NOT_HOLD_KEY_MODAL,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: true,
        render: () => (
          <Flex direction={'column'} justifyContent={'center'}>
            {getIcon()}
            <Text
              mt={8}
              mb={8}
              fontSize={'18px'}
              fontWeight={400}
              textAlign={'center'}
            >
              Hold at least {formatCurrency(Number(minimum), 0, 0)}{' '}
              <p style={{ color: '#fff' }}>
                {playerPoolProfile.twitterName} {getKeyText()} to join the
                tournament
              </p>
            </Text>
            <Button
              onClick={e => {
                e.stopPropagation();
                handleBuyKey(playerPoolProfile, isBuyKey);
              }}
            >
              Buy {isBuyKey ? 'keys' : 'tokens'}
            </Button>
          </Flex>
        ),
      })
    );
  };

  const isQualifiedToJoinTournament = async (
    circleTournamentInfo: IJackPotInfo
  ) => {
    if (circleTournamentInfo.tournamentType == GroupRoomType.KEY_CIRCLE) {
      const requiredKeys = Web3.utils.fromWei(
        circleTournamentInfo.balanceRequirement ?? '0',
        'ether'
      );

      const res = await getTokenHolding({
        address: addressL2 as string,
        player_address: ownerCircleAddress as string,
      });
      const myKeys = res[0]?.balance ?? 0;

      return {
        isQualified: Number(myKeys) >= Number(requiredKeys),
        requiredAmount: requiredKeys,
      };
    }

    const requiredToken = Web3.utils.fromWei(
      (
        BigInt(circleTournamentInfo.balanceRequirement ?? '0') +
        BigInt(circleTournamentInfo?.ticketPrice ?? '0')
      ).toString(),
      'ether'
    );

    console.log('requiredToken$$$$$$', requiredToken);

    return {
      isQualified: Number(tokenBalance) >= Number(requiredToken),
      requiredAmount: requiredToken,
      tokenBalance: tokenBalance,
    };
  };

  const handleClickPlayFT = async () => {
    if (isCountryBlocked) {
      dispatch(
        openModal({
          id: WARNING_INFO_MODAL_ID,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          render: () => <CountryBlock />,
        })
      );
      return;
    }
    if (
      !circleTournamentInfo?.isOpening ||
      Date.now() / 1000 >= parseInt(circleTournamentInfo.endAt)
    ) {
      toast.error('Tournament has ended!');
      return;
    }

    if (isProcessing.current) return;
    isProcessing.current = true;

    const { isQualified, requiredAmount, tokenBalance } =
      await isQualifiedToJoinTournament(circleTournamentInfo);

    errorLogger.report({
      action: GameActions.CreateTournamentFail,
      address: addressL2,
      error: '',
      info: JSON.stringify({
        isQualified,
        requiredAmount,
        tokenBalance,
        circleTournamentInfo: circleTournamentInfo,
      }),
      level: ERROR_LOGGER_LEVEL.Info,
    });

    if (!isQualified) {
      showNotAllowNotHolder(
        requiredAmount.toString(),
        circleTournamentInfo.tournamentType == GroupRoomType.KEY_CIRCLE
      );
      isProcessing.current = false;
      return;
    }

    dispatch(closeModal({ id: NOT_HOLD_KEY_MODAL }));

    let timeNow = Date.now() / 1000;
    if (
      !circleTournamentInfo?.isOpening ||
      parseInt(circleTournamentInfo?.endAt) - timeNow < 300
    ) {
      dispatch(
        openModal({
          id: WARNING_INFO_MODAL_ID,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          render: () => <WarningInfoModel onPlay={handleClickPlay} />,
        })
      );
      isProcessing.current = false;
      return;
    }

    isProcessing.current = false;

    handleClickPlay();
  };

  const handleClickPlay = async () => {
    dispatch(closeModal({ id: WARNING_INFO_MODAL_ID }));
    gaEventTracker(GameActions.PressPlay, JSON.stringify(circleTournamentInfo));

    // const ownerPlayerPoolProfile =
    //   await getPlayerPoolProfile(ownerCircleAddress);

    // const _balanceRequirement =
    //   BigInt(circleTournamentInfo?.ticketPrice ?? '0') +
    //   BigInt(
    //     Web3.utils.toWei(
    //       (
    //         ownerPlayerPoolProfile?.passTokenMinHoldingRequirement ?? 0
    //       ).toString(),
    //       'ether'
    //     )
    //   );

    const _balanceRequirement = BigInt(
      circleTournamentInfo?.ticketPrice ?? '0'
    );

    console.log('_balanceRequirement', _balanceRequirement);

    if (BigInt(Web3.utils.toWei(tokenBalance, 'ether')) < _balanceRequirement) {
      // toast.error(`Your ${tournamentUnit} is not enough!`);
      gaEventTracker(
        GameActions.NotEnoughBTC,
        JSON.stringify(circleTournamentInfo)
      );

      showNotAllowNotHolder(
        Web3.utils.fromWei(_balanceRequirement.toString()),
        false
      );
      return;
    }

    // console.log(
    //   `Check gas fee: ${parseFloat(balanceL2.amountFormated) < JACKPOT_TC_FEE}`
    // );

    if (parseFloat(balanceL2.amountFormated) < JACKPOT_TC_FEE) {
      await playerShareContract.buyTCFee('0.03');
      gaEventTracker(
        GameActions.AutoByTC,
        JSON.stringify(circleTournamentInfo)
      );
    }

    updateIsPlayingGame(UnityIFrameStatus.LOADING, {
      game: gameInfo,
      mode: 'play',
      circleAddress: circleAddress,
      extra: extra,
      circleTournamentInfo: circleTournamentInfo,
    });

    if (unityIframeStatus == UnityIFrameStatus.PENDING) {
      openGame();
    } else {
      var tokenAmount = Web3.utils.fromWei(
        circleTournamentInfo?.ticketPrice ?? '0',
        'ether'
      );

      // console.log('approve_FE', parseFloat(tokenAmount));

      // playerShareContract.getBTCApproveFixedAmount({
      //   need_approve: true,
      //   token_amount: parseFloat(tokenAmount),
      //   spender_address: JACKPOT_CONTRACT,
      // });
    }
  };

  const _buildHeader = () => {
    return (
      <div className={s.estimatePrizeContainer}>
        <div className={s.estimatePrizeWrapper}>
          <p className={s.estimatePrizeTitleWrapper}>
            <span>Estimate prize </span>
          </p>
          <p>
            <span>Time left </span>
          </p>
        </div>

        {_buildPool()}
        {_buildBestStandings()}
        {_buildLeaderBoardButton()}
      </div>
    );
  };

  const _buildGameInfo = () => {
    var path = 'merge-cube';

    switch (circleTournamentInfo?.game?.name) {
      case GameName.PEPE:
        path = 'wombat';
        break;
      case GameName.WOMBAT:
        path = 'wombat';
        break;
      case GameName.BITCOIN_M21:
        path = 'sum21';
        break;
      case GameName.BLAST:
        path = 'blast';
        break;
    }

    return (
      <div className={s.gameInfo}>
        {/* <img className={s.gameThumbnail} src={gameInfo.thumbnailUrl} /> */}
        <img
          className={s.gameThumbnail}
          src={`https://cdn-dev.gamefi.garden/unity-build/${path}/metadata/poster-tournament.jpg`}
        />
        {_buildInfoButton()}
      </div>
    );
  };

  const _buildHowToPlayButton = () => {
    return;
  };

  const _buildPool = () => {
    return (
      <div className={s.jackpotWrapper}>
        {/* <CountUp
              start={0}
              delay={0.5}
              duration={2}
              end={jackpot}
              // decimalPlaces={3}
              // decimals={3}
            />
            {` ${tournamentUnit}`} */}

        <span className={s.valueText} style={{ marginLeft: 6 }}>
          <TokenDisplay
            amount={jackpot}
            tribeToken={circleTournamentInfo?.potToken ?? ''}
            prefix=""
            customClass={s.prize}
            iconSize={24}
          />
          &nbsp;{'('}
          {/* <CountUp start={0} delay={0.5} duration={2} end={usdVal} prefix="$" /> */}
          ${formatCurrency(usdVal, 0, 0)}
          {`)`}
        </span>

        <p
          // onClick={() => {
          //   handleEndTournament();
          // }}
          className={s.deadline}
        >
          <CountDownText
            deadline={deadline ?? ''}
            isEnded={!circleTournamentInfo?.isOpening}
          />
        </p>
      </div>
    );
  };

  const _buildPlayButton = () => {
    var value = Web3.utils.fromWei(
      circleTournamentInfo?.ticketPrice ?? '0',
      'ether'
    );

    if (!circleTournamentInfo?.isOpening) {
      return <></>;
    }

    return (
      <>
        <TokenDisplay
          amount={tokenBalance}
          tribeToken={circleTournamentInfo.potToken}
          prefix="Balance: "
          customClass={s.balance}
        />
        <button
          disabled={isProcessing.current}
          className={s.playButton}
          onClick={() => handleClickPlayFT()}
        >
          <p>Play</p>

          {!!!playerInfo?.hasTicket && (
            <span>
              (
              <TokenDisplay
                amount={value}
                tribeToken={circleTournamentInfo.potToken ?? ''}
                prefix=""
                customClass={s.prize}
                iconSize={14}
              />
              )
            </span>
          )}
        </button>
      </>
    );
  };

  const _buildInfoButton = () => {
    return (
      <button
        className={s.infoButton}
        onClick={() => {
          dispatch(
            openModal({
              id: PRIZE_INFO_MODAL_ID,
              theme: 'dark',
              modalProps: {
                centered: true,
                zIndex: 9999999,
              },
              render: () => <PrizeInfoModal />,
            })
          );
          gaEventTracker(
            GameActions.PressRule,
            JSON.stringify(circleTournamentInfo)
          );
        }}
      >
        <SvgInset size={14} svgUrl={`${CDN_URL_ICONS}/ic-rule.svg`} />
        {'Rule'}
      </button>
    );
  };

  const _buildUserProfile = () => {
    // return <></>;

    return <div className={s.userProfile}>{_yourProfile()}</div>;
  };

  const _buildBestStandings = () => {
    if (!topAlpha) return;
    return (
      <div className={s.topUserProfileWrapper}>
        <p className={s.rankText}>Rank 1</p>
        <div className={s.avatarWrapper}>
          <Avatar
            url={topAlpha.player?.twitterAvatar}
            width={40}
            className={s.avatar}
          />
        </div>
        <div className={s.userInfoWapper}>
          <p className={s.name}>{topAlpha?.player?.twitterName}</p>
          <p className={s.userName}>@{topAlpha?.player?.twitterUsername}</p>
        </div>

        <div className={s.userScore}>
          <h1 className={s.title}>SCORE</h1>
          <p className={s.scoreValue}>{topAlpha.score}</p>
        </div>
      </div>
    );
  };

  const _buildLeaderBoardButton = () => {
    if (!topAlpha) return;
    return (
      <div
        className={s.leaderBoardButtonWrapper}
        onClick={() => {
          dispatch(
            openModal({
              id: REWARDS_MODAL_ID,
              theme: 'dark',
              modalProps: {
                centered: true,
                zIndex: 9999999,
              },
              render: () => (
                <RewardsModal
                  data={[]}
                  tournamentName={circleTournamentInfo?.game?.name ?? ''}
                  tournamentId={circleTournamentInfo?.id?.toString() ?? ''}
                  keyName={''}
                  keyAvatar={''}
                />
              ),
            })
          );
        }}
      >
        <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/ic-leaderboard.svg`} />

        <>LeaderBoard</>
      </div>
    );
  };

  const _yourProfile = () => {
    const _scoreText = () => {
      if (!bestScore) {
        return '---';
      }
      return bestScore;
    };

    const _yourRewardHeader = () => {
      return (
        <div className={s.header}>
          <div className={s.avatarWrapper}>
            <Avatar
              url={playerPoolProfile?.twitterAvatar}
              width={40}
              className={s.avatar}
            />
          </div>
          <div className={s.userInfoWapper}>
            <p className={s.name}>{playerPoolProfile?.twitterName}</p>
            <p className={s.userName}>@{playerPoolProfile?.twitterUsername}</p>
          </div>

          <div className={s.userScore}>
            <h1 className={s.title}>SCORE</h1>
            <p className={s.scoreValue}>{_scoreText()}</p>
          </div>
        </div>
      );
    };
    const _yourRewardValue = () => {
      const _lastScoreText = () => {
        if (!lastScore) {
          return '---';
        }
        return lastScore;
      };
      const _rankText = () => {
        if (!rank) {
          return '---';
        }
        return rank;
      };
      const _bestScoreText = () => {
        if (!bestScore) {
          return '---';
        }
        return bestScore;
      };
      const _nextReward = () => {
        if (nPlayTarget == 0 || rank == 1) {
          return <></>;
        }

        if (nPlayTarget <= 5) {
          return (
            <span
              className={s.target}
            >{`No rewards? Play ${nPlayTarget} more match to rise in the ranks!`}</span>
          );
        }

        return (
          <span
            className={s.target}
          >{`No rewards? Keep playing to boots your rank!`}</span>
        );
      };

      return (
        <div className={s.userRowRewardWrapper}>
          <div className={s.userRewardWrapper}>
            <span>Current rank</span>
            <span className={s.valueText}>{_rankText()}</span>
          </div>
          {nPlayTarget > 0 && rank != 1! && (
            <div className={s.userRewardWrapper}>
              <span>Gap to rank 1</span>
              <span className={s.valueText}>{nPlayTarget}</span>
            </div>
          )}
          {/* <div className={s.userRewardWrapper}>
            <span>Best Play Score</span>
            <span className={s.valueText}>{_bestScoreText()}</span>
          </div>
          <div className={s.userRewardWrapper}>
            <span>Last Play Score</span>
            <span className={s.valueText}>{_lastScoreText()}</span>
          </div> */}
          {/* <div className={s.userRewardWrapper}>
            <span>Last Play Score</span>
            <span className={s.valueText}></span>
            <span className={s.valueText}>4320</span>
          </div> */}
        </div>
      );
    };

    return (
      <div className={s.yourUserProfileWrapper}>
        <div className={s.yourUserProfile}>
          {_yourRewardHeader()}
          {_yourRewardValue()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={s.jackpot}>
        {_buildGameInfo()}

        <div className={s.content}>
          {_buildHeader()}
          {_buildUserProfile()}
          {_buildPlayButton()}
        </div>
      </div>
    </>
  );
};

export default CircleTournament;
