import { ROUTE_PATH } from '@/constants/route-path';
import accountStorage from '@/utils/account.storage';
import { isWebApp } from '@/utils/client';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Referral from '@/modules/AlphaPWA/Referral';
import { useAlphaTheme } from '@/hooks/useAlphaTheme';
import { TOKEN_TAB_INDEX } from '@/constants/storage-key';
import { TokensTabIndex } from '@/modules/AlphaPWA/Tokens';

const withAlphaMiddleware = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const router = useRouter();
    const address = accountStorage.getAddress();
    const isPWA = isWebApp();
    const isAuthenticated = !!address && !!accountStorage.isUserLinkingTwitter;
    const isAlphaScreen = router.pathname === ROUTE_PATH.ALPHA_MOBILE;
    const isWelcomeScreen = router.pathname === ROUTE_PATH.ALPHA_MOBILE_LINK_TW;
    const isAppScreen = router.pathname === '/app';
    useAlphaTheme('dark');

    /// Static check
    useEffect(() => {
      // if (isPWA) {
      //   if (isWelcomeScreen || isAppScreen || isAlphaScreen) {
      //     if (!isAuthenticated) {
      //       router.push(ROUTE_PATH.ALPHA_MOBILE_HOME);
      //     } else {
      //       router.push(ROUTE_PATH.ALPHA_MOBILE_KEYS);
      //     }
      //   }
      // }

      // if ((!isAuthenticated || !isPWA) && !isWelcomeScreen) {
      //   router.push(ROUTE_PATH.ALPHA_MOBILE);
      //   return;
      // }

      // if (isAuthenticated && isPWA && isWelcomeScreen) {
      //   localStorage.setItem(TOKEN_TAB_INDEX, TokensTabIndex.Tokens.toString());
      //   router.push(ROUTE_PATH.ALPHA_MOBILE_KEYS);

      //   // router.push(ROUTE_PATH.ALPHA_MOBILE_TOKENS);
      //   return;
      // }
    }, [isPWA]);

    return <WrappedComponent {...props} />;

    if (isWelcomeScreen) {
      return (
        <>
          <WrappedComponent {...props} />
        </>
      );
    }

    return isAuthenticated ? (
      <>
        <WrappedComponent {...props} />
      </>
    ) : null;
  };
};

export default withAlphaMiddleware;
