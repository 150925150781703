import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IAliasState } from '@/state/alias/types';
import * as actions from './actions';

const initialState: IAliasState = {
  configs: [],
};

const aliasSlide = createSlice({
  name: 'alias',
  initialState,
  reducers: {
    resetsConfigs: (state: IAliasState) => {
      state.configs = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(
      actions.getConfigs.fulfilled,
      (state: IAliasState, action) => {
        state.configs = action.payload;
      }
    );
  },
});

export const { resetsConfigs } = aliasSlide.actions;

export const aliasReducer = (state: RootState) => (state as any).alias;

export default aliasSlide.reducer;
