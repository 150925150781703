import { JsonRpcProvider } from '@ethersproject/providers';
import { Wallet } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { isAddress } from 'web3-utils';
import { AddressZero } from '@ethersproject/constants';

const getWalletSigner = (
  privateKey: string,
  provider: JsonRpcProvider
): Wallet => {
  const wallet = new Wallet(privateKey);
  return wallet.connect(provider);
};

const getContractSigner = (
  address: string,
  ABI: any,
  provider: JsonRpcProvider,
  privateKey: string
): Contract => {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  const walletSigner = getWalletSigner(privateKey, provider);
  return new Contract(address, ABI, walletSigner);
};

export { getContractSigner };
