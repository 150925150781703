export default function IcGif() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.26599 6.86592H5.74097V10.4659C5.74097 10.5489 5.67394 10.6159 5.59094 10.6159H3.61597C2.61597 10.6159 2.11597 10.1159 2.11597 9.11592V7.01592C2.11597 6.93292 2.18299 6.86592 2.26599 6.86592ZM9.96594 6.86592H6.49097V10.4659C6.49097 10.5489 6.55799 10.6159 6.64099 10.6159H8.61597C9.61597 10.6159 10.116 10.1159 10.116 9.11592V7.01592C10.116 6.93292 10.0489 6.86592 9.96594 6.86592ZM10.616 4.36592V5.96593C10.616 6.04893 10.5489 6.11592 10.4659 6.11592H6.49097V4.36592H5.74097V6.11592H1.76599C1.68299 6.11592 1.61597 6.04893 1.61597 5.96593V4.36592C1.61597 3.95192 1.95197 3.61592 2.36597 3.61592H2.89844C2.73894 3.30692 2.67947 2.93842 2.82147 2.53792C2.96297 2.13892 3.28594 1.80843 3.68994 1.68193C4.24894 1.50693 4.82396 1.68992 5.17596 2.11742C5.19196 2.13692 5.75446 2.88541 6.11646 3.36641C6.47795 2.88541 7.03997 2.13842 7.05347 2.12142C7.40797 1.69042 7.97796 1.50891 8.53546 1.67891C8.92546 1.79841 9.24246 2.10742 9.39246 2.48692C9.55846 2.90692 9.50047 3.29343 9.33447 3.61543H9.86694C10.2799 3.61593 10.616 3.95192 10.616 4.36592ZM5.36499 3.61592C5.02649 3.16592 4.61747 2.62191 4.58997 2.58641C4.47547 2.44691 4.30497 2.36592 4.11597 2.36592C3.77147 2.36592 3.49097 2.64642 3.49097 2.99092C3.49097 3.33542 3.77147 3.61592 4.11597 3.61592H5.36499ZM8.74097 2.99092C8.74097 2.64642 8.46047 2.36592 8.11597 2.36592C7.92697 2.36592 7.75644 2.44691 7.63544 2.59441C7.61194 2.62541 7.20444 3.16692 6.86694 3.61592H8.11597C8.46047 3.61592 8.74097 3.33542 8.74097 2.99092Z"
        fill="url(#paint0_linear_21946_22674)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21946_22674"
          x1="1.61597"
          y1="6.11582"
          x2="10.616"
          y2="6.11582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00F5A0" />
          <stop offset="1" stop-color="#00D9F5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
