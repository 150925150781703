import px2rem from '@/utils/px2rem';
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  tablist: {
    borderBottom: '1px solid',
    borderColor: 'var(--alpha-tabs-border)',
  },
});

const alphaVariant = definePartsStyle(props => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    tab: {
      fontSize: px2rem(16),
      padding: '6px',
      fontWeight: '400',
      opacity: 0.7,
      borderBottom: '1px solid',
      borderColor: 'transparent',
      outline: 'none',
      outlineOffset: 0,
      span: {
        paddingBottom: '4px',
      },
      _after: {
        userSelect: 'none',
      },
      _selected: {
        borderBottom: 'none',
        opacity: 1,
        _after: {
          transition: 'all 200ms',
          position: 'absolute',
          content: '""',
          width: '24px',
          height: '2px',
          bottom: '0px',
          borderBottom: '2px solid var(--alpha-tabs-border-active)',
        },
      },
    },
    tablist: {
      borderBottom: '1px solid',
      borderColor: 'var(--alpha-tabs-border)',
      minHeight: '32px',
      paddingLeft: '20px',
      paddingRight: '20px',
      gap: '16px',
      position: 'relative',
      height: '32px',
    },
    tabpanel: {
      padding: '0px',
      height: '100%',
    },
    tabpanels: {
      height: 'calc(100% - 32px)',
      overflow: 'auto',
    },
  };
});

const variants = {
  alpha: alphaVariant,
};

// export the component theme
const Tabs = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: 'alpha',
  },
});

export default Tabs;
