import {
  LanguageSupportedEnum,
  LanguageBackEndEnum,
} from '@/interfaces/api/chat';
import { LanguageContext } from '@/providers/LanguageProvider/types';
import { createContext } from 'react';

export const context = createContext<LanguageContext>({} as any);

export const LANGUAGE_SETTING_STORAGE_KEY = 'language';

export const LANGUAGE_LABELS: Record<LanguageSupportedEnum, string> = {
  [LanguageSupportedEnum.OFF]: 'Off',
  [LanguageSupportedEnum.ENGLISH]: 'English',
  [LanguageSupportedEnum.CHINESE_TRADITIONAL]: 'Chinese (Traditional)',
  [LanguageSupportedEnum.CHINESE_SIMPLIFIED]: 'Chinese (Simplified)',
  [LanguageSupportedEnum.JAPANESE]: 'Japanese',
};
