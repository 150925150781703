import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import PasswordIcon from '@/components/PassWord';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_IMAGES } from '@/configs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .error {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: red;
    margin-top: 6px;
  }

  .input-title {
    font-family: var(--chakra-fonts-titillium);
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 12px;
  }

  .wrap-ic-ask {
    width: fit-content;
    margin-left: 6px;

    .ic-ask {
      width: fit-content;

      svg path,
      svg rect {
        stroke: #5b5b5b;
      }
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  color: #5b5b5b;
  border: 1px solid #cecece;

  :hover {
    outline: none !important;
    opacity: 0.8;
  }

  .input-container-style {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #5b5b5b;
    height: 100%;

    :-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #5b5b5b !important;
    }
  }

  .icon-container {
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: any;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  refInput?: any;
  classContainer?: string | undefined;
  classInputWrapper?: string | undefined;
  classInput?: string | undefined;
  title?: string | undefined;
  rightTitle?: string | undefined | any;
  errorMsg?: string;
  tooltipText?: string;
  sizeLabel?: number;
  rightElement?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    type = 'text',
    value = '',
    placeholder = '',
    onChange = () => {},
    onKeyDown = () => {},
    refInput,
    classContainer,
    classInputWrapper,
    classInput,
    title,
    rightTitle,
    errorMsg,
    tooltipText,
    sizeLabel,
    rightElement,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [currentType, setCurrentType] = useState<
    HTMLInputTypeAttribute | undefined
  >(type);
  const inputTypeInit = useRef<string>(type);

  useEffect(() => {
    inputTypeInit.current = type;
  }, []);

  const onClick = () => {
    setShowPassword(!showPassword);
    setCurrentType(currentType === 'text' ? 'password' : 'text');
  };

  return (
    <Container className={classContainer}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {!!title && (
          <label
            style={{
              textTransform: 'uppercase',
              fontSize: sizeLabel || 12,
              display: 'flex',
              alignItems: 'center',
              fontFamily: '$font-alp',
            }}
            className="input-title"
          >
            {title}
            {!!tooltipText && (
              <Tooltip
                label={
                  <Text style={{ fontSize: 12, color: 'white' }}>
                    {tooltipText}
                  </Text>
                }
                minWidth="180px"
                padding="8px"
              >
                <span className="wrap-ic-ask">
                  <SvgInset
                    className="ic-ask"
                    svgUrl={`${CDN_URL_IMAGES}/ic-information.svg`}
                    size={18}
                  />
                </span>
              </Tooltip>
            )}
          </label>
        )}
        {!!rightTitle && (
          <Text style={{ fontSize: 12, fontWeight: '500', color: 'white' }}>
            {rightTitle}
          </Text>
        )}
      </Flex>

      <InputWrapper className={classInputWrapper}>
        <input
          {...rest}
          ref={refInput}
          className={`input-container-style ${classInput}`}
          type={currentType as any}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          spellCheck="false"
        />
        {inputTypeInit.current === 'password' && (
          <div className="icon-container" onClick={onClick}>
            <PasswordIcon isShow={showPassword} />
          </div>
        )}
        {!!rightElement && <div className="icon-container">{rightElement}</div>}
      </InputWrapper>
      {!!errorMsg && <Text className="error">{errorMsg}</Text>}
    </Container>
  );
};

export default Input;
