import React from 'react';

const IcSweepKey = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="black" />
      <path
        d="M8.2742 8.28737C8.2742 8.48383 8.11493 8.6431 7.91847 8.6431C7.72201 8.6431 7.56274 8.48383 7.56274 8.28737C7.56274 8.0909 7.72201 7.93164 7.91847 7.93164C8.11493 7.93164 8.2742 8.0909 8.2742 8.28737Z"
        fill="#FFDE17"
      />
      <path
        d="M8.2742 15.6825C8.2742 15.879 8.11493 16.0382 7.91847 16.0382C7.72201 16.0382 7.56274 15.879 7.56274 15.6825C7.56274 15.486 7.72201 15.3268 7.91847 15.3268C8.11493 15.3268 8.2742 15.486 8.2742 15.6825Z"
        fill="#FFDE17"
      />
      <path
        d="M4.30813 11.9735C4.30813 12.17 4.14887 12.3293 3.95241 12.3293C3.75594 12.3293 3.59668 12.17 3.59668 11.9735C3.59668 11.7771 3.75594 11.6178 3.95241 11.6178C4.14887 11.6178 4.30813 11.7771 4.30813 11.9735Z"
        fill="#FFB81A"
      />
      <path
        d="M8.64714 11.989C8.6465 11.5249 8.50828 11.0714 8.24997 10.6859C7.99165 10.3003 7.62483 9.99992 7.19587 9.82276C6.7669 9.6456 6.29504 9.59958 5.83993 9.69052C5.38482 9.78146 4.96688 10.0053 4.63893 10.3337C4.31098 10.6621 4.08775 11.0803 3.99744 11.5356C3.90713 11.9908 3.95381 12.4626 4.13156 12.8913C4.30932 13.32 4.61018 13.6864 4.99612 13.9442C5.38205 14.202 5.83575 14.3396 6.29986 14.3396C6.60838 14.3396 6.91388 14.2788 7.19888 14.1606C7.48388 14.0424 7.74278 13.8692 7.96079 13.6509C8.1788 13.4326 8.35163 13.1735 8.4694 12.8883C8.58717 12.6031 8.64757 12.2976 8.64714 11.989ZM4.61438 11.989C4.61425 11.6562 4.71282 11.3308 4.89763 11.054C5.08244 10.7772 5.34519 10.5614 5.65264 10.4339C5.9601 10.3064 6.29845 10.2729 6.62492 10.3377C6.95139 10.4025 7.25131 10.5627 7.48676 10.798C7.7222 11.0332 7.8826 11.333 7.94766 11.6594C8.01272 11.9859 7.97952 12.3242 7.85227 12.6318C7.72502 12.9393 7.50942 13.2023 7.23275 13.3873C6.95607 13.5723 6.63074 13.6711 6.2979 13.6713L6.2966 13.6713C5.85061 13.6707 5.42303 13.4933 5.10766 13.178C4.7923 12.8626 4.6149 12.435 4.61438 11.989Z"
        fill="white"
      />
      <path
        d="M9.84618 13.6732C9.84631 13.2756 9.72854 12.8869 9.50776 12.5563C9.28699 12.2256 8.97312 11.9679 8.60586 11.8156C8.23859 11.6634 7.83443 11.6234 7.44446 11.7009C7.0545 11.7783 6.69626 11.9696 6.41504 12.2507C6.13382 12.5317 5.94225 12.8898 5.86456 13.2797C5.78687 13.6696 5.82655 14.0738 5.97857 14.4412C6.1306 14.8086 6.38815 15.1226 6.71865 15.3436C7.04915 15.5646 7.43776 15.6826 7.83534 15.6827L7.83599 15.6827C8.36885 15.6822 8.87975 15.4703 9.25661 15.0936C9.63346 14.7169 9.84549 14.2061 9.84618 13.6732ZM6.49348 13.6732C6.49335 13.4077 6.57196 13.1481 6.71937 12.9272C6.86678 12.7064 7.07637 12.5342 7.32163 12.4325C7.5669 12.3307 7.83682 12.304 8.09726 12.3557C8.35771 12.4074 8.59699 12.5351 8.78483 12.7228C8.97268 12.9104 9.10066 13.1496 9.15258 13.41C9.20451 13.6704 9.17806 13.9403 9.07657 14.1857C8.97507 14.4311 8.8031 14.6408 8.5824 14.7884C8.3617 14.9361 8.10217 15.0149 7.83665 15.0151L7.836 15.0151C7.47993 15.0151 7.13842 14.8737 6.88646 14.6222C6.6345 14.3706 6.49269 14.0293 6.49217 13.6732L6.49348 13.6732Z"
        fill="white"
      />
      <path
        d="M8.91473 12.8644L8.91473 11.1281L9.99057 11.1281L9.99057 12.8644L8.91473 12.8644Z"
        fill="#FFDE17"
      />
      <path
        d="M8.67172 11.9891C8.67185 11.5193 8.53267 11.0601 8.27177 10.6694C8.01087 10.2787 7.63998 9.97412 7.206 9.79422C6.77202 9.61432 6.29443 9.56715 5.83364 9.65867C5.37285 9.7502 4.94954 9.97631 4.61726 10.3084C4.28497 10.6405 4.05863 11.0637 3.96685 11.5244C3.87507 11.9852 3.92198 12.4628 4.10164 12.8969C4.28131 13.331 4.58566 13.702 4.9762 13.9631C5.36675 14.2242 5.82596 14.3637 6.29575 14.3638L6.2964 14.3638C6.92605 14.3631 7.52972 14.1127 7.97501 13.6676C8.4203 13.2224 8.67086 12.6188 8.67172 11.9891ZM4.63896 11.9891C4.63883 11.6612 4.73595 11.3406 4.91804 11.0678C5.10013 10.7951 5.359 10.5825 5.66193 10.4569C5.96486 10.3312 6.29823 10.2983 6.61989 10.3621C6.94156 10.426 7.23706 10.5838 7.46904 10.8156C7.70102 11.0474 7.85906 11.3428 7.92316 11.6644C7.98727 11.986 7.95456 12.3194 7.82918 12.6224C7.70381 12.9254 7.49138 13.1845 7.21878 13.3668C6.94618 13.5491 6.62565 13.6464 6.29771 13.6466L6.2964 13.6466C5.85698 13.6461 5.4357 13.4713 5.12499 13.1606C4.81427 12.8498 4.63948 12.4286 4.63896 11.9891Z"
        fill="#FFB81A"
      />
      <path
        d="M8.91473 12.4936L8.91473 11.4536L19.9991 11.4536L19.9991 12.4936L8.91473 12.4936Z"
        fill="white"
      />
      <path
        opacity="0.9"
        d="M8.91473 12.4936L8.91473 11.4536L19.9991 11.4536L19.9991 12.4936L8.91473 12.4936Z"
        fill="#FFB81A"
      />
      <path
        d="M9.87053 10.3226C9.87053 9.92008 9.75118 9.52665 9.52757 9.192C9.30397 8.85735 8.98615 8.59652 8.61431 8.4425C8.24247 8.28848 7.83331 8.24818 7.43856 8.3267C7.04382 8.40522 6.68122 8.59903 6.39663 8.88362C6.11203 9.16822 5.91822 9.53082 5.8397 9.92556C5.76118 10.3203 5.80148 10.7295 5.9555 11.1013C6.10952 11.4731 6.37035 11.791 6.705 12.0146C7.03964 12.2382 7.43308 12.3575 7.83556 12.3575L7.83621 12.3575C8.37565 12.3568 8.89278 12.1422 9.27416 11.7607C9.65553 11.3792 9.87001 10.862 9.87053 10.3226ZM6.51848 10.3226C6.51848 10.0619 6.59576 9.80717 6.74056 9.59047C6.88535 9.37377 7.09115 9.20487 7.33194 9.10513C7.57272 9.00539 7.83768 8.9793 8.09329 9.03014C8.34891 9.08099 8.58371 9.20649 8.768 9.39078C8.95229 9.57507 9.07779 9.80987 9.12863 10.0655C9.17948 10.3211 9.15338 10.5861 9.05365 10.8268C8.95391 11.0676 8.78501 11.2734 8.56831 11.4182C8.35161 11.563 8.09684 11.6403 7.83622 11.6403C7.66317 11.6403 7.49182 11.6062 7.33194 11.54C7.17207 11.4738 7.0268 11.3767 6.90444 11.2543C6.78207 11.132 6.68501 10.9867 6.61879 10.8268C6.55256 10.667 6.51848 10.4956 6.51848 10.3226Z"
        fill="#FFDE17"
      />
      <path
        d="M9.87052 13.6731C9.87065 13.2706 9.75143 12.8771 9.52793 12.5424C9.30444 12.2077 8.9867 11.9467 8.61491 11.7926C8.24312 11.6385 7.83397 11.598 7.4392 11.6764C7.04443 11.7548 6.68177 11.9485 6.39709 12.233C6.1124 12.5175 5.91847 12.8801 5.83983 13.2748C5.76118 13.6695 5.80135 14.0787 5.95525 14.4506C6.10916 14.8225 6.36988 15.1404 6.70446 15.3641C7.03903 15.5878 7.43243 15.7072 7.83491 15.7074L7.83621 15.7074C8.37553 15.7067 8.89257 15.4921 9.27393 15.1108C9.65529 14.7294 9.86984 14.2124 9.87052 13.6731ZM6.51848 13.6731C6.51835 13.4124 6.59551 13.1576 6.74019 12.9409C6.88488 12.7241 7.0906 12.5551 7.33134 12.4552C7.57207 12.3554 7.83701 12.3292 8.09265 12.3799C8.34829 12.4306 8.58315 12.556 8.76753 12.7402C8.95191 12.9244 9.07753 13.1591 9.1285 13.4147C9.17948 13.6703 9.15351 13.9353 9.0539 14.1761C8.95428 14.4169 8.78548 14.6228 8.56885 14.7677C8.35222 14.9126 8.09749 14.99 7.83687 14.9902L7.83622 14.9902C7.48695 14.9898 7.15208 14.851 6.90505 14.604C6.65802 14.3571 6.519 14.0223 6.51848 13.6731Z"
        fill="#FFDE17"
      />
      <path
        d="M8.18532 8.28743C8.18532 8.43477 8.06588 8.55422 7.91853 8.55422C7.77118 8.55422 7.65173 8.43477 7.65173 8.28743C7.65173 8.14008 7.77118 8.02063 7.91853 8.02063C8.06588 8.02063 8.18532 8.14008 8.18532 8.28743Z"
        fill="#24F08E"
      />
      <path
        d="M4.21926 11.9736C4.21926 12.1209 4.09981 12.2404 3.95246 12.2404C3.80512 12.2404 3.68567 12.1209 3.68567 11.9736C3.68567 11.8262 3.80512 11.7068 3.95246 11.7068C4.09981 11.7068 4.21926 11.8262 4.21926 11.9736Z"
        fill="#2983EE"
      />
      <path
        d="M8.17824 15.6751C8.17824 15.8225 8.0588 15.9419 7.91145 15.9419C7.7641 15.9419 7.64465 15.8225 7.64465 15.6751C7.64465 15.5278 7.7641 15.4083 7.91145 15.4083C8.0588 15.4083 8.17824 15.5278 8.17824 15.6751Z"
        fill="#F9A33B"
      />
      <path
        d="M9.99109 11.4534L9.99109 11.1281L10.4981 11.1281L10.4981 11.4534L9.99109 11.4534Z"
        fill="#2983EE"
      />
      <path
        d="M10.4982 11.4534L10.4982 11.1281L11.0052 11.1281L11.0052 11.4534L10.4982 11.4534Z"
        fill="#F9A33B"
      />
      <path
        d="M11.0045 11.4534L11.0045 11.1281L11.5115 11.1281L11.5115 11.4534L11.0045 11.4534Z"
        fill="#24F08E"
      />
      <path
        d="M9.99109 12.8644L9.99109 12.4934L10.4981 12.4934L10.4981 12.8644L9.99109 12.8644Z"
        fill="#2983EE"
      />
      <path
        d="M10.4982 12.8644L10.4982 12.4934L11.0052 12.4934L11.0052 12.8644L10.4982 12.8644Z"
        fill="#F9A33B"
      />
      <path
        d="M11.0045 12.8644L11.0045 12.4934L11.5115 12.4934L11.5115 12.8644L11.0045 12.8644Z"
        fill="#24F08E"
      />
      <path
        d="M15.9621 13.9572L16.3573 13.9572C16.449 13.9572 16.537 13.9208 16.6019 13.8559C16.6667 13.7911 16.7031 13.7031 16.7031 13.6114C16.7031 13.5197 16.6667 13.4317 16.6019 13.3669C16.537 13.302 16.449 13.2656 16.3573 13.2656L15.9621 13.2656C15.8703 13.2656 15.7824 13.2291 15.7175 13.1643C15.6526 13.0994 15.6162 13.0114 15.6162 12.9197L15.6162 12.8393C15.6162 12.7938 15.6252 12.7489 15.6425 12.7069C15.6599 12.6649 15.6854 12.6268 15.7175 12.5947C15.7496 12.5626 15.7877 12.5371 15.8297 12.5197C15.8717 12.5024 15.9166 12.4934 15.9621 12.4934L18.6893 12.4934C18.781 12.4934 18.869 12.5298 18.9338 12.5947C18.9987 12.6596 19.0351 12.7475 19.0351 12.8393L19.0351 12.9197C19.0351 13.0114 18.9987 13.0994 18.9338 13.1643C18.869 13.2291 18.781 13.2656 18.6893 13.2656L18.3208 13.2656C18.2291 13.2656 18.1411 13.302 18.0762 13.3669C18.0114 13.4317 17.9749 13.5197 17.9749 13.6114C17.9749 13.7031 18.0114 13.7911 18.0762 13.8559C18.1411 13.9208 18.2291 13.9572 18.3208 13.9572L18.6893 13.9572C18.781 13.9572 18.869 13.9937 18.9338 14.0585C18.9987 14.1234 19.0351 14.2114 19.0351 14.3031L19.0351 14.3836C19.0351 14.4753 18.9987 14.5632 18.9338 14.6281C18.869 14.693 18.781 14.7294 18.6893 14.7294L15.9621 14.7294C15.8703 14.7294 15.7824 14.693 15.7175 14.6281C15.6526 14.5632 15.6162 14.4753 15.6162 14.3836L15.6162 14.3031C15.6162 14.2114 15.6526 14.1234 15.7175 14.0585C15.7824 13.9937 15.8703 13.9572 15.9621 13.9572Z"
        fill="#FFDE17"
      />
    </svg>
  );
};

export default IcSweepKey;
