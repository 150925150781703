import { CDN_URL_IMAGES } from '@/configs';
import s from './styles.module.scss';

export const CountryBlock = () => {
  return (
    <div className={s.countryBlock}>
      <img
        alt="unavailale-banner"
        src={`${CDN_URL_IMAGES}/unavailale_banner.svg`}
      />
      <p>
        Unfortunately, this game is not currently available in your country or
        region.<br></br> We apologize for the inconvenience.
      </p>
    </div>
  );
};

export default CountryBlock;
