import configs from '@/constants/trustless.constant';
import { WalletContext } from '@/contexts/wallet-context';
import { useContext, useEffect, useState } from 'react';

export enum IframeEventName {
  topup = 'topup',
  create_gamefi_wallet = 'create_gamefi_wallet',
  import_gamefi_wallet = 'import_gamefi_wallet',
  hide_sidebar = 'hide_sidebar',
  open_sidebar = 'open_sidebar',
  trustless_computer_change_route = 'trustless-computer-change-route',
}
export interface IFrameEvent {
  data: {
    name: IframeEventName;
  };
}

const Hydrated = ({ children }: { children?: any }) => {
  const [hydration, setHydration] = useState(false);
  const { handleShowGetTcModal } = useContext(WalletContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHydration(true);
    }
  }, []);

  useEffect(() => {
    if (hydration && window) {
      window.onmessage = function (event: IFrameEvent & any) {
        try {
          const eventData = JSON.parse(event.data);
          switch (eventData.name) {
            case IframeEventName.topup:
              handleShowGetTcModal(
                eventData.amount ? eventData.amount : undefined,
                eventData.address ? eventData.address : undefined
              );
              break;
            case IframeEventName.trustless_computer_change_route: {
              const subUrl = (eventData.url || '').split('/');
              if (subUrl.length > 0) {
                const lastSubUrl = subUrl[subUrl.length - 1];
                const MAIN_PATH_WITHOUT_IFRAME = configs.MAIN_PATH.replace(
                  '-iframe',
                  ''
                );
                if (lastSubUrl.includes('trustless-computers-iframe')) {
                  window.history.pushState({}, '', '/tc');
                } else {
                  window.history.pushState({}, '', '/tc/' + lastSubUrl);
                }
              }
              // router.replace(eventData.url);
              break;
            }
            default:
              break;
          }
        } catch (error) {}
      };
    }
  }, [hydration]);

  return hydration ? children : null;
};

export default Hydrated;
