import { ROUTE_PATH } from '@/constants/route-path';
import { POPUP_REFERRAL_THROTTLE_TIME } from '@/constants/storage-key';
import { WalletContext } from '@/contexts/wallet-context';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { CHOOSE_AMOUNT_3_3_MODAL_KEY } from '@/modules/AlphaPWA/Profiles/TradeKey/pro';
import ModalAmountBTC from '../../pro/ModalAmountBTC';
import styles from '@/modules/AlphaPWA/Profiles/TradeKey/styles.module.scss';
import { useOverlay } from '@/providers/OverlayProvider';
import { requestReload } from '@/state/common';
import { closeModal, openModal } from '@/state/modal';
import { compareString, formatCurrency } from '@/utils';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { getErrorMessageContract } from '@/utils/helpers';
import px2rem from '@/utils/px2rem';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import HorizontalItem from '../horizontalItem';
import { showError, showSuccess } from '../toast';
import s from './styles.module.scss';
import { snakeCaseKeys } from '@/utils/commons';

const BtnSend33 = ({
  profile,
  tokenDetail,
  className,
  title = '(3,3)',
  onCallback,
  isResend,
  orderId,
  isDisabled,
}: {
  profile: any;
  tokenDetail: any;
  className?: any;
  title?: string;
  onCallback?: (_: any) => void;
  isResend?: boolean;
  isDisabled?: boolean;
  orderId?: string;
}) => {
  const router = useRouter();

  const { showRequest } = router.query as { showRequest: string };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    requestTrade33KeysSameAmount,
    reRequestTrade33KeysSameAmount,
    userProfile,
    scanTrxAlpha,
  } = useTradeKey();

  const { addressL2 } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { openOverlayScreen } = useOverlay();

  const [submitting, setSubmitting] = useState(false);

  const [estimateTC33, setEstimateTC33] = useState<string>('0');
  const [selectKeys33, setSelectKeys33] = useState<IBodyBuySweetFloorKeys[]>(
    []
  );
  const [estimateKeysTC33, setEstimateKeysTC33] = useState<any[]>([]);

  const gaEventTracker = useAnalyticsEventTracker();

  const selectKeys: IBodyBuySweetFloorKeys[] = useMemo(() => {
    if (tokenDetail) {
      return [
        {
          token_address: tokenDetail.address,
          token_amount: 1,
          data: snakeCaseKeys(tokenDetail),
        },
      ];
    }

    return [];
  }, [tokenDetail]);

  useEffect(() => {
    if (showRequest) {
      onOpen();
    }
  }, [showRequest]);

  const openReferralModal = () => {
    localStorage.set(
      POPUP_REFERRAL_THROTTLE_TIME,
      `${new Date().toISOString()}`
    );
    openOverlayScreen('SHARE_REFERRAL');
    gaEventTracker(GameFiActions.PopupReferralOpenModal, addressL2);
  };

  const showSuccessInform = () => {
    try {
      const lastShowPopupReferral = localStorage.get(
        POPUP_REFERRAL_THROTTLE_TIME
      );
      if (lastShowPopupReferral) {
        const lastTime = moment(lastShowPopupReferral as any);
        const hoursAgo = moment().diff(lastTime, 'hours');
        if (Math.abs(hoursAgo) >= 3) {
          openReferralModal();
        } else {
          // showSuccess()
        }
      } else {
        openReferralModal();
      }
    } catch (e) {
      //
    }
  };

  const onHideModalChooseAmount33 = () => {
    dispatch(closeModal({ id: CHOOSE_AMOUNT_3_3_MODAL_KEY }));
  };

  const handleSubmit33 = () => {
    onHideModalChooseAmount33();
    onOpen();
  };

  const openChooseAmountFor33 = () => {
    dispatch(
      openModal({
        id: CHOOSE_AMOUNT_3_3_MODAL_KEY,
        theme: 'dark',
        // title: `Amount of keys`,
        className: styles.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <ModalAmountBTC
            selectKeys={selectKeys}
            onConfirm={handleSubmit33}
            setSelectKeys33={setSelectKeys33}
            setEstimateKeysTC33={setEstimateKeysTC33}
            setEstimateTC33={setEstimateTC33}
            userProfile={userProfile}
          />
        ),
      })
    );
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      let txs = [];
      if (isResend) {
        const tx = await reRequestTrade33KeysSameAmount({
          token_address: selectKeys33[0].token_address,
          token_amount: selectKeys33[0].token_amount,
          order_id: orderId,
          btc_amount: selectKeys33[0].btc_amount,
        });
        txs = [tx];
      } else {
        txs = await requestTrade33KeysSameAmount([
          {
            token_address: selectKeys33[0].token_address,
            token_amount: selectKeys33[0].token_amount,
            btc_amount: selectKeys33[0].btc_amount,
          },
        ]);

        const tx = txs[0].tx;
        if (tx) {
          await tx.wait();
          await scanTrxAlpha(tx?.hash);
          dispatch(requestReload());
        }
      }
      showSuccessInform();
      if (!isResend) {
        showSuccess({
          message: `You have successfully sent a (3,3) request to ${tokenDetail?.userTwitterName}.`,
          linkText: 'Manage your request here!',
          url: `${ROUTE_PATH.ALPHA_MOBILE_KEYS_INVITATIONS}?tab=2`,
        });
      }

      onClose();
      onCallback && onCallback(txs);
    } catch (error: any) {
      showError(getErrorMessageContract(error));
    } finally {
      dispatch(requestReload());
      setSubmitting(false);
    }
  };

  if (compareString(addressL2, profile?.address)) {
    return null;
  }

  return (
    <>
      <Button
        onClick={openChooseAmountFor33}
        className={clsx(className, s.btn_trade, s.btn_trade__33)}
        isDisabled={isDisabled}
      >
        {title}
      </Button>
      <Drawer
        closeOnEsc={!submitting}
        closeOnOverlayClick={!submitting}
        placement={'bottom'}
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent className={s.confirmModal}>
          <DrawerHeader borderBottomWidth="1px">
            Sending (3,3) request
            <DrawerCloseButton
              fontSize={'14px'}
              mt={4}
              mr={4}
              onClick={() => {
                setSubmitting(false);
                onClose();
              }}
            />
          </DrawerHeader>
          <DrawerBody>
            <HorizontalItem
              label={
                <Text fontSize={px2rem(16)} fontWeight={500}>
                  Total:
                </Text>
              }
              value={`${formatCurrency(estimateTC33, 0, 6)} BTC`}
            />
            <Button
              isDisabled={submitting}
              onClick={onSubmit}
              className={s.btnConfirm}
              isLoading={submitting}
            >
              Send
            </Button>

            <Text>
              (3,3) runs on a smart contract that arranges the key swap
              transaction trustlessly and holds the keys for 30 days.
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BtnSend33;
