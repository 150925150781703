import React from 'react';
import cs from 'classnames';
import s from './styles.module.scss';

type IProps = {
  url: string;
};

const ImageContent: React.FC<IProps> = ({ url }): React.ReactElement => {
  return (
    <div className={cs(s.boxMessage, s.imageMessage)}>
      <img src={url} />
    </div>
  );
};

export default React.memo(ImageContent);
