import { INetwork } from './types';

const NETWORK_ALL: INetwork = {
  id: 'All',
  networkName: 'All',
  networkTitle: 'All',
  networkLogo: '',
  rpcEndpoint: '',
  explorerUrl: '',
  chainId: 0,
  status: 0,
  bridgeContractAddress: ''
};

const NETWORK_NAME_MAP = {
  NOS: 'NOS',
  BITCOIN: 'BITCOIN',
  ETHEREUM: 'ETHEREUM',
  SOLANA: 'SOLANA',
};

export { NETWORK_ALL, NETWORK_NAME_MAP };
