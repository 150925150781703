import AccountInfoL2Modal, {
  ACCOUNT_INFO_KEY_L2,
} from '@/components/AccountInfoL2/modal';
import CreateModal, {
  CREATE_MODAL_KEY,
} from '@/components/CreateAccountL2/create.modal';
import ImportModal, {
  IMPORT_MODAL_KEY,
} from '@/components/ImportAccountL2/import.modal';
import LoginAccount, {
  LOGIN_ACCOUNT_MODAL_L2,
} from '@/components/LoginAccountL2/login.modal';
import SendTCModal, {
  SEND_TC_L2_MODAL_KEY,
} from '@/components/SendTCL2/sendTC';
import SetAvatarGameFiModal, {
  SET_AVATAR_GAMEFI,
} from '@/components/SetAvatarGameFi';
import SetNameGameFiModal, {
  SET_NAME_GAMEFI,
} from '@/components/SetNameGameFi';
import { WalletContext } from '@/contexts/wallet-context';

import { useAppDispatch } from '@/state/hooks';
import { openModal } from '@/state/modal';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import React, { useContext } from 'react';
import useContentWindowMessage from './useContentWindowMessage';

interface IOpenPayload {
  link?: string;
  inviter?: string;
}

const useGameFiWallet = () => {
  const { walletStateL2, onRandomAccountL2 } = useContext(WalletContext);
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();
  const { onPostMessageWalletCreated } = useContentWindowMessage();

  const openCreate = async (payload?: IOpenPayload) => {
    if (!walletStateL2.isNeedCreate) return;
    if (walletStateL2.isNeedCreate) {
      gaEventTracker(GameFiActions.CreateIngameWallet);
      await onRandomAccountL2({
        inviter: payload ? payload.inviter : '',
        password: `${Math.floor(1000 + Math.random() * 9000)}`,
      });
      onPostMessageWalletCreated();
    }
    if (payload && payload.link) {
      window.open(payload.link, '_blank');
    }
  };

  const onViewKey = () => {
    dispatch(
      openModal({
        id: ACCOUNT_INFO_KEY_L2,
        theme: 'dark',
        title: `Private Key`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <AccountInfoL2Modal />,
      })
    );
  };

  const onSendTC = () => {
    dispatch(
      openModal({
        id: SEND_TC_L2_MODAL_KEY,
        theme: 'dark',
        title: `Withdraw TC`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <SendTCModal />,
      })
    );
  };

  const onImportKey = () => {
    dispatch(
      openModal({
        id: IMPORT_MODAL_KEY,
        theme: 'dark',
        title: `Import Private Key`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <ImportModal />,
      })
    );
  };

  const onLogin = () => {
    dispatch(
      openModal({
        id: LOGIN_ACCOUNT_MODAL_L2,
        theme: 'dark',
        title: `Login`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <LoginAccount isSavePass={true} />,
      })
    );
  };

  const onSetName = () => {
    dispatch(
      openModal({
        id: SET_NAME_GAMEFI,
        theme: 'dark',
        title: `Setup your onchain username`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <SetNameGameFiModal />,
      })
    );
  };

  const onSetAvatar = () => {
    if (walletStateL2.isNeedCreate) return;
    dispatch(
      openModal({
        id: SET_AVATAR_GAMEFI,
        theme: 'dark',
        title: `Setup your profile`,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <SetAvatarGameFiModal />,
      })
    );
  };

  return {
    openCreate,
    onViewKey,
    onSendTC,
    onImportKey,
    onLogin,
    onSetName,
    onSetAvatar,
  };
};

export default useGameFiWallet;
