export const TRADE_ACTION = {
  BUY: 'BUY',
  SELL: 'SELL',
  TOKEN_TO_KEY: 'TOKEN_TO_KEY',
};

export const DEFAULT_FOLLOWERS_MIN = 0;
export const DEFAULT_FOLLOWERS_MAX = 200000;
export const DEFAULT_TC_MIN = 0;
export const DEFAULT_TC_MAX = 1000;
export const DEFAULT_RED_FLAG_MIN = 0;
export const DEFAULT_RED_FLAG_MAX = 10;
export const DEFAULT_RED_FLAG = 6.9;
export const DEFAULT_TAGGED_MIN = 0;
export const DEFAULT_TAGGED_MAX = 10;

export const DEFAULT_HOLDING = false;

export const SORT_ASD = 1;
export const SORT_DSD = 0;

export const KEY_FILTER = 'key_filters_1';
export const KEY_SELECT = 'key_selects_1';
export const PLACE_HOLDER_KEY_FILTER = 'place_holder_key_filter_4';
export const PLACE_HOLDER_KEY_SELECT = 'place_holder_key_selects_4';
export const NUMBER_OF_KEYS = [0.1, 0.5, 1, 2, 5];
export const KEY_PAGE_PARAMS = 'key_page_params';
export const PLACE_HOLDER_PAGE_PARAMS = 'place_holder_page_params';
export const KEY_SWAP_FILTER = 'key_swap_filter';

export const MIN_DECIMAL = 2;
export const MAX_DECIMAL = 6;
export const MIN_KEY_AMOUNT = 0.000001;
export const LARGE_TOKEN_DECIMAL = 2;

export const labelAmountOrNumber = (amount: number) => {
  return Number(amount) < 1 ? 'Amount' : 'Number';
};

export const labelAmountOrNumberAdds = (amount: number) => {
  return Number(amount) !== 1 ? 's' : '';
};
