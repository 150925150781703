import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import cs from 'classnames';
import s from './styles.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getReferralHistory } from '@/services/referral';
import { IReferralHistoryRecord } from '@/interfaces/api/referral';
import { debounce } from 'lodash';
import moment from 'moment';
import CopyIcon from '@/modules/AlphaPWA/Profile/InviteAndRewards/svg/CopyIcon';
import { copyToClipboard } from '@/utils/commons';
import toast from 'react-hot-toast';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  zIndex?: number;
};

const LIMIT_PAGE = 100;

const ReferralHistoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  zIndex = 3000,
}: Props): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IReferralHistoryRecord[]>([]);
  const [total, setTotal] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const fetchData = async (page = 1, isFetchMore = false) => {
    try {
      setLoading(true);
      const res = await getReferralHistory({
        page,
        limit: LIMIT_PAGE,
      });

      setTotal(res?.total || 0);

      if (isFetchMore) {
        setData(prev => [...prev, ...(res?.history || [])]);
      } else {
        setData(res?.history || []);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onLoadMoreData = () => {
    if (loading || data.length % LIMIT_PAGE !== 0) return;
    const page = Math.floor(data.length / LIMIT_PAGE) + 1;
    fetchData(page, true);
  };

  const onCopyTx = (hash: string) => {
    copyToClipboard(hash);
    toast.success('Copied', {
      duration: 2000,
    });
  };

  const debounceLoadMore = debounce(onLoadMoreData, 300);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay zIndex={zIndex} />

      <ModalContent
        p="24px 22px"
        minW={'90%'}
        maxH={'80%'}
        textAlign={'center'}
        zIndex={zIndex}
        style={{
          zIndex: zIndex,
        }}
        containerProps={{
          zIndex: zIndex,
        }}
        className={s.modalContent}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalHeader
          textAlign="center"
          fontSize="24px"
          fontWeight={'600'}
          mb="24px"
        >
          Claim History
        </ModalHeader>
        <ModalBody overflow="hidden" zIndex={zIndex}>
          <div className={s.referents}>
            <div className={s.table_heading}>
              <div className={s.table_heading__col}>Tx</div>
              <div className={s.table_heading__col}>Claim amount</div>
              <div className={s.table_heading__col}>Time</div>
              <div className={s.table_heading__col}>Status</div>
            </div>
            <div className={cs(s.table_body, s.body)} ref={listRef}>
              <InfiniteScroll
                style={{
                  flex: 1,
                  height: '100%',
                  overflow: 'auto',
                }}
                height="100%"
                dataLength={data?.length || total}
                hasMore={true}
                loader={
                  loading && (
                    <Flex justifyContent={'center'} alignItems={'center'}>
                      <Spinner
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                      />
                    </Flex>
                  )
                }
                next={debounceLoadMore}
              >
                {data.map(item => (
                  <div key={item.id} className={s.row}>
                    <div
                      className={cs(s.txCol, s.col)}
                      onClick={() => onCopyTx(item.txHash)}
                    >
                      <div className={s.flex}>
                        <span className={s.hash}>{item.txHash}</span>
                        <span>
                          <CopyIcon />
                        </span>
                      </div>
                    </div>
                    <div className={s.col}>
                      {parseFloat(item.amount).toFixed(5)} BTC
                    </div>
                    <div className={cs(s.end, s.col)}>
                      {moment(item.createdAt).format('YYYY/MM/DD HH:mm')}
                    </div>
                    <div
                      className={cs(s.end, s.col, {
                        [`${s.success}`]: item.txHash,
                        [`${s.timeout}`]: !item.txHash,
                      })}
                    >
                      {item.txHash ? 'Success' : 'Fail'}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReferralHistoryModal;
