import { TokenDetail } from '@/interfaces/api/player-share';
import Persistor from './Persistor';
import moment from 'moment';
import { getPlayerTokenDetail } from '@/services/player-share';
import { getListPlayerTokenDetail } from '@/services/presale';
import { chunk } from 'lodash';

const COLLECTION = 'PlayerToken';
const DATABASE_NAME = 'PlayerToken';

// 10 minutes
const REVALIDATE_TIME = [1, 'minutes'];
const BATCH_TIMEOUT = 10000; //10s

type StoreProfileType = TokenDetail & {
  writtenAt: number;
};

const KEY_PATH = 'stored_id';

class PlayerTokenPersistor extends Persistor {
  private timeOutManager: Record<string, ReturnType<typeof setTimeout>> = {};
  private batchAddressToRevalidate: string[] = [];
  // private isStorageds: Record<string, boolean> = {};

  constructor() {
    super(DATABASE_NAME, COLLECTION, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreProfileType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection();
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private clearTimeoutByAddress(address: string) {
    if (this.timeOutManager[address]) {
      this.batchAddressToRevalidate = this.batchAddressToRevalidate.filter(
        addr => `${addr}`.toLowerCase() !== `${address}`.toLowerCase()
      );
      clearTimeout(this.timeOutManager[address]);
    }
  }

  private retryUpdateNewData(address: string) {
    this.batchAddressToRevalidate = [...this.batchAddressToRevalidate, address];
    this.timeOutManager[address] = setTimeout(() => {
      // one of any timeout fire will be call by list api and cleanup all timeout instances

      const uniqueAddress = Array.from(new Set(this.batchAddressToRevalidate));
      if (uniqueAddress.length) {
        chunk(uniqueAddress, 20).forEach(addressChunked => {
          getListPlayerTokenDetail(addressChunked).then(tokens => {
            tokens.forEach(token => {
              this.upsertItem(`${token.address}`.toLowerCase(), token);
            });
          });
        });
      }

      this.batchAddressToRevalidate = [];
      Object.values(this.timeOutManager[address]).forEach(timeout => {
        if (timeout) {
          clearTimeout(timeout);
        }
      });
    }, BATCH_TIMEOUT);
  }

  public getItem = async (address: string): Promise<TokenDetail | null> => {
    // if (this.isStorageds[`${address}`.toLowerCase()] === false) {
    //   return new Promise(resolve => {
    //     if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
    //       resolve(this.getItem(address));
    //     } else {
    //       setTimeout(() => {
    //         resolve(this.getItem(address));
    //       }, 50);
    //     }
    //   });
    // } else if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
    //   this.isStorageds[`${address}`.toLowerCase()] = false;
    // }
    try {
      // this.clearTimeoutByAddress(address);
      const record = await this.getRecord(address);
      if (record) {
        // this.isStorageds[`${address}`.toLowerCase()] = true;
        if (
          record.writtenAt &&
          moment(record.writtenAt)
            .add(...REVALIDATE_TIME)
            .isAfter(moment())
        ) {
          return record;
        }

        this.retryUpdateNewData(address);
        return record;
      }
      const token = await getPlayerTokenDetail({
        address: address as string,
      });
      this.upsertItem(address, token);
      return token;
    } catch (e) {
      // delete this.isStorageds[`${address}`.toLowerCase()];
      const token = await getPlayerTokenDetail({
        address: address as string,
      });
      this.upsertItem(address, token);
      return token;
    }
  };

  private addItem = async (
    address: string,
    item: TokenDetail
  ): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (
    address: string,
    item: TokenDetail
  ): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');

        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (
    address: string,
    item: TokenDetail
  ): Promise<void> => {
    try {
      const record = await this.getRecord(address);
      if (record) {
        this.updateItem(address, item);
      } else {
        this.addItem(address, item);
      }
    } catch (e) {
      //
    } finally {
      //
      // this.isStorageds[`${address}`.toLowerCase()] = true;
    }
  };
}

const persistor = new PlayerTokenPersistor();

export default persistor;
