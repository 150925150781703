import { JsonRpcProvider } from '@ethersproject/providers';
import { BaseContract, ContractInterface, Wallet, ethers } from 'ethers';

import { formatAmountFromChain } from '@/utils/helpers';
import AlphaGovernorABI from './abis/AlphaGovernor.json';
import AlphaKeysFactoryABI from './abis/AlphaKeysFactory.json';
import AlphaKeysTokenABI from './abis/AlphaKeysToken.json';
import BlastStakerABI from './abis/BlastStakers.json';
import BridgeL2 from './abis/BridgeL2.json';
import MultiSendABI from './abis/MultiSend.json';
import MultiCallABI from './abis/MultiTargetCall.json';
import PassTokenABI from './abis/PassToken.json';
import PassTokenFactoryABI from './abis/PassTokenFactory.json';
import PassTokenStakingABI from './abis/PassTokenStaking.json';
import RouterABI from './abis/Router.json';
import SwapV3ABI from './abis/SwapV3.json';
import ERC20ABI from './abis/erc20.json';

import {
  ALPHA_KEY_FACTORY_ADDRESS,
  ALPHA_KEY_SWEEP_FLOOR_FACTORY_ADDRESS,
  BLAST_STAKER_ADDRESS,
  MULTI_SEND_ADDRESS,
  PASS_TOKEN_FACTORY
} from '@/configs';
import { TOKEN_ADDRESS, VAULTS } from '@/constants/token';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import { Bridge } from '@/contracts/interfaces/Bridge';
import { PassTokenFactory } from '@/contracts/interfaces/PassTokenFactory';
import { PassTokenStaking } from '@/contracts/interfaces/PassTokenStaking';
import { Router } from '@/contracts/interfaces/Router';
import { SwapV3 } from '@/contracts/interfaces/SwapV3';
import useProviderEthereum from '@/hooks/useProviderEthereum';
import useProviderL2 from '@/hooks/useProviderL2';
import { isNativeToken } from '@/state/tokens/helpers';
import BigNumber from 'bignumber.js';
import { useContext } from 'react';
import { AlphaKeysFactory } from './interfaces/AlphaKeysFactory';
import { AlphaKeysToken } from './interfaces/AlphaKeysToken';
import { ERC20 } from './interfaces/IERC20';
import { IMultiSend1 } from './interfaces/IMultiSend';
import { MultiTargetCall } from './interfaces/MultiTargetCall';
import { PassToken } from './interfaces/PassToken';
// import AppRpcProvider from '@/rpc/AppRpcProvider';
import { AlphaGovernor } from '@/contracts/interfaces/AlphaGovernor';
import { IBlastStakers } from '@/contracts/interfaces/IBlastStakers';

class CContract {
  public gameWallet: Wallet | undefined = window.gameWallet;
  public provider: ethers.providers.Provider | undefined =
    this.gameWallet?.provider || window.provider || useProviderL2();

  public providerEther: ethers.providers.Provider | undefined =
    useProviderEthereum();

  public providerBaseChainID: ethers.providers.Provider | undefined;

  private alphaKeysFactory: AlphaKeysFactory | undefined = undefined;
  private alphaKeysToken: AlphaKeysToken | undefined = undefined;
  private erc20: ERC20 | undefined = undefined;
  private erc20Ethereum: ERC20 | undefined = undefined;
  private multiCall: MultiTargetCall | undefined = undefined;
  private bridge: Bridge | undefined = undefined;
  // private router: Router | undefined = undefined;
  private router: Router | undefined = undefined;

  private passToken: PassToken | undefined = undefined;
  private passTokenFactory: PassTokenFactory | undefined = undefined;
  private swapVer3: SwapV3 | undefined = undefined;
  private stakingToken: PassTokenStaking | undefined = undefined;

  private alphaGovernor: AlphaGovernor | undefined = undefined;

  private blastStaker: IBlastStakers | undefined = undefined;

  gameWalletProvider: IWalletContext = useContext(WalletContext);
  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;

  private multiSend: IMultiSend1 | undefined = undefined;

  public getAlphaKeysFactoryContract = () => {
    if (this.alphaKeysFactory === undefined) {
      this.alphaKeysFactory = new ethers.Contract(
        ALPHA_KEY_FACTORY_ADDRESS,
        AlphaKeysFactoryABI,
        this.provider
      ) as AlphaKeysFactory;
    }

    return this.alphaKeysFactory;
  };

  public getAlphaKeysTokenContract = (contractAddress: string) => {
    this.alphaKeysToken = new ethers.Contract(
      contractAddress,
      AlphaKeysTokenABI,
      this.provider
    ) as AlphaKeysToken;

    return this.alphaKeysToken;
  };

  public getERC20Contract = (contractAddress: string) => {
    this.erc20 = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.provider
    ) as ERC20;

    return this.erc20;
  };

  public getERC20EthereumContract = (contractAddress: string) => {
    this.erc20Ethereum = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.providerEther
    ) as ERC20;
    return this.erc20Ethereum;
  };

  public getRouterContract = (contractAddress: string) => {
    this.router = new ethers.Contract(
      contractAddress,
      RouterABI,
      this.provider
    ) as Router;

    return this.router;
  };

  public getBridgeContract = () => {
    this.bridge = new ethers.Contract(
      TOKEN_ADDRESS.BRIDGE_ADDRESS_L2,
      BridgeL2,
      this.provider
    ) as Bridge;

    return this.bridge;
  };

  public getMultiCallContract = () => {
    if (this.multiCall === undefined) {
      this.multiCall = new ethers.Contract(
        ALPHA_KEY_SWEEP_FLOOR_FACTORY_ADDRESS,
        MultiCallABI,
        this.provider
      ) as MultiTargetCall;
    }

    return this.multiCall;
  };

  public getTotalBTCBridgeOnNOS = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.BTC_ADDRESS_L2);
      const totalSupply = await contract.totalSupply();
      return totalSupply.toString() || '0';
    } catch (error) {
      // console.log('[getTotalBTCBridgeOnNOS] error ', error);
      throw error;
    }
  };

  public getTotalETHBridgeOnNOS = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2);
      const totalSupply = await contract.totalSupply();
      return totalSupply.toString() || '0';
    } catch (error) {
      // console.log('[getTotalETHBridgeOnNOS] error ', error);
      throw error;
    }
  };

  public getUSDTTotalSupplyOnEthereum = async () => {
    try {
      const contract = this.getERC20EthereumContract(
        TOKEN_ADDRESS.USDT_ADDRESS_ETHEREUM
      );
      const amount = await contract.balanceOf(
        '0xca258d51A825c82d16CE54dC61B8aBC95021d17b'
      );
      return amount.toString() || '0';
    } catch (error) {
      return '0';
    }
  };

  public getETHTotalSupplyOnEthereum = async () => {
    try {
      const balance = await this.providerEther?.getBalance(
        '0xca258d51A825c82d16CE54dC61B8aBC95021d17b'
      );
      const result = balance?.toString() || '0';
      return result;
    } catch (error) {
      return '0';
    }
  };

  public getTotalUSDTBridgeOnNOS = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2);
      const totalSupply = await contract.totalSupply();
      return totalSupply.toString() || '0';
    } catch (error) {
      // console.log('[getTotalUSDTBridgeOnNOS] error ', error);
      return '0';
    }
  };

  public getVaultUSDTBalanceOnEthereum = async () => {
    try {
      const contract = this.getERC20EthereumContract(
        TOKEN_ADDRESS.USDT_ADDRESS_ETHEREUM
      );
      const amount = await contract.balanceOf(VAULTS.VAULT_ADDRESS_ETHEREUM);
      return amount.toString() || '0';
    } catch (error) {
      // console.log('[getVaultUSDTBalanceOnEthereum] error ', error);
      return '0';
    }
  };

  public getVaultETHBalanceOnEthereum = async () => {
    try {
      const balance = await this.providerEther?.getBalance(
        VAULTS.VAULT_ADDRESS_ETHEREUM
      );
      const result = balance?.toString() || '0';
      return result;
    } catch (error) {
      // console.log('[getVaultETHBalanceOnEthereum] error ', error);
      return '0';
    }
  };

  public getTotalBTCKeyOnNOS = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.BTC_ADDRESS_L2);
      const balance = await contract.balanceOf(TOKEN_ADDRESS.TOTAL_BTC_KEY_L2);
      return balance.toString() || '0';
    } catch (error) {
      // console.log('[getTotalBTCKeyOnNOS] error ', error);
      throw error;
    }
  };

  public getBalanceERC20 = async (
    tokenAddress?: string,
    tcAddress?: string
  ): Promise<string> => {
    try {
      if (tokenAddress && tcAddress) {
        const contract = this.getERC20Contract(tokenAddress);
        const balance = (await contract.balanceOf(tcAddress)).toString() || '0';
        return balance;
      } else {
        return '0';
      }
    } catch (error) {
      console.log('[getBalanceERC20] error ', error);
      return '0';
    }
  };

  public getBalanceERC20WithToken = async ({
    token = 'ETH',
    tcAddress,
  }: {
    token: 'ETH' | 'USDT' | 'USDC';
    tcAddress?: string;
  }): Promise<string> => {
    try {
      let tokenAddress;

      switch (token) {
        case 'ETH':
          tokenAddress = TOKEN_ADDRESS.ETH_ADDRESS_L2;
          break;
        case 'USDT':
          tokenAddress = TOKEN_ADDRESS.USDT_ADDRESS_L2;
          break;
        case 'USDC':
          tokenAddress = TOKEN_ADDRESS.USDC_ADDRESS_L2;
          break;
        default:
          tokenAddress = TOKEN_ADDRESS.ETH_ADDRESS_L2;
      }
      if (tokenAddress && tcAddress) {
        const contract = this.getERC20Contract(tokenAddress);
        const balance = (await contract.balanceOf(tcAddress)).toString() || '0';
        return balance;
      } else {
        return '0';
      }
    } catch (error) {
      console.log('[getBalanceERC20] error ', error);
      return '0';
    }
  };

  public getPassTokenContract = (ido_token_address: string) => {
    this.passToken = new ethers.Contract(
      ido_token_address,
      PassTokenABI,
      this.provider
    ) as PassToken;

    return this.passToken;
  };

  public getPassTokenFactoryContract = () => {
    this.passTokenFactory = new ethers.Contract(
      PASS_TOKEN_FACTORY,
      PassTokenFactoryABI,
      this.provider
    ) as PassTokenFactory;

    return this.passTokenFactory;
  };

  public getMultiSendContract = () => {
    if (this.multiSend === undefined) {
      this.multiSend = new ethers.Contract(
        MULTI_SEND_ADDRESS,
        MultiSendABI,
        this.provider
      ) as IMultiSend1;
    }

    return this.multiSend;
  };

  public getSwapV3Contract = () => {
    this.swapVer3 = new ethers.Contract(
      TOKEN_ADDRESS.DEX_ROUTER_ADDRESS_L2,
      SwapV3ABI,
      this.provider
    ) as SwapV3;

    return this.swapVer3;
  };

  public getStakingTokenContract = () => {
    this.stakingToken = new ethers.Contract(
      TOKEN_ADDRESS.STAKING_TOKEN_ADDRESS,
      PassTokenStakingABI,
      this.provider
    ) as PassTokenStaking;

    return this.stakingToken;
  };

  public getAlphaGovernorContract = (governorAddress: string) => {
    this.alphaGovernor = new ethers.Contract(
      governorAddress,
      AlphaGovernorABI,
      this.provider
    ) as AlphaGovernor;

    return this.alphaGovernor;
  };

  public getBlastStakerContract = () => {
    this.blastStaker = new ethers.Contract(
      BLAST_STAKER_ADDRESS,
      BlastStakerABI,
      this.provider
    ) as IBlastStakers;

    return this.blastStaker;
  };

  public approve = async (params: {
    tokenAddress: string;
    neededApproveAddress: string; // Contract needed approve
  }) => {
    const contract = await this.getERC20Contract(params.tokenAddress).connect(
      this.wallet
    );

    const allowance = await contract.allowance(
      this.wallet.address,
      params.neededApproveAddress
    );

    const amountApprove = allowance.toString();

    const isNeedApprove = new BigNumber(amountApprove).lt(1);

    if (isNeedApprove) {
      await contract.approve(
        params.neededApproveAddress,
        ethers.constants.MaxUint256
      );
      // await tx.wait();
    }
  };

  public getContract = <T extends BaseContract>(
    addressOrName: string,
    contractInterface: ContractInterface,
    provider?: ethers.providers.Provider
  ) => {
    if (provider) {
      return new ethers.Contract(
        addressOrName,
        contractInterface,
        provider
      ) as T;
    }
    return new ethers.Contract(
      addressOrName,
      contractInterface,
      this.providerEther
    ) as T;
  };

  public getFreeERC20Contract = <T extends BaseContract>(
    contractAddress: string,
    provider?: ethers.providers.Provider
  ) => {
    if (provider) {
      return new ethers.Contract(contractAddress, ERC20ABI, provider) as T;
    }
    this.erc20 = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.provider
    ) as ERC20;

    return this.erc20;
  };

  public getBalanceERC20WithParams = async ({
    tokenAddress,
    walletAddress,
    provider,
    decimals = 18,
  }: {
    tokenAddress: string;
    walletAddress: string;
    provider: JsonRpcProvider | undefined;
    decimals?: number;
  }) => {
    let balance: string = '0';
    try {
      const contractERC20 = new ethers.Contract(
        tokenAddress,
        ERC20ABI,
        provider
      ) as ERC20;

      // console.log('[getBalanceERC20WithParams] Params: ', {
      //   tokenAddress,
      //   walletAddress,
      //   provider,
      //   decimals,
      // });

      let balanceBigNumber;
      if (isNativeToken(tokenAddress)) {
        balanceBigNumber = await provider?.getBalance(walletAddress);
      } else {
        balanceBigNumber = await contractERC20.balanceOf(walletAddress);
      }

      balance = formatAmountFromChain(balanceBigNumber?.toString(), decimals);

      // console.log(
      //   '[getBalanceERC20WithParams] address ',
      //   tokenAddress,
      //   ' - balance - ',
      //   balance
      // );
    } catch (error) {
      balance = '0';
    } finally {
      return balance;
    }
  };
}

export default CContract;
