import React from 'react';

const IcCoin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="10"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 0)"
        fill="white"
        fill-opacity="0.15"
      />
      <path
        d="M10 6.5C7.5155 6.5 5 7.273 5 8.75C5 10.227 7.5155 11 10 11C12.485 11 15 10.227 15 8.75C15 7.273 12.485 6.5 10 6.5ZM7.5 11.469V12.969C8.1185 13.1185 8.8025 13.21 9.5 13.2395V11.7395C8.82624 11.7133 8.15653 11.6227 7.5 11.469ZM10.5 11.739V13.239C11.1738 13.2133 11.8436 13.1227 12.5 12.9685V11.4685C11.8436 11.6227 11.1738 11.7133 10.5 11.739ZM13.5 11.1485V12.6485C14.4005 12.271 15 11.72 15 11V9.5C15 10.22 14.4005 10.771 13.5 11.1485ZM6.5 12.6485V11.1485C5.6 10.771 5 10.2195 5 9.5V11C5 11.7195 5.6 12.271 6.5 12.6485Z"
        fill="url(#paint0_linear_22833_12145)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_22833_12145"
          x1="5"
          y1="9.86975"
          x2="15"
          y2="9.86975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE259" />
          <stop offset="1" stop-color="#FFA751" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcCoin;
