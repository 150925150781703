import { ALPHA_KEY_FACTORY_ADDRESS, BTC_L2_ADDRESS } from '@/configs';
import { TOKEN_ADDRESS } from '@/constants/token';
import { AssetsContext, IAssetsContext } from '@/contexts/assets-context';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import CTradeAPI from '@/services/classes/trade';
import errorLogger from '@/services/errorLogger';
import { passTokenReducer } from '@/state/passToken';
import { isProduction } from '@/utils/commons';
import {
  delay,
  formatAmountToClient,
  getErrorMessageContract,
  parseFee,
} from '@/utils/helpers';
import sleep from '@/utils/sleep';
import NBigNumber from 'bignumber.js';
import { BigNumber, Wallet, ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { ceil } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Environment,
  WalletType,
  changeWallet,
  choiceConFig,
  refreshProvider,
} from 'trustless-swap-sdk';
import {
  ICloseStaking,
  IOpenStaking,
  IOrderCloseStaking,
  IOrderStaking,
} from './interfaces/staking';
import {
  IBodyBuySweetFloorKeys,
  IBodyCheckBalance,
  IBodyFillOrderLimit,
  IBodyFillOrderLimitV2,
  IBodySwap,
  IBuyKeyByKey,
  IEstimateTCMultiKeys,
  IResponseBuySweetFloorKeys,
  ISellKeyFromOut,
} from './interfaces/tradeKey';
import {
  IAddWatchListParams,
  IRemoveWatchListParams,
} from './interfaces/watchList';
import { IEstimateMultiSwap } from './passToken/passToken.interface';

export enum ETypes {
  'issue',
  'buy',
  'sell',
  'approve',
  'update_creator_fee',
  'withdraw',
  'createTournament',
  'add_watch_list',
  'remove_watch_list',
  'swap_tokens',
  'transfer',
  'swap_eth_key',
}
type TokenType = 'BTC' | 'ETH';

export const typeToFee = {
  [ETypes.issue]: 1000000,
  [ETypes.buy]: 1000000,
  [ETypes.sell]: 1000000,
  [ETypes.approve]: 60000,
  [ETypes.update_creator_fee]: 100000,
  [ETypes.withdraw]: 2100000,
  [ETypes.createTournament]: 350000,
  [ETypes.add_watch_list]: 200000,
  [ETypes.remove_watch_list]: 70000,
  [ETypes.swap_tokens]: 2000000,
  [ETypes.transfer]: 10000,
  [ETypes.swap_eth_key]: 2000000,
};

class CPlayerShare {
  gameWalletProvider: IWalletContext = useContext(WalletContext);

  assetContext: IAssetsContext = useContext(AssetsContext);

  private keyAmountSweep = useSelector(passTokenReducer).keyAmountSweep;

  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;

  private contract = new CContract();
  public tradeAPI = new CTradeAPI();

  public platformFee = 50000;
  public defaultCreatorFee = 50000;

  public initialize = () => {};

  private balanceL2 = this.assetContext.balanceL2;
  private MIN_BUY_TC = '0.05'; // need buy 0.05 TC for fee

  private CURRENT_RETRY = 0;

  public configSDK = () => {
    changeWallet(
      WalletType.PRIVATEKEY,
      this.wallet.address,
      this.wallet.privateKey
    );
    choiceConFig(isProduction() ? Environment.MAINNET : Environment.TESTNET);
    refreshProvider(null);
  };

  // maximum 5%
  public getPlatformFee = async (token_address: string) => {
    try {
      const fee = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getProtocolFeeRatio();

      return parseFee(fee);
    } catch (error) {
      console.log('error', error);
      return parseFee(this.platformFee);
    }
  };

  // maximum 5%
  public getCreatorFee = async (token_address: string) => {
    try {
      const fee = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getPlayerFeeRatio();

      return parseFee(fee);
    } catch (error) {
      console.log('error', error);
      return parseFee(this.defaultCreatorFee);
    }
  };

  // fee: 0 -> 5;
  public updateCreateFee = async ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => {
    try {
      const amount = (parseFloat(fee.toString()) * 10000).toString();

      await this.estimateTCGasFee({ type: ETypes.update_creator_fee });

      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .connect(this.wallet)
        .updatePlayerFeeRatio(amount);
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public getPrice = (supply: BigNumber, amount: BigNumber) => {
    if (supply.eq(0)) throw 'bad supply';
    if (amount.eq(0)) {
      return BigNumber.from(0);
    }
    const sum1 = supply
      .add(1)
      .mul(supply)
      .mul(supply.sub(1).mul(2).add(1))
      .div(6);
    const sum2 = supply
      .sub(1)
      .add(amount)
      .mul(supply.add(amount))
      .mul(supply.sub(1).add(amount).mul(2).add(1))
      .div(6);
    const summation = sum2.sub(sum1);
    return summation.mul(parseEther('1')).div(264000);
  };

  public getPriceV2 = (supply: BigNumber, amount: BigNumber) => {
    if (supply.eq(0)) throw 'bad supply';
    if (amount.eq(0)) {
      return BigNumber.from(0);
    }
    const sum1 = supply
      .sub(10)
      .mul(supply)
      .mul(supply.sub(10).mul(2).add(10))
      .div(6);
    const sum2 = supply
      .sub(10)
      .add(amount)
      .mul(supply.add(amount))
      .mul(supply.sub(10).add(amount).mul(2).add(10))
      .div(6);
    const summation = sum2.sub(sum1);
    return summation.mul(parseEther('1')).div(264000).div(1000);
  };

  public getBuyPriceV2 = (supply: BigNumber, amount: BigNumber) => {
    return this.getPriceV2(
      supply.div(parseEther('0.1')),
      amount.div(parseEther('0.1'))
    ).add(1);
  };

  public getBuyPriceAfterFeeV2 = (
    supply: BigNumber,
    amount: BigNumber,
    {
      _platformFee,
      _creatorFee,
    }: {
      _platformFee: number;
      _creatorFee: number;
    }
  ) => {
    const byPrice = this.getPriceV2(
      supply.div(parseEther('0.1')),
      amount.div(parseEther('0.1'))
    ).add(1);
    console.log('creatorFee', _creatorFee, _platformFee, byPrice.toString());
    const creatorFee = byPrice
      .mul(parseEther(_creatorFee.toString()))
      .div(parseEther('1'));
    const platformFee = byPrice
      .mul(parseEther(_platformFee.toString()))
      .div(parseEther('1'));

    return byPrice.add(creatorFee).add(platformFee);
  };

  public estimateTCBeforeMint = ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => {
    try {
      const getBuyPrice = (supply: BigNumber, amount: BigNumber) => {
        return this.getPrice(supply, amount).add(1);
      };

      const price = getBuyPrice(
        BigNumber.from(supply),
        BigNumber.from(token_amount)
      );
      const protocolFee = price.mul(0).div(100);
      const playerFee = price.mul(0).div(100);
      const amount = price.add(protocolFee).add(playerFee);
      return amount;
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * createAlphaKeysFor
   */
  public autoCreateAlphaKeysFor = async ({
    twitter_id,
    name,
    symbol,
    signature,
    token_amount = 0,
    tc_amount = 0,
  }: {
    twitter_id: string;
    name: string;
    symbol: string;
    signature: string;
    token_amount: number;
    tc_amount: number;
  }) => {
    try {
      const alphaKeyFactory = this.contract.getAlphaKeysFactoryContract();
      await this.estimateTCGasFee({ type: ETypes.issue });
      const multicalls = [
        alphaKeyFactory.interface.encodeFunctionData(
          'createAlphaKeysForTwitter',
          [twitter_id, name, symbol, signature]
        ),
      ];

      if (token_amount && tc_amount) {
        await this.getBTCApprove({
          spender_address: alphaKeyFactory.address,
          token_amount,
          need_approve: true,
        });

        await this.estimateTCGasFee({ type: ETypes.buy });
        multicalls.push(
          alphaKeyFactory.interface.encodeFunctionData(
            'buyKeysForV2ByTwitterId',
            [
              twitter_id,
              parseEther(token_amount.toString()),
              tc_amount,
              this.wallet.address,
            ]
          )
        );
      }

      const tx = await alphaKeyFactory
        .connect(this.wallet)
        .multicall(multicalls);
      await tx.wait();

      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getAlphaKeysToken
   */
  public getAlphaKeysToken = async () => {
    try {
      const alphaTokenAddress = await this.contract
        .getAlphaKeysFactoryContract()
        .getPlayerKeys(this.wallet.address);
      return alphaTokenAddress;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyAmountAfterFeeV2
   */
  public getBuyAmountAfterFeeV2 = async ({
    token_address,
    btc_amount,
  }: {
    token_address: string;
    btc_amount: number;
  }) => {
    try {
      const result = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getBuyAmountAfterFeeV2(parseEther(btc_amount.toString()));

      return result;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFee
   */
  public getBuyPriceAfterFee = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getBuyPriceAfterFeeV2(parseEther(token_amount.toString()));

      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFee
   */
  public getBuyPrice = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getBuyPriceV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceAfterFee
   */
  public getSellPriceAfterFee = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getSellPriceAfterFeeV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceAfterFee
   */
  public getSellPrice = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getSellPriceV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * buyKeys
   */
  public buyKeys = async ({
    token_address,
    token_amount,
    tc_amount,
    no_wait = false,
    is_multi = false,
  }: {
    token_address: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
    is_multi?: boolean;
  }) => {
    try {
      console.log(
        'tokenAddress -buyKeys',
        token_address,
        token_amount,
        tc_amount.toString()
      );

      await this.buyTCByETH();
      await this.buyTCByUSDT();

      const akf = this.contract.getAlphaKeysFactoryContract();

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      if (!is_multi) {
        await this.estimateTCGasFee({ type: ETypes.buy });
      }

      const tx = await akf
        .connect(this.wallet)
        .buyKeysV2ByToken(
          token_address,
          parseEther(token_amount.toString()),
          tc_amount.toString()
        );
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * buyKeys
   */
  public sellKeys = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    console.log('tokenAddress - sellKeys', token_address, token_amount);

    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      await this.buyTCByETH();
      await this.buyTCByUSDT();
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      await this.estimateTCGasFee({ type: ETypes.sell });

      const { amount } = this.balanceL2;
      console.log('-----amount---', amount);

      // const sellPriceAfterFee = await this.getSellPriceAfterFee({
      //   token_address,
      //   token_amount,
      // });

      const tx = await akf
        .connect(this.wallet)
        .sellKeysV2ByToken(
          token_address,
          parseEther(token_amount.toString()),
          0
        );

      await tx.wait();
      return tx;
    } catch (error) {
      console.log('sellkey error', error);

      throw error;
    }
  };

  public getNativeBalance = async () => {
    try {
      const balance = await this.contract?.provider?.getBalance?.(
        this.wallet.address
      );

      return formatAmountToClient(balance?.toString());
    } catch (e) {
      throw e;
    }
  };

  public getTokenBalance = async (tokenAddress: string) => {
    try {
      const balance = await this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .balanceOf(this.wallet?.address);

      return formatAmountToClient(balance.toString());
    } catch (e) {
      return '0';
    }
  };

  public getTokenBalanceV2 = async (tokenAddress: string) => {
    let result = '0';
    try {
      const balance = await this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .balanceOf(this.wallet?.address);

      result = formatAmountToClient(balance.toString());
    } catch (e) {
      console.log('[getTokenBalanceV2] ERROR : ', e, tokenAddress);
      result = '-1'; // Amount Error, to validate
    } finally {
      return result;
    }
  };

  public getTokenERC20Info = async (tokenAddress: string) => {
    try {
      const [name, symbol, decimals, totalSupply] = await Promise.all([
        this.contract.getERC20Contract(tokenAddress).name(),
        this.contract.getERC20Contract(tokenAddress).symbol(),
        this.contract.getERC20Contract(tokenAddress).decimals(),
        this.contract.getERC20Contract(tokenAddress).totalSupply(),
      ]);

      return { name, symbol, decimals, totalSupply };
    } catch (e) {
      throw e;
    }
  };

  public buySweepKeys = async (params: IEstimateMultiSwap) => {
    try {
      for (let i = 0; i < params.tokens.length; i++) {
        const element = params.tokens[i];

        postMessage(
          JSON.stringify({
            address: element.address,
            status: 'processing',
          })
        );

        try {
          const _estimateAmount = await this.getBuyPriceAfterFee({
            token_amount: this.keyAmountSweep,
            token_address: element.address as string,
          });

          const _tx = await this.buyKeys({
            token_address: element.address as string,
            token_amount: this.keyAmountSweep,
            tc_amount: _estimateAmount.toString(),
          });

          postMessage(
            JSON.stringify({
              address: element.address,
              status: 'success',
              tx_hash: _tx.hash,
            })
          );
        } catch (error) {
          postMessage(
            JSON.stringify({
              address: element.address,
              status: 'error',
              message: getErrorMessageContract(error).message,
            })
          );
        }
      }
    } catch (error) {
      throw error;
    }
  };

  /**
   * buySweetFloorKeys
   */
  public buySweetFloorKeys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      const { alphaTokens } = await this.estimateTCMultiKeys(body);

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: 1,
      });

      const txs: IResponseBuySweetFloorKeys[] = [];

      const needFeeTC = typeToFee[ETypes.buy] * alphaTokens.length * 1.1;

      await this.estimateTCGasFee({ type: ETypes.buy, needFeeTC });

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        try {
          const _tx = await this.buyKeys({
            token_address: v.token_address,
            token_amount: v.token_amount,
            tc_amount: v.amount_tc.toString(),
            no_wait: true,
            is_multi: true,
          });
          postMessage(
            JSON.stringify({
              tx_hash: _tx?.hash,
              token_address: v.token_address,
              token_amount: v.token_amount,
              tc_amount: v.amount_tc,
            })
          );
          // txs.push({
          //   tx: _tx,
          //   token_address: v.token_address,
          //   token_amount: v.token_amount,
          //   tc_amount: v.amount_tc,
          // });
        } catch (error) {
          postMessage(
            JSON.stringify({
              tx_hash: null,
              token_address: v.token_address,
              token_amount: v.token_amount,
              tc_amount: v.amount_tc,
              error: error,
            })
          );
        }
      }
      return txs;
    } catch (error: any) {
      throw error;
    }
  };

  public getBuyPriceAfterFeeForMultiCall = async ({
    token_address,
    token_amount,
    order_id,
    btc_amount,
  }: IBodyBuySweetFloorKeys) => {
    try {
      const alphaTokenContract =
        this.contract.getAlphaKeysTokenContract(token_address);

      const amount_tc = await alphaTokenContract.getBuyPriceAfterFeeV2(
        parseEther(token_amount.toString())
      );

      return {
        alphaTokenContract,
        amount_tc,
        token_address,
        token_amount,
        order_id,
        btc_amount,
      };
    } catch (error) {
      throw error;
    }
  };

  /**
   * estimateTCMultiKeys
   */
  public estimateTCMultiKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<IEstimateTCMultiKeys> => {
    try {
      const promises = () =>
        body.map(v => this.getBuyPriceAfterFeeForMultiCall(v));

      const response = await Promise.all(promises());

      const total_tc = response.reduce(
        (p, c) => BigNumber.from(p).add(c.amount_tc).toString(),
        '0'
      );

      return {
        alphaTokens: response,
        total_tc,
        total_tc_formatted: formatAmountToClient(total_tc),
      };
    } catch (error) {
      return {
        alphaTokens: [],
        total_tc: '0',
        total_tc_formatted: '0',
      };
    }
  };

  /**
   * getERC20Approve
   */
  public getBTCApprove = async ({
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .allowance(this.wallet.address, spender_address);

      console.log(
        'getBTCApprove',
        response.toString(),
        parseEther(token_amount.toString()),
        BigNumber.from(response).lt(parseEther(token_amount.toString()))
      );

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateTCGasFee({
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(BTC_L2_ADDRESS)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getBTCApprove({
          spender_address,
          token_amount,
          need_approve,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };

  /**
   * getERC20Approve
   */
  public getETHApprove = async ({
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2)
        .allowance(this.wallet.address, spender_address);

      console.log(
        'getETHApprove',
        response.toString(),
        parseEther(token_amount.toString()),
        BigNumber.from(response).lt(parseEther(token_amount.toString()))
      );

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateETHTCGasFee({
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getETHApprove({
          spender_address,
          token_amount,
          need_approve,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };

  /**
   * getERC20Approve
   */
  public getUSDTApprove = async ({
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2)
        .allowance(this.wallet.address, spender_address);

      console.log(
        'getUSDTApprove',
        response.toString(),
        parseEther(token_amount.toString()),
        BigNumber.from(response).lt(parseEther(token_amount.toString()))
      );

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateUSDTTCGasFee({
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getUSDTApprove({
          spender_address,
          token_amount,
          need_approve,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };

  /**
   * getERC20Approve
   */
  public getTokenApprove = async ({
    token_address,
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    token_address: string;
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(token_address)
        .allowance(this.wallet.address, spender_address);

      console.log(
        'getTokenApprove',
        response.toString(),
        parseEther(token_amount.toString()),
        BigNumber.from(response).lt(parseEther(token_amount.toString()))
      );

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateTokenTCGasFee({
          token_address: token_address,
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(token_address)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getBTCApprove({
          spender_address,
          token_amount,
          need_approve,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };

  public approveERC20Amount = async ({
    spender_address,
    token_amount,
    need_approve = false,
    token_address,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
    token_address: string;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(token_address)
        .connect(this.wallet)
        .allowance(this.wallet.address, spender_address);

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        await this.estimateTCGasFee({ type: ETypes.approve });
        const txApprove = await this.contract
          .getERC20Contract(token_address)
          .connect(this.wallet)
          .approve(spender_address, parseEther(token_amount.toString()));
        await txApprove.wait();
        return parseEther(token_amount.toString());
      }

      return response.toString();
    } catch (error) {
      return 0;
    }
  };

  public getBTCApproveFixedAmount = async ({
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(this.wallet)
        .allowance(this.wallet.address, spender_address);

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        await this.estimateTCGasFee({ type: ETypes.approve });
        const txApprove = await this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .connect(this.wallet)
          .approve(spender_address, parseEther(token_amount.toString()));
        await txApprove.wait();
        return parseEther(token_amount.toString());
      }

      return response.toString();
    } catch (error) {
      return 0;
    }
  };

  public getBTCAllowanceOfAddresss = async ({
    spender_address,
    address,
  }: {
    spender_address: string;
    address: string;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .allowance(address, spender_address);

      return response.toString();
    } catch (error) {
      return 0;
    }
  };

  private getFaucet = async () => {
    try {
      const tx = await this.tradeAPI.faucetTC({
        address: this.wallet.address,
        network: 'nos',
      });
      console.log('getFaucettx', tx);

      if (tx) {
        await this.gameWalletProvider.gameWallet?.provider.waitForTransaction(
          tx as any
        );
      }
      return true;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.FAUCET_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
      });
      return true;
    } finally {
      // store.dispatch(requestReload());
    }
  };

  public estimateTCGasFee = async ({
    type,
    needFeeTC,
  }: {
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;

    try {
      const [amount, amountBTC] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      console.log(
        'aaaa',
        amountBTC.toString(),
        BigNumber.from(amountBTC).gt('0')
      );

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountBTC).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(BTC_L2_ADDRESS)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amount?.toString()).lt(gasFee.toString()),
        amount?.toString()
      );

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amount?.toString()).lt(gasFee.toString())
      ) {
        await this.buyTCFee(this.MIN_BUY_TC);
        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }

    return isBuy;
  };

  public estimateETHTCGasFee = async ({
    type,
    needFeeTC,
  }: {
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;

    try {
      const [amountTC, amountETH] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountETH).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(TOKEN_ADDRESS.ETH_ADDRESS_L2)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString()),
        amountTC?.toString()
      );

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString())
      ) {
        await this.buyTCFee('0.05', 'ETH');

        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }

    return isBuy;
  };

  public estimateUSDTTCGasFee = async ({
    type,
    needFeeTC,
  }: {
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;

    try {
      const [amountTC, amountUSDT] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountUSDT).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(TOKEN_ADDRESS.USDT_ADDRESS_L2)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString()),
        amountTC?.toString()
      );

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString())
      ) {
        await this.requestTC({ token_address: TOKEN_ADDRESS.USDT_ADDRESS_L2 });

        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }

    return isBuy;
  };

  public estimateTokenTCGasFee = async ({
    token_address,
    type,
    needFeeTC,
  }: {
    token_address: string;
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;

    try {
      const [amountTC, amountToken] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(token_address)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountToken).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(token_address)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(token_address)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString()),
        amountTC?.toString()
      );

      if (
        BigNumber.from(amountTC?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amountTC?.toString()).lt(gasFee.toString())
      ) {
        // Using old method from Contract ()
        if (token_address === TOKEN_ADDRESS.ETH_ADDRESS_L2) {
          await this.buyTCFee('0.05', 'ETH');
        } else {
          await this.requestTC({ token_address });
        }
        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }

    return isBuy;
  };

  public getBTCPrice = async () => {
    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      return await akf.getBTCPrice();
    } catch (error) {
      return 0;
    }
  };

  public requestTC = async ({ token_address }: { token_address: string }) => {
    const akf = this.contract.getAlphaKeysFactoryContract();

    const token = token_address;
    const tokenPrice = await akf.getTCPrice(token);
    const nonce = BigNumber.from(ethers.utils.randomBytes(32));
    const amountBTC = parseEther('0.05').mul(parseEther('1')).div(tokenPrice);
    const network =
      await this.gameWalletProvider.gameWallet?.provider.getNetwork();
    const chainId = network?.chainId;
    const messagePack = ethers.utils.defaultAbiCoder.encode(
      [
        'address',
        'uint256',
        'uint256',
        'address',
        'address',
        'uint256',
        'uint256',
        'uint256',
      ],
      [
        akf.address,
        chainId,
        nonce,
        this.wallet.address,
        token,
        amountBTC,
        tokenPrice,
        ethers.constants.MaxUint256,
      ]
    );
    const messageHash = ethers.utils.keccak256(
      ethers.utils.arrayify(messagePack)
    );
    const signature = await this.wallet.signMessage(
      ethers.utils.arrayify(messageHash)
    );
    const dataHex = akf.interface.encodeFunctionData('requestToken2TC', [
      nonce,
      this.wallet.address,
      token,
      amountBTC,
      tokenPrice,
      ethers.constants.MaxUint256,
      signature,
    ]);

    const result: any = await this.tradeAPI.sendTx({
      contract_address: akf.address,
      data_hex: dataHex,
    });
    const r: any =
      await this.gameWalletProvider.gameWallet?.provider.getTransaction(result);
    await r.wait();
    await this.assetContext.fetchAssets();
  };

  public buyTCFee = async (need_amount_tc = '0.1', tokenType?: TokenType) => {
    const tokenAddress =
      tokenType === 'ETH' ? TOKEN_ADDRESS.ETH_ADDRESS_L2 : BTC_L2_ADDRESS;
    let [tokenBuyPrice, chainNetwork, userAmountBTC] = await Promise.all([
      this.getBTCPrice(),
      this.gameWalletProvider.gameWallet?.provider.getNetwork(),
      this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .balanceOf(this.wallet.address),
    ]);

    if (tokenType === 'ETH') {
      tokenBuyPrice = BigNumber.from('145000000000000000000');
    }
    const amountApprove = await this.contract
      .getERC20Contract(tokenAddress)
      .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);
    if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
      const txApprove = await this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
      await txApprove.wait();
    }

    const estimateBTCAmount = parseEther(need_amount_tc)
      .mul(parseEther(tokenType === 'ETH' ? '0.1' : '1'))
      .div(tokenBuyPrice);

    let amountBTC = estimateBTCAmount;

    if (BigNumber.from(estimateBTCAmount).gt(userAmountBTC)) {
      amountBTC = BigNumber.from(userAmountBTC).gt(estimateBTCAmount)
        ? amountBTC
        : userAmountBTC;
    }

    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));

      const chainId = chainNetwork?.chainId as number;

      const messagePack = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'uint256',
          'uint256',
          'address',
          'uint256',
          'uint256',
          'uint256',
        ],
        [
          akf.address,
          chainId,
          nonce,
          this.wallet.address,
          amountBTC,
          tokenBuyPrice,
          ethers.constants.MaxUint256,
        ]
      );

      const messageHash = ethers.utils.keccak256(
        ethers.utils.arrayify(messagePack)
      );
      const signature = await this.wallet.signMessage(
        ethers.utils.arrayify(messageHash)
      );

      const data_hex = akf.interface.encodeFunctionData(
        (tokenType === 'ETH' ? 'requestETH2TC' : 'requestTC') as any,
        [
          nonce,
          this.wallet.address,
          amountBTC,
          tokenBuyPrice,
          ethers.constants.MaxUint256,
          signature,
        ]
      );
      const result: any = await this.tradeAPI.sendTx({
        contract_address: akf.address,
        data_hex,
      });
      const r: any =
        await this.gameWalletProvider.gameWallet?.provider.getTransaction(
          result
        );
      await r.wait();
      await this.assetContext.fetchAssets();
      return;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.BUY_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
        info: JSON.stringify({
          userAmountBTC: userAmountBTC.toString(),
          amountBTC: amountBTC.toString(),
        }),
      });
      await this.getFaucet();
      throw error;
    }
  };

  /**
   * requestTrade33Keys
   */
  public requestTrade33Keys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      console.log('body', body);

      const { total_tc, alphaTokens, total_tc_formatted } =
        await this.estimateTCMultiKeys(body);

      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        const _tx = await this.request33Keys({
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc.toString(),
          no_wait: true,
        });

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
        });
      }
      return txs;
    } catch (error) {
      console.log('eeeee', error);
      throw error;
    }
  };

  /**
   * request33Keys
   */
  public request33Keys = async ({
    token_address,
    token_amount,
    tc_amount,
    no_wait = false,
  }: {
    token_address: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
  }) => {
    try {
      console.log(
        'tokenAddress -buyKeys',
        token_address,
        token_amount,
        tc_amount.toString()
      );

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      await this.estimateTCGasFee({ type: ETypes.buy });

      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeRequest(
          token_address,
          parseEther(token_amount.toString()),
          tc_amount.toString()
        );
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * getTokenBalanceFree
   */
  public getTokenBalanceFree = async (token_address: string) => {
    try {
      const akt = this.contract.getAlphaKeysTokenContract(token_address);
      const response = await akt
        .connect(this.wallet)
        .freeBalanceOf(this.wallet.address);
      return response;
    } catch (error) {
      return 0;
    }
  };

  /**
   * trade33Keys
   */
  public trade33Keys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      const { total_tc, alphaTokens, total_tc_formatted } =
        await this.estimateTCMultiKeys(body);

      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        const _tx = await this.executeTrade33Keys({
          order_id: v.order_id,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc.toString(),
          no_wait: false,
        });

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
        });
      }
      return txs;
    } catch (error) {
      throw error;
    }
  };

  /**
   * executeTrade33Keys
   */
  public executeTrade33Keys = async ({
    order_id,
    token_amount,
    tc_amount,
    no_wait = false,
  }: {
    order_id: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
  }) => {
    try {
      console.log('tokenAddress -buyKeys', order_id, tc_amount.toString());

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.buy });
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeTrade(order_id, tc_amount.toString());
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * cancel three three
   */

  public cancelTrade33Keys = async (order_id: string) => {
    try {
      await this.estimateTCGasFee({ type: ETypes.buy });
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeCancel(order_id);

      await tx.wait();

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * reject three three
   */

  public rejectTrade33Keys = async (order_id: string) => {
    try {
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeReject(order_id);

      await tx.wait();

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * reRequestTrade33Keys
   */
  public reRequestTrade33Keys = async (body: IBodyBuySweetFloorKeys) => {
    try {
      const v = await this.getBuyPriceAfterFeeForMultiCall(body);

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: v.token_amount,
      });

      const alphaKeyFactory = this.contract.getAlphaKeysFactoryContract();

      const multicalls = [
        alphaKeyFactory.interface.encodeFunctionData(
          'threeThreeReject' as any,
          [body.order_id] as any
        ),
      ];

      multicalls.push(
        alphaKeyFactory.interface.encodeFunctionData('threeThreeRequest', [
          v.token_address,
          parseEther(v.token_amount.toString()),
          v.amount_tc.toString(),
        ])
      );

      const tx = await alphaKeyFactory
        .connect(this.wallet)
        .multicall(multicalls);
      await tx.wait();

      return tx;
    } catch (error) {
      console.log('reRequestTrade33Keys error', error);
      throw error;
    }
  };

  public checkEnoughBalanceAndAllowance = async (body: IBodyCheckBalance) => {
    const amount = parseEther(body.amount);

    const allowance = await this.getBTCAllowanceOfAddresss({
      spender_address: ALPHA_KEY_FACTORY_ADDRESS,
      address: body.user_address,
    });

    const balance = await this.contract
      .getERC20Contract(BTC_L2_ADDRESS)
      .balanceOf(body.user_address);

    const isAllow = !body.check_allowance || amount.lt(allowance);
    const isEnough = amount.lt(balance);

    return isAllow && isEnough;
  };

  /**
   * requestTrade33KeysSameAmount
   */
  public requestTrade33KeysSameAmount = async (
    body: IBodyBuySweetFloorKeys[]
  ) => {
    try {
      console.log('body', body);

      const { total_tc, alphaTokens, total_tc_formatted } =
        await this.estimateTCMultiKeys(body);

      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        const _tx = await this.request33KeysSameAmount({
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc.toString(),
          btc_amount: v.btc_amount.toString(),
          no_wait: true,
        });

        console.log('_tx_tx_tx', _tx);

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
          btc_amount: v.btc_amount,
        });
      }
      return txs;
    } catch (error) {
      console.log('eeeee', error);
      throw error;
    }
  };

  /**
   * request33Keys
   */
  public request33KeysSameAmount = async ({
    token_address,
    token_amount,
    tc_amount,
    no_wait = false,
    btc_amount,
  }: {
    token_address: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
    btc_amount?: string;
  }) => {
    try {
      console.log(
        'request33KeysSameAmount',
        token_address,
        token_amount,
        tc_amount.toString(),
        btc_amount?.toString()
      );

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: 1,
      });

      await this.estimateTCGasFee({ type: ETypes.buy });

      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeRequestBTC(token_address, parseEther(btc_amount as string));
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * trade33Keys
   */
  public trade33KeysSameAmount = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < body.length; i++) {
        const v = body[i];

        const _tx = await this.executeTrade33KeysSameAmount({
          order_id: v.order_id as string,
          no_wait: false,
        });

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: parseEther(v.btc_amount as string).toString(),
        });
      }
      return txs;
    } catch (error) {
      throw error;
    }
  };

  /**
   * executeTrade33Keys
   */
  public executeTrade33KeysSameAmount = async ({
    order_id,
    no_wait = false,
  }: {
    order_id: string;
    no_wait?: boolean;
  }) => {
    try {
      console.log('executeTrade33KeysSameAmount', order_id);

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.buy });
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeTradeBTC(order_id);
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * reRequestTrade33KeysSameAmount
   */
  public reRequestTrade33KeysSameAmount = async (
    body: IBodyBuySweetFloorKeys
  ) => {
    try {
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: body.token_amount,
      });

      const alphaKeyFactory = this.contract.getAlphaKeysFactoryContract();

      const multicalls = [
        alphaKeyFactory.interface.encodeFunctionData(
          'threeThreeReject' as any,
          [body.order_id] as any
        ),
      ];

      multicalls.push(
        alphaKeyFactory.interface.encodeFunctionData('threeThreeRequestBTC', [
          body.token_address,
          parseEther(body.btc_amount as string),
        ])
      );

      const tx = await alphaKeyFactory
        .connect(this.wallet)
        .multicall(multicalls);
      await tx.wait();

      return tx;
    } catch (error) {
      console.log('reRequestTrade33Keys error', error);
      throw error;
    }
  };

  /**
   * FillLimitOrder
   */
  public getSignatureFillLimitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));
      const amount = parseEther(body.amount.toString());
      const triggerPrice = parseEther(body.trigger_price.toString());
      const isFaucet = body.is_buy || true;

      const akf = this.contract.getAlphaKeysFactoryContract();
      const akt = this.contract.getAlphaKeysTokenContract(body.token_address);

      const network =
        await this.gameWalletProvider.gameWallet?.provider.getNetwork();
      const chainId = network?.chainId;
      const messagePack = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'uint256',
          'uint256',
          'address',
          'address',
          'bool',
          'uint256',
          'uint256',
        ],
        [
          akf.address,
          chainId,
          nonce,
          this.wallet.address,
          akt.address,
          isFaucet,
          amount,
          triggerPrice,
        ]
      );

      const messageHash = ethers.utils.keccak256(
        ethers.utils.arrayify(messagePack)
      );
      const signature = await this.wallet.signMessage(
        ethers.utils.arrayify(messageHash)
      );
      return {
        signature,
        nonce: nonce.toString(),
      };
    } catch (error) {
      throw error;
    }
  };

  /**
   * createFillOrder
   */
  public createFillOrder = async (body: IBodyFillOrderLimitV2) => {
    try {
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));
      const amount = parseEther(body.amount.toString());
      const supply = parseEther(Number(body.supply).toString());
      const triggerPrice = parseEther(body.trigger_price.toString());
      const isFaucet = body.is_buy || true;

      const akf = this.contract.getAlphaKeysFactoryContract();
      const akt = this.contract.getAlphaKeysTokenContract(body.token_address);

      const [_creatorFee, _platformFee] = await Promise.all([
        this.getCreatorFee(body.token_address),
        this.getPlatformFee(body.token_address),
      ]);

      const buyPriceAfterFee = this.getBuyPriceAfterFeeV2(supply, amount, {
        _creatorFee,
        _platformFee,
      });

      await this.getBTCApprove({
        spender_address: akf.address,
        token_amount: parseFloat(body.amount.toString()),
        need_approve: true,
      });

      await this.estimateTCGasFee({ type: ETypes.buy });

      const tx = await akf
        .connect(this.wallet)
        .limitOrderCreate(
          nonce,
          akt.address,
          isFaucet,
          amount,
          triggerPrice,
          buyPriceAfterFee
        );
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * CancelFillOrder
   */
  public cancelFillOrder = async ({ nonce }: { nonce: string }) => {
    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      const tx = await akf.connect(this.wallet).limitOrderCancel(nonce);
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * ==== Begin Watch List ======
   */

  /**
   * addWatchList
   */
  public addWatchList = async (
    params: IAddWatchListParams
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.getBTCApprove({
        spender_address: BTC_L2_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.add_watch_list });

      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .addWatchlist(
          params.watch_twitter_id,
          params.amount_max,
          params.buy_price_max
        );
      await tx.wait();
      return tx;
    } catch (error) {
      console.log('addWatchList error', error);

      throw error;
    }
  };

  /**
   * addWatchList
   */
  public removeWatchList = async (
    params: IRemoveWatchListParams
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.getBTCApprove({
        spender_address: BTC_L2_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.remove_watch_list });
      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .removeWatchlist(params.watch_twitter_id);
      await tx.wait();
      return tx;
    } catch (error) {
      console.log('removeWatchList', error);

      throw error;
    }
  };

  /**
   * ==== End Watch List ======
   */

  public amountOutForSwapTokens = async (params: IBodySwap): Promise<any> => {
    try {
      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .amountOutForSwapTokens(
          params.tokenIn,
          params.tokenOut,
          params.amountIn
        );
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public buyTCByToken = async ({
    token_address,
  }: {
    token_address: string;
  }) => {
    try {
      await this.getTokenApprove({
        token_address: token_address,
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });
      await this.estimateTokenTCGasFee({
        token_address: token_address,
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      // TODO: handle error
    }
  };

  public buyTCByETH = async () => {
    try {
      await this.getETHApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });
      await this.estimateETHTCGasFee({
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      // TODO: handle error
    }
  };

  public buyTCByUSDT = async () => {
    try {
      await this.getUSDTApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });
      await this.estimateUSDTTCGasFee({
        type: ETypes.swap_tokens,
      });
    } catch (error) {
      // TODO: handle error
    }
  };

  public swapTokens = async (
    params: IBodySwap
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.buyTCByETH();
      await this.buyTCByUSDT();
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.swap_tokens });
      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .swapTokens(
          params.tokenIn,
          params.tokenOut,
          params.amountIn,
          params.amountOutMin || '0'
        );
      await tx.wait();
      return tx;
    } catch (error) {
      console.log('removeWatchList', error);

      throw error;
    }
  };

  public stakingOpen = async (
    params: IOpenStaking
  ): Promise<ethers.ContractTransaction> => {
    let step = 0;
    try {
      console.log('stakingOpen params', params, params.stakingKeys);
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      step = 1;
      await this.estimateTCGasFee({ type: ETypes.buy });
      step = 2;
      const afk = this.contract.getAlphaKeysFactoryContract();
      step = 3;
      const tx = await afk
        .connect(this.wallet)
        .stakingOpen(
          params.ratio,
          params.duration,
          params.isLock,
          parseEther(params.stakingKeys.toString()),
          parseEther(params.btcAmount)
        );
      step = 4;
      await tx.wait();
      await delay(1000);
      step = 5;
      await this.tradeAPI.scanTrxAlpha({ tx_hash: tx.hash });
      return tx;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.STAKING,
        address: this.wallet.address || '',
        error: JSON.stringify(error),
        info: JSON.stringify({
          step: 'create staking',
          params,
          step_error: step,
        }),
      });
      console.log('stakingOpen error', error);
      throw error;
    }
  };

  public stakingClose = async (
    params: ICloseStaking
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.buy });
      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .stakingClose(params.ratio, params.duration, params.isLock);
      await tx.wait();
      await delay(1000);
      await this.tradeAPI.scanTrxAlpha({ tx_hash: tx.hash });
      return tx;
    } catch (error) {
      console.log('stakingClose error', error);
      throw error;
    }
  };

  public stakingOrderCreate = async (
    params: IOrderStaking
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      const orderId = BigNumber.from(ethers.utils.randomBytes(32));
      await this.estimateTCGasFee({ type: ETypes.buy });
      const afk = this.contract.getAlphaKeysFactoryContract();
      const tx = await afk
        .connect(this.wallet)
        .stakingOrderCreate(
          params.tokenAddress,
          ethers.utils.hexlify(orderId),
          params.ratio,
          params.duration,
          params.isLock,
          parseEther(params.amount)
        );
      await tx.wait();
      await delay(1000);
      await this.tradeAPI.scanTrxAlpha({ tx_hash: tx.hash });
      return tx;
    } catch (error) {
      console.log('stakingOrderCreate error', error);
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.STAKING,
        address: this.wallet.address,
        error: JSON.stringify(error),
        info: JSON.stringify({
          step: 'create staking order',
          params,
        }),
      });
      throw error;
    }
  };

  public stakingOrderClose = async (
    params: IOrderCloseStaking
  ): Promise<ethers.ContractTransaction> => {
    try {
      await this.getBTCApprove({
        spender_address: BTC_L2_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.buy });
      const afk = this.contract.getAlphaKeysFactoryContract();
      if (!params.orderId.startsWith('0x')) {
        params.orderId = '0x' + params.orderId;
      }
      const tx = await afk
        .connect(this.wallet)
        .stakingOrderClose(params.tokenAddress, params.orderId);
      await tx.wait();
      await delay(1000);
      await this.tradeAPI.scanTrxAlpha({ tx_hash: tx.hash });
      return tx;
    } catch (error) {
      console.log('stakingOrderClose error', error);
      throw error;
    }
  };

  public freeStakingBalanceOf = async (
    tokenAddress: string
  ): Promise<string> => {
    try {
      const alphaTokenContract =
        this.contract.getAlphaKeysTokenContract(tokenAddress);

      const balance = await alphaTokenContract
        .connect(this.wallet)
        .freeStakingBalanceOf(this.wallet.address);
      return balance.toString();
    } catch (error) {
      console.log('stakingOrderClose error', error);
      throw error;
    }
  };

  public getBuyAmountAfterFeeForStaking = async (params: {
    token_address: string;
    btc_amount: number;
  }) => {
    const result = await this.contract
      .getAlphaKeysTokenContract(params.token_address)
      .getBuyAmountAfterFeeForStaking(parseEther(params.btc_amount.toString()));
    return result;
  };

  /**
   * getBuyPriceAfterFeeForStaking
   */
  public getBuyPriceAfterFeeForStaking = async (params: {
    token_address: string;
    token_amount: number;
  }) => {
    const amount = await this.contract
      .getAlphaKeysTokenContract(params.token_address)
      .getBuyPriceAfterFeeForStaking(
        parseEther(params.token_amount.toString())
      );
    return amount;
  };

  public tokenTransfer = async ({
    amountBTC,
    transferToAddress,
    transferFromAddress,
    noNeedApproveOrEstimate,
  }: {
    amountBTC: any;
    transferToAddress: string;
    transferFromAddress: string;
    noNeedApproveOrEstimate?: boolean;
  }) => {
    try {
      if (!noNeedApproveOrEstimate) {
        await this.estimateTCGasFee({ type: ETypes.transfer });

        await this.getBTCApprove({
          spender_address: transferFromAddress,
          token_amount: 1,
          need_approve: true,
        });
      }

      const tx = await this.contract
        .getERC20Contract(transferFromAddress)
        .connect(this.wallet)
        .transfer(transferToAddress, parseEther(amountBTC));
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public estimateBuyKeyByKeyAfterFee = async (params: IBuyKeyByKey) => {
    const akf = this.contract.getAlphaKeysFactoryContract();
    const amount = await akf.amountInForSwapTokens(
      params.addressIn,
      params.addressOut,
      parseEther(params.amountOut)
    );
    return amount;
  };

  public getSellAmountAfterFeeV2 = async (params: ISellKeyFromOut) => {
    const akf = this.contract.getAlphaKeysTokenContract(params.tokenAddress);
    const amount = await akf.getSellAmountAfterFeeV2(
      parseEther(params.amountBTC)
    );
    return amount;
  };

  /**
   * getPastVotes
   */
  public getPastVotes = async ({
    token_address,
    address,
    voteStartBlock,
  }: any) => {
    try {
      const akt = this.contract.getAlphaKeysTokenContract(token_address);
      const response = await akt.getPastVotes(address, voteStartBlock);
      return formatEther(response);
    } catch (error) {
      return 0;
    }
  };
}

export default CPlayerShare;
