/* eslint-disable @typescript-eslint/no-explicit-any */
import poolProfilePersistor from './PoolProfilePersistor';
import playerTokenPersistor from './PlayerTokenPersistor';
import hyperLinkPersistor from './HyperLinkPersistor';

import tokenHolderPersistor from './TokenHolderPersistor';
import tokenHoldingPersistor from './TokenHoldingPersistor';
import chatRoomPersistor from './ChatRoomPersistor';
import mediaPersistor from './MediaPersistor';
import publicChatRoomPersistor from './PublicChatRoomPersistor';

export {
  poolProfilePersistor,
  playerTokenPersistor,
  hyperLinkPersistor,
  tokenHoldingPersistor,
  tokenHolderPersistor,
  chatRoomPersistor,
  mediaPersistor,
  publicChatRoomPersistor,
};

if (typeof window !== 'undefined') {
  (window as any).persistors = {};
  (window as any).persistors.poolProfile = poolProfilePersistor;
  (window as any).persistors.playerToken = playerTokenPersistor;
  (window as any).persistors.hyperLink = hyperLinkPersistor;
  (window as any).persistors.tokenHolding = tokenHoldingPersistor;
  (window as any).persistors.tokenHolder = tokenHolderPersistor;
  (window as any).persistors.chatRoom = chatRoomPersistor;
  (window as any).persistors.publicChatRoom = publicChatRoomPersistor;
}
