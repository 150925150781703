import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';
import {
  IGetPlayerPoolProfile,
  TokenDetail,
} from '@/interfaces/api/player-share';
import qs from 'query-string';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { IValidateTwitter } from './interfaces/user';

export const checkPresaleTwitterName = (params: {
  address: string;
  twitter_name: string;
}): Promise<IValidateTwitter> => {
  return apiClient.get(`${PERP_API_URL}/api/alpha-keys/user/validate-twitter`, {
    params: {
      network: 'nos',
      ...params,
    },
  });
};

export const getPresaleSignature = (params: {
  address: string;
  twitter_name: string;
}) => {
  return apiClient.get(`${PERP_API_URL}/api/alpha-keys/user/get-key-sign`, {
    params: {
      network: 'nos',
      ...params,
    },
  });
};

export const getPresaleSignatureV2 = (params: {
  address: string;
  twitter_name: string;
}) => {
  return apiClient.get(`${PERP_API_URL}/api/alpha-keys/user/get-key-sign-v2`, {
    params: {
      network: 'nos',
      ...params,
    },
  });
};

export const getListPlayerTokenDetail = async (
  addresses: string[],
  network = 'nos'
): Promise<TokenDetail[]> => {
  const res = await apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/dapp/token/list?network=${network}&address=${addresses.join(
      ','
    )}`
  );
  return Object(camelCaseKeys(res));
};
