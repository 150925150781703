import styled from 'styled-components';
import { Box, Button } from '@chakra-ui/react';

const Content = styled(Box)`
  margin-top: 24px;

  .row-content {
    margin-top: 16px;

    p {
      font-size: 14px;
      color: black;
    }

    .sub-text {
      color: #343131;
    }
  }
`;

const ButtonCreate = styled(Button)`
  margin-top: 24px;
  width: 100%;
  border-radius: 4px !important;
  background-color: rgba(253, 171, 29, 1) !important;
  font-family: var(--chakra-fonts-bangers);
  color: black !important;
  font-weight: 500 !important;
`;

const Space = styled.div`
  height: 24px;
`;

const Row = styled.div<{
  width?: string;
  align?: string;
  justify?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  gap?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap }) => gap};
  padding: 0;
`;

export { Content, ButtonCreate, Space, Row };
