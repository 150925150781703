import LottieWrapper from '@/components/Lottie';
import { CDN_URL_ICONS } from '@/configs';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { useAgora } from '@/providers/AgoraProvider/hooks';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import cs from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import s from './styles.module.scss';
import { poolProfilePersistor } from '@/utils/persistor';

const getTime = () => {
  return new Date().valueOf();
};

export default function JamFloating() {
  const { getUserProfile } = useDM();
  const { switchScreen, isMinimize, callingRoomInfo } = useAgora();
  const [roomOwner, setRoomOwner] = useState(
    getUserProfile(callingRoomInfo?.owner)
  );
  const moveTimeRef = useRef({
    start: getTime(),
    end: getTime(),
  });

  const resetTimer = () => {
    moveTimeRef.current.start = moveTimeRef.current.end = getTime();
  };

  const predictAction = () => {
    moveTimeRef.current.end = getTime();
    const holdingTime = moveTimeRef.current.end - moveTimeRef.current.start;
    if (holdingTime < 200) {
      switchScreen('MAIN');
    }
  };
  useEffect(() => {
    if (callingRoomInfo?.owner) {
      poolProfilePersistor.getItem(callingRoomInfo.owner).then(item => {
        if (item) {
          setRoomOwner(item);
        }
      });
    }
  }, [callingRoomInfo?.owner]);

  return (
    <Draggable scale={1}>
      <div
        className={cs(
          s.jamFloating,
          isMinimize ? s.jamFloatingActivated : s.jamFloatingDisabled
        )}
      >
        <div className={s.container}>
          {callingRoomInfo && roomOwner && (
            <div className={s.avatar}>
              <Avatar
                url={
                  getUrlAvatarTwitter(
                    roomOwner?.twitterAvatar as string,
                    'medium',
                    Boolean(roomOwner.twitterAvatar)
                  ) || ''
                }
                address={roomOwner?.address || ''}
                width={56}
              />
            </div>
          )}
          <div
            className={cs(s.calling)}
            onTouchStart={() => {
              resetTimer();
            }}
            onTouchEnd={() => {
              predictAction();
            }}
            onMouseDown={() => {
              resetTimer();
            }}
            onMouseUp={() => {
              predictAction();
            }}
          >
            <LottieWrapper
              size={20}
              src={`${CDN_URL_ICONS}/ic-jam-animation.json`}
            ></LottieWrapper>
          </div>
        </div>
      </div>
    </Draggable>
  );
}
