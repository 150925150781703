import { ReactElement, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import s from './styles.module.scss';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';

type Props = {
  src: string;
  size?: number;
};

const StickerWebp = ({ src, size = 160 }: Props): ReactElement => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewportOnce(sectionRef, { threshold: 0 });

  if (!isInViewport)
    return <Box width={`${size}px`} height={`${size}px`} ref={sectionRef} />;

  return (
    <Box
      className={s.stickerWebp}
      width={`${size}px`}
      height={`${size}px`}
      ref={sectionRef}
    >
      <img src={src} alt="sticker" />
    </Box>
  );
};

export default StickerWebp;
