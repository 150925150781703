import sleep from '@/utils/sleep';

export enum IContentWindowEventName {
  gamefi_wallet_created = 'gamefi_wallet_created',
  create_gamefi_wallet = 'create_gamefi_wallet',
  set_avatar_success = 'set_avatar_success',
  set_name_success = 'set_name_success',
  was_in_allowlist = 'was_in_allowlist',
  connect_signature_trustless = 'connect_signature_trustless',
}

export enum IUnityEventType {
  OpenGame = 'JS2U_OPEN_GAME',
  UpdateGameUrl = 'JS2U_UPDATE_URL',
  CloseGame = 'JS2U_CLOSE_GAME',
}

export enum IGameType {
  BitcoinMerge = 'MERGE_CUBE',
  ConnectMeme = 'CONNECT',
  BitcoinBlast = 'MATCH_THREE',
  PepeFight = 'PEPE',
  Sum21 = 'SUM21',
  MatchWord = 'MATCH_WORD',
}

const buildUnityPayload = (eventType: string, metadata?: string) => {
  var unityPayload = {
    type: eventType,
    metadata: metadata?.toString() ?? '',
  };
  var data = {
    messageType: 'unity',
    payload: unityPayload,
  };

  return JSON.stringify(data);
};

const useContentWindowMessage = () => {
  const onPostMessageOpenGame = (data: IGameType) => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      const payload = buildUnityPayload(
        IUnityEventType.OpenGame,
        data.toString()
      );
      element.contentWindow.postMessage(payload, '*');
    }
  };

  const onPostMessageUpdateGameUrl = (data: String) => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      const payload = buildUnityPayload(
        IUnityEventType.UpdateGameUrl,
        data.toString()
      );
      element.contentWindow.postMessage(payload, '*');
    }
  };

  const onPostMessageCloseGame = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      const payload = buildUnityPayload(IUnityEventType.CloseGame);
      element.contentWindow.postMessage(payload, '*');
    }
  };

  const onPostMessageWalletCreated = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.gamefi_wallet_created,
        '*'
      );
    }
  };

  const onPostMessageOpenCreateWallet = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.create_gamefi_wallet,
        '*'
      );
    }
  };

  const onPostMessageSetAvatarSuccess = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.set_avatar_success,
        '*'
      );
    }
  };

  const onPostMessageSetNameSuccess = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.set_name_success,
        '*'
      );
    }
  };

  const onPostMessageWasOnAllowlist = async () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.was_in_allowlist,
        '*'
      );
    }
  };

  const onPostMessageConnectWithSignatureTrustless = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.connect_signature_trustless,
        '*'
      );
    }
  };

  return {
    onPostMessageWalletCreated,
    onPostMessageOpenCreateWallet,
    onPostMessageSetAvatarSuccess,
    onPostMessageSetNameSuccess,
    onPostMessageWasOnAllowlist,
    onPostMessageConnectWithSignatureTrustless,
    onPostMessageOpenGame,
    onPostMessageCloseGame,
    onPostMessageUpdateGameUrl,
  };
};

export default useContentWindowMessage;
