import React from 'react';
import { QrReader } from 'react-qr-reader';
import s from './styles.module.scss';
import { isPrivateKey } from '@/utils';
import * as S from '@/components/ImportAccountL2/styled';

interface IProps {
  onSubmited: (privateKey: string) => void;
  onClose: () => void;
}

const QRCodeScanner = ({ onSubmited, onClose }: IProps) => {
  const onResult = (scanData: { text: string }) => {
    if (scanData && scanData.text) {
      const isPrvKey = isPrivateKey(scanData.text);
      if (isPrvKey) {
        onSubmited(scanData.text);
        onClose();
      } else {
        alert('Invalid private key.');
      }
    }
  };

  return (
    <div className={s.container}>
      <QrReader
        className={s.container_scanner}
        onResult={onResult as any}
        scanDelay={300}
        constraints={{
          facingMode: 'environment',
        }}
      />
      <S.ButtonCreate type="button" onClick={onClose}>
        Close
      </S.ButtonCreate>
    </div>
  );
};

export default QRCodeScanner;
