import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';
import Persistor from './Persistor';
import { ERRORS } from '@/utils/persistor/constants';

const COLLECTION = 'ChatRoom';
const DATABASE_NAME = 'ChatRoom';

type StoreMetadataType = DMRoom & {
  writtenAt: number;
};

const KEY_PATH = 'stored_id';

class ChatRoomPersistor extends Persistor {
  constructor() {
    super(DATABASE_NAME, COLLECTION, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreMetadataType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection();
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
            // this.sendErrorLog(ERRORS.READ, event);
          };
        }
      } catch (e) {
        // this.sendErrorLog(ERRORS.READ, e);
        reject(e);
      }
    });
  };

  public getItem = async (roomId: string): Promise<DMRoom | null> => {
    try {
      const record = await this.getRecord(roomId);
      if (record) {
        return record;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  private addItem = async (roomId: string, item: DMRoom): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${roomId}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
            // this.sendErrorLog(ERRORS.ADD, event);
          };
        }
      } catch (e) {
        // this.sendErrorLog(ERRORS.ADD, e);
        reject(e);
      }
    });
  };

  private updateItem = async (roomId: string, item: DMRoom): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection('readwrite');
        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${roomId}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
            // this.sendErrorLog(ERRORS.UPDATE, event);
          };
        }
      } catch (e) {
        // this.sendErrorLog(ERRORS.UPDATE, e);
        reject(e);
      }
    });
  };

  // public updatePinned = async (roomId: string, item: DMRoom): Promise<void> => {
  //   try {
  //     const record = await this.getRecord(roomId);
  //     if (record) {
  //       this.updateItem(roomId, item);
  //     } else {
  //       this.addItem(roomId, item);
  //     }
  //   } catch (e) {
  //     //
  //   } finally {
  //     //
  //   }
  // };

  public upsertItem = async (roomId: string, item: DMRoom): Promise<void> => {
    try {
      const record = await this.getRecord(roomId);
      // item.pinned = record?.pinned;
      if (record) {
        this.updateItem(roomId, item);
      } else {
        this.addItem(roomId, item);
      }
    } catch (e) {
      //
    } finally {
      //
    }
  };

  public getAllItem = async (): Promise<DMRoom[]> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const collection = this.getCollection();
        const query = collection?.getAll();

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
            this.sendErrorLog(ERRORS.READ_ALL, event);
          };
        }
      } catch (e) {
        this.sendErrorLog(ERRORS.READ_ALL, e);
        reject(e);
      }
    });
  };

  public removeItem = async (roomId: string) => {
    try {
      await this.remove();
      const collection = this.getCollection('readwrite');
      collection?.delete(roomId);
    } catch (e) {
      // this.sendErrorLog(ERRORS.DELETE, e);
    }
  };
}

const persistor = new ChatRoomPersistor();

export default persistor;
