import { API_URL } from '@/configs';
import createAxiosInstance from './http-client';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';

// Can create multiple axios instances with different base URL
export const apiClient = createAxiosInstance({ baseURL: API_URL });

// Make sure api have bearer token before run to backend
apiClient.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${localStorage.get(TWITTER_TOKEN)}`;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// export const setBearerToken = (token: string | string[]) => {
//   if (token && apiClient) {
//     apiClient.interceptors.request.use(
//       config => {
//         config.headers.Authorization = `Bearer ${token}`;
//         return config;
//       },
//       error => {
//         Promise.reject(error);
//       }
//     );
//   }
// };

// setBearerToken(localStorage.get(TWITTER_TOKEN) || '');
