import { LanguageSupportedEnum } from '@/interfaces/api/chat';
import { LANGUAGE_SETTING_STORAGE_KEY } from '@/providers/LanguageProvider/constants';
import { DEFAULT_LANGUAGE } from '@/services/chat/helpers';

export const getStorageLanguage = (): LanguageSupportedEnum => {
  try {
    const storageValue = localStorage.getItem(
      LANGUAGE_SETTING_STORAGE_KEY
    ) as LanguageSupportedEnum;
    return storageValue || DEFAULT_LANGUAGE;
  } catch (e) {
    return DEFAULT_LANGUAGE;
  }
};

export const writeStorageLanguage = (lang: LanguageSupportedEnum) => {
  try {
    localStorage.setItem(LANGUAGE_SETTING_STORAGE_KEY, lang);
  } catch (e) {
    //
  }
};
