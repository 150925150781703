import { TC_ADDRESS } from '@/configs';
import { isProduction } from '@/utils/commons';

export const NATIVE_ETH_ADDRESS = '0x0000000000000000000000000000000000000000';

export const NATIVE_TOKEN_LIST = [
  '0x0000000000000000000000000000000000000000', // ETH on Ethereum
  TC_ADDRESS, // TC on NOS
];

export const TokenIcon = {
  turbo: `https://s2.coinmarketcap.com/static/img/coins/64x64/24911.png`,
  pepe: `	https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png`,
  eth: `	https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png`,
  btc: `https://s2.coinmarketcap.com/static/img/coins/64x64/1.png`,
};

// Token Address + Contract on NOS Network

const BTC_ADDRESS_L2 = isProduction()
  ? '0x111808abe352c8003e0effcc04998eab26cebe3c'
  : '0x1d45c32C97707C916b350042CEAC2164fb6D00A1';

const ETH_ADDRESS_L2 = isProduction()
  ? '0x43bda480de297a14cec95bfb1c6a313615f809ef'
  : '0x0FBa66555B74F13809862BD6f15FffA0A0237059'; // '0xe09385f5b5170322d441a4f36dda01804f2993ab';

const USDT_ADDRESS_L2 = isProduction()
  ? '0xf1612388D43A6b00316CA05ca358BC1a2e7b8E97'
  : '0x5B68dEd386C681232a1308eD1eCC68c997792055';

const DEX_ROUTER_ADDRESS_L2 = isProduction()
  ? '0xB3eAc9358462356B231801309f553c48667B2CB7'
  : '0x3a3885F7a03beC1F4A1c00f155A5d57168fDE205';

const TC_ADDRESS_L2 = TC_ADDRESS;

const BRIDGE_ADDRESS_L2 = '0x966d0714efa3e3950a2bd4f4b3760d0fc07209f5';

const TOTAL_BTC_KEY_L2 = isProduction()
  ? '0xea21fbBB923E553d7b98D14106A104665BA57eCd'
  : '0xea21fbBB923E553d7b98D14106A104665BA57eCd';

const USDC_ADDRESS_L2 = isProduction()
  ? '0xe8b280ebb57be03adc9d87e207bcd689efadef96'
  : '0xe8b280ebb57be03adc9d87e207bcd689efadef96';

const TM_ADDRESS_L2 = isProduction()
  ? '0xF7051dfeF294FB125Bd30C035197bA9a68F8D997'
  : '0x9f64F7C388B7d2c6f67f1dF191F1Cd0da0a40294';

const ROUTER_ADDRESS = isProduction()
  ? '0xC29108123659a8640d48f1EF2287a558a74a95ee'
  : '0xd8255B554e7D57e409B797D25c48a08A94FA1FC5';

const PEPE_ADDRESS_L2 = isProduction()
  ? '0xdC0155d60dB4f9DeeE0bde67b3Cf5f72ef716c20'
  : '0x53fB3204757d007961bC995A97D2b9AD7565FCDc';

// Token Address + Contract on Ethereum Network
const VAULT_ADDRESS_ETHEREUM = isProduction()
  ? '0xca258d51A825c82d16CE54dC61B8aBC95021d17b'
  : '0xca258d51A825c82d16CE54dC61B8aBC95021d17b';

const USDT_ADDRESS_ETHEREUM = isProduction()
  ? '0xdac17f958d2ee523a2206206994597c13d831ec7'
  : '0xdac17f958d2ee523a2206206994597c13d831ec7';

const STAKING_TOKEN_ADDRESS = isProduction()
  ? '0x53745f72CC5cF021e0379a5b835Cb5A72D2D90cd'
  : '0x673Ed412189B937a8A4C7ecBb95EACE2C155A696';

const PEPE_ADDRESS_ETHEREUM = isProduction()
  ? '0x6982508145454Ce325dDbE47a25d4ec3d2311933'
  : '0x6982508145454Ce325dDbE47a25d4ec3d2311933';

const SOL_ADDRESS_L2 = isProduction()
  ? '0xca0ea534e2a7e116be739f4ccc1121515ce6f56a'
  : '0x8066FBcC2E49fBc531De3d6739306fa04e09862D';

const BRUCE_TOKEN_ADDRESS = isProduction()
  ? '0x0865f75bd6e9524f64f38d726e420c37fc03a45c'
  : '0x658bf0f028d42ce1ea622fe845608797103d3865';

const SAMANTHA_TOKEN_ADDRESS = isProduction()
  ? '0x4130ed7712d95f82a1646dc6453887e6fc11c362'
  : '0x5d9980532e47beae4a36db3ffc4d447c0357c00f';

const MATIC_ADDRESS_L2 = '0xb924d66117409ff0be03c2f87261bfb1dd0d8ba7';

const TOKEN_ADDRESS = {
  BTC_ADDRESS_L2,
  ETH_ADDRESS_L2,
  USDT_ADDRESS_L2,
  DEX_ROUTER_ADDRESS_L2,
  TC_ADDRESS_L2,
  BRIDGE_ADDRESS_L2,
  TOTAL_BTC_KEY_L2,
  USDC_ADDRESS_L2,
  ROUTER_ADDRESS,
  TM_ADDRESS_L2,
  PEPE_ADDRESS_L2,
  USDT_ADDRESS_ETHEREUM,
  STAKING_TOKEN_ADDRESS,
  PEPE_ADDRESS_ETHEREUM,
  SOL_ADDRESS_L2,
  BRUCE_TOKEN_ADDRESS,
  SAMANTHA_TOKEN_ADDRESS,
  MATIC_ADDRESS_L2,
};

const VAULTS = {
  VAULT_ADDRESS_ETHEREUM,
};

export { TOKEN_ADDRESS, VAULTS };
