import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';
import qs from 'query-string';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

const PREFIX = '/api/tw';

export type TwitterFollower = {
  twitterId: string;
  name: string;
  twitterUsername: string;
  isBlueVerified: string;
  numFollower: string;
  profileUrl: string;
  isPreSale: boolean;
  baseTokenSymbol: string;
  buyPrice: string;
  tokenAddress: string;
  usdPrice: string;
  vol: string;
  walletAddress: string;
};

export const getTwitterFollowers = async (payload: {
  address: string;
  search?: string;
  page?: number;
  limit?: number;
}): Promise<TwitterFollower[]> => {
  const query = qs.stringify(payload);
  return camelCaseKeys(
    await apiClient.get(`${PERP_API_URL}${PREFIX}/followers?${query}`)
  );
};
