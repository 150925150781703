import Logger from './Logger';
import { WebSocket } from './Socket';

import context from './context';
import { BASE_CHANNEL_ENUM } from './Socket/constants';
import React, { useContext, useState } from 'react';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import localStorage from '@/utils/localstorage';
import { AssetsContext } from '@/contexts/assets-context';
import AgoraProvider from '@/providers/AgoraProvider';

export interface SocketProviderProps
  extends Pick<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  logger?: typeof console;
  host: string;
  path: string;
}

const SocketProvider = ({
  children,
  logger = console,
  host,
  path,
}: SocketProviderProps) => {
  const [socket, setSocket] = React.useState<WebSocket>();
  const [isConnected, setIsConnected] = React.useState(false);
  const [authToken, setAuthToken] = useState<string>('');

  const { refreshTokenTwitter } = useContext(AssetsContext);

  React.useEffect(() => {
    const getBearerToken = async () => {
      await refreshTokenTwitter();
      const token = localStorage.get(TWITTER_TOKEN) as string;
      if (token) {
        setAuthToken(token);
      } else {
        setTimeout(() => {
          getBearerToken();
        }, 5000);
      }
    };

    getBearerToken();
  }, []);

  React.useEffect(() => {
    Logger.setInstance(logger);
  }, [logger]);

  React.useEffect(() => {
    let _socket: WebSocket;

    if (host && authToken) {
      _socket = new WebSocket(host, path, authToken);

      setSocket(_socket);

      if (_socket) {
        Logger.info(`WebSocket: listen base events`);
        _socket.addEventListener(BASE_CHANNEL_ENUM.CONNECT, (data: unknown) => {
          setIsConnected(_socket.isConnected);
          Logger.info(
            `WebSocket: Received `,
            BASE_CHANNEL_ENUM.CONNECT,
            _socket.isConnected,
            data
          );
        });

        _socket.addEventListener(
          BASE_CHANNEL_ENUM.RECONNECT,
          (data: unknown) => {
            setIsConnected(_socket.isConnected);
            Logger.info(
              `WebSocket: Received `,
              BASE_CHANNEL_ENUM.RECONNECT,
              _socket.isConnected,
              data
            );
          }
        );

        _socket.addEventListener(
          BASE_CHANNEL_ENUM.RECONNECT_ATTEMPT,
          (data: unknown) => {
            setIsConnected(_socket.isConnected);
            Logger.info(
              `WebSocket: Received `,
              BASE_CHANNEL_ENUM.RECONNECT_ATTEMPT,
              _socket.isConnected,
              data
            );
          }
        );

        _socket.addEventListener(
          BASE_CHANNEL_ENUM.RECONNECT_ERROR,
          (data: unknown) => {
            setIsConnected(_socket.isConnected);
            Logger.info(
              `WebSocket: Received `,
              BASE_CHANNEL_ENUM.RECONNECT_ERROR,
              _socket.isConnected,
              data
            );
          }
        );

        _socket.addEventListener(
          BASE_CHANNEL_ENUM.RECONNECT_FAILED,
          (data: unknown) => {
            setIsConnected(_socket.isConnected);
            Logger.info(
              `WebSocket: Received `,
              BASE_CHANNEL_ENUM.RECONNECT_FAILED,
              _socket.isConnected,
              data
            );
          }
        );

        _socket.addEventListener(
          BASE_CHANNEL_ENUM.DISCONNECT,
          (data: unknown) => {
            setIsConnected(_socket.isConnected);
            Logger.info(
              `WebSocket: Received `,
              BASE_CHANNEL_ENUM.DISCONNECT,
              _socket.isConnected,
              data
            );
          }
        );
      }
    }
    return () => {
      if (_socket) {
        _socket.disconnect();
      }
    };
  }, [host, path, authToken]);

  React.useEffect(() => {
    if (isConnected && socket) {
      socket.handleReconnect();
    }
  }, [socket, isConnected]);

  const contextValue = React.useMemo(() => {
    return {
      socket,
      host,
      path,
      isConnected,
    };
  }, [socket, host, path, isConnected]);

  return (
    <context.Provider value={contextValue}>
      <AgoraProvider>{children}</AgoraProvider>
    </context.Provider>
  );
};

export default SocketProvider;
