import {
  ILiveTournamentInfo,
  ILiveTournamentCreateFailed,
} from '@/interfaces/api/gamefi-match-word';
import useUserToken from '@/modules/AlphaPWA/Games/hooks/useUserToken';
import {
  getCurrentMatchWordGameByCircleAddress as getCurrentMatchWordGameByAlphaAddress,
  getLeaderBoardById,
} from '@/services/gamefi-match-word';
import dayjs from 'dayjs';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
import { ILeaderBoardPlayer } from '../interfaces/leader-board-player';

export interface ILiveTournamentContext {
  alphaAddress: string;
  ownerAddress: string;
  tokenAddress: string;
  liveTournamentInfo: ILiveTournamentInfo | undefined;
  usdVal: number;
  deadline: string;
  prize: number;
  initLiveTournament: (alphaAddress?: string, ownerAddress?: string) => void;
  createLiveTournament: () => void;
  resetLiveTournament: () => void;
  getRewards: (id: string) => Promise<ILeaderBoardPlayer[]>;
  // player
  playerTxHashMap: Map<string, string>;
  savePlayerTxHash: (potId: string, txHash: string) => void;
  removePlayerTxHash: (potId: string) => void;
  getPlayerTxHashByPotId: (potId: string) => string | undefined;
  // creator
  createGameFailedList: ILiveTournamentCreateFailed[];
  saveCreateGameFailedInfo: (info: ILiveTournamentCreateFailed) => void;
  removeCreateGameFailedInfo: (info: ILiveTournamentCreateFailed) => void;
}

const initialValue: ILiveTournamentContext = {
  alphaAddress: '',
  ownerAddress: '',
  tokenAddress: '',
  liveTournamentInfo: undefined,
  usdVal: 0,
  deadline: '',
  prize: 0,
  initLiveTournament: () => {},
  createLiveTournament: () => {},
  resetLiveTournament: () => {},
  getRewards: async () => [],
  // Player
  playerTxHashMap: new Map<string, string>(),
  savePlayerTxHash: () => {},
  removePlayerTxHash: () => {},
  getPlayerTxHashByPotId: () => undefined,
  // Creator
  createGameFailedList: [],
  saveCreateGameFailedInfo: () => {},
  removeCreateGameFailedInfo: () => {},
};

export const LiveTournamentContext =
  React.createContext<ILiveTournamentContext>(initialValue);

export const LiveTournamentProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [alphaAddress, setAlphaAddress] = useState('');
  const [ownerAddress, setOwnerAddress] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [liveTournamentInfo, setLiveTournamentInfo] = useState<
    ILiveTournamentInfo | undefined
  >();

  const [playerTxHashMap, setPlayerTxHashMap] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [createGameFailedList, setCreateGameFailedList] = useState<
    ILiveTournamentCreateFailed[]
  >([]);

  const { getUserInfoByToken } = useUserToken();

  const [ratio, setRatio] = useState<number>(0);

  const prize: number = useMemo(() => {
    if (!liveTournamentInfo) return 0;
    return Number(Web3.utils.fromWei(liveTournamentInfo.value ?? '0'));
  }, [liveTournamentInfo]);

  const usdVal = useMemo(() => {
    return prize * ratio;
  }, [prize, ratio]);

  const deadline = useMemo(() => {
    if (!liveTournamentInfo) {
      return '';
    }

    return dayjs(parseInt(liveTournamentInfo.endAt ?? '0') * 1000)
      .utc()
      .toISOString();
  }, [liveTournamentInfo]);

  useEffect(() => {
    setRatio(0);
    fetchTokenPrice();
  }, [liveTournamentInfo?.potToken]);

  const getRewards = async (potId: string): Promise<ILeaderBoardPlayer[]> => {
    const res = await getLeaderBoardById(potId);
    return res.players;
  };

  const fetchTokenPrice = async () => {
    const tokenPriceUsd = (
      await getUserInfoByToken(liveTournamentInfo?.potToken ?? '')
    )?.tokenPriceUsd;
    if (!tokenPriceUsd) return;
    setRatio(tokenPriceUsd);
  };

  const fetchCurrentGame = async (_alphaAddress: string) => {
    const res = await getCurrentMatchWordGameByAlphaAddress(_alphaAddress);
    setLiveTournamentInfo(res?.game);
  };

  const createLiveTournament = async () => {};

  const resetLiveTournament = () => {
    setAlphaAddress('');
    setOwnerAddress('');
    setLiveTournamentInfo(undefined);
  };

  const initLiveTournament = (
    _alphaAddress?: string,
    _ownerAddress?: string,
    _tokenAddress?: string
  ) => {
    console.log('________initLiveTournament', _alphaAddress);

    if (_alphaAddress) {
      setAlphaAddress(_alphaAddress);
      fetchCurrentGame(_alphaAddress);
    }

    if (_ownerAddress) {
      setOwnerAddress(_ownerAddress);
    }

    if (_tokenAddress) {
      setTokenAddress(_tokenAddress);
    }
  };

  const savePlayerTxHash = (potId: string, txHash: string) => {
    const newMap = new Map<string, string>(playerTxHashMap);
    newMap.set(potId, txHash);

    setPlayerTxHashMap(newMap);
  };

  const removePlayerTxHash = (potId: string) => {
    const newMap = new Map<string, string>(playerTxHashMap);
    newMap.delete(potId);
    setPlayerTxHashMap(newMap);
  };

  const getPlayerTxHashByPotId = (potId: string) => {
    const txHash = playerTxHashMap.get(potId);
    return txHash;
  };

  const saveCreateGameFailedInfo = (info: ILiveTournamentCreateFailed) => {
    const newArray = [...createGameFailedList];
    newArray.push(info);
    setCreateGameFailedList(newArray);
  };

  const removeCreateGameFailedInfo = (info: ILiveTournamentCreateFailed) => {
    const newArray = createGameFailedList.filter(item => {
      return item.txHash == info.txHash;
    });

    setCreateGameFailedList(newArray);
  };

  const contextValues = useMemo((): ILiveTournamentContext => {
    return {
      alphaAddress,
      initLiveTournament,
      liveTournamentInfo,
      createLiveTournament,
      usdVal,
      prize,
      deadline,
      resetLiveTournament,
      ownerAddress,
      tokenAddress,
      getRewards,
      playerTxHashMap,
      savePlayerTxHash,
      removePlayerTxHash,
      getPlayerTxHashByPotId,
      createGameFailedList,
      saveCreateGameFailedInfo,
      removeCreateGameFailedInfo,
    };
  }, [alphaAddress, liveTournamentInfo, usdVal, prize, deadline]);

  return (
    <LiveTournamentContext.Provider value={contextValues}>
      {children}
    </LiveTournamentContext.Provider>
  );
};
