import BigNumber from 'bignumber.js';
import BigNumer from 'bignumber.js';
import { LockTerm, StakeType } from '@/interfaces/api/staking';
import dayjs from 'dayjs';
import { LockTime, YEAR_DAYS } from '@/modules/Stake/constant';
import { formatCurrency } from '@/utils';
import { cloneDeep } from 'lodash';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';

const getNumOfStakeHolder = ({
  amount,
  percent,
}: {
  amount: string;
  percent: number;
}) => {
  return Math.floor((Math.floor(Number(amount)) / percent) * 100);
};

const getNumOfKeysRequired = (params: {
  stakingKeys: string;
  percent: number;
  lockTerm: LockTerm;
}) => {
  const requiredKeys = new BigNumber(params.stakingKeys)
    .times(params.percent)
    .div(100)
    .toFixed(0, BigNumber.ROUND_CEIL);
  return requiredKeys;
};

const prepareCreateParams = ({
  percent,
  days,
}: {
  percent: number;
  days: number;
  lockTerm: LockTerm;
}) => {
  const duration = new BigNumber(days)
    .multipliedBy(24)
    .multipliedBy(3600)
    .toFixed(0, BigNumber.ROUND_CEIL);
  const ratio = new BigNumber(percent)
    .div(100)
    .multipliedBy(1e6)
    .toFixed(0, BigNumber.ROUND_CEIL);

  return {
    duration: duration.toString(),
    ratio,
  };
};

const convertSecondsToDays = (seconds: number | string) => {
  const days = new BigNumber(seconds)
    .div(new BigNumber(3600).multipliedBy(24))
    .toFixed(0, BigNumber.ROUND_FLOOR);
  return days;
};

const formatStakeAmount = (params: {
  amount: string;
  roundCeil?: boolean;
  decimals?: number;
}) => {
  const decimals = params.decimals || 5;
  return new BigNumber(params.amount).toFixed(
    decimals,
    params.roundCeil ? BigNumber.ROUND_CEIL : BigNumber.ROUND_FLOOR
  );
};

const getLockDayByTerm = (term: LockTerm) => {
  switch (term) {
    case LockTerm.FLEXIBLE:
      return '30';
    case LockTerm.ONE_MONTH:
      return '30';
    case LockTerm.TWO_MONTH:
      return '60';
    case LockTerm.THREE_MONTH:
      return '90';
    case LockTerm.SIX_MONTH:
      return '180';
  }
};

const getLockDayByDuration = (duration: number) => {
  const time = new BigNumber(duration).div(3600).div(24).toNumber();
  switch (time) {
    case LockTime[LockTerm.FLEXIBLE]:
    case LockTime[LockTerm.ONE_MONTH]:
      return '30';
    case LockTime[LockTerm.TWO_MONTH]:
      return '60';
    case LockTime[LockTerm.THREE_MONTH]:
      return '90';
    case LockTime[LockTerm.SIX_MONTH]:
      return '180';
  }
};

const getLockTypeShortHandByDuration = (duration: number) => {
  const time = new BigNumber(duration).div(3600).div(24).toNumber();
  switch (time) {
    case LockTime[LockTerm.FLEXIBLE]:
      return 'flex';
    case LockTime[LockTerm.ONE_MONTH]:
    case LockTime[LockTerm.TWO_MONTH]:
    case LockTime[LockTerm.THREE_MONTH]:
    case LockTime[LockTerm.SIX_MONTH]:
      return 'locked';
  }
};

const getLockNameShorthand = (duration: number, locked: boolean) => {
  const time = new BigNumber(duration).div(3600).div(24).toNumber();
  if (locked) {
    switch (time) {
      case LockTime[LockTerm.ONE_MONTH]:
        return '30 days';
      case LockTime[LockTerm.TWO_MONTH]:
        return '60 days';
      case LockTime[LockTerm.THREE_MONTH]:
        return '90 days';
      case LockTime[LockTerm.SIX_MONTH]:
        return '180 days';
    }
  }
  return 'Flex';
};

const convertRatioToAPR = (params: {
  ratio: string | number;
  duration: string | number;
}) => {
  const yearDuration = new BigNumber(360).times(24).times(3600).times(1000);
  const ratioYear = new BigNumber(params.ratio)
    .times(100)
    .div(1e3)
    .div(params.duration)
    .multipliedBy(yearDuration)
    .toFixed(0);
  const isHigh = new BigNumber(ratioYear).gt(60);
  const isMedium =
    new BigNumber(ratioYear).gt(12) && new BigNumber(ratioYear).lte(60);
  return {
    ratioYear,
    isHigh,
    isMedium,
    isNormal: !isHigh && !isMedium,
  };
};

const formatMaxDecimals = (params: { value: any; maxDecimals?: number }) => {
  const value = params.value;
  const maxDecimals = params.maxDecimals !== undefined ? params.maxDecimals : 3;

  if (
    value &&
    value.toString().includes('.') &&
    value.toString().split('.')[1]?.length > maxDecimals
  ) {
    return undefined;
  }
  return value;
};

const getLockedDays = (params: {
  order_expired_at: string;
  order_created_at: string;
}) => {
  const totalDays = dayjs.utc(params.order_expired_at).diff(
    params.order_created_at,
    'day'
  );
  const lockedDays =
    totalDays - dayjs.utc(params.order_expired_at).diff(dayjs().utc(), 'day');
  return `${lockedDays}/${totalDays}`;
};

const getStakeCreateMessage = (params: {
  lockTerm: LockTerm;
  stakeType: StakeType;
}) => {
  let message = '';
  const copy = {
    type: params.stakeType === StakeType.KEYS ? 'key' : 'token',
    time: LockTime[params.lockTerm],
    typeUpper: params.stakeType === StakeType.KEYS ? 'Key' : 'Token',
  };
  switch (params.lockTerm) {
    case LockTerm.FLEXIBLE:
      message = `Configure your staking pool to allow ${copy.type} holders to stake their ${copy.type}s and earn rewards for a period of ${copy.time} days. ${copy.typeUpper} holders have the flexibility to unstake at any time.`;
      break;
    case LockTerm.ONE_MONTH:
    case LockTerm.TWO_MONTH:
    case LockTerm.THREE_MONTH:
    case LockTerm.SIX_MONTH:
      message = `Configure your staking pool to allow ${copy.type} holders to stake their ${copy.type}s and earn rewards for a period of ${copy.time} days. No cancellations or unstaking are allowed within ${copy.type} days.`;
      break;
  }
  return message;
};

const getStakeDecimals = (stakeType: StakeType) => {
  return stakeType === StakeType.TOKENS ? 0 : 3;
};
const getJoinDecimals = (stakeType: StakeType) => {
  return stakeType === StakeType.TOKENS ? 6 : 3;
};

const getStakedMessage = (staking: number, total: number) => {
  return formatCurrency(staking, 0, 6) + ' / ' + formatCurrency(total, 0, 6);
};

const convertPercentByLockType = (params: {
  value: number | string;
  stakeType: StakeType;
  lockTerm: LockTerm;
}) => {
  const { value, stakeType, lockTerm } = params;
  let _amount = cloneDeep(value);
  if (stakeType === StakeType.TOKENS) {
    _amount = new BigNumer(_amount)
      .div(LockTime[lockTerm])
      .times(YEAR_DAYS)
      .toFixed(2);
    _amount = new BigNumer(_amount).toNumber();
  }
  return _amount;
};

const parsePercentByLockType = (params: {
  value: number;
  stakeType: StakeType;
  lockTerm: LockTerm;
}) => {
  const { value, stakeType, lockTerm } = params;
  let _amount = `${value}`;
  if (stakeType === StakeType.TOKENS) {
    _amount = new BigNumer(_amount)
      .div(YEAR_DAYS)
      .times(LockTime[lockTerm])
      .toFixed(2, BigNumber.ROUND_FLOOR);
  }
  return new BigNumber(_amount).toNumber();
};

const getInterestMessage = (params: {
  stakeType: StakeType;
  duration: string | number;
  ratio: number | string;
}) => {
  const message =
    params.stakeType === StakeType.KEYS
      ? `Interest rate / ${convertSecondsToDays(params?.duration || 0)} days`
      : 'APR (360 days)';

  let ratio;
  if (params.stakeType === StakeType.KEYS) {
    ratio = Number(params.ratio || 0) * 100;
  } else {
    ratio = convertRatioToAPR({
      ratio: params.ratio,
      duration: params.duration,
    }).ratioYear;
  }
  return {
    message,
    ratio: ratio + '%',
  };
};

const getSymbol = (params: { stakeType: StakeType; amount?: any }) => {
  let symbol = params.stakeType === StakeType.KEYS ? 'key' : 'token';
  if (params.amount !== undefined) {
    symbol = `${symbol}${labelAmountOrNumberAdds(params.amount)}`;
  }
  return symbol;
};

export {
  getNumOfStakeHolder,
  prepareCreateParams,
  convertSecondsToDays,
  formatStakeAmount,
  getLockDayByTerm,
  getLockDayByDuration,
  convertRatioToAPR,
  getLockTypeShortHandByDuration,
  getNumOfKeysRequired,
  formatMaxDecimals,
  getLockedDays,
  getLockNameShorthand,
  getStakeCreateMessage,
  getStakeDecimals,
  getStakedMessage,
  convertPercentByLockType,
  parsePercentByLockType,
  getInterestMessage,
  getJoinDecimals,
  getSymbol,
};
