import QRCodeGenerator from '@/components/QRCodeGenerator';
import SvgInset from '@/components/SvgInset';
import { CDN_URL, CDN_URL_ICONS } from '@/configs';
import { GetTcPayType, IGetTcResponse } from '@/interfaces/topup';
import { formatEthPrice } from '@/utils/format';
import useAnalyticsEventTracker from '@/utils/ga';
import { Spinner } from '@chakra-ui/react';
import {
  formatBTCPrice,
  formatLongAddress,
} from '@trustless-computer/dapp-core';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { CheckHistory, Container } from './Payment.styled';

interface PaymentFormProps {
  paymentInfo: IGetTcResponse;
  isProcessing: boolean;
  onBackToSubmit: () => void;
  onSubmitGenerate: (
    tcAddress: string,
    tcAmount: number,
    payType: GetTcPayType,
    onErrorCallback?: () => void
  ) => void;
  isJackpotDeposit?: boolean;
}

const PaymentForm = (props: PaymentFormProps) => {
  const gaEventTracker = useAnalyticsEventTracker();
  const {
    paymentInfo,
    isProcessing,
    onBackToSubmit,
    onSubmitGenerate,
    isJackpotDeposit = false,
  } = props;

  // const [payType, setPayType] = useState<IPayType>(ListPayType[0]);

  const onClickCopy = (address: string) => {
    copy(address);
    toast.success('Copied');
  };

  // const handleSelectPaytype = (pt: IPayType) => {
  //   if (pt !== payType) {
  //     gaEventTracker(
  //       TopupActions.PaymentCurrency,
  //       pt.value === 'bitcoin' ? 'BTC' : 'ETH'
  //     );
  //     paymentInfo &&
  //       onSubmitGenerate(
  //         paymentInfo.tcAddress,
  //         Number(formatTCPrice(paymentInfo.tcAmount)),
  //         pt.value,
  //         () => {
  //           setPayType(
  //             pt.value === 'bitcoin' ? ListPayType[0] : ListPayType[1]
  //           );
  //         }
  //       );
  //     setPayType(pt);
  //   }
  // };

  return (
    <Container>
      {/* <PaytypeList data={payType} onSelect={handleSelectPaytype} /> */}
      {isProcessing ? (
        <div
          style={{
            height: 380,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spinner color="blue.500" size="xl" />
        </div>
      ) : (
        paymentInfo && (
          <>
            <div className="payment">
              <p className="text_title">
                Send{' '}
                <span>
                  {paymentInfo.payType === 'bitcoin'
                    ? `${formatBTCPrice(paymentInfo.paymentAmount)} BTC`
                    : `${formatEthPrice(paymentInfo.paymentAmount)} ETH`}{' '}
                </span>{' '}
                to this payment address
              </p>
              <div className="copy-container">
                <p className="copy-container_address">
                  {paymentInfo.address || ''}
                </p>
                <SvgInset
                  size={24}
                  svgUrl={`${CDN_URL}/gasstion-icons/ic-copy.svg`}
                  onClick={() => onClickCopy(paymentInfo.address || '')}
                  className="icon-copy"
                />
              </div>
              <div className="wrap-qr">
                <QRCodeGenerator
                  bgColor="#FFFFFF"
                  size={180}
                  value={paymentInfo.address || ''}
                />
              </div>
            </div>
            {!isJackpotDeposit && (
              <div className="wrap-receive-address">
                <p className="wrap-receive-address_text">
                  <span style={{ fontWeight: 600 }}>
                    Receiving Wallet address:
                  </span>{' '}
                  <br></br> {formatLongAddress(paymentInfo.tcAddress || '')}
                </p>
              </div>
            )}

            <div className="back-again-btn" onClick={onBackToSubmit}>
              <SvgInset size={20} svgUrl={`${CDN_URL_ICONS}/angle-left.svg`} />
              <p>Buy more</p>
            </div>
          </>
        )
      )}
      <CheckHistory>
        <a
          target="_blank"
          href={`/topup/history?address=${paymentInfo.tcAddress}`}
          className="checkHistory checkHistoryModal"
        >
          Check your BVM transaction history here.
        </a>
      </CheckHistory>
    </Container>
  );
};

export default PaymentForm;
