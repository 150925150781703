import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';

export const getTradeKey = async (): Promise<string | null> => {
  const tradeKey = (await apiClient.get(
    `${PERP_API_URL}/uni-bot-api/wallet/private-key`
  )) as string;
  return tradeKey;
};

export type PromptType = '/jup' | '!jup';

export const SUPPORTED_PROMPT: PromptType[] = ['/jup', '!jup'];

export type PromptAction = 'deposit';

export const getTradeKeyAddress = async (
  prompt: PromptType
): Promise<string | null> => {
  const tradeKey = (await apiClient.get(
    `${PERP_API_URL}/uni-bot-api/wallet/deposit?prompt=${prompt.replace(
      '/',
      ''
    )}`
  )) as string;
  return tradeKey;
};

const tradeService = {
  getTradeKey,
  getTradeKeyAddress,
};

export default tradeService;
