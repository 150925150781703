import {Flex} from '@chakra-ui/react';
import styles from './styles.module.scss';
import {useState} from 'react';
import {colors} from '@/utils/color';

interface IProps {
  values: any[];
  value: string;
  onChange: (_: string) => void;
}

const ActionSwitch: React.FC<IProps> = ({
                                          values,
  value = 'all',
  onChange,
}: IProps) => {
  const [action, setAction] = useState(value);

  const handleSwitch = (_action: string) => {
    if (_action !== action) {
      setAction(_action);
      onChange && onChange(_action);
    }
  };

  const leftObject = values[0];
  const rightObject = values[1];

  return (
    <Flex className={styles.container}>
      <Flex
        className={action === leftObject?.value ? styles.buy : ''}
        onClick={() => handleSwitch(leftObject?.value)}
        backgroundColor={
          action === leftObject?.value ? colors.bluePrimary : 'transparent'
        }
      >
        {leftObject?.label}
      </Flex>
      <Flex
        className={action === rightObject?.value ? styles.sell : ''}
        onClick={() => handleSwitch(rightObject?.value)}
        backgroundColor={
          action === rightObject?.value ? colors.bluePrimary : 'transparent'
        }
      >
        {rightObject?.label}
      </Flex>
    </Flex>
  );
};

export default ActionSwitch;
